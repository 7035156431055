import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormController } from '@web/form';
import { RegisterContainer, Title, TitleBold } from '../../style';
import { PasswordStatus } from '../../../components';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { AuthStateManager } from '@workspace/frontend-shared-application-state-manager';
import { AlertWeb, BoxWeb, LinkWeb, NavigateButtonsWeb } from '@workspace/frontend/shared/presentation/components';
import { PasswordField } from '@web/form/component';
import { registerUserFactory } from '../../../factories/register-user';
import useErrorProvider from '../../../providers/error-provider';
import { StepperContext } from '../stepper/stepper-context';
import { RegisterUserRequestDTO } from '../../../adapter/register-user-request';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { RecaptchaActions } from '../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../providers/auth-product-provider';

type SubmitData = {
  password: string;
  confirm_password: string;
};

export function RegisterPasswordContainer() {
  const { handleNext, handleBack } = useContext(StepperContext);
  const titleRef = useRef(undefined);
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.CREATE_PASSWORD;

  const [sending, setSending] = useState<boolean>(false);
  const [missedRulesComponent, setMissedRulesComponent] = useState<JSX.Element | null>(null);

  const handleCloseAlert = () => setRequestError({ show: false });

  const handleSubmitError = (errorData) => {
    setSending(false);

    const error = buildError(errorData);

    setRequestError({ ...error, show: true });
  };

  const registerUserUsecase = registerUserFactory({ partialEnvironment, onError: handleSubmitError });

  const handleSubmit = async ({ password }: SubmitData) => {
    analyticsAuth.NEXT();
    setSending(true);

    const { cpf, email, name, cellphone } = AuthStateManager.registrationUserInformationSelector();

    const payload = RegisterUserRequestDTO.translate({
      cpf,
      email,
      name,
      cellphone,
      password: password,
    });

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaActions.REGISTER,
      partialEnvironment.environment.recaptchaSiteKey
    );

    const registerResponse = await registerUserUsecase.execute(payload, {
      recaptcha: JSON.stringify(recaptchaToken),
    });

    if (registerResponse?.success) {
      AuthStateManager.registrationStepDispatcher(3);
      handleNext();
    }

    setSending(false);
  };

  const handleClickBack = () => {
    analyticsAuth.BACK();
    handleBack();
  };

  const initialValues = {
    password: '',
  };

  useEffect(() => {
    analyticsAuth.LOAD();

    setTimeout(() => {
      if (titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  return (
    <>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        onClose={handleCloseAlert}
        children={requestError.message}
        secondsToClose={16}
      />
      <RegisterContainer>
        <BoxWeb marginBottom="8px">
          <Title tabIndex={-1} ref={titleRef}>
            quase lá!
          </Title>
        </BoxWeb>

        <BoxWeb marginBottom="45px">
          <TitleBold id="lblInicialText_screenRegister">crie uma senha</TitleBold>
        </BoxWeb>

        <FormController initialValues={initialValues} onSubmit={handleSubmit} validationMode="onBlur" hideErrors>
          {({ watch, clearErrors, trigger, formState }) => {
            const [passwordField, confirmPasswordField] = watch(['password', 'confirm_password']);

            const handlePasswordChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
              value?.length && clearErrors('password');

            const handlePasswordBlur = () =>
              !confirmPasswordField ? trigger('password') : trigger(['password', 'confirm_password']);

            const handleClickContinue = () => {
              if (!passwordField) return;

              setSending(true);
              setRequestError({ show: false });

              if (!missedRulesComponent) return setSending(false);

              setTimeout(() => {
                setRequestError({
                  message: missedRulesComponent,
                  show: true,
                  type: 'warning',
                });

                setSending(false);
              }, 500);
            };

            const confirmPasswordValidation = {
              requiredMessage: 'você precisa confirmar a senha',
              test: [
                {
                  name: 'passwords-match',
                  message: 'as senhas precisam ser iguais',
                  test: function (value) {
                    return this.parent.password === value;
                  },
                },
              ],
            };

            if (formState.errors['confirm_password']) {
              analyticsAuth.PASSWORDS_DONT_MATCH(formState.errors['confirm_password']['message']);
            }

            return (
              <>
                <BoxWeb marginBottom="24px">
                  <BoxWeb marginBottom="8px" fontSize="18px" lineHeight="23px">
                    a sua senha deve:
                  </BoxWeb>

                  <PasswordStatus value={passwordField} missedRulesComponent={setMissedRulesComponent} />
                </BoxWeb>
                <BoxWeb maxWidth="400px" marginBottom="32px">
                  <PasswordField
                    componentProps={{
                      id: 'txtPassword_screenRegister',
                      label: 'digite sua senha',
                    }}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                  />
                </BoxWeb>
                <BoxWeb maxWidth="400px" marginBottom="32px">
                  <PasswordField
                    componentProps={{
                      id: 'txtConfirmPassword_screenRegister',
                      label: 'confirme sua senha',
                    }}
                    prefix="confirm"
                    validationOverrides={confirmPasswordValidation}
                  />
                </BoxWeb>
                <BoxWeb maxWidth="400px" marginBottom="32px"></BoxWeb>
                <BoxWeb maxWidth="513px" marginBottom="40px" fontSize="16px" lineHeight="21px">
                  Saiba mais sobre como tratamos seus dados em nossa{' '}
                  <LinkWeb
                    id="lnkPrivacyPolicy_screenRegister"
                    href="https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/termos/termos_condicoes_2021_05.pdf"
                    text="Política de Privacidade"
                    target="_blank"
                  />
                  , disponível em nossos sites e aplicativos.
                </BoxWeb>
                <BoxWeb maxWidth="400px">
                  <NavigateButtonsWeb
                    onClickBack={handleClickBack}
                    onClickContinue={handleClickContinue}
                    widthBackButton={'188px'}
                    widthContinueButton={'188px'}
                    justifyContent="center"
                    loading={sending}
                  />
                </BoxWeb>
              </>
            );
          }}
        </FormController>
      </RegisterContainer>
    </>
  );
}
