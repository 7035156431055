import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector, useFormControllerMethods } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import { Plate } from '@workspace/entities';

const plateValidation = () => {
  return stringValidationFactory({
    condition: {
      when: 'zeroKm',
      is: true,
      then: stringValidationFactory({}),
      otherwise: stringValidationFactory({
        requiredMessage: 'você precisa informar a placa do veículo',
        length: {
          limit: 7,
          message: 'placa inválida',
        },
      }),
    },
  });
};

export const PlateField = ({ onChange, disabled }: { onChange?: any; disabled?: boolean }) => {
  const { watch } = useFormControllerMethods();
  const isZeroKm = watch('zeroKm');
  return (
    <FormControllerFieldConnector<InputMaskWebProps>
      component={InputMaskWeb}
      name="plate"
      onChange={onChange}
      disabled={disabled || isZeroKm}
      validation={plateValidation()}
      componentProps={{
        helperText: 'a partir da placa reconhecemos os dados do seu veículo',
        maskType: Plate.MASK,
        id: 'txtVehicleLicensePlate_screenCarInformation',
        label: 'placa',
      }}
    />
  );
};
