import { FormControllerFieldConnector } from '@workspace/frontend/shared/presentation/form/control';
import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { IStringValidationFactory, nameValidation } from '@workspace/frontend-shared-application-validation';

export const NameField = ({
  prefix = undefined,
  id,
  validationOverrides,
  label = 'nome',
  disabled = false,
  componentProps,
}: {
  prefix?: string;
  id?: string;
  validationOverrides?: IStringValidationFactory;
  label?: string;
  componentProps?: Partial<InputWebProps>;
  disabled?: boolean;
}) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name={prefix ? prefix + '_name' : 'name'}
    disabled={disabled}
    validation={nameValidation(validationOverrides)}
    componentProps={{ id, label, ...componentProps }}
  />
);
