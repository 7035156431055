import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';

export const FrequentlyAskedQuestionsTitlePrefix = styled.span`
  font-size: 16px;
  line-height: 21px;
  color: #2b374a;
  font-family: 'ItauDisplay-Light';

  &:after {
    content: attr(data-title);
    color: #ec7000;
    font-family: 'ItauDisplay-Bold';
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const ProductPageLink = styled.a`
  color: #106eb0;
  font-family: 'ItauDisplay-Bold';
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-decoration: underline;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    font-size: 14px;
  }
`;

export const QuestionStyle = styled(BoxWeb)`
  text-decoration: underline;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  background-color: #faf7f5;
  margin-top: 16px;

  a {
    color: #106eb0;
  }
`;

export const DesktopContent = styled(BoxWeb)`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
`;

export const MobileContent = styled(BoxWeb)`
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const TopicQuestion = styled.a`
  color: #2b374a;
  font-family: 'ItauText-Regular';
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  width: 191px;
  word-break: break-word;
  text-decoration: none;
  line-break: auto;

  &:hover {
    text-decoration: underline;
  }
`;
