import { useEffect, useState } from 'react';
import { StepperWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { domainFactory } from '../../factories/domain-factory';
import { StepProviderProps, useStepProvider } from '../../providers/step-provider';
import DriverCpfStep from './steps/driver-cpf-step/driver-cpf-step-container';
import { StepThreeAddress } from './steps/vehicle-address-step/address-step-container';
import { StepTwoVehicle } from './steps/vehicle-data-step/vehicle-step-container';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { PaymentStepContainerWrapper } from './steps/payment-step/payment-step-container';
import { AdditionalDataStepContainerWrapper } from './steps/additional-data-step/additional-data-step-container';
import { StepFourQuotation } from './steps/quotation-step/quotation-step-container';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { DomainAdapter } from '@workspace/frontend/auto/application/adapters';
import { LoadingAutoPage } from '@workspace/frontend/auto/presentation/components';
import { getAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { AutoV1TestABKeysEnum } from '../../enums/testAB';
import { AuthenticationStepContainerWrapper } from './steps/authentication-step/authentication-step-container';

export function HiringWrapper() {
  const stepProvider: StepProviderProps = useStepProvider();
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const [loading, setLoading] = useState(true);
  const { Theme } = useThemeProvider();

  const initializeAutoV1TestABParams = () => {
    const cpf = sessionStorage.getItem(AutoV1TestABKeysEnum.CPF);
    const licensePlate = sessionStorage.getItem(AutoV1TestABKeysEnum.LICENSE_PLATE);
    const isZeroKm = sessionStorage.getItem(AutoV1TestABKeysEnum.ZERO_KM);
    if (cpf) {
      stepProvider.setActiveStep(1);
      AutoStateManager.testABHiringDispatcher(cpf, licensePlate, isZeroKm === 'true');
      sessionStorage.removeItem(AutoV1TestABKeysEnum.CPF);
      sessionStorage.removeItem(AutoV1TestABKeysEnum.LICENSE_PLATE);
      sessionStorage.removeItem(AutoV1TestABKeysEnum.ZERO_KM);
    }
  };

  useEffect(() => {
    (async () => {
      const domainUsecase = domainFactory(environment);
      const domain = await domainUsecase.execute();
      const parsedDomain = DomainAdapter(domain);
      AutoStateManager.onMountDispatch(parsedDomain);
      setLoading(false);
    })();

    const redirectRejectedStep = () => {
      stepProvider.setActiveStep(5);
    };

    const steps = [
      {
        title: 'dados do condutor',
        component: DriverCpfStep,
        onClick: () => stepProvider.setActiveStep(0),
      },
      {
        title: 'veículo',
        component: StepTwoVehicle,
        onClick: () => stepProvider.setActiveStep(1),
      },
      {
        title: 'endereço',
        component: StepThreeAddress,
        onClick: () => stepProvider.setActiveStep(2),
      },
      {
        title: 'cotação',
        component: StepFourQuotation,
        onClick: () => stepProvider.setActiveStep(3),
      },
    ];
    const hiringSteps = [
      {
        title: 'dados adicionais',
        component: AdditionalDataStepContainerWrapper,
        onClick: () => stepProvider.setActiveStep(4),
      },
      {
        title: 'autenticação',
        component: AuthenticationStepContainerWrapper,
        onClick: () => stepProvider.setActiveStep(5),
      },
      {
        title: 'pagamento',
        component: PaymentStepContainerWrapper,
        onClick: () => stepProvider.setActiveStep(6),
        protectedStep: {
          isRejected: !getAuthentication()?.identifier,
          onRejected: redirectRejectedStep,
        },
      },
    ];

    if (environment.enableAutov2Hiring) {
      steps.push(...hiringSteps);
    }
    stepProvider.setSteps(steps);
    initializeAutoV1TestABParams();
  }, []);

  if (loading) return <LoadingAutoPage />;

  return (
    <SCThemeProvider theme={Theme}>
      <StepperWeb activeStep={stepProvider.activeStep} steps={stepProvider.steps} />
    </SCThemeProvider>
  );
}

export default HiringWrapper;
