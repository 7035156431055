import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';

export const AgencyField = ({
  id = 'cboAgency_screen',
  label = 'agência',
  type = 'tel',
}: {
  id?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name={'agencia'}
    validation={stringValidationFactory({
      requiredMessage: 'você precisa informar a agência',
      min: {
        limit: 4,
        message: 'a agência deve conter 4 caracteres',
      },
      max: {
        limit: 4,
        message: 'a agência deve conter 4 caracteres',
      },
    })}
    componentProps={{ id, label, type, maskType: '9999' }}
  />
);
