import React, { useCallback, useEffect, useState } from 'react';
import { autov2Images } from '@web/assets';
import {
  BoxWeb,
  InfoBoxWeb,
  LinkButtonWeb,
  NavigateButtonsWeb,
  OutlinedButtonWeb,
} from '@workspace/frontend/shared/presentation/components';
import { SimulationType } from '../../../../adapter/simulation-response';
import { TabAutoItem } from '@workspace/frontend/auto/presentation/components';
import { Company } from '@core/domain/enums/company';
import { Coverage } from '../../../../enums/coverage';
import { QuotationDescription } from '../../../../components/QuotationDescription/quotation-description';
import { LeadDialog } from '../../../../components/auto-quotation-lead-dialog/auto-quotation-lead-dialog';

const firstTabTitle = {
  titleTabItem: 'recomendação melhor proteção',
  titleOffer: 'Seguro Porto',
  partnerBanner: autov2Images.logoSeguradora,
  partnerBannerName: 'porto',
};

const secondTabTitle = {
  titleTabItem: 'recomendação melhor custo',
  titleOffer: 'Seguro Azul',
  partnerBanner: autov2Images.logoAzul,
  partnerBannerName: 'azul',
};

const tabTitles = [firstTabTitle, secondTabTitle, firstTabTitle];

type OfferInsurance = {
  title: string;
  children: JSX.Element;
};

export const AutoStepQuotation = ({
  redoQuotation,
  onBack,
  onContinue,
  insuranceOffers,
  onChangeTab,
  onToggleAccordion,
  peopleImagePath,
  enableAutov2Hiring,
  sendLeadUsecase,
  initialValues,
  isBusy,
  config,
  enableCampanhaAuto,
}) => {
  const [switchFranchise, setSwitchFranchise] = useState(0);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [offersInsurance, setOffersInsurance] = useState<OfferInsurance[]>([]);
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const handleCloseLeadModal = () => setOpenLeadModal(false);
  const handleOpenLeadModal = () => setOpenLeadModal(true);

  const handleContinue = useCallback(() => {
    onContinue(switchFranchise ? 100 : 50, currentTab === 1);
  }, [switchFranchise, currentTab, sendLeadUsecase]);

  const getPortoOffers = (offers: SimulationType[]): SimulationType[] =>
    offers.filter((offer) => offer.company === Company.PORTO);
  const getAzulOffers = (offers: SimulationType[]): SimulationType[] =>
    offers.filter((offer) => offer.company === Company.AZUL);

  const getSelectedCompanyByIndexTab = () => (currentTab === 0 ? Company.PORTO : Company.AZUL);

  useEffect(() => {
    if (insuranceOffers.offers) {
      const offersGroupedByCompany = [
        getPortoOffers(insuranceOffers.offers),
        getAzulOffers(insuranceOffers.offers),
      ]?.filter((i) => i.length > 0);

      const offersInsuranceMap = offersGroupedByCompany.map((offer, index) => ({
        title: tabTitles[index].titleTabItem,
        children: (
          <QuotationDescription
            simulationResponse={offer}
            title={tabTitles[index].titleOffer}
            partnerBanner={tabTitles[index].partnerBanner}
            partnerBannerName={tabTitles[index].partnerBannerName}
            tabIndex={index}
            selectedCompany={getSelectedCompanyByIndexTab()}
            getOfferByFranchise={getOfferByFranchise}
            onChangeSwitch={setSwitchFranchise}
            onToggleAccordion={onToggleAccordion}
            enableCampanhaAuto={enableCampanhaAuto}
          />
        ),
      }));
      setOffersInsurance(offersInsuranceMap);
    }
  }, [currentTab]);

  const getOfferByFranchise = (offers: SimulationType[], franchiseCode: number): SimulationType => {
    return offers.find((simulation) => {
      const coverageFranchiseIndex = simulation.offers.coverages.find((item) => {
        return item.code === Coverage.WITH_FRANCHISE_VALUE;
      });
      return coverageFranchiseIndex?.franchise[0].code === franchiseCode;
    });
  };

  return (
    <>
      <LeadDialog
        simulationNumber={insuranceOffers.simulationNumber}
        open={openLeadModal}
        onClose={handleCloseLeadModal}
        sendLeadUsecase={sendLeadUsecase}
        initialValues={initialValues}
      />
      <BoxWeb display="flex" flexDirection="row" justifyContent="space-between" width={'100%'} role={'tablist'}>
        {offersInsurance.length > 1 &&
          offersInsurance.map((item, index) => {
            return (
              <TabAutoItem
                id={`btnOffers_screenQuotation_${index}`}
                current={index === currentTab}
                onClick={() => {
                  onChangeTab(index === 1);
                  setCurrentTab(index);
                }}
                ariaPosinset={index + 1}
                ariaSetsize={offersInsurance.length}
                label={item.title}
              />
            );
          })}
      </BoxWeb>
      <div role={'tabpanel'}>{offersInsurance[currentTab]?.children}</div>
      <BoxWeb width={['250px', '500px']} my={'3rem'}>
        <InfoBoxWeb
          bgcolor={'#fff'}
          border={'1px solid'}
          imgSrc={peopleImagePath}
          imgRight={'-6rem'}
          text={
            'não se preocupe, você pode alterar seu plano sempre que quiser entrando em contato com a central da Porto Seguro'
          }
        />
      </BoxWeb>

      <BoxWeb display={'flex'} justifyContent={'flex-start'} alignItems={'center'} flexDirection={['column', 'row']}>
        <NavigateButtonsWeb
          continueText={enableAutov2Hiring ? 'continuar' : 'solicitar contato'}
          onClickContinue={handleContinue}
          onClickBack={onBack}
          loading={isBusy}
        />
        <BoxWeb width={'fit-content'} ml={'1rem'}>
          <LinkButtonWeb
            label={'Refazer cotação para outro veículo'}
            id="btnQuotationRedo_screenQuotation"
            onClick={redoQuotation}
          />
        </BoxWeb>
      </BoxWeb>

      <BoxWeb height="3rem" />

      {config?.ENABLE_AUTO_SIMPLE_LEAD && (
        <BoxWeb width="17rem">
          <span>se preferir, deixe seus dados e nós entraremos em contato com você</span>
          <BoxWeb height="0.5rem" />
          <BoxWeb tabIndex={0}>
            <OutlinedButtonWeb
              id="btnContactLead_screenQuotation"
              label="entrar em contato"
              onClick={handleOpenLeadModal}
            />
          </BoxWeb>
        </BoxWeb>
      )}
    </>
  );
};
