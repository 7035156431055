import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { DynamicLandPageProvider } from '../providers/landing-provider';
import { DynamicLandPageContent } from './dynamic-landing-page-content';
import { LandingTheme } from '../components/landing-theme';

export const DynamicLandPageWrapper = () => {
  return (
    <BrowserRouter>
      <Switch>
        <LandingTheme>
          <DynamicLandPageProvider>
            <DynamicLandPageContent />
          </DynamicLandPageProvider>
        </LandingTheme>
      </Switch>
    </BrowserRouter>
  );
};
