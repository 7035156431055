import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function IconWeb({ variant }: { variant?: 'icon-itaufonts_e_comercial' }) {
  return (
    <ThemeProviderInjector>
      <i className="icon-itaufonts_e_comercial" />
    </ThemeProviderInjector>
  );
}

export default IconWeb;
