import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import {
  dateFormatTest,
  futureDateTest,
  stringValidationFactory,
} from '@workspace/frontend-shared-application-validation';
import React from 'react';

export const ExpirationField = ({
  id = 'txtExpiration_screen',
  label = 'data de validade',
  type = 'tel',
}: {
  id?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name={'expirationDate'}
    validation={stringValidationFactory({
      test: [dateFormatTest({ format: 'MM/YYYY' }), futureDateTest({ format: 'MM/YYYY' })],
      requiredMessage: 'informe a validade',
    })}
    componentProps={{ id, label, type, maskType: '99/9999' }}
  />
);
