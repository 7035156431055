import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { Button } from '@material-ui/core';

export const CardContainer = styled(Button)`
  height: 100%;
  border: none;
  background-color: transparent;
  width: 100%;
  &:active {
    color: transparent;
  }
  &:hover {
    background-color: transparent;
  }

  & span {
    height: 100%;
  }
`;

export const PostCardWrapper = styled(BoxWeb)`
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  overflow-y: scroll;
  border-radius: 23px;
  background-color: ${(props) => props.theme.palette.background.grey};
  height: 100%;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PostImage = styled.img`
  height: 245px;
  width: 100%;
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  object-fit: cover;
`;
