import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { User } from '@core/domain/entities/auth/user';
import { RegisterUserResponse } from '@core/domain/entities/auth/response/user';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { EnvironmentContextProps } from '@workspace/providers';
import { RegisterUserRepository } from '@core/infra/repository/auth/register-user-repository';
import { RegisterUserUsecase } from '@core/data/services/register-user-usecase';

type RegisterUserFactory = {
  partialEnvironment: Partial<EnvironmentContextProps>;
  onError?: (error) => void;
};

export function registerUserFactory({ partialEnvironment, onError }: RegisterUserFactory) {
  const { environment } = partialEnvironment;

  const httpPostClient: IHttpPostClient<User, RegisterUserResponse> = new AxiosHttpPostClient<
    User,
    RegisterUserResponse
  >('usuarios', {}, environment.gatewayBFFBaseURL.url);
  const registerUserRepository = new RegisterUserRepository(httpPostClient);
  const registerUserUsecase = new RegisterUserUsecase(registerUserRepository, onError);
  return registerUserUsecase;
}
