import { rest } from 'msw';

export const postUsersMock = (env) => {
  return rest.post(`${env.gatewayBFFBaseURL.url}/usuarios`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        errors: [],
        value: {
          id: 123,
          idSituacao: 'ATIVO',
        },
      })
    );
  });
};
