import { BoxMui } from './box-mui';

const lightgray = '#EDEDED';

const borderRadius = '0.5rem';

export const InfoBoxMui = ({ text, bgcolor = lightgray, imgSrc = '', imgAlt = '', imgRight = '-1.8rem', ...props }) => {
  return (
    <BoxMui
      bgcolor={bgcolor}
      color={'primary.main'}
      borderRadius={borderRadius}
      fontWeight={'700'}
      p={'0.5rem'}
      pr={'3rem'}
      position={'relative'}
      {...props}
    >
      <BoxMui p="0.3rem" lineHeight={1.4} fontSize={'1.1875rem'} fontWeight={'700'}>
        {text}
      </BoxMui>
      <BoxMui position={'absolute'} display={'inline-block'} top={'-2rem'} right={imgRight}>
        <img src={imgSrc} alt={imgAlt} />
      </BoxMui>
    </BoxMui>
  );
};
