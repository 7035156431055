import { ActionAnalyticsInterface, ActionEventAnalyticsInterface } from './analytics';
import { ANALYTICS_EVENT_RULES } from '../constants/rules';
import { replaceLabelTokensByValues } from './replace-label-tokens-by-values';
import { assembleAnalyticsCustomPageData } from './assemble-analytics-custom-page-data';

const assembleAnalyticsCustomEvent = (
  analyticsCustomEvent: ActionEventAnalyticsInterface,
  labelValues: string[] = [],
  transactionName?: string,
  customPageData?: {page: string, responsibleSquadTag: string} | null | undefined
): ActionAnalyticsInterface => {
  const formattedLabel: string = replaceLabelTokensByValues(analyticsCustomEvent.label, labelValues);
  const formattedAnalyticsCustomEvent: ActionEventAnalyticsInterface = {
    ...analyticsCustomEvent,
    label: formattedLabel,
  };

  let actionAnalytics: ActionAnalyticsInterface = {
    events: formattedAnalyticsCustomEvent,
    rule: ANALYTICS_EVENT_RULES.CUSTOM_LINK,
  };

  if (transactionName) {
    actionAnalytics.transaction = { name: transactionName };
  }
  if (customPageData) {
    actionAnalytics = { ...actionAnalytics, ...assembleAnalyticsCustomPageData(customPageData) };
  }
  return actionAnalytics;
};

export { assembleAnalyticsCustomEvent };
