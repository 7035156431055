import { EMAIL_REGEX } from '../regex';
import {
  IStringValidationFactory,
  stringValidationFactory,
  StringValidationSchema,
} from '../validation-generators/string-validation-factory';

export const emailValidation = (overrides: IStringValidationFactory = {}): StringValidationSchema => {
  const defaultValues = {
    requiredMessage: 'você precisa informar o e-mail',
    matches: {
      pattern: EMAIL_REGEX,
      message: 'verifique o formato do e-mail',
    },
  };

  const values: IStringValidationFactory = Object.assign(defaultValues, overrides);

  return stringValidationFactory(values);
};
