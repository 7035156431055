import { CustomPageDataInterface } from './analytics';

interface AnalyticsCustomPageDataProps {
  page: string,
  responsibleSquadTag: string
}

export const assembleAnalyticsCustomPageData = ({page, responsibleSquadTag}): CustomPageDataInterface => ({
    page: {
      nome: page
    },
    custom: {
      squadresponsaveltag: responsibleSquadTag,
    },
})