import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { Typography } from '@material-ui/core';

export const WrapperContainer = styled(BoxWeb)`
  display: grid;
  grid-template-areas:
    'headline '
    'filter-mobile'
    'hero'
    'newsletter'
    'filter-desktop'
    'posts';
  position: relative;
  grid-template-columns: 100%;
  font-family: 'ItauDisplayPro-XBold';
`;

export const HeadlineContainer = styled(Typography)`
  grid-area: headline;
  background: ${(props) => props?.theme?.palette.primary.main};
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 30px 28px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 22px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props?.theme?.spacing(3)}px;
    font-size: 51px;
    margin-bottom: ${(props) => props?.theme?.spacing(7)}px;
  }
  color: ${(props) => props?.theme?.palette.primary.contrastText};
`;

export const HeroContainer = styled.section`
  grid-area: hero;
`;

export const CategoryFilterMobileContainer = styled.section`
  grid-area: filter-mobile;
`;

export const CategoryFilterDesktopContainer = styled.section`
  grid-area: filter-desktop;
`;

export const PostCardsContainer = styled.section`
  grid-area: posts;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 12px;
  } ;
`;

export const NewsletterContainer = styled.aside`
  grid-area: newsletter;
  display: none;
`;

export const LoadMoreContainer = styled.aside`
  grid-area: load-more;
`;
