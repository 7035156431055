import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { RefreshToken } from '@core/domain/entities/auth/refreshToken';
import { RefreshTokenRepository } from '@core/infra/repository/auth/refresh-token-repository';
import { RefreshTokenUsecase } from '@core/data/services/refresh-token-usecase';
import { RefreshTokenResponse } from '@core/domain/entities/auth/response/refresh-token-response';

export function refreshTokenFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<RefreshToken, RefreshTokenResponse> = new AxiosHttpPostClient(
    '/refreshtoken',
    {},
    environment.gatewayBFFBaseURL.url
  );
  const refreshTokenRepository = new RefreshTokenRepository(httpPostClient);
  const refreshTokenUsecase = new RefreshTokenUsecase(refreshTokenRepository);

  return refreshTokenUsecase;
}
