import { PageLoadAuthLoginTechSpec as AuthLoginPageLoadTagsWithoutOrigin } from './page-load-tags';
import {
  ActionEventTags as AuthLoginActionsTagsWithoutOrigin,
  ActionsAuthLoginLabelCreators as AuthLoginLabelCreators,
} from './action-event-tags';
import { registerAnalyticsEvent } from '@workspace/web/analytics';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const LoginTags = (options: { origin?: string; product?: string }) => {
  const AuthLoginActionsTags = AuthLoginActionsTagsWithoutOrigin(options);
  const AuthLoginPageLoadTags = AuthLoginPageLoadTagsWithoutOrigin(options);

  return {
    ACCOUNT_ACCESS: {
      LOAD: () => RAE(AuthLoginPageLoadTags.P1),
      FORGOT_PASSWORD: () => RAE(AuthLoginActionsTags.P1E1()),
      BACK: () => RAE(AuthLoginActionsTags.P1E2()),
      CONTINUE: () => RAE(AuthLoginActionsTags.P1E3()),
      REGISTER: () => RAE(AuthLoginActionsTags.P1E4()),
      VERIFICATION_ERROR: (error: string) => RAE(AuthLoginActionsTags.P1E5(AuthLoginLabelCreators.P1E5(error))),
    },
    CHANGE_PASSWORD_EMAIL: {
      LOAD: () => RAE(AuthLoginPageLoadTags.P2),
      BACK: () => RAE(AuthLoginActionsTags.P2E1()),
      SEND: () => RAE(AuthLoginActionsTags.P2E2()),
    },
    CHANGE_PASSWORD_EMAIL_SENT: { LOAD: () => RAE(AuthLoginPageLoadTags.P3) },
    CHANGE_PASSWORD_NEW_PASSWORD: {
      LOAD: () => RAE(AuthLoginPageLoadTags.P4),
      BACK: () => RAE(AuthLoginActionsTags.P4E1()),
      NEXT: () => RAE(AuthLoginActionsTags.P4E2()),
    },
    CHANGE_PASSWORD_SUCCESS: {
      LOAD: () => RAE(AuthLoginPageLoadTags.P5),
      SEEN_SUCCESS: () => RAE(AuthLoginActionsTags.P5E1()),
      LOGIN: () => RAE(AuthLoginActionsTags.P5E2()),
    },
  };
};
