import { AlertWeb, ItauIcon } from '@workspace/frontend/shared/presentation/components';
import React from 'react';

interface AlertProps {
  show: boolean;
  message: string;
}

export function AlertProduct({ show, message }: AlertProps) {
  return(
    <AlertWeb
      show={show}
      children={message}
      severity={'error'}
      variant={'standard'}
      icon={<ItauIcon iconName="exclamacao" ariaHidden={true} />}  />
  );
}
