import { styled as styledMui, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { InputWebProps } from '../../models/input-web-props';
import React, { useState } from 'react';

export const ToggleVisibleButton = styled.button`
  font-size: 16px;
  padding: 3px 6px;
  color: #000;
  line-height: 20px;
  background-color: transparent;
  border: unset;
  cursor: pointer;
`;

const CustomInput = ({
  dataTestID,
  onChange,
  onBlur,
  name,
  value,
  variant = 'outlined',
  label,
  ...otherProps
}: InputWebProps) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const renderEndAdornment = (): JSX.Element => (
    <ToggleVisibleButton type="button" onClick={() => setVisiblePassword((oldProps) => !oldProps)}>
      {visiblePassword ? 'ocultar' : 'mostrar'}
    </ToggleVisibleButton>
  );

  return (
    <TextField
      {...otherProps}
      inputProps={{
        'data-testid': dataTestID,
        'aria-label': label,
      }}
      type={visiblePassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: renderEndAdornment(),
      }}
      label={label}
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      value={value}
      variant={variant}
    />
  );
};

export const PasswordMui = styledMui(CustomInput)(({ theme }) => ({
  width: '100%',
  root: {
    width: '100%',
  },
}));
