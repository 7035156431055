import React from 'react';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '../../models/default-typography';

const renderMarkdown = (text: string) => {
  const splittedTextByMarkdown = text?.split('**');
  const toHTML = splittedTextByMarkdown?.map((element) => {
    if (text.includes(`**${element}**`)) {
      return <strong>{element}</strong>;
    }
    return element;
  });
  return toHTML;
};

export function TypographyMui({
  className,
  component,
  children,
  color,
  align,
  id,
  tabIndex,
  ariaLabel,
  ariaHiden = false,
  style,
  ariaHidenChildren = false,
}: TypographyProps) {
  return (
    <Typography
      align={align}
      aria-label={ariaLabel}
      aria-hidden={ariaHiden}
      className={className}
      color={color}
      id={id}
      tabIndex={tabIndex}
      variant={component}
      style={style}
    >
      <span aria-hidden={ariaHidenChildren} style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
        {renderMarkdown(children)}
      </span>
    </Typography>
  );
}
