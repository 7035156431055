import { MouseEventHandler } from 'react';
import { Line } from '../..';
import { ButtonStyled, ReadMoreWrapper, TypographyWebStyled } from './ReadMore-styles';

export interface ReadMoreProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title: string;
}
export const ReadMore = ({ onClick, title }: ReadMoreProps) => {
  return (
    <ReadMoreWrapper>
      <Line />
      <ButtonStyled data-testid="readmore-button" onClick={onClick}>
        <TypographyWebStyled>{title}</TypographyWebStyled>
      </ButtonStyled>
      <Line />
    </ReadMoreWrapper>
  );
};
