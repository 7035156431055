import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import {
  AddressComplementField,
  AddressField,
  AddressNeighborhoodField,
  AddressNumberField,
  CepField,
  CityField,
  StateField,
} from '@web/form/component';
import styled from 'styled-components';
import { gridTemplateBase } from '../constants/grid-template-base';
import { GridTemplateArea } from '@workspace/frontend/auto/presentation/components';
import { GetAddressByCepUseCaseFactory } from '../../../../../factories/get-address-by-cep-usecase-factory';
import React, { useEffect } from 'react';
import { useFormControllerMethods } from '@web/form';
import { Link } from '../../vehicle-address-step/auto-address-step.style';

const HolderAddressGridTemplate = styled.div`
  ${gridTemplateBase}
  grid-template-rows: 50px 100px repeat(3, 84px);
  grid-template-areas:
    'title title'
    'cep .'
    'address addressNumber'
    'addressComplement addressNeighborhood'
    'city state';
  h2 {
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    grid-template-rows: 100px 100px repeat(6, 84px);
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'cep'
      'address'
      'addressNumber'
      'addressComplement'
      'addressNeighborhood'
      'city'
      'state';
  }
`;

const SEARCH_CEP_URL = 'https://buscacepinter.correios.com.br/app/endereco/index.php?t';

export function HolderAddress({ addressCep }) {
  const { setValue, setError, clearErrors } = useFormControllerMethods();

  useEffect(() => {
    getAddressByCep(addressCep);
  }, []);

  async function getAddressByCep(cep: string) {
    try {
      const { city, district, state, street } = await GetAddressByCepUseCaseFactory.instance.execute({
        cep,
      });

      clearErrors('cep');
      setValue('address', street);
      setValue('addressNeighborhood', district);
      setValue('city', city);
      setValue('state', state);
    } catch (err) {
      setError('cep', {
        message: err.message,
      });
    }
  }

  return (
    <HolderAddressGridTemplate>
      <GridTemplateArea area="title">
        <BoxWeb display={'flex'} justifyContent={{ xs: 'center', md: 'start' }}>
          <BoxWeb
            component={'h2'}
            aria-label="endereço do titular"
            id="lblQuotation_screenQuotation"
            textAlign={{ xs: 'center', md: 'left' }}
            width={'100%'}
          >
            <span aria-hidden>
              endereço<strong> do titular</strong>
            </span>
          </BoxWeb>
        </BoxWeb>
      </GridTemplateArea>

      <GridTemplateArea area="cep">
        <CepField onBlur={(e) => getAddressByCep(e.target.value)} />
        <Link
          tabIndex={0}
          rel="noopener noreferrer"
          target="_blank"
          href={SEARCH_CEP_URL}
          id="lnkZipCode_screenAddress"
        >
          não sei meu CEP
        </Link>
      </GridTemplateArea>

      <GridTemplateArea area="address">
        <AddressField />
      </GridTemplateArea>

      <GridTemplateArea area="addressNumber">
        <AddressNumberField />
      </GridTemplateArea>

      <GridTemplateArea area="addressComplement">
        <AddressComplementField />
      </GridTemplateArea>

      <GridTemplateArea area="addressNeighborhood">
        <AddressNeighborhoodField />
      </GridTemplateArea>

      <GridTemplateArea area="city">
        <CityField />
      </GridTemplateArea>

      <GridTemplateArea area="state">
        <StateField />
      </GridTemplateArea>
    </HolderAddressGridTemplate>
  );
}
