import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function StepperWeb(props) {
  const { Components } = useThemeProvider();
  const { Stepper } = Components;

  return (
    <ThemeProviderInjector>
      <Stepper {...props} />
    </ThemeProviderInjector>
  );
}
