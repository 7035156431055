import { IFindUserByCPFRepository } from '@core/data/contracts/auth/find-user-by-cpf-repository';
import { CPFResponse } from '@core/domain/entities/auth/response/cpf';
import { UserByCPF } from '@core/infra/models/user-by-cpf';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';

export class FindUserByCPFRepository implements IFindUserByCPFRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<UserByCPF, CPFResponse>) {}

  async execute(cpf: string, headers?: any): Promise<CPFResponse> {
    return await this.httpPostClient.post({ document: cpf }, null, headers);
  }
}
