import { LinearProgress, Step, StepButton, StepLabel, Stepper, withStyles } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import StepConnector from '@material-ui/core/StepConnector';
import { useWindowWidth } from './use-window-width';
import { Step as StepProps } from './step';

const CustomStepper = withStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
}))(Stepper);

const CustomConnector = withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: 'transparent',
      fontFamily: theme.typography.fontWeightBold,
    },
  },
  completed: {
    '& $line': {
      borderColor: 'transparent',
    },
  },
  line: {
    borderColor: 'transparent',
    borderTopWidth: 0,
    borderRadius: 0,
  },
}))(StepConnector);

interface DefaultStepperProps {
  steps: StepProps[];
  activeStep?: number;
}

export function StepperDesktop({ steps, activeStep }: DefaultStepperProps) {
  const width = useWindowWidth();
  const stepperRef = useRef(undefined);

  useEffect(() => {
    setTimeout(() => {
      if (stepperRef.current) stepperRef.current.focus();
    }, 100);
  }, [activeStep]);

  const isActiveStep = (index) => index === activeStep;
  let stepCount = 0;

  const getWizardStepAriaLabel = (step, index) => {
    stepCount += 1;

    if (index < activeStep) {
      return `ir para ${step.title}, etapa ${stepCount} de ${steps.length}`;
    }

    return `${step.title}, etapa ${stepCount} de ${steps.length}${isActiveStep(index) ? ', etapa atual' : ''}`;
  };

  const handleProgressValue = () => {
    const maxWidth = 1104;
    if (width <= maxWidth) {
      return ((activeStep + 1) / steps.length) * 100;
    }

    const progressPlace = (maxWidth / steps.length) * (activeStep + 1);

    return (((width - maxWidth) / 2 + progressPlace) * 100) / width;
  };

  return (
    <>
      <CustomStepper connector={<CustomConnector />} activeStep={activeStep} id={'stepper'}>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepButton
                aria-label={getWizardStepAriaLabel(step, index)}
                onClick={step?.onClick}
                disabled={index > activeStep}
                ref={isActiveStep(index) ? stepperRef : undefined}
              >
                <StepLabel aria-hidden>{step?.title || 'custom title'}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </CustomStepper>
      <LinearProgress color="primary" variant="determinate" aria-hidden value={handleProgressValue()} />
    </>
  );
}
