import { createContext, useContext, useState } from 'react';
import { RouteEnum } from '../enums/route-enum';

const AuthenticationParamsContext = createContext(null);

type VoidFunction = (params?) => void | undefined;

export type AuthenticationParamsProviderResponse = {
  registerSuccessLoginCallback: VoidFunction;
  registeredSuccessLoginCallback: VoidFunction;
  registerLoginURLBase: VoidFunction;
  getRegisteredLoginURLBase: () => string;
};

const AUTH_LOGIN_BASEURL_KEY = 'auth:baseurl';
export function AuthenticationParamsProvider({ children }) {
  const [registeredSuccessLoginCallback, setRegisteredSuccessLoginCallback] = useState<VoidFunction>();

  const getRegisteredLoginURLBase = () => {
    const cachedLoginURLBase = getCachedLoginURLBase();
    return cachedLoginURLBase || RouteEnum.LOGIN;
  };

  const getCachedLoginURLBase = () => {
    return sessionStorage.getItem(AUTH_LOGIN_BASEURL_KEY);
  };

  const registerLoginURLBase = () => {
    sessionStorage.setItem(AUTH_LOGIN_BASEURL_KEY, window.location.pathname);
  };

  const registerSuccessLoginCallback = (callback: VoidFunction) => {
    setRegisteredSuccessLoginCallback(null);
  };

  return (
    <AuthenticationParamsContext.Provider
      value={{
        registerSuccessLoginCallback,
        registeredSuccessLoginCallback,
        registerLoginURLBase,
        getRegisteredLoginURLBase,
      }}
    >
      {children}
    </AuthenticationParamsContext.Provider>
  );
}

export function useAuthenticationParams(): AuthenticationParamsProviderResponse {
  return useContext(AuthenticationParamsContext);
}
