import { Alert } from '@material-ui/lab';
import { DefaultAlertProps } from '../../models/default-alert';

export const AlertMui = ({ children, severity, variant, icon, onClose, ...props }: DefaultAlertProps) => {
  return (
    <Alert severity={severity} variant={variant} icon={icon} onClose={onClose}>
      {children}
    </Alert>
  );
};
