import { FormControllerFieldConnector } from '@web/form';
import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const AddressField = ({ disabled, label = 'endereço' }: { disabled?: boolean; label?: string }) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name="address"
    disabled={disabled}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar o endereço' })}
    componentProps={{
      label,
      id: 'txtAdress_screenAddress',
    }}
  />
);
