import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { RecoverPasswordResponse } from '@core/domain/entities/auth/response/recover-password-response';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { RecoverPasswordRepository } from '@core/infra/repository/auth/recover-password-repository';
import { RecoverPasswordUsecase } from '@core/data/services/recover-password-usecase';

export function recoveryPasswordFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<{ email: string }, RecoverPasswordResponse> = new AxiosHttpPostClient<
    { email: string },
    RecoverPasswordResponse
  >('recuperarsenha/envioemail', {}, environment.gatewayBFFBaseURL.url);

  const recoverPasswordRepository = new RecoverPasswordRepository(httpPostClient);
  const recoverPasswordUsecase = new RecoverPasswordUsecase(recoverPasswordRepository);
  return recoverPasswordUsecase;
}
