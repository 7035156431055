import { BrowserRouter, Switch } from 'react-router-dom';
import { AutoV2LocalHiringRouter, AutoV2Hiring, AutoV2Success } from './route/local-hiring-router';
import { AnalyticsInjector } from '@workspace/web/analytics';
export function TemporaryFeatFlag({ prefixpath, partners }) {
  return <InsuranceHiringAuto prefixpath={prefixpath} partners={partners}></InsuranceHiringAuto>;
}

const parsePrefixPath = ({ prefixpath, partners }) => {
  if (partners === 'superapp' || partners === 'superapppersonnalite' || partners === 'superapppersonalite') {
    const prefixPathSplitted = prefixpath.split('/');
    return prefixpath.indexOf('#/') > 0 ? prefixpath : addHash(prefixPathSplitted);
  }
  return prefixpath;
};
const addHash = (pathArray) => {
  const last = pathArray.length - 1;
  pathArray[last] = `#/${pathArray[last]}`;
  return pathArray.join('/');
};

export function InsuranceHiringAuto({ prefixpath, partners }) {
  prefixpath = parsePrefixPath({ prefixpath, partners });

  return (
    <>
      <AnalyticsInjector />
      <BrowserRouter>
        <Switch>
          <AutoV2LocalHiringRouter prefixPath={prefixpath} origin={partners} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export function InsuranceHiringAutoSuperApp({ prefixpath, partners }) {

  prefixpath = parsePrefixPath({ prefixpath, partners });

  return (
    <>
      <AnalyticsInjector />
      <BrowserRouter>
        <Switch>
          <AutoV2Hiring origin={partners} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export function InsuranceHiringSuccessAutoSuperApp({ prefixpath, partners }) {

  prefixpath = parsePrefixPath({ prefixpath, partners });

  return (
    <>
      <AnalyticsInjector />
      <BrowserRouter>
        <Switch>
          <AutoV2Success origin={partners} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
