export class Email {
  private readonly _value;

  constructor(email: string) {
    this._value = email;
  }

  static REGEX = /^[a-z0-9.+%_-]{1,64}@[a-z0-9.-]{1,255}\.[a-z]{2,}$/;

  get value(): string {
    return this._value;
  }

  get isValid(): boolean {
    return Email.REGEX.test(this._value);
  }
}
