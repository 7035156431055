import styled from 'styled-components';

const Title = styled.h1`
  font-size: 28px;
  color: #2b374a;
  font-family: 'ItauDisplay-Bold';
  font-weight: normal;
  margin-right: 10px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    width: 11px;
    height: 15px;
    background: rgb(236, 112, 0);
    display: flex;
    margin-right: 10px;
  }
`;
const Separator = styled.div`
  @media (min-width: 960px) {
    border-left: 2px solid #ec7000;
    height: 23px;
    margin: 0 20px 0 25px;
  }
`;
const Subtitle = styled.p`
  font-size: 18px;
  color: #2b374a;
  font-family: 'ItauText-Regular';
  font-weight: normal;
  margin-left: 10px;

  @media (max-width: 360px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
const TitleContainer = styled.div`
  margin: 20px 0 5px;
  @media (min-width: 960px) {
    display: flex;
    align-items: center;
  }
`;

const PageTitle = ({ title, subtitle }: any) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      <Separator />
      <Subtitle> {subtitle} </Subtitle>
    </TitleContainer>
  );
};

export default PageTitle;
