import { KEY_CODES } from './constants';
import { SELECTOR_DATA_ATTR } from './constants';

const selectElement = (selector: string) => document.querySelector<HTMLElement>(selector);

const focusListItem = (listItems: NodeListOf<Element>, direction: number | Array<number>, event) => {
  const selectorAttr: string = SELECTOR_DATA_ATTR;
  const listItemsArray: Array<Element> = Array.from(listItems);

  const activeElement: string = document.activeElement.getAttribute(selectorAttr);
  const listItemElements: Array<string> = listItemsArray.map((element) => element.getAttribute(selectorAttr));

  const currentActiveElementIndex: number = listItemElements.indexOf(activeElement);

  const focusNextItem = () => {
    const currentActiveElementIsNotLastItem = currentActiveElementIndex < listItemElements.length - 1;

    if (currentActiveElementIsNotLastItem) {
      const nextListItemElement = listItemElements[currentActiveElementIndex + 1];

      const querySelector = `[${selectorAttr}=${nextListItemElement}]`;
      selectElement(querySelector).focus();
    } else {
      const querySelector = `[${selectorAttr}=${listItemElements[0]}]`;
      selectElement(querySelector).focus();
    }

    event.preventDefault();
  };

  const focusPreviousItem = () => {
    const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0;

    if (currentActiveElementIsNotFirstItem) {
      const nextListItemId = listItemElements[currentActiveElementIndex - 1];
      const querySelector = `[${selectorAttr}=${nextListItemId}]`;
      selectElement(querySelector).focus();
    } else {
      const querySelector = `[${selectorAttr}=${listItemElements[listItemElements.length - 1]}]`;
      selectElement(querySelector).focus();
    }

    event.preventDefault();
  };

  if (direction === KEY_CODES.DOWN_ARROW) focusNextItem();
  if (direction === KEY_CODES.UP_ARROW) focusPreviousItem();
};

export const keyboardControlEvents = (items, callback) => {
  const keydownEvent = (event: any) => {
    switch (event.keyCode) {
      case KEY_CODES.DOWN_ARROW:
        return focusListItem(items, KEY_CODES.DOWN_ARROW, event);

      case KEY_CODES.UP_ARROW:
        return focusListItem(items, KEY_CODES.UP_ARROW, event);

      case KEY_CODES.ESCAPE:
        return callback();

      default:
        return;
    }
  };

  items.forEach((item) => item.addEventListener('keydown', keydownEvent));
};
