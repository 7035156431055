import { AlertWeb, BoxWeb, ButtonWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { PasswordField } from '@web/form/component';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { PasswordStatus, RecaptchaField } from '../../../../components';
import { sendRecoveryPasswordFactory } from '../../../../factories/send-recovery-password-factory';
import { RegisterContainer, Title, TitleBold } from '../../../style';
import useErrorProvider from '../../../../providers/error-provider';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { RecaptchaActions } from '../../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../../providers/auth-product-provider';

export const FormData = ({ hash, passwordCallback }) => {
  const { Theme } = useThemeProvider();
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const {
    environment: { recaptchaSiteKey, recaptchaV2SiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.CHANGE_PASSWORD_NEW_PASSWORD;

  const [sending, setSending] = useState<boolean>(false);
  const [missedRulesComponent, setMissedRulesComponent] = useState<JSX.Element | null>(null);

  const handleCloseAlert = () => setRequestError({ show: false });

  const handleRequestError = (errorData) => {
    setSending(false);
    passwordCallback({ success: errorData.success });

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });
  };

  const sendRecoveryPasswordUsecase = sendRecoveryPasswordFactory();

  const handleSubmit = async ({ password }) => {
    analyticsAuth.NEXT();

    try {
      setSending(true);

      const recaptcha = await getRecaptchaToken(RecaptchaActions.REGISTER_NEW_PASSWORD, recaptchaSiteKey);

      const payload = { password, hash };
      const response = await sendRecoveryPasswordUsecase.execute(payload, {
        recaptcha: JSON.stringify(recaptcha),
      });

      setSending(false);

      if (response?.data.success) {
        passwordCallback({ success: response.data.success, username: response?.data.value.user });
        // TODO: here, probably
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const initialValues = {
    password: '',
  };

  useEffect(() => {
    analyticsAuth.LOAD();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        onClose={handleCloseAlert}
        children={requestError.message}
        secondsToClose={16}
      />
      <RegisterContainer>
        <FormController validationMode="onBlur" initialValues={initialValues} onSubmit={handleSubmit} hideErrors>
          {({ watch, clearErrors, trigger, setValue }) => {
            const [passwordField, confirmPasswordField] = watch(['password', 'confirm_password']);

            const handlePasswordChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
              value?.length && clearErrors('password');

            const handlePasswordBlur = () =>
              !confirmPasswordField ? trigger('password') : trigger(['password', 'confirm_password']);

            const handleClickContinue = () => {
              if (!passwordField) return;

              setSending(true);
              setRequestError({ show: false });

              if (!missedRulesComponent) return setSending(false);

              setTimeout(() => {
                setRequestError({
                  message: missedRulesComponent,
                  show: true,
                  type: 'warning',
                });

                setSending(false);
              }, 500);
            };

            const handleRecaptchaCallback = (token: string) => setValue('recaptcha', token);

            const confirmPasswordValidation = {
              requiredMessage: 'você precisa confirmar a senha',
              test: [
                {
                  name: 'passwords-match',
                  message: 'as senhas precisam ser iguais',
                  test: function (value) {
                    return this.parent.password === value;
                  },
                },
              ],
            };

            return (
              <>
                <BoxWeb marginBottom="8px">
                  <Title>quase lá!</Title>
                </BoxWeb>

                <BoxWeb marginBottom="45px">
                  <TitleBold>escolha uma nova senha</TitleBold>
                </BoxWeb>

                <BoxWeb marginBottom="24px">
                  <BoxWeb marginBottom="8px" fontSize="18px" lineHeight="23px">
                    a sua senha deve:
                  </BoxWeb>

                  <PasswordStatus value={passwordField} missedRulesComponent={setMissedRulesComponent} />
                </BoxWeb>

                <BoxWeb maxWidth="400px" marginBottom="32px">
                  <PasswordField
                    componentProps={{
                      label: 'digite sua senha',
                    }}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                  />
                </BoxWeb>

                <BoxWeb maxWidth="400px" marginBottom="32px">
                  <PasswordField
                    componentProps={{
                      label: 'confirme sua senha',
                    }}
                    prefix="confirm"
                    validationOverrides={confirmPasswordValidation}
                  />
                </BoxWeb>

                <BoxWeb maxWidth="400px" marginBottom="32px">
                  <RecaptchaField sitekey={recaptchaV2SiteKey} onChange={handleRecaptchaCallback} />
                </BoxWeb>

                <BoxWeb maxWidth="188px">
                  <ButtonWeb
                    type="submit"
                    label="continuar"
                    disabled={sending}
                    loading={sending}
                    onClick={handleClickContinue}
                  />
                </BoxWeb>
              </>
            );
          }}
        </FormController>
      </RegisterContainer>
    </ThemeProvider>
  );
};
