import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const ChassisField = ({
  id,
  disabled,
  label = 'chassi',
}: {
  disabled?: boolean;
  id?: string;
  label?: string;
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name="chassis"
    helperText={'campo com 17 caracteres'}
    disabled={disabled}
    validation={stringValidationFactory({
      length: { limit: 17, message: 'o chassi deve ter 17 caracteres' },
      requiredMessage: 'você precisa informar o número do chassi',
    })}
    componentProps={{
      label,
      id,
      maskType: '*****************',
    }}
  />
);
