import styled, { ThemeProvider as SCThemeProvider } from 'styled-components';
import { BoxWeb, ItauIcon, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { Product } from '@workspace/frontend/faq-v2/domain/entities';

export const HorizontalList = ({
  items = [],
  onClick,
  selectedCard,
  activeBullet,
  handleActivedBullet,
  bulletActived,
  amountBullets,
}: {
  items: Product[];
  onClick: any;
  selectedCard: Product;
  activeBullet: number;
  handleActivedBullet: (bullet: number) => void;
  bulletActived: any;
  amountBullets: number;
}) => {
  const { Theme } = useThemeProvider();
  function HorizontalListBullet(props: { id: number; activeBullet: number; onClick: () => void; bulletActived: any }) {
    return (
      <button
        id={'bullet_' + props.id}
        className={`bullet${props.activeBullet === props.id ? '_active' : ''}`}
        onClick={props.onClick}
        ref={props.activeBullet === props.id ? props.bulletActived : null}
      />
    );
  }

  function setBullets() {
    const bullets = [];
    for (let i = 0; i < amountBullets; i++) {
      const bullet = (
        <HorizontalListBullet
          id={i}
          activeBullet={activeBullet}
          bulletActived={bulletActived}
          onClick={() => handleActivedBullet(i)}
          key={i}
        />
      );
      bullets.push(bullet);
    }

    return bullets;
  }

  return (
    <SCThemeProvider theme={Theme}>
      <BoxWeb className="products-section" display="flex" justifyContent="center" alignItems="center" maxWidth="100vw">
        <BoxWeb
          className="box-back-arrow"
          width="184px"
          height="163px"
          display="flex"
          justifyContent="end"
          aria-hidden={true}
        >
          <BackArrow className="back-arrow" onClick={() => handleActivedBullet(activeBullet - 1)}>
            <ItauIcon iconName={'seta'} style={{ fontSize: 38, color: '#EC7000' }} />
          </BackArrow>
        </BoxWeb>

        <ProductsList className="products-list">
          {items.map((item, i) => {
            return (
              <CardContainer className="card-box" key={item.uid}>
                <Card
                  className="card"
                  aria-label={
                    item.titulo +
                    ', card ' +
                    (i + 1) +
                    ' de ' +
                    items.length +
                    (item.uid === selectedCard?.uid ? ' selecionado' : '')
                  }
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  <CardImg src={item.logo.path} alt={''} />
                  <CardLabel selected={item.uid === selectedCard?.uid}>{item.titulo}</CardLabel>
                  <CardMark selected={item.uid === selectedCard?.uid} />
                </Card>
              </CardContainer>
            );
          })}
        </ProductsList>

        <BoxWeb
          className="box-advance-arrow"
          width="184px"
          height="163px"
          display="flex"
          justifyContent="start"
          aria-hidden={true}
        >
          <AdvanceArrow
            className="advance-arrow"
            onClick={() => handleActivedBullet(activeBullet + 1)}
            style={{ transform: 'rotate(180deg)' }}
          >
            <ItauIcon iconName={'seta'} style={{ fontSize: 38, color: '#EC7000' }} />
          </AdvanceArrow>
        </BoxWeb>
      </BoxWeb>
      <ScrollBullet className="scrollball" aria-hidden={true}>
        {setBullets()}
      </ScrollBullet>
    </SCThemeProvider>
  );
};

interface CardSelectedProps {
  selected: boolean;
}

const BackArrow = styled.button`
  border: none;
  padding-right: 23px;
  background-color: transparent;
  cursor: pointer;
`;

const ProductsList = styled.ul`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 14px;
  width: 100vw;
  max-width: 1072px;

  ::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
`;

const CardContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  scroll-snap-align: start;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    padding: 4px 0 4px 11px;

    &:last-child {
      padding-right: 4px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 4px 0 4px 32px;

    &:last-child {
      padding-right: 4px;
    }
  }
`;

export const Card = styled.button`
  width: 176px;
  height: 140px;
  padding: 0 48px;
  border-radius: 20px;
  border: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 120px;
    height: 96px;
    border-radius: 13.73px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 98px;
    height: 78px;
    border-radius: 11.1px;
  }
`;

const CardImg = styled.img`
  width: 48px;
  height: 48px;
  pointer-events: none;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 33px;
    height: 33px;
    margin-top: 13px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 27px;
    height: 27px;
    margin-top: 11px;
  }
`;

const CardLabel = styled.span<CardSelectedProps>`
  width: 100px;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  color: #2b374a;
  text-transform: lowercase;
  margin-top: 6px;
  transition: 0.5s;
  font-family: ${(props) => (props.selected ? 'ItauDisplay-Bold' : 'ItauDisplay-Light')};
  font-weight: ${(props) => (props.selected ? 800 : 300)};
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 12px;
    line-height: 15px;
    width: 65px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 10px;
    line-height: 13px;
    width: 55px;
  }
`;

const CardMark = styled.div<CardSelectedProps>`
  height: 4px;
  width: 97px;
  transition: 0.5s;
  margin-top: auto;
  background-color: #ec7000;
  opacity: ${(props) => (props.selected ? 1 : 0)};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 67px;
    height: 2px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 54px;
    height: 2px;
  }
`;

const ScrollBullet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .bullet {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    border: none;
    background-color: #56504c;
    margin-left: 6px;
  }

  .bullet_active {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: none;
    background-color: #106eb0;
    margin-left: 6px;
  }
`;

const AdvanceArrow = styled.button`
  border: none;
  padding-right: 23px;
  background-color: transparent;
  cursor: pointer;
`;
