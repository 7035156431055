import { useEffect, useRef, useState } from 'react';
import {
  BoxWeb,
  ButtonWeb,
  DialogTitleWeb,
  LinkWeb,
  ModalWeb,
  OutlinedButtonWeb,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import {
  ActionButtonTopWrapper,
  AdvantageContent,
  AdvantageIconBorder,
  AdvantageItem,
  AdvantageTitle,
  BoldCoveragesTitle,
  BuildImg,
  CallToActionTitle,
  CallToActionTop,
  CarImg,
  CloudImg,
  CoverageImageItem,
  CoverageItem,
  CoverageItemModal,
  CoverageTextItemContent,
  CoverageTextItemTitle,
  CoverageTextItemWrapper,
  FillBackground,
  FillBackground2,
  FillBackgroundImg,
  FlowerImg,
  HomeBackground,
  ModalContent,
  NormalCoveragesTitle,
  NormalTitle,
  PartnersImg,
  PartnersSmallImg,
  PartnersTitle,
  PeopleImg,
  SectionAdvantages,
  SectionBuild,
  SectionCar,
  SectionClouds,
  SectionCoverages,
  SectionCoveragesCards,
  SectionCoveragesContent,
  SectionPartners,
  SectionSeeCoverages,
  SectionTitle,
  SeeCoveragesDetails,
  UserImg,
} from './styles';
import { advantageItems } from './parameters';
import { autov2Images } from '@web/assets';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { getOffersFactory } from '../../factories/get-offers-factory';
import { OfferAssist, OfferCoverage } from '@core/data/models/autov2/response/offer-response';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { useOriginContext } from '../../providers/origin-provider';

function HomeWrapper() {
  const [open, setOpen] = useState(false);
  const [coverageItems, setCoverageItems] = useState<OfferCoverage[]>([]);
  const linkShowCoveragesRef = useRef(null);
  const { Theme } = useThemeProvider();
  const getOffersUseCase = getOffersFactory();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);

  const translateToMicrofrontendPath = (imagePath) =>
    `${partialEnvironment.environment?.evolutionCloudfrontBase}/${imagePath}`;

  const handleGetOffers = async () => {
    try {
      const offersResponse = await getOffersUseCase.execute();

      if (offersResponse) {
        const joinOffers = offersResponse.coverages.concat(offersResponse.assistences);
        setCoverageItems(joinOffers);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const mountHiringRouteRedirect = () => {
    const lastHrefCaractere = window.location.pathname.substr(-1);
    let pathnameDivisor = '';
    if (lastHrefCaractere !== '/') {
      pathnameDivisor = '/';
    }
    return `${window.location.origin}${window.location.pathname}${pathnameDivisor}contratacao${window.location.search}`;
  };

  const handleClickAction = () => {
    window.location.href = mountHiringRouteRedirect();
  };

  const analyticsEventButtonTop = () => {
    autoV2analyticsCalls.HOME.CONTINUE();
    handleClickAction();
  };

  const analyticsEventButtonBottom = () => {
    autoV2analyticsCalls.HOME.CONTINUE_2();
    handleClickAction();
  };

  const titleRef = useRef(undefined);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
    autoV2analyticsCalls.HOME.LOAD();
    handleGetOffers().then(() => ({}));
  }, []);

  const handleOpen = () => {
    setOpen(true);
    autoV2analyticsCalls.HOME.DETAIL();
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      linkShowCoveragesRef.current.focus();
    }, 200);
  };

  return (
    <SCThemeProvider theme={Theme}>
      <FillBackground>
        <HomeBackground>
          <FillBackground2>
            <FillBackgroundImg
              src={translateToMicrofrontendPath(autov2Images.fillHome)}
              alt={'imagem de fundo'}
              aria-hidden
            />
          </FillBackground2>
          <SectionClouds>
            <CloudImg src={autov2Images.nuvem} alt={'nuvem'} aria-hidden />
            <CloudImg src={autov2Images.nuvem} alt={'nuvem'} aria-hidden />
          </SectionClouds>
          <SectionBuild>
            <SectionTitle id="lblInicialText_screenHomeAuto">
              <NormalTitle ref={titleRef} tabIndex={'-1'} style={{outline: 0}}>
                O Seguro Auto criado para você
              </NormalTitle>
            </SectionTitle>
            <BuildImg src={translateToMicrofrontendPath(autov2Images.builds)} alt={'prédios'} aria-hidden />
          </SectionBuild>
          <SectionCar>
            <CarImg src={translateToMicrofrontendPath(autov2Images.car)} alt={'carro'} aria-hidden />
            <UserImg src={translateToMicrofrontendPath(autov2Images.user)} alt={'ser humano'} aria-hidden />
            <FlowerImg src={translateToMicrofrontendPath(autov2Images.flowers)} alt={'flores'} aria-hidden />
          </SectionCar>
        </HomeBackground>
        <CallToActionTop>
          <CallToActionTitle id="lblInicialBanner_screenHomeAuto">
            se encante com esse novo jeito rápido e fácil de proteger seu carro
          </CallToActionTitle>
          <ActionButtonTopWrapper>
            <ButtonWeb
              label="cotar meu seguro"
              onClick={analyticsEventButtonTop}
              id="btnStartQuotation_screenHomeAuto"
            />
          </ActionButtonTopWrapper>
        </CallToActionTop>
        <SectionAdvantages>
          {advantageItems.map((item) => (
            <AdvantageItem>
              <AdvantageIconBorder>
                <BoxWeb color={'primary.main'} m={'0.5rem'} fontSize={'50px'}>
                  <i data-testid={`${item.icon}`} className={`${item.icon}`} aria-hidden />
                </BoxWeb>
              </AdvantageIconBorder>
              <AdvantageTitle>{item.title}</AdvantageTitle>
              <div>
                <AdvantageContent>{item.content}</AdvantageContent>
              </div>
            </AdvantageItem>
          ))}
        </SectionAdvantages>
        <SectionCoverages>
          <SectionCoveragesContent>
            <NormalCoveragesTitle id="lblCoverageDescriptions_screenHomeAuto">
              <BoldCoveragesTitle>as melhores coberturas e assistências</BoldCoveragesTitle> selecionadas para você
            </NormalCoveragesTitle>
            <PeopleImg src={translateToMicrofrontendPath(autov2Images.people)} alt={'pessoa'} aria-hidden />
          </SectionCoveragesContent>
        </SectionCoverages>
        <SectionCoveragesCards>
          {coverageItems.map((item: OfferCoverage) => (
            <CoverageItem>
              <CoverageImageItem alt={item.descricao} src={item.imagem.url} aria-hidden />
              <CoverageTextItemWrapper>
                <CoverageTextItemTitle>{item.titulo}</CoverageTextItemTitle>
                <div>
                  <CoverageTextItemContent size={'160px'}>{item.descricao}</CoverageTextItemContent>
                </div>
              </CoverageTextItemWrapper>
            </CoverageItem>
          ))}
        </SectionCoveragesCards>

        <SectionSeeCoverages>
          <BoxWeb mb={{ xs: 4, sm: 0 }} mr={{ xs: 0, sm: 4 }}>
            <LinkWeb
              ref={linkShowCoveragesRef}
              component="button"
              variant="caption"
              color="secondary"
              onClick={handleOpen}
              text="Ver detalhes de todas as coberturas e assistências"
              id="linkCoveragesHome"
            />
          </BoxWeb>
          <ModalWeb open={open}>
            <SeeCoveragesDetails>
              <DialogTitleWeb id="lead-dialog-title" onClose={handleClose}>
                estes são os detalhes de todas coberturas e assistências
              </DialogTitleWeb>
              <ModalContent>
                {coverageItems.map((item: OfferAssist) => {
                  return (
                    <CoverageItemModal modal>
                      <CoverageImageItem src={item.imagem.url} aria-hidden />
                      <CoverageTextItemWrapper>
                        <CoverageTextItemTitle>{item.titulo}</CoverageTextItemTitle>
                        <CoverageTextItemContent size={'280px'}>{item.descricao}</CoverageTextItemContent>
                      </CoverageTextItemWrapper>
                    </CoverageItemModal>
                  );
                })}
                <BoxWeb p={'2rem'}>
                  <OutlinedButtonWeb label="fechar" onClick={handleClose} />
                </BoxWeb>
              </ModalContent>
            </SeeCoveragesDetails>
          </ModalWeb>
          <ActionButtonTopWrapper>
            <ButtonWeb
              label="cotar meu seguro"
              onClick={analyticsEventButtonBottom}
              id="btnStartQuotation_screenHomeAuto_1"
            />
          </ActionButtonTopWrapper>
        </SectionSeeCoverages>
        <SectionPartners>
          <PartnersTitle>Nossos parceiros são referências no mercado</PartnersTitle>
          <BoxWeb zIndex={1}>
            <PartnersImg src={autov2Images.logoSeguradora} alt={'logo porto seguro'} />
            <PartnersSmallImg src={autov2Images.logoAzul} alt={'logo azul seguros'} />
          </BoxWeb>
        </SectionPartners>
      </FillBackground>
    </SCThemeProvider>
  );
}

export const Home = HomeWrapper;
