import { Immutable } from 'immer';
import { AnyAction } from 'redux';
import { authInitialValues } from './authInitialValues';
import authReducerHandlers from './auth/authReducerHandlers';

function authReducer(state = authInitialValues, action: AnyAction) {
  const handlers = Object.assign({}, authReducerHandlers);

  const reducer: Immutable<any> = Object.entries(handlers).reduce((acc, [key, value]) => {
    return key === action.type ? value : acc;
  }, null);

  if (reducer) return reducer(state, action);
  return state;
}

export default authReducer;
