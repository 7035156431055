import { ItauIcon } from '@workspace/frontend/shared/presentation/components';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MissingRules } from './missing-rules';

type Rule = {
  label: string;
  rule: (value: string) => boolean;
  aria?: string;
};

type PasswordStatusProps = {
  rules?: Array<Rule>;
  toggleValidation?: boolean;
  missedRules?: (missed: Array<Omit<Rule, 'rule'>>) => void;
  missedRulesComponent?: (missedComponent: JSX.Element) => void;
  value: string;
};

type TPasswordStatusListItem = {
  status?: boolean;
};

const defaultRules = [
  {
    label: 'ter número',
    rule: (value: string) => /[0-9]/g.test(value),
  },
  {
    label: 'ter letra minúscula e maiúscula',
    rule: (value: string) => /[a-z]/g.test(value) && /[A-Z]/g.test(value),
  },
  {
    label: 'ter, no mínimo, 8 caracteres',
    rule: (value: string) => value?.length > 7,
  },
  {
    label: 'não ter número sequencial e 3 caracteres repetidos em sequência',
    rule: (value: string) => {
      const numberPattern = /(012|123|234|345|456|567|678|789)/;
      const lowerCaseAlphabetPattern =
        /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/;
      const upperCaseAlphabetPattern =
        /(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ)/;

      return (
        Boolean(value?.length > 7) &&
        !/(.)\1{2,}/g.test(value) &&
        !numberPattern.test(value) &&
        !lowerCaseAlphabetPattern.test(value) &&
        !upperCaseAlphabetPattern.test(value)
      );
    },
  },
  {
    aria: 'ter caractere especial, exceto barra invertida, asterisco, aspas, trema, sinal de menor, sinal de maior, vírgula, ponto final, apóstrofo, parênteses esquerdo, parênteses direito, barra',
    label: 'ter caractere especial, exceto \\ * " ¨ < > , . \' () /',
    rule: (value: string) => !/[\\*"¨<>,.'()/]/g.test(value) && /[!#$%&\+\-\:;=?@\[\]^_`\{|\}~]/g.test(value),
  },
];

export const PasswordStatus = ({
  value: passwordValue,
  rules,
  missedRules,
  missedRulesComponent,
}: PasswordStatusProps) => {
  const [internalMissingRules, setInternalMissingRules] = useState<Array<Omit<Rule, 'rule'>>>([]);

  const setMissingRules = useCallback(() => {
    if (!rules.length) return;

    const missed = rules
      .filter((item) => !item.rule(passwordValue))
      .map((item) => ({
        label: item.label,
        aria: item?.aria,
      }));

    setInternalMissingRules(missed);
  }, [passwordValue]);

  useEffect(setMissingRules, [passwordValue]);

  useEffect(() => {
    missedRules(internalMissingRules);

    const component = internalMissingRules?.length ? <MissingRules missing={internalMissingRules} /> : null;
    missedRulesComponent(component);
  }, [internalMissingRules]);

  const renderRulesItems = (password: Rule) => (
    <PasswordStatusListItem key={password.label} status={password.rule(passwordValue)}>
      <span aria-label={password?.aria || password?.label} role="text">
        <div aria-hidden={true}>
          {password.label}

          {password.rule(passwordValue) && (
            <ItauIcon iconName="check_inst" ariaHidden={true} style={{ fontSize: 18, marginLeft: '8px' }} />
          )}
        </div>
      </span>
    </PasswordStatusListItem>
  );

  return <PasswordStatusList>{rules?.length && rules.map(renderRulesItems)}</PasswordStatusList>;
};

PasswordStatus.defaultProps = {
  rules: defaultRules,
  missedRules: () => null,
  missedRulesComponent: () => null,
};

const PasswordStatusList = styled.ul`
  padding-left: 32px;
`;

const PasswordStatusListItem = styled.li<TPasswordStatusListItem>`
  font-size: 18px;
  color: ${({ status }) => (status ? '#007A47' : '#2B374A')};
`;
