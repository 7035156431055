import palette from './blog/palette';
import typography from './blog/typography';
import breakpoints from './blog/breakpoints';

const style = {
  typography: typography,
  palette: palette,
  breakpoints: breakpoints,
  shape: {},
  overrides: {
    MuiFormControl: {
      root: {
        '& label': {
          position: 'relative',
        },
        '& label + .MuiInput-formControl': {
          marginTop: '0px',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#FFF',

        '&$error': {
          color: '#531E6D',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#2B374A',
        '&$focused': {
          color: '#2B374A',
        },

        '&$error': {
          color: '#531E6D',
          fontWeight: '700',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '18px',
      },
    },
    MuiInput: {
      root: {
        '&$focused': {
          outline: 'none',
        },
      },

      input: {
        height: 'auto',
        lineHeight: '22px',
        padding: '8px 0',
      },

      underline: {
        '&:after': {
          display: 'none',
        },

        '&:not(.Mui-disabled):before': {
          borderBottom: '2px solid #2B374A',
        },

        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '2px solid #2B374A',
        },

        '&$error:not(.Mui-disabled):before': {
          borderColor: '#531E6D',
        },
      },
    },
    MuiAlert:{
      standardError: {
        color: '#781B8E',
        backgroundColor: '#F2E9F4',
        border: '1px solid #781B8E',
      },
      message:{
        fontWeight: 'Bold' 
      }
    },
    MuiButton: {
      text: {
        fontWeight: '700',
      },
      outlined: {
        fontWeight: '700',
      },
      fullWidth:{
        border: '1.5px solid #2B374A',
      },
    },
    MuiLink: {
      button: {
        color:'#781B8E',
      },
    },
  },
};

export default style;
