import { rest } from 'msw';

interface EligibilityRequestProps {
  nome: string;
  cpf: string;
}

export const eligibilityMock = (env) => {
  return rest.post<EligibilityRequestProps>(
    `${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/elegibilidade`,
    (req, res, ctx) => {
      const { cpf } = req.body;
      if (cpf?.replaceAll(/[.-]/g, '') === '51228191069') {
        return res(
          ctx.status(200),
          ctx.json({
            nome: 'John Doe',
            cpf: '512.281.910-69',
            elegibilidade: [
              {
                elegivel: false,
                motivo: '',
                pep: true,
              },
            ],
          })
        );
      } else {
        return res(
          ctx.status(200),
          ctx.json({
            nome: 'John Doe',
            cpf: '01234567890',
            elegibilidade: [
              {
                elegivel: true,
                motivo: '',
                pep: true,
              },
            ],
          })
        );
      }
    }
  );
};
