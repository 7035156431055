import { takeEvery } from 'redux-saga/effects';
import addressStepEffect from './auto/addressStepEffect';
import itemRiskStepEffect, { ItemRiskStepInterface } from './auto/itemRiskStepEffect';
import testABHiringEffect, { TestABHiringInterface } from './auto/testABHiringEffect';
import profileStepEffect from './auto/profile-step-effect';
import { stepEffectTypes } from './EffectTypes';
import { Action } from 'redux';
import registrationEmailEffect from './auth/registrationEmailEffect';
import registrationUserInformationEffect, { IUserRegister } from './auth/registrationUserInformationEffect';
import registrationCpfEffect from './auth/registrationCpfEffect';
import additionalDataStepEffect, { additionalDataType } from './auto/additional-data-step-effect';
import paymentEffect from './auto/proposal-effect';
import registrationStepEffect from './auth/registrationStepEffect';
import userNameEffect from './auth/userNameEffect';
import userAuthenticatedEffect from './auth/userAuthenticated';
import hospitauContextEffect from './auth/hospitauContextEffect';

const effects = function* () {
  //auto
  yield takeEvery<{ cpf: string, contact: boolean, name: string, cellphone: string } & Action>(stepEffectTypes.profileStepEffect, profileStepEffect);
  yield takeEvery<{ hiringId: string } & Action>(stepEffectTypes.paymentEffect, paymentEffect);
  yield takeEvery<ItemRiskStepInterface & Action>(stepEffectTypes.itemRiskStepEffect, itemRiskStepEffect);
  yield takeEvery<{ cep: string } & Action>(stepEffectTypes.addressStepEffect, addressStepEffect);
  yield takeEvery<Action & additionalDataType>(stepEffectTypes.additionalDataEffect, additionalDataStepEffect);
  yield takeEvery<TestABHiringInterface & Action>(stepEffectTypes.testABHiringEffect, testABHiringEffect);

  //auth
  yield takeEvery<{ name: string } & Action>(stepEffectTypes.userEffect, userNameEffect);
  yield takeEvery<{ isAuthenticated: boolean } & Action>(
    stepEffectTypes.userAuthenticatedEffect,
    userAuthenticatedEffect
  );
  yield takeEvery<{ identifier: string, integrationKey: string } & Action>(stepEffectTypes.hospitauContextEffect, hospitauContextEffect);

  yield takeEvery<{ email: string } & Action>(stepEffectTypes.registrationEmailEffect, registrationEmailEffect);
  yield takeEvery<{ cpf: string } & Action>(stepEffectTypes.registrationCpfEffect, registrationCpfEffect);
  yield takeEvery<IUserRegister & Action>(
    stepEffectTypes.registrationUserInformationEffect,
    registrationUserInformationEffect
  );
  yield takeEvery<{ step: number } & Action>(stepEffectTypes.registrationStepEffect, registrationStepEffect);
};

export default effects;
