import { UserAuth } from '../../models/send-user-auth';
import { ISendUserAuthRepository } from '../../../data/contracts/auth/send-user-auth';
import { UserAuthResponse } from '../../../domain/entities/auth/response/user';
import { IHttpPostClient } from '../../../data/contracts/http/http-post-client';

export class SendUserAuthRepository implements ISendUserAuthRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<UserAuth, UserAuthResponse>) {}

  async execute(authParams: UserAuth, headers?: any): Promise<UserAuthResponse> {
    return await this.httpPostClient.post(authParams, null, headers);
  }
}
