import { ButtonProps } from '@material-ui/core';
import { CircleButtonStyled } from './CircleButton-styles';

export interface CircleButtonProps extends Omit<ButtonProps, 'color' | 'size'> {
  children: JSX.Element | JSX.Element[];
  buttonSize?: number;
  buttonColor?: string;
}
export const CircleButton = ({ children, buttonSize = 60, buttonColor = '#FFF', ...props }: CircleButtonProps) => {
  return (
    <CircleButtonStyled $buttonSize={buttonSize} $buttonColor={buttonColor} {...props}>
      {children}
    </CircleButtonStyled>
  );
};
