import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { FAQProductQuestionsResponse, Question } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQProductQuestionsRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export class GetFAQProductQuestionsRepository implements IGetFAQProductQuestionsRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQProductQuestionsResponse>) {}

  async execute(): Promise<Question[]> {
    const response: FAQProductQuestionsResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
