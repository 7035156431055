import { rest } from 'msw';

type SearchVehicleRequestProps = {
  placa: string;
  descricao: string;
};

export const searchVehicle = (env) => {
  return rest.get<SearchVehicleRequestProps>(`${env.gatewayBFFBaseURL.url}/automovel/buscaveiculo`, (req, res, ctx) => {
    const placa = req.url.searchParams.get('placa');
    const descricao = req.url.searchParams.get('descricao');

    if (placa === 'abc1234') {
      return res(
        ctx.status(404),
        ctx.json({
          data: {
            code: 'vlc-002',
            message: 'Veículo não encontrado',
          },
        })
      );
    }

    if (descricao === 'teste') {
      return res(
        ctx.status(400),
        ctx.delay(500),
        ctx.json({
          data: {
            code: 'vlc-002',
            message: 'Veículo não encontrado',
          },
        })
      );
    }

    if (descricao === 'empty') {
      return res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
          data: {
            page: 1,
            perPage: 1,
            totalPages: 0,
            totalElements: 0,
            valoresMercado: [],
          },
        })
      );
    }

    if (descricao === 'chevrolet') {
      return res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
          data: {
            page: 1,
            perPage: 20,
            totalPages: 5,
            totalElements: 48,
            valoresMercado: [
              {
                codigoVersao: 15201,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 222962,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET EQUINOX PREMIER 1.5 16V TURBO  1.5 16V GASOLINA 4X4 AUTOMÁTICO - 5PORTAS - GASOLINA - AUTOMÁTICO - CAT.23 - COD.15201',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 2,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4529,
                      digitoVeiculoTabela: 2,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1326,
                    nomeModelo: 'EQUINOX',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 626,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 78771,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA MONTANA PICK-UP LS 1.4 ECONOFLEX - 2PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.14 - COD.626',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4370,
                      digitoVeiculoTabela: 2,
                    },
                  ],
                  modelo: {
                    codigoModelo: 304,
                    nomeModelo: 'NOVA MONTANA PICK-UP',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4389,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 185715,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA ADVANTAGE 2.5 FLEX 4X2 - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.22 - COD.4389',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4474,
                      digitoVeiculoTabela: 1,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 3621,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 300281,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA HIGH COUNTRY 2.8 CTDI 4X4 AUT. - 4PORTAS - DIESEL - AUTOMÁTICO - CAT.22 - COD.3621',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4464,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 1796,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 237621,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LS 2.8 CTDI 4X4 - 4PORTAS - DIESEL - MANUAL - CAT.22 - COD.1796',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4413,
                      digitoVeiculoTabela: 0,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4634,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 186562,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LT 2.5 ECOTEC 4X2 AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.22 - COD.4634',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4482,
                      digitoVeiculoTabela: 2,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 1505,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 265347,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LT 2.8 CTDI 4X4 AUT. - 4PORTAS - DIESEL - AUTOMÁTICO - CAT.22 - COD.1505',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4395,
                      digitoVeiculoTabela: 8,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4631,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 187084,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LTZ 2.5 ECOTEC 4X2 AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.22 - COD.4631',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4480,
                      digitoVeiculoTabela: 6,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4633,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 230630,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LTZ 2.5 ECOTEC 4X4 AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.22 - COD.4633',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4481,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 1507,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 289183,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB DUPLA LTZ 2.8 CTDI 4X4 AUT. - 4PORTAS - DIESEL - AUTOMÁTICO - CAT.22 - COD.1507',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4397,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 563,
                    nomeModelo: 'NOVA S10 CAB DUPLA',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 1501,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 235140,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVA S10 CAB SIMPLES LS 2.8 CTDI 4X4 - 2PORTAS - DIESEL - MANUAL - CAT.20 - COD.1501',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4391,
                      digitoVeiculoTabela: 5,
                    },
                  ],
                  modelo: {
                    codigoModelo: 564,
                    nomeModelo: 'NOVA S10',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4436,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 135882,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO CRUZE HATCH SPORT LT 1.4 16V TB FLEX AUT. 5 PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.4436',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4478,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1265,
                    nomeModelo: 'NOVO CRUZE HATCH',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6023,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 165141,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO CRUZE HATCH SPORT PREMIER 1.4 16V FLEX AUT - 5PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.11 - COD.6023',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4510,
                      digitoVeiculoTabela: 1,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1265,
                    nomeModelo: 'NOVO CRUZE HATCH',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4111,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 136180,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO CRUZE SEDAN LT 1.4 TURBO FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.4111',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4470,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1191,
                    nomeModelo: 'NOVO CRUZE SEDAN',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 4112,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 151714,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO CRUZE SEDAN LTZ 1.4 TURBO FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.11 - COD.4112',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4469,
                      digitoVeiculoTabela: 5,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1191,
                    nomeModelo: 'NOVO CRUZE SEDAN',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6013,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 163446,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO CRUZE SEDAN PREMIER 1.4 TURBO FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.11 - COD.6013',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4509,
                      digitoVeiculoTabela: 8,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1191,
                    nomeModelo: 'NOVO CRUZE SEDAN',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6050,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 67288,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO JOY PLUS BLACK ED.1.0 8V FLEX - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.6050',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4508,
                      digitoVeiculoTabela: 0,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1567,
                    nomeModelo: 'NOVO JOY PLUS',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6212,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 75680,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO ONIX HATCH 1.0 12V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.6212',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4519,
                      digitoVeiculoTabela: 5,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1578,
                    nomeModelo: 'NOVO ONIX HATCH',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6094,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 82840,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO ONIX HATCH 1.0 12V TURBO FLEX AUT. - 5PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.6094',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4511,
                      digitoVeiculoTabela: 0,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1578,
                    nomeModelo: 'NOVO ONIX HATCH',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
              {
                codigoVersao: 6140,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 77895,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'CHEVROLET NOVO ONIX HATCH LT 1.0 12V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.6140',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 4517,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1578,
                    nomeModelo: 'NOVO ONIX HATCH',
                    marca: {
                      codigo: 2,
                      descricao: 'CHEVROLET',
                    },
                  },
                },
              },
            ],
          },
        })
      );
    }

    if (descricao === 'fiat') {
      return res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
          data: {
            page: 1,
            perPage: 20,
            totalPages: 9,
            totalElements: 86,
            valoresMercado: [
              {
                codigoVersao: 5216,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 72494,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT ARGO 1.0 6V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.5216',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1509,
                      digitoVeiculoTabela: 1,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1303,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4644,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 78589,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT ARGO DRIVE 1.0 6V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.4644',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1494,
                      digitoVeiculoTabela: 0,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1303,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4645,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 72526,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT ARGO DRIVE 1.3 8V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.4645',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1493,
                      digitoVeiculoTabela: 1,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1303,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4664,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 100900,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT ARGO HGT 1.8 16V FLEX AUT. - 5PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.4664',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1488,
                      digitoVeiculoTabela: 5,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1303,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5763,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 84743,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT ARGO TREKKING 1.3 8V FLEX 5 PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.5763',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1519,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1512,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 6157,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 100900,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT ARGO TREKKING 1.8 16V FLEX AUT. - 5PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.6157',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1524,
                      digitoVeiculoTabela: 5,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1303,
                    nomeModelo: 'ARGO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5049,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 81387,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT CRONOS SEDAN 1.3 8V FLEX - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.5049',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1506,
                      digitoVeiculoTabela: 7,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1381,
                    nomeModelo: 'CRONOS SEDAN',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5041,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 86569,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT CRONOS SEDAN DRIVE 1.3 8V FLEX - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.5041',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1505,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1381,
                    nomeModelo: 'CRONOS SEDAN',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5547,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 89794,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT CRONOS SEDAN DRIVE 1.8 16V FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.5547',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1517,
                      digitoVeiculoTabela: 2,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1383,
                    nomeModelo: 'CRONOS SEDAN',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5921,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 105905,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT CRONOS SEDAN HGT 1.8 16V FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.5921',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1522,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1383,
                    nomeModelo: 'CRONOS SEDAN',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5045,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 100609,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT CRONOS SEDAN PRECISION 1.8 16V FLEX AUT. - 4PORTAS - GASOLINA/ALCOOL - AUTOMÁTICO - CAT.10 - COD.5045',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 2,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1502,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1383,
                    nomeModelo: 'CRONOS SEDAN',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 838,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 132141,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT DOBLO ESSENCE 1.8 16V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.838',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1352,
                      digitoVeiculoTabela: 8,
                    },
                  ],
                  modelo: {
                    codigoModelo: 84,
                    nomeModelo: 'DOBLO',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 15270,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 68590,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT GRAND SIENA 1.0 8V EVO FLEX 1.0 8V GASOLINA/ALCOOL 4X2 MANUAL - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.15270',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1533,
                      digitoVeiculoTabela: 4,
                    },
                  ],
                  modelo: {
                    codigoModelo: 604,
                    nomeModelo: 'GRAND SIENA',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 15406,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 74900,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT GRAND SIENA 1.4 8V EVO  1.4 8V GASOLINA/ALCOOL 4X2 MANUAL - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.15406',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1532,
                      digitoVeiculoTabela: 6,
                    },
                  ],
                  modelo: {
                    codigoModelo: 604,
                    nomeModelo: 'GRAND SIENA',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4442,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 58068,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT GRAND SIENA ATTRACTIVE 1.0 8V FLEX - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.4442',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1481,
                      digitoVeiculoTabela: 8,
                    },
                  ],
                  modelo: {
                    codigoModelo: 604,
                    nomeModelo: 'GRAND SIENA',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 1604,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 61410,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao:
                    'FIAT GRAND SIENA ATTRACTIVE 1.4 FLEX - 4PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.1604',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1378,
                      digitoVeiculoTabela: 1,
                    },
                  ],
                  modelo: {
                    codigoModelo: 604,
                    nomeModelo: 'GRAND SIENA',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 15567,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 66465,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT MOBI 1.0 FLEX TREKKING - 5 PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.15567',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1536,
                      digitoVeiculoTabela: 9,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1185,
                    nomeModelo: 'MOBI',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4046,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 51089,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT MOBI EASY 1.0 8V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.4046',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1463,
                      digitoVeiculoTabela: 0,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1185,
                    nomeModelo: 'MOBI',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 4047,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 63458,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT MOBI LIKE 1.0 8V FLEX - 5PORTAS - GASOLINA/ALCOOL - MANUAL - CAT.10 - COD.4047',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 8,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1461,
                      digitoVeiculoTabela: 3,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1185,
                    nomeModelo: 'MOBI',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
              {
                codigoVersao: 5050,
                anoModelo: 2021,
                flagZeroKm: 'S',
                valorMercado: 186148,
                mesReferencia: 3,
                anoReferencia: 2022,
                versaoVeiculo: {
                  nomeVersao: 'FIAT NOVA DUCATO CHASSI 2.3 16V - 2PORTAS - DIESEL - MANUAL - CAT.40 - COD.5050',
                  flagVeiculoPCD: 'N',
                  flagVeiculoBlindado: 'N',
                  tipoCombustivel: {
                    codigo: 3,
                  },
                  tipoCambio: {
                    codigo: 1,
                  },
                  tabelaReferencia: [
                    {
                      codigoTipoTabela: 2,
                      codigoVeiculoTabela: 1496,
                      digitoVeiculoTabela: 6,
                    },
                  ],
                  modelo: {
                    codigoModelo: 1385,
                    nomeModelo: 'NOVA DUCATO CHASSI',
                    marca: {
                      codigo: 4,
                      descricao: 'FIAT',
                    },
                  },
                },
              },
            ],
          },
        })
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(500),
      ctx.json({
        data: {
          page: 1,
          perPage: 1,
          totalPages: 22,
          totalElements: 22,
          valoresMercado: [
            {
              codigoVersao: 3109,
              anoModelo: 2016,
              anoFabricacao: 2016,
              flagZeroKm: 'N',
              valorMercado: 72788,
              mesReferencia: 1,
              anoReferencia: 2022,
              versaoVeiculo: {
                nomeVersao: 'Tesla da Adria',
                flagVeiculoPCD: 'N',
                flagVeiculoBlindado: 'N',
                tipoCombustivel: { codigo: 8 },
                tipoCambio: { codigo: 1 },
                tabelaReferencia: [
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122420, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122405, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122430, digitoVeiculoTabela: 6 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122431, digitoVeiculoTabela: 8 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122407, digitoVeiculoTabela: 0 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122435, digitoVeiculoTabela: 5 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122408, digitoVeiculoTabela: 2 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122419, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122434, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 2, codigoVeiculoTabela: 5408, digitoVeiculoTabela: 9 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122406, digitoVeiculoTabela: 9 },
                ],
                chassi: '9BWAG412XGT557407',
                modelo: { codigoModelo: 999, nomeModelo: 'NOVA SAVEIRO CAB DUPLA', marca: { codigo: 1 } },
              },
            },
            {
              codigoVersao: 3111,
              anoModelo: 2016,
              anoFabricacao: 2016,
              flagZeroKm: 'N',
              valorMercado: 72788,
              mesReferencia: 1,
              anoReferencia: 2022,
              versaoVeiculo: {
                nomeVersao: 'Embraer Phenom 300 do Ayrton',
                flagVeiculoPCD: 'N',
                flagVeiculoBlindado: 'N',
                tipoCombustivel: { codigo: 8 },
                tipoCambio: { codigo: 1 },
                tabelaReferencia: [
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122420, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122405, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122430, digitoVeiculoTabela: 6 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122431, digitoVeiculoTabela: 8 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122407, digitoVeiculoTabela: 0 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122435, digitoVeiculoTabela: 5 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122408, digitoVeiculoTabela: 2 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122419, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122434, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 2, codigoVeiculoTabela: 5408, digitoVeiculoTabela: 9 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122406, digitoVeiculoTabela: 9 },
                ],
                chassi: '9BWAG412XGT557407',
                modelo: { codigoModelo: 999, nomeModelo: 'NOVA SAVEIRO CAB DUPLA', marca: { codigo: 1 } },
              },
            },
            {
              codigoVersao: 3122,
              anoModelo: 2016,
              anoFabricacao: 2016,
              flagZeroKm: 'N',
              valorMercado: 72788,
              mesReferencia: 1,
              anoReferencia: 2022,
              versaoVeiculo: {
                nomeVersao: 'Camaro do Erico',
                flagVeiculoPCD: 'N',
                flagVeiculoBlindado: 'N',
                tipoCombustivel: { codigo: 8 },
                tipoCambio: { codigo: 1 },
                tabelaReferencia: [
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122420, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122405, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122430, digitoVeiculoTabela: 6 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122431, digitoVeiculoTabela: 8 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122407, digitoVeiculoTabela: 0 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122435, digitoVeiculoTabela: 5 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122408, digitoVeiculoTabela: 2 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122419, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122434, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 2, codigoVeiculoTabela: 5408, digitoVeiculoTabela: 9 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122406, digitoVeiculoTabela: 9 },
                ],
                chassi: '9BWAG412XGT557407',
                modelo: { codigoModelo: 999, nomeModelo: 'NOVA SAVEIRO CAB DUPLA', marca: { codigo: 1 } },
              },
            },
            {
              codigoVersao: 3133,
              anoModelo: 2016,
              anoFabricacao: 2016,
              flagZeroKm: 'N',
              valorMercado: 72788,
              mesReferencia: 1,
              anoReferencia: 2022,
              versaoVeiculo: {
                nomeVersao: 'Patins do Will',
                flagVeiculoPCD: 'N',
                flagVeiculoBlindado: 'N',
                tipoCombustivel: { codigo: 8 },
                tipoCambio: { codigo: 1 },
                tabelaReferencia: [
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122420, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122405, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122430, digitoVeiculoTabela: 6 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122431, digitoVeiculoTabela: 8 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122407, digitoVeiculoTabela: 0 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122435, digitoVeiculoTabela: 5 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122408, digitoVeiculoTabela: 2 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122419, digitoVeiculoTabela: 7 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122434, digitoVeiculoTabela: 3 },
                  { codigoTipoTabela: 2, codigoVeiculoTabela: 5408, digitoVeiculoTabela: 9 },
                  { codigoTipoTabela: 3, codigoVeiculoTabela: 5122406, digitoVeiculoTabela: 9 },
                ],
                chassi: '9BWAG412XGT557407',
                modelo: { codigoModelo: 999, nomeModelo: 'NOVA SAVEIRO CAB DUPLA', marca: { codigo: 1 } },
              },
            },
          ],
        },
      })
    );
  });
};
