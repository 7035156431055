import { SelectWeb } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@web/validation';

export const ModelYearField = (props) => {
  return (
    <FormControllerFieldConnector
      name="modelYear"
      component={SelectWeb}
      disabled={props?.disabled}
      onChange={props?.onChange}
      componentProps={{
        ...props,
        items: props.items || [],
        label: 'ano do modelo',
        id: 'txtModelYear_screenCarInformation',
      }}
      validation={stringValidationFactory({
        requiredMessage: 'você precisa informar o ano do modelo',
      })}
    />
  );
};
