import { rest } from 'msw';

interface TokenAuthRequestProps {
  email: string;
  token: string;
}
export const tokenAuthMock = (env) => {
  return rest.post<TokenAuthRequestProps>(`${env.gatewayBFFBaseURL.url}/autenticar/token`, (req, res, ctx) => {
    const { token } = req.body;
    if (token !== '123456') {
      return res(ctx.status(200), ctx.json({ success: true, errors: [], data: { success: true } }));
    } else {
      return res(
        ctx.status(400),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'authenticateTokenNotFoundError',
                message: 'Token inválido',
              },
            ],
          },
        })
      );
    }
  });
};

export type OfferCoverage = {
  titulo: string;
  descricao: string;
  imagem: string;
};

export type OfferAssist = {
  titulo: string;
  descricao: string;
  imagem: string;
};
export interface Offer {
  coberturas: OfferCoverage[];
  assistencias: OfferAssist[];
}
