import { useThemeProvider } from '../theme-provider/theme-provider';
import { InputWebProps } from '../../models/input-web-props';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function InputWeb(props: InputWebProps) {
  const { Components } = useThemeProvider();
  const { Input } = Components;
  return (
    <ThemeProviderInjector>
      <Input {...props} />
    </ThemeProviderInjector>
  );
}

export default InputWeb;
