import React, { ComponentType, forwardRef, HTMLProps } from 'react';
import { Box, BoxProps, Button, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled(
  forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, ref) => <Box ref={ref} {...props} />)
)({
  fontFamily: 'ItauText-Regular, sans-serif',
  zIndex: 2,
});

export const ButtonMenu = withStyles({
  root: ({ active, alignRight }: any) => ({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'end',

    visibility: active ? 'hidden' : 'visible',
    height: active ? 0 : undefined,
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '22.88px',
    padding: 0,
    textAlign: alignRight ? 'right' : undefined,

    '&:hover': { backgroundColor: '#fff' },
    '&:focus': { outline: '1px auto rgb(16, 16, 16)' },
  }),
})(Button);

export const MenuItemHeader = styled<ComponentType<BoxProps>>((props) => <Box component="span" {...props} />)({
  fontWeight: 600,
  overflow: 'hidden',
  width: '100px',
  textOverflow: 'ellipsis',
  margin: '0 10px',
  whiteSpace: 'nowrap',
});

export const HeaderButton = withStyles({
  root: ({ active, alignRight }: any) => ({
    zIndex: 1,
    display: 'flex',
    fontSize: '1rem',
    lineHeight: '22.88px',
    padding: '10px 1rem',
    borderRadius: '5px 5px 0 0',
    cursor: 'pointer',
    textAlign: 'left',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    width: '168px',

    color: active ? '#EC7000' : undefined,
    marginLeft: alignRight ? 'auto' : undefined,
    boxShadow: active ? '0 0 5px rgb(0 0 0 / 30%)' : undefined,
    fontWeight: active ? 'bold' : undefined,

    '&:hover': { backgroundColor: '#fff' },
    '&:focus': { outline: '1px auto rgb(16, 16, 16)' },
  }),
})(Button);

export const HeaderTitle = styled<ComponentType<BoxProps>>(Box)(({ alignRight }: { alignRight: boolean }) => ({
  margin: '0 10px',
  fontWeight: 600,
  overflow: 'hidden',
  width: '100px',
  textOverflow: 'ellipsis',
  textAlign: alignRight ? ('right' as const) : undefined,
}));

export const HeaderIcon = (props) => <Box {...props} />;

export const HeaderDivider = styled<ComponentType<BoxProps>>(Box)({
  width: '100%',
  height: '4px',
  bottom: '-4px',
  backgroundColor: '#fff',
  position: 'absolute' as const,
});

export const ListWrapper = styled<ComponentType<BoxProps>>((props) => <Box {...props} />)(
  ({ alignRight }: { alignRight: boolean }) => ({
    width: 0,
    marginTop: '-12px',
    marginLeft: '-4rem',
  })
);

export const ExpandableListRef = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, ref) => (
  <Box ref={ref} id="dropDownList-menu-item-acesso" {...props} />
));

export const DropdownContainer = styled<ComponentType<BoxProps>>((props) => <Box component="ul" {...props} />)(
  ({ alignRight }: { alignRight: boolean }) => ({
    backgroundColor: '#fff',
    overflow: 'hidden',
    borderRadius: alignRight ? '0 5px 5px 5px' : '5px 0 5px 5px',
    boxShadow: '0 0 5px rgb(0 0 0 / 30%)',
    cursor: 'pointer',
    padding: '4px 0 0',
    margin: 0,
    listStyle: 'none',
  })
);

export const ListContainer = withStyles({
  root: ({ active, maxHeight, alignRight }: any) => ({
    opacity: active ? 1 : 0,
    maxHeight: active ? maxHeight : 0,
    visibility: active ? 'visible' : 'hidden',
    transition: 'opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height .5s',
    padding: '3px',
    overflow: 'hidden',
    width: 'fit-content',
    marginTop: '2rem',
  }),
})(Box);

export const ListItem = (props) => <Box component="li" {...props} />;

export const ListLink = styled.a({
  padding: '10px 1rem',
  display: 'flex',
  textDecoration: 'none',
  color: 'rgb(16, 16, 16)',
  margin: '2px',

  '&:hover *, &:focus *': {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});
export const ListLabel = styled<ComponentType<BoxProps>>(Box)({
  whiteSpace: 'nowrap',
  margin: '0 5px 0 10px',
});
