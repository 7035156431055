import { PageLoadAnalyticsInterface } from '../../../analytics-config/analytics';
import { assembleAnalyticsPageLoadEventV2 } from '../../../analytics-config/assemble-analytics-page-load-event';
import { ANALYTICS_EVENT_TECHNICAL_IDS } from '../../../constants/technicalIds';

type P = 'P1';
export const pages: Record<P, string> = {
  P1: 'LS:NL:NCC:Faq:PrecisaDeAjuda:{0}',
};
export type PageLoadFaqQuestionsTechSpecI = Record<P, (labels?: unknown[]) => PageLoadAnalyticsInterface>;
export const PageLoadFaqQuestionsTechSpec: PageLoadFaqQuestionsTechSpecI = {
  P1: (label: string[]) =>
    assembleAnalyticsPageLoadEventV2(
      {
        nome: pages.P1,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
      },
      label
    ),
};
