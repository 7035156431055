import { rest } from 'msw';

export const getEffectiveProposal = (env) => {
  return rest.patch(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/propostas/9041388`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        id_proposta: 1234567,
        situacao: 'em análise',
        efetivada: true,
      })
    )
  );
};
