import { StrictMode } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export const FAQV2Base = () => <faq-base />;

export const AutoBase = () => <loja-seguros-auto />;

export const AuthBase = () => <auth-base />;

export const BlogPage = () => <loja-blog />;

export const LocalRouter = function () {
  return (
    <StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path="/autov2" render={AutoBase} />
          <Route path="/blog" render={BlogPage} />
          <Route path="/v2/ajuda" render={FAQV2Base} />
          <Route path="/v2/autenticacao/:id" render={AuthBase} />
          <dynamic-landpage />
        </Switch>
      </BrowserRouter>
    </StrictMode>
  );
};
