import { IHttpPostClient } from '@workspace/backend/shared/contracts/repositories';
import {ICommentBlogRepository} from '@workspace/frontend/blog/domain/contracts/repositories';
import { CommentBlogRequest } from '@workspace/frontend/blog/domain/entities';

export class CommentBlogRepository implements ICommentBlogRepository{
    constructor(
        private readonly httpPostClient: IHttpPostClient<CommentBlogRequest, string>,
      ) {}
    async execute(comment:CommentBlogRequest) {
        return await this.httpPostClient.post(comment)
    }
}
