import axios, { AxiosInstance } from 'axios';
import { BaseHttpClient } from './base.http-client';

export abstract class AxiosHttpClient extends BaseHttpClient {
    protected readonly axiosInstance: AxiosInstance;
    constructor(baseUrl: string) {
        super();

        this.axiosInstance = axios.create({
            baseURL: baseUrl,
            headers: {
                ...this.commomHeaders,
            },
        });
    }
}
