import { apiTypes } from '../../reducers/api/api-reducer-handlers';
import { stepEffectTypes } from '../../effects/EffectTypes';
import { store } from '../../store';
import { offerTypes } from '../../reducers/offer/offerHandlers';
import { Action } from 'redux';
import { StateManager } from '../StateManager';
import { itemRiskAutomobileTypes } from '../../reducers/itemRisk/item-risk-automobile-handlers';
import { additionalDataType } from '../../effects/auto/additional-data-step-effect';
import { SelectOption } from '@workspace/frontend/shared/presentation/components';
import { Company } from '@core/domain/enums/company';

/**
 * State manager for the Auto product
 * only auto-web-component should call this
 * methods should be on a step level (one per dispatcher and selector per step)
 * quotation calling order:
 *  1 - profile
 *  2 - itemRisk
 *  3 - address
 *  4 - quotation
 * hiring calling order:
 *  1 - additional data
 *  2 - payment
 *  3 - success
 *
 *  business logic should (for now) be on the effect level (but will be removed from this lib)
 *
 *  api calls are temporary and should not be here
 */
export class AutoStateManagerClass extends StateManager {
  constructor(store) {
    super(store);
  }

  onMountDispatch(domain) {
    this.store.dispatch({ type: apiTypes.setDomain, data: domain });
  }

  //---------------------------
  profileStepSelector(): { cpf: string, contact: boolean, name: string, cellphone: string } {
    const {cpf, contact, name, cellphone } = this.store.getState().actor.driver;
    return { cpf, contact, name, cellphone };
  }

  profileStepDispatcher(cpf: string, contact: boolean, name: string, cellphone: string) {
    this.store.dispatch({
      type: stepEffectTypes.profileStepEffect,
      cpf,
      contact,
      name,
      cellphone,
    });
  }
  //---------------------------

  itemRiskStepDispatcher(
    plate: string,
    zeroKm: boolean,
    bonusClass: number,
    brandAndModel: string,
    insuranceCompany: string,
    modelYear: number,
    fabricationYear: number,
    itemsBrandAndModel: [{ key: string; value: string }]
  ) {
    this.store.dispatch({
      type: stepEffectTypes.itemRiskStepEffect,
      plate,
      zeroKm,
      bonusClass,
      brandAndModel,
      insuranceCompany,
      modelYear,
      fabricationYear,
      itemsBrandAndModel,
    });
    this.store.dispatch<Action & { response: [{ key: string; value: string }] }>({
      type: apiTypes.setVehicleSearchPlate,
      response: itemsBrandAndModel,
    });
  }

  testABHiringDispatcher(cpf: string, plate: string, zeroKm: boolean) {
    this.store.dispatch({
      type: stepEffectTypes.testABHiringEffect,
      cpf,
      plate,
      zeroKm,
    });
  }

  itemRiskStepSelector() {
    const automobile = this.store.getState().itemRisk.automobile;
    const itemsBrandAndModel = this.store.getState().api.vehicleSearchPlate;
    const domain = this.store.getState().api.domain.data;
    const { cpf, name, cellphone } = this.store.getState().actor.driver;

    return {
      cpf,
      plate: automobile.plate,
      zeroKm: automobile.zeroKm,
      bonusClass: automobile.lastInsurance.bonusClass,
      brandAndModel: automobile.brandAndModel,
      insuranceCompany: automobile.lastInsurance.insuranceCompany,
      modelYear: automobile.modelYear ? parseInt(automobile.modelYear) : undefined,
      fabricationYear: automobile.fabricationYear ? parseInt(automobile.fabricationYear) : undefined,
      previousBonus: !!(automobile.lastInsurance.bonusClass || automobile.lastInsurance.insuranceCompany),
      itemsBrandAndModel,
      bonusClassOptions: domain.bonusClass,
      insuranceCompanyOptions: domain.insuranceCompany,
      name,
      cellphone,
    };
  }

  itemRiskResetDispatcher() {
    this.store.dispatch({
      type: itemRiskAutomobileTypes.reset,
    });
  }
  //---------------------------

  addressStepDispatcher(cep: string) {
    this.store.dispatch<Action & { cep: string }>({ type: stepEffectTypes.addressStepEffect, cep });
  }

  addressStepSelector(): { cep: string; cpf: string, name: string, cellphone: string } {
    const cep = this.store.getState().itemRisk.automobile.overnightCep;
    const { cpf, name, cellphone } = this.store.getState().actor.driver;

    return { cep, cpf, name, cellphone };
  }
  //---------------------------

  quotationStepSelector(): { cpf: string; selectedVehicle: string, name: string, cellphone: string, contact: boolean } {
    const brandAndModel = this.store.getState().itemRisk.automobile.brandAndModel;
    const { cpf, name, cellphone, contact } = this.store.getState().actor.driver;

    const vehicleCode = parseInt(brandAndModel);
    const selectedVehicle = this.store.getState().api.vehicleSearchPlate?.find((i) => {
      return parseInt(i.key) === vehicleCode || i.value === brandAndModel;
    });
    const res = {
      cpf,
      selectedVehicle: undefined,
      name,
      cellphone,
      contact
    };
    if (selectedVehicle) {
      res.selectedVehicle = selectedVehicle.label || selectedVehicle.value;
    }
    return res;
  }

  quotationStepDispatcher(installments) {
    this.store.dispatch({ type: apiTypes.setInstallmentsQuery, data: installments });
  }

  //---------------------------

  additionalDataStepSelector(): additionalDataType & {
    plate?: string;
    brandAndModel: string;
    zeroKm: boolean;
    nationalityOptions: SelectOption[];
    professionOptions: SelectOption[];
    incomeBracketOptions: SelectOption[];
    driverCpf: string;
  } {
    const automobile = this.store.getState().itemRisk.automobile;
    const proponent = this.store.getState().actor.proponent;
    const vehicle = this.quotationStepSelector();
    const domain = this.store.getState().api.domain.data;
    const driver = this.store.getState().actor.driver;

    return {
      zeroKm: automobile.zeroKm,
      isMainDriver: proponent.isMainDriver,
      name: proponent.name,
      cpf: proponent.cpf,
      rg: proponent.rg,
      issueDate: proponent.issueDate,
      issuingAgency: proponent.issuingAgency,
      nationality: proponent.nationality,
      profession: proponent.profession,
      incomeBracket: proponent.incomeBracket,
      cep: proponent.address?.cep || automobile.overnightCep,
      address: proponent.address?.address,
      addressNumber: proponent.address?.addressNumber,
      addressComplement: proponent.address?.addressComplement,
      addressNeighborhood: proponent.address?.addressNeighborhood,
      city: proponent.address?.city,
      state: proponent.address?.state,
      chassis: automobile.chassis,
      plate: automobile.plate,
      renavam: automobile.renavam,
      brandAndModel: vehicle.selectedVehicle,
      nationalityOptions: domain.nationality,
      professionOptions: domain.occupation,
      incomeBracketOptions: domain.incomeBracket,
      driverCpf: driver.cpf,
    };
  }

  additionalDataStepDispatcher(data: additionalDataType): void {
    this.store.dispatch<Action & additionalDataType>({
      type: stepEffectTypes.additionalDataEffect,
      ...data,
    });
  }
  //---------------------------

  paymentStepDispatcher(hiringId) {
    this.store.dispatch({
      type: stepEffectTypes.paymentEffect,
      hiringId,
    });
  }

  paymentStepSelector() {
    enum Insurance {
      REQUIRED_INSPECTION = 5,
      EXEMPT_INSPECTION = 3,
    }
    const selectedOffer = this.store.getState().offer.selectedOffer;
    const isRequiredInsuranceCar = selectedOffer?.offers?.insurance?.type === Insurance.REQUIRED_INSPECTION;
    const simulation = this.store.getState().offer.simulation;
    const domain = this.store.getState().api?.domain?.data;
    const installments = this.store.getState().api?.installmentsQuery?.data;
    const proponent = this.store.getState().actor.proponent;
    const fullAddress = `${proponent.address.address}, ${proponent.address.addressNumber}. ${proponent.address.city} - ${proponent.address.state}`;
    const allowedBadges = domain.badges.map((i) => i.value.toUpperCase());

    return {
      isRequiredInsuranceCar,
      bankOptions: domain.banks,
      allowedBadges,
      installments: installments,
      selectedOfferCode: selectedOffer?.code,
      simulationNumber: simulation.simulationNumber,
      isPorto: selectedOffer?.company === Company.PORTO,
      insuranceTotalPrice: selectedOffer?.price,
      coverages: selectedOffer?.offers.coverages.map((i) => ({ title: i.title, price: i.price })),
      assistances: selectedOffer?.offers.assistances.map((i) => ({ title: i.title, price: i.price })),
      name: proponent.name,
      cpf: proponent.cpf,
      cep: proponent.address?.cep,
      address: fullAddress,
    };
  }

  //---------------------------

  successSelector() {
    const simulation = this.store.getState()?.offer?.simulation;
    const simulationNumber = simulation?.simulationNumber;
    const proposalNumber = this.store.getState()?.proposal?.auto?.hiringId;

    return {
      simulationNumber,
      proposalNumber,
    };
  }
  //---------------------------

  clearStore() {
    return this.store.dispatch({ type: 'RESET' });
  }

  //---------------------------

  /**
   * @deprecated
   */
  selectedOfferDispatcher(data: unknown) {
    this.store.dispatch({ type: offerTypes.setSelectedOffer, data: data });
  }

  /**
   * @deprecated
   */
  quotationStepApiResponseDispatcher(data: unknown) {
    this.store.dispatch({ type: offerTypes.setQuotation, data: data });
  }

  /**
   * @deprecated
   */
  quotationStepApiSelector() {
    return this.store.getState().offer.simulation;
  }

  /**
   * @deprecated
   */
  vehicleSearchPlateSelector() {
    return this.store.getState().api.vehicleSearchPlate;
  }

  /**
   * @deprecated
   */
  getState(): Record<string, any> {
    return this.store.getState();
  }
}

const AutoStateManagerSingleton = new AutoStateManagerClass(store);
export default AutoStateManagerSingleton;
