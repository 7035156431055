import React, { useEffect, useState } from 'react';
import { Vehicle } from '@core/domain/entities/autov2/response/vehicle';
import {
  BoxWeb,
  NavigateButtonsWeb,
  OutlinedButtonWeb,
  TooltipWeb,
} from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { GentleTraffic, LoadingAutoPage } from '@workspace/frontend/auto/presentation/components';
import { RetryButton } from '../../../../components/retry-field/retry-button';
import { ZeroKmField } from '../../../../components/autoFields/ZeroKmField';
import { PlateField } from '../../../../components/autoFields/PlateField';
import { RuleZerokmSection } from './rule-zerokm-section';
import { RulePlateFoundSection } from './rule-platefound-section';
import { BonusSection } from './bonus-section';
import { RuleEditableSection } from './rule-editable-section';
import { PlateFieldBox } from './vehicle-data-step.style';
import { PreviousBonusField } from '../../../../components/autoFields/PreviousBonusField';
import { LeadDialogFormConnectorWrapper } from '../../../../components/auto-quotation-lead-dialog/lead-dialog-form-connector-wrapper';

const tooltipZeroKmText =
  'veículo que não tenha as suas características originais alteradas, esteja em nome do primeiro proprietário e que não tenha saído da revendedora/concessionária autorizada.';

export function VehicleStep({
  initialValues: initialValuesFromState,
  onSubmit,
  handleBack,
  sendLeadUsecase,
  findVehicleByDescriptionUsecase,
  findVehicleByPlateUseCase,
  setIsPlateFound,
  isPlateFound,
  setIsEditableFields,
  isEditableFields,
  setSearchByDescriptionError,
  plateNotFoundMessage,
  searchByDescriptionError,
  analyticsAutoV2,
  gentilTextImagePath,
  gentilLogoImagePath,
  setplateNotFoundMessage,
  config,
}: any) {
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const handleCloseLeadModal = () => setOpenLeadModal(false);
  const handleOpenLeadModal = () => setOpenLeadModal(true);
  const bonusClass = initialValuesFromState.bonusClassOptions;
  const congenereList = initialValuesFromState.insuranceCompanyOptions;
  const orderedCongenereList = congenereList ? [...congenereList] : [];

  const [searchByPlateError, setSearchByPlateError] = useState<boolean>(false);
  const [itemsBrandAndModel, setItemsBrandAndModel] = useState(initialValuesFromState.itemsBrandAndModel);
  const [initialValues, setInititalValues] = useState({ ...initialValuesFromState, ...{ customError: undefined } });
  const [searchVehicleLoading, setSearchVehicleLoading] = useState<boolean>(false);

  const activeRedirectV1ModePlate = initialValuesFromState.plate && !initialValuesFromState.modelYear;
  const activeRedirectV1ModeZeroKm = initialValuesFromState.zeroKm;
  const [preparedData, setPreparedData] = useState<boolean>(!activeRedirectV1ModePlate && !activeRedirectV1ModeZeroKm);

  const handleParse = (current: any) => ({
    value: current.versaoVeiculo.nomeVersao,
    key: current.codigoVersao,
  });

  orderedCongenereList.sort(({ value: fa }, { value: fb }) => (fa + 'pt-Br').localeCompare(fb));

  const handleRedirectAutoV1ByPlate = async () => {
    const result = await handleSearchVehiclePlate(initialValuesFromState.plate);
    if (result) {
      initialValuesFromState.fabricationYear = result.anoFabricacao;
      initialValuesFromState.modelYear = result.anoModelo;
      const parsedResult = handleParse(result);
      if (parsedResult) setIsPlateFound(true);
      setItemsBrandAndModel([parsedResult]);
      initialValuesFromState.brandAndModel = parsedResult.key;
      setInititalValues(initialValuesFromState);
    }
    setPreparedData(true);
  };

  const handleRedirectAutoV1ByZeroKm = () => {
    initialValuesFromState.zeroKm = true;
    setInititalValues(initialValuesFromState);
    setPreparedData(true);
  };

  useEffect(() => {
    if (activeRedirectV1ModePlate) {
      handleRedirectAutoV1ByPlate();
    } else if (activeRedirectV1ModeZeroKm) {
      handleRedirectAutoV1ByZeroKm();
    }
  }, []);

  const bonusClassInfo =
    'a classe de bônus se refere ao valor de desconto que você possui com base no seu histórico de seguros anteriores. Caso tenha dúvida sobre sua classe, você pode encontra-lá na documentação do seu seguro anterior';

  const handleSearchVehiclePlate = async (plate: string): Promise<Vehicle> => {
    const vehicle = await findVehicleByPlateUseCase.execute(plate);
    if (vehicle?.valoresMercado) {
      setSearchByPlateError(false);
      return vehicle.valoresMercado[0];
    }
    return null;
  };

  if (!preparedData) return <LoadingAutoPage />;

  return (
    <>
      <BoxWeb
        mx={{ xs: 'auto', sm: '0' }}
        display={{ xs: 'flex', md: 'block' }}
        flexDirection={'column'}
        alignItems={{ xs: 'center', md: 'start' }}
      >
        <BoxWeb component={'h1'} aria-label="qual seu veículo?" id="lblInicialText_screenCarInformation" m={0}>
          <span aria-hidden>
            qual seu <strong>veículo?</strong>
          </span>
        </BoxWeb>
      </BoxWeb>
      <BoxWeb mt={{ xs: 4, md: 7 }}>
        <FormController
          initialValues={initialValues}
          onSubmit={(data) => {
            data.itemsBrandAndModel = itemsBrandAndModel;
            onSubmit(data);
          }}
        >
          {({ watch, setValue, clearErrors }) => {
            const isZeroKm = watch('zeroKm');
            const showPlateFound = isPlateFound && !isEditableFields && !isZeroKm;
            const showEditableFields = isPlateFound && isEditableFields && !isZeroKm;
            const isPreviousBonus = watch('previousBonus');
            const modelYear = watch('modelYear');
            const plate = watch('plate');

            const handleParse = (current: any) => ({
              value: current.versaoVeiculo.nomeVersao,
              key: current.codigoVersao,
            });

            const checkIfDescriptionIsValid = (value: string | number): boolean => typeof value !== 'number';

            const handleSearchVehicleByDescription: any = async (description) => {
              if (!checkIfDescriptionIsValid(description)) return;
              const vehicle = await findVehicleByDescriptionUsecase.execute(modelYear, description);
              const adapter = (current: Vehicle) => ({
                value: current.versaoVeiculo?.nomeVersao,
                key: current.codigoVersao?.toString(),
              });
              if (vehicle?.valoresMercado) {
                setSearchByDescriptionError(false);
                return vehicle?.valoresMercado?.map?.(adapter) || [];
              }
              return [];
            };

            const searchVehiclePlate = async (plate) => {
              setSearchVehicleLoading(true);
              const result: Vehicle = await handleSearchVehiclePlate(plate);
              if (result) {
                setValue('fabricationYear', result.anoFabricacao);
                setValue('modelYear', result.anoModelo);
                const parsedResult = handleParse(result);
                if (parsedResult) setIsPlateFound(true);
                setIsEditableFields(false);
                setItemsBrandAndModel([parsedResult]);
                setValue('brandAndModel', parsedResult.key);
              }
              setSearchVehicleLoading(false);
            };

            const handleGetVehicleWithPlate = async (event) => {
              setplateNotFoundMessage(false);
              if (event.target.value.trim().length === 7) {
                await searchVehiclePlate(event.target.value);
              }
            };

            const handleToggleZeroKm = () => {
              if (!isZeroKm) {
                setItemsBrandAndModel([]);
                setIsPlateFound(false);
                setValue('brandAndModel', '');
                setValue('fabricationYear', '');
                setValue('modelYear', '');
                setValue('plate', '');
              } else {
                clearErrors();
              }
            };

            const handlePreviousBonusToggle = () => {
              setValue('bonusClass', '');
              setValue('insuranceCompany', '');
              clearErrors(['bonusClass', 'insuranceCompany']);
            };

            const handleInitValuesBrandAndModelZeroKm = (items) => {
              setItemsBrandAndModel(items);
            };
            const handleClearFabricationYear = () => {
              setValue('fabricationYear', '');
            };

            const handleClearModelYear = () => {
              setValue('modelYear', '');
            };

            const handleClearBrandAndModel = () => {
              setValue('brandAndModel', null);
            };

            const handleChangeFabrication = () => {
              handleClearModelYear();
              handleClearBrandAndModel();
            };

            const handleChangeModelYear = () => {
              handleClearBrandAndModel();
            };
            const handleClickEdit = () => {
              handleClearFabricationYear();
              handleClearModelYear();
              handleClearBrandAndModel();
              setIsEditableFields(true);
              analyticsAutoV2.HIRING.VEHICLE.EDIT();
            };

            return (
              <>
                <LeadDialogFormConnectorWrapper
                  open={openLeadModal}
                  onClose={handleCloseLeadModal}
                  sendLeadUsecase={sendLeadUsecase}
                />
                <PlateFieldBox>
                  <PlateField onChange={handleGetVehicleWithPlate} />
                  <RetryButton
                    visible={searchByPlateError}
                    loading={searchVehicleLoading}
                    onClick={() => searchVehiclePlate(plate)}
                  />
                  {plateNotFoundMessage && (
                    <BoxWeb color={'text.error'}>
                      Não encontramos os dados do seu veículo. Por favor, insira os campos a seguir.
                    </BoxWeb>
                  )}
                  <BoxWeb display="flex" flexDirection="row" mt={'1rem'}>
                    <BoxWeb my={'1.5rem'}>
                      <ZeroKmField onChange={handleToggleZeroKm} disabled={searchVehicleLoading} />
                    </BoxWeb>
                    <BoxWeb mt={'1rem'} mr={'1rem'}>
                      <TooltipWeb title={tooltipZeroKmText} />
                    </BoxWeb>
                  </BoxWeb>
                </PlateFieldBox>

                <RuleZerokmSection
                  onSearchVehicleByDescriptionService={handleSearchVehicleByDescription}
                  onInitValuesBrandAndModelZeroKm={handleInitValuesBrandAndModelZeroKm}
                  itemsBrandAndModel={itemsBrandAndModel}
                  modelAndBrandNotFoundMessage={searchByDescriptionError}
                  onChangeManufactureYear={handleChangeFabrication}
                  onChangeModelYear={handleChangeModelYear}
                />

                {showPlateFound && (
                  <RulePlateFoundSection itemsBrandAndModel={itemsBrandAndModel} onClick={handleClickEdit} />
                )}

                {showEditableFields && (
                  <RuleEditableSection
                    onSearchVehicleByDescriptionService={handleSearchVehicleByDescription}
                    onInitValuesBrandAndModelZeroKm={handleInitValuesBrandAndModelZeroKm}
                    itemsBrandAndModel={itemsBrandAndModel}
                    modelAndBrandNotFoundMessage={searchByDescriptionError}
                    onChangeManufactureYear={handleChangeFabrication}
                    onChangeModelYear={handleChangeModelYear}
                  />
                )}

                {(isZeroKm || isPlateFound) && (
                  <>
                    <BoxWeb display="flex" flexDirection={{ xs: 'column', sm: 'row' }} my="1.5rem">
                      <PreviousBonusField onChange={handlePreviousBonusToggle} />
                    </BoxWeb>
                    {isPreviousBonus && (
                      <BonusSection
                        bonusClass={bonusClass}
                        bonusClassInfo={bonusClassInfo}
                        congenereList={orderedCongenereList}
                      />
                    )}
                  </>
                )}
                <BoxWeb ml="1rem" mt={'1.5rem'}>
                  <GentleTraffic logoPath={gentilLogoImagePath} textPath={gentilTextImagePath} />
                </BoxWeb>

                <BoxWeb
                  my={'3rem'}
                  display={{ xs: 'flex', md: 'block' }}
                  flexDirection={'column'}
                  alignItems={{ xs: 'center', md: 'start' }}
                >
                  <NavigateButtonsWeb
                    loading={searchVehicleLoading}
                    onClickBack={handleBack}
                    disabled={searchByPlateError || searchByDescriptionError}
                  />
                </BoxWeb>
                {config?.ENABLE_AUTO_SIMPLE_LEAD && (
                  <BoxWeb width="17rem">
                    <span>se preferir, deixe seus dados e nós entraremos em contato com você</span>
                    <BoxWeb height="0.5rem" />
                    <BoxWeb tabIndex={0}>
                      <OutlinedButtonWeb
                        id="btnContactLead_screenCarInformation"
                        label="entrar em contato"
                        onClick={handleOpenLeadModal}
                      />
                    </BoxWeb>
                  </BoxWeb>
                )}
              </>
            );
          }}
        </FormController>
      </BoxWeb>
    </>
  );
}
