import {HashRouter, Redirect, Route} from 'react-router-dom';
import { AutoV2HiringCarrier } from '../containers/hiring/autov2-hiring-carrier';
import { AutoV2SuccessCarrier } from '../containers/success/autov2-success-carrier';

export const AutoV2Hiring = ({ origin }) => <AutoV2HiringCarrier partner={origin} />;
export const AutoV2Success = ({ origin }) => <AutoV2SuccessCarrier partner={origin} />;

export const AutoV2LocalHiringRouter = function ({ origin, prefixPath = '/autov2' }) {
  return (
    <>
      <Hiring prefixPath={prefixPath} origin={origin}/>
      <Success prefixPath={prefixPath} origin={origin} />
      <Route exact path={prefixPath + '/'} render={() => <Redirect to={`${prefixPath}/contratacao`} />}></Route>
    </>
  );
};

const Hiring = ({ prefixPath, origin}) => {
  const isHashPath = prefixPath.includes('#');
  if(isHashPath)
    return (
      <HashRouter>
        <Route exact hash={`${prefixPath}/contratacao`} render={() => <AutoV2Hiring origin={origin} />} />
      </HashRouter>
    )

  return (
    <Route exact path={`${prefixPath}/contratacao`} render={() => <AutoV2Hiring origin={origin}/>}/>
  );
};

const Success = ({ prefixPath, origin }) => (
  prefixPath.includes('#') ? 
  <HashRouter>
    <Route exact hash={`${prefixPath}/sucesso`} render={() => <AutoV2Success origin={origin} />} />
  </HashRouter> :
  <Route exact path={`${prefixPath}/sucesso`} render={() => <AutoV2Success origin={origin} />} />
);
