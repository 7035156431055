import firebase from 'firebase/app';
// Import all modules from tihs environment
// import 'firebase/performance';

const firebaseConfig = {
  apiKey: 'AIzaSyAqjxYEiTPlYRz5h0_POpST_fMlTME6XVs',
  authDomain: 'zup-itau-seguros-prod.firebaseapp.com',
  databaseURL: 'https://zup-itau-seguros-prod.firebaseio.com',
  projectId: 'zup-itau-seguros-prod',
  storageBucket: 'zup-itau-seguros-prod.appspot.com',
  messagingSenderId: '277471525993',
  appId: '1:277471525993:web:73182f03f812493201f6f8',
};

export const initFirebase = () => {
  if (firebase?.apps?.length === 0 && !firebase.apps.some((a) => a.name === '[DEFAULT]'))
    firebase.initializeApp(firebaseConfig);

  // initialize modules
  const performance = firebase.performance();
  const remoteConfig = firebase.remoteConfig();
  const analytics = firebase.analytics();
  const database = firebase.firestore();
  const auth = firebase.auth();
  // remoteConfig.settings = { minimumFetchIntervalMillis: 0 };
  return { performance, remoteConfig, analytics, database, auth };
};
