import { Product } from '@workspace/frontend/my-account/domain/entities';
import { GetUserProductsUseCase } from '@workspace/frontend/my-account/domain/use-cases';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { UserId } from '@workspace/frontend/my-account/domain/contracts';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';

interface ICancellationHiringResponse {
    data: {
      contratos: {
        id_contratacao: string;
        numero_contratacao: string;
        data_contratacao: string;
        status: string;
        nome_produto: string;
        plano_odontologico: boolean;
        type: number;
        mfa: boolean;
      }[];
      status: string;
  }
}

//instancea o useCase pra quando precisa de algum tipo de variavel
export function getUserProductsFactory(environment, authenticationData = { accessToken: '', identifier: ''}) {
  const headersUserProducts = authenticationData?.accessToken ? {
    authorization : `Bearer ${authenticationData?.accessToken}`,
  } : {};

  const getuserProductsHttpPostClient: IHttpPostClient<UserId, Product> = new AxiosHttpPostClient(
    'produtos-usuario',
    headersUserProducts,
    environment.gatewayBFFBaseURL.url
  );

  const headersHiring = authenticationData?.accessToken ? {
    'token-sts' : authenticationData?.accessToken,
    identifier: authenticationData?.identifier
  } : {};
  
  const cancellationHiringHttpGetClient: IHttpGetClient<null, ICancellationHiringResponse> = new AxiosHttpGetClient(
    '/v1/mkt-seguros-e2e/contratacoes',
    headersHiring,
    environment.gatewayAWSBaseURL.url
  );

  //precisa data(info), tem uma função execute que traz todos os dados
  // a ideia de passar as dependencia é por causa do Dependency injection do SOLID
  const getUserProductsRepositoryInstance = new getUserProductsRepository(
    getuserProductsHttpPostClient,
    cancellationHiringHttpGetClient
  );

  // tenho regra de negocio
  const getUserProductsUseCaseInstance = new GetUserProductsUseCase(getUserProductsRepositoryInstance);

  return getUserProductsUseCaseInstance;
}

class getUserProductsRepository {
  constructor(
    private readonly userProductHttpPostClient: IHttpPostClient<UserId, Product>,
    private readonly getCancellationHiringHttpGetClient: IHttpGetClient<null, ICancellationHiringResponse>
  ) {}

  async execute(userId: UserId): Promise<{ getHiring: ICancellationHiringResponse; userProducts: Product }> {
    const [ getHiring, userProducts ] = await Promise.all([
      this.getCancellationHiringHttpGetClient.get().catch(_ => ({
        data: {
          contratos: [],
          status: 'OK'
        }
      })),
      this.userProductHttpPostClient.post(userId).catch(_ => ({
        data: {
          success: true,
          errors: [],
          value: {
            integrationKey: "",
            products: [
              {
                type: 1,
                identifier: '0',
                error: {
                  code: 'systemicError',
                  message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
                },
                isValid: false,
              },
            ],
          },
        },
      }))
    ]);

    return {
      getHiring,
      userProducts,
    };
  }
}
