export enum RecaptchaActions {
  AUTHENTICATION = 'authentication',
  REGISTER = 'register',
  CONFIRM_TOKEN = 'confirmToken',
  RESEND_TOKEN = 'resendToken',
  VALIDATE_HASH = 'validateHash',
  RECOVER_PASSWORD = 'recoverPassword',
  REGISTER_NEW_PASSWORD = 'registerNewPassword',
  CONSULT_CPF = 'consultCPF',
  CONSULT_EMAIL = 'consultEmail',
}
