import { IEligibilityUseCase } from '@core/domain/usecases/eligibility-usecase';
import { IEligibilityRepository } from '@core/data/contracts/autov2/eligibility-repository';
import { Eligibility } from '@core/domain/entities/autov2/eligibility';
import { EligibilityResponse } from '@core/domain/entities/autov2/response/eligibility-response';

export class ElegibilityUsecase implements IEligibilityUseCase {
  constructor(private readonly eligibilityRepository: IEligibilityRepository) {}

  async execute(data: Eligibility): Promise<EligibilityResponse> {
    const newData = {
      nome: data.nome,
      cpf: data.cpf?.replace(/\D/g, ''),
    };
    return await this.eligibilityRepository.execute(newData);
  }
}
