import { useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { TextAreaProps } from '../../models/text-area';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function TextAreaWeb(props: TextAreaProps) {
  const { Components } = useThemeProvider();
  const { TextArea } = Components;
  return (
    <ThemeProviderInjector>
      <TextArea {...props} />
    </ThemeProviderInjector>
  );
}

export default TextAreaWeb;
