import { SendNicknameResponse } from '../../domain/entities/my-account/response/send-nickname-response';
import { ISendNicknameUsecase } from '../../domain/usecases/send-nickname-usecase';
import { ISendNicknameRepository } from '../../data/contracts/my-account/send-nickname-repository';
import { SendNickname } from '@core/infra/models/send-nickname';

export class SendNicknameUsecase implements ISendNicknameUsecase {
  constructor(private readonly sendNicknameRepository: ISendNicknameRepository) {}

  async execute(props: SendNickname, headers?: any): Promise<SendNicknameResponse> {
    return await this.sendNicknameRepository.execute(props, headers);
  }
}
