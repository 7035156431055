import { IHttpGetClient } from '../../../data/contracts/http/http-get-client';
import { IGetAddressByCepRepository } from '../../../data/contracts/autov2/get-address-by-cep-repository-interface';
import { GetAddressByCepRequest } from '../../../data/models/autov2/get-address-by-cep-request';
import { GetAddressByCepResponse } from '../../../data/models/autov2/get-address-by-cep-response';
import { ViaCepResponseContract } from '../../../domain/entities/autov2/via-cep-response-contract';
import { ViaCepResponseContractDTO } from './dto/via-cep-response-contract-dto';

export class GetAddressByCepRepository implements IGetAddressByCepRepository {
  private caches: any = {};

  constructor(private httpClient: IHttpGetClient<string, ViaCepResponseContract>) {}

  async execute(requestModel: GetAddressByCepRequest): Promise<GetAddressByCepResponse> {
    if (this.caches[requestModel.cep]) {
      console.log(`[${requestModel.cep}] returnig from cache`);
    } else {
      console.log(`[${requestModel.cep}] generating cache`);
      const response = await this.httpClient.getWithUri(`ws/${requestModel.cep}/json`);
      
      if (response.erro) {
        throw new Error("endereço não encontrado para o cep informado");
      }

      this.caches[requestModel.cep] = ViaCepResponseContractDTO.translate(response);
    }

    return this.caches[requestModel.cep];
  }
}
