import { IdentifierRepositoryContract } from '@workspace/frontend/shared/domain/contracts';
import { IdentifierAppServiceContract } from '@workspace/frontend/shared/application/contracts';

class IdentifierAppService implements IdentifierAppServiceContract {
  constructor(private identifierRepository: IdentifierRepositoryContract) {}

  public get(): string {
    return this.identifierRepository.get();
  }

  public create(value: string): void {
    this.identifierRepository.create(value);
  }

  public remove(): void {
    this.identifierRepository.remove();
  }
}

export { IdentifierAppService };
