type NumericInputMode = 'numeric';
type NumericInputPattern = '[0-9]*';

type InputModes = NumericInputMode;
type InputPatterns = NumericInputPattern;

type KeyboardProps = {
  inputmode: InputModes;
  pattern: InputPatterns;
};

export interface InputMaskWebProps {
  inputKeyboardType?: KeyboardProps;
  maskType: InputMaskType | string;
  label: string;
  name?: string;
  dataTestID?: string;
  onChange?: any;
  onBlur?: any;
  autoFocus?: boolean;
  id?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
  error?: any;
  helperText?: string;
  InputLabelProps?: {
    shrink?: boolean;
  };
  variant?: any;
  renderCustomError?(message: string): JSX.Element;
}

export enum InputMaskType {
  DATE = '99/99/9999',
  CELLPHONE = '(99) 99999-9999',
  PHONE = '(99) 9999-9999',
  CPF = '999.999.999-99',
  YEAR = '9999',
  CEP = '99999-999',
  RG = '***********',
}

export const KeyboardMode: { NUMERIC: KeyboardProps } = {
  NUMERIC: {
    inputmode: 'numeric',
    pattern: '[0-9]*',
  },
};
