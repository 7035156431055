import palette from './loja-de-seguros/palette';
import typography from './loja-de-seguros/typography';
import breakpoints from './loja-de-seguros/breakpoints';

const style = {
  typography: typography,
  palette: palette,
  breakpoints: breakpoints,
  shape: {},
  overrides: {
    MuiFormHelperText: {
      root: {
        color: palette.text.primary,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '18px',
        fontFamily: 'ItauText-Light',
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiWithWidth: { initialWidth: 'xs' },
  },
};

export default style;
