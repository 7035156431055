export default {
  primary: {
    main: '#EC7000',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
    gray: '#969290',
  },
  secondary: {
    main: '#106EB0',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
    dark: '#004990',
  },
  warning: {
    main: '#781B8E',
  },
  text: {
    primary: '#2b374a',
  },
  link: {
    primary: '#106EB0',
  },
  success: {
    main: '#007A47',
  },
  background: {
    main: '#efe9e5',
    lightMain: '#FFDAB8',
  },
  error: {
    main: '#B71C1C',
  },
};
