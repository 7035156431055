import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';

export const CVVField = ({
  id = 'txtCvv_screen',
  label = 'cvv',
  type = 'tel',
}: {
  id?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name={'cvv'}
    validation={stringValidationFactory({
      requiredMessage: 'informe o cvv',
      min: {
        limit: 3,
        message: 'o cvv deve conter entre 3 e 4 caracteres',
      },
      max: {
        limit: 4,
        message: 'o cvv deve conter entre 3 e 4 caracteres',
      },
    })}
    componentProps={{ id, label, type, maskType: '9999' }}
  />
);
