import React from 'react';
import { BoxWeb, TooltipWeb } from '@workspace/frontend/shared/presentation/components';
import { BonusClassField } from '../../../../components/autoFields/BonusClassField';
import { InsuranceCompanyField } from '../../../../components/autoFields/InsuranceCompanyField';
import { CompanyInsuranceBox } from './vehicle-data-step.style';

export function BonusSection({ bonusClass, bonusClassInfo, congenereList }) {
  return (
    <BoxWeb display="flex" flexDirection={{ xs: 'column', lg: 'row' }} my="1rem">
      <BoxWeb display="flex" alignItems="flex-start" width={220} mr="3rem" mt={'1rem'}>
        <BonusClassField
          componentProps={{
            items: bonusClass,
          }}
        />
        <TooltipWeb title={bonusClassInfo} />
      </BoxWeb>

      <CompanyInsuranceBox>
        <InsuranceCompanyField items={congenereList} />
      </CompanyInsuranceBox>
    </BoxWeb>
  );
}
