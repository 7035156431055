import { CreateCartAppService } from "@workspace/frontend/shared/abandoned-cart/application/services";
import { CreateCartRepositoryFactory } from "./create-cart-repository.factory";
import { CreateCartSerializerFactory } from "./create-cart-serializer.factory";

export abstract class CreateCartAppServiceFactory {

    public static execute(): CreateCartAppService {
        return new CreateCartAppService(
            CreateCartRepositoryFactory.execute(),
            CreateCartSerializerFactory.execute(),    
        );
    }
}