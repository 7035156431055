import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { TokenAuth } from '@core/domain/entities/auth/token';
import { SendTokenAuthRepository } from '@core/infra/repository/auth/send-token-auth-repository';
import { TokenAuthUsecase } from '@core/data/services/token-auth-usecase';
import { TokenAuthResponse } from '@core/domain/entities/auth/response/token-auth-response';

export function sendTokenAuthFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<TokenAuth, TokenAuthResponse> = new AxiosHttpPostClient(
    '/autenticar/token',
    {},
    environment.gatewayBFFBaseURL.url
  );
  const sendTokenAuthRepository = new SendTokenAuthRepository(httpPostClient);
  const sendTokenUsecase = new TokenAuthUsecase(sendTokenAuthRepository);

  return sendTokenUsecase;
}
