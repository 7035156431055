import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { AnySchema } from 'yup';
import { FormControllerErrorBanner } from './form-controller-error-banner';
import { FormControllerDebug } from './form-controller-debug';

type TAddFieldValidation = (fieldsName: string, validation: AnySchema) => void;
type TRemoveField = (name: string) => void;

type TFormContext = { addFieldValidation: TAddFieldValidation; removeField: TRemoveField };

export const FormControllerContext = createContext<TFormContext>({
  addFieldValidation(name: string, validation: AnySchema) {
    console.warn('function not yet implemented');
  },
  removeField(name: string) {
    console.warn('function not yet implemented');
  },
});

const FormControllerMethodsContext = createContext({});

export function useFormControllerMethods() {
  return useContext(FormControllerMethodsContext) as UseFormReturn;
}

interface FormControllerParams {
  debug?: boolean;
  children: JSX.Element[] | JSX.Element | ((props?: UseFormReturn<Record<string, any>>) => React.ReactElement);
  onSubmit?: any;
  initialValues?: { [x: string]: any };
  hideErrors?: boolean;
  validationMode?: 'onSubmit' | 'onChange' | 'onBlur' | 'onTouched' | 'all';
  id?: string;
}

const dynamicSchema: any = {};
export function FormController({
  debug = false,
  children,
  onSubmit,
  initialValues = {},
  hideErrors = false,
  validationMode = 'onSubmit',
  id = 'form',
}: FormControllerParams): JSX.Element {
  const [schema, setSchema] = useState(yup.object({}));
  const [watch, setWatch] = useState(0);
  if (dynamicSchema[id] === undefined) {
    dynamicSchema[id] = {};
  }

  const addFieldValidation = (fieldsName: string, validation: AnySchema) => {
    dynamicSchema[id][fieldsName] = validation;
    setWatch(watch + 1);
  };
  const removeField = (name: string) => {
    dynamicSchema[id][name] = undefined;
    setWatch(watch + 1);
  };

  useEffect(() => {
    const newSchema = yup.object({ ...dynamicSchema[id] });
    setSchema(newSchema);
  }, [watch]);

  const _onSubmit = (data: any) => {
    const _data = schema.cast(data);
    onSubmit(_data);
  };

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: validationMode,
  });

  return (
    <FormProvider {...hookFormMethods}>
      <FormControllerContext.Provider value={{ addFieldValidation, removeField }}>
        <FormControllerMethodsContext.Provider value={{ ...hookFormMethods }}>
          {!hideErrors && <FormControllerErrorBanner />}
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => {
              hookFormMethods.handleSubmit(_onSubmit)(e);
              e.stopPropagation();
            }}
            noValidate
            id={id}
          >
            {debug && <FormControllerDebug />}
            {typeof children === 'function' ? children(hookFormMethods) : children}
          </form>
        </FormControllerMethodsContext.Provider>
      </FormControllerContext.Provider>
    </FormProvider>
  );
}
