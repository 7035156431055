import { List, ListItem, ListItemLink, ListItemIcon, ListItemLabel } from './mobile-style';
import { DropdownOption } from './types';

type OptionsListProps = { listItems: Array<DropdownOption>; id: string };

const OptionsList = ({ listItems, id }: OptionsListProps) => (
  <List id={id} role="menu">
    {listItems.map((item) => (
      <ListItem role="menuitem">
        <ListItemLink
          type="button"
          role="link"
          onClick={item.onClick}
          key={item.id}
          aria-label={item.label}
          aria-live="polite"
        >
          <ListItemIcon aria-hidden={true}>{item.icon}</ListItemIcon>
          <ListItemLabel aria-hidden={true}>{item.label}</ListItemLabel>
        </ListItemLink>
      </ListItem>
    ))}
  </List>
);

export default OptionsList;
