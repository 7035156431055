import { createContext, useContext, useState } from 'react';

const ErrorContext = createContext(null);

type ErrorCodes =
  | 'emailIsRequired'
  | 'emailOrPasswordInvalid'
  | 'passwordIsRequired'
  | 'loginIsInvalid'
  | 'passwordIsLittleOrGreat'
  | 'blockedUser'
  | 'pendingUser'
  | 'errorInterno'
  | 'weakPassword'
  | 'phoneIsRequired'
  | 'phoneIsInvalid'
  | 'erroResponsePassword'
  | 'erroResponseUpdateStatusPassword'
  | 'dddIsRequired'
  | 'nameIsRequired'
  | 'nameIsInvalid'
  | 'documentIsRequired'
  | 'documentIsInvalid'
  | 'authenticationTokenInvalid'
  | 'authenticationTokenCreateError'
  | 'dynamoTokenCreateError'
  | 'tokenIsRequired'
  | 'tokenIsInvalid'
  | 'tokenNotSend'
  | 'sendEmailError'
  | 'authenticateTokenRquiredError'
  | 'authenticateTokenNotFoundError'
  | 'updateStatusUserPasswordError'
  | 'resendTokenError'
  | 'expiredTokenError'
  | 'recoveryPasswordTokenRequiredError'
  | 'invalidPasswordTokenRequiredError'
  | 'notificationIsRequired'
  | 'notificationIsIsInvalid'
  | 'erroResponseEQ3'
  | 'systemicError'
  | 'updateStatusPasswordError'
  | 'payloadIsRequired'
  | 'errorResendToken'
  | 'errorAuthentication'
  | 'errorRecoveryPassword'
  | 'errorGenerateHashPassword'
  | 'errorGetUser'
  | 'errorVerifyUser';

type Errors = {
  code: ErrorCodes;
  message: string;
};

type RequestError = {
  response?: {
    status?: number;
    data?: {
      data?: { status: number; errors: Errors[]; success: boolean };
    };
  };
};

type RequestErrorResponse = { message?: string; type?: 'error' | 'warning' };

type BuildConfigErrorPops = {
  complementaryMessage?: Partial<Record<ErrorCodes, string>>;
  defaultErrorMessage?: string;
};

type RequestErrorComponentProps = { show: boolean; message?: string | JSX.Element; type?: 'error' | 'warning' };

type ErrorProviderResponse = {
  buildError(errorData: RequestError, config?: BuildConfigErrorPops): RequestErrorResponse;
  requestError: RequestErrorComponentProps;
  setRequestError: React.Dispatch<React.SetStateAction<RequestErrorComponentProps>>;
};

export function ErrorProvider({ children }) {
  const [requestError, setRequestError] = useState<RequestErrorComponentProps>({ show: false });

  const defaultConfigBuildError: BuildConfigErrorPops = {
    complementaryMessage: {
      blockedUser: 'Clique em "não lembro a minha senha" para redefinir sua senha e acessar sua conta.',
    },
    defaultErrorMessage: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
  };

  const buildError = (errorData, config: BuildConfigErrorPops = defaultConfigBuildError): RequestErrorResponse => {
    const response = errorData?.response;
    const status = response?.status;
    const responseError = response?.data?.data || response?.data;

    const { complementaryMessage, defaultErrorMessage } = config;

    const type = !response || status === 417 || status >= 500 ? 'error' : 'warning';
    const error = responseError?.errors && responseError?.errors[0];

    if (type === 'error')
      return {
        type: type,
        message: defaultErrorMessage,
      };

    const hasComplementaryMessage = error.code && complementaryMessage[error.code];
    error.message += hasComplementaryMessage ? `. ${complementaryMessage[error.code]}` : '';

    return {
      type: type,
      message: error.message,
    };
  };

  return (
    <ErrorContext.Provider
      value={{
        buildError,
        requestError,
        setRequestError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
}

export default function useErrorProvider(): ErrorProviderResponse {
  return useContext(ErrorContext);
}
