import { AuthenticationRepositoryContract } from '@workspace/frontend/shared/domain/contracts';
import { SessionStorageBaseRepository } from './session-storage-base-repository';

class AuthenticationRepository extends SessionStorageBaseRepository implements AuthenticationRepositoryContract {
  constructor() {
    super('auth:safe');
  }
}

export { AuthenticationRepository };
