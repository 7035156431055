import { CELLPHONE_REGEX } from '../regex';
import { IStringValidationFactory, stringValidationFactory } from '../validation-generators/string-validation-factory';

export function cellphoneValidation(overrides: IStringValidationFactory = {}) {
  const defaultValues: IStringValidationFactory = {
    requiredMessage: 'você precisa informar o celular',
    test: [],
    matches: {
      pattern: CELLPHONE_REGEX,
      message: 'celular com número e ddd inválido',
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return stringValidationFactory({
    requiredMessage: values.requiredMessage,
    matches: values.matches,
  });
}
