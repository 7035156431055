import { CODE_CONFIRMATION } from '../regex';
import { IStringValidationFactory, stringValidationFactory } from '../validation-generators/string-validation-factory';

export function codeConfirmationValidation(overrides: IStringValidationFactory = {}) {
  const defaultValues: IStringValidationFactory = {
    requiredMessage: 'você precisa informar o código',
    test: [],
    matches: {
      pattern: CODE_CONFIRMATION,
      message: 'código incorreto',
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return stringValidationFactory({
    requiredMessage: values.requiredMessage,
    matches: values.matches,
  });
}
