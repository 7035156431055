import { rest } from 'msw';

export const faqPet = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/faq/Pet`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          uid: 'blt76969d19e6d92e40',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'a assistência Pet aceita, qualquer animal?',
          resposta: 'Não, está limitado a cães e gatos.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt6918e75846c0f4a3',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'quais são os serviços oferecidos pela assistência Pet?',
          resposta:
            'Assistência Pet funeral, castração, Vacinas e aplicação (Antirrábica, Polivalente e Bodetella), Hospedagem em caso de Viagem, Transporte e Hospedagem do PET, Consulta Veterinária Emergencial e Atendimento Ambulatorial.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt0c9b6adec6890947',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'o que é a assistência Pet?',
          resposta:
            'A Assistência Pet trata-se de uma prestação de Assistência ao Animal Doméstico, dentro das condições gerais.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt6f181024078b4746',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt6de55acfad5af03d',
            titulo: 'assistência vacina e aplicação',
          },
          pergunta: 'qual o limite de vacinas?',
          resposta: 'É disponibilizado 01 utilização de cada vacina mencionada.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8ee60a3609596b5d',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'como funciona a assistência e como faço a ativação?',
          resposta:
            'A assistência encontra-se disponível 24 horas por dia e para você solicitar algum serviço oferecido na assistência contratada, tirar suas dúvidas e pedir informações é só entrar em contato no telefone 0800 718 0115.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4d4600cb9244312b',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'existe algum limite de serviços que posso acionar?',
          resposta:
            'A quantidade de utilizações varia em cada serviço, então você pode consultar nas condições gerais de acordo com a sua assistência contratada. Lembrando que deve ser utilizado no período de 12 (doze) meses.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blta1f9ca63d2c356aa',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'como é a rede de prestadores da assistência?',
          resposta:
            'Nossa rede tem capacidade de atender todo território nacional e para nossa segurança, passamos por um processo de credenciamento e avaliação criterioso.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt5a8e3c491bb0639b',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'posso acionar a assistência para qualquer localidade?',
          resposta: 'Pode sim, desde que seja no território brasileiro.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt418e9cbd7bed508f',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'quantas intervenções tenho disponíveis?',
          resposta:
            'Consulta veterinária por telefone - ilimitado <br>Consulta veterinária emergencial - Até 02 intervenções',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8eb5a61a3abe37fb',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'meu Pet precisa de uma consulta veterinária de rotina, como aciono o serviço?',
          resposta:
            'O Serviço de Assistência PET será realizado por uma empresa Prestadora de Serviço, através de uma Central de Atendimento, pelo telefone 0800 718 0115.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6637dcf25699b52d',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'posso utilizar a assistência Pet para mais de um Pet?',
          resposta: 'Sim, desde que tenham intervenções disponíveis.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt38afd91e1195deb3',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'quais são os serviços oferecidos no pacote principal?',
          resposta:
            'A Assistência Pet trata-se de uma prestação de Assistência ao Animal Doméstico, dentro das condições gerais: Orientação veterinária por telefone, consulta emergencial veterinária e atendimento ambulatorial',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltc0e09c8c16432326',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'quantas opções de serviços possuo na Orientação Veterinário por Telefone?',
          resposta:
            'Para orientação veterinária por telefone: orientações de primeiros socorros em situações emergenciais e/ou indicações de clínicas e veterinários.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt5b68dde969844a20',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt9a52198f8054e4a7',
            titulo: 'serviços pet',
          },
          pergunta: 'quantas opções de serviços possuo na Consulta Emergencial Veterinária e Atendimento Ambulatorial?',
          resposta:
            'Para consulta emergencial veterinária e atendimento ambulatorial: em consequência de uma emergência (acidente ou doença súbita), a Assistência providenciará, através da sua rede credenciada, a consulta veterinária emergencial, além dos exames e medicamentos necessários para o animal assistido, respeitando o limite de utilização contratado.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltf57b12412496f2a2',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltd4ba54bee0117da0',
            titulo: 'assistência funeral do pet',
          },
          pergunta: 'ocorreu um óbito do meu animal de estimação, o que eu faço?',
          resposta:
            'Na hipótese de óbito do animal de estimação, a IKE arcará com as despesas de organização do funeral, cremação ou enterro, conforme disponibilidade local.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt1c1861a5556ca563',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltd4ba54bee0117da0',
            titulo: 'assistência funeral do pet',
          },
          pergunta: 'qual é a carência?',
          resposta: 'A carência é de 60 dias.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt39a8d2a2bf25d26a',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltd4ba54bee0117da0',
            titulo: 'assistência funeral do pet',
          },
          pergunta: 'quantas intervenções tenho disponíveis?',
          resposta: 'Até 01 utilização.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6fcaa2f0de29f106',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt6de55acfad5af03d',
            titulo: 'assistência vacina e aplicação',
          },
          pergunta: 'meu animal de estimação precisa ser vacinado, o que eu faço?',
          resposta:
            'Estará à disposição do usuário a vacinação para seu pet, sendo a mesma aplicada na rede credenciada indicada pela Assistência.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltdea171d2ae0003ea',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt6de55acfad5af03d',
            titulo: 'assistência vacina e aplicação',
          },
          pergunta: 'quais vacinas são cobertas pelo serviço?',
          resposta: 'Vacinas antirrábica, polivalente e bordetella.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt3a77dbc2b196b78f',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt6de55acfad5af03d',
            titulo: 'assistência vacina e aplicação',
          },
          pergunta: 'qual é a carência?',
          resposta: 'A carência é de 60 dias.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8d678be5c73c90f4',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt16162788caecfdbe',
            titulo: 'atendimento',
          },
          pergunta: 'como posso obter mais informações ou acionar minha assistência?',
          resposta:
            'Para obter informações ou acionar sua assistência ligue para a Realiza no telefone:<br><br> Realiza <br> 0800 718 0115 (Em todo território nacional) <br><br> Itaú Corretora <br> 4090 1125 (Capitais e regiões metropolitanas) <br> 0800 791  1125 (Demais localidades)',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4ab39e1f6438ab34',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt3f8f7b4dcff623f6',
            titulo: 'cancelamento',
          },
          pergunta: 'em qual situação ocorre o cancelamento da minha assistência?',
          resposta: 'O cancelamento pode ser feito em casos de inadimplência após 60 dias.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltef83f9bd69cef7f5',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt3f8f7b4dcff623f6',
            titulo: 'cancelamento',
          },
          pergunta: 'como é a política para cancelar minha assistência?',
          resposta:
            'Você pode cancelar o seu produto na própria Loja pelo link no rodapé da página, através do telefone da empresa parceira 0800 718 0115',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltb29ad67720e7672c',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltb76e960f01432d48',
            titulo: 'hospedagem pet',
          },
          pergunta: 'qual o limite de utilizações?',
          resposta: 'É disponibilizado até 02 utilizações.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltdb604152b23d835f',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltb76e960f01432d48',
            titulo: 'hospedagem pet',
          },
          pergunta: 'qual é a carência?',
          resposta: 'Não há carência.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blte1d9c6ab16c5b470',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'bltb76e960f01432d48',
            titulo: 'hospedagem pet',
          },
          pergunta: 'vou viajar e estou impossibilitado de levar o meu animal de estimação, o que eu faço?',
          resposta:
            'A Assistência disponibiliza a hospedagem em hotéis da rede credenciada para cuidar do animal de estimação.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4360ab7baf8892bc',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt395a93c87318d599',
            titulo: 'assistência castração pet',
          },
          pergunta: 'qual o limite de utilizações?',
          resposta: 'É disponibilizado 01 utilização.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt310f3c08604be491',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt395a93c87318d599',
            titulo: 'assistência castração pet',
          },
          pergunta: 'qual é a carência?',
          resposta: 'A carência é de 90 dias.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8e81e77f0917473a',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt395a93c87318d599',
            titulo: 'assistência castração pet',
          },
          pergunta: 'como faço para solicitar a castração  do meu animal de estimação?',
          resposta:
            'A cirurgia de castração estará disponível para seu pet, mediante o agendamento prévio junto a rede credenciada da Assistência.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt03bd60d3abc48129',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'sou menor de idade, posso contratar a Assistência Pet?',
          resposta: 'A idade mínima para a contratação do serviço de assistência é de 18 anos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltf43a4b09789cf67e',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'vou receber algum material informativo após a contratação das assistências?',
          resposta:
            'Após a aquisição da assistência em nosso site, você vai receber um certificado e todas as informações necessárias para o uso das suas assistências.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4e28fd3eeb087097',
          produto: {
            uid: 'bltaf3b58c84197ab4e',
            titulo: 'Pet',
          },
          categoria: {
            uid: 'blte0ee81114c86da35',
            titulo: 'pagamento',
          },
          pergunta: 'quais são as formas de pagamento da assistência?',
          resposta:
            'O pagamento é realizado via cartão de crédito, e as bandeiras aceitas são: Visa, MasterCard, American Express, Aura, Diners (somente emitidos no exterior).',
          frequente: false,
          ativo: true,
        },
      ])
    )
  );
};
