import { BoxWeb, SelectOption } from '@workspace/frontend/shared/presentation/components';
import { CreditCardI } from './payment-methods-switcher';
import { CreditCard } from '@workspace/frontend/auto/presentation/components';
import React from 'react';
import { InstallmentField } from '@web/form/component';
import styled from 'styled-components';
import { AllowedBadgesSection } from './components/allowed-badges-section';
import { possibleBadgesArray } from '@workspace/frontend/auto/domain/entities';
import { CreditCardIcon } from '@web/assets';

const InstallmentFieldContainer = styled.div`
  width: 350px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const creditCardSectionFactory = (installmentNumberOptionsMock: SelectOption[], allowedBadges) =>
  new CreditCardSection(
    'credit-card-payment-type',
    'cartão de crédito',
    '',
    (
      <BoxWeb m={'0.5rem'} fontSize={'26px'}>
        <img src={CreditCardIcon} width={'100%'} />
      </BoxWeb>
    ),
    installmentNumberOptionsMock,
    allowedBadges
  );

class CreditCardSection implements CreditCardI {
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public renderIcon: JSX.Element,
    public installments: any,
    public allowedBadges?: possibleBadgesArray
  ) {}

  //seção do cartao
  render() {
    return (
      <>
        <AllowedBadgesSection allowedBadges={this.allowedBadges} />
        <CreditCard allowedBadges={this.allowedBadges} />
        <BoxWeb height={36} />
        <InstallmentFieldContainer>
          <InstallmentField options={this.installments} />
        </InstallmentFieldContainer>
      </>
    );
  }
}
