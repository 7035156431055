import { useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { BaseStepper } from './stepper/base-stepper';

import { ValidateCPFContainer } from './screens/validate-cpf-container';
import { RegisterPasswordContainer } from './screens/register-password-container';
import { CodeConfirmationContainer } from './screens/code-confirmation-container';
import { SuccessContainer } from './screens/success-container';
import { RegisterPersonalDataContainer } from './screens/register-personal-data-container';

import { ErrorProvider } from '../../providers/error-provider';
import { Step } from './stepper/step';
import { AuthStateManager } from '@workspace/frontend-shared-application-state-manager';

export const RegisterContainer = () => {
  const { Theme } = useThemeProvider();
  const initialStep = AuthStateManager.registrationStepSelector();

  const steps: Array<Step> = [
    {
      title: 'cpf',
      component: ValidateCPFContainer,
    },
    {
      title: 'dados pessoais',
      component: RegisterPersonalDataContainer,
    },
    {
      title: 'criação de senha',
      component: RegisterPasswordContainer,
    },
    {
      title: 'confirmação do código',
      component: CodeConfirmationContainer,
    },
    {
      title: 'sucesso!',
      component: SuccessContainer,
    },
  ];

  return (
    <SCThemeProvider theme={Theme}>
      <ErrorProvider>
        <BaseStepper steps={steps} hideSteps={true} initialStep={initialStep} />
      </ErrorProvider>
    </SCThemeProvider>
  );
};
