import { VehicleResponseData } from '@core/domain/entities/autov2/response/vehicle';
import { IFindVehicleByPlateUsecase } from '@core/domain/usecases/find-vehicle-by-plate-usecase';
import { IFindVehicleByPlateRepository } from '../contracts/autov2/find-vehicle-by-plate-repository';

export class FindVehicleByPlateUsecase implements IFindVehicleByPlateUsecase {
  constructor(
    private readonly findVehicleByPlateRepository: IFindVehicleByPlateRepository,
    private readonly onError: (error) => void
  ) {}

  async execute(plate: string): Promise<VehicleResponseData> {
    try {
      const res = await this.findVehicleByPlateRepository.execute(plate);
      return res.data.data;
    } catch (error) {
      this.onError(error?.response);
    }
  }
}
