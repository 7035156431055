import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';

import { useEnvironment } from '@workspace/providers';
import { GetFaqBannerUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';
import { GetFAQBannerRepository } from '@workspace/frontend/faq-v2/infra/repositories';
import { FAQBannerResponse } from '@workspace/frontend/faq-v2/domain/entities';

export function getFAQBannerFactory() {
  const { environment } = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQBannerResponse> = new AxiosHttpGetClient(
    `/content_types/faq_banner/entries?environment=${environment?.cmsBase?.environment}`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQBannerRepository = new GetFAQBannerRepository(httpGetClient);
  return new GetFaqBannerUseCase(getFAQBannerRepository);
}
