import { CreateCartRepositoryContract } from '@workspace/frontend/shared/abandoned-cart/domain/contracts';
import { CreateCartPayload } from '@workspace/utils/abandoned-cart';
import { PostHttpClientContract } from './http/contracts/post-http-client.contract';

export class CreateCartRepository implements CreateCartRepositoryContract {
  constructor(private readonly postHttpClient: PostHttpClientContract) {}
  public async execute(input: CreateCartPayload): Promise<void> {
    await this.postHttpClient.execute({
      payload: input,
      uri: 'abandonedcart/createcart',
    });
  }
}
