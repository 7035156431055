import { PageLoadAutoV2HomeTechSpec as HomePageLoadTagsWithoutOrigin } from './page-load-tags';
import { ActionEventTags as HomeActionsTagsWithoutOrigin } from './action-event-tags';
import { registerAnalyticsEvent } from '@workspace/web/analytics';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const HomeAnalytics = (origin) => {
  const HomePageLoadTags = HomePageLoadTagsWithoutOrigin(origin);
  const HomeActionsTags = HomeActionsTagsWithoutOrigin(origin);
  return {
    LOAD: () => RAE(HomePageLoadTags.T1),
    DETAIL: () => RAE(HomeActionsTags.A2()),
    CONTINUE: () => RAE(HomeActionsTags.A1()),
    CONTINUE_2: () => RAE(HomeActionsTags.A3()),
  };
};
