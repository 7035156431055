import { rest } from 'msw';

interface UserByCPFRequestProps {
  document: string;
}

export const findUserByCPFAuthMock = (env) => {
  return rest.post<UserByCPFRequestProps>(`${env.gatewayBFFBaseURL.url}/verificausuarioexiste`, (req, res, ctx) => {
    const { document } = req.body;

    const docStatus = {
      invalid: !document,
      registered: document === '116.510.030-41',
      systemicError: document === '753.561.390-04',
    };

    if (docStatus.invalid) {
      return res(
        ctx.status(409),
        ctx.json({
          data: {
            success: false,
            value: undefined,
            errors: [
              {
                code: 'documentIsRequired',
                message: 'Documento é obrigatório',
              },
            ],
          },
        })
      );
    }

    if (docStatus.registered) {
      return res(
        ctx.status(200),
        ctx.json({
          data: {
            success: true,
            value: {
              foundIn: 2,
              status: 'ATIVA',
              email: 'a******a@email.com',
            },
          },
        })
      );
    }

    if (docStatus.systemicError) {
      return res(
        ctx.status(417),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'systemicError',
                message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
              },
            ],
          },
        })
      );
    }

    return res(
      ctx.status(409),
      ctx.json({
        data: {
          success: false,
          errors: [
            {
              code: 'errorVerifyUser',
              message: 'Erro ao verificar usuário',
            },
          ],
        },
      })
    );
  });
};
