import { rest } from 'msw';
import odontoOffers from './odonto_offers.json';
import residOffers from './resid_offers.json';
import autoOffers from './auto_offers.json';

export const offerSearch = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/offers/search`, (req, res, ctx) => {
    const productName = req.headers.get('product-name');
    const PRODUCT_NAMES = {
      ODONTO: 'Odonto',
      RESID: 'Residencial',
      AUTO: 'Auto',
    };

    switch (productName) {
      case PRODUCT_NAMES.ODONTO: {
        return res(ctx.status(200), ctx.json(odontoOffers));
      }
      case PRODUCT_NAMES.RESID: {
        return res(ctx.status(200), ctx.json(residOffers));
      }
      case PRODUCT_NAMES.AUTO: {
        return res(ctx.status(200), ctx.json(autoOffers));
      }
      default:
        return;
    }
  });
};
