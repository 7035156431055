// Factories
import { CELLPHONE_REGEX, CODE_CONFIRMATION, NAME_REGEX, PHONE_REGEX } from './regex';

export * from './validation-generators/string-validation-factory';
export { booleanValidationFactory } from './validation-generators/boolean-validation-factory';
export { numberValidationFactory } from './validation-generators/number-validation-factory';

// Default validations
export { cellphoneValidation } from './validations/cellphone-validation';
export { phoneValidation } from './validations/phone-validation';
export { cepValidation } from './validations/cep-validation';
export { cpfValidation } from './validations/cpf-validation';
export { dateValidation } from './validations/date-validation';
export { emailValidation } from './validations/email-validation';
export { nameValidation } from './validations/name-validation';
export { trueBooleanValidation } from './validations/true-boolean-validation';
export { passwordValidation } from './validations/password-validation';
export { confirmPasswordValidation } from './validations/confirm-password-validation';

// Validation tests
export { pastDateTest } from './validation-tests/past-date-test';
export { futureDateTest } from './validation-tests/future-date-test';
export { dateFormatTest } from './validation-tests/date-format-test';
export { cpfTest } from './validation-tests/cpf-test';
export { codeConfirmationValidation } from './validations/code-confirmation-validation';

export { NAME_REGEX, EMAIL_REGEX, CELLPHONE_REGEX, PHONE_REGEX, CODE_CONFIRMATION } from './regex';
