import firebase from 'firebase/app';
// Import all modules from tihs environment
// import 'firebase/performance';

const firebaseConfig = {
  apiKey: 'AIzaSyBUGkcV3ADFRORKU8KUUocbEUQEP39B-ik',
  authDomain: 'zup-itau-seguros-qa.firebaseapp.com',
  databaseURL: 'https://zup-itau-seguros-qa.firebaseio.com',
  projectId: 'zup-itau-seguros-qa',
  storageBucket: 'zup-itau-seguros-qa.appspot.com',
  messagingSenderId: '567370069477',
  appId: '1:567370069477:web:323eb5785428edc6a96b0c',
};

export const initFirebase = () => {
  if (firebase?.apps?.length === 0 && !firebase.apps.some((a) => a.name === '[DEFAULT]'))
    firebase.initializeApp(firebaseConfig);

  // initialize modules
  const performance = firebase.performance();
  const remoteConfig = firebase.remoteConfig();
  const analytics = firebase.analytics();
  const database = firebase.firestore();
  const auth = firebase.auth();
  // remoteConfig.settings = { minimumFetchIntervalMillis: 0 };
  return { performance, remoteConfig, analytics, database, auth };
};
