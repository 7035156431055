import { shared } from '../shared/environment.hom';
import { FrontendEnvironment } from './frontend.model';
export const environment: FrontendEnvironment = {
  ...shared,
  environment: 'hom',
  recaptchaSiteKey: '6Leui7cZAAAAAPttkTQyu37RbITgtfsfDu7SOtd3',
  recaptchaV2SiteKey: '6LeIzpscAAAAAMrqEstjgFUfmef9bGSRsMlCk7GZ',
  gatewayBFFBaseURL: {
    url: 'https://lojadeseguros-api.hom.cloud.itau.com.br/v1',
  },
  gatewayZupBaseURL: {
    url: 'https://itau-azr.staging.gateway.zup.me/',
    apiAppKey: '46f42d50802a0139990a16c8e5eebdc7',
  },
  gatewayAWSBaseURL: {
    url: 'https://mktseguros-api.hom.cloud.itau.com.br',
  },
  leadPath: {
    path: '/v1/mktseguros/leads',
  },
  autoV2Susep: '9JQ9XJ',
  leadProcessor: {
    env: 'qa',
    products: [
      {
        codigo: 'b84c230f-481c-467e-a373-4bd8c37cf3c9',
        nome: 'Auto',
      },
    ],
  },
  channel: 'loja',
  eligibility: {
    path: '/mkt-seguros-e2e/v1/elegibilidade?gw-app-key=',
  },
  enableAutov2Hiring: false,
  tokenAuth: {
    path: '/autenticar/token',
  },
  resendToken: {
    path: '/autenticar/reenviotoken',
  },
  autoV2Offers: {
    path: '/oferta',
  },
  evolutionCloudfrontBase: 'https://lojadeseguros-evolucao.hom.cloud.itau.com.br',
  firebaseConfig: {
    apiKey: 'AIzaSyBUGkcV3ADFRORKU8KUUocbEUQEP39B-ik',
    authDomain: 'zup-itau-seguros-qa.firebaseapp.com',
    databaseURL: 'https://zup-itau-seguros-qa.firebaseio.com',
    projectId: 'zup-itau-seguros-qa',
    storageBucket: 'zup-itau-seguros-qa.appspot.com',
    messagingSenderId: '567370069477',
    appId: '1:567370069477:web:323eb5785428edc6a96b0c',
    measurementId: 'G-3JJTRLHTTK',
  },
  libFrameworkDA: 'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/importLibJSFrameworkDA.js?GTM-NQFGCF3',
  frameworkDA: 'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/frameworkDA.js?GTM-NQFGCF3',
  optimize: 'https://www.googleoptimize.com/optimize.js?id=GTM-MTPNW5H',
  enableGoogleAnalytics: true,
  enableCampanhaAuto: true,
};

