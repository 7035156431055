import { RadioGroupProps } from '../../models/radio-group';
import { useThemeProvider } from '../..';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export const RadioGroupWeb = (props: RadioGroupProps) => {
  const { Components } = useThemeProvider();
  const { RadioGroup } = Components;

  return (
    <ThemeProviderInjector>
      <RadioGroup {...props} />
    </ThemeProviderInjector>
  );
};
