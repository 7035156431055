import React from 'react';
import { Container } from './style';
import { LogoItau } from '@web/assets';

export const Loading = () => {
  return (
    <Container>
      <div className="logo">
        <img width="100px" src={LogoItau} alt="Logo Itaú" aria-hidden="true" />
        <div id="progress"></div>
      </div>
    </Container>
  );
};
export default Loading;
