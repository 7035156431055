import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { SendUserAuthRepository } from '@core/infra/repository/auth/send-user-auth-repository';
import { SendUserAuthUsecase } from '@core/data/services/send-user-auth-usecase';
import { UserAuthResponse } from '@core/domain/entities/auth/response/user';
import { UserAuth } from '@core/infra/models/send-user-auth';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

export type SendUserAuthErrorProps = Omit<UserAuthResponse['data'], 'value'> & {
  status: number;
};
export type SendUserAuthFactoryProps = {
  onError?: (error: SendUserAuthErrorProps) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

export function sendUserAuthFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<UserAuth, UserAuthResponse> = new AxiosHttpPostClient<
    UserAuth,
    UserAuthResponse
  >('usuarios/login', {}, environment.gatewayBFFBaseURL.url);

  const sendUserAuthRepository = new SendUserAuthRepository(httpPostClient);
  return new SendUserAuthUsecase(sendUserAuthRepository);
}
