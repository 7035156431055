import { useEffect, useRef, useState } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import { useWindowWidth } from './use-window-width';
import { Box } from '@material-ui/core';
import { Step } from './step';

interface DefaultStepperProps {
  steps: Step[];
  activeStep?: number;
}

export function StepperMobile({ steps, activeStep }: DefaultStepperProps) {
  const width = useWindowWidth();
  const stepperRef = useRef(undefined);
  const [maxStepLength, setMaxStepLength] = useState<number>(steps.length + 1);

  useEffect(() => {
    setTimeout(() => {
      if (stepperRef.current) stepperRef.current.focus();
    }, 600);
  }, [activeStep]);

  const handleProgressValue = () => {
    const maxWidth = 1104;
    if (width <= maxWidth) {
      return ((activeStep + 1) / steps.length) * 100;
    }

    const progressPlace = (maxWidth / steps.length) * (activeStep + 1);

    return (((width - maxWidth) / 2 + progressPlace) * 100) / width;
  };

  return (
    <>
      <Box height={'81px'}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          my={'1rem'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <Box tabIndex={-1} pr={'1rem'} ref={stepperRef} aria-label={`você está na etapa ${steps[activeStep]?.title}`}>
            <Box mr={'0.5rem'} display={'inline'} fontSize={'18px'}>
              <i className={'icon-itaufonts_chat_enviado'} />
            </Box>
            {steps[activeStep]?.title}
          </Box>
          <div aria-atomic="true" aria-label={`etapa ${activeStep + 1} de ${steps.length}`}>
            {`etapa ${activeStep + 1} de ${steps.length}`}
          </div>
        </Box>
        <MobileStepper
          variant="progress"
          steps={maxStepLength}
          backButton={() => null}
          nextButton={() => null}
          position="static"
          activeStep={activeStep + 1}
          LinearProgressProps={{ 'aria-hidden': true, value: handleProgressValue(), style: { width: '100%' } }}
        />
      </Box>
      {/*{steps[activeStep || 0]?.component()}*/}
    </>
  );
}
