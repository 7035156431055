import { BoxWeb, OutlinedButtonWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

const DoubtContentContainer = styled.div`
  padding: 0 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;
  }
`;

const Strong = styled.strong`
  font-weight: bold;
  white-space: nowrap;
  width: fit-content;
  margin-right: 3px;
`;

const Line = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  line-height: 1.7;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    white-space: break-spaces;
  }
`;

export function DoubtContent({ openLeadModal }) {
  return (
    <DoubtContentContainer>
      <BoxWeb height={32} />

      <Strong>dúvidas?</Strong>

      <Line>
        <Strong>4020 2031 </Strong>
        <span> - capitais e regiões metropolitanas</span>
      </Line>

      <Line>
        <Strong>0800 545 1150 </Strong>
        <span> - demais regiões</span>
      </Line>

      <span>atendimento: 2ª a 6ª das 9h à 18h</span>

      <BoxWeb height={16} />

      <span>se preferir deixe seus dados e nós entramos em contato no horário que você escolher</span>
      <BoxWeb mt="0.5rem">
        <OutlinedButtonWeb label="entrar em contato" onClick={openLeadModal} />
      </BoxWeb>
    </DoubtContentContainer>
  );
}
