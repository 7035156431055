import { FormControllerFieldConnector } from '@web/form';
import { SelectWeb, SelectWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';

export const BankField = ({
  id = 'rdoBank_screen',
  label = 'selecione seu banco',
  options = [],
}: {
  id?: string;
  label?: string;
  options?: Array<{ value: string; key: string }>;
}): JSX.Element => (
  <FormControllerFieldConnector<SelectWebProps>
    component={SelectWeb}
    name={'bank'}
    validation={stringValidationFactory({
      requiredMessage: 'você precisa informar um banco',
    })}
    componentProps={{ id, label, items: options }}
  />
);
