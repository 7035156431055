import styled from 'styled-components';

export const ProductsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem calc((100vw - 1300px) / 2);
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 2rem 1rem;
  }
`;

export const Title = styled.span`
  font-size: 26px;
  font-family: 'ItauDisplay-Light';
  width: 100%;

  &:before {
    content: 'propostas ';
    font-family: 'ItauDisplay-Regular';
    font-weight: 700;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 20px;
  }
`;

export const LightText = styled.span`
  font-size: 1.625rem;
  font-family: 'ItauDisplay-Light';
  color: #2b374a;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.25rem;
  }
`;

export const RegularText = styled.span`
  width: 100%;
  font-size: 1rem;
  font-family: 'ItauDisplay-Regular';
  color: #2b374a;
`;

export const BoldText = styled.span`
  font-size: 1.625rem;
  font-family: 'ItauDisplay-Regular';
  font-weight: 700;
  color: #2b374a;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.25rem;
  }
`;

export const HighlightText = styled.span`
  width: 100%;
  font-size:  1.625rem;
  font-family: 'ItauDisplay-Regular';
  color: #ec7000;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.25rem;
  }
`;

export const Card = styled.div`
  margin-top: 24px;
  height: 86px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 6fr 1fr;
  gap: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: 208px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    padding: 19px;
  }
`;

export const CardTitle = styled.p`
  font-size: 1.5rem;
  font-family: 'ItauText-Bold';
  color: #ec7000;
  margin-left: 14px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: 0 0 0 9px;
  }
`;

export const CardImg = styled.img`
  width: 48px;
  height: 48px;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 36px;
    height: 36px;
  }
`;

export const CardDescriptive = styled.p`
  font-size: 18px;
  font-family: 'ItauText-Regular';

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    max-width: 185px;
  }
`;

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CardInfoTitle = styled.p`
  font-size: 18px;
  font-family: 'ItauText-Bold';
  margin: 17px 0 7px 0;
`;

export const CardInfoText = styled.span`
  font-size: 18px;
  font-family: 'ItauText-Regular';
`;

export const CardInfoCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #007a47;
  margin-right: 8px;
`;

export const CardButton = styled.button`
  width: 150px;
  height: 48px;
  font-size: 19px;
  font-family: 'ItauDisplay-Bold';
  border: 1px solid #106eb0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #106eb0;
  margin-right: 26px;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: 0;
  }
`;

export const Divider = styled.div`
  border-bottom: 2px #efe9e5 solid;
  width: 649px;
`;
