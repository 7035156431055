import { Topic } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQTopicRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQTopicUseCase {
  execute(): Promise<Topic[]>;
}

export class GetFaqTopicUseCase implements IGetFAQTopicUseCase {
  constructor(private readonly getFAQTopicRepository: IGetFAQTopicRepository) {}

  async execute(): Promise<Topic[]> {
    return await this.getFAQTopicRepository.execute();
  }
}
