import React from 'react';
import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';

const SummaryAccordionGroupContainer = styled.div`
  table {
    width: 700px;
    padding-bottom: 1.5rem;
  }
  table tr th {
    text-align: left;
    width: 100px;
    padding: 0.75rem 0;
  }
  .title {
    font-weight: bold;
  }
  .title,
  .value {
    max-width: 250px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: lightgray;
`;

const BoxContent = styled.section`
  display: flex;
  flex-flow: column;
  margin: 1rem;
`;

export function SummaryAccordionGroup({ title, content }) {
  return (
    <SummaryAccordionGroupContainer>
      <BoxWeb height={46} width={'100%'} />
      <BoxWeb fontSize={'1.5em'} fontWeight={'bold'}>
        {title}
      </BoxWeb>
      <Divider />
      <BoxWeb height={16} width={'100%'} />
      <BoxWeb display={'flex'} justifyContent={'flex-start'} flexWrap={'wrap'} width={'100%'}>
        {content.map((data) => (
          <BoxContent>
            <span className={'title'}>{data.title}</span>
            <span className={'value'}>{data.value}</span>
          </BoxContent>
        ))}
      </BoxWeb>
    </SummaryAccordionGroupContainer>
  );
}
