import React, { useEffect, useState } from 'react';
import { useEnvironment } from '@workspace/providers';
import { ANALYTICS_BLOG } from '@workspace/web/analytics';
import { BoxWeb, HiddenWeb } from '@workspace/frontend/shared/presentation/components';
import CircularProgress from '@material-ui/core/CircularProgress';

//style
import {
  HeadlineContainer,
  WrapperContainer,
  HeroContainer,
  CategoryFilterDesktopContainer,
  CategoryFilterMobileContainer,
  NewsletterContainer,
  PostCardsContainer,
} from './home-style';

import { getCardArticleFactory } from '../../factories';
import { TextCarousel, Dropdown, Album, Hero } from '../../components';
import { useDynamicBlog } from '../../provider/blog-provider';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DEFAULT_VALUE = '';

const Home = () => {
  const enviroment = useEnvironment();
  const [breaknews, setBreaknews] = useState<any>([]);
  const [recentloading, setRecentLoading] = useState(true);
  const [showReadMoreButton, setShowReadMoreButton] = useState(true);
  const [postLoading, setPostLoading] = useState(true);
  const [articles, setArticles] = useState<{ posts: any; category: string; skip: number }>({
    posts: [],
    category: DEFAULT_VALUE,
    skip: 0,
  });

  const { blogCategories } = useDynamicBlog();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getRecentCards = async () => {
    setRecentLoading(true);
    const getPaginationArticle = getCardArticleFactory(enviroment, '3', '0');
    const result = await getPaginationArticle.execute();
    setBreaknews(result.entries);
    setRecentLoading(false);
  };

  const getPosts = async (quantity) => {
    setPostLoading(true);
    const getPaginationArticle = getCardArticleFactory(
      enviroment,
      String(quantity),
      String(articles.skip),
      articles.category === DEFAULT_VALUE ? undefined : articles.category
    );
    const result: any = await getPaginationArticle.execute();

    const newPosts = [...articles.posts, ...result?.entries];
    setPostLoading(false);

    setShowReadMoreButton(newPosts.length < result?.count);
    setArticles({ ...articles, posts: newPosts });
  };

  const loadMore = () => {
    setArticles({ ...articles, skip: articles.skip === 0 ? 6 : articles.skip + 3 });
    ANALYTICS_BLOG.HOME.KEEP_READING();
  };
  useEffect(() => {
    ANALYTICS_BLOG.HOME.LOAD();
    getRecentCards();
  }, []);
  useEffect(() => {
    getPosts(articles.skip === 0 ? 6 : 3);
  }, [articles.skip, articles.category]);

  const onClickCategoryItem = (uid) => {
    setArticles({ posts: [], skip: 0, category: uid });
  };
  return (
    <WrapperContainer p={{ sm: 8 }}>
      <HeadlineContainer data-testid="title-container" variant="h1">
        Com você em todos os momentos
      </HeadlineContainer>

      {isMobileScreen && (
        <CategoryFilterMobileContainer data-testid="categories-mobile">
          <Dropdown
            onClickItem={onClickCategoryItem}
            listTitle="categorias"
            defaultValue={{ title: 'Mais Recentes', value: DEFAULT_VALUE }}
            items={blogCategories}
            selectedItem={articles.category}
          />
        </CategoryFilterMobileContainer>
      )}

      {!isMobileScreen && (
        <HeroContainer data-testid="hero-container">
          {recentloading && (
            <BoxWeb display="flex" width="100%" justifyContent="center" my={3}>
              <CircularProgress />
            </BoxWeb>
          )}
          <Hero cards={breaknews} />
        </HeroContainer>
      )}

      <HiddenWeb>
        <NewsletterContainer>Newsletter</NewsletterContainer>
      </HiddenWeb>

      {!isMobileScreen && (
        <CategoryFilterDesktopContainer data-testid="categories-container">
          <TextCarousel
            items={blogCategories}
            selectedItem={articles.category}
            onClickItem={onClickCategoryItem}
            defaultValue={{ title: 'Mais Recentes', value: DEFAULT_VALUE }}
            listTitle="categorias"
          />
        </CategoryFilterDesktopContainer>
      )}

      <PostCardsContainer data-testid="album-container">
        <Album
          cards={articles.posts}
          onReadMoreClick={loadMore}
          focusIndex={articles.skip === 0 ? undefined : articles.skip}
          loading={postLoading}
          showReadMore={showReadMoreButton}
        ></Album>
      </PostCardsContainer>
    </WrapperContainer>
  );
};

export default Home;

