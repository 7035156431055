import { authImages } from '@web/assets';
import { BoxWeb, ButtonWeb, DialogTitleWeb } from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { EmailField } from '@web/form/component';
import { useEffect } from 'react';
import { ModalImage } from './style';
import { useAuthOriginProvider } from '../../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../../providers/auth-product-provider';

export const SendMailScreen = ({ handleSubmit, loading, handleBack, onClose }) => {
  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.CHANGE_PASSWORD_EMAIL;

  useEffect(() => {
    analyticsAuth.LOAD();
  }, []);

  return (
    <>
      <DialogTitleWeb onClose={onClose} />

      <BoxWeb
        fontSize="28px"
        lineHeight="36px"
        color="#2B374A"
        fontFamily="ItauDisplay-Regular"
        fontWeight="700"
        marginBottom={{ xs: '24px', sm: 0 }}
      >
        alterar senha
      </BoxWeb>

      <BoxWeb
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={{ xs: '24px', sm: '5px' }}
      >
        <BoxWeb
          maxWidth={{ xs: 'none', sm: '270px' }}
          color="#2B374A"
          component="p"
          fontSize="18px"
          lineHeight="23px"
          marginRight="30px"
          marginBottom={{ xs: '24px', sm: 0 }}
        >
          Informe o e-mail utilizado no seu cadastro para receber instruções e o link de alteração da senha:
        </BoxWeb>
        <ModalImage src={authImages.forgotMyPassword} />
      </BoxWeb>

      <FormController id="forgotPasswordForm" onSubmit={handleSubmit} hideErrors>
        <BoxWeb marginBottom="52px">
          <EmailField componentProps={{ label: 'insira seu e-mail', type: 'email' }} />
        </BoxWeb>

        <BoxWeb maxWidth="183px">
          <ButtonWeb id="forgotContinueButton" label="enviar" loading={loading} type="submit" />
        </BoxWeb>
      </FormController>
    </>
  );
};
