import { Assists } from '@core/domain/entities/autov2/simulation-response';
import { formatPriceNumber } from '../utils/format-price-number';
import { AssistsType } from './simulation-response';

export class AssistsDTO {
  static translate(assistances: Assists[]): AssistsType[] {
    return assistances.map((assistance: Assists) => ({
      code: assistance.codigo,
      price: `R$${formatPriceNumber(assistance.valorPremio)}`,
      type: assistance.tipo,
      title: assistance.titulo,
      description: assistance.descricao,
    }));
  }
}
