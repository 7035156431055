import { Link } from '@material-ui/core';
import { DefaultLinkProps } from '../../models/default-link';

export const LinkMui = ({
  component,
  variant,
  color,
  onClick,
  text,
  id,
  underline,
  href,
  classes,
  target = '_blank',
  rel = 'noopener',
  style,
  ariaLabel
}: DefaultLinkProps) => (
  <Link
    component={component}
    variant={variant}
    onClick={onClick}
    color={color}
    data-testid={id}
    id={id}
    href={href}
    classes={classes}
    underline={underline}
    target={target}
    rel={rel}
    style={style}
    aria-label={ariaLabel}
  >
    {text}
  </Link>
);
