import React from 'react';
import { useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import PageReadArticle from './article';

export function ArticleBase(content) {
  const { Theme } = useThemeProvider();
  return (
    <SCThemeProvider theme={Theme}>
      <PageReadArticle content={content.content} />
    </SCThemeProvider>
  );
}
