import { VehicleSearchByPlate } from './../../models/vehicle-search-by-plate';
import { IFindVehicleByPlateRepository } from '../../../data/contracts/autov2/find-vehicle-by-plate-repository';
import { VehicleResponse } from '../../../domain/entities/autov2/response/vehicle';
import { IHttpGetClient } from '../../../data/contracts/http/http-get-client';

export class FindVehicleByPlateRepository implements IFindVehicleByPlateRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<{ params: VehicleSearchByPlate }, { data: VehicleResponse }>) {}

  async execute(plate: string): Promise<{ data: VehicleResponse }> {
    const params = { placa: plate, flagZeroKm: 'N' };
    return await this.httpGetClient.get({ params });
  }
}
