import { GetCardArticleBlogUseCase } from '@workspace/frontend/blog/domain/use-cases';
import { GetCardArticleRepository } from '@workspace/frontend/blog/infra/repositories';
import { EnvironmentContextProps } from '@workspace/providers';
import { CardArticleBlogResponse } from '@workspace/frontend/blog/domain/entities';
import {IHttpGetClient} from "@workspace/frontend/http/domain/contracts/repositories";
import {AxiosHttpGetClient} from "@workspace/frontend/http/repositories/axios";

export function getCardArticleFactory(
  { environment }: Partial<EnvironmentContextProps>,
  limit: string,
  skip: string,
  category?: string
) {
  const httpGetClient: IHttpGetClient<null, CardArticleBlogResponse> = new AxiosHttpGetClient(
    `/content_types/seg_blog_posts/entries/?environment=${
      environment?.cmsBase?.environment
    }&desc=created_at&include_count=true&limit=${limit}&skip=${skip}&include[]=post_category${
      !!category ? `&query={"post_category.uid":"${category}"}` : ''
    }`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getCardArticleRepository = new GetCardArticleRepository(httpGetClient);
  const getArticleBlogUsecase = new GetCardArticleBlogUseCase(getCardArticleRepository);
  return getArticleBlogUsecase;
}
