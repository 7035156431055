import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { useEffect, useState } from 'react';
import { useStepProvider } from '../../../../providers/step-provider';
import { AdditionalDataStep } from './additional-data-step';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { LoadingError } from '../../../../components/loading-error/loading-error';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { NetworkImages } from '../../../../enums/networkImages';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { sendLeadFactory } from '@workspace/frontend/auto/presentation/web-app';
import { useOriginContext } from '../../../../providers/origin-provider';
import { useHistory } from 'react-router-dom';

function AdditionalDataStepContainer() {
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const stepProvider = useStepProvider();
  const { incomeBracketOptions, professionOptions, nationalityOptions, ...initialValues } =
    AutoStateManager.additionalDataStepSelector();
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const analyticCalls = autoV2analyticsCalls.HIRING.ADDITIONAL_DATA;
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [carImagePath, setCarImagePath] = useState<string>();
  const networkStyleService = networkStyleFactory(partialEnvironment);
  const { environment } = partialEnvironment;
  const [leadChannel, setLeadChannel] = useState<string>();
  const history = useHistory();

  const initImages = async () => {
    const cachedNetworkStyle: NetworkStyle = await networkStyleService.execute();
    const carImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.CAR);
    setCarImagePath(carImage?.file?.url);
  };
  useEffect(() => {
    initImages();
    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name }: { channel_name: string } = await networkStyleUsecase.execute();
      setLeadChannel(channel_name);
    })();
  }, []);

  const handleSubmitError = () => {
    setError(true);
  };

  useEffect(() => {
    analyticCalls.LOAD();
  }, []);

  const handleBack = () => {
    analyticCalls.BACK();
    stepProvider.handleBack();
  };

  const handleSubmit = async (props) => {
    setLoading(true);
    const { name, cpf } = props;
    // const eligibilityRequest = EligibilityRequestAdapter.translate({ name, cpf });
    // const responseEligibility: EligibilityResponse = await eligibilityUseCase.execute(eligibilityRequest);
    // const parsedEligibilityResponse = EligibilityResponseAdapter.translate(responseEligibility?.data);

    // const { eligible } = parsedEligibilityResponse;

    // if (eligible) {
    AutoStateManager.additionalDataStepDispatcher(props);
    setLoading(false);
    stepProvider.handleNext();
    // }
  };

  const redirectHome = () => {
    AutoStateManager.clearStore();
    const prefix = window.location.pathname.replace(/\/contratacao.*/, '');
    history.push(`${prefix}`);
  };

  const textPEP =
    'Informamos que por razão dos critérios de análise desta corretora e de acordo com termos da legislação vigente, não podemos seguir com a proposta de contratação do produto que você solicitou na Loja de Seguros Itaú.';

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });
  if (error) {
    return (
      <LoadingError
        redirectToHome
        onRetry={redirectHome}
        errorTitle={textPEP}
        initialValues={initialValues}
        sendLeadUsecase={sendLeadUsecase}
      />
    );
  }

  return (
    <AdditionalDataStep
      handleSubmit={(props) => {
        analyticCalls.CONTINUE();
        handleSubmit(props);
      }}
      incomeBracketOptions={incomeBracketOptions}
      nationalityOptions={nationalityOptions}
      professionOptions={professionOptions}
      handleBack={handleBack}
      initialValues={initialValues}
      loading={loading}
      carImagePath={carImagePath}
      sendLeadUsecase={sendLeadUsecase}
    />
  );
}

export function AdditionalDataStepContainerWrapper() {
  return <AdditionalDataStepContainer />;
}
