import { useEffect, useState } from 'react';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import LoadingAutoImage from './loading-auto-image';

export const LoadingAutoPage = () => {
  const messages = [
    <span>
      você sabia que no <b>cartão porto seguro você tem 5% de desconto</b> no pagamento do seu seguro? desconto especial
      para você :)
    </span>,
    <span>
      imprevistos acontecem e nem sempre estamos preparados, mas nós pensamos em tudo! Temos uma{' '}
      <b>assistência 24 horas para você não ficar na mão.</b>
    </span>,
    <span>
      o inesperável pode acontecer no trajeto para o trabalho ou para casa, e por isso disponibilizamos{' '}
      <b>centros de atendimento automotivo para a sua tranquilidade.</b>
    </span>,
    <span>
      quando falamos de praticidade logo pensamos no que é simples e fácil, não é mesmo? Você pode{' '}
      <b>acionar uma assistência para o seu carro pelo WhatsApp</b> da seguradora
    </span>,
  ];

  const DEFAULT_HIDDEN_LOADING_MESSAGE = 'Carregando cotações.';
  const LOOP_MILLISECONDS_INTERVAL = 7000;
  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  useEffect(() => {
    let cicleCounter = 0;
    const interval = setInterval(() => {
      const currentIndex = ++cicleCounter % messages.length;
      setCurrentMessage(messages[currentIndex]);
      cicleCounter = currentIndex;
    }, LOOP_MILLISECONDS_INTERVAL);

    scrollTo();

    return () => clearInterval(interval);
  }, []);

  return (
    <BoxWeb
      mx={'auto'}
      width={'fit-content'}
      mt={'6rem'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <div
        style={{
          opacity: 0,
          scale: 0,
        }}
        role="alert"
      >
        {DEFAULT_HIDDEN_LOADING_MESSAGE}
      </div>
      <LoadingAutoImage />
      <BoxWeb color={'primary.main'} textAlign={'center'} maxWidth={'30rem'} fontSize={'19px'} px={'1rem'}>
        <p aria-live="polite" aria-atomic="true">
          {currentMessage}
        </p>
      </BoxWeb>
    </BoxWeb>
  );
};
