import { HiringUsecase } from '@core/data/services/hiring-usecase';
import { AutoV2HiringRepository } from '@core/infra/repository/autov2/autov2-hiring';
import { HiringResponse } from '@core/domain/entities/autov2/response/hiring-response';
import { HiringRequest } from '@core/domain/entities/autov2/hiring';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { EnvironmentContextProps } from '@workspace/providers';

export type HiringFactoryProps = {
  onError: (error) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

class HttpPostClientMock implements IHttpPostClient<HiringRequest, HiringResponse> {
  post(params: HiringRequest): Promise<HiringResponse> {
    return Promise.resolve({
      numeroProposta: '123123',
    });
  }
}

export function hiringFactory({ partialEnvironment, onError }: HiringFactoryProps) {
  // const { environment } = partialEnvironment;

  // const httpPostClient: IHttpPostClient<HiringRequest, HiringResponse> = new AxiosHttpPostClient(
  //   "contratacao",
  //   {
  //   },
  //   environment.gatewayBFFBaseURL.url
  // );
  const httpPostClientMock = new HttpPostClientMock();

  const hiringRepository = new AutoV2HiringRepository(httpPostClientMock);
  const hiringUsecase = new HiringUsecase(hiringRepository, onError);
  return hiringUsecase;
}
