import { IValidateRecoveryHashUsecase } from '../../domain/usecases/validate-recovery-hash-usecase';
import { IValidateRecoveryHashRepository } from '../contracts/auth/validate-recovery-hash-repository';
import { ValidateRecoveryHashResponse } from '../../domain/entities/auth/response/validate-recovery-hash-response';

export class ValidateRecoveryHashUsecase implements IValidateRecoveryHashUsecase {
  constructor(private readonly validateRecoveryHashRepository: IValidateRecoveryHashRepository) {}

  async execute(hash: string, headers?: any): Promise<ValidateRecoveryHashResponse> {
    return await this.validateRecoveryHashRepository.execute(hash, headers);
  }
}
