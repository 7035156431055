import { AnySchema } from 'yup';
import { ConditionConfig } from 'yup/lib/Condition';

export interface mixedValidationInterface<T extends AnySchema> {
  requiredMessage?: string;
  test?: Array<{ name: string; message: string; test: (value: any) => boolean }>;
  oneOf?: {
    arrayOfValues: Array<unknown>;
    message: string;
  };
  condition?: ConditionConfig<T> & { when: string };
  chainableSchema: T;
  transform?: (currentValue: any, originalValue: any) => T;
}

const mixedValidationFactory = <Schema extends AnySchema>({
  chainableSchema,
  requiredMessage,
  test,
  oneOf,
  condition,
  transform,
}: mixedValidationInterface<Schema>): Schema => {
  if (oneOf) {
    chainableSchema = chainableSchema.oneOf(oneOf.arrayOfValues, oneOf.message);
  }

  if (requiredMessage) {
    chainableSchema = chainableSchema.required(requiredMessage);
  }
  if (test) {
    test.forEach((testIndex) => {
      chainableSchema = chainableSchema.test(testIndex);
    });
  }

  if (condition) {
    chainableSchema = chainableSchema.when(condition.when, {
      is: condition.is,
      then: condition.then,
      otherwise: condition.otherwise,
    });
  }

  if (transform) {
    chainableSchema = chainableSchema.transform(transform);
  }
  return chainableSchema;
};

export default mixedValidationFactory;
