import { WebComponentBuilder } from '@workspace/frontend/shared/presentation/webcomponent-builder';
import { DivergentDataQuotationHolderCarrier } from './divergent-data-quotation-holder/divergent-data-quotation-holder-carrier';

export enum WebcomponentModule {
  DIVERGENT_DATA_QUOTATION_HOLDER = 'divergent-data-quotation-holder',
}

export const createDivergentDataQuotationHolderWebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env)
    .create(WebcomponentModule.DIVERGENT_DATA_QUOTATION_HOLDER, DivergentDataQuotationHolderCarrier)
    .builder();
};
