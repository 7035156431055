import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { BlogTheme } from '../components';
import { HomeBase } from '../pages';
import { BlogProvider } from '../provider/blog-provider';
import { DynamicBlogContent } from '../pages/blog-content';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/core';

const AccessibilityRead = styled.div`
  a:focus-visible,
  p:focus-visible {
    padding: 3px;
  }
`;

function HomeWrapper() {
  return (
    <BrowserRouter>
      <Switch>
        <BlogTheme>
          <BlogProvider>
            <AccessibilityRead>
              <StylesProvider injectFirst>
                <Route exact path="/blog/home" render={() => <HomeBase />} />
              </StylesProvider>
              <DynamicBlogContent />
            </AccessibilityRead>
          </BlogProvider>
        </BlogTheme>
      </Switch>
    </BrowserRouter>
  );
}

export const Blog = HomeWrapper;
