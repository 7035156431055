import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DefaultButtonProps } from '../../models/default-button';

export const OutlinedButtonMui = styled(({ className, onClick, label, color, ...other }: DefaultButtonProps) => (
  <Button
    className={className}
    variant="outlined"
    color={color || 'secondary'}
    onClick={onClick}
    fullWidth
    {...other}
    disableFocusRipple
  >
    {label}
  </Button>
))({
  width: '100%',
  height: 48,
  fontFamily: 'ItauDisplay-Bold',
  fontWeight: 700,
  fontSize: '19px',
  textTransform: 'lowercase',
  border: '1px solid #106EB0',
  boxSizing: 'border-box',
  borderRadius: 4,
});
