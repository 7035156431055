import { useEffect, useMemo, useState } from 'react';
import { StepperContext } from './stepper-context';

export function StepController({
  initialStep = 0,
  onChangeStep,
  children,
}: {
  initialStep?: number;
  onChangeStep?;
  children: JSX.Element;
}) {
  const [currentStep, setCurrentStep] = useState(initialStep);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  const handleNext = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
  };

  const handleBack = () => {
    if (currentStep >= 1) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
    }
  };

  const changeStep = (index) => {
    setCurrentStep(index);
    if (typeof onChangeStep === 'function') onChangeStep(index);
  };

  return useMemo(
    () => (
      <StepperContext.Provider value={{ handleBack, handleNext, currentStep, changeStep }}>
        {children}
      </StepperContext.Provider>
    ),
    [currentStep]
  );
}
