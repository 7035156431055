import {
  ComponentMode,
  StyleMode,
  ThemeProviderWeb,
  ComponentAdapterTypes,
  StyleAdapterTypes,
} from '@workspace/frontend/shared/presentation/components';

export function MyAccountTheme({ children }: any) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
    componentOverrides: [ComponentAdapterTypes.MY_ACCOUNT_UI],
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.MY_ACCOUNT,
  };

  return (
    <ThemeProviderWeb rootName="my-account" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
