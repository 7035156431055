import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@web/validation';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import {
  ModalFilteredSelection,
  ModalFilteredSelectionProps,
} from '@workspace/frontend/shared/presentation/composed-components';

const DEFAULT_DEBOUNCE_WAIT_PERIOD = 500;

export const BrandAndModelField = ({ items, onInputChange, service, disabled, modelAndBrandNotFoundMessage }: any) => {
  const [loading, setLoading] = useState(false);

  const handleComponentChange = useCallback(
    debounce(async (text: string) => {
      if (!text || text.length === 0) return;
      setLoading(true);
      const result = await service(text);
      setLoading(false);
      onInputChange(result);
    }, DEFAULT_DEBOUNCE_WAIT_PERIOD),
    [service]
  );

  return (
    <FormControllerFieldConnector<ModalFilteredSelectionProps>
      name="brandAndModel"
      component={ModalFilteredSelection}
      validation={stringValidationFactory({
        requiredMessage: 'você precisa informar a marca e modelo do carro',
      })}
      disabled={disabled}
      error={modelAndBrandNotFoundMessage}
      helperText={modelAndBrandNotFoundMessage && 'algo deu errado, tente novamente!'}
      componentProps={{
        id: 'cboCarModel_screenCarInformation',
        label: 'marca e modelo do carro',
        onInputChange: handleComponentChange,
        loading: loading,
        items: items,
        disableFilter: true,
      }}
    />
  );
};
