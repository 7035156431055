import styled from 'styled-components';

export const Container = styled.div`
  height: 22px;
  position: relative;
  z-index: 2;
`;

export const AccessButton = styled.button`
  font-weight: bold;
  color: black;
  font-size: 16px;
  line-height: 22px;
  border: none;
  background-color: white;
  padding: 0;
  cursor: pointer;
`;
