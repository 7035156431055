import React from 'react';
import styled from 'styled-components';

export function SummaryAccordionTable({ content, header1, header2 }) {
  const SummaryAccordionTable = styled.div`
    table {
      width: 100%;
      padding-bottom: 1.5rem;
    }
    table tr th {
      text-align: left;
      width: 100px;
      padding: 0.75rem 0;
    }
  `;

  return (
    <>
      <SummaryAccordionTable>
        <table>
          <tr>
            <th>{header1}</th>
            <th>{header2}</th>
          </tr>
          {content?.map((data, index) => (
            <tr key={index}>
              <td>{data.title}</td>
              <td>{data.price}</td>
            </tr>
          ))}
        </table>
      </SummaryAccordionTable>
    </>
  );
}
