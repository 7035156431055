import React, { useState } from 'react';
import {
  BoxWeb,
  HiddenWeb,
  LinkWeb,
  NavigateButtonsWeb,
  TypographyWeb,
} from '@workspace/frontend/shared/presentation/components';
import { FormController, FormControllerErrorBanner, useFormControllerMethods } from '@web/form';
import {
  CarInspectionModal,
  GridTemplateArea,
  SummaryAccordionContainer,
  SummaryCard,
  SummaryCardMobile,
} from '@workspace/frontend/auto/presentation/components';
import { TermField } from '@web/form/component';
import styled from 'styled-components';
import { DoubtContent } from './components/doubt-content';
import { PaymentMethodsSwitcher } from './payment-methods-switcher';
import { LeadDialog } from '../../../../components/auto-quotation-lead-dialog/auto-quotation-lead-dialog';
import { BoxSteps } from '../../hiring-utils';

interface summaryCardProps {
  overtitle?: string;
  subtitle?: string;
  title: string;
  contentList: { title?: string; list?: string[]; renderContent?: JSX.Element }[];
}

interface paymentStepProps {
  onContinue: () => void;
  onSubmit: (data: any) => void;
  onBack: () => void;
  initialValues: any;
  termsOfUse: string;
  generalConditions: string;
  isRequiredInsuranceCar: boolean;
  paymentMethods: any;
  selectedOfferCode: any;
  simulationNumber: any;
  summaryCard: summaryCardProps;
  summaryCardMobile: summaryCardProps;
  summaryAccordion: any;
  sendLeadUsecase?: any;
}

function SummaryCardMobileContainer({ overtitle, subtitle, title, contentList }) {
  return <SummaryCardMobile overtitle={overtitle} subtitle={subtitle} title={title} contentList={contentList} />;
}

function SummaryCardContainer({ overtitle, title, contentList, paymentMethods }) {
  const { watch } = useFormControllerMethods();
  const intallmentNumber = watch('installmentNumber');
  const paymentMethodIndex = watch('paymentMethod');
  const selectedInstallment = paymentMethods[paymentMethodIndex - 1]?.installments?.find(
    (i) => i.key == intallmentNumber
  )?.value;
  return <SummaryCard overtitle={selectedInstallment || overtitle} title={title} contentList={contentList} />;
}

const PaymentStepContainer = styled.div`
  * {
    outline-offset: 0.3rem;
  }
`;

const PaymentStepGrid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-areas:
    'paymentMethods paymentResume'
    'detailsContainer detailsContainer';
  grid-template-rows: auto;
  grid-template-columns: 60% 30%;
  grid-row-gap: 8px;
  grid-column-gap: 26px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'paymentMethods'
      'paymentResume'
      'detailsContainer';
  }
`;

/**
 * payment step UI
 * should not have any business rules of outside behaviour
 * should never have any effect call on its descendants
 */


export function PaymentStep({
  onContinue,
  onBack,
  initialValues = {},
  termsOfUse,
  generalConditions,
  isRequiredInsuranceCar,
  paymentMethods,
  simulationNumber,
  summaryCard,
  summaryCardMobile,
  summaryAccordion,
  onSubmit,
  sendLeadUsecase,
}: paymentStepProps) {
  const [openInsuranceModal, setOpenInsuranceModal] = useState<boolean>(false);
  const [openedLeadModal, setOpenedLeadModal] = useState<boolean>(false);

  const handleOpenLeadModal = () => setOpenedLeadModal(true);
  const handleCloseLeadModal = () => setOpenedLeadModal(false);

  return (
    <PaymentStepContainer>
      <HiddenWeb mdUp>
        <SummaryCardMobileContainer
          overtitle={summaryCardMobile.overtitle}
          subtitle={summaryCardMobile.subtitle}
          title={summaryCardMobile.title}
          contentList={summaryCardMobile.contentList}
        />
      </HiddenWeb>
      <BoxSteps>
        <BoxWeb width={'100%'} display={'flex'} justifyContent={{ xs: 'center', md: 'start' }}>
          <BoxWeb component={'h1'} textAlign={{ xs: 'center', md: 'left' }} width={{ xs: '280px', md: '100%' }}>
            pagamento
          </BoxWeb>
        </BoxWeb>

        <BoxWeb height={26} />

        <TypographyWeb component={'p'}>você pode pagar com</TypographyWeb>

        <BoxWeb height={20} />

        <FormController
          onSubmit={(data) => {
            onSubmit(data);
            setOpenInsuranceModal(true);
          }}
          initialValues={initialValues}
          hideErrors
        >
          <CarInspectionModal
            onClose={onContinue}
            open={openInsuranceModal}
            isRequiredInsuranceCar={isRequiredInsuranceCar}
          />

          <PaymentStepGrid>
            <GridTemplateArea area="paymentMethods">
              <FormControllerErrorBanner />

              <PaymentMethodsSwitcher paymentMethods={paymentMethods} />

              <BoxWeb height={26} />

              <TypographyWeb component={'p'}>
                O valor do IOF (Imposto sobre Operação Financeira) é 7,38% para todas as compras. Havendo inadimplência
                o pagamento só poderá ser efetuado na seguradora acrescido de uma taxa de 0,30% ao dia.
              </TypographyWeb>

              <BoxWeb height={26} />
              <TermField
                id={'linkTerms_screen'}
                label={'declaro que li e concordo com os termos de uso e as condições gerais'}
                errorMessage={'você precisa aceitar os termos de uso e condições gerais'}
              />
              <LinkWeb id={'linkTermsOfUse_screen'} text={'termos de uso'} href={termsOfUse} />
              <> | </>
              <LinkWeb id={'linkGeneralConditions_screen'} text={'condições gerais'} href={generalConditions} />
              <BoxWeb height={26} />

              <TypographyWeb component={'p'}>
                Pela sua atividade de intermediação, a Itaú Corretora de Seguros S.A. receberá uma comissão de
                corretagem de 18%. Informamos que incidem as alíquotas de PIS 0,65% de COFINS 4.00% sobre a formação do
                preço.
              </TypographyWeb>

              <BoxWeb height={16} />
            </GridTemplateArea>

            <GridTemplateArea area="paymentResume">
              <HiddenWeb smDown>
                <SummaryCardContainer
                  overtitle={summaryCard.overtitle}
                  title={summaryCard.title}
                  contentList={summaryCard.contentList}
                  paymentMethods={paymentMethods}
                />
                <DoubtContent openLeadModal={handleOpenLeadModal} />
              </HiddenWeb>
            </GridTemplateArea>

            <GridTemplateArea area="detailsContainer">
              <SummaryAccordionContainer content={summaryAccordion} />
              <NavigateButtonsWeb onClickBack={onBack} continueText={'contratar'} />
            </GridTemplateArea>
          </PaymentStepGrid>
        </FormController>
        <LeadDialog
          simulationNumber={simulationNumber}
          open={openedLeadModal}
          onClose={handleCloseLeadModal}
          initialValues={initialValues}
          sendLeadUsecase={sendLeadUsecase}
        />
      </BoxSteps>
    </PaymentStepContainer>
  );
}
