import { FormControllerFieldConnector } from '@workspace/frontend/shared/presentation/form/control';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import {
  SelectWithModalOnMobile,
  SelectWithModalOnMobileProps,
} from '@workspace/frontend/shared/presentation/composed-components';

export const ProfessionField = ({
  disabled,
  id,
  label = 'profissão',
  options,
}: { disabled?: boolean; id?: string; label?: string; options: any } & Partial<SelectWithModalOnMobileProps>) => (
  <FormControllerFieldConnector<SelectWithModalOnMobileProps>
    component={SelectWithModalOnMobile}
    name="profession"
    disabled={disabled}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar a profissão' })}
    componentProps={{
      items: options,
      label,
      id,
    }}
  />
);
