interface RegistrationUserType {
  cpf: string;
  cellphone: string;
  name: string;
  email: string;
  password: string;
}

interface RegistrationUserRequest {
  cpf: string;
  ddd: number;
  telefone: string;
  nome: string;
  email: string;
  senha: string;
}

export class RegisterUserRequestDTO {
  static translate({ cpf, cellphone, name, email, password }: RegistrationUserType): RegistrationUserRequest {
    const formattedCPF = cpf?.replace(/[^0-9]/g, '');

    const [unformattedDDD, unformattedPhone] = cellphone.split(' ');
    const formattedDDD = Number(unformattedDDD.replace(/[^0-9]/g, ''));
    const formattedCellphone = unformattedPhone.replace('-', '');

    return {
      email,
      senha: password,
      nome: name,
      cpf: formattedCPF,
      ddd: formattedDDD,
      telefone: formattedCellphone,
    };
  }
}
