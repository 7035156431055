import { Domain } from '../../domain/entities/autov2/response/domain';
import { IDomainUsecase } from '../../domain/usecases/domain-usecase';
import { IDomainRepository } from '../contracts/autov2/domain-repository';

export class DomainUsecase implements IDomainUsecase {
  constructor(private readonly domainRepository: IDomainRepository) {}
  async execute(): Promise<Domain> {
    return await this.domainRepository.execute();
  }
}
