import { DefaultAutocompleteProps } from '../../models/default-autocomplete';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function AutocompleteWeb(props: DefaultAutocompleteProps) {
  const { Components } = useThemeProvider();
  const { Autocomplete } = Components;

  return (
    <ThemeProviderInjector>
      <Autocomplete {...props} />
    </ThemeProviderInjector>
  );
}

export default AutocompleteWeb;
