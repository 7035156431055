import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { ResendTokenAuth } from '@core/domain/entities/auth/resend-token';
import { IResendTokenRepository } from '@core/data/contracts/auth/resend-token-repository';
import { ResendTokenAuthResponse } from '@core/domain/entities/auth/response/resend-token-auth-response';

export class ResendTokenAuthRepository implements IResendTokenRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<ResendTokenAuth, ResendTokenAuthResponse>) {}

  async execute(data: ResendTokenAuth, headers?: any) {
    return await this.httpPostClient.post(data, null, headers);
  }
}
