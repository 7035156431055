import styled from 'styled-components';

export const DesktopContent = styled.article`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: flex-start;
  }
`;

export const Title = styled.span`
  font-family: "ItauText-Light";
  font-size: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 24px;
  }
`;

export const SearchTitle = styled.span`
  font-family: "ItauText-Bold";
  font-size: 16px;
  color: #EC7000;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 24px;
  }
`;

export const QuestionSection = styled.section`
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 35px;
  }
`;
