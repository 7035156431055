import { FormControllerFieldConnector } from '@web/form';
import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';

export const CheckingAccountField = ({
  id = 'txtAccount_screen',
  label = 'conta corrente',
}: {
  id?: string;
  label?: string;
}) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name={'conta-corrente'}
    validation={stringValidationFactory({
      requiredMessage: 'você precisa informar a conta corrente',
      min: {
        limit: 5,
        message: 'a conta corrente deve conter pelo menos 5 caracteres',
      },
      max: {
        limit: 9,
        message: 'a agencia deve conter entre 5 a 9 caracteres',
      },
    })}
    componentProps={{ id, label }}
  />
);
