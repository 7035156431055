import produce from 'immer';

export const FAQV2Types = {
  SET_FAQ_PRODUCTS: 'SET [faq-v2] products',
};

export const handlers = {
  [FAQV2Types.SET_FAQ_PRODUCTS]: produce((draft, _action) => {
    draft.faq = _action.faqContent;
  }),
};
