import { styled, TextField } from '@material-ui/core';
import { InputWebProps } from '../../models/input-web-props';

const CustomInput = ({
  dataTestID,
  onChange,
  onBlur,
  name,
  value,
  variant = 'outlined',
  label,
  type,
  ...otherProps
}: InputWebProps) => (
  <TextField
    {...otherProps}
    inputProps={{
      'data-testid': dataTestID,
      'aria-label': label,
      ...otherProps?.inputProps,
    }}
    InputLabelProps={{
      shrink: !!value,
      ...otherProps?.InputLabelProps,
    }}
    label={label}
    fullWidth
    onChange={onChange}
    onBlur={onBlur}
    name={name}
    value={value}
    variant={variant}
    type={type}
  />
);

export const InputMui = styled(CustomInput)(({ theme }) => ({
  width: '100%',
  root: {
    width: '100%',
  },
  '& .MuiInputBase-root': {
    height: '48px',
  },
  '& .MuiInputLabel-root': {
    top: '-0.3rem',
    fontSize: '16px',
  },
}));
