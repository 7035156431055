import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { DropdownHeaderWeb, LoadingWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { AuthStateManager } from '@web/state-management';
import { AccessButton, Container } from './desktop-styles';
import { RouteEnum } from '../../../enums/route-enum';
import { getAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { useEffect } from 'react';
import { useAccessButton } from '../../../providers/access-button-provider';
import useOriginAndProductSession from "../../../hooks/useOriginAndProductSession";

const DesktopContainer = (props: { alignRight?: boolean }) => {
  const { Theme } = useThemeProvider();
  const { isAuthenticated, name } = AuthStateManager.userSelector();
  const { options, loading } = useAccessButton();
  const {removeOriginAndProductSession, setOriginAndProductSession} = useOriginAndProductSession();

  const defaultOption = {
    id: 0,
    label: `Olá, ${name}`,
  };

  const handleAccessClick = (): void => {
    removeOriginAndProductSession();
    setOriginAndProductSession({origin: 'loja', product: 'home'});
    const isLoginPage = Boolean(window.location.href.includes('/entrar'));
    if (!isLoginPage) window.location.assign(RouteEnum.LOGIN);
  };

  useEffect(() => {
    const authData = getAuthentication();
    const isAuthenticated = !!authData?.accessToken;
    AuthStateManager.userAuthenticatedDispatcher(isAuthenticated);
  }, []);

  return !loading ? (
    <SCThemeProvider theme={Theme}>
      <Container>
        {isAuthenticated ? (
          <DropdownHeaderWeb
            id="accessDropdown"
            alignRight={props.alignRight}
            defaultOption={defaultOption}
            options={options}
            HeaderButtonProps={{
              'aria-label': 'Opções do Usuário',
            }}
          />
        ) : (
          <AccessButton onClick={handleAccessClick}>acesse</AccessButton>
        )}
      </Container>
    </SCThemeProvider>
  ) : (
    <LoadingWeb size={20} />
  );
};

export default DesktopContainer;
