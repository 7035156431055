import React, { createContext, useContext, useEffect, useState } from 'react';
import { DynamicLandpage } from '../../../../../core/src/domain/entities/dynamic-landings/dynamic-landpage';
import { getDynamicLandingsFactory } from '../factories/get-dynamic-landings-factory';
import { useEnvironment } from '@workspace/providers';
import { Route } from 'react-router-dom';
import { DynamicLandPageView } from '../pages/dynamic-landpage-view';

type DynamicLandPageContextProps = {
  createDynamicRoute?: Function;
  loading?: boolean;
  dynamicLandings?: DynamicLandpage[];
};

const DynamicLandPageContext = createContext<DynamicLandPageContextProps>({});

export type DynamicLandPageProps = {
  children: any;
};

const DynamicLandPageProvider = ({ children }: DynamicLandPageProps) => {
  const [dynamicLandings, setDynamicLandings] = useState<DynamicLandpage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const enviroment = useEnvironment();
  const getLandPageUsecase = getDynamicLandingsFactory(enviroment);

  const _initialize = async () => {
    setLoading(true);
    const getLandPages = await getLandPageUsecase.execute();
    setLoading(false);
    setDynamicLandings(getLandPages);
  };

  useEffect(() => {
    _initialize();
  }, []);

  const createDynamicRoute = (url: string, content: DynamicLandpage) => {
    return <Route exact path={url} render={() => <DynamicLandPageView content={content} />} />;
  };

  return (
    <DynamicLandPageContext.Provider value={{ createDynamicRoute, loading, dynamicLandings }}>
      {children}
    </DynamicLandPageContext.Provider>
  );
};

const useDynamicLandPage = () => {
  return useContext(DynamicLandPageContext);
};

export { DynamicLandPageProvider, useDynamicLandPage };
