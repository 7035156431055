import { makeStyles, TextField } from '@material-ui/core';
import { InputWebProps } from '../../models/input-web-props';

const CustomInput = ({
  id,
  dataTestID,
  onChange,
  onBlur,
  name,
  value,
  label,
  InputLabelProps,
  InputProps,
  error,
}: InputWebProps) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      id={id}
      name={name}
      error={error}
      label={label}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      variant="outlined"
      inputProps={{
        'data-testid': dataTestID,
        'aria-label': label,
      }}
      InputLabelProps={{
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
      }}
      className={classes.root}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '638px',
    height: '50px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingTop: '3px',
  },
  input: {
    root: { display: 'none' },
  },
}));

export const InputFAQ = CustomInput;
