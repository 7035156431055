export default {
  primary: {
    main: '#EC7000',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
  },
  secondary: {
    main: '#FFF',
    contrastText: '#EC7000',
    light: 'rgba(255,255,255, 0.75)',
    dark: '#004990',
  },
  text: {
    primary: '#2b374a',
    secondary: '#605751',
    tertiary: '#707070',
  },
  link: {
    primary: '#EC7000',
  },
  success: {
    main: '#007A47',
  },
  background: {
    lightMain: '#FFDAB8',
    lightSecondary: '#EFE9E5',
    grey: '#D1D1D12C',
  },
};
