import { SimulationResponse } from '@core/domain/entities/autov2/simulation-response';
import { OfferDTO } from './offer-dto';
import { QuotationStatusDTO } from './quotation-status-dto';

export interface SimulationType {
  code: number;
  startValid: string;
  endValid: string;
  company: number;
  price: string;
  interestFreeInstallments: string;
  offers: OfferType;
}

export interface InsuranceType {
  type: number;
}

export interface AssistsType {
  code: number;
  price: string;
  type?: number;
  title: string;
  description: string;
}

export interface OfferType {
  coverages: CoverageType[];
  assistances: AssistsType[];
  insurance?: InsuranceType;
}

export interface CoverageType {
  code: number;
  type?: number;
  amountInsured: string;
  price: string;
  franchise?: FranchiseType[];
  title: string;
  description: string;
}

export interface FranchiseType {
  code: number;
  price: string;
}

export interface QuotationStatus {
  code: number;
  description: string;
}

export class SimulationResponseDTO {
  constructor(
    public readonly simulationNumber: number,
    public readonly quotationStatus: QuotationStatus,
    public readonly offers: SimulationType[]
  ) {}

  static translate(simulationResponse: SimulationResponse) {
    return {
      simulationNumber: simulationResponse.data.numeroCotacao,
      quotationStatus: QuotationStatusDTO.translate(simulationResponse.data.statusCotacao),
      offers: OfferDTO.translate(simulationResponse.data.orcamentos),
    };
  }
}
