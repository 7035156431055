import { environment } from "../../envs/environment";
import { PostHttpClientContract, PostHttpClienteContractInput } from "../contracts/post-http-client.contract"
import { AxiosHttpClient } from "./axios.http-client"

export class PostAxiosHttpClient extends AxiosHttpClient implements PostHttpClientContract {
    constructor() {
        super(environment.gatewayBFFBaseURL.url);
    }

    public async execute<TPayload>(input: PostHttpClienteContractInput<TPayload>): Promise<void> {

        await this.axiosInstance.post(input.uri, input.payload);
    }
}
