import { GetAddressByCepUseCase } from '@core/data/services/get-address-by-cep-usecase';
import { IGetAddressByCepUseCase } from '@core/domain/usecases/get-address-by-cep-usecase-interface';
import { GetAddressByCepRepositoryFactory } from './get-address-by-cep-repository-factory';

export abstract class GetAddressByCepUseCaseFactory {
  private static _instance: IGetAddressByCepUseCase;

  public static get instance(): IGetAddressByCepUseCase {
    if (!this._instance) {
      this._instance = new GetAddressByCepUseCase(GetAddressByCepRepositoryFactory.instance);
    }

    return this._instance;
  }
}
