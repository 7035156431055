import { AuthTheme } from '../../components/auth-theme';
import { AuthenticationParamsProvider } from '../../providers/authentication-params-provider';
import { RegisterBase } from './register-base';
import { AuthOriginProvider } from '../../providers/auth-origin-provider';
import { AuthProductProvider } from '../../providers/auth-product-provider';

export const Register = ({ origin, product }: { origin?: string; product?: string }) => {
  return (
    <AuthOriginProvider origin={origin}>
      <AuthProductProvider product={product}>
        <AuthenticationParamsProvider>
          <AuthTheme>
            <RegisterBase />
          </AuthTheme>
        </AuthenticationParamsProvider>
      </AuthProductProvider>
    </AuthOriginProvider>
  );
};
