import { useState } from 'react';
import { FormData } from './form-data';
import { RecoverySuccess } from './recovery-success';

type PasswordResponse = {
  success: boolean;
  username?: string;
};

export const FormContainer = ({ hash }) => {
  const [passwordResponse, setPasswordResponse] = useState<PasswordResponse>({ success: false });

  return !passwordResponse.success ? (
    <FormData hash={hash} passwordCallback={setPasswordResponse} />
  ) : (
    <RecoverySuccess username={passwordResponse.username} />
  );
};
