
import { AxiosProxyConfig } from 'axios';
import { AxiosBase } from './axios-base';
import {IHttpPostClient} from "@workspace/frontend/http/domain/contracts/repositories";

export class AxiosHttpPostClient<T, R> extends AxiosBase implements IHttpPostClient<T, R> {
  protected axiosConfig: AxiosProxyConfig;
  constructor(
    private readonly service: string,
    private readonly headers?: any,
    public readonly baseURL?: string,
    public readonly customResponseInterceptorCallback?: (error: any) => any,
    public readonly parsedData?: boolean
  ) {
    super(baseURL, customResponseInterceptorCallback);
  }

  async post(data: T, query: string, headers?: any): Promise<R> {
    const fullService = this.service + (query ? `?${query}` : '');
    const response = await this.axiosInstance.post(fullService, data, { headers: headers || this.headers });
    return this.parsedData ? response.data : response;
  }
}
