import { SelectWeb, SelectWebProps } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const BonusClassField = ({ componentProps }) => (
  <FormControllerFieldConnector<SelectWebProps>
    name="bonusClass"
    component={SelectWeb}
    componentProps={{
      items: componentProps.items,
      label: 'classe de bônus',
      id: 'cboBonusClass_screenCarInformation',
    }}
    validation={stringValidationFactory({
      condition: {
        when: 'previousBonus',
        is: true,
        then: stringValidationFactory({ requiredMessage: 'você precisa informar a classe de bônus' }),
        otherwise: stringValidationFactory({}),
      },
    })}
  />
);
