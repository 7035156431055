import { ActionAnalyticsInterface, PageLoadAnalyticsInterface } from './analytics';

interface AnalyticsLogInterface {
  message: string;
  showData?: boolean;
  debug?: boolean;
}

interface FrameworkDA {
  Track: () => {};
}

interface Window {
  analyticsData: object;
  _frameworkDA: FrameworkDA;
}

declare let window: Window;

const analyticsLog = ({ message, showData, debug = false }: AnalyticsLogInterface) => {
  const newMessage = `[ANALYTICS] ${message}`;
  if (debug) console.log(newMessage);
  if (showData) console.log('\nEvent loaded:', window.analyticsData);
};

const feedFrameworkDA = (debug = false) => {
  if (debug) return analyticsLog({ message: 'Analytics tracking is disabled.', showData: true, debug });
  if (window?._frameworkDA) return window._frameworkDA.Track();

  return analyticsLog({
    message: 'Analytics tracking is activated but FramewordDA is not found',
    showData: false,
    debug,
  });
};

export const registerAnalyticsEvent = (event: ActionAnalyticsInterface | PageLoadAnalyticsInterface) => {
  window.analyticsData = event;
  feedFrameworkDA();
};
