import { SendLeadUsecase } from '@core/data/services/send-lead-usecase';
import { SendLeadRepository } from '@core/infra/repository/autov2/send-lead-repository';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';

import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { Lead } from '@core/domain/entities/autov2/lead';

export type SendLeadFactoryProps = {
  onError?: (error) => void;
  service?: string;
  baseURL?: string;
  environment?: string;
  recaptchaSiteKey?: string;
  products?: { codigo: string; nome: string }[];
  channel?: string | null;
};

export function sendLeadFactory({
  service = '/v1/mktseguros/leads',
  baseURL = 'https://mktseguros-api.dev.cloud.itau.com.br',
  environment = 'local',
  recaptchaSiteKey,
  products,
  channel,
}: SendLeadFactoryProps) {
  const httpPostClient: IHttpPostClient<Lead, string> = new AxiosHttpPostClient(
    service,
    {
      'x-environment': environment,
      'x-utm-source': 'loja',
    },
    baseURL
  );

  const sendLeadRepository = new SendLeadRepository(httpPostClient);
  return new SendLeadUsecase(sendLeadRepository, recaptchaSiteKey, products, channel);
}
