// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import FocusTrap from 'focus-trap-react';
import { ItauIcon } from '../../common/itau-icon/';
import { DropdownOption, DropdownProps } from '../../../models/dropdown';
import {
  DropdownContainer,
  ExpandableListRef,
  ListContainer,
  ListItem,
  ListLabel,
  ListLink,
  ListWrapper,
  MenuItemHeader,
  Wrapper,
} from './styles';
import { keyboardControlEvents } from './events';
import { LIST_ITEM_ID_PREFIX, SELECTOR_DATA_ATTR } from './constants';

enum ArrowIcon {
  UP = 'seta_up',
  DOWN = 'seta_down',
}

export const DropdownHeaderMui = ({
  id = 'dropdown',
  testIds = {
    wrapper: 'dropdown',
    openDropdownButton: 'openDropdownButton',
    closeDropdownButton: 'openDropdownButton',
    optionsListContainer: 'optionsListContainer',
    openDropdownContainer: 'openDropdownContainer',
    dropdownOptions: 'dropdownOptions',
  },
  options,
  defaultOption,
  unselectable = true,
  alignRight = false,
  HeaderButtonProps = {
    id: 'dropdownHeaderButton',
  },
}: DropdownProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption>(defaultOption);

  const menuItemRef = useRef(null);
  const buttonHeaderRef = useRef(null);

  const [dropdownContainerRef, dropdownContainerBounds] = useMeasure({ polyfill: ResizeObserver });
  const listWrapperMaxHeight: number = dropdownContainerBounds.height + 20;

  const handleClickAway = () => setExpanded(false);

  const handleDropdownClick = (): void => {
    const elementRef = !expanded ? buttonHeaderRef : menuItemRef;
    const time = !expanded ? 300 : 0;

    setTimeout(() => elementRef?.current?.focus(), time);
    setExpanded((oldOpenedValue) => !oldOpenedValue);
  };

  const handleOptionClick = (option: DropdownOption) => {
    return () => {
      if (!unselectable) {
        setSelected(option);
        setExpanded(false);
      }

      option?.onClick();
    };
  };

  const generateSelectorDataAttr = (key: string | number) => ({
    [SELECTOR_DATA_ATTR]: LIST_ITEM_ID_PREFIX.concat(String(key)),
  });

  const commonPropsOnTriggerButtons = {
    alignRight,
    role: 'listbox',
    active: expanded,
    'aria-live': 'assertive',
    'aria-expanded': expanded,
    'aria-label': HeaderButtonProps?.['aria-label'],
  };

  useEffect(() => {
    if (!buttonHeaderRef) return;

    const listItemsSelector = `#${id} [${SELECTOR_DATA_ATTR}^=${LIST_ITEM_ID_PREFIX}]`;
    const listItems = document.querySelectorAll(listItemsSelector);

    const scapeFunction = () => {
      setExpanded(false);
      setTimeout(() => menuItemRef?.current?.focus(), 50);
    };

    keyboardControlEvents(listItems, scapeFunction);
  }, [buttonHeaderRef]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FocusTrap active={expanded} focusTrapOptions={{ allowOutsideClick: true }}>
        <Wrapper id={id} data-testid={testIds?.wrapper} aria-live="assertive">
          <button
            disableRipple
            ref={menuItemRef}
            aria-controls={HeaderButtonProps?.id}
            onClick={handleDropdownClick}
            data-testid={testIds?.openDropdownButton}
            {...commonPropsOnTriggerButtons}
          >
            <MenuItemHeader>{selected.label}</MenuItemHeader>
            <ItauIcon ariaHidden iconName={expanded ? ArrowIcon.UP : ArrowIcon.DOWN} />
          </button>
          <ListWrapper alignRight={alignRight} id={HeaderButtonProps?.id}>
            <ListContainer
              active={expanded}
              maxHeight={listWrapperMaxHeight}
              alignRight={alignRight}
              data-testid={testIds?.openDropdownContainer}
            >
              <ExpandableListRef ref={dropdownContainerRef} id="dropdownExpandableList" role="navigation">
                <DropdownContainer role="menu" alignRight={alignRight} data-testid={testIds?.optionsListContainer}>
                  {options?.length &&
                    options.map((option, index) => (
                      <ListItem key={option.id}>
                        <ListLink
                          tabIndex={-1}
                          role="menuitem"
                          href={option?.href}
                          onClick={handleOptionClick(option)}
                          data-testid={testIds.dropdownOptions}
                          {...generateSelectorDataAttr(index + 1)}
                        >
                          <span aria-hidden={true}>{option?.icon && option.icon}</span>
                          <ListLabel>{option.label}</ListLabel>
                        </ListLink>
                      </ListItem>
                    ))}
                </DropdownContainer>
              </ExpandableListRef>
            </ListContainer>
          </ListWrapper>
        </Wrapper>
      </FocusTrap>
    </ClickAwayListener>
  );
};
