import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BoxWeb,
  ButtonWeb,
  InputWeb,
  ItauIcon,
  ModalWeb,
  SelectOption,
} from '@workspace/frontend/shared/presentation/components';
import { Button, CircularProgress, Divider, IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled from 'styled-components';

export interface ModalFilteredSelectionProps {
  value?: string;
  onChange?: (e) => void;
  onInputChange?: (e) => void;
  onBlur?: (e) => void;
  items: Array<SelectOption>;
  label?: string;
  name?: string;
  id?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  disableFilter?: boolean;
}

const Hidden = styled.p`
  height: 0;
  width: 0;
  text-indent: -9999px;
`;

export function ModalFilteredSelection({
  label,
  id,
  value,
  disabled,
  helperText,
  error,
  items = [],
  onChange,
  onInputChange,
  loading,
  required,
  disableFilter = false,
}: ModalFilteredSelectionProps) {
  const selectInitialInputRef = useRef(null);
  const searchTextInputRef = useRef(null);
  const firstListItemElement = useRef(null);

  const setRenderValueByParam = useCallback(() => {
    const findSelectedValue = items?.find((item) => item.key?.toString() === value?.toString());
    if (findSelectedValue) return findSelectedValue.value;
    return '';
  }, [value]);

  const [renderValue, setRenderValue] = useState<string>(setRenderValueByParam());
  const [searchTextValue, setSearchTextValue] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [resultList, setResultList] = useState<Array<SelectOption>>(items);
  const [focusedListItem, setFocusItem] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(loading);

  const handleOpenSelect = () => {
    if (disabled) return;
    setSearchTextValue(renderValue);
    setOpenModal(true);
  };

  useEffect(() => {
    setRenderValue(setRenderValueByParam());
  }, [value]);

  const handleCloseSelect = () => {
    setOpenModal(false);
    setTimeout(() => {
      selectInitialInputRef.current.focus();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      searchTextInputRef?.current?.focus();
    }, 300);
  }, [openModal]);

  const handleResetSearchField = () => {
    setSearchTextValue('');
    setResultList(items);
    searchTextInputRef.current.focus();
  };
  useEffect(() => {
    setResultList(items);
  }, [items]);

  if (loading && !searching) setSearching(true);

  const handleFilterResults = (event) => {
    const currentSearchText = event.target.value;
    setSearchTextValue(currentSearchText);
    if (currentSearchText.length > 0 && !disableFilter) {
      const filterResultsBySearchText = (searchText: string) =>
        items.filter((item) => item.value.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));

      const filteredResults: Array<SelectOption> = filterResultsBySearchText(currentSearchText);
      setResultList(filteredResults);
    }

    if (onInputChange) {
      onInputChange(currentSearchText);
    }
  };

  const filteredResultMemo = useMemo(() => {
    const handleListBoxOptions = (listboxOption: SelectOption) => {
      setRenderValue(listboxOption.value);
      setSearchTextValue(listboxOption.value);
      handleCloseSelect();
      if (onChange) {
        onChange(listboxOption.key);
      }
    };

    const emptyList = !loading && resultList.length === 0 && items.length > 0;

    const ariaLabelLive = loading
      ? 'carregando'
      : emptyList
      ? 'carregado, não há resultados para sua busca'
      : `carregado, lista de ${resultList.length} elementos`;

    const handleListKeyDown = () => {
      type Item = HTMLElement | undefined;

      const isListItem = (el: Item): Item => (el && el.nodeName === 'BUTTON' ? el : undefined);

      const getElementByKeyCode = {
        ArrowUp: (el: HTMLElement) => el.previousSibling?.previousSibling,
        ArrowDown: (el: HTMLElement) => el.nextSibling?.nextSibling,
      };

      const codes = ['ArrowDown', 'ArrowUp'];

      const handler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        const keyCode = e.code;

        if (!codes.includes(keyCode)) {
          return;
        }

        e.preventDefault();

        const nextItem: Item = getElementByKeyCode[keyCode](e.target);

        if (!isListItem(nextItem)) {
          if (keyCode === 'ArrowUp') {
            searchTextInputRef?.current?.focus();
          }
          return;
        }

        const pos: number = +nextItem.getAttribute('aria-posinset');

        if (nextItem && pos >= 0 && pos <= resultList.length) {
          nextItem.focus();
        }
      };

      return handler;
    };

    const getListItemId = (index) => `select-${id}-listItem${index}`;

    return (
      <>
        <BoxWeb mt="1rem" textAlign="center">
          {loading && <CircularProgress color="primary" />}
          {emptyList && <span>não há resultados para sua busca</span>}
        </BoxWeb>
        <BoxWeb aria-live="polite">
          <Hidden>{searching && ariaLabelLive}</Hidden>
        </BoxWeb>
        <BoxWeb
          role="listbox"
          aria-activedescendant={focusedListItem}
          data-testid={`select-modal-list-${id}`}
          position="absolute"
          height="100%"
          overflow="scroll"
          width="100%"
          aria-required={required}
          onKeyDown={handleListKeyDown()}
        >
          {!loading &&
            resultList.map((item: SelectOption, i: number) => (
              <>
                <Button
                  {...(i === 0 ? { ref: firstListItemElement } : {})}
                  id={getListItemId(i)}
                  data-testid={`select-modal-item-list-${id}`}
                  key={`item-${i}`}
                  fullWidth
                  size="large"
                  onClick={() => handleListBoxOptions(item)}
                  role={'option'}
                  aria-posinset={i + 1}
                  aria-setsize={resultList.length}
                  aria-selected={focusedListItem === getListItemId(i)}
                  onFocus={() => {
                    setFocusItem(getListItemId(i));
                  }}
                  onBlur={() => {
                    setFocusItem('');
                  }}
                >
                  {item.value}
                </Button>
                <Divider aria-hidden />
              </>
            ))}
        </BoxWeb>
      </>
    );
  }, [loading, resultList, focusedListItem, searching]);

  const showCleanInputField = searchTextValue.length > 0;

  const handleTextFieldKeyDown = (e) => {
    const spaceKeyCode = 'Space';

    if (e.code === spaceKeyCode) {
      handleOpenSelect();
    }
  };

  const inputMemo = useMemo(
    () => (
      <InputWeb
        fullWidth
        inputRef={selectInitialInputRef}
        inputProps={{
          'aria-label': label,
          'aria-describedby': `modal-filter-select-helper-text-${id}`,
          id,
          'data-testid': id,
          readonly: 'readonly',
          onClick: handleOpenSelect,
          onKeyDown: handleTextFieldKeyDown,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" aria-hidden>
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          id: `modal-filter-select-helper-text-${id}`,
        }}
        label={label}
        value={renderValue}
        disabled={disabled}
        onClick={handleOpenSelect}
        helperText={!disabled ? helperText : ''}
        error={error}
      >
        <option key={''} value={''} hidden></option>
        {items.map((item) => (
          <option key={item.key} value={item.key} hidden>
            {item.value}
          </option>
        ))}
      </InputWeb>
    ),
    [renderValue, disabled, helperText]
  );

  const handleInputSearchKeyDown = (e) => {
    if (e.code === 'ArrowDown' && resultList.length > 0) {
      e.preventDefault();
      firstListItemElement?.current?.focus();
    }
  };

  const filterInputMemo = useMemo(
    () => (
      <InputWeb
        fullWidth
        id={`modal-filter-select-input-${id}`}
        label={label}
        value={searchTextValue}
        placeholder="digite para filtrar"
        onChange={handleFilterResults}
        onKeyDown={handleInputSearchKeyDown}
        inputProps={{
          ref: searchTextInputRef,
          'data-testid': `modal-filter-select-${id}`,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="icon-itaufonts_busca_consulta" aria-hidden />
            </InputAdornment>
          ),
          endAdornment: showCleanInputField && (
            <InputAdornment position="end">
              <IconButton
                onClick={handleResetSearchField}
                aria-label="limpar campo"
                data-testid={`modal-filter-select-clean-button-${id}`}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={!disabled ? helperText : ''}
        error={error}
      />
    ),
    [disabled, error, helperText, searchTextValue, resultList]
  );

  const modalMemo = useMemo(
    () => (
      <ModalWeb
        id={`modal-filter-select-${id}`}
        onClose={handleCloseSelect}
        open={openModal}
        ariaLabelledby={`modal-filter-title-${id}`}
      >
        <BoxWeb display={'flex'} alignItems={'center'} justifyContent={'space-between'} mx={'1rem'}>
          <BoxWeb p={'1rem'} width={'80%'} component={'h2'} id={`modal-filter-title-${id}`}>
            {label}
          </BoxWeb>

          <BoxWeb width={'fit-content'} color="primary.main">
            <ButtonWeb
              data-testid={`modal-filter-select-close-button-${id}`}
              color="primary"
              onClick={handleCloseSelect}
              aria-label={'fechar'}
              variant="text"
              label={<ItauIcon iconName="fechar" style={{ fontSize: '36px', color: 'inherit' }} />}
            />
          </BoxWeb>
        </BoxWeb>

        <BoxWeb height={'1rem'} />

        <BoxWeb pr={{ xs: 'none', sm: '2rem' }} pl={{ xs: 'none', sm: '2rem' }}>
          {filterInputMemo}
        </BoxWeb>
        <BoxWeb mt="1rem">{filteredResultMemo}</BoxWeb>
      </ModalWeb>
    ),
    [openModal, filteredResultMemo, filterInputMemo]
  );

  return (
    <>
      {inputMemo}
      {modalMemo}
    </>
  );
}
