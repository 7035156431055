import { BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BoxSteps } from '../../hiring-utils';


const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30rem;
`;

const ActionsWrapper = styled.div`
  margin-top: 4rem;
`;

type NoOffersPageProps = {
  description: string;
  action: any;
};

export function NoOffersPage({ description, action }: NoOffersPageProps) {
  const titleRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 300);
  });

  return (
    <BoxSteps>
      <BoxWeb mx="auto" display="flex" width="100%" justifyContent="space-around" flexDirection={['column', 'row']}>
        <Content>
          <Title tabIndex={-1} ref={titleRef}>
            {description}
          </Title>
          <ActionsWrapper>
            <ButtonWeb label="alterar endereço" onClick={action} />
          </ActionsWrapper>
        </Content>
        <BoxWeb mt={['3rem', '0']}>
          <img
            style={{ width: 'inherit' }}
            alt="tivemos um imprevisto"
            aria-hidden
            src="https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/auto/ilustracoes/icone_block.svg"
          />
        </BoxWeb>
      </BoxWeb>
    </BoxSteps>
  );
}
