import palette from './my-account/palette';
import typography from './my-account/typography';
import breakpoints from './my-account/breakpoints';
import props from './my-account/props';
import overrides from './my-account/overrides';

const style = {
  typography,
  palette,
  breakpoints,
  shape: {},
  overrides,
  props,
};

export default style;
