import { FormControllerFieldConnector } from '@web/form';
import { InputMaskType, InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const RgField = ({ disabled, id, label = 'RG' }: { disabled?: boolean; id?: string; label?: string }) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name="rg"
    disabled={disabled}
    validation={stringValidationFactory({
      max: { limit: 11, message: 'você precisa informar o rg até 11 dígitos' },
      requiredMessage: 'você precisa informar o rg',
    })}
    componentProps={{
      label,
      id,
      maskType: InputMaskType.RG,
    }}
  />
);
