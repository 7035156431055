import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';
import { checkCreditCardFlag } from './credit-card-utils';
import { possibleBadges, possibleBadgesArray } from '@workspace/frontend/auto/domain/entities';

export const CreditCardNumberField = ({
  id = 'txtCreditCardNumber_screen',
  label = 'número do cartão',
  type = 'tel',
  allowedBadges = [
    possibleBadges.AURA,
    possibleBadges.AMEX,
    possibleBadges.MASTERCARD,
    possibleBadges.DINERS,
    possibleBadges.ELO,
    possibleBadges.VISA,
  ],
}: {
  id?: string;
  label?: string;
  allowedBadges?: possibleBadgesArray;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name={'creditCardNumber'}
    validation={stringValidationFactory({
      min: { limit: 13, message: 'o número deve conter no mínimo 13 caracteres' },
      max: { limit: 16, message: 'o número deve conter no máximo 16 caracteres' },
      requiredMessage: 'informe o número do cartão',
      transform: (value) => value.replace(/\s/g, ''),
      test: [
        {
          name: 'allowed badge',
          message: 'bandeira não permitida',
          test: (val) => {
            const res = checkCreditCardFlag(val);
            if (res === null) return false;
            return allowedBadges.includes(res);
          },
        },
      ],
    })}
    componentProps={{ id, label, type, maskType: '9999 9999 9999 9999' }}
  />
);
