import styled from 'styled-components';

export const SelectFieldBox = styled.div`
  width: 100%;
  margin: 0.8rem 0;
  @media (min-width: ${(props) => props.theme.breakpoints?.values.lg}px) {
    margin-right: 1rem;
    margin-left: 0;
    margin-top: 0;
    width: 200px;
  }
`;

export const BrandAndModelFieldBox = styled.div`
  width: 100%;
  margin-top: 0.8rem;
  @media (min-width: ${(props) => props.theme.breakpoints?.values.lg}px) {
    width: 300px;
    margin: 0 1rem;
  }
`;

export const CompanyInsuranceBox = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media (min-width: ${(props) => props.theme.breakpoints?.values.lg}px) {
    width: 300px;
  },
`;

export const PlateFieldBox = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints?.values.lg}px) {
    width: 350px;
  },
`;
