export class Plate {
  static MASK = 'aaa9*99';

  private static REGEX = /[a-zA-Z]{3}[0-9a-zA-Z]{4}/;
  private readonly _value: string;

  get isValid(): boolean {
    return Plate.REGEX.test(this._value);
  }

  get value(): string {
    return this._value;
  }

  constructor(plate: string) {
    this._value = plate;
  }
}
