import { SelectOption } from '@workspace/frontend/shared/presentation/components';

interface DomainAdapter {
  bonusClass: SelectOption[];
  insuranceCompany: SelectOption[];
  banks: SelectOption[];
  badges: SelectOption[];
  incomeBracket: SelectOption[];
  occupation: SelectOption[];
  nationality: SelectOption[];
}

export function DomainAdapter(data): DomainAdapter {
  const res = {
    insuranceCompany: undefined,
    bonusClass: undefined,
    banks: undefined,
    badges: undefined,
    incomeBracket: undefined,
    occupation: undefined,
    nationality: undefined,
  };

  const parseInsuranceCompanyList = (insuranceCompanyList) => {
    return insuranceCompanyList.map((item) => ({
      key: item.codigoSusepCongenere,
      value: item.nomeLegalPessoa,
    }));
  };

  const parseDomainType = (domainTypes): { key: number | string; value: string }[] => {
    return domainTypes.map((item) => ({
      key: item.codigo,
      value: item.descricao,
    }));
  };

  const parseOccupation = (occupation) =>
    occupation.map((item) => ({
      key: item.numeroOcupacao,
      value: item.nomeOcupacao,
    }));

  const parseIncomeBracket = (incomeBracket) =>
    incomeBracket.map((item) => ({
      key: item.codigo,
      value: item.nome,
    }));

  const _data = data.data;

  res.bonusClass = parseDomainType(data.classeBonus);
  res.insuranceCompany = parseInsuranceCompanyList(data.tipoCongeneres);
  res.banks = parseDomainType(data.bancos);
  res.badges = parseDomainType(data.bandeirasCartoes);
  res.incomeBracket = parseIncomeBracket(data.faixaRendaMensal);
  res.occupation = parseOccupation(data.ocupacao);
  res.nationality = parseDomainType(data.nacionalidade);

  return res;
}
