import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { FindVehicleByPlateRepository } from '@core/infra/repository/autov2/find-vehicle-by-plate-repository';
import { FindVehicleByPlateUsecase } from '@core/data/services/find-vehicle-by-plate-usecase';
import { VehicleResponse } from '@core/domain/entities/autov2/response/vehicle';
import { VehicleSearchByPlate } from '@core/infra/models/vehicle-search-by-plate';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { EnvironmentContextProps } from '@workspace/providers';

export type FindVehicleByPlateFactoryProps = {
  onError: (error) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

export function findVehicleByPlateFactory({ partialEnvironment, onError }: FindVehicleByPlateFactoryProps) {
  const { environment } = partialEnvironment;
  const httpGetClient: IHttpGetClient<{ params: VehicleSearchByPlate }, { data: VehicleResponse }> = new AxiosHttpGetClient('automovel/buscaveiculo', {}, environment.gatewayBFFBaseURL.url);

  const fazerSimulacaoRepository = new FindVehicleByPlateRepository(httpGetClient);
  return new FindVehicleByPlateUsecase(fazerSimulacaoRepository, onError);
}
