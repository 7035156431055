import { SharedEnvironment } from './shared.model';

export const shared: SharedEnvironment = {
  production: false,
  environment: 'hom',
  version: '1.0',
  cmsBase: {
    url: 'https://cdn.contentstack.io/v3',
    environment: 'qa',
    contentType: 'seg_dynamiclandpage',
  },
  contentStack: {
    apiKey: 'blt90a7e08c6e8530e7',
    accessToken: 'cs1353a436461e5453612107d1',
  },
};
