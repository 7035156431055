import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { emailValidation } from '@workspace/frontend-shared-application-validation';
import { EMAIL_REGEX } from '../../../utils/validations';

const validationOverrides = {
  requiredMessage: 'campo obrigatório',
  matches: {
    pattern: EMAIL_REGEX,
    message: 'verifique o formato do e-mail',
  },
};
export const NetworkEmailField = ({ prefix = undefined, label = 'insira seu e-mail', ...props }) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name={prefix ? prefix + '_email' : 'email'}
    validation={emailValidation(validationOverrides)}
    onChange={props.onChange}
    onBlur={props.onBlur}
    componentProps={{ label, ...props.componentProps }}
  />
);
