import { Buttons, FeedbackMessage, Input, PageTitle } from '../../../../components/src';
import { useEffect, useState } from 'react';
import { CommonInput, CommonLabel, Container, ContainerWrapper, InputContainer, Row } from './my-account-container-styles';
import { getAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { getUserInfoFactory, sendNicknameFactory } from '@workspace/frontend/my-account/infra/factories';

export const MyAccountContainer = () => {
  type User = {
    name: string;
    cellphone: string;
    document: string;
    email: string;
    password: string;
  };
  const [inputValue, setInputValue] = useState('');
  const [userData, setUserData] = useState<User | undefined>();
  const [status, setStatus] = useState<string>('default');
  const sendNicknameUsecase = sendNicknameFactory();
  const getUserInfoUsecase = getUserInfoFactory();
  const authData = getAuthentication();

  const fetchData = async () => {
    const user = await getUserInfoUsecase.execute(authData.identifier, {
      Authorization: `Bearer ${authData.accessToken}`,
    });
    setUserData(user.data.value);
  };
  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const payload = {
        nickname: inputValue,
        userIdentifier: authData.identifier,
      };
      await sendNicknameUsecase.execute(payload, {
        Authorization: `Bearer ${authData.accessToken}`,
      });
      setStatus('success');
    } catch (error) {
      setStatus('failure');
    }
  }
  function handleInputValue(event) {
    setInputValue(event.target.value);
  }
  function replaceValue(event) {
    const input = event.target;
    input.value = input.value.replace(/[^\wÀ-ú]+/, '');
  }
  return (
    <div>
      {status != 'default' ? <FeedbackMessage status={status} /> : ''}
      <Container>
        <ContainerWrapper>
          <form onSubmit={handleSubmit}>
            <PageTitle title={'minha conta'} subtitle={'Aqui você encontra as informações sobre sua conta'} />
            <div>
              <Row>
                <div>
                  <InputContainer>
                    <CommonLabel>como gostaria de ser chamado?</CommonLabel>
                    <CommonInput onChange={handleInputValue} onKeyUp={replaceValue} />
                  </InputContainer>
                </div>
                <Input label={'nome completo'} input={userData?.name} />
                <Input label={'celular com DDD'} input={userData?.cellphone} />
              </Row>
              <Row>
                <Input label={'CPF'} input={userData?.document} />
                <Input label={'e-mail'} input={userData?.email} />
                <Input label={'senha'} input={userData?.password} />
              </Row>
            </div>

            <Buttons />
          </form>
        </ContainerWrapper>
      </Container>
    </div>
  );
};
