import {
  ActionEventAnalyticsInterface,
  PageActionAnalyticsInterface,
  PageActionEventAnalyticsInterface,
  PageAnalyticsInterface,
} from './analytics';
import { ANALYTICS_EVENT_RULES } from '../constants/rules';
import { replaceLabelTokensByValues } from './replace-label-tokens-by-values';
import { ANALYTICS_EVENT_ENVIRONMENTS } from '../constants/environments';
import { ANALYTICS_EVENT_SQUADS } from '../constants/squads';

function mapper(mapper, obj) {
  const res = {};
  for (const key in obj) {
    if (obj[key]) {
      res[mapper[key]] = obj[key];
    }
  }
  return res;
}

const assembleAnalyticsPageCustomEvent = (
  analyticsPageCustomEvent: PageActionEventAnalyticsInterface,
  labelValues: string[] = [],
  options: { origin?: string; product?: string } = {}
): PageActionAnalyticsInterface => {
  const eventPage: PageAnalyticsInterface = { nome: analyticsPageCustomEvent.pageName };
  const formattedLabel: string = replaceLabelTokensByValues(analyticsPageCustomEvent.label, labelValues);
  const formattedAnalyticsCustomEvent: ActionEventAnalyticsInterface = {
    category: analyticsPageCustomEvent.category,
    action: analyticsPageCustomEvent.action,
    label: formattedLabel,
  };

  const res: PageActionAnalyticsInterface = {
    page: eventPage,
    events: formattedAnalyticsCustomEvent,
    rule: ANALYTICS_EVENT_RULES.CUSTOM_LINK,
  };

  if (Object.keys(options)?.length > 0) {
    res.transaction = {};
    if (options.origin) res.transaction.name = options.origin;
    if (options.product) res.transaction.details = options.product;
  }

  return res;
};

const assembleAnalyticsPageCustomEventV2 = (
  analyticsPageCustomEvent: PageActionEventAnalyticsInterface,
  pageLabelValues: string[] = [],
  actionLabelValues: string[] = []
): PageActionAnalyticsInterface => {
  const eventPage: PageAnalyticsInterface = { nome: analyticsPageCustomEvent.pageName };
  const formattedPageLabel: string = replaceLabelTokensByValues(eventPage.nome, pageLabelValues);
  const formattedActionLabel: string = replaceLabelTokensByValues(analyticsPageCustomEvent.label, actionLabelValues);
  eventPage.nome = formattedPageLabel;

  const formattedAnalyticsCustomEvent: ActionEventAnalyticsInterface = {
    category: analyticsPageCustomEvent.category,
    action: analyticsPageCustomEvent.action,
    label: formattedActionLabel,
  };

  return {
    rule: ANALYTICS_EVENT_RULES.CUSTOM_LINK,
    page: eventPage,
    custom: {
      squadresponsaveltag: ANALYTICS_EVENT_SQUADS.CUSTOMER_SUCCESS,
    },
    events: formattedAnalyticsCustomEvent,
    site: {
      platform: ANALYTICS_EVENT_ENVIRONMENTS.NI,
    },
    visitor: {
      customerid: null, // TODO - Can only be developed after the login is finished
      customersegment: null, // TODO - Can only be developed after the login is finished
    },
  };
};

export { assembleAnalyticsPageCustomEvent, assembleAnalyticsPageCustomEventV2 };
