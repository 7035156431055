import { authImages } from '@web/assets';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: row;
    width: 100%;
  }
`;

type ModalImage = {
  src: string;
};

export const ModalImage = styled.div<ModalImage>`
  background-image: url(${({ src }) => src});
  width: 195px;
  height: 139px;
`;

export const CloseModal = styled.button`
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  top: 20px;
  right: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    top: 40px;
    right: 40px;
  }
`;
