import { AccordionWeb, BoxWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import styled from 'styled-components';
import { SummaryAccordionTable } from './summary-accordion-table';
import { SummaryAccordionGroup } from './summary-accordion-group';

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: lightgray;
`;

export function SummaryAccordionContainer({ content }) {
  return (
    <>
      <BoxWeb height={38} />
      <AccordionWeb fontSize={'1.5em'} title={'ver resumo do seu seguro'}>
        <BoxWeb width={'100%'}>
          <BoxWeb height={36} width={'100%'} />
          <BoxWeb fontSize={'1.5em'} fontWeight={'bold'}>
            serviços do seu seguro
          </BoxWeb>
          <Divider />
          <BoxWeb height={26} width={'100%'} />

          <SummaryAccordionTable content={content[0]} header1={'coberturas'} header2={'valores'} />
          <SummaryAccordionTable content={content[1]} header1={'assistência'} header2={'valores'} />
          <Divider />

          <BoxWeb height={16} />
          <TypographyWeb component={'span'}>**total do seu seguro: **</TypographyWeb>
          <span>{content[2]}</span>
          <BoxWeb height={16} />

          <SummaryAccordionGroup title={'dados do titular do seguro'} content={content[3]} />

          <SummaryAccordionGroup title={'endereço de residência'} content={content[4]} />
        </BoxWeb>
      </AccordionWeb>
      <BoxWeb height={26} />
    </>
  );
}
