import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'mf',
});

export const ThemeProviderMui = ({ theme, children }) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};
