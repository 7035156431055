import { Tj } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import { BoxWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';

type TjDynamicProps = {
  content: Tj;
};

export const TjDynamic = ({ content }: TjDynamicProps) => {
  return (
    <BoxWeb
      bgcolor={'background.gray'}
      height={{ xs: '600px', sm: '350px' }}
      display="flex"
      flexDirection="column"
      justifyContent={'space-around'}
      alignItems={'center'}
    >
      <BoxWeb
        width="100%"
        height="100%"
        textAlign={'center'}
        fontSize={'13px'}
        display="flex"
        flexDirection="column"
        justifyContent={'space-around'}
      >
        <BoxWeb>
          <MarkdownWeb>{content?.title_one}</MarkdownWeb>
        </BoxWeb>
        <BoxWeb>
          <MarkdownWeb>{content?.title_two}</MarkdownWeb>
        </BoxWeb>
        <BoxWeb>
          <MarkdownWeb>{content?.title_three}</MarkdownWeb>
        </BoxWeb>
      </BoxWeb>

      {content?.actions.length > 0 && (
        <BoxWeb display="flex" flexDirection="row" width={{ xs: '300px', sm: '500px' }} textAlign={'center'}>
          <BoxWeb>
            <a href={content?.actions[0]?.url}>{content?.actions[0]?.title}</a>
          </BoxWeb>
          <BoxWeb ml={'1rem'} mr={'1rem'}>
            {' '}
            |{' '}
          </BoxWeb>
          <BoxWeb>
            <a href={content?.actions[1]?.url}>{content?.actions[1]?.title}</a>
          </BoxWeb>
        </BoxWeb>
      )}
    </BoxWeb>
  );
};
