import { DynamicLandpage } from '@core/domain/entities/dynamic-landings/dynamic-landpage';

export const dynamicLandPage: DynamicLandpage = {
  url: '/consorcio',
  enable_header: true,
  home: {
    title: 'Seguro residencial',
    subtitle: 'subtitulo da pagina',
    description: '',
    partners_image: ['https://uri', 'https://uri', 'https://uri'],
    partners_alt: ['alt1', 'alt2', 'alt3'],
  },
  banner: {
    banner_image: {
      path: 'path',
      size: 'size',
      title: 'title',
      url: 'url',
    },
    alt: 'alt',
    title: 'titulo imagem',
    subtitle_middle: 'middle title',
    subtitle: 'subtitulo imagem',
    action: {
      label: 'link para parceiro',
      type: 'redirect',
      url: 'url',
      helper_text: 'texto descritivo',
    },
  },
  infos: {
    title: 'title infos',
    subtitle: 'subtitle infos',
    description: 'description infos',
  },
  detail: {
    title: 'title detail',
    detail_items: [
      {
        title: 'descricao',
        subtitle: 'descricao',
        image: 'https://uri',
        alt: 'alt',
      },
    ],
    action: {
      label: 'link para parceiro',
      type: 'redirect',
      url: 'https://uri',
      helper_text: 'texto descritivo',
    },
  },
  partner: {
    title: 'parceiro',
    subtitle: 'parceiro',
    image: {
      url: 'https://uri',
    },
    alt: 'alt',
  },
  lead: {
    title: 'title',
    subtitle: 'subtitle',
  },
  tj: {
    title_one: 'texto juridico',
    title_two: 'texto juridico',
    title_three: 'texto juridico',
    actions: [
      {
        title: 'condições gerais',
        url: 'https://uri',
      },
      {
        title: 'termos de uso',
        url: 'https://uri',
      },
    ],
  },
  products: {
    code: '1234',
    name: 'nome',
  },
  analytics: [
    {
      pageload: {
        site: {
          negocio: 'string',
          tipoDeCanal: 'string',
        },
        page: {
          nome: 'string',
          idTecnico: 'string',
          ambiente: 'string',
        },
        visitor: {
          iDPF: 'string',
          segmento: 'string',
        },
        rule: 'string',
      },
      anchor: {
        events: {
          category: 'string',
          action: 'string',
          label: 'string',
          noInteraction: true,
        },
        rule: 'string',
      },
      redirect_top: {
        events: {
          category: 'string',
          action: 'string',
          label: 'string',
          noInteraction: false,
        },
        rule: 'string',
      },
      redirect_bottom: {
        events: {
          category: 'string',
          action: 'string',
          label: 'string',
          noInteraction: false,
        },
        rule: 'string',
      },
      send: {
        events: {
          category: 'string',
          action: 'string',
          label: 'string',
          noInteraction: true,
        },
        rule: 'string',
      },
    },
  ],
};
