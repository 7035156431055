import React, { useState } from 'react';
import { AlertWeb, BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { FormController, FormControllerErrorBanner } from '@web/form';
import { CellphoneField, CpfField, EmailField, NameField, TermField } from '@web/form/component';
import { sendLeadFactory } from '@workspace/frontend/auto/presentation/web-app';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { Products } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import { ANALYTICS_DYNAMIC_LANDING } from '@workspace/frontend-shared-application-analytics';

const labelTerms =
  'Os dados inseridos por você a partir de agora serão compartilhados com as empresas parceiras e utilizados na cotação e, se aprovada, na contratação do produto escolhido.';

type FormFieldProps = {
  name: string;
  cpf: string;
  email: string;
  cellphone: string;
  termField: boolean;
};

type LeadProps = {
  products: Products;
  sendCmsAnalytics: any;
};

export const LeadDynamic = ({ products, sendCmsAnalytics }: LeadProps) => {
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { environment } = partialEnvironment;

  let channel: string | null = sessionStorage.getItem('source');
  if (channel) {
    channel = JSON.parse(channel).value;
  }

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment?.recaptchaSiteKey,
    products: environment?.leadProcessor?.products,
    channel,
  });

  const onSubmit = async (props: FormFieldProps): Promise<void> => {
    ANALYTICS_DYNAMIC_LANDING.DYNAMIC_LANDING.CALL(sendCmsAnalytics);
    setSuccess(false);
    const { name, cpf, email, cellphone } = props;
    setLoading(true);
    try {
      const response: boolean = await sendLeadUsecase.execute({ name, cpf, email, cellphone });
      if (response) setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      setError(true);
    }
  };

  return (
    <BoxWeb
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={'auto'}
      pb={'4rem'}
      pt={'4rem'}
      width={{ xs: '320px', sm: '500px' }}
    >
      <FormController onSubmit={onSubmit} hideErrors>
        {() => {
          return (
            <>
              <BoxWeb>
                <FormControllerErrorBanner />
              </BoxWeb>
              {error && (
                <BoxWeb mb={'2rem'}>
                  <AlertWeb variant="filled" severity="error">
                    Houve algum problema, tente novamente :(
                  </AlertWeb>
                </BoxWeb>
              )}
              {success && (
                <BoxWeb mb={'2rem'}>
                  <AlertWeb variant="filled" severity="success">
                    Enviado com sucesso! :D
                  </AlertWeb>
                </BoxWeb>
              )}
              <BoxWeb mb={'2rem'}>
                <NameField
                  componentProps={{
                    label: 'como podemos te chamar?',
                    id: 'txtLeadsName_screen',
                  }}
                />
              </BoxWeb>
              <BoxWeb mb={'2rem'}>
                <CpfField
                  componentProps={{
                    label: 'qual seu CPF?',
                    id: 'txtLeadsCpf_screen',
                  }}
                />
              </BoxWeb>
              <BoxWeb mb={'2rem'}>
                <EmailField
                  componentProps={{
                    label: 'pode me passar seu e-mail',
                    id: 'txtLeadsEmail_screen',
                    type: 'email',
                  }}
                />
              </BoxWeb>
              <BoxWeb mb={'2rem'}>
                <CellphoneField
                  componentProps={{ label: 'em qual número podemos te ligar?', id: 'txtLeadsCellphone_screen' }}
                />
              </BoxWeb>
              <BoxWeb mb={'2rem'}>
                <TermField label={labelTerms} id={'checkBox_termField_lead'} />
              </BoxWeb>
              <BoxWeb width={'162px'}>
                <ButtonWeb label={'enviar dados'} loading={loading} type={'submit'} id="btnContinue_screen" />
              </BoxWeb>
            </>
          );
        }}
      </FormController>
    </BoxWeb>
  );
};
