import { put } from 'redux-saga/effects';
import { itemRiskAutomobileTypes } from '../../reducers/itemRisk/item-risk-automobile-handlers';

export interface ItemRiskStepInterface {
  plate: string;
  zeroKm: boolean;
  bonusClass: string;
  brandAndModel: string;
  insuranceCompany: string;
  modelYear: Date;
  fabricationYear: Date;
}

function* itemRiskStepEffect({
  plate,
  zeroKm,
  bonusClass,
  brandAndModel,
  insuranceCompany,
  modelYear,
  fabricationYear,
}: ItemRiskStepInterface) {
  yield put({ type: itemRiskAutomobileTypes.setPlate, data: plate });
  yield put({ type: itemRiskAutomobileTypes.setZerokm, data: zeroKm });
  yield put({ type: itemRiskAutomobileTypes.setBonusClass, data: bonusClass });
  yield put({
    type: itemRiskAutomobileTypes.setBrandAndModel,
    data: brandAndModel,
  });
  yield put({
    type: itemRiskAutomobileTypes.setInsuranceCompany,
    data: insuranceCompany,
  });
  yield put({
    type: itemRiskAutomobileTypes.setModelYear,
    data: modelYear,
  });
  yield put({
    type: itemRiskAutomobileTypes.setFabricationYear,
    data: fabricationYear,
  });
}

export default itemRiskStepEffect;
