abstract class SessionStorageBaseRepository {
  protected sessionStorage: Storage;

  protected constructor(private key: string) {
    this.sessionStorage = window.sessionStorage;
  }

  public create(value: string): void {
    this.sessionStorage.setItem(this.key, value);
  }

  public get(): string {
    return this.sessionStorage.getItem(this.key);
  }

  public remove(): void {
    return this.sessionStorage.removeItem(this.key);
  }
}

export { SessionStorageBaseRepository };
