import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { ITokenRepository } from '@core/data/contracts/auth/token-repository';
import { TokenAuth } from '@core/domain/entities/auth/token';
import { TokenAuthResponse } from '@core/domain/entities/auth/response/token-auth-response';

export class SendTokenAuthRepository implements ITokenRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<TokenAuth, TokenAuthResponse>) {}

  async execute(data: TokenAuth, headers?: any) {
    return await this.httpPostClient.post(data, null, headers);
  }
}
