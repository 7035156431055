import { ANALYTICS_EVENT_CATEGORIES } from '../../../constants/categories';
import { ANALYTICS_EVENT_ACTIONS } from '../../../constants/actions';
import { ActionAnalyticsInterface } from '../../../analytics-config/analytics';
import { assembleAnalyticsPageCustomEvent } from '../../../analytics-config/assemble-analytics-page-custom-event';
type T = 'T1';
export const pages: Record<T, string> = {
  T1: 'LS:NL:NCC:Blog:Postagem',
};

type A = 'A1' | 'A2' | 'A3';
const actions: Record<A, string> = {
  A1: 'BTN:Blog:Postagem:{0}:TopoCompartilhe{1}',
  A2: 'BTN:Blog:Postagem:{0}:RodapeCompartilhe{1}',
  A3: 'BTN:Blog:Postagem:{0}:Likert{1}',
};
export type ActionsBlogPostTechSpecI = Record<A, (labels: string[]) => ActionAnalyticsInterface>;
export const ActionsBlogPostTechSpec: ActionsBlogPostTechSpecI = {
  A1: (labels: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.T1 + labels[0] ?? '',
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.BLOG,
        label: actions.A1,
        noInteraction: false,
      },
      labels
    ),
  A2: (labels: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.T1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.BLOG,
        label: actions.A2,
        noInteraction: false,
      },
      labels
    ),
  A3: (labels: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.T1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.BLOG,
        label: actions.A3,
        noInteraction: false,
      },
      labels
    ),
};
