import { useMemo } from 'react';
import { useDynamicBlog } from '../provider/blog-provider';

export const DynamicBlogContent = () => {
  const dynamicBlog = useDynamicBlog();
  const renderRoutes = useMemo(() => {
    const routes = dynamicBlog.blogContent;
    return routes?.map((route) => {
      const { url } = route;
      if (dynamicBlog.createDynamicRoute) return dynamicBlog.createDynamicRoute(url, route);
    });
  }, [dynamicBlog.blogContent]);

  return <>{renderRoutes}</>;
};
