import { CreateCartAppServiceContractInput, CreateCartResidAppServiceContractInput } from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import {ContextsEnum} from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { CreateCartPayload } from '@workspace/utils/abandoned-cart';
import { CreateCartStrategySerializer } from './create-cart-strategy.serializer';

export class CreateCartResidStrategySerializer extends CreateCartStrategySerializer {
    public specializedExecute(input: CreateCartAppServiceContractInput): CreateCartPayload {
        if (input.context !== ContextsEnum.RESID)
            return;

        const data = input as CreateCartResidAppServiceContractInput;

        throw new Error('serializer not implemented.');

        // return {
        //     context: data.context,
        //     customer: {
        //         fullName: 'não informado',
        //         email: 'não informado',
        //         phone: 'não informado',
        //         birthDate: '1990-01-01T00:00:00.000Z',
        //         cpf: '',
        //     },
        //     address: {
        //         cep: '01153000',
        //         street: 'não informado',
        //         number: '0',
        //         complement: 'não informado',
        //         district: 'não informado',
        //         city: 'Sao Paulo',
        //         state: 'SP',
        //         country: 'BR',
        //     },
        //     product: {
        //         title: 'seguro automóvel',
        //         coverages: [
        //             {
        //                 title: 'não informado',
        //                 description: 'não informado',
        //             },
        //         ],
        //     },
        // };
    }
}
