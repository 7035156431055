import { ComponentMode, StyleMode, ThemeProviderWeb, ComponentAdapterTypes, StyleAdapterTypes } from '@workspace/frontend/shared/presentation/components';

export function FAQV2Theme({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
    componentOverrides: [ComponentAdapterTypes.FAQV2_UI],
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.FAQV2,
  };

  return (
    <ThemeProviderWeb rootName="faqv2" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
