import { NetworkStyleRepository } from '@core/infra/repository/autov2/network-style-repository';
import { NewtorkStyleUsecase } from '@core/data/services/network-style-usecase';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { CMSResponse } from '@core/data/models/autov2/cmsResponse';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { EnvironmentContextProps } from '@workspace/providers';

export function networkStyleFactory({ environment }: Partial<EnvironmentContextProps>) {
  const httpGetClient: IHttpGetClient<null, CMSResponse<NetworkStyle[]>> = new AxiosHttpGetClient(
    `/content_types/parceiros_whitelabel/entries/?environment=${environment?.cmsBase?.environment}`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getArticleRepository = new NetworkStyleRepository(httpGetClient);
  return new NewtorkStyleUsecase(getArticleRepository);
}
