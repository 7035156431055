export class Cpf {
  get value(): string {
    return this._value;
  }
  private readonly _value: string;

  static MASK = '999.999.999-99';
  static REGEX = /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}$/;

  constructor(cpf: string) {
    this._value = cpf;
  }

  get isValid(): boolean {
    const regex = Cpf.REGEX.test(this._value);
    if (!regex) return false;
    return Cpf.validateCPF(this.value);
  }

  static validateCPF = (value) => {
    let cpf = value;
    if (cpf === undefined) return true;

    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return true;
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  };
}
