import { rest } from 'msw';

export const getProductsKinship = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/produtos/:id`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        parentesco: [
          {
            codigo: 11,
            descricao: 'companheiro(a)',
          },
          {
            codigo: 2,
            descricao: 'conjuge',
          },
          {
            codigo: 6,
            descricao: 'enteada',
          },
          {
            codigo: 5,
            descricao: 'enteado',
          },
          {
            codigo: 4,
            descricao: 'filha',
          },
          {
            codigo: 3,
            descricao: 'filho',
          },
          {
            codigo: 10,
            descricao: 'irmã',
          },
          {
            codigo: 9,
            descricao: 'irmão',
          },
          {
            codigo: 14,
            descricao: 'mãe',
          },
          {
            codigo: 16,
            descricao: 'neta',
          },
          {
            codigo: 15,
            descricao: 'neto',
          },
          {
            codigo: 17,
            descricao: 'pai',
          },
          {
            codigo: 19,
            descricao: 'sogra',
          },
          {
            codigo: 18,
            descricao: 'sogro',
          },
          {
            codigo: 8,
            descricao: 'tutelada',
          },
          {
            codigo: 7,
            descricao: 'tutelado',
          },
          {
            codigo: 111,
            descricao: 'avó',
          },
          {
            codigo: 112,
            descricao: 'avô',
          },
          {
            codigo: 113,
            descricao: 'bisavó',
          },
          {
            codigo: 114,
            descricao: 'bisavô',
          },
          {
            codigo: 115,
            descricao: 'bisneto',
          },
          {
            codigo: 116,
            descricao: 'bisneta',
          },
          {
            codigo: 117,
            descricao: 'tio',
          },
          {
            codigo: 118,
            descricao: 'tia',
          },
          {
            codigo: 119,
            descricao: 'sobrinho',
          },
          {
            codigo: 120,
            descricao: 'sobrinha',
          },
          {
            codigo: 121,
            descricao: 'primo',
          },
          {
            codigo: 122,
            descricao: 'prima',
          },
          {
            codigo: 123,
            descricao: 'cunhado',
          },
          {
            codigo: 124,
            descricao: 'cunhada',
          },
        ],
        texto_juridico:
          'Plano Odonto MetLife, registro ANS Nº 478.030/17-2, Plano Odonto MetLife Gold, registro ANS Nº 477.849/17-9 e Plano Odonto MetLife Diamond, registro ANS nº 480.956/18-4 providos por METLIFE PLANOS ODONTOLÓGICOS LTDA, empresa inscrita no CNPJ/MF sob o nº 03.273.825/0001-78, com inscrição Estadual/SP nº ISENTA, registrada no CRO-SP sob nº 6191 e na ANS sob o nº 40648-1, classificada como operadora na ANS na modalidade Odontologia de Grupo, com cobertura de todos os procedimentos estabelecidos pelo Rol mínimo da Agência Nacional de Saúde (ANS). Resp Tec: Luís Danilo Bronzatto Maurici CRO-SP 40364. Restrições se aplicam. Consulte, previamente a contratação, as Condições Gerais do Produto.',
      })
    )
  );
};
