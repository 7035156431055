import { ISearchCepUsecase } from "@core/domain/usecases/search-cep-usecase";
import { ISearchCepRepository } from "@core/data/contracts/autov2/search-cep-repository-interface";
import { SearchCepResponse } from "@core/domain/entities/autov2/response/search-cep";

export class SearchCepUsecase implements ISearchCepUsecase {
  constructor(
    private readonly searchCepRepository: ISearchCepRepository,
    private readonly onError: (error) => void
  ) {}
  //Promise<SearchCepResponse>
  async execute(cep: string): Promise<any> {
    try {
      const res = await this.searchCepRepository.execute(cep);
      return res;
    } catch (error) {
      this.onError(error?.response);
    }
  }
}
