import { AuthenticationRepositoryContract } from '@workspace/frontend/shared/domain/contracts';
import { AuthenticationAppServiceContract } from '@workspace/frontend/shared/application/contracts';
class AuthenticationAppService implements AuthenticationAppServiceContract {
  constructor(private authenticationRepository: AuthenticationRepositoryContract) {}

  public get() {
    return this.authenticationRepository.get();
  }

  public create(value: string): void {
    this.authenticationRepository.create(value);
  }

  public remove(): void {
    this.authenticationRepository.remove();
  }
}

export { AuthenticationAppService };
