import { assembleAnalyticsPageCustomEventV2 } from '../../../analytics-config/assemble-analytics-page-custom-event';
import { ANALYTICS_EVENT_CATEGORIES } from '../../../constants/categories';
import { ANALYTICS_EVENT_ACTIONS } from '../../../constants/actions';
import { ActionAnalyticsInterface } from '../../../analytics-config/analytics';
import { pages } from './pages-faq-home-techspec';

type E = 'P1E1' | 'P1E2' | 'P1E3' | 'P1E4';

const pageEvent: Record<E, string> = {
  P1E1: 'BTN:PrecisaDeAjuda:Pesquisar',
  P1E2: 'BTN:PrecisaDeAjuda:{0}',
  P1E3: 'BTN:DuvidasFrequentes:{0}:{1}',
  P1E4: 'BTN:DuvidasFrequentes:VerTodasAsDuvidas:{0}',
};

export type ActionsFaqHomeTechSpecI = Record<E, (labels?: unknown[]) => ActionAnalyticsInterface>;
export const ActionsFaqHomeTechSpec: ActionsFaqHomeTechSpecI = {
  P1E1: () =>
    assembleAnalyticsPageCustomEventV2({
      pageName: pages.P1,
      category: ANALYTICS_EVENT_CATEGORIES.CLICK,
      action: ANALYTICS_EVENT_ACTIONS.FAQ,
      label: pageEvent.P1E1,
    }),
  P1E2: (label: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E2,
      },
      label
    ),
  P1E3: (label: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E3,
      },
      label
    ),
  P1E4: (label: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E4,
      },
      label
    ),
};

export const ActionsFaqHomeLabelCreators = {
  P1E2: (label: string): string[] => {
    return [label];
  },
  P1E3: (labels: string[]): string[] => {
    return labels;
  },
  P1E4: (label: string): string[] => {
    return [label];
  },
};
