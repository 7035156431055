import { DialogTitleProps } from '@material-ui/core';
import { useThemeProvider } from '../../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../../theme-provider/theme-provider-injector';

export function DialogTitleWeb({ children, ...otherProps }: DialogTitleProps & { onClose?: () => void }) {
  const { Components } = useThemeProvider();
  const { DialogTitle } = Components;

  return (
    <ThemeProviderInjector>
      <DialogTitle {...otherProps}>{children}</DialogTitle>
    </ThemeProviderInjector>
  );
}
