import React from 'react';
import {
  CpfField,
  IncomeBracketField,
  IssueDateField,
  IssuingAgencyField,
  NameField,
  NationalityField,
  ProfessionField,
  RgField,
} from '@web/form/component';
import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import { MainDriverField } from '../../../../../components/autoFields/MainDriverField';
import { useFormControllerMethods } from '@web/form';

const HolderDataFormGridTemplate = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export function HolderDataForm({ incomeBracketOptions, nationalityOptions, professionOptions }) {
  const { watch } = useFormControllerMethods();
  const isMainDriver = watch('mainDriver');

  return (
    <HolderDataFormGridTemplate>
      <BoxWeb width={'100%'} marginBottom={'2rem'}>
        <MainDriverField />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <NameField
          componentProps={{
            helperText: 'Insira seu nome completo, conforme consta no documento CPF',
            id: 'txtName_screenAdditionalHolderData',
          }}
        />
      </BoxWeb>

      {!isMainDriver && (
        <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
          <CpfField
            componentProps={{ id: 'txtCpf_screenAdditionalHolderData' }}
            validation={stringValidationFactory({
              condition: {
                when: 'mainDriver',
                is: true,
                then: stringValidationFactory({}),
                otherwise: stringValidationFactory({ requiredMessage: 'você precisa informar o cpf' }),
              },
            })}
          />
        </BoxWeb>
      )}

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <RgField id="txtRg_screenAdditionalHolderData" />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <IssueDateField id="txtIssueDateField_screenAdditionalHolderData" />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <IssuingAgencyField id="txtIssuingAgency_screenAdditionalHolderData" />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <NationalityField id={'cbonationality_screenAdditionalHolderData'} options={nationalityOptions} />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <ProfessionField id={'cboProfessoin_screenAdditionalHolderData'} options={professionOptions} />
      </BoxWeb>

      <BoxWeb width={{ xs: '100%', lg: '48%' }} marginBottom={'2rem'}>
        <IncomeBracketField id={'cboincome_screenAdditionalHolderData'} options={incomeBracketOptions} />
      </BoxWeb>
    </HolderDataFormGridTemplate>
  );
}
