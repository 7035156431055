import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { NoProductsScreen } from '../evolution-screen/no-products-screen';
import {
  Card,
  CardButton,
  CardContent,
  CardDescriptive,
  CardImg,
  CardInfoCircle,
  CardInfoContainer,
  CardInfoText,
  CardInfoTitle,
  CardTitle,
  Divider,
  Title,
} from './product-card-styles';


interface ProductCardProps {
  img: string;
  title: string;
  descriptive?: string;
  status?: string;
  proposal?: string;
  value?: number;
}

const showingEvolutionScreen = () => {
  return (
    <BoxWeb display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} mt={'65px'}>
      <Divider />
      <NoProductsScreen />
    </BoxWeb>
  );
};

const showingPartnersScreen = () => {
  return (
    <>
      <BoxWeb mt={'50px'}>
        <Title>de produtos parceiros</Title>
      </BoxWeb>
      <Card>
        <BoxWeb display={'flex'} justifyContent={'center'} alignItems={'center'} ml={{ sm: '26px' }}>
          <CardImg
            src={'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/bemestar.png'}
          />
          <CardTitle>hospitaú</CardTitle>
        </BoxWeb>
        <CardContent>
          <CardInfoContainer>
            <CardInfoTitle>status</CardInfoTitle>
            <BoxWeb display={'flex'} justifyContent={'flex'} alignItems={'center'} flexDirection={'row'}>
              <CardInfoCircle />
              <CardInfoText>ativo</CardInfoText>
            </BoxWeb>
          </CardInfoContainer>
          <CardInfoContainer>
            <CardInfoTitle>proposta</CardInfoTitle>
            <CardInfoText>1234567</CardInfoText>
          </CardInfoContainer>
          <CardInfoContainer>
            <CardInfoTitle>valor</CardInfoTitle>
            <CardInfoText>R$ 120,00 / mês</CardInfoText>
          </CardInfoContainer>
        </CardContent>
        <CardButton onClick={() => console.log('Estou funcionando!')}>ver detalhes</CardButton>
      </Card>
    </>
  );
};

export function ProductCard({ img, title, descriptive }: ProductCardProps) {
  return (
    <>
      <Card>
        <BoxWeb display={'flex'} justifyContent={'center'} alignItems={'center'} ml={{ sm: '26px' }}>
          <CardImg
            src={img}
            aria-hidden={true}
          />
          <CardTitle>{ title }</CardTitle>
        </BoxWeb>
        <CardDescriptive>{descriptive || 'Acesse aqui os detalhes sobre o seu produto hospitaú'}</CardDescriptive>
        { /* @ts-ignore */ }
        <CardButton disabled={descriptive?.length > 0} aria-label={`ver detalhes ${title}`} onClick={() => window.location.assign("/v2/detalhes/produto/hospitau")}>ver detalhes</CardButton>
      </Card>
    </>
  );
}
