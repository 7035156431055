import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { useEffect } from 'react';

export const RecaptchaDOMInjector = () => {
  const { environment: env }: Partial<EnvironmentContextProps> = useEnvironment();
  useEffect(() => {
    injectRecaptchaToDOM(env?.recaptchaSiteKey);
    return removeRecaptchaDOM;
  }, []);
  return null;
};

const injectRecaptchaToDOM = (siteKey = '') => {
  if (navigator.userAgent === 'ReactSnap') return;
  const head = document.getElementsByTagName('head')[0];
  const scriptRecaptcha = document.createElement('script');
  scriptRecaptcha.type = 'text/javascript';
  scriptRecaptcha.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
  scriptRecaptcha.async = true;
  scriptRecaptcha.defer = true;

  head.appendChild(scriptRecaptcha);
};

const removeRecaptchaDOM = () => {
  const recaptchaSelector = 'script[src*="https://www.gstatic.com/recaptcha"]';
  const badgeSelector = '.grecaptcha-badge';
  const script = document.querySelector(recaptchaSelector);
  const badge = document.querySelector(badgeSelector);
  if (script) script.remove();
  if (badge) badge.remove();
};

declare global {
  interface Window {
    grecaptcha: {
      execute: (key: string, options: unknown) => Promise<string>;
    };
  }
}

export const getRecaptchaToken = async (action: string, siteKey: string) => {
  const token = await window['grecaptcha'].execute(siteKey, {
    action,
  });
  return {
    action,
    acao: action,
    token,
  };
};
