import { AlertAuthenticationProps } from '../../models/alert-authentication';
import { ItauIcon } from '../common/itau-icon';
import styled from 'styled-components';
import { useEffect } from 'react';

interface AlertStyleProps {
  show: boolean;
  severity: 'error' | 'warning';
}

const AlertWrapper = styled.div<AlertStyleProps>`
  position: absolute;
  width: 100%;
  padding: 24px 0;
  background-color: ${(props) =>
    props.severity === 'error' ? props.theme.palette.error.main : props.theme.palette.warning.main};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  color: #fff;

  .auth-alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: max(16px, 6%);
    padding-right: max(16px, 6%);

    p {
      flex: 1;
    }

    .auth-alert-close {
      background-color: transparent;
      cursor: pointer;
      border: none;
      color: #fff;
    }

    p,
    .auth-alert-close {
      margin-left: 16px;
    }
  }
`;

export function AlertAuthentication({
  icon = <ItauIcon iconName="exclamacao" ariaHidden={true} />,
  ...props
}: AlertAuthenticationProps) {
  const { children, show, severity, onClose, secondsToClose } = props;

  useEffect(() => {
    if (!show || !secondsToClose || !onClose) return;

    const closeInterval = setTimeout(onClose, secondsToClose * 1000);

    return () => clearInterval(closeInterval);
  }, [show]);

  return (
    show && (
      <AlertWrapper role="alert" severity={severity} show={show}>
        <div className="auth-alert-content">
          <ItauIcon iconName="exclamacao" ariaHidden={true} />
          <p>{children}</p>
        </div>
      </AlertWrapper>
    )
  );
}
