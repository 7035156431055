import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { InstallmentsQueryRequest } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryRequest';
import { InstallmentsQueryResponse } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryResponse';
import { IInstallmentsQueryRepository } from './type';

export class InstallmentsQueryRepository implements IInstallmentsQueryRepository {
  constructor(
    private readonly httpPostClient: IHttpPostClient<InstallmentsQueryRequest, InstallmentsQueryResponse[]>
  ) {}

  async execute(data: InstallmentsQueryRequest) {
    return await this.httpPostClient.post(data);
  }
}
