import { Checkbox, FormControlLabel, Grid, makeStyles, withStyles } from '@material-ui/core';
import { DefaultCheckboxProps } from '../../models/default-checkbox';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '15px',
  },
  helperText: {
    marginTop: '0.5rem',
    marginLeft: '1rem',
    color: '#f44336',
    fontSize: '13.714px',
  },
}));

const CheckBoxCustom = withStyles((theme) => ({
  checked: {
    color: '#F36D00 !important',
  },
}))(Checkbox);

const FormControlLabelCustom = withStyles(() => ({
  label: {
    fontSize: '15px',
  },
}))(FormControlLabel);

export const CheckBoxMui = (props: DefaultCheckboxProps) => {
  const styles = useStyles();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12} className={styles.label}>
        <FormControlLabelCustom
          control={<CheckBoxCustom onChange={props.onChange} />}
          label={props.label}
          id={props.id}
        />
      </Grid>
      <Grid item xs={12} className={styles.helperText}>
        <span>{props.helperText}</span>
      </Grid>
    </Grid>
  );
};
