import { rest } from 'msw';

export const simulation = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/simulacoes/assistencia-pet`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        id_simulacao: '7caf4fcf-6d87-4481-8c6e-9d7526076595',
        corretora: {
          taxa_corretagem: 0,
        },
        produtos: [
          {
            id_produto: '62',
            assistencias: [
              {
                id_assistencia: '34cd651d-e918-4df6-a9f8-038446b4df31',
                nome: 'Suporte veterinário',
                valor_assistencia: 14.9,
              },
              {
                id_assistencia: 'a8c4af69-d8c2-459e-a9c0-8b093504bfcc',
                nome: 'Consulta emergencial',
                valor_assistencia: 0.0,
              },
              {
                id_assistencia: 'f8ad6c96-1e83-41bc-8e05-9283392e7836',
                nome: 'Vacina e aplicação',
                valor_assistencia: 7.0,
              },
              {
                id_assistencia: 'e556bb1e-639f-49f8-9d9e-157176fa65ad',
                nome: 'Hospedagem Pet',
                valor_assistencia: 10.0,
              },
              {
                id_assistencia: '240cff51-78ef-4bad-8be5-44811dc8a165',
                nome: 'Castração Pet',
                valor_assistencia: 10.0,
              },
              {
                id_assistencia: '1c325b4c-ca1b-4201-92cd-a79f2687c7aa',
                nome: 'Funeral Pet',
                valor_assistencia: 5.0,
              },
            ],
          },
        ],
        opcoes_pagamento: [
          {
            id_opcao_pagamento: '1',
            recorrencia: true,
            metodo: 'credit_card',
            parcelas: 12,
            valor_parcela: 46.9,
            valor_total: 562.8,
            variante: 'padrao',
            iof: 0.0,
            iof_percentual: 0.0,
            juros: 0.0,
            juros_percentual: 0.0,
          },
        ],
        metodos_pagamento: [
          {
            metodo: 'credit_card',
            cartoes: [
              {
                tipo: 'Visa',
              },
              {
                tipo: 'MasterCard',
              },
              {
                tipo: 'Amex',
              },
              {
                tipo: 'Elo',
              },
              {
                tipo: 'Aura',
              },
              {
                tipo: 'Diners',
              },
            ],
          },
        ],
      })
    )
  );
};
