import {
  BoxWeb,
  NavigateButtonsWeb,
  OutlinedButtonWeb,
  SelectOption,
} from '@workspace/frontend/shared/presentation/components';
import { FormController, useFormControllerMethods } from '@web/form';
import { HolderDataForm } from './components/holder-data-form';
import { HolderAddress } from './components/holder-address';
import { AdditionalVehicleData } from './components/additional-vehicle-data';
import { BoxSteps } from '../../hiring-utils';

import React, { useState } from 'react';
import { LeadDialog } from '../../../../components/auto-quotation-lead-dialog/auto-quotation-lead-dialog';

function LeadDialogAdditionalFormConnectorWrapper({
  onClose,
  open,
  sendLeadUsecase,
  initialValues,
}: {
  open: boolean;
  onClose: () => void;
  sendLeadUsecase: any;
  initialValues: any;
}) {
  const { getValues } = useFormControllerMethods();
  const isMainDriver = getValues(`mainDriver`);
  const cpfValue = isMainDriver ? initialValues.driverCpf : getValues('cpf');
  const nameValue = getValues('name');
  const cellphoneValue = getValues('cellphone');

  return (
    <LeadDialog
      open={open}
      onClose={onClose}
      initialValues={{ cpf: cpfValue, name: nameValue, cellphone: cellphoneValue }}
      sendLeadUsecase={sendLeadUsecase}
    />
  );
}

export function AdditionalDataStep({
  handleSubmit,
  initialValues,
  handleBack,
  incomeBracketOptions,
  nationalityOptions,
  professionOptions,
  loading,
  carImagePath,
  sendLeadUsecase,
}: {
  handleSubmit: (props) => void;
  initialValues: any;
  handleBack: () => void;
  incomeBracketOptions: SelectOption[];
  nationalityOptions: SelectOption[];
  professionOptions: SelectOption[];
  loading: boolean;
  carImagePath: string;
  sendLeadUsecase?: any;
}) {
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const handleCloseLeadModal = () => setOpenLeadModal(false);
  const handleOpenLeadModal = () => setOpenLeadModal(true);
  return (
    <BoxSteps>
      <BoxWeb width={'100%'} display={'flex'} justifyContent={{ xs: 'center', md: 'start' }}>
        <BoxWeb
          component={'h1'}
          aria-label="dados adicionais do titular"
          id="lblTitle_screenAdditionalHolderData"
          textAlign={{ xs: 'center', md: 'left' }}
          width={{ xs: '280px', md: '100%' }}
        >
          <span aria-hidden>
            <strong>dados adicionais </strong>do titular
          </span>
        </BoxWeb>
      </BoxWeb>
      <FormController onSubmit={handleSubmit} initialValues={initialValues}>
        <LeadDialogAdditionalFormConnectorWrapper
          open={openLeadModal}
          onClose={handleCloseLeadModal}
          sendLeadUsecase={sendLeadUsecase}
          initialValues={initialValues}
        />

        <HolderDataForm
          incomeBracketOptions={incomeBracketOptions}
          nationalityOptions={nationalityOptions}
          professionOptions={professionOptions}
        />
        <BoxWeb height={48} />
        <HolderAddress addressCep={initialValues.cep} />
        <BoxWeb height={48} />
        <AdditionalVehicleData
          selectedVehicle={initialValues.brandAndModel}
          isZeroKm={initialValues.zeroKm}
          carImagePath={carImagePath}
        />
        <BoxWeb height={48} />
        <NavigateButtonsWeb onClickBack={handleBack} loading={loading} />
        <BoxWeb height="3rem" />
        <BoxWeb width="17rem">
          <span>se preferir, deixe seus dados e nós entraremos em contato com você</span>
          <BoxWeb height="0.5rem" />
          <OutlinedButtonWeb label="entrar em contato" onClick={handleOpenLeadModal} />
        </BoxWeb>
      </FormController>
    </BoxSteps>
  );
}
