import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_CATEGORIES, registerAnalyticsEvent } from '@workspace/web/analytics';
import { ANALYTICS_EVENT_RULES } from '@workspace/web/analytics';

const debug = false;
let RAE;

if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

const myProductsPageLoad = {
  L1: {
    rule: ANALYTICS_EVENT_RULES.PAGE_LOAD,
    page: {
      nome: 'LS:LG:NCC:Login:MinhaConta:MeusProdutos',
    },
  },
};

const myProductEvent = {
  E1: (productName, MFALogin) => {
    return {
      rule: ANALYTICS_EVENT_RULES.CUSTOM_LINK,
      page: {
        nome: 'LS:LG:NCC:Login:MinhaConta:MeusProdutos',
      },
      events: {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.LOGIN,
        label: `BTN:MinhaConta:MeusProdutos:Cancelar:${productName}:${MFALogin}`,
      },
    };
  },
};

const MyProductsAnalytics = {
  LOAD: () => RAE(myProductsPageLoad.L1),
  CANCEL: (productName, isLogin) => RAE(myProductEvent.E1(productName, isLogin ? 'login' : 'mfa')),
};

export const analyticsMyAccount = {
  MY_PRODUCTS: MyProductsAnalytics,
};
