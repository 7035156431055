import { IGetUserInfoRepository } from '../../../data/contracts/my-account/get-user-info-repository';
import { IHttpGetClient } from '../../../data/contracts/http/http-get-client';
import { GetUserInfoResponse } from '@core/domain/entities/my-account/response/get-user-info-response';
import { GetUserInfo } from '@core/infra/models/get-user-info';

export class GetUserInfoRepository implements IGetUserInfoRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<{ params: GetUserInfo }, GetUserInfoResponse>) {}

  async execute(userIdentifier: string, headers?: any): Promise<GetUserInfoResponse> {
    return await this.httpGetClient.get({ params: { userIdentifier } }, headers);
  }
}
