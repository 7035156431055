import { TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ReactMaskedInput from 'react-input-mask';
import { InputMaskWebProps } from '../../models/input-mask-web-props';

function MaskCustom({ maskType, ...others }: InputMaskWebProps) {
  return (
    <ReactMaskedInput
      {...others}
      maskChar=""
      alwaysShowMask={false}
      mask={maskType}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export const CustomMaskedInput = (props: InputMaskWebProps) => {
  const customError = () => props.renderCustomError && props.helperText && props.renderCustomError(props.helperText);

  return (
    <TextField
      {...props}
      label={props.label}
      name={props.name}
      helperText={customError() || props.helperText}
      id={props.id}
      inputProps={{
        'data-testid': props.dataTestID,
        'aria-label': props.label,
        pattern: props?.inputKeyboardType?.pattern,
        inputmode: props?.inputKeyboardType?.inputmode,
        maskType: props.maskType,
      }}
      InputProps={{
        inputComponent: MaskCustom,
      }}
    />
  );
};

export const InputMaskMui = styled(CustomMaskedInput)(() => ({
  width: '100%',
  root: {
    width: '100%',
  },
  '& .MuiInputBase-root': {
    height: '48px',
  },
  '& .MuiInputLabel-root': {
    top: '-0.3rem',
    fontSize: '16px',
  },
}));
