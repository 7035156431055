import { createContext, useContext } from 'react';
import { AuthStateManager } from '@web/state-management';
import { removeAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { RouteEnum } from '../enums/route-enum';
import useOriginAndProductSession from "../hooks/useOriginAndProductSession";

const AccessButtonContext = createContext(null);

type DropdownOption = {
  id: number;
  label: string | JSX.Element;
  icon?: React.ReactNode;
  onClick?(): void;
  href?: string;
  labelProps: Pick<React.HTMLAttributes<HTMLButtonElement>, 'aria-label' | 'id'>;
};
export type AccessButtonProviderResponse = {
  logout(): void;
  options: Array<DropdownOption>;
  optionsWithoutProducts: Array<DropdownOption>;
  loading: boolean;
  hasProducts: boolean;
};

export function AccessButtonProvider({ children }) {
  const {removeOriginAndProductSession} = useOriginAndProductSession();
  const logout = (): void => {
    removeOriginAndProductSession();
    removeAuthentication();
    AuthStateManager.userNameDispatcher(undefined);
    AuthStateManager.userAuthenticatedDispatcher(false);

    setTimeout(() => window.location.assign(RouteEnum.LOGIN), 50);
  };

  const options: Array<DropdownOption> = [
    {
      id: 0,
      label: 'minha conta',
      icon: <i className="icon-itaufonts_outline_compras" aria-hidden={true} />,
      href: '/v2/minha-conta/dados',
      onClick: () => window.location.assign(RouteEnum.MY_ACCOUNT),
      labelProps: {
        'aria-label': 'Minha Conta',
      },
    },
    {
      id: 1,
      label: 'meus produtos',
      icon: <i className="icon-itaufonts_usuario_perfil" aria-hidden={true} />,
      href: '/v2/minha-conta/produtos',
      onClick: () => window.location.assign(RouteEnum.MY_PRODUCTS),
      labelProps: {
        'aria-label': 'Meus Produtos',
      },
    },
    {
      id: 2,
      label: 'sair',
      icon: <i className="icon-sair" aria-hidden={true} />,
      onClick: logout,
      href: '/',
      labelProps: {
        'aria-label': 'Sair',
      },
    }
  ];

  const optionsWithoutHospitau: Array<DropdownOption> = [
    {
      id: 0,
      label: 'minha conta',
      icon: <i className="icon-itaufonts_outline_compras" aria-hidden={true} />,
      href: '/v2/minha-conta/dados',
      onClick: () => window.location.assign(RouteEnum.MY_ACCOUNT),
      labelProps: {
        'aria-label': 'Minha Conta',
      },
    },
    {
      id: 2,
      label: 'sair',
      icon: <i className="icon-sair" aria-hidden={true} />,
      onClick: logout,
      href: '/',
      labelProps: {
        'aria-label': 'Sair',
      },
    },
  ];

  return (
    <AccessButtonContext.Provider
      value={{
        logout,
        options,
        loading: false,
      }}
    >
      {children}
    </AccessButtonContext.Provider>
  );
}

export function useAccessButton(): AccessButtonProviderResponse {
  return useContext(AccessButtonContext);
}
