import { rest } from 'msw';

export const faqOdonto = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/faq/Odonto`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          uid: 'blt6790eb0596ac2aea',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Tem reajuste?',
          resposta:
            '<strong>Sim</strong>, o reajuste \u00E9 feito <strong>todo ano assim que o contrato \u00E9 renovado</strong>. O <strong>\u00EDndice</strong> que reajuste o valor \u00E9 o <strong>IPCA acumulado nos \u00FAltimos 12 meses</strong>.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt347a32eaeedca036',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'O plano \u00E9 contratado por quanto tempo (vig\u00EAncia)?',
          resposta: 'O plano tem a dura\u00E7\u00E3o de <strong>12 meses</strong>.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'bltab79f827ad128da2',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'O que \u00E9 um plano Odontol\u00F3gico?',
          resposta:
            '\u00C9 um <strong>servi\u00E7o</strong> que voc\u00EA contrata <strong>para</strong> ter <strong>acesso a</strong> v\u00E1rios <strong>procedimentos</strong> que <strong>ajudam a cuidar da sua sa\u00FAde bucal e do seu sorriso</strong>.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt3817b9776202daa6',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Como fa\u00E7o para mudar o plano que contratei?',
          resposta:
            'Para qualquer <strong>mudan\u00E7a nos planos</strong> voc\u00EA precisa pedir <strong>pela central de atendimento da MetLife</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltc9f00b78b9fb1d7c',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Tem limite de idade para contrata\u00E7\u00E3o?',
          resposta:
            '<strong>N\u00E3o</strong> tem limite de idade para contrata\u00E7\u00E3o do plano odontol\u00F3gico, exceto para o plano Odonto MetLife Jovem, que \u00E9 at\u00E9 18 anos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltc03f8e4432fe558c',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Receberei algum material impresso depois de contratar o plano Odontol\u00F3gico?',
          resposta:
            '<strong>N\u00E3o</strong>, todos os <strong>informativos</strong> e orienta\u00E7\u00F5es ser\u00E3o <strong>encaminhados</strong> para voc\u00EA <strong>por e-mail e SMS</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt21624e24f74fc9cd',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'O que s\u00E3o "coberturas"? (gloss\u00E1rio)',
          resposta:
            '\u00C9 o conjunto de tratamentos que o benefici\u00E1rio tem direito, ou seja, as coberturas s\u00E3o as garantias oferecidas para quem contrata o Plano Odontol\u00F3gico quando ocorre um evento coberto, previsto nas Condi\u00E7\u00F5es Gerais.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt677d37adc909b5c6',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto Metlife Pro Mais?',
          resposta:
            'O Plano Odonto <strong>Metlife Pro Mais</strong> tem <strong>todas as coberturas do plano Odonto Metlife Essencial</strong> e <strong>mais</strong>: <ul type="square"><li>Consultas e Diagn\u00F3stico</li><li>Atendimento de Urg\u00EAncia</li><li>Restaura\u00E7\u00F5es</li><li>Cirurgias em Consult\u00F3rio</li><li>Odontopediatria (Tratamento de crian\u00E7a)</li><li>Periodontia (tratamento de Gengiva)</li><li>Endodontia (tratamento de Canal)</li><li>Preven\u00E7\u00E3o Odontol\u00F3gica</li><li>Periodontia (Limpeza e Raspagem)</li><li>Pr\u00F3teses Unit\u00E1rias (Coroas em Cer\u00F4mero em dentes da Frente e Coroas Met\u00E1licas em dentes posteriores)</li><li>Pr\u00F3tese Parcial remov\u00EDvel provis\u00F3ria e Conserto de pr\u00F3teses remov\u00EDveis</li><li>Faceta em cer\u00F4mero e cer\u00E2mica *</li><li>Placa de mordida *</li><li>Pr\u00F3tese em cer\u00E2mica (unit\u00E1ria e fixa) *</li><li>Pr\u00F3tese em resina (unit\u00E1ria e fixa) *</li><li>Pr\u00F3tese parcial remov\u00EDvel *</li><li>Pr\u00F3tese total (dentadura) *</li></ul><br> * A Cobertura destes ser\u00E1 exclusivamente atrav\u00E9s de reembolso em dentista de sua escolha. <br> <strong>Importante:</strong> Para estes procedimentos voc\u00EA n\u00E3o utilizar\u00E1 a rede credenciada <br><br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltaad0e56ba9337513',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto Metlife Orto?',
          resposta:
            'O Plano Odonto <strong>Metlife Orto</strong> tem <strong>todas as coberturas do plano Odonto Metlife Essencial</strong> e <strong>mais</strong>: <ul type="square"><li>Consultas e Diagn\u00F3stico</li><li>Atendimento de Urg\u00EAncia</li><li>Restaura\u00E7\u00F5es</li><li>Cirurgias em Consult\u00F3rio</li><li>Odontopediatria (Tratamento de crian\u00E7a)</li><li>Periodontia (tratamento de Gengiva)</li><li>Endodontia (tratamento de Canal)</li><li>Preven\u00E7\u00E3o Odontol\u00F3gica</li><li>Periodontia (Limpeza e Raspagem)</li><li>Pr\u00F3teses Unit\u00E1rias (Coroas em Cer\u00F4mero em dentes da Frente e Coroas Met\u00E1licas em dentes posteriores)</li><li>Pr\u00F3tese Parcial remov\u00EDvel provis\u00F3ria e Conserto de pr\u00F3teses remov\u00EDveis</li><li>Documenta\u00E7\u00E3o ortod\u00F4ntica/ortop\u00E9dica *</li><li>Aparelho ortod\u00F4ntico/ortop\u00E9dico *</li><li>Manuten\u00E7\u00E3o ortod\u00F4ntica/ortop\u00E9dica *</li><li>Tratamento ortod\u00F4ntico (fixo ou remov\u00EDvel) /Ortop\u00E9dico *</li></ul><br> * A Cobertura destes ser\u00E1 exclusivamente atrav\u00E9s de reembolso em dentista de sua escolha. <br> <strong>Importante:</strong> Para estes procedimentos voc\u00EA n\u00E3o utilizar\u00E1 a rede credenciada <br><br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6cc44f003a28b9aa',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Posso contratar o plano odontol\u00F3gico apenas para minha esposa e/ou filhos?',
          resposta:
            '<strong>Sim</strong>, voc\u00EA <strong>ser\u00E1</strong> apenas o <strong>respons\u00E1vel financeiro</strong> e n\u00E3o far\u00E1 parte como usu\u00E1rio do plano. <span style="color: red">\u00C9 necess\u00E1rio indicar um titular, e este dever\u00E1 ter v\u00EDnculo com seus eventuais dependentes</span>. ',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blta5d444ac589337b9',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Se eu sentir dor no dente, posso contratar o plano odontol\u00F3gico e usar logo depois?',
          resposta:
            'A <strong>urg\u00EAncia</strong> odontol\u00F3gica poder\u00E1 ser usada <strong>ap\u00F3s 24 horas do pagamento da primeira</strong> parcela do plano. Esta consulta ser\u00E1 para tratar a sua dor ou desconforto. J\u00E1 para a continuidade do tratamento, devem ser observados os prazos de car\u00EAncia de cada procedimento.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltbcf6645ec2d21aac',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'O que \u00E9 uma telerradiografia? O plano odontol\u00F3gico cobre?',
          resposta:
            'A Telerradiografia \u00E9 um exame adicional coberto em todos os planos: Trata-se de <strong>uma radiografia lateral do cr\u00E2nio, utilizada principalmente no diagn\u00F3stico</strong> inicial <strong>do tratamento ortod\u00F4ntico</strong> ou para <strong>acompanhamento</strong> de pessoas em tratamento ortod\u00F4ntico. Solicitado em conjunto com o exame de tra\u00E7ado cefalom\u00E9trico (medidas do cr\u00E2nio da face, para determinar o desvio do paciente e contribuir com o planejamento do tratamento ortod\u00F4ntico).',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blte389030ad3945f5f',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Terei reajuste no valor do meu plano por ficar mais velho?',
          resposta: '<strong>N\u00E3o</strong> ;)',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt543cd35a9bc96202',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto MetLife Jovem?',
          resposta:
            'As principais coberturas desse plano s\u00E3o: <ul type="square"><li>Atendimento de urg\u00EAncia</li><li>Preven\u00E7\u00E3o</li><li>Odontopediatria</li><li>Restaura\u00E7\u00F5es</li><li>Clareamento de dente desvitalizado</li><li>Conserto em pr\u00F3tese parcial remov\u00EDvel e/ou pr\u00F3tese total</li><li>Documenta\u00E7\u00E3o periodontal</li><li>Mantenedor de espa\u00E7o fixo e/ou remov\u00EDvel (aparelhos colocados se os dentes de leite ca\u00EDrem precocemente)</li><li>Pr\u00F3tese parcial remov\u00EDvel provis\u00F3ria</li><li>Radiografia da ATM</li><li>Radiografias RX (RX M\u00E3o e punho, teleperfil com tra\u00E7ado)</li><li>Cirurgia de Tracionamento com finalidade ortod\u00F4ntica</li></ul> <br> O Titular e seus dependentes devem ter at\u00E9 18 anos no momento da contrata\u00E7\u00E3o <br><br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt35973f658d136a58',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto MetLife DOC?',
          resposta:
            'O Plano Odonto <strong>Metlife DOC</strong> tem <strong>todas as coberturas do plano Odonto Metlife Essencial</strong> e <strong>mais</strong>: <ul type="square"><li>Consultas e diagn\u00F3stico</li><li>Atendimento de urg\u00EAncia</li><li>Restaura\u00E7\u00F5es</li><li>Cirurgia em Consult\u00F3rio</li><li>Cirurgias em Consult\u00F3rio</li><li>Odontopediatria (Tratamento de crian\u00E7a)</li><li>Periodontia (Tratamento de Gengiva)</li><li>Endodontia (Tratamento de Canal)</li><li>Preven\u00E7\u00E3o Odontol\u00F3gica</li><li>Periodontia (Limpeza e Raspagem)</li><li>Pr\u00F3teses Unit\u00E1rias (Coroas em Cer\u00F4mero em dentes da Frente e Coroas Met\u00E1licas em dentes posteriores)</li><li>Pr\u00F3teses Parcial remov\u00EDvel provis\u00F3ria e Conserto de pr\u00F3teses remov\u00EDveis</li><li>4 tipos de documenta\u00E7\u00E3o ortod\u00F4ntica</li><li>Documenta\u00E7\u00E3o ortop\u00E9dica</li><li>Tratamento de aparelho ortod\u00F4ntico a partir da documenta\u00E7\u00E3o</li></ul> <br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt1c78d1a9b5b126ab',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto Metlife Master?',
          resposta:
            'O Plano Odonto <strong>Metlife Master</strong> tem <strong>todas as coberturas do plano Odonto Metlife Essencial</strong> e <strong>mais</strong>: <ul type="square"><li>Consultas e Diagn\u00F3stico</li><li>Atendimento de Urg\u00EAncia</li><li>Restaura\u00E7\u00F5es</li><li>Cirurgias em Consult\u00F3rio</li><li>Odontopediatria (Tratamento de crian\u00E7a)</li><li>Periodontia (tratamento de Gengiva)</li><li>Endodontia (tratamento de Canal)</li><li>Preven\u00E7\u00E3o Odontol\u00F3gica</li><li>Periodontia (Limpeza e Raspagem)</li><li>Pr\u00F3teses Unit\u00E1rias (Coroas em Cer\u00F4mero em dentes da Frente e Coroas Met\u00E1licas em dentes posteriores)</li><li>Pr\u00F3tese Parcial remov\u00EDvel provis\u00F3ria e Conserto de pr\u00F3teses remov\u00EDveis</li><li>Pr\u00F3tese em cer\u00E2mica *</li><li>Pr\u00F3tese parcial e remov\u00EDvel *</li><li>Pr\u00F3tese total *</li><li>Tratamento ortod\u00F4ntico *</li></ul><br> * A Cobertura destes ser\u00E1 exclusivamente atrav\u00E9s de reembolso em dentista de sua escolha. <br> <strong>Importante:</strong> Para estes procedimentos voc\u00EA n\u00E3o utilizar\u00E1 a rede credenciada <br><br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt1c59fb8022e3331f',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Onde posso consultar todas as coberturas e informa\u00E7\u00F5es sobre Planos Odontol\u00F3gicos?',
          resposta:
            'Voc\u00EA pode ver todas essas informa\u00E7\u00F5es <strong>nas condi\u00E7\u00F5es gerais</strong> do produto, <strong>\u00E9 s\u00F3 acessar: www.parceriadental.com.br</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltf47701ce55556970',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'O plano odontol\u00F3gico cobre manuten\u00E7\u00E3o de aparelho?',
          resposta:
            '<strong>O tratamento ortod\u00F4ntico</strong> (tratamento com aparelhos) est\u00E1 presente <strong>nos planos  Odonto Metlife Orto e Odonto MetLife Master</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt55da9a962bb85744',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Os planos t\u00EAm cobertura para limpeza?',
          resposta: '<strong>Sim</strong>, todos os planos cobrem limpeza.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltbb5e55f9cf051764',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Os planos cobrem extra\u00E7\u00E3o do siso?',
          resposta:
            '<strong>Sim</strong>. Quando voc\u00EA for buscar um dentista na rede credenciada, <strong>procure por um que tenha a especialidade "cirurgia"</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6006566d3b13f243',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Os planos Odonto MetLife Essencial e Odonto MetLife DOC cobrem pr\u00F3tese?',
          resposta:
            'Os planos <strong>Odonto MetLife Essencial e Odonto MetLife DOC cobrem pr\u00F3tese parcial remov\u00EDvel provis\u00F3ria e conserto de dentaduras e pontes m\u00F3veis</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt1a9c94d1dfb957ac',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta:
            'Os planos Odonto MetLife Jovem,  Odonto MetLife Essencial, Odonto MetLife Doc,  Odonto MetLife Pro, Odonto MetLife Pro Mais, Odonto MetLife Orto e Master cobrem clareamento?',
          resposta:
            '<strong>Todos os planos</strong> Odonto MetLife <strong>cobrem apenas clareamento de dentes desvitalizados</strong> (clareamento de dente da frente com canal tratado). Al\u00E9m disso, <strong>o plano Odonto MetLife Master e o Odonto MetLife Pro Mais cobre a placa de clareamento caseira</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blta795529f74d4e99c',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Preciso fazer uma pr\u00F3tese dent\u00E1ria, o plano odontol\u00F3gico cobre??',
          resposta:
            'Sim, todos os nossos planos cobrem: conserto de ponte m\u00F3vel e pr\u00F3tese total (dentadura) realizada no consult\u00F3rio ou laborat\u00F3rio de pr\u00F3tese Mas dependendo da sua necessidade, temos os planos espec\u00EDficos para pr\u00F3tese, como plano Odonto MetLife Pro e Pro Mais.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd1dbd74415eac576',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Tem cobertura para tratamento de perda \u00F3ssea?',
          resposta:
            'Todos os planos t\u00EAm coberturas para tratamento periodontal (incluindo o tratamento de perda \u00F3ssea). Depois do diagn\u00F3stico o dentista pode indicar o melhor tratamento dentro das coberturas do plano.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6cbc508cb2e5409d',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'O plano MetLife Essencial ou DOC cobre dentadura?',
          resposta:
            '<strong>N\u00E3o, apenas</strong> os Planos Odonto MetLife Pro, Plano Odonto MetLife Pro Mais e  Master cobrem dentatura. ',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltcf2e56a4c0575081',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Os planos odontol\u00F3gicos cobrem cirurgia ortogn\u00E1tica?',
          resposta:
            '<strong>N\u00E3o</strong>. A <strong>cirurgia ortogn\u00E1tica \u00E9</strong> uma <strong>cobertura de</strong> um <strong>plano m\u00E9dico</strong>, o <strong>odontol\u00F3gico n\u00E3o tem esse servi\u00E7o</strong>.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt89425027946a320d',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Tem cobertura para enxerto \u00F3sseo?',
          resposta: '<strong>N\u00E3o</strong>, infelizmente nossos planos n\u00E3o t\u00EAm essa cobertura :(',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt660888078e76b1d3',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'Tem cobertura para tratamentos com toxina botul\u00EDnica para diminuir dores?',
          resposta:
            '<strong>N\u00E3o, os planos n\u00E3o cobrem</strong> tratamentos com toxina botul\u00EDnica (botox).',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt0014fc6e2f5b535f',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Preciso incluir dependentes. Quais os dados necess\u00E1rios?',
          resposta:
            'Para dependentes voc\u00EA precisa informar: <strong>nome, data de nascimento, sexo, CPF, nome da m\u00E3e e rela\u00E7\u00E3o de depend\u00EAncia</strong> com o titular (por exemplo, o c\u00F4njuge e filho).',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4f3b5db26a96026a',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Quem pode ser dependente do meu plano Odontol\u00F3gico?',
          resposta:
            '<ul type="square"><li><strong>C\u00F4njuge</strong>: companheiro com uni\u00E3o est\u00E1vel na forma da lei, sem eventual concorr\u00EAncia com c\u00F4njuge salvo por decis\u00E3o judicial</li><li><strong>Filhos e enteados at\u00E9 40 anos</strong></li><li><strong>Tutelados</strong></li><li><strong>Menores sob Guarda</strong></li><li><strong>Pai/M\u00E3e</strong> do titular</li><li><strong>Irm\u00E3os</strong> do titular</li><li><strong>Sogro/Sogra</strong></li><li><strong>Netos</strong></li><li>Av\u00F3s</li><li>Bisav\u00F3s</li><li>Bisnetos</li><li>Tios</li><li>Sobrinhos</li><li>Cunhados</li><li>Sogros</li><li>Primos</li></ul>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt011a89a4d7b5c98c',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Posso contratar um Plano Odonto MetLife Jovem para o meu filho (menor de 18 anos)?',
          resposta:
            '<strong>Sim, desde que seu filho entre como titular do plano e voc\u00EA como respons\u00E1vel financeiro</strong>. O Plano Odonto MetLife Jovem \u00E9 permitido a contrata\u00E7\u00E3o de titular de at\u00E9 18 anos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd1508c836545aed6',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Posso incluir meu sobrinho com benefici\u00E1rio?',
          resposta:
            '<strong>Sim</strong>,  voc\u00EA pode inclu\u00ED-lo como seu dependente do plano odontol\u00F3gico.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt752e007c1db43249',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta:
            'J\u00E1 tenho um plano odontol\u00F3gico contratado com outra empresa e quero contratar o da MetLife. Preciso esperar o prazo de car\u00EAncia para usar os servi\u00E7os?',
          resposta:
            'Voc\u00EA poder\u00E1 solicitar a Portabilidade de plano se possuir plano h\u00E1 pelo menos 2 anos em outra empresa e estiver adimplente. <br> Nesta situa\u00E7\u00E3o, se o plano que voc\u00EA estiver adquirindo tiver a mesma cobertura, voc\u00EA n\u00E3o cumprir\u00E1 car\u00EAncia. Se tiver cobertura superior em rela\u00E7\u00E3o ao plano atual, cumprir\u00E1 car\u00EAncia apenas das novas coberturas. <br> Para isso, \u00E9 importante que ap\u00F3s a contrata\u00E7\u00E3o, voc\u00EA ligue para MetLife para pedir a isen\u00E7\u00E3o da car\u00EAncia.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt7a427ae96f9c2b4f',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta:
            'Consigo contratar planos diferentes para titulares e adicionais/dependentes (exemplo: Metlife Essencial para o titular e Metlife DOC para adicionais)?',
          resposta: '<strong>N\u00E3o, o plano deve ser o mesmo</strong> para titular, dependentes e adicionais.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt2f4fb78221438907',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Sou menor de idade, posso ter um plano odontol\u00F3gico?',
          resposta:
            '<strong>Sim</strong>, desde que voc\u00EA tenha um CPF. Mas precisar\u00E1 de um maior de idade como respons\u00E1vel financeiro.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltdd4ea854bfaea33d',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Posso incluir meus tios como dependentes?',
          resposta:
            '<strong>Sim</strong>,  voc\u00EA pode inclui-lo como seu dependente do plano odontol\u00F3gico, a lista completa est\u00E1 na quest\u00E3o 27, ou para mais informa\u00E7\u00F5es consulte as condi\u00E7\u00F5es gerias.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd056f8e0ae8fbb6b',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Onde posso consultar as informa\u00E7\u00F5es do meu plano?',
          resposta:
            'Voc\u00EA pode consultar <strong>pelo site</strong>: <a href="https://www.parceriadental.com.br">www.parceriadental.com.br</a>. L\u00E1 voc\u00EA encontra todas as condi\u00E7\u00F5es gerais e resumos dos planos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt754683e79d8eaf46',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Onde posso consultar as informa\u00E7\u00F5es da minha proposta ou contrato?',
          resposta: 'Voc\u00EA pode acessar as informa\u00E7\u00F5es do seu contrato no canal que voc\u00EA contratou.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt17d8c3b2128df61d',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'O que \u00E9 car\u00EAncia?',
          resposta:
            'A car\u00EAncia <strong>\u00E9 o tempo que voc\u00EA precisa aguardar para poder utilizar alguns procedimentos do  plano</strong>, ap\u00F3s a contrata\u00E7\u00E3o. Ela muda de acordo com a cobertura que voc\u00EA precisa.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltc86e609523866c07',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'O que s\u00E3o atendimentos de urg\u00EAncia e emerg\u00EAncia?',
          resposta:
            'S\u00E3o <strong>procedimentos para al\u00EDvio da dor</strong>. Voc\u00EA <strong>pode usar</strong> esse servi\u00E7o <strong>depois de 24 horas do pagamento</strong> da sua primeira mensalidade.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8cfbf83a656e1057',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Contratei meu plano, j\u00E1 posso usar as coberturas?',
          resposta:
            '<strong>Depende do procedimento</strong> que voc\u00EA vai usar, confira as car\u00EAncias: <ul type="square"><li>Para <strong>urg\u00EAncias e emerg\u00EAncias</strong> (procedimentos para al\u00EDvio da dor): <strong>24 horas ap\u00F3s o pagamento</strong> da mensalidade</li><li>Para <strong>pr\u00F3teses, documenta\u00E7\u00E3o ortod\u00F4ntica</strong> e <strong>tratamento ortod\u00F4ntico</strong> (quando h\u00E1 a cobertura): <strong>180 dias</strong> ap\u00F3s o pagamento da mensalidade.</li></ul>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd51ceda0d6dbd002',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Como consigo a carteirinha do plano?',
          resposta:
            '<strong>Depois de at\u00E9 72 horas</strong> da confirma\u00E7\u00E3o de pagamento, <strong>voc\u00EA pode acessar sua carteirinha pelos canais digitais</strong>: <ul type="square"> <li><strong>Aplicativo MetLife Brasil</strong>para IOS e Android:</li></ul> Depois de baixar o app, fa\u00E7a o cadastro, acesse sua carteirinha digital e todas as informa\u00E7\u00F5es do seu plano. <ul type="square"><li><strong>Portal do Benefici\u00E1rio MetLife</strong> (<a href="https://www.metlife.com.br">www.metlife.com.br</a>): Depois de criar se cadastrar e criar uma senha, voc\u00EA consegue visualizar o seu cart\u00E3o digital, ele estar\u00E1 dispon\u00EDvel depois de entrar com seu login e senha, em "produto Dental"</li></ul> <br> Importante:: para atendimento na rede credenciada da MetLife, n\u00E3o \u00E9 obrigat\u00F3rio a apresenta\u00E7\u00E3o da carteirinha. \u00C9 s\u00F3 informar o n\u00FAmero do CPF e apresentar um documento oficial com foto. <br> Se voc\u00EA tiver alguma <strong>urg\u00EAncia antes de ter a carteirinha</strong>, entre em contato com a MetLife: <strong>2\u00AA a 6\u00AA das 9h00 \u00E0s 18h00</strong>, hor\u00E1rio de Bras\u00EDlia. <br> - <strong>3003-3046</strong> (capitais e regi\u00F5es metropolitanas)  <br> - <strong>0800 728 3046</strong> (demais localidades) <br><br> <strong>Depois das 18h00</strong> e nos <strong>finais de semana</strong>: - <strong>SAC</strong> - Servi\u00E7o de Apoio ao Cliente 0800 746 3420.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd430d820b1daf039',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta:
            'O que fa\u00E7o em caso de urg\u00EAncia e emerg\u00EAncia antes de ter acesso a minha carteirinha digital?',
          resposta:
            'Para atendimento na rede credenciada da MetLife, n\u00E3o \u00E9 obrigat\u00F3rio apresentar carteirinha, \u00E9 s\u00F3 informar o n\u00FAmero do CPF e documento oficial com foto <br><br> Se voc\u00EA tiver alguma <strong>urg\u00EAncia antes de ter a carteirinha</strong>, entre em contato com a MetLife: <strong>2\u00AA a 6\u00AA das 9h00 \u00E0s 18h00</strong>, hor\u00E1rio de Bras\u00EDlia. <br> - <strong>3003-3046</strong> (capitais e regi\u00F5es metropolitanas)  <br> - <strong>0800 728 3046</strong> (demais localidades) <br><br> <strong>Depois das 18h00</strong> e nos <strong>finais de semana</strong>: - <strong>SAC</strong> - Servi\u00E7o de Apoio ao Cliente 0800 746 3420.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt80d0ea10dc1563eb',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'O que fa\u00E7o se n\u00E3o estiver com a carteirinha digital no momento da consulta?',
          resposta:
            'Se estiver em algum consult\u00F3rio ou cl\u00EDnica da rede credenciada, \u00E9 s\u00F3 apresentar documento oficial com foto e o n\u00FAmero do seu CPF',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6567d13f6c2819fe',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Quais os documentos necess\u00E1rios para usar o plano?',
          resposta:
            '<strong>A carteirinha digital ou n\u00FAmero de CPF</strong>, qualquer um dos dois <strong>acompanhados</strong> de <strong>documento</strong> oficial com foto..',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltd8091d3527c92960',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta:
            'Qual o telefone e hor\u00E1rio de funcionamento da central de atendimento e onde o produto \u00E9 atendido?',
          resposta:
            'MetLife: <br> <strong>2\u00AA a 6\u00AA das 9h00 \u00E0s 18h00</strong>, hor\u00E1rio de Bras\u00EDlia. <br> - <strong>3003-3046</strong> (capitais e regi\u00F5es metropolitanas) <br> - <strong>0800 728 3046</strong> (demais localidades) <br> <strong>Depois das 18h00</strong> e nos <strong>finais de semana</strong>: <br> - <strong>SAC</strong> - Servi\u00E7o de Apoio ao Cliente 0800 746 3420 <br><br> Ou <br><br> <strong>Ita\u00FA Corretora de Seguros:</strong> <br> <strong>- 4090 1125 </strong>(Capitais e regi\u00F5es metropolitanas) <br> - <strong>0800 791 1125</strong> (Demais localidades) <br> Informa\u00E7\u00F5es e altera\u00E7\u00F5es no seguro: 2\u00B0 a 6\u00B0 das 8h \u00E0s 20h',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt92476c3ae48af7a5',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'Se eu cancelar antes do fim da vig\u00EAncia (12 meses) ter\u00E1 cobran\u00E7a de multa?',
          resposta:
            '<strong>N\u00E3o!</strong> Os planos odonto MetLife (cobran\u00E7a mensal) vendidos pela Ita\u00FA Corretora de Seguros n\u00E3o tem cobran\u00E7a de multa ;)',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt936d7a4a1dc988f6',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Como fa\u00E7o para cancelar o meu plano Odontol\u00F3gico?',
          resposta:
            'Poxa, que pena :( De qualquer forma, voc\u00EA pode cancelar pelo atendimento em hor\u00E1rio comercial (09h00 \u00E0s 18h00 de segunda \u00E0 sexta-feira): <br> - <strong>3003-3046</strong> - Capitais e Grandes Centros <br> - <strong>0800 728 3046</strong> - Demais regi\u00F5es<br> <br><br> Ou, voc\u00EA pode acessar o canal de contrata\u00E7\u00E3o para o cancelamento.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blte7783be4dd64bc79',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'O plano pode ser cancelado de alguma forma sem ser por pedido meu?',
          resposta:
            '<strong>Sim</strong>, a MetLife poder\u00E1 cancelar em caso de inadimpl\u00EAncia <strong>(falta de pagamento) por mais de 60 dias ou fraude</strong> na utiliza\u00E7\u00E3o do plano.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt39792821e2304a51',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Onde posso consultar a tabela de reembolso??',
          resposta:
            '<strong>A tabela de reembolso est\u00E1 nas condi\u00E7\u00F5es gerais.</strong> <br> <strong>No site: </strong><a href="https://www.parceriadental.com.br">www.parceriadental.com.br</a> <br> Aqui voc\u00EA encontra todas as condi\u00E7\u00F5es gerais e resumos dos planos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt5bb90db540170f7b',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Onde posso consultar as condi\u00E7\u00F5es gerais e regras do plano Odontol\u00F3gico?',
          resposta:
            '<strong>No site: </strong> <a href="https://www.parceriadental.com.br">www.parceriadental.com.br</a> <br> Aqui voc\u00EA encontra todas as condi\u00E7\u00F5es gerais e resumos dos planos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt357b62c861d0bbcc',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Como posso pagar o plano Odontol\u00F3gico? E qual a periodicidade de pagamento?',
          resposta:
            'O pagamento pode ser feito por <strong>d\u00E9bito em conta corrente</strong> ou <strong>cart\u00E3o de cr\u00E9dito</strong>, e \u00E9 <strong>mensal!</strong>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt57ace72183208453',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'bltc959aa04fc94336b',
            titulo: 'uso',
          },
          pergunta: 'Depois dos 12 meses o que acontece com meu plano?',
          resposta: 'Depois de 12 meses o plano <strong>\u00E9 renovado automaticamente.</strong>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt0971d94e308593ad',
          produto: {
            uid: 'blt65f099a7405768a3',
            titulo: 'Odonto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'Quais s\u00E3o as principais coberturas do Plano Odonto Metlife Pro?',
          resposta:
            'O Plano Odonto <strong>Metlife Pro</strong> tem <strong>todas as coberturas do plano Odonto Metlife Essencial</strong> e <strong>mais</strong>: <ul type="square"><li>Consultas e Diagn\u00F3stico</li><li>Atendimento de Urg\u00EAncia</li><li>Restaura\u00E7\u00F5es</li><li>Cirurgias em Consult\u00F3rio</li><li>Odontopediatria (Tratamento de crian\u00E7a)</li><li>Periodontia (tratamento de Gengiva)</li><li>Endodontia (tratamento de Canal)</li><li>Preven\u00E7\u00E3o Odontol\u00F3gica</li><li>Periodontia (Limpeza e Raspagem)</li><li>Pr\u00F3teses Unit\u00E1rias (Coroas em Cer\u00F4mero em dentes da Frente e Coroas Met\u00E1licas em dentes posteriores)</li><li>Pr\u00F3tese Parcial remov\u00EDvel provis\u00F3ria e Conserto de pr\u00F3teses remov\u00EDveis</li><li>Pr\u00F3tese em resina (unit\u00E1ria e fixa) *</li><li>Pr\u00F3tese parcial remov\u00EDvel *</li><li>Pr\u00F3tese total (dentadura) *</li></ul><br> * A Cobertura destes ser\u00E1 exclusivamente atrav\u00E9s de reembolso em dentista de sua escolha. <br> <strong>Importante:</strong> Para estes procedimentos voc\u00EA n\u00E3o utilizar\u00E1 a rede credenciada <br><br> <a href="https://www.lojadesegurositau.com.br/odonto">Quer saber qual \u00E9 o melhor plano pra voc\u00EA?</a>',
          frequente: false,
          ativo: true,
        },
      ])
    )
  );
};
