import { HospitauProductSection } from './hospitau-product-section';
import React, { useEffect, useState } from 'react';
import { getUserProductsFactory } from '@workspace/frontend/my-account/infra/factories';
import { getAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { BoxWeb, LoadingWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { AuthStateManager } from '@web/state-management';
import { HospitauMessagesEnum } from '../../enums/hospitau-messages-enum';
import { NoProductsScreen, PageTitle, ProductsSection } from '@workspace/frontend/my-account/presentation/components';
import { PartnerProductSection } from './partner-products-section';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { analyticsMyAccount } from '@workspace/frontend/my-account/application/analytics';
import {EnvironmentContextProps, useEnvironment, useRemoteConfigConsumer} from '@workspace/providers';

export function MyProductsContainer() {
  const { Theme } = useThemeProvider();

  const [isClientHospitau, setIsClientHospitau] = useState(false);
  const [hasPartnerProduct, setHasPartnerProduct] = useState(false);
  const [partnerProducts, setPartnerProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [messageDescriptive, setMessageDescriptive] = useState('');
  const hasProducts = isClientHospitau;
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();
  const remoteConfig: any = useRemoteConfigConsumer();

  const authenticationData = getAuthentication();
  const userIdentifier = authenticationData?.identifier;

  const userProductsData = {
    accessToken: authenticationData?.accessToken,
    identifier: authenticationData?.identifier
  };
  const getUserProductsUseCase = getUserProductsFactory(environment, userProductsData);

  const closeAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const persistHospitauValuesToStorage = (integrationKey, identifier) => {
    AuthStateManager.contextHospitauDispatcher({
      identifier,
      integrationKey,
    });
  };

  // ????????
  const settingErrorMessage = (value) => {
    value?.products.map((product) => {
      const message = product?.error?.message;
      const enableCardMessageHospitau = () => {
        setIsClientHospitau(true);
        setMessageDescriptive(message);
      };

      if (
        message === HospitauMessagesEnum.COSTUMER_DO_NOT_HAVE_HOSPITAU ||
        message === HospitauMessagesEnum.MISSING_USER_DOCUMENT
      ) {
        setIsClientHospitau(false);
      } else {
        message === HospitauMessagesEnum.SYSTEMIC_ERROR ? setShowAlert(true) : enableCardMessageHospitau();
      }
    });
  };

  const analyticsCancelProductCall = (productName, fromLogin) => {
    analyticsMyAccount.MY_PRODUCTS.CANCEL(productName, fromLogin);
  };

  const retrieveContent = async () => {
    try {
      await remoteConfig.fetchAndActivate();
      const remoteConfigResponse = remoteConfig.getAll();

      const useCaseResponse = await getUserProductsUseCase.execute(
        userIdentifier,
        getAuthentication().accessToken,
        analyticsCancelProductCall,
        remoteConfigResponse
      );
      const hospitauProduct = useCaseResponse?.hospitau;
      const partnersProducts = useCaseResponse?.partners;

      if (hospitauProduct) {
        persistHospitauValuesToStorage(hospitauProduct?.integrationKey, hospitauProduct?.identifier);
        setIsClientHospitau(hospitauProduct.isValid);

        if (!hospitauProduct.isValid) {
         settingErrorMessage(useCaseResponse.hospitau);
        }
      }

      if (partnersProducts.length > 0) {
        setHasPartnerProduct(true);
        setPartnerProducts(partnersProducts);
      }
    } catch (err) {
      setShowAlert(true);
      closeAlert();
      setIsClientHospitau(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    analyticsMyAccount.MY_PRODUCTS.LOAD();
    (async () => {
      await retrieveContent();
    })();
  }, []);

  if (loading)
    return (
      <BoxWeb height={'100%'} minHeight={'400px'} display={'flex'} alignItems={'center'}>
        <LoadingWeb />
      </BoxWeb>
    );

  return (
    <SCThemeProvider theme={Theme}>
      <ProductsSection>
        <PageTitle
          title={'meus produtos'}
          subtitle={'Aqui você encontra as informações dos seus produtos contratados'}
        />

        {isClientHospitau && (
          <HospitauProductSection
            isClientHospitau={isClientHospitau}
            showAlert={showAlert}
            messageDescriptive={messageDescriptive}
          />
        )}

        {/* {hasPartnerProduct && <PartnerProductSection products={partnerProducts} />} */}

        {!hasProducts && <NoProductsScreen subtitle={'Você não possui produtos contratados'} text={' '} />}
      </ProductsSection>
    </SCThemeProvider>
  );
}
