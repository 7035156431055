import { WebComponentBuilder } from '@workspace/frontend/shared/presentation/webcomponent-builder';
import { Auth } from './containers/auth';
import { Register } from './containers/register';
import { RegisteredUser } from './containers/registered-user';
import { ConfirmAuthToken } from './containers/confirm-token';
import { PasswordRecovery } from './containers/recovery';

export enum WebcomponentModule {
  AUTH_LOGIN = 'auth-login',
  AUTH_REGISTER = 'auth-register',
  AUTH_REGISTERED_USER = 'auth-registered-user',
  AUTH_CONFIRM_TOKEN = 'auth-confirm-token',
  AUTH_PASSWORD_RECOVERY = 'auth-password-recovery',
}

export const createAuthv2WebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env)
    .create(WebcomponentModule.AUTH_LOGIN, Auth)
    .create(WebcomponentModule.AUTH_REGISTER, Register)
    .create(WebcomponentModule.AUTH_REGISTERED_USER, RegisteredUser)
    .create(WebcomponentModule.AUTH_CONFIRM_TOKEN, ConfirmAuthToken)
    .create(WebcomponentModule.AUTH_PASSWORD_RECOVERY, PasswordRecovery)
    .builder();
};
