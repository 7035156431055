import React, { ReactElement, useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { AnySchema, mixed } from 'yup';
import { FormControllerContext } from './form-controller';

type RequiredProps = {
  name: string;
  onChange: (e) => void;
  onBlur: (e) => void;
  value: unknown;
};

interface FormControllerFieldConnectorProps<CT = undefined> extends Record<string, any> {
  component: (props: RequiredProps & CT) => unknown;
  onBlur?: (e) => void;
  onChange?: (e) => void;
  name: string;
  validation?: any;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  translator?: Record<string, string>;
  componentProps?: Omit<CT, 'name' | 'value' | 'onChange' | 'onBlur'>;
}

/**
 *
 * @param Component ui component to render
 * @param onChange on change callback
 * @param onBlur on blur callback
 * @param name name used for the key to save the value of the field
 * @param validation field validation from web-validation lib
 * @param disabled
 * @param translator object used for property mapping ex: {checked: value} will passa property checked as value
 * @param helperText prop to add the error message
 * @param componentProps any props for the component that the connector doenst overrides
 * @param error activate error style for helpertext
 */
export function FormControllerFieldConnector<CT>({
  component: Component,
  onChange,
  onBlur,
  name,
  validation,
  disabled,
  translator,
  helperText,
  componentProps,
  error,
}: FormControllerFieldConnectorProps<CT>): ReactElement<RequiredProps> {
  const controller = useContext(FormControllerContext);

  const getEmptyValidationByType = (type: string): AnySchema => {
    return mixed();
  };

  useEffect(() => {
    if (controller) {
      if (disabled) {
        const emptyValidation = getEmptyValidationByType(validation.type);
        controller.addFieldValidation(name, emptyValidation);
      } else {
        controller.addFieldValidation(name, validation);
      }
    }

    return () => {
      if (controller) controller.removeField(name);
    };
  }, [disabled]);
  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        let translatedProps = {};

        if (translator) {
          translatedProps = Object.entries(translator).reduce((acc, cur) => {
            acc[cur[1]] = field[cur[0]];
            return acc;
          }, {});
        }

        const props = {
          ...componentProps,
          error: !!fieldState.error || error,
          helperText: fieldState?.error?.message || helperText,
          onChange: (e: Event): void => {
            if (typeof onChange === 'function') onChange(e);
            field.onChange(e);
          },
          onBlur: (e: Event): void => {
            if (typeof onBlur === 'function') onBlur(e);
            field.onBlur();
          },
          name: field.name,
          value: field.value,
          disabled,
          required: !!validation?.exclusiveTests.required && !disabled,
          ...translatedProps,
        };
        // @ts-ignore
        return <>{React.cloneElement<RequiredProps>(<Component />, props)}</>;
      }}
    />
  );
}
