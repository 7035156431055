import { Actor } from '@workspace/entities';
import produce from 'immer';
import { typeGenerator } from '../utils/typeGenerator';

export const ActorProponentTypes = {
  setCpf: 'SET [actor] [proponent] cpf',
  setName: typeGenerator(['actor', 'proponent'], 'SET', 'name'),
  setIsMainDriver: typeGenerator(['actor', 'proponent'], 'SET', 'isMainDriver'),
  setRg: typeGenerator(['actor', 'proponent'], 'SET', 'rg'),
  setIssueDate: typeGenerator(['actor', 'proponent'], 'SET', 'issueDate'),
  setIssuingAgency: typeGenerator(['actor', 'proponent'], 'SET', 'issuingAgency'),
  setNationality: typeGenerator(['actor', 'proponent'], 'SET', 'nationality'),
  setProfession: typeGenerator(['actor', 'proponent'], 'SET', 'profession'),
  setIncomeBracket: typeGenerator(['actor', 'proponent'], 'SET', 'incomeBracket'),
  setCep: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'cep'),
  setAddress: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'address'),
  setAddressNumber: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'addressNumber'),
  setAddressComplement: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'addressComplement'),
  setAddressNeighborhood: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'addressNeighborhood'),
  setCity: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'city'),
  setState: typeGenerator(['actor', 'proponent', 'address'], 'SET', 'state'),
};

const handlers = {
  [ActorProponentTypes.setCpf]: produce((draft, _action) => {
    const actor = new Actor({ cpf: _action.data });
    draft.actor.proponent.cpf = actor.cpf;
  }),
  [ActorProponentTypes.setName]: produce((draft, _action) => {
    draft.actor.proponent.name = _action.data;
  }),
  [ActorProponentTypes.setIsMainDriver]: produce((draft, _action) => {
    draft.actor.proponent.isMainDriver = _action.data;
  }),
  [ActorProponentTypes.setRg]: produce((draft, _action) => {
    draft.actor.proponent.rg = _action.data;
  }),
  [ActorProponentTypes.setIssueDate]: produce((draft, _action) => {
    draft.actor.proponent.issueDate = _action.data;
  }),
  [ActorProponentTypes.setIssuingAgency]: produce((draft, _action) => {
    draft.actor.proponent.issuingAgency = _action.data;
  }),
  [ActorProponentTypes.setNationality]: produce((draft, _action) => {
    draft.actor.proponent.nationality = _action.data;
  }),
  [ActorProponentTypes.setProfession]: produce((draft, _action) => {
    draft.actor.proponent.profession = _action.data;
  }),
  [ActorProponentTypes.setIncomeBracket]: produce((draft, _action) => {
    draft.actor.proponent.incomeBracket = _action.data;
  }),
  [ActorProponentTypes.setCep]: produce((draft, _action) => {
    draft.actor.proponent.address.cep = _action.data;
  }),
  [ActorProponentTypes.setAddress]: produce((draft, _action) => {
    draft.actor.proponent.address.address = _action.data;
  }),
  [ActorProponentTypes.setAddressNumber]: produce((draft, _action) => {
    draft.actor.proponent.address.addressNumber = _action.data;
  }),
  [ActorProponentTypes.setAddressComplement]: produce((draft, _action) => {
    draft.actor.proponent.address.addressComplement = _action.data;
  }),
  [ActorProponentTypes.setAddressNeighborhood]: produce((draft, _action) => {
    draft.actor.proponent.address.addressNeighborhood = _action.data;
  }),
  [ActorProponentTypes.setCity]: produce((draft, _action) => {
    draft.actor.proponent.address.city = _action.data;
  }),
  [ActorProponentTypes.setState]: produce((draft, _action) => {
    draft.actor.proponent.address.state = _action.data;
  }),
};

export default handlers;
