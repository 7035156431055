import {FinalizeCartAppServiceContract, FinalizeCartAppServiceContractInput} from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import { FinalizeCartRepositoryContract } from '@workspace/frontend/shared/abandoned-cart/domain/contracts';

export class FinalizeCartAppService implements FinalizeCartAppServiceContract {

    constructor(
        private readonly  finalizeCartRepository : FinalizeCartRepositoryContract
    ){}
    public async execute(input : FinalizeCartAppServiceContractInput): Promise<void> {
        await this.finalizeCartRepository.execute(input)
    }
}