import { FormControllerFieldConnector } from '@web/form';
import { InputMaskType, InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { dateValidation, pastDateTest } from '@workspace/frontend-shared-application-validation';

export const IssueDateField = ({
  disabled,
  id,
  label = 'data de emissão',
  type = 'tel',
}: {
  disabled?: boolean;
  id?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name="issueDate"
    disabled={disabled}
    validation={dateValidation({
      requiredMessage: 'você precisa informar a data de emissão',
      test: [pastDateTest()],
    })}
    componentProps={{
      label,
      id,
      maskType: InputMaskType.DATE,
      type,
    }}
  />
);
