import {
  stringValidationFactory,
  IStringValidationFactory,
  StringValidationSchema,
} from '../validation-generators/string-validation-factory';
import { cpfTest } from '../validation-tests/cpf-test';
import { Cpf } from '@workspace/entities';

export function cpfValidation(overrides: IStringValidationFactory = {}): StringValidationSchema {
  const defaultValues: IStringValidationFactory = {
    requiredMessage: 'você precisa informar o CPF',
    test: [],
    matches: {
      pattern: Cpf.REGEX,
      message: 'CPF inválido',
    },
  };

  const values: IStringValidationFactory = Object.assign(defaultValues, overrides);

  return stringValidationFactory({
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    test: [cpfTest()],
  });
}
