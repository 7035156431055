import { DialogTitle as MuiDialogTitle, IconButton, Typography, withStyles } from '@material-ui/core';
import { DialogTitleProps } from '@material-ui/core/DialogTitle/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useRef } from 'react';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[600],
  },
});
// @ts-ignore
export const DialogTitleMui = withStyles(styles)(
  ({
    children,
    classes,
    onClose,
    id,
    ...other
  }: DialogTitleProps & { onClose?: () => void; classes?: { closeButton: any } }) => {
    const ref = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (ref.current) ref.current.focus();
      }, 300);
    }, []);

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography
          variant="h2"
          style={{ lineHeight: 'normal', paddingRight: '2.5rem', fontSize: '1.5rem' }}
          id={id}
          ref={ref}
          tabIndex={-1}
        >
          {children}
        </Typography>
        {onClose ? (
          <IconButton aria-label="fechar" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);
