import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { ButtonProps } from '@material-ui/core';
import styled from 'styled-components';
import { CircleButton } from '../..';
interface CarouselLinkProps extends ButtonProps {
  selected?: boolean;
}

interface TextCarouselButtonProps {
  direction: 'lf' | 'rg';
}

export const ListItem = styled.li`
  padding: ${(props) => props.theme.spacing(2, 0)};
  flex-shrink: 0;
  border-radius: 18px;
  scroll-snap-align: start;
  font-size: 20px;
  font-family: 'ItauDisplayPro-XBold';
  font-weight: 700;
`;

export const TextCarouselBoxStyled = styled.ul`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  margin: 16px 8px;
  list-style-type: none;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  margin-inline: 2.5%;
  flex: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TextCarouselButtonStyled = styled(CircleButton)<TextCarouselButtonProps>`
  margin-inline: 19px;

  &:after {
    content: '';
    position: absolute;
    left: ${(props) => (props.direction === 'lf' ? 80 + 'px' : -80 + 'px')};
    height: 60px;
    width: 50px;
    background: linear-gradient(
      to ${(props) => (props.direction === 'lf' ? 'left' : 'right')},
      transparent -25%,
      ${(props) => props?.theme?.palette.primary.main}
    );
    z-index: 10;
  }
`;

export const TextCarouselWrapperStyled = styled(BoxWeb)`
  background: ${(props) => props?.theme?.palette.primary.main} 0% 0% no-repeat padding-box;
  height: 87px;
  border-radius: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 60px 0;
`;

export const CarouselLink = styled.button<CarouselLinkProps>`
  text-decoration: none;
  color: ${(props) => props?.theme?.palette.primary.contrastText};
  font-size: 20px;
  padding: 13px 15px;
  font-family: 'ItauDisplayPro-XBold';
  background: none;
  border: none;
  font-weight: 700;

  &:active {
    color: transparent;
  }
  &:hover {
    color: ${(props) => props?.theme?.palette.primary.main};
    background-color: ${(props) => props?.theme?.palette.primary.contrastText};
    border-radius: 18px;
    cursor: pointer;
  }
  ${({ selected, theme }) =>
    selected &&
    `
      color: ${theme.palette.secondary.contrastText};
      background-color: ${theme.palette.secondary.main};
      border-radius: 18px;

      &:hover {
        cursor: default;
      }
    `}
`;
