import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { domain } from '@core/test/mocks/autov2';
import { VehicleSearchByDescription } from '@core/infra/models/vehicle-search-by-desciption';
import { DomainRepository } from '@core/infra/repository/autov2/domain-repository';
import { DomainUsecase } from '@core/data/services/domain-usecase';
import { Domain } from '@core/domain/entities/autov2/response/domain';

class HttpPostClient implements IHttpPostClient<null, Domain> {
  post(params: VehicleSearchByDescription): Promise<Domain> {
    return Promise.resolve(domain);
  }
}
export function domainFactory(partialEnvironment) {
  // const httpGetClient: IHttpPostClient<null, Domain> = new AxiosHttpGetClient(
  //   '/dominio',
  //   undefined,
  //   partialEnvironment.gatewayBFFBaseURL.url
  // );

  const httpPostMock = new HttpPostClient()

  const domainRepository = new DomainRepository(httpPostMock);
  return new DomainUsecase(domainRepository);
}