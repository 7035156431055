import { IHttpGetClient } from '../../data/contracts/http/http-get-client';
import { AxiosBase } from './axios-base';

export class AxiosHttpGetClient<T, R> extends AxiosBase implements IHttpGetClient<T, R> {
  constructor(private readonly service: string, private readonly headers?: any, public readonly baseURL?: string) {
    super(baseURL);
  }

  async get(params: T, headers?: any): Promise<R> {
    return await this.axiosInstance.get(this.service, {
      ...params,
      paramsSerializer: (params) =>
        Object.entries(Object.assign({}, params))
          .map(([key, value]) => `${key}=${value}`)
          .join('&'),
      headers: headers || this.headers,
    });
  }

  async getWithUri(uri: string): Promise<R> {
    const response = await this.axiosInstance.get(uri);
    return response.data;
  }
}
