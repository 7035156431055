import styled from 'styled-components';
import { BoxWeb, HiddenWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from '@web/assets';
import React from 'react';
export interface wayToShareProps {
  name: string;
  icon: string;
  href: string;
  class?: string;
}
const SHARETEXT = 'Dê uma olhada nesse a post';

const waysToShare: wayToShareProps[] = [
  {
    class: 'lnkWhatsaspp_screnArtcle',
    name: 'Whatsapp',
    icon: WhatsappIcon,
    href: `https://api.whatsapp.com/send?text=${SHARETEXT}`,
  },
  {
    class: 'lnkFacebook_screnArtcle',
    name: 'Facebook',
    icon: FacebookIcon,
    href: 'https://www.facebook.com/sharer.php?u=',
  },
  {
    class: 'lnkTwitter_screnArtcle',
    name: 'Twitter',
    icon: TwitterIcon,
    href: 'https://twitter.com/intent/tweet?url=',
  },
];

const IconsWrapper = styled.div`
  a:first-child {
    margin-left: 16px;
  }
  a {
    margin: 8px;
    line-height: 1;
  }
  a:last-child {
    margin-right: 0;
  }
  display: flex;
`;

export const ShareContent = ({ url, title, onClick }) => {
  return (
    <BoxWeb display="flex" aria-label="compartilhe:" alignItems="center" style={{ textTransform: 'uppercase' }}>
      <HiddenWeb smDown>
        <div aria-hidden="true">
          <TypographyWeb component="caption" color="textSecondary">
            compartilhe
          </TypographyWeb>
        </div>
      </HiddenWeb>
      <IconsWrapper>
        {waysToShare.map((item) => (
          <BoxWeb>
            <a href={item.href + url} target="_blank" rel="noreferrer" aria-label={`compartilhar com ${item.name}`}>
              <img
                src={item.icon}
                alt={item.name}
                aria-hidden="true"
                className={item.class}
                onClick={() => onClick(title, item.name)}
              />
            </a>
          </BoxWeb>
        ))}
      </IconsWrapper>
    </BoxWeb>
  );
};
