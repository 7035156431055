import { LocalStorageBaseRepository } from './local-storage-base-repository';
import { IdentifierRepositoryContract } from '@workspace/frontend/shared/domain/contracts';

class IdentifierRepository extends LocalStorageBaseRepository implements IdentifierRepositoryContract {
  constructor() {
    super('identifier');
  }
}

export { IdentifierRepository };
