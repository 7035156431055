import styled from 'styled-components';

export const Link = styled.a`
  width: 119px;
  height: 24px;
  left: 352px;
  top: 75px;

  font-family: Itau Text;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: underline;

  color: #1a9ace;
`;
const CepModelFieldBox = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    width: 326px;
    margin-right: 2rem;
  }
`;
