import {
  CreateCartAppServiceContractInput,
  CreateCartAutoAppServiceContractInput,
} from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import { ContextsEnum } from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { CreateCartPayload } from '@workspace/utils/abandoned-cart';
import { CreateCartStrategySerializer } from './create-cart-strategy.serializer';

export class CreateCartAutoStrategySerializer extends CreateCartStrategySerializer {
  public specializedExecute(input: CreateCartAppServiceContractInput): CreateCartPayload {
    if (input.context !== ContextsEnum.AUTO) return;

    const data = input as CreateCartAutoAppServiceContractInput;

    return {
      context: data.context,
      customer: {
        fullName: data.fullName,
        phone: data.cellphone,
        cpf: data.cpf.replace(/\D+/g, ''),
        simulationNumber: data.simulationNumber,
        dac: data.dac,
        segment: data.segment,
      },
      product: {
        title: 'seguro automóvel',
        coverages: [
          {
            title: 'não informado',
            description: 'não informado',
          },
        ],
      },
    };
  }
}
