import { rest } from 'msw';

export const usersMock = (env) => {
  return rest.get(`${env.gatewayBFFBaseURL.url}/usuarios`, (req, res, ctx) => {
    const email = req.url.searchParams.get('email');
    switch (email) {
      case 'error-validacao@teste.com':
        return res(
          ctx.status(200),
          ctx.json({
            success: true,
            errors: [],
            value: {
              id: 123,
              idSituacao: 'ATIVO',
            },
          })
        );

      case 'error-integracao@teste.com':
        return res(
          ctx.status(417),
          ctx.json({
            success: false,
            errors: [
              {
                code: 'systemicError',
                message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
              },
            ],
          })
        );
      default:
        return res(
          ctx.status(409),
          ctx.json({
            success: false,
            errors: [
              {
                code: 'emailIsRequired',
                message: 'E-mail requerido',
              },
            ],
          })
        );
    }
  });
};
