import { createContext, useContext } from 'react';
import { EnvironmentProviderProps, useEnvironment } from '../environment/environment-provider';
import { initHomFirebase, initLocalFirebase, initProdFirebase } from '@workspace/firebase';

const RemoteConfigContext = createContext({});

export const RemoteConfigProvider = ({ children }: any) => {
  const useEnvironmentRes: Partial<EnvironmentProviderProps> = useEnvironment();
  const environment = useEnvironmentRes?.environment?.environment;
  let remoteConfig = {};
  switch (environment) {
    case 'prod':
      {
        const firebaseConfig = initProdFirebase();
        remoteConfig = firebaseConfig.remoteConfig;
      }
      break;
    case 'dev':
      {
        const firebaseConfig = initLocalFirebase();
        remoteConfig = firebaseConfig.remoteConfig;
      }
      break;
    case 'e2e':
      {
        const firebaseConfig = initHomFirebase();
        remoteConfig = firebaseConfig.remoteConfig;
      }
      break;
    case 'hom':
      {
        const firebaseConfig = initHomFirebase();
        remoteConfig = firebaseConfig.remoteConfig;
      }
      break;
    default: {
      const firebaseConfig = initLocalFirebase();
      remoteConfig = firebaseConfig.remoteConfig;
    }
  }

  return <RemoteConfigContext.Provider value={remoteConfig}>{children}</RemoteConfigContext.Provider>;
};

export const useRemoteConfigConsumer = () => useContext(RemoteConfigContext);
