import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 72px;
`;
const SuccessBar = styled.div`
  height: 100%;
  width: 100%;
  background-color: green;
  color: #fff;
  display: flex;
  align-items: center;
  > div {
    width: 1360px;
    margin: 0 auto;
  }
`;
const ErrorBar = styled.div`
  height: 100%;
  background-color: #781b8e;
  color: #fff;
  display: flex;
  align-items: center;
  > div {
    width: 1360px;
    margin: 0 auto;
  }
`;

const FeedbackMessage = ({ status }: any) => {
  return (

    <Container>
      {console.log(status)}
      {status === 'success' ? (
        <SuccessBar>
          <div> alteração realizada com sucesso</div>
        </SuccessBar>
      ) : (
        <ErrorBar>
          <div> erro inesperado </div>
        </ErrorBar>
      )}
    </Container>
  );
};

export default FeedbackMessage;
