import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { AutoV2SimulationRepository } from '@core/infra/repository/autov2/autov2-simulation';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { SimulationRequest } from '@core/domain/entities/autov2/simulation-request';
import { SimulationResponse } from '@core/domain/entities/autov2/simulation-response';
import { FrontendEnvironment } from '@workspace/environments';
import { SimulationRequestDTO } from '../adapter/simulation-request';
import { SimulationResponseDTO } from '../adapter/simulation-response';

export function simulationFactory(environment: FrontendEnvironment) {
  const httpPostClient: IHttpPostClient<SimulationRequest, { data: SimulationResponse }> = new AxiosHttpPostClient(
    'automovel/simulacao',
    {},
    environment.gatewayBFFBaseURL.url,
    null
  );

  const fazerSimulacaoRepository = new AutoV2SimulationRepository(httpPostClient);
  return new SimulationUsecase(fazerSimulacaoRepository);
}

export interface ISimulationRepository<T, R> {
  execute: (simulacao: T) => Promise<R>;
}

export class SimulationUsecase {
  constructor(private readonly fazerSimulacaoRepository) {}

  async execute(simulacao: {
    cpf: string;
    fabricationYear: number;
    modelYear: number;
    brandAndModel: string;
    zeroKm?: boolean;
    plate: string;
    susep: string;
    overnightCep: string;
    previousBonus: boolean;
    bonusClass?: number;
    insuranceCompany?: any;
    itemsBrandAndModel: { value: string; key: number | string }[];
  }): Promise<any> {
    const simulationRequest = SimulationRequestDTO.translate(simulacao);
    const simulationResponse = await this.fazerSimulacaoRepository.execute(simulationRequest);
    return SimulationResponseDTO.translate(simulationResponse.data);
  }
}
