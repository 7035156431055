import React, { useState } from 'react';
import { BoxWeb, HiddenWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import { ShareContent } from '..';
import { FormController, FormControllerErrorBanner } from '@web/form';
import { SatisfactionLevelField } from '@web/form/component';
import { FeedBackIcons } from '@web/assets';
import styled from 'styled-components';
import { CommentContainer } from '../commentContainer/commentContainer';
import { ANALYTICS_BLOG } from '@workspace/frontend-shared-application-analytics';
import { phraseToUpperCamelCase } from '../../utils';

const SATISFACTION_OPTIONS = [
  {
    id: 'rdoRuim_screenArticle',
    value: '1',
    label: <label aria-hidden={true}>ruim</label>,
    ariaLabel: 'ruim',
    icon: <FeedBackIcons type="bad" />,
    checkedIcon: <FeedBackIcons type="bad" checked />,
  },
  {
    id: 'rdoNaoGostei_screenArticle2',
    value: '2',
    label: <label aria-hidden={true}>não gostei</label>,
    ariaLabel: 'não gostei',
    icon: <FeedBackIcons type="didntLike" />,
    checkedIcon: <FeedBackIcons type="didntLike" checked />,
  },
  {
    id: 'rdoSeiLa_screenArticle',
    value: '3',
    label: <label aria-hidden={true}>sei lá</label>,
    ariaLabel: 'sei lá',
    icon: <FeedBackIcons type="beatsMe" />,
    checkedIcon: <FeedBackIcons type="beatsMe" checked />,
  },
  {
    id: 'rdoGostei_screenArticle',
    value: '4',
    label: <label aria-hidden={true}>gostei</label>,
    ariaLabel: 'gostei',
    icon: <FeedBackIcons type="liked" />,
    checkedIcon: <FeedBackIcons type="liked" checked />,
  },
  {
    id: 'rdoAdorei_screenArticle',
    value: '5',
    label: <label aria-hidden={true}>adorei</label>,
    ariaLabel: 'adorei',
    icon: <FeedBackIcons type="loved" />,
    checkedIcon: <FeedBackIcons type="loved" checked />,
  },
];

const COMMENT_LABEL = {
  '1': {
    textAreaLabel: 'poxa, você pode/gostaria nos contar o motivo?',
    successLabel: 'obrigado por avaliar!',
  },
  '2': {
    textAreaLabel: 'poxa, você pode/gostaria nos contar o motivo?',
    successLabel: 'obrigado por avaliar!',
  },
  '3': {
    textAreaLabel: 'obrigado por avaliar!',
    successLabel: 'obrigado por avaliar!',
  },
  '4': {
    textAreaLabel: 'obrigado por avaliar! ficamos felizes em saber que você gostou do artigo.',
    successLabel: 'obrigado por avaliar! ficamos felizes em saber que você gostou do artigo.',
  },
  '5': {
    textAreaLabel: 'obrigado por avaliar! ficamos felizes em saber que você gostou do artigo.',
    successLabel: 'obrigado por avaliar! ficamos felizes em saber que você gostou do artigo.',
  },
};

const FeedbackSection = styled.div`
  #satisfactionLevel {
    font-family: 'ItauText-Regular';
    font-size: 22px;
    font-weight: 400;
    line-height: 28.31px;
  }

  #successLabel {
    color: ${(props) => props.theme.palette.success.main};
    font-size: 22px;
    line-height: 28px;
    margin: 24px 0;
  }
`;

const SatisfactionLevelContainer = styled.div`
  .MuiFormControlLabel-root:first-child {
    margin-left: 0;
  }
  .MuiFormControlLabel-root {
    margin: 0 8px;
  }
  .MuiFormControlLabel-root:last-child {
    margin-right: 0;
  }
  .MuiTypography-root {
    font-size: 13px;
  }
  .MuiButtonBase-root {
    padding: 0;
  }
`;

export const FeedbackContainer = ({ onSubmit, url, title }) => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = (data) => {
    onSubmit(data);
    const satisfactionLabel = SATISFACTION_OPTIONS.find((el) => el.value === data?.satisfactionLevel).label;

    ANALYTICS_BLOG.POST.SATISFACTION([phraseToUpperCamelCase(title), phraseToUpperCamelCase(' ' + satisfactionLabel)]);
    setSuccess(true);
  };

  const analyticsEventShare = (title, midia) => {
    ANALYTICS_BLOG.POST.SHARE_CONTENT_2([phraseToUpperCamelCase(title), phraseToUpperCamelCase(midia)]);
  };
  return (
    <BoxWeb my={4}>
      <FormController onSubmit={handleSubmit} hideErrors id="feedback">
        {({ watch }) => {
          const satisfactionLevel = watch('satisfactionLevel');
          const commentLabel = COMMENT_LABEL[satisfactionLevel];

          return (
            <>
              <BoxWeb>
                <FormControllerErrorBanner />
              </BoxWeb>
              <FeedbackSection>
                <TypographyWeb component="span" id="satisfactionLevel">
                  o que achou desse artigo? avalie:
                </TypographyWeb>
                <BoxWeb display="flex" flexDirection="row" justifyContent="space-between" my={2}>
                  <SatisfactionLevelContainer>
                    <SatisfactionLevelField options={SATISFACTION_OPTIONS} disabled={success} row />
                  </SatisfactionLevelContainer>
                  <HiddenWeb smDown>
                    <ShareContent title={title} url={url} onClick={analyticsEventShare} />
                  </HiddenWeb>
                </BoxWeb>
                {success ? (
                  <TypographyWeb>{commentLabel?.successLabel}</TypographyWeb>
                ) : (
                  satisfactionLevel && <CommentContainer commentLabel={commentLabel?.textAreaLabel} />
                )}
              </FeedbackSection>
            </>
          );
        }}
      </FormController>
    </BoxWeb>
  );
};
