import { IdentifierAppServiceContract } from '@workspace/frontend/shared/application/contracts';
import { IdentifierAppService } from '@workspace/frontend/shared/application/services';
import { IdentifierRepositoryFactory } from './identifier-repository-factory';

abstract class IdentifierAppServiceFactory {
  private static identifierAppService: IdentifierAppServiceContract;

  public static get instance(): IdentifierAppServiceContract {
    if (!this.identifierAppService) {
      this.identifierAppService = new IdentifierAppService(IdentifierRepositoryFactory.instance);
    }

    return this.identifierAppService;
  }
}

export { IdentifierAppServiceFactory };
