import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { cancellationReasonsMock } from './cancellation-reasons-mock';
import { cancellationMock } from './cancellation-mock';
import { cancellationAuthMock } from './cancellation-auth-mock';
import { cancellationHiringMock } from './cancellation-hiring-mock';
import { cancellationHiringDetailMock } from './cancellation-hiring-detail-mock';

export const cancellationMockHandlers = (env): RestHandler[] => {
  return [
    cancellationAuthMock(env),
    cancellationMock(env),
    cancellationReasonsMock(env),
    cancellationHiringMock(env),
    cancellationHiringDetailMock(env),
  ];
};
