import { IStringValidationFactory, stringValidationFactory } from '../validation-generators/string-validation-factory';

export const passwordValidation = (overrides: IStringValidationFactory = {}) => {
  const defaultValues = {
    requiredMessage: 'você precisa informar a senha',
    matches: {
      pattern:
        /^(?!.*(.)\1{2,})(?=.*[a-z])(?!.*(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz))(?!.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ))(?!.*(012|123|234|345|456|567|678|789))(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&\+\-\:;=?@\[\]^_`\{|\}~])[A-Za-z\d!#$%&\+\-\:;=?@\[\]^_`\{|\}~]{8,}$/g,
      message: 'a senha precisa corresponder às regras',
    },
  };

  const values: IStringValidationFactory = Object.assign(defaultValues, overrides);

  return stringValidationFactory(values);
};
