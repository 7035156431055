import { DefaultSwitchProps } from '../../models/default-switch';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import React from 'react';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 45,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
  },
  disabled: {
    '& + $track': {
      backgroundColor: `${theme.palette.divider} !important`,
      opacity: 1,
      border: 'none',
    },
  },
  switchBase: {
    margin: '-8px',
    '&$checked': {
      transform: 'translateX(19px)',
      '&.Mui-focusVisible': {
        boxShadow: `0 0 0 1.0px black`,
      },
      color: theme.palette.primary.contrastText,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    width: 40,
    height: 24,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
}))(Switch);

export const SwitchMui = ({ checked, disabled, id, labelledByID, name, onChange, label }: DefaultSwitchProps) => (
  <FormControlLabel
    control={
      <IOSSwitch
        checked={checked}
        disabled={disabled}
        aria-labelledby={labelledByID}
        onChange={onChange}
        name={name}
        id={id}
        inputProps={{ role: 'switch' }}
      />
    }
    label={label}
  />
);
