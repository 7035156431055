import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { InstallmentsQueryResponse } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryResponse';
import { InstallmentsQueryRequest } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryRequest';
import { InstallmentsQueryRepository } from '@workspace/frontend/auto/infra/repositories';
import { InstallmentsQueryUseCase } from '@workspace/frontend/auto/domain/use-cases';

class HttpPostClientMock implements IHttpPostClient<InstallmentsQueryRequest, InstallmentsQueryResponse[]> {
  post(params: InstallmentsQueryRequest): Promise<InstallmentsQueryResponse[]> {
    // CREDITO_PORTO = 97,
    // CREDITO = 62,
    // DEBITO_CONTA = 52
    return Promise.resolve([
      {
        codigoFormaPagamento: 97,
        tipoClasseCalculoPremio: 2,
        descricaoFormaPagamento: 'string',
        ordenacao: 3,
        parcelamentos: [
          {
            numero: 2,
            valor: '100',
            descricao: '1 vez sem juros',
            juros: 2,
            encargos: 4,
          },
          {
            numero: 3,
            valor: 'string',
            descricao: '2 vezes sem juros',
            juros: 1,
            encargos: 3,
          },
        ],
      },
      {
        codigoFormaPagamento: 62,
        tipoClasseCalculoPremio: 2,
        descricaoFormaPagamento: 'string',
        ordenacao: 3,
        parcelamentos: [
          {
            numero: 2,
            valor: 'string',
            descricao: '3 vezes sem juros',
            juros: 2,
            encargos: 4,
          },
          {
            numero: 3,
            valor: 'string',
            descricao: '4 vezes sem juros',
            juros: 1,
            encargos: 3,
          },
        ],
      },
      {
        codigoFormaPagamento: 52,
        tipoClasseCalculoPremio: 2,
        descricaoFormaPagamento: 'string',
        ordenacao: 3,
        parcelamentos: [
          {
            numero: 2,
            valor: 'string',
            descricao: '5 vezes sem juros',
            juros: 2,
            encargos: 4,
          },
          {
            numero: 3,
            valor: 'string',
            descricao: '6 vezes sem juros',
            juros: 1,
            encargos: 3,
          },
        ],
      },
    ]);
  }
}

export function installmentsQueryFactory({ partialEnvironment }) {
  const { environment } = partialEnvironment;

  // const httpPostClient: IHttpPostClient<null, InstallmentsQueryResponse> = new AxiosHttpPostClient(
  //   `${environment.installmentsQuery.path}`,
  //   {},
  //   environment.gatewayBFFBaseURL.url
  // );

  const httpPostClient = new HttpPostClientMock();
  const installmentsQueryRepository = new InstallmentsQueryRepository(httpPostClient);
  return new InstallmentsQueryUseCase(installmentsQueryRepository);
}
