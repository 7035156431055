import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { ISendRecoveryPasswordRepository } from '@core/data/contracts/auth/send-recovery-password-repository';
import { RecoveryPassword } from '@core/domain/entities/auth/recovery-password';
import { SendRecoveryPasswordResponse } from '@core/domain/entities/auth/response/send-recovery-password-response';

export class SendRecoveryPasswordRepository implements ISendRecoveryPasswordRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<RecoveryPassword, SendRecoveryPasswordResponse>) {}

  async execute(data: RecoveryPassword, headers?: any) {
    return await this.httpPostClient.post(data, null, headers);
  }
}
