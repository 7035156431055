import React from 'react';
import { NavigateButtonsProps } from '../../models/navigate-buttons';
import { BoxMui } from './box-mui';
import { ButtonMui } from './button-mui';
import { OutlinedButtonMui } from './outlined-button-mui';

export function NavigateButtonsMui({
  hideBackButton,
  hideContinueButton,
  idBackButton = 'btnBack_screen',
  idContinueButton = 'btnContinue_screen',
  backText = 'voltar',
  continueText = 'continuar',
  onClickContinue,
  onClickBack,
  loading,
  disabled,
  widthBackButton = 136,
  widthContinueButton = 136,
  justifyContent = null,
}: NavigateButtonsProps) {
  return (
    <BoxMui display="flex" flexDirection="row" justifyContent={justifyContent}>
      {!hideBackButton && (
        <BoxMui width={widthBackButton} mr={2} fontFamily="ItauDisplay-Regular" tabIndex={0}>
          <OutlinedButtonMui label={backText} onClick={onClickBack} id={idBackButton} tabIndex={-1}/>
        </BoxMui>
      )}
      {!hideContinueButton && (
        <BoxMui width={widthContinueButton} fontFamily="ItauDisplay-Regular" tabIndex={0}>
          <ButtonMui
            tabIndex={-1}
            loading={loading}
            disabled={loading || disabled}
            type="submit"
            label={continueText}
            onClick={onClickContinue}
            id={idContinueButton}
          />
        </BoxMui>
      )}
    </BoxMui>
  );
}
