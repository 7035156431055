import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';

interface IAxiosPostRepository<Body, Response, Header> {
  execute(body?: Body, header?: Header): Promise<Response>;
}

export class AxiosPostRepository<Body, Response, Header = undefined>
  implements IAxiosPostRepository<Body, Response, Header>
{
  constructor(private readonly httpPostClient: IHttpPostClient<Body, Response, Header>) {}

  async execute(body?: Body, header?: Header): Promise<Response> {
    return await this.httpPostClient.post(body, undefined, header);
  }
}
