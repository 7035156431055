import React, { useState } from 'react';
import { AlertWeb, BoxWeb, ButtonWeb, HiddenWeb } from '@workspace/frontend/shared/presentation/components';
import { Aside, ContainerButton, ContainerInput, Title } from './styles';
import { FormController, FormControllerErrorBanner } from '@web/form';
import { CellphoneField, CpfField, EmailField, NameField } from '@web/form/component';

import { sendLeadFactory } from '@workspace/frontend/auto/presentation/web-app';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

type FormFieldProps = {
  cellphone: string;
  cpf: string;
  cta_email: string;
  cta_name: string;
};

export function Cta({ visible }) {
  const [success, setSuccess] = useState('');

  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const { environment } = partialEnvironment;

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: environment.channel,
  });

  const nameRegex = /^[^!@#$%^&*(),.?":{}|<>0-9][a-zA-Z\u00C0-\u00FF]{2,}( [a-zA-Z\u00C0-\u00FF]{2,})+$/;

  const inputFormat = (input: string) => {
    return input.replace(/\.|-|[(]|[)]|[ ]/g, '');
  };

  const HandleSubmit = async ({ cellphone, cpf, cta_email, cta_name }: FormFieldProps) => {
    try {
      await sendLeadUsecase.execute({
        cellphone: inputFormat(cellphone),
        cpf: inputFormat(cpf),
        email: cta_email,
        name: inputFormat(cta_name),
      });
      visible(false);
      setSuccess('Sucesso');
    } catch {
      setSuccess('Error');
    }
  };

  return (
    <Aside>
      <Title>Quer falar com um consultor? Deixa seus dados aqui que ligamos para te ajudar!</Title>
      <BoxWeb display="flex" flexDirection="column">
        <FormController onSubmit={HandleSubmit} hideErrors>
          {() => (
            <>
              {success == '' ? (
                <>
                  <BoxWeb>
                    <FormControllerErrorBanner />
                  </BoxWeb>
                  <ContainerInput mb="36px">
                    <NameField
                      prefix="cta"
                      validationOverrides={{
                        requiredMessage: 'você precisa informar seu nome',
                        matches: {
                          pattern: nameRegex,
                          message: 'verifique seu nome.',
                        },
                      }}
                      label={'como podemos te chamar?'}
                      componentProps={{
                        id: 'txtCtaName_blog',
                        variant: 'standard',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput mb="36px">
                    <CpfField
                      componentProps={{
                        label: 'qual o seu CPF?',
                        variant: 'standard',
                        id: 'numbCtaCpf_blog',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput mb="36px">
                    <EmailField
                      prefix="cta"
                      componentProps={{
                        label: 'pode me passar o e-mail para enviar a cotação depois?',
                        id: 'txtCtaEmail_blog',
                        variant: 'standard',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput mb={{ xs: '24px', md: '36px' }}>
                    <CellphoneField
                      componentProps={{
                        label: 'em qual número podemos te ligar?',
                        id: 'numbCtaCelular_blog',
                        variant: 'standard',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  </ContainerInput>
                  <ContainerButton
                    display="flex"
                    flexDirection={{
                      xs: 'column',
                      md: 'row',
                    }}
                    justifyContent="space-between"
                  >
                    <HiddenWeb mdUp>
                      <p style={{ marginBottom: '24px' }}>
                        Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e
                        contratação dos produtos escolhidos e poderão ser tratados de acordo com nossa Política de
                        Privacidade disponível em nossos sites e aplicativos
                      </p>
                    </HiddenWeb>
                    <ButtonWeb loading={false} label="Continuar" disabled={false} type="submit" />
                  </ContainerButton>
                </>
              ) : success == 'Sucesso' ? (
                <AlertWeb severity={'success'}>Dados enviados</AlertWeb>
              ) : (
                <AlertWeb severity={'error'}> Houve um problema, tente novamente mais tarde. </AlertWeb>
              )}
            </>
          )}
        </FormController>
      </BoxWeb>
    </Aside>
  );
}
