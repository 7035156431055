import { PostHttpClientContract } from './http/contracts/post-http-client.contract';
import { FinalizeCartRepositoryContract, FinalizeCartRepositoryContractInput } from '@workspace/frontend/shared/abandoned-cart/domain/contracts';

export class FinalizeCartRepository implements FinalizeCartRepositoryContract{
    constructor(
        private readonly postHttpClient: PostHttpClientContract
    ){}

    public async execute(input: FinalizeCartRepositoryContractInput ): Promise<void> {
        input.cpf = input.cpf.replace(/\D+/g, '');
        
        await this.postHttpClient.execute({
            payload: input,
            uri: 'abandonedcart/finalizecart'
        });
    }
}