import { PageLoadAnalyticsInterface } from '../../../analytics-config/analytics';

import { assembleAnalyticsPageLoadEvent } from '../../../analytics-config/assemble-analytics-page-load-event';
import { ANALYTICS_EVENT_BUSINESS } from '../../../constants/business';

type T = 'T1';
const pages: Record<T, string> = {
  T1: 'LS:NL:NCC:Blog:Postagem:{0}',
};
export type PageLoadBlogPostTechSpecI = Record<T, (labelValue?: string[]) => PageLoadAnalyticsInterface>;
export const PageLoadBlogPostTechSpec: PageLoadBlogPostTechSpecI = {
  T1: (labelValue: string[]) =>
    assembleAnalyticsPageLoadEvent(
      {
        nome: pages.T1,
        screen: ANALYTICS_EVENT_BUSINESS.NO_ACCOUNT,
      },
      labelValue
    ),
};
