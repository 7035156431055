import { BoxWeb, ItauIcon } from '@workspace/frontend/shared/presentation/components';
import { IconButton } from '@material-ui/core';
import { Product, Question, Topic } from '@workspace/frontend/faq-v2/domain/entities';
import { useState } from 'react';
import { Answers } from './feedback-buttons-style';
import { ANALYTICS_FAQ } from '@workspace/web/analytics';

type ButtonProps = {
  product: Product;
  topic: Topic;
  refLikeButton;
  refDislikeButton;
  question: Question;
};

export const FeedbackButtons = ({ product, topic, refLikeButton, refDislikeButton, question }: ButtonProps) => {
  const [likertButtonActive, setLikertButtonActive] = useState<string>('');

  const activeButton = (questionUid, variantLikeDislike) => {
    if (product && topic) {
      const analyticsFaq = ANALYTICS_FAQ.QUESTIONS.PAGE;
      analyticsFaq.LIKE_DISLIKE_ANSWER([product?.slug], [topic?.slug, variantLikeDislike]);
    }

    setLikertButtonActive(formatQuestionUid(questionUid, variantLikeDislike));
  };

  const formatQuestionUid = (questionUid: string, variant: string) => questionUid + variant;

  return (
    <>
      <BoxWeb display={'flex'} flexDirection={'column'} marginTop={'12px'}>
        <IconButton
          style={{ padding: '8px' }}
          ref={refLikeButton}
          onClick={() => activeButton(question.uid, 'like')}
          id={formatQuestionUid(question.uid, 'like')}
          key={formatQuestionUid(question.uid, 'like')}
          aria-pressed={likertButtonActive === formatQuestionUid(question.uid, 'like')}
          aria-label={'curtir'}
        >
          <ItauIcon
            iconName={likertButtonActive === formatQuestionUid(question.uid, 'like') ? 'full_curtir' : 'curtir'}
            style={{ color: '#2B374A', width: '24px', height: '24px' }}
            ariaLabel={'curtir'}
          />
        </IconButton>
        <Answers>SIM</Answers>
      </BoxWeb>

      <BoxWeb display={'flex'} flexDirection={'column'} marginTop={'12px'}>
        <IconButton
          style={{ padding: '8px' }}
          ref={refDislikeButton}
          onClick={() => activeButton(question.uid, 'dislike')}
          id={formatQuestionUid(question.uid, 'dislike')}
          key={formatQuestionUid(question.uid, 'dislike')}
          aria-pressed={likertButtonActive === formatQuestionUid(question.uid, 'dislike')}
          aria-label={'descurtir'}
        >
          <ItauIcon
            iconName={
              likertButtonActive === formatQuestionUid(question.uid, 'dislike') ? 'full_descurtir' : 'descurtir'
            }
            style={{ color: '#2B374A', width: '24px', height: '24px' }}
            ariaLabel={'descurtir'}
          />
        </IconButton>
        <Answers>NÃO</Answers>
      </BoxWeb>
    </>
  );
};
