import BreadCrumbs from '@material-ui/core/Breadcrumbs';
import { LinkMui } from './link-mui';
import { TypographyMui } from './typography-mui';
import { BreadCrumbsProps } from '../../models/breadcrumbs';
import React from 'react';

export const BreadCrumbsMui = ({ pages, separator, linkTarget, style, onClick }: BreadCrumbsProps) => {
  return (
    <BreadCrumbs separator={separator}>
      {pages.map((page, index) => {
        const lastElement = pages.length - 1;
        const id = `brdCrmb-element-${index}`;
        if (index !== lastElement) {
          return (
            <LinkMui
              variant="inherit"
              component="a"
              text={page.name}
              key={index}
              id={id}
              href={page.href}
              color={page.color}
              target={linkTarget}
              underline="always"
              style={style}
              onClick={onClick}
            />
          );
        }

        return (
          <TypographyMui color={page.color} component="span" id={id} key={index} style={style}>
            {page.name}
          </TypographyMui>
        );
      })}
    </BreadCrumbs>
  );
};
