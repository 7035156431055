import { IStringValidationFactory, stringValidationFactory } from '../validation-generators/string-validation-factory';

export function phoneValidation(overrides: IStringValidationFactory = {}) {
  const defaultValues: IStringValidationFactory = {
    transform: (value) => value.replace(/[\s()-]/g, ''),
    length: {
      limit: 10,
      message: 'o número de telefone informado deve ter 10 caracteres',
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return stringValidationFactory({
    length: values.length,
    transform: values.transform,
  });
}
