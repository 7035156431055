import { DialogContentProps } from '@material-ui/core';
import { useThemeProvider } from '../../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../../theme-provider/theme-provider-injector';

export function DialogContentWeb({ children, ...otherProps }: DialogContentProps) {
  const { Components } = useThemeProvider();
  const { DialogContent } = Components;

  return (
    <ThemeProviderInjector>
      <DialogContent {...otherProps}>{children}</DialogContent>
    </ThemeProviderInjector>
  );
}
