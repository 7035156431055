import palette from '../loja-de-seguros/palette';

export default {
  MuiLink: {
    root: {
      color: '#106EB0',
      textDecoration: 'underline',

      '&.MuiLink-underlineHover': {
        textDecoration: 'underline',
      },
    },
  },
  MuiFormControl: {
    root: {
      '& label + .MuiInput-formControl': {
        marginTop: '24px',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.text.primary,

      '&$error': {
        color: '#781B8E',
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: '#000',

      '&$focused': {
        color: '#000000',
      },

      '&$error': {
        color: '#781B8E',
        fontWeight: '700',
      },
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '18px',
    },
  },
  MuiInput: {
    root: {
      '&$focused': {
        outline: '2px solid #106EB0',
      },
    },
    input: {
      height: 'auto',
      lineHeight: '22px',
      padding: '14px',
    },
    underline: {
      '&:after': {
        display: 'none',
      },

      '&:not(.Mui-disabled):before': {
        borderBottom: '2px solid #ACACAC',
      },

      '&:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid #ACACAC',
      },

      '&$error:not(.Mui-disabled):before': {
        borderColor: '#781B8E',
      },
    },
  },
  MuiButton: {
    text: {
      fontWeight: '700',
    },
    outlined: {
      fontWeight: '700',
    },
  },
  MuiCollapse: {
    container: {
      boxSizing: 'border-box',
    },
  },
};
