import React, { useEffect, useRef, useState } from 'react';
import {
  CarouselLink,
  TextCarouselButtonStyled,
  TextCarouselBoxStyled,
  TextCarouselWrapperStyled,
  ListItem,
} from './TextCarousel-styled';
import { SetaDireita, SetaEsquerda } from '@web/assets';
import { useTheme } from '@material-ui/core';
const AFTER_ITEM_WIDTH = 60;

export interface TextCarouselProps {
  onClickItem: (uid: string) => void;
  selectedItem?: string;
  listTitle?: string;
  defaultValue: { value: string; title: string };
  items: { uid: string; title: string }[];
}
export const TextCarousel = ({ onClickItem, selectedItem, listTitle, defaultValue, items }: TextCarouselProps) => {
  const [scrollForButtonLeft, setScrollForButtonLeft] = useState(true);
  const [scrollForButtonRight, setScrollForButtonRight] = useState(false);

  const itemsRef = useRef([]);
  const listRef = useRef(null);

  const theme = useTheme();

  const itemIsSelected = (id) => selectedItem === id;
  const getItemPosition = (itemIndex) => itemsRef?.current[itemIndex]?.getBoundingClientRect();

  const overflowRight = () =>
    listRef?.current?.getBoundingClientRect().right >= getItemPosition(items?.length)?.right - AFTER_ITEM_WIDTH;
  const overflowLeft = () => listRef?.current?.getBoundingClientRect()?.x <= getItemPosition(0)?.left;

  const updateArrowsState = () => {
    setScrollForButtonRight(overflowRight());
    setScrollForButtonLeft(overflowLeft());
  };

  const onClickRightButton = () => {
    listRef.current.scrollLeft += listRef?.current?.clientWidth;
  };

  const onClickLeftButton = () => {
    listRef.current.scrollLeft -= listRef?.current?.clientWidth;
  };

  window.addEventListener('resize', updateArrowsState);

  useEffect(() => {
    if (!!listRef?.current && !!itemsRef?.current && items?.length > 0) {
      updateArrowsState();
    }
  }, [listRef, itemsRef, items]);

  const onClick = (uid) => {
    if (!itemIsSelected(uid)) onClickItem(uid);
  };

  return (
    <TextCarouselWrapperStyled data-testid="carousel">
      <TextCarouselButtonStyled
        direction={'lf'}
        onClick={onClickLeftButton}
        disabled={scrollForButtonLeft}
        aria-hidden="true"
        data-testid="arrow-left"
      >
        <SetaEsquerda
          fill={scrollForButtonLeft ? theme.palette.text.secondary : theme.palette.primary.main}
          stroke="none"
        />
      </TextCarouselButtonStyled>
      <TextCarouselBoxStyled ref={listRef} onScroll={updateArrowsState} title={listTitle}>
        <ListItem ref={(el) => (itemsRef.current[0] = el)}>
          <CarouselLink
            onClick={() => onClick(defaultValue.value)}
            selected={selectedItem === defaultValue.value || !selectedItem}
            aria-pressed={selectedItem === defaultValue.value || !selectedItem}
          >
            {defaultValue.title}
          </CarouselLink>
        </ListItem>
        {items?.map((item, index) => (
          <ListItem ref={(el) => (itemsRef.current[index + 1] = el)} key={index}>
            <CarouselLink
              selected={itemIsSelected(item.uid)}
              onClick={() => onClick(item.uid)}
              aria-pressed={itemIsSelected(item.uid)}
            >
              {item.title}
            </CarouselLink>
          </ListItem>
        ))}
      </TextCarouselBoxStyled>

      <TextCarouselButtonStyled
        direction={'rg'}
        onClick={onClickRightButton}
        disabled={scrollForButtonRight}
        aria-hidden="true"
        data-testid="arrow-right"
      >
        <SetaDireita
          fill={scrollForButtonRight ? theme.palette.text.secondary : theme.palette.primary.main}
          stroke="none"
        />
      </TextCarouselButtonStyled>
    </TextCarouselWrapperStyled>
  );
};
