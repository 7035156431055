import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import {
  SelectWithModalOnMobile,
  SelectWithModalOnMobileProps,
} from '@workspace/frontend/shared/presentation/composed-components';

export const IncomeBracketField = ({
  disabled,
  id,
  label = 'faixa de renda',
  options,
}: { disabled?: boolean; id?: string; label?: string; options: any } & Partial<SelectWithModalOnMobileProps>) => (
  <FormControllerFieldConnector<SelectWithModalOnMobileProps>
    component={SelectWithModalOnMobile}
    name="incomeBracket"
    disabled={disabled}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar a faixa de renda' })}
    componentProps={{
      items: options,
      label,
      id,
    }}
  />
);
