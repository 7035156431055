import {
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  assembleAnalyticsPageLoadEvent,
  PageLoadAnalyticsInterface,
} from '@workspace/frontend-shared-application-analytics';

type P = 'P1' | 'P2' | 'P3' | 'P4' | 'P5';
export const pages: Record<P, string> = {
  P1: 'LS:NL:NCC:Login:AcesseSuaConta',
  P2: 'LS:NL:NCC:Login:AlterarSenha:Email',
  P3: 'LS:NL:NCC:Login:AlterarSenha:EmailEnviado',
  P4: 'LS:NL:NCC:Login:AlterarSenha:NovaSenha',
  P5: 'LS:NL:NCC:Login:AlterarSenha:Sucesso',
};
export type PageLoadAuthLoginTechSpecI = (options: {
  origin?: string;
  product?: string;
}) => Record<P, PageLoadAnalyticsInterface>;

export const PageLoadAuthLoginTechSpec: PageLoadAuthLoginTechSpecI = (options) => ({
  P1: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P1,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P2: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P2,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P3: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P3,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P4: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P4,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P5: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P5,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
});
