export default {
  primary: {
    main: '#F36D00',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
  },
  secondary: {
    main: '#5397C6',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
    dark: '#004990',
  },
  warning: {
    main: '#781B8E',
  },
  text: {
    primary: '#2b374a',
  },
  link: {
    primary: '#106EB0',
  },
  success: {
    main: '#007A47',
  },
  background: {
    main: '#efe9e5',
    lightMain: '#FFDAB8',
  },
  error: {
    main: '#B71C1C',
  },
};
