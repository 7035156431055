import { AppWebComponent } from './export';
import { FrontendEnvironment } from '@workspace/environments';
import { EnvironmentProvider } from '@workspace/providers';
import React from 'react';

type WebcomponentModule = { name: string; component: React.ElementType };

export class WebComponentBuilder {
  private webcomponentNames: Array<WebcomponentModule>;
  private readonly environment: FrontendEnvironment;

  private constructor(environment: FrontendEnvironment) {
    this.webcomponentNames = [];
    this.environment = environment;
  }

  static setEnvironment(environment: FrontendEnvironment) {
    return new WebComponentBuilder(environment);
  }

  create(webcomponentName: string, webcomponentElement: React.ElementType) {
    this.webcomponentNames.push({ name: webcomponentName, component: webcomponentElement } as WebcomponentModule);
    return this;
  }

  builder() {
    this.webcomponentNames.forEach((elem) => {
      const ReactFunctionElement = elem.component;
      const ReactFunctionElementWithEnvironment = (props: any) => (
        <EnvironmentProvider environment={this.environment}>
          <ReactFunctionElement {...props} />
        </EnvironmentProvider>
      );
      class WebComponent extends AppWebComponent {
        constructor() {
          super(ReactFunctionElementWithEnvironment);
        }
      }
      customElements.define(elem.name, WebComponent);
    });
  }
}
