import { createContext, useContext, useEffect, useState } from 'react';
import { StepperStateManager } from '@workspace/frontend-shared-application-state-manager';

const StepContext = createContext(null);

export type StepProviderProps = {
  activeStep: number;
  handleNext: Function;
  handleBack: Function;
  steps: [];
  setSteps: Function;
  setActiveStep: Function;
};

function StepProvider({ children }) {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setActiveStep(StepperStateManager.getStep());
  }, []);

  const _setActiveStep = (newStep) => {
    if (newStep === StepperStateManager.getStep()) return;
    StepperStateManager.setStepDispatch(newStep);
    setActiveStep(newStep);
  };

  const handleNext = () => {
    const newStep = activeStep + 1;
    _setActiveStep(newStep);
  };

  const handleBack = () => {
    if (activeStep >= 1) {
      const newStep = activeStep - 1;
      _setActiveStep(newStep);
    }
  };

  return (
    <StepContext.Provider
      value={{ activeStep, handleNext, handleBack, steps, setSteps, setActiveStep: _setActiveStep }}
    >
      {children}
    </StepContext.Provider>
  );
}

function useStepProvider() {
  return useContext(StepContext);
}

export { StepProvider, useStepProvider };
