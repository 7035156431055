import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { FAQSearchQuestionsResponse } from '@workspace/frontend/faq-v2/domain/entities';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { GetFaqSearchQuestionsUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';
import { GetFAQSearchQuestionsRepository } from '@workspace/frontend/faq-v2/infra/repositories';

export function getFAQSearchQuestionsFactory(termSearched: string) {
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQSearchQuestionsResponse> = new AxiosHttpGetClient(
    `/content_types/faq_pergunta_resposta/entries?environment=${environment?.cmsBase?.environment}&query={"$or": [{"pergunta": {"$regex": "${termSearched}"}}, {"resposta": {"$regex": "${termSearched}"}}]}`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQSearchQuestionsRepository = new GetFAQSearchQuestionsRepository(httpGetClient);
  return new GetFaqSearchQuestionsUseCase(getFAQSearchQuestionsRepository);
};
