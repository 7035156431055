import { ModelYearField } from '../../../../components/autoFields/ModelYearField';
import { BrandAndModelField } from '../../../../components/autoFields/BrandAndModelField';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import React, { useMemo } from 'react';
import { BrandAndModelFieldBox, SelectFieldBox } from './vehicle-data-step.style';
import { ManufactureYearEditableField } from '../../../../components/autoFields/manufacture-year-editable-field';
import { useFormControllerMethods } from '@web/form';

export function RuleEditableSection({
  onSearchVehicleByDescriptionService,
  onInitValuesBrandAndModelZeroKm,
  itemsBrandAndModel,
  modelAndBrandNotFoundMessage,
  onChangeManufactureYear,
  onChangeModelYear,
}) {
  const { watch } = useFormControllerMethods();

  const fabricationYear = Number(watch('fabricationYear'));
  const modelYear = watch('modelYear');

  const disableBrandAndModelField = !modelYear || !fabricationYear;

  const optionFactory = (value) => ({ key: value, value });

  const modelYearOptions = [optionFactory(fabricationYear), optionFactory(fabricationYear + 1)];

  const brandAndModelMemo = useMemo(() => {
    return (
      <BrandAndModelField
        service={onSearchVehicleByDescriptionService}
        onInputChange={onInitValuesBrandAndModelZeroKm}
        items={itemsBrandAndModel}
        disabled={disableBrandAndModelField}
        modelAndBrandNotFoundMessage={modelAndBrandNotFoundMessage}
      />
    );
  }, [itemsBrandAndModel, modelAndBrandNotFoundMessage, disableBrandAndModelField]);

  const fabricationYearFieldMemo = useMemo(() => {
    return <ManufactureYearEditableField autoFocus={true} onChange={onChangeManufactureYear} />;
  }, [onChangeManufactureYear]);

  const modelYearFieldMemo = useMemo(() => {
    return <ModelYearField onChange={onChangeModelYear} disabled={!fabricationYear} items={modelYearOptions} />;
  }, [fabricationYear, modelYearOptions]);

  return (
    <BoxWeb display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
      <SelectFieldBox>{fabricationYearFieldMemo}</SelectFieldBox>
      <SelectFieldBox>{modelYearFieldMemo}</SelectFieldBox>
      <BrandAndModelFieldBox>{brandAndModelMemo}</BrandAndModelFieldBox>
    </BoxWeb>
  );
}
