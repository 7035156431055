import { useMemo } from 'react';
import { useDynamicLandPage } from '../providers/landing-provider';

export const DynamicLandPageContent = () => {
  const dynamicLandPage = useDynamicLandPage();

  const renderRoutes = useMemo(() => {
    const routes = dynamicLandPage.dynamicLandings;
    return routes?.map((route) => {
      const { url, ...landing } = route;
      if (dynamicLandPage.createDynamicRoute) return dynamicLandPage.createDynamicRoute(url, landing);
    });
  }, [dynamicLandPage.dynamicLandings]);

  return <>{renderRoutes}</>;
};
