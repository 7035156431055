import { useEffect, useState } from 'react';
import { BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';

export function QaToolsContainer() {
  const cachedMSWRaw = window.sessionStorage.getItem('msw');
  let cachedMSWParsed = true;
  if (cachedMSWRaw) cachedMSWParsed = JSON.parse(cachedMSWRaw);

  const cachedQAToolsRaw = window.sessionStorage.getItem('qatools');
  let cachedQAToolsParsed = false;
  if (cachedQAToolsRaw) cachedQAToolsParsed = JSON.parse(cachedQAToolsRaw);

  const [isMSWEnabled, setIsMSWEnabled] = useState<boolean>(cachedMSWParsed);
  const [isQaToolsEnabled, setIsQaToolsEnabled] = useState<boolean>(cachedQAToolsParsed);

  const handleToggleButtons = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.code === 'F10') {
      setIsQaToolsEnabled((oldProp) => !oldProp);
    }
  };
  useEffect(() => {
    window.sessionStorage.setItem('qatools', isQaToolsEnabled.toString());
  }, [isQaToolsEnabled]);

  useEffect(() => {
    // @ts-ignore
    window.qatools = { open: () => setIsQaToolsEnabled(true), close: () => setIsQaToolsEnabled(false) };
    window.addEventListener('keydown', handleToggleButtons);

    return () => {
      window.removeEventListener('keydown', handleToggleButtons);
    };
  }, []);

  const handleActiveMsw = () => {
    // @ts-ignore
    window.msw.worker.start();
    window.sessionStorage.setItem('msw', 'true');
    setIsMSWEnabled(true);
  };

  const handleDisableMsw = () => {
    // @ts-ignore
    window.msw.worker.stop();
    window.sessionStorage.setItem('msw', 'false');
    setIsMSWEnabled(false);
  };

  const MswToggle = () => (
    <BoxWeb
      display={'flex'}
      position={'absolute'}
      flexDirection={'column'}
      margin={'-9rem 0rem 0rem 2rem'}
      width={'10rem'}
    >
      <BoxWeb mb={'2rem'}>
        <ButtonWeb label={'Ativar MSW'} onClick={handleActiveMsw} disabled={isMSWEnabled} />
      </BoxWeb>
      <BoxWeb>
        <ButtonWeb label={'Desativar MSW'} onClick={handleDisableMsw} disabled={!isMSWEnabled} />
      </BoxWeb>
    </BoxWeb>
  );

  return <>{isQaToolsEnabled && <MswToggle />}</>;
}
