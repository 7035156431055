import palette from './palette';

export default {
  useNextVariants: true,
  fontFamily: 'ItauDisplayPro-Regular',
  fontSize: 16,
  h1: {
    fontFamily: 'ItauDisplayPro-XBold',
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: 51,
  },
  h2: {
    fontFamily: 'ItauDisplayPro-XBold',
    color: palette.text.tertiary,
    fontWeight: 500,
    fontSize: 22,
  },
  h3: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 32,
    lineHeight: 36,
  },
  h4: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 28,
    lineHeight: 32,
  },
  h5: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 28,
  },
  h6: {
    fontFamily: 'ItauText-Bold',
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 24,
  },
};
