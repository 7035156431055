import { rest } from 'msw';

export const cmsWhiteLabelResponse = {
  entries: [
    {
      _version: 18,
      locale: 'pt-br',
      uid: 'blt91cb1698d5ca2318',
      ACL: {},
      _in_progress: false,
      created_at: '2021-11-30T12:06:44.349Z',
      created_by: 'blt91b98dcc4a8e7219',
      channel_name: 'loja-autov2',
      susep: 'X3G4H6',
      images: [
        {
          name: 'autov2-help',
          _metadata: {
            uid: 'csfc63b9cace392e53',
          },
          file: {
            uid: 'blt7a5e916a1830d979',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:31:12.585Z',
            updated_at: '2021-12-10T03:31:12.585Z',
            content_type: 'image/png',
            file_size: '1513',
            filename: 'help.png',
            title: 'help.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.395Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt7a5e916a1830d979/61b2ca00a59d77416b8f8000/help.png',
          },
        },
        {
          name: 'autov2-step2-transito-logo',
          _metadata: {
            uid: 'csa2009b065d079c43',
          },
          file: {
            uid: 'bltb0e35352a6e5a2d7',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:36:04.408Z',
            updated_at: '2021-12-10T03:36:04.408Z',
            content_type: 'image/png',
            file_size: '4883',
            filename: 'transito-logo.png',
            title: 'transito-logo.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.404Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/bltb0e35352a6e5a2d7/61b2cb24f6adbe75ea5e1219/transito-logo.png',
          },
        },
        {
          name: 'autov2-step2-transito-frase',
          _metadata: {
            uid: 'csfad7adf4e3d6e2dd',
          },
          file: {
            uid: 'blt3b4c1fa5c7c9b7e4',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:34:35.243Z',
            updated_at: '2021-12-10T03:34:35.243Z',
            content_type: 'image/png',
            file_size: '5045',
            filename: 'transito-logo.png',
            title: 'transito-logo.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.411Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt3b4c1fa5c7c9b7e4/61b2cacb9a45fb1280c191f2/transito-logo.png',
          },
        },
        {
          name: 'autov2-car',
          _metadata: {
            uid: 'cs4e77c838aefff200',
          },
          file: {
            uid: 'blt3704e5c6eba4b332',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:38:55.016Z',
            updated_at: '2021-12-10T03:38:55.016Z',
            content_type: 'image/png',
            file_size: '7724',
            filename: 'car.png',
            title: 'car.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.418Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt3704e5c6eba4b332/61b2cbcf1bdbe25709ba7061/car.png',
          },
        },
        {
          name: 'autov2-step4-people',
          _metadata: {
            uid: 'csd06052266b352526',
          },
          file: {
            uid: 'blt4443083573893da6',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:40:02.255Z',
            updated_at: '2021-12-10T03:40:02.255Z',
            content_type: 'image/png',
            file_size: '6870',
            filename: 'people.png',
            title: 'people.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.424Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt4443083573893da6/61b2cc122670f813ab26554d/people.png',
          },
        },
      ],
      partner: 'lojadeseguros',
      style: {
        palette: {
          primary: {
            main: '#ec7000',
            contrastText: '#FFF',
            light: 'rgba(236,112,0, 0.75)',
          },
          secondary: {
            main: '#5397C6',
            contrastText: '#FFF',
            light: 'rgba(236,112,0, 0.75)',
            dark: '#004990',
          },
          text: {
            primary: '#2b374a',
            error: 'red',
          },
          link: {
            primary: '#0C5599',
          },
          success: {
            main: '#007A47',
          },
          background: {
            lightMain: '#FFDAB8',
            lightSecondary: '#EFE9E5',
            gray: '#F5F5F5',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        overrides: {
          MuiInputBase: {
            root: {
              fontSize: '18px',
              fontFamily: 'ItauText-Light',
            },
          },
        },
        props: {
          MuiTextField: {
            variant: 'outlined',
          },
          MuiWithWidth: {
            initialWidth: 'lg',
          },
        },
        typography: {
          useNextVariants: true,
          fontFamily: 'ItauText-Regular',
          fontSize: 16,
          h1: {
            fontFamily: 'ItauText-Regular',
            fontWeight: 300,
            fontSize: 32,
          },
          h2: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 36,
            lineHeight: 40,
          },
          h3: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 32,
            lineHeight: 36,
          },
          h4: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 28,
            lineHeight: 32,
          },
          h5: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 28,
          },
          h6: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 24,
          },
        },
      },
      tags: [],
      title: 'Loja de Seguros',
      updated_at: '2021-12-10T04:41:56.122Z',
      updated_by: 'blt91b98dcc4a8e7219',
      url: '/loja-de-seguros',
      publish_details: {
        environment: 'bltf74ca377a499e53d',
        locale: 'pt-br',
        time: '2021-12-21T20:33:09.431Z',
        user: 'blt91b98dcc4a8e7219',
      },
    },
    {
      _version: 11,
      locale: 'pt-br',
      uid: 'blt54e40142f9ece4a7',
      ACL: {},
      _in_progress: false,
      created_at: '2021-12-03T15:41:50.645Z',
      created_by: 'blt91b98dcc4a8e7219',
      images: [
        {
          name: 'autov2-help',
          _metadata: {
            uid: 'csf969da23bc2e2c9a',
          },
          file: {
            uid: 'blt3220a3301a4e1e93',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T04:37:52.621Z',
            updated_at: '2021-12-10T04:37:52.621Z',
            content_type: 'image/png',
            file_size: '1433',
            filename: 'img-alerta.png',
            title: 'img-alerta.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2022-03-03T12:19:40.066Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt3220a3301a4e1e93/61b2d9a0f2c59775f0a56626/img-alerta.png',
          },
        },
        {
          name: 'autov2-step2-transito-logo',
          _metadata: {
            uid: 'csb2dd1441f6aa82b5',
          },
          file: {
            uid: 'bltb0e35352a6e5a2d7',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:36:04.408Z',
            updated_at: '2021-12-10T03:36:04.408Z',
            content_type: 'image/png',
            file_size: '4883',
            filename: 'transito-logo.png',
            title: 'transito-logo.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.404Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/bltb0e35352a6e5a2d7/61b2cb24f6adbe75ea5e1219/transito-logo.png',
          },
        },
        {
          name: 'autov2-step2-transito-frase',
          _metadata: {
            uid: 'cs00b94347497a3105',
          },
          file: {
            uid: 'blt3b4c1fa5c7c9b7e4',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:34:35.243Z',
            updated_at: '2021-12-10T03:34:35.243Z',
            content_type: 'image/png',
            file_size: '5045',
            filename: 'transito-logo.png',
            title: 'transito-logo.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.411Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt3b4c1fa5c7c9b7e4/61b2cacb9a45fb1280c191f2/transito-logo.png',
          },
        },
        {
          name: 'autov2-car',
          _metadata: {
            uid: 'csd7041c0cbdcf96b2',
          },
          file: {
            uid: 'blt3704e5c6eba4b332',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T03:38:55.016Z',
            updated_at: '2021-12-10T03:38:55.016Z',
            content_type: 'image/png',
            file_size: '7724',
            filename: 'car.png',
            title: 'car.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2021-12-21T20:33:09.418Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/blt3704e5c6eba4b332/61b2cbcf1bdbe25709ba7061/car.png',
          },
        },
        {
          name: 'autov2-step4-people',
          _metadata: {
            uid: 'cse52c59d5e1bd2390',
          },
          file: {
            uid: 'bltaa9f4703152a172e',
            created_by: 'blt91b98dcc4a8e7219',
            updated_by: 'blt91b98dcc4a8e7219',
            created_at: '2021-12-10T04:38:24.776Z',
            updated_at: '2021-12-10T04:38:24.776Z',
            content_type: 'image/png',
            file_size: '4739',
            filename: 'img-ilustracao.png',
            title: 'img-ilustracao.png',
            ACL: {},
            _version: 1,
            parent_uid: null,
            is_dir: false,
            tags: [],
            publish_details: {
              environment: 'bltf74ca377a499e53d',
              locale: 'pt-br',
              time: '2022-03-03T12:19:40.086Z',
              user: 'blt91b98dcc4a8e7219',
            },
            url: 'https://images.contentstack.io/v3/assets/blt90a7e08c6e8530e7/bltaa9f4703152a172e/61b2d9c0f6adbe75ea5e1227/img-ilustracao.png',
          },
        },
      ],
      partner: 'icarros',
      style: {
        palette: {
          primary: {
            main: '#550000',
            contrastText: '#fff',
            light: '#FFB790',
          },
          secondary: {
            main: '#0071CE',
            contrastText: '#00386b',
            light: '#90CCFF',
            dark: '#00213F',
          },
          text: {
            primary: '#2b374a',
            error: 'red',
          },
          link: {
            primary: '#0C5599',
          },
          success: {
            main: '#007A47',
          },
          background: {
            lightMain: '#FFDAB8',
            lightSecondary: '#EFE9E5',
            gray: '#F5F5F5',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        typography: {
          useNextVariants: true,
          fontFamily: 'ItauText-Regular',
          fontSize: 16,
          h1: {
            fontFamily: 'ItauText-Regular',
            fontWeight: 300,
            fontSize: 32,
          },
          h2: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 36,
            lineHeight: 40,
          },
          h3: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 32,
            lineHeight: 36,
          },
          h4: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 28,
            lineHeight: 32,
          },
          h5: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 28,
          },
          h6: {
            fontFamily: 'ItauText-Bold',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 24,
          },
        },
        props: {
          MuiTextField: {
            variant: 'outlined',
          },
          MuiWithWidth: {
            initialWidth: 'lg',
          },
        },
        overrides: {
          MuiInputBase: {
            root: {
              fontSize: '18px',
              fontFamily: 'ItauText-Light',
            },
          },
        },
      },
      tags: [],
      title: 'Icarros',
      updated_at: '2022-03-04T19:57:02.791Z',
      updated_by: 'blt91b98dcc4a8e7219',
      url: '/icarros',
      publish_details: {
        environment: 'bltf74ca377a499e53d',
        locale: 'pt-br',
        time: '2022-03-04T19:57:07.659Z',
        user: 'blt91b98dcc4a8e7219',
      },
    },
  ],
};

export const cmsWhitelabelMock = (env) => {
  return rest.get(`https://cdn.contentstack.io/v3/content_types/parceiros_whitelabel/entries/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(cmsWhiteLabelResponse));
  });
};
