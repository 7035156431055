import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';

export const TextWrapper = styled(BoxWeb)`
  align-content: space-between;
  display: grid;
  justify-content: unset;
  height: 400px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: unset;
  }

  h3 {
    font-size: 28px;
    line-height: 36.04px;
    margin: 1rem 0;
  }
  .category {
    color: #106eb0;
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem 0;
  }
`;

export const CardContainer = styled.div`
  align-content: space-between;
  display: grid;
  flex: 1;
  height: 100%;
  max-width: 410px;
  text-decoration: none;
  ${(props) => props.theme.breakpoints.down('md')} {
    align-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  color: #106eb0;
  width: 51%;
`;
