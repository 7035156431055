
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { IGetDynamicLandPageRepository } from '@core/data/contracts/landings/get-dynamic-landpage-repository';
import { DynamicLandpage } from '@core/domain/entities/dynamic-landings/dynamic-landpage'
import { DynamicLandPageResponse } from '@core/domain/entities/dynamic-landings/dynamic-landpage-response';


export class GetDynamicLandPageRepository implements IGetDynamicLandPageRepository {

  constructor(private readonly httpGetClient: IHttpGetClient<null, DynamicLandPageResponse>) { }

  async execute(): Promise<DynamicLandpage[]> {
    const response: DynamicLandPageResponse =  await this.httpGetClient.get();
    return response.data.entries;
  }

}
