import {
  ActionAnalyticsInterface,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  assembleAnalyticsCustomEvent,
} from '@workspace/web/analytics';

type A = 'A1' | 'A2' | 'A3';
const actions: Record<A, string> = {
  A1: 'BTN:Home:Auto2.0:CotarMeuSeguroTopo',
  A2: 'BTN:Home:Auto2.0:VerDetalhesDasAssistenciasECoberturas',
  A3: 'BTN:Home:Auto2.0:CotarMeuSeguroRodape',
};
export type ActionsAutoV2HomeTechSpecI = (
  origin: string
) => Record<A, (labels?: unknown[]) => ActionAnalyticsInterface>;
export const ActionEventTags: ActionsAutoV2HomeTechSpecI = (origin: string) => ({
  A1: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.HOME,
        label: actions.A1,
        noInteraction: false,
      },
      null,
      origin
    ),
  A2: (label: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.HOME,
        label: actions.A2,
        noInteraction: false,
      },
      label,
      origin
    ),
  A3: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.HOME,
        label: actions.A3,
        noInteraction: false,
      },
      null,
      origin
    ),
});
