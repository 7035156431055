import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { Title, TitleBold, Text } from './no-products-screen-styles';

type NoProductsScreenProps = {
  title?: string;
  subtitle?: string;
  text?: string;
  image?: string;
  imageAlt?: string;
};

export function NoProductsScreen({
  title = 'ooops...',
  subtitle = 'estamos em evolução',
  text = 'Ainda não estamos exibindo os seus outros produtos contratados, mas daqui a pouco você vai conseguir\n' +
    '          vê-los!',
  image = 'autov2Images.inEvolution',
  imageAlt = 'uma pessoa aguardando'
}: NoProductsScreenProps) {
  return (
    <BoxWeb
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      mt={{ xs: '25px', md: '45px' }}
      mb={{ xs: '25px', md: '45px' }}
      flexWrap={'wrap'}
    >
      <BoxWeb>
        <Title>{title}</Title>
        <TitleBold>{subtitle}</TitleBold>
        <Text>
          {text}
        </Text>
      </BoxWeb>
      {/*<BoxWeb>*/}
      {/*  <img src={translateToMicrofrontendPath(image)} alt={imageAlt} />*/}
      {/*</BoxWeb>*/}
    </BoxWeb>
  );
}
