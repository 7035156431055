import { rest } from 'msw';

interface UserByCPFRequestProps {
  hash: string;
}

export const validateHashAuthMock = (env) => {
  return rest.post<UserByCPFRequestProps>(
    `${env.gatewayBFFBaseURL.url}/recuperarsenha/validarhash`,
    (req, res, ctx) => {
      const { hash } = req.body;

      const hashStatus = {
        valid: hash === '123456',
        systemicError: hash === '000000',
      };

      if (hashStatus.valid) {
        return res(
          ctx.status(200),
          ctx.json({
            data: {
              success: true,
              value: {},
            },
          })
        );
      }

      if (hashStatus.systemicError) {
        return res(
          ctx.status(417),
          ctx.json({
            data: {
              success: false,
              errors: [
                {
                  code: 'systemicError',
                  message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
                },
              ],
            },
          })
        );
      }

      return res(
        ctx.status(409),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'invalidPasswordTokenRequiredError',
                message: 'Mensagem de erro ao verificar Hash',
              },
            ],
          },
        })
      );
    }
  );
};
