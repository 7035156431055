import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme } from '@material-ui/core';
import { ANALYTICS_BLOG } from '@workspace/web/analytics';
import { PostCard } from '../PostCard/PostCard';
import { phraseToUpperCamelCase } from '../../../utils';

export interface HeroParams {
  cards: {
    post_image: {
      path: string;
    };
    post_title: string;
    post_category: {
      title: string;
    }[];
    url: string;
  }[];
}

export const Hero = ({ cards }: HeroParams) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <PostCard
          image={cards[0]?.post_image.path}
          title={cards[0]?.post_title}
          category={cards[0]?.post_category[0]?.title}
          height={{ xs: '61vw', sm: '100%' }}
          onClickPost={() => {
            history.push(cards[0].url);
            ANALYTICS_BLOG.HOME.READ_POST_2([phraseToUpperCamelCase(cards[0].post_title)]);
          }}
          data-testid="card-1"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PostCard
          image={cards[1]?.post_image.path}
          title={cards[1]?.post_title}
          category={cards[1]?.post_category[0]?.title}
          m={{ xs: theme.spacing(1, 0, 0, 0), sm: theme.spacing(0, 0, 1, 1) }}
          height={{ xs: '61vw', sm: '22vw' }}
          data-testid="card-2"
          onClickPost={() => {
            history.push(cards[1].url);
            ANALYTICS_BLOG.HOME.READ_POST_2([phraseToUpperCamelCase(cards[1].post_title)]);
          }}
        />
        <PostCard
          image={cards[2]?.post_image.path}
          title={cards[2]?.post_title}
          category={cards[2]?.post_category[0]?.title}
          m={{ xs: theme.spacing(1, 0, 0, 0), sm: theme.spacing(0, 0, 0, 1) }}
          height={{ xs: '61vw', sm: '22vw' }}
          data-testid="card-3"
          onClickPost={() => {
            history.push(cards[2].url);
            ANALYTICS_BLOG.HOME.READ_POST_2([phraseToUpperCamelCase(cards[2].post_title)]);
          }}
        />
      </Grid>
    </Grid>
  );
};
