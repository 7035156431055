import { useContext, useEffect, useRef, useState } from 'react';
import { RegisterContainer, Title, TitleBold, TitleDescription } from '../../style';
import { NetworkEmailField } from '../../../components';
import { AuthStateManager } from '@web/state-management';
import { FormController } from '@web/form';
import { getUserByEmailFactory } from '../../../factories/get-user-by-email';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { AlertWeb, BoxWeb, ItauIcon, NavigateButtonsWeb } from '@workspace/frontend/shared/presentation/components';
import { CellphoneField, NameField } from '@web/form/component';
import { EMAIL_REGEX } from '../../../utils/validations';
import useErrorProvider from '../../../providers/error-provider';
import { Loading } from '../../../components/loading/loading';
import { StepperContext } from '../stepper/stepper-context';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { RecaptchaActions } from '../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../providers/auth-product-provider';

type FormData = { name: string; email: string; cellphone: string };

type User = {
  cpf?: string;
  email?: string;
  name?: string;
  cellphone?: string;
};

export function RegisterPersonalDataContainer() {
  const {
    environment: { recaptchaSiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();
  const titleRef = useRef(undefined);
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const { handleNext, handleBack } = useContext(StepperContext);

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.PERSONAL_DATA;

  const [userDataStored, setUserDataStored] = useState<User & { loaded: boolean }>({ loaded: false });
  const [emailAvailable, setEmailAvailable] = useState<boolean>(false);
  const [consultingEmail, setConsultingEmail] = useState<boolean>(false);
  const [emailConsulted, setEmailConsulted] = useState<boolean>(false);
  const [requestFailure, setRequestFailure] = useState<boolean>(false);

  const handleCloseAlert = () => setRequestError({ show: false });

  const handleRequestError = (errorData) => {
    if (!errorData?.response || errorData.response?.status < 500) {
      return setEmailAvailable(true);
    }

    setRequestFailure(true);

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });
  };

  const getUserByEmail = getUserByEmailFactory();

  const handleSubmit = (data: FormData) => {
    analyticsAuth.NEXT();
    AuthStateManager.registrationUserInformationDispatcher(data);
    AuthStateManager.registrationStepDispatcher(2);
    handleNext();
  };

  const handleClickBack = () => {
    analyticsAuth.BACK();
    handleBack();
  };

  const renderConfirmed = () =>
    emailAvailable && (
      <ItauIcon
        iconName="check_inst"
        style={{ margin: '0 8px 0 0', fontSize: 30, color: '#007A47' }}
        ariaLabel={'email verificado'}
      />
    );

  const fetchEmail = async (emailField: string) => {
    try {
      setConsultingEmail(true);

      const recaptcha = await getRecaptchaToken(RecaptchaActions.CONSULT_EMAIL, recaptchaSiteKey);
      const { data } = await getUserByEmail.execute(emailField, {
        recaptcha: JSON.stringify(recaptcha),
      });

      return data;
    } catch (error) {
      handleRequestError(error);
    } finally {
      setEmailConsulted(true);
      setConsultingEmail(false);
    }
  };

  const handleEmailChange = () => {
    setEmailConsulted(false);
    setEmailAvailable(false);
    setRequestFailure(false);
  };

  useEffect(() => {
    analyticsAuth.LOAD();
    const user = AuthStateManager.userSelector();
    setUserDataStored({ ...user, loaded: true });

    setTimeout(() => {
      if (titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  return (
    <>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        onClose={handleCloseAlert}
        children={requestError.message}
        secondsToClose={16}
      />
      {!userDataStored?.loaded ? (
        <Loading />
      ) : (
        <RegisterContainer>
          <BoxWeb marginBottom="8px">
            <Title id="lblInicialText_screenRegister" tabIndex={-1} ref={titleRef}>
              é a sua primeira vez por aqui{' '}
              <span role="img" aria-hidden={true}>
                :)
              </span>
            </Title>
          </BoxWeb>

          <BoxWeb marginBottom="45px">
            <TitleBold>crie sua conta: é simples e rápido</TitleBold>
          </BoxWeb>

          <BoxWeb marginBottom="32px">
            <TitleDescription>só precisamos de mais algumas informações</TitleDescription>
          </BoxWeb>

          <FormController
            validationMode="onBlur"
            onSubmit={handleSubmit}
            hideErrors
            initialValues={{
              name: userDataStored?.name,
              email: userDataStored?.email,
              cellphone: userDataStored?.cellphone,
            }}
          >
            {({ watch, setError, formState: { errors } }) => {
              const emailField = watch('email');

              if (userDataStored?.email && userDataStored?.email === emailField) {
                setEmailConsulted(true);
                setEmailAvailable(true);
              }

              const handleEmailBlur = async () => {
                if (!emailField || emailConsulted) return;

                const userData = EMAIL_REGEX.test(emailField) ? await fetchEmail(emailField) : undefined;

                if (!userData) return;

                if (userData?.success) {
                  setEmailAvailable(false);
                  const unavailableMessage = 'Erro inesperado';
                  setError('email', { message: unavailableMessage });
                  analyticsAuth.EMAIL_ALREADY_REGISTERED(unavailableMessage);
                }
              };

              const nextNavigationDisabled =
                requestFailure ||
                consultingEmail ||
                (emailField && !emailConsulted) ||
                Boolean(Object.keys(errors).length);

              return (
                <>
                  <BoxWeb maxWidth="400px" marginBottom="32px">
                    <NetworkEmailField
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      componentProps={{
                        id: 'txtEmail_screenRegister',
                        loading: consultingEmail,
                        InputProps: {
                          disabled: consultingEmail,
                          endAdornment: renderConfirmed(),
                        },
                        maxLength: 250,
                      }}
                    />
                  </BoxWeb>

                  <BoxWeb maxWidth="400px" marginBottom="32px">
                    <NameField
                      componentProps={{
                        id: 'txtName_screenRegister',
                        label: 'digite seu nome completo',
                        maxLength: 250,
                      }}
                    />
                  </BoxWeb>

                  <BoxWeb maxWidth="400px" marginBottom="32px">
                    <CellphoneField
                      componentProps={{
                        id: 'txtCellphone_screenRegister',
                        label: 'informe o número do seu celular com DDD',
                      }}
                    />
                  </BoxWeb>

                  <BoxWeb maxWidth="400px">
                    <NavigateButtonsWeb
                      onClickBack={handleClickBack}
                      widthBackButton={'188px'}
                      widthContinueButton={'188px'}
                      justifyContent="center"
                      disabled={nextNavigationDisabled}
                    />
                  </BoxWeb>
                </>
              );
            }}
          </FormController>
        </RegisterContainer>
      )}
    </>
  );
}
