import { BoxWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import { autov2Images } from '@web/assets';
import styled from 'styled-components';

type GentleTraffic = {
  logoPath: string;
  textPath: string;
};

export const GentleTraffic = ({ logoPath, textPath }: GentleTraffic) => {
  return (
    <BoxWeb display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={'center'}>
      <BoxWeb width={60} height={58} mr="0.5rem">
        <img src={logoPath} alt={''} aria-hidden />
      </BoxWeb>
      <BoxWeb width={91} height={47} mt="0.3rem" mr="1rem">
        <img src={textPath} alt={''} aria-hidden />
      </BoxWeb>
      <BoxWeb width={312} mt="0.5rem" fontSize={14} fontWeight={600}>
        Antes de continuar, baixe o app e se cadastre para ganhar mais 3% de desconto na contratação do seu seguro auto.
      </BoxWeb>
    </BoxWeb>
  );
};
