import { SelectWeb } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const InsuranceCompanyField = ({ items }) => (
  <FormControllerFieldConnector
    name="insuranceCompany"
    component={SelectWeb}
    validation={stringValidationFactory({
      condition: {
        when: 'previousBonus',
        is: true,
        then: stringValidationFactory({ requiredMessage: 'você precisa informar o nome da seguradora' }),
        otherwise: stringValidationFactory({}),
      },
    })}
    componentProps={{
      label: 'nome da seguradora',
      id: 'cboInsuranceCompany_screenCarInformation',
      items: items || [],
    }}
  />
);
