import { authImages, translateToMicrofrontendPath } from '@web/assets';
import { BoxWeb, ButtonWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import styled, { ThemeProvider } from 'styled-components';
import { useAuthenticationParams } from '../../../../providers/authentication-params-provider';
import { RecoveryContainer } from '../../../style';

const Image = styled.img`
  width: 350px;
  height: 280px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}) {
    margin-left: 100px;
  }
`;

export type HashErrorProps = {
  title: string;
  text: string;
};

export const HashError = ({ title, text }: HashErrorProps) => {
  const { Theme } = useThemeProvider();
  const { getRegisteredLoginURLBase } = useAuthenticationParams();

  const handleRedirect = () => {
    const loginURLBase = getRegisteredLoginURLBase();
    window.location.assign(loginURLBase);
  };

  return (
    <ThemeProvider theme={Theme}>
      <RecoveryContainer>
        <BoxWeb
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'start' }}
          alignItems="center"
          marginTop="60px"
          marginBottom="60px"
          color="#2B374A"
        >
          <BoxWeb maxWidth="370px" order={{ xs: 1, md: 0 }}>
            <BoxWeb
              fontFamily="ItauDisplay-Regular"
              fontSize="26px"
              lineHeight="33px"
              fontWeight="300"
              marginBottom="8px"
              component="p"
            >
              ooops...
            </BoxWeb>
            <BoxWeb
              fontFamily="ItauDisplay-Regular"
              fontSize="28px"
              lineHeight="36px"
              fontWeight="700"
              marginBottom="33px"
              component="p"
            >
              {title}
            </BoxWeb>
            <BoxWeb fontSize="18px" lineHeight="23px" marginBottom="33px" component="p">
              {text}
            </BoxWeb>
            <BoxWeb maxWidth="183px">
              <ButtonWeb label="ir para login" onClick={handleRedirect} />
            </BoxWeb>
          </BoxWeb>
          <BoxWeb order={{ xs: 0, md: 1 }}>
            <Image
              src={translateToMicrofrontendPath(authImages.redAlert)}
              alt="Alerta vermelho ao lado de uma pessoa pensativa"
            />
          </BoxWeb>
        </BoxWeb>
      </RecoveryContainer>
    </ThemeProvider>
  );
};
