import styled from 'styled-components';
import { ModalProps } from '../../models/modal-props';
import React, { useEffect } from 'react';
import { Hidden, Modal } from '@material-ui/core';

const ModalContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`;

const ModalContainerDesk = styled.div`
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 100;
`;

const ModalContentDesk = styled.div`
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 435px;
  max-height: calc(100% - 64px);
  max-width: 600px;
  z-index: 101;
  width: 100%;
`;

export function ModalMui({ children, open, id, onClose, ariaLabelledby, ariaDescribedby }: ModalProps): JSX.Element {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    setTimeout(() => {
      if (open) {
        rootElement?.setAttribute('aria-hidden', 'true');
        rootElement?.setAttribute('inert', 'true');
      } else {
        rootElement?.removeAttribute('aria-hidden');
      }
    }, 400);

    if (!open) {
      rootElement?.removeAttribute('inert');
    }
  }, [open]);

  return (
    <>
      <Hidden smUp>
        <Modal open={open} onClose={onClose} role={'complementary'}>
          <ModalContainer
            data-testid={id}
            role="dialog"
            aria-modal={open}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
          >
            {children}
          </ModalContainer>
        </Modal>
      </Hidden>
      <Hidden xsDown>
        <Modal open={open} onClose={onClose} role={'complementary'}>
          <ModalContainerDesk
            role="dialog"
            aria-modal={open}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
          >
            <ModalContentDesk data-testid={id} id={id}>
              {children}
            </ModalContentDesk>
          </ModalContainerDesk>
        </Modal>
      </Hidden>
    </>
  );
}
