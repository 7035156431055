import { rest } from 'msw';

export const cancellationAuthMock = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/cancelamento/autenticacao`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id_proposta: 9041654,
        email: 'da************@fake.com',
        telefone: '*******3425',
      })
    );
  });
};
