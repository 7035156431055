import { useThemeProvider } from '../theme-provider/theme-provider';
import { DefaultButtonProps } from '../../models/default-button';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function ButtonWeb({ type, label, onClick, id, disabled, loading, ...restProps }: DefaultButtonProps) {
  const { Components } = useThemeProvider();
  const { Button } = Components;
  return (
    <ThemeProviderInjector>
      <Button
        loading={loading}
        type={type}
        onClick={onClick}
        id={id}
        label={label}
        disabled={loading || disabled}
        {...restProps}
      />
    </ThemeProviderInjector>
  );
}

export default ButtonWeb;
