import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { DefaultToastProps } from '../../models/default-toast';
import { Collapse, CollapseProps, IconButton, withStyles } from '@material-ui/core';

const CollapseBase = withStyles(() => ({
  // style here
}))((props: CollapseProps) => <Collapse {...props} />);

const ToastBase = ({ children, iconButtonClick, collapseOpen = false, ...props }: DefaultToastProps) => (
  <CollapseBase in={collapseOpen}>
    <Alert
      {...props}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={iconButtonClick}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {children}
    </Alert>
  </CollapseBase>
);

export const ToastMui = withStyles(({ palette, breakpoints }) => {
  const { sm, md } = breakpoints?.values;

  return {
    root: {
      maxWidth: '100vw',
      boxSizing: 'border-box',
      borderRadius: 0,
      top: 0,
      lineHeight: 1,
      alignItems: 'center',
      padding: '12px 20px',
    },
    icon: {
      padding: 0,
      fontSize: 24,
      maxWidth: 24,
      maxHeight: 24,
    },
    message: {
      fontSize: 12,
      padding: 0,
    },
    action: {
      maxHeight: 24,
      marginRight: 0,
      maxWidth: 24,
    },
    standardError: {
      color: palette.common.white,
      backgroundColor: palette.error.main,
    },

    [`@media screen and (min-width: ${sm}px)`]: {
      root: {
        padding: '24px 44px',
      },
      message: {
        fontSize: 14,
      },
    },

    [`@media screen and (min-width: ${md}px)`]: {
      root: {
        padding: '24px 88px',
      },
      message: {
        fontSize: 16,
      },
    },
  };
})(ToastBase);
