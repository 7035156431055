export enum RouteEnum {
  BASE = '/v2/autenticacao',
  LOGIN = '/v2/autenticacao/entrar',
  REGISTER = '/v2/autenticacao/cadastrar',
  USER_ALREADY_REGISTERED = '/v2/autenticacao/usuario-existente',
  CONFIRM_TOKEN = '/v2/autenticacao/confirmar-codigo',
  RECOVERY_PASSWORD = '/v2/autenticacao/recuperar-senha',
  ACCESS = '/v2/autenticacao/acesso',
  MY_ACCOUNT = '/v2/minha-conta/dados',
  MY_PRODUCTS = '/v2/minha-conta/produtos',
}
