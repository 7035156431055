import { BreadCrumbsProps } from '../../models/breadcrumbs';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function BreadCrumbsWeb(props: BreadCrumbsProps) {
  const { Components } = useThemeProvider();
  const { Breadcrumbs } = Components;
  return (
    <ThemeProviderInjector>
      <Breadcrumbs {...props} />
    </ThemeProviderInjector>
  );
}

export default BreadCrumbsWeb;
