import React from 'react';
import { MyProductsContainer } from './my-products-container';
import {RemoteConfigProvider} from "@workspace/providers";


export function MyProductsCarrier() {
  return (
    <RemoteConfigProvider>
      <MyProductsContainer />
    </RemoteConfigProvider>
  )
}
