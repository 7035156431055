import { useThemeProvider } from './theme-provider';

type ThemeProviderInjectorProps = {
  children: JSX.Element;
  loading?: boolean;
};

export function ThemeProviderInjector({ children }: ThemeProviderInjectorProps) {
  const { Components, GlobalStyle, Theme } = useThemeProvider();
  const { ThemeProvider } = Components;

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={Theme}>{children}</ThemeProvider>
    </>
  );
}
