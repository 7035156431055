import { IFindVehicleByDescriptionRepository } from '@core/data/contracts/autov2/find-vehicle-by-description-repository';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { VehicleResponse } from '@core/domain/entities/autov2/response/vehicle';
import { VehicleSearchByDescription } from '@core/infra/models/vehicle-search-by-desciption';

export class FindVehicleByDescriptionRepository implements IFindVehicleByDescriptionRepository {
  constructor(
    private readonly httpGetClient: IHttpGetClient<{ params: VehicleSearchByDescription }, { data: VehicleResponse }>
  ) {}

  async execute(yearModel: number, description: string): Promise<{ data: VehicleResponse }> {
    const params = { anoModelo: yearModel, descricao: description, tipoTabelaReferencia: 2 };
    return await this.httpGetClient.get({ params }, {});
  }
}
