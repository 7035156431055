import { useEffect, useState } from 'react';
import { Loading } from '../../../../components/loading/loading';
import { HashError, HashErrorProps } from './hash-error';

export enum ValidateRecoveryHashErrors {
  invalid = 'invalidPasswordTokenRequiredError',
  expired = 'expiredTokenError',
}

export const HashErrorContainer = ({ codeMessage }: { codeMessage: string }) => {
  const [hashErrorComponentProps, setHashErrorComponentProps] = useState<HashErrorProps>();

  useEffect(() => {
    if (codeMessage === ValidateRecoveryHashErrors.expired) {
      return setHashErrorComponentProps({
        title: 'esse link expirou!',
        text: 'Para alterar sua senha, vá para a tela de login e solicite um novo link através da opção "não lembro a minha senha".',
      });
    }

    if (!codeMessage || codeMessage === ValidateRecoveryHashErrors.invalid) {
      return setHashErrorComponentProps({
        title: 'esse link está inválido!',
        text: 'Para alterar sua senha, vá para a tela de login e solicite um novo link através da opção "não lembro a minha senha".',
      });
    }

    return setHashErrorComponentProps({
      title: 'nosso serviço está indisponível no momento',
      text: 'Tente novamente mais tarde, por favor.',
    });
  }, [codeMessage]);

  if (codeMessage && hashErrorComponentProps) {
    return <HashError {...hashErrorComponentProps} />;
  }

  return <Loading />;
};
