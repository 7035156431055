import { put } from 'redux-saga/effects';
import { itemRiskAutomobileTypes } from '../../reducers/itemRisk/item-risk-automobile-handlers';
import { ActorDriverTypes } from '../../reducers/actor/actor-driver-reducer-handlers';

export interface TestABHiringInterface {
  cpf: string;
  plate: string;
  zeroKm: boolean;
}

function* testABHiringEffect({ cpf, plate, zeroKm }: TestABHiringInterface) {
  yield put({ type: itemRiskAutomobileTypes.setPlate, data: plate });
  yield put({ type: itemRiskAutomobileTypes.setZerokm, data: zeroKm });
  yield put({ type: ActorDriverTypes.setCpf, cpf });
}

export default testABHiringEffect;
