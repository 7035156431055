import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

const CustomBox = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: -10rem;
  max-width: 410px;
  width: 85%;
  height: 316px;
  margin: auto;
  background: ${(props) => props.theme.palette.success.main};
  box-shadow: 0px 11px 7px rgba(0, 68, 40, 0.195082);
  border-radius: 36px;
`;

type GreenBoxProps = {
  children: JSX.Element | string
  component?: React.ElementType
}

export function GreenBox({ children, component = 'h1' }: GreenBoxProps) {
  return (
    <CustomBox>
      <BoxWeb
        pt="2rem"
        color="#FFFFFF"
        fontSize="85px"
        fontFamily="Itau Text"
        fontWeight="normal"
        fontStyle="normal"
        alignItems="center"
        textAlign="center"
        lineHeight="normal"
        aria-hidden
      >
        :D
      </BoxWeb>
      <BoxWeb
        component={component}
        pt="2rem"
        px="1rem"
        mx={'auto'}
        my={0}
        maxWidth={337}
        height={68}
        color="#FFFFFF"
        fontSize={{ xs: '22px', sm: '30px' }}
        lineHeight={{ xs: '31px', sm: '43px' }}
        fontFamily="Itau Text"
        fontWeight="normal"
        fontStyle="normal"
        alignItems="center"
        textAlign="center"
        id="lblSuccess_screenConfirmation"
      >
        {children}
      </BoxWeb>
    </CustomBox>
  );
}
