import { ComponentMode, StyleMode, ThemeProviderWeb, ComponentAdapterTypes, StyleAdapterTypes } from '@workspace/frontend/shared/presentation/components';

export function LandingTheme({ children }: any) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.LOJA_DE_SEGUROS,
  };

  return (
    <ThemeProviderWeb rootName="land" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
