import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import React from 'react';
import { RadioGroupProps } from '../../models/radio-group';

export const RadioGroupMui = ({
  options,
  disabled,
  onChange,
  onBlur,
  value,
  name,
  row,
  labelPlacement,
  defaultValue,
  id,
}: RadioGroupProps) => {
  return (
    <FormControl disabled={disabled} id={id}>
      <RadioGroup onBlur={onBlur} onChange={onChange} value={value || defaultValue} name={name} row={row}>
        {options.map((option, index) => {
          const icon = option.icon || <RadioButtonUnchecked />;
          const checkedIcon = option.checkedIcon || <RadioButtonChecked />;
          const id = option.id;
          const optionsLength = options.length;
          const ariaPosinset = index + 1;
          return (
            <FormControlLabel
              labelPlacement={labelPlacement || 'bottom'}
              value={option.value}
              control={
                <Radio
                  icon={icon}
                  checkedIcon={checkedIcon}
                  id={id}
                  inputProps={{
                    'aria-setsize': optionsLength,
                    'aria-posinset': ariaPosinset,
                    'aria-label': option?.ariaLabel,
                  }}
                />
              }
              label={option.label}
              disabled={option.disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
