import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .logo {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  #progress {
    animation: opacity 0.5s 2.5s 1 forwards;
    background-color: #f1f1f1;
    height: 4px;
    margin: 32px auto 20px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    width: 300px;
  }
  #progress::before {
    animation: linear-loading 20s 3s linear forwards;
    background-color: #ec7000;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transform: translateX(-300px);
    width: 300px;
  }
  @keyframes linear-loading {
    0% {
      transform: translateX(-300px);
    }
    5% {
      transform: translateX(-240px);
    }
    15% {
      transform: translateX(-30px);
    }
    25% {
      transform: translateX(-30px);
    }
    30% {
      transform: translateX(-20px);
    }
    45% {
      transform: translateX(-20px);
    }
    50% {
      transform: translateX(-15px);
    }
    65% {
      transform: translateX(-15px);
    }
    70% {
      transform: translateX(-10px);
    }
    95% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes opacity {
    100% {
      opacity: 1;
    }
  }
`;
