import { AuthTheme } from '../../components/auth-theme';
import { AuthenticationParamsProvider } from '../../providers/authentication-params-provider';
import { ErrorProvider } from '../../providers/error-provider';
import { AuthContainer } from './auth-container';
import { AuthOriginProvider } from '../../providers/auth-origin-provider';
import { AuthProductProvider } from '../../providers/auth-product-provider';
import useOriginAndProductSession from "../../hooks/useOriginAndProductSession";

export const Auth = ({
  origin,
  onsuccesslogincallbackname,
  onbackeventname,
  product,
}: {
  origin?: string;
  onsuccesslogincallbackname?: string;
  onbackeventname?: string;
  product?: string;
}) => {
  const {originSessionStore, productSessionStore} = useOriginAndProductSession();
  const createCallBackFromEvent = (eventName: string) => {
    const evt = new CustomEvent(eventName);
    window.dispatchEvent(evt);
  };

  const onBack = () => createCallBackFromEvent(onbackeventname);
  const onSuccessLoginCallback = () => createCallBackFromEvent(onsuccesslogincallbackname);

  return (
    <AuthOriginProvider origin={origin}>
      <AuthProductProvider product={product}>
        <AuthTheme>
          <AuthenticationParamsProvider>
            <ErrorProvider>
              <AuthContainer onSuccessLoginCallback={onSuccessLoginCallback} onBack={onBack} />
            </ErrorProvider>
          </AuthenticationParamsProvider>
        </AuthTheme>
      </AuthProductProvider>
    </AuthOriginProvider>
  );
};
