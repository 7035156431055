import { rest } from 'msw';
import simulacoes from './simulacoes.json';

const responseError = { erros: ['cpf'] };
const timeOutError =  {mensagem: 'Endpoint request timed out'};
const simulationAuthError =  {mensagem: 'Error validating authentication'};
const simulationFailSend =  {mensagem: 'Erro ao enviar simulação'};

interface ResidSimulationRequestProps {
  proponente: any;
}

export const residSimulation = (env) => {
  return rest.post<ResidSimulationRequestProps>(
    `${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/simulacoes`,
    (req, res, ctx) => {
      const { cpf, data_nascimento } = req.body.proponente;
      //465.648.560-83 cpf para exceção de dados divergentes
      if (cpf === '46564856083') return res(ctx.status(400), ctx.json(responseError));
      // add essa data de nascimento na tela de dados pessoais para simular erro diferente de 400 na segunda chamada de simulacao
      if (data_nascimento === '1992-07-25') return res(ctx.status(400), ctx.json(simulationAuthError));
      if (data_nascimento === '1992-07-26') return res(ctx.status(400), ctx.json(simulationFailSend));
      return res(ctx.status(200), ctx.json(simulacoes));
    }
  );
};
