import { put } from 'redux-saga/effects';
import { RegistrationTypes } from '../../reducers/auth/authReducerHandlers';

export interface IUserRegister {
  name: string;
  email: string;
  cellphone: string;
}

function* registrationUserInformationEffect({ name, email, cellphone }: IUserRegister) {
  yield put({ type: RegistrationTypes.setemail, email });
  yield put({ type: RegistrationTypes.setname, name });
  yield put({ type: RegistrationTypes.setcellphone, cellphone });
}

export default registrationUserInformationEffect;
