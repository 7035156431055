import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { IRefreshTokenRepository } from '@core/data/contracts/auth/refresh-token-repository';
import { RefreshToken } from '@core/domain/entities/auth/refreshToken';
import { RefreshTokenResponse } from '@core/domain/entities/auth/response/refresh-token-response';

export class RefreshTokenRepository implements IRefreshTokenRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<RefreshToken, RefreshTokenResponse>) {}

  async execute(data: RefreshToken, headers?: any) {
    return await this.httpPostClient.post(data, null, headers);
  }
}
