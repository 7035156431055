import { Banner } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQBannerRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQBannerUseCase {
  execute(): Promise<Banner[]>;
}

export class GetFaqBannerUseCase implements IGetFAQBannerUseCase {
  constructor(private readonly getFAQBannerRepository: IGetFAQBannerRepository) {}

  async execute(): Promise<Banner[]> {
    return await this.getFAQBannerRepository.execute();
  }
}
