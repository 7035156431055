export const ANALYTICS_EVENT_ACTIONS = {
  HOME: 'Home',
  ODONTO: 'Odonto',
  RESID: 'Resid',
  AUTO: 'Auto',
  LOGIN: 'Login',
  REGISTER: 'Cadastro',
  FORM_FILLING: 'PreenchimentoDeFormulario',
  BLOG: 'Blog',
  FAQ: 'Faq',
};
