import { useEffect } from 'react';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { DefaultToastProps } from '../../models/default-toast';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function ToastWeb({ iconMapping, secondsToHide, visible, setVisible, ...props }: DefaultToastProps) {
  const { Components } = useThemeProvider();
  const { Toast } = Components;

  const iconError = <span style={{ color: 'white' }}>&#10008;</span>;
  const iconButtonClick = () => setVisible(false);

  useEffect(() => {
    const timeOut = secondsToHide && setTimeout(() => setVisible(false), secondsToHide * 1000);
    const timer = secondsToHide ? timeOut : undefined;

    return () => clearTimeout(timer);
  }, [visible, secondsToHide, setVisible]);

  return (
    <ThemeProviderInjector>
      <Toast
        {...props}
        collapseOpen={visible}
        iconButtonClick={iconButtonClick}
        iconMapping={{ error: iconError, ...iconMapping }}
      />
    </ThemeProviderInjector>
  );
}

export default ToastWeb;
