import styled from 'styled-components';

export const Feedback = styled.span`
  margin-top: 8px;
  font-size: 18px;
  color: #2b374a;
  font-family: "ItauDisplay-Light";

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 14px;
  }
`;

export const PageTitle = styled.h2`
  margin: 0 0 0 8px;
  vertical-align: center;
  color: #ec7000;
  font-size: 26px;
  font-family: 'ItauText-XBold';

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 20px;
  }
`;

export const TopicTitle = styled.h3`
  color: #2b374a;
  font-size: 22px;
  font-weight: 800;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 16px;
  }
`;

export const NavLink = styled.a`
  border-radius: 16px;
  border: 1px solid #ec7000;
  padding: 4px 10px;
  margin-left: 8px;
  font-weight: 400;
  color: #2b374a;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    color: #fff;
    font-weight: 700;
    background-color: #ec7000;
  }
`;

export const DesktopContent = styled.article`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: flex-start;
  }
`;

export const CardImg = styled.img`
  width: 38px;
  height: 38px;
`;
