import {
  ActionAnalyticsInterface,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  assembleAnalyticsPageCustomEvent,
} from '@workspace/frontend-shared-application-analytics';
import { pages } from './page-load-tags';

type E =
  | 'P1E1'
  | 'P1E2'
  | 'P2E1'
  | 'P2E2'
  | 'P3E1'
  | 'P3E2'
  | 'P3E3'
  | 'P3E4'
  | 'P4E1'
  | 'P4E2'
  | 'P4E3'
  | 'P4E4'
  | 'P4E5'
  | 'P5E1'
  | 'P5E2'
  | 'P5E3'
  | 'P6E1'
  | 'P6E2'
  | 'P6E3'
  | 'P7E1'
  | 'P7E2'
  | 'P7E3'
  | 'P7E4'
  | 'P8E1'
  | 'P8E2';

const pageEvent: Record<E, string> = {
  P1E1: 'BTN:Cadastro:Cpf:Voltar',
  P1E2: 'BTN:Cadastro:Cpf:Proximo',
  P2E1: 'BTN:Cadastro:UsuarioJaCadastrado:Voltar',
  P2E2: 'BTN:Cadastro:UsuarioJaCadastrado:FazerLogin',
  P3E1: 'BTN:Cadastro:Correntista:IdentificacaoDosDados:NaoIdetificouDados',
  P3E2: 'BTN:Cadastro:Correntista:IdentificacaoDosDados:Voltar',
  P3E3: 'BTN:Cadastro:Correntista:IdentificacaoDosDados:Continuar',
  P3E4: 'BTN:Cadastro:Correntista:IdentificacaoDosDados:PularEtapa',
  P4E1: 'Erro:Cadastro:Correntista:Codigo:{0}',
  P4E2: 'BTN:Cadastro:Correntista:Codigo:ReenviarCodigo',
  P4E3: 'BTN:Cadastro:Correntista:Codigo:TrocarMetodo',
  P4E4: 'BTN:Cadastro:Correntista:Codigo:Voltar',
  P4E5: 'BTN:Cadastro:Correntista:Codigo:Continuar',
  P5E1: 'Erro:Cadastro:DadosPessoais:{0}',
  P5E2: 'BTN:Cadastro:DadosPessoais:Voltar',
  P5E3: 'BTN:Cadastro:DadosPessoais:Proximo',
  P6E1: 'Erro:Cadastro:CriarSenha:{0}',
  P6E2: 'BTN:Cadastro:CriarSenha:Voltar',
  P6E3: 'BTN:Cadastro:CriarSenha:Proximo:Comunicacao[Nenhuma|Sms|Whatsapp|Email]',
  P7E1: 'Erro:Cadastro:Validacao:{0}',
  P7E2: 'BTN:Cadastro:Validacao:ReenviarCodigo',
  P7E3: 'BTN:Cadastro:Validacao:Voltar',
  P7E4: 'BTN:Cadastro:Validacao:Continuar',
  P8E1: 'BTN:Cadastro:Validacao:Voltar',
  P8E2: 'BTN:Cadastro:Validacao:Continuar',
};

export type ActionsAuthRegisterTechSpecI = (options: {
  origin?: string;
  product?: string;
}) => Record<E, (labels?: unknown[]) => ActionAnalyticsInterface>;

export const ActionEventTags: ActionsAuthRegisterTechSpecI = (options: { origin?: string; product?: string }) => ({
  P1E1: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P1E1,
        noInteraction: false,
      },
      null,
      options
    ),
  P1E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P1E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P2E1: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P2,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P2E1,
        noInteraction: false,
      },
      null,
      options
    ),
  P2E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P2,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P2E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P3E1: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P3,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P3E1,
        noInteraction: false,
      },
      null,
      options
    ),
  P3E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P3,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P3E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P3E3: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P3,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P3E3,
        noInteraction: false,
      },
      null,
      options
    ),
  P3E4: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P3,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P3E4,
        noInteraction: false,
      },
      null,
      options
    ),
  P4E1: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P4,
        category: ANALYTICS_EVENT_CATEGORIES.ERROR,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P4E1,
        noInteraction: false,
      },
      label,
      options
    ),
  P4E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P4,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P4E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P4E3: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P4,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P4E3,
        noInteraction: false,
      },
      null,
      options
    ),
  P4E4: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P4,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P4E4,
        noInteraction: false,
      },
      null,
      options
    ),
  P4E5: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P4,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P4E5,
        noInteraction: false,
      },
      null,
      options
    ),
  P5E1: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P5,
        category: ANALYTICS_EVENT_CATEGORIES.ERROR,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P5E1,
        noInteraction: false,
      },
      label,
      options
    ),
  P5E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P5,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P5E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P5E3: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P5,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P5E3,
        noInteraction: false,
      },
      null,
      options
    ),
  P6E1: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P6,
        category: ANALYTICS_EVENT_CATEGORIES.ERROR,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P6E1,
        noInteraction: false,
      },
      label,
      options
    ),
  P6E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P6,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P6E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P6E3: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P6,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P6E3,
        noInteraction: false,
      },
      null,
      options
    ),
  P7E1: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P7,
        category: ANALYTICS_EVENT_CATEGORIES.ERROR,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P7E1,
        noInteraction: false,
      },
      label,
      options
    ),
  P7E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P7,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P7E2,
        noInteraction: false,
      },
      null,
      options
    ),
  P7E3: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P7,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P7E3,
        noInteraction: false,
      },
      null,
      options
    ),
  P7E4: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P7,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P7E4,
        noInteraction: false,
      },
      null,
      options
    ),
  P8E1: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P8,
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P8E1,
        noInteraction: false,
      },
      null,
      options
    ),
  P8E2: () =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.P8,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.REGISTER,
        label: pageEvent.P8E2,
        noInteraction: false,
      },
      null,
      options
    ),
});

export const ActionsAuthRegisterLabelCreators = {
  P4E1: (erro: string): string[] => {
    return [erro];
  },
  P5E1: (erro: string): string[] => {
    return [erro];
  },
  P6E1: (erro: string): string[] => {
    return [erro];
  },
  P7E1: (erro: string): string[] => {
    return [erro];
  },
};
