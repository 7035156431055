import { createContext, ReactChild, ReactChildren, useContext } from 'react';
import { FrontendEnvironment } from '@workspace/environments';

export interface EnvironmentContextProps {
  environment: FrontendEnvironment | null;
  onError?: (error: any) => void;
}

export interface EnvironmentProviderProps {
  environment: FrontendEnvironment | null;
  children: ReactChild | ReactChildren;
}

const EnvironmentContext = createContext<Partial<EnvironmentContextProps>>({});

export const EnvironmentProvider = ({ environment, children }: EnvironmentProviderProps) => {
  return (
    <EnvironmentContext.Provider
      value={{
        environment,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => useContext(EnvironmentContext);
