import { put } from 'redux-saga/effects';
import { itemRiskAutomobileTypes } from '../../reducers/itemRisk/item-risk-automobile-handlers';

function* addressStepEffect({ cep }) {
  yield put({
    type: itemRiskAutomobileTypes.setOvernightCep,
    cep,
  });
}

export default addressStepEffect;
