import { ITokenAuthUseCase } from '@core/domain/usecases/token-auth-usecase';
import { ITokenRepository } from '@core/data/contracts/auth/token-repository';
import { TokenAuth } from '@core/domain/entities/auth/token';
import { TokenAuthResponse } from '@core/domain/entities/auth/response/token-auth-response';

export class TokenAuthUsecase implements ITokenAuthUseCase {
  constructor(private readonly tokenRepository: ITokenRepository) {}

  async execute(tokenParams: TokenAuth, headers?: any): Promise<TokenAuthResponse> {
    return await this.tokenRepository.execute(tokenParams, headers);
  }
}
