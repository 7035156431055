import styled from 'styled-components';
import { CreditCardNumberField, CVVField, ExpirationField, NameField } from '@web/form/component';
import { BoxWeb, TooltipWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import { GridTemplateArea } from './grid-template-area';
import { BadgeSelector } from './badge-selector';
import { NAME_ABBREVIATED_REGEX, possibleBadgesArray } from '@workspace/frontend/auto/domain/entities';

export const CreditCard = ({
  renderBadge = <BadgeSelector />,
  allowedBadges,
}: {
  renderBadge?: JSX.Element;
  allowedBadges?: possibleBadgesArray;
}) => {
  const CreditCardBase = styled.div`
    width: 400px;
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 11px;
    padding: 1.5rem;

    display: inline-grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-columns: 48% 10% 20% 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      'number number badge badge'
      'name name name name'
      'date cvv cvv cvv';
    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  `;

  return (
    <CreditCardBase>
      <GridTemplateArea area={'number'}>
        <CreditCardNumberField allowedBadges={allowedBadges} />
      </GridTemplateArea>
      {renderBadge}
      <GridTemplateArea area={'name'}>
        <NameField
          prefix={'creditCard'}
          id={'txtNameCreditCard_screenPayment'}
          label={'nome impresso no cartão'}
          validationOverrides={{
            requiredMessage: 'informe o nome impresso',
            matches: {
              pattern: NAME_ABBREVIATED_REGEX,
              message: 'verifique seu nome e sobrenome, e se não existe mais de um espaço entre eles.',
            },
          }}
        />
      </GridTemplateArea>
      <GridTemplateArea area={'date'}>
        <ExpirationField label={'validade'} />
      </GridTemplateArea>
      <GridTemplateArea area={'cvv'}>
        <BoxWeb display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
          <CVVField />
          <TooltipWeb
            title={'Código de segurança (CVV) de 3 ou 4 dígitos que fica geralmente na parte de trás do cartão'}
          />
        </BoxWeb>
      </GridTemplateArea>
    </CreditCardBase>
  );
};
