import { BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import { Banner } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import styled from 'styled-components';
import './BannerDynamic.css';

type BannerDynamicProps = {
  banner: Banner;
  handleButtonTop: (typeRedirect: string, url: string) => void;
};

export const BannerImgDesktop = styled.img`
  width: 100%;
  height: 550px;
  object-fit: cover;
`;

export const BannerDynamic = ({ banner, handleButtonTop }: BannerDynamicProps) => {
  const handleClick = () => {
    handleButtonTop(banner?.action?.type, banner?.action?.url);
  };

  return (
    <BoxWeb width={'100%'} height={'100%'}>
      <BoxWeb width={'auto'} height={'auto'}>
        <BannerImgDesktop alt={banner?.alt} src={banner?.banner_image?.path} aria-hidden/>
      </BoxWeb>
      <BoxWeb
        display="flex"
        height={'154px'}
        justifyContent="space-around"
        alignItems="center"
        flexWrap={'wrap'}
        width={'100%'}
      >
        <BoxWeb display="flex" flexDirection="column" maxWidth={{ sm: '360px' }}>
          <BoxWeb
            component={'h3'}
            color="primary.main"
            fontSize={'30px'}
            fontFamily="ItauDisplay-Regular"
            role={'heading'}
            aria-level={3}
          >
            {banner?.title}
          </BoxWeb>
          {banner?.subtitle_middle ? (
            <BoxWeb
              color="primary.main"
              fontSize={'35px'}
              fontFamily="ItauDisplay-Regular"
              width={'275px'}
              textAlign="center"
            >
              {banner?.subtitle_middle}
            </BoxWeb>
          ) : null}
          <BoxWeb color="primary.contrastText" fontSize={'18px'} fontFamily="ItauDisplay-Regular">
            {banner?.subtitle}
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <BoxWeb width={'272px'}>
            <ButtonWeb
              label={banner?.action?.label}
              onClick={handleClick}
              id="btnPartnerWebsite_screenLanding"
              className={'button-banner-dynamic'}
            />
          </BoxWeb>
          {banner?.action?.helper_text && (
            <BoxWeb color={'primary.contrastText'} fontSize={'13px'}>
              {banner?.action?.helper_text}
            </BoxWeb>
          )}
        </BoxWeb>
      </BoxWeb>
    </BoxWeb>
  );
};
