import { CircularProgress, styled, TextField } from '@material-ui/core';
import React from 'react';
import { InputWebProps } from '../../models/input-web-props';
import { ItauIcon } from '../common/itau-icon';

const CustomInput = ({
  id,
  dataTestID,
  onChange,
  onBlur,
  name,
  value,
  label,
  InputLabelProps,
  InputProps,
  loading,
  helperText,
  error,
  maxLength = 250,
  required,
  type,
  pattern
}: InputWebProps) => {
  const customHelperText = () => {
    if (error) {
      return (
        <>
          <ItauIcon
            iconName="exclamacao"
            ariaHidden={true}
            style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}
          />
          {helperText}
        </>
      );
    }

    return helperText;
  };

  const renderLoading = () => <CircularProgress color="inherit" size={32} />;

  const renderEndAdornment = () => (loading && renderLoading()) || (!loading && InputProps?.endAdornment) || null;

  return (
    <TextField
      required={required}
      fullWidth
      id={id}
      name={name}
      error={error}
      label={label}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      variant="standard"
      helperText={customHelperText()}
      inputProps={{
        'data-testid': dataTestID,
        'aria-label': label,
        maxLength,
        pattern
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        endAdornment: renderEndAdornment(),
      }}
      type={type}
    />
  );
};

export const InputAuthentication = styled(CustomInput)(() => ({
  width: '100%',
  root: {
    width: '100%',
  },
}));
