import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; // defaults to sessionStorage for web
import createSagaMiddleware from 'redux-saga';

import effects from './effects/rootEffects';
import authReducer from './reducers/authReducers';

const persistConfig = {
  key: 'auth',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const sagaMiddleware = createSagaMiddleware();
const composedEnhancers = composeWithDevTools({ name: 'Auth Store' })(applyMiddleware(sagaMiddleware));

const store = createStore(persistedReducer, composedEnhancers);
const persistor = persistStore(store);
sagaMiddleware.run(effects);

export { persistor, store };
