import { AuthenticationAppServiceContract } from '@workspace/frontend/shared/application/contracts';
import { AuthenticationAppService } from '@workspace/frontend/shared/application/services';
import { AuthenticationRepositoryFactory } from './authentication-repository-factory';

abstract class AuthenticationAppServiceFactory {
  private static authenticationAppService: AuthenticationAppServiceContract;

  public static get instance(): AuthenticationAppServiceContract {
    if (!this.authenticationAppService) {
      this.authenticationAppService = new AuthenticationAppService(AuthenticationRepositoryFactory.instance);
    }

    return this.authenticationAppService;
  }
}

export { AuthenticationAppServiceFactory };
