import React, { createContext, useContext, useEffect, useState } from 'react';
import { ComponentAdapterTypes, ComponentBuilder } from '../../infra/base/component-builder';
import { StyleAdapterTypes, StyleBuilder } from '../../infra/base/style-builder';
import { GlobalStyleAdapterTypes, GlobalStyleBuilder } from '../../infra/base/global-style-builder';
import { LoadingWeb } from '../loading-web/loading-web';

export interface ThemeProviderProps {
  children: JSX.Element;
  rootName?: string;
  styleMode: StyleMode;
  componentMode: ComponentMode;

  networkStyleService?: NetworkServiceType;
}

type NetworkServiceType = () => Promise<NetworkServiceResponseType>;

export type NetworkServiceResponseType = {
  title: string;
  style: {
    palette: {
      [paletteKey: string]: {
        [palleteItemKey: string]: string;
      };
    };
  };
};

export type ComponentMode = {
  componentBase: ComponentAdapterTypes;
  componentOverrides?: ComponentAdapterTypes[];
};

export type StyleMode = {
  styleBase: StyleAdapterTypes;
};

const createComponentAdapterByContext = (
  componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  }
) => {
  const componentBase: ComponentBuilder = ComponentBuilder.adapter(componentMode.componentBase);
  componentMode?.componentOverrides?.forEach((composition) => {
    componentBase.compose(composition);
  });
  return componentBase.builder();
};

function createThemeSync(
  DynamicComponents,
  styleMode = {
    styleBase: StyleAdapterTypes.LOJA_DE_SEGUROS,
  }
) {
  const currentTheme = StyleBuilder.adapter(styleMode.styleBase).builder();
  return DynamicComponents.createTheme({
    ...currentTheme,
  });
}

async function createThemeAsync(networkStyleService, DynamicComponents) {
  const networkServiceResponse: NetworkServiceResponseType = await networkStyleService();
  const currentTheme = networkServiceResponse.style;
  return DynamicComponents.createTheme({
    ...currentTheme,
  });
}

const createThemeByContext = async (DynamicComponents, styleMode, networkStyleService) =>
  networkStyleService
    ? await createThemeAsync(networkStyleService, DynamicComponents)
    : createThemeSync(DynamicComponents, styleMode);

const createGlobalStyleByContext = () => GlobalStyleBuilder.adapter(GlobalStyleAdapterTypes.LOJA_DE_SEGUROS).builder();

const ThemeProviderContext = (() => {
  const GlobalStyle = createGlobalStyleByContext();
  const DynamicComponents: any = createComponentAdapterByContext();
  return createContext({
    Components: DynamicComponents,
    Theme: createThemeSync(DynamicComponents),
    GlobalStyle,
  });
})();

function ThemeProviderWeb({
  componentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  },
  styleMode = {
    styleBase: StyleAdapterTypes.LOJA_DE_SEGUROS,
  },
  networkStyleService,
  children,
}: ThemeProviderProps) {
  const [currentTheme, setCurrentTheme] = useState();
  const DynamicComponents: any = createComponentAdapterByContext(componentMode);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (networkStyleService) setLoading(true);
    createThemeByContext(DynamicComponents, styleMode, networkStyleService).then((data) => {
      setCurrentTheme(data);
      setLoading(false);
    });
  }, []);

  const GlobalStyle = createGlobalStyleByContext();
  if (loading) return <LoadingWeb />;
  if (!currentTheme) return children;

  return (
    <ThemeProviderContext.Provider value={{ Components: DynamicComponents, Theme: currentTheme, GlobalStyle }}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

function useThemeProvider() {
  return useContext(ThemeProviderContext);
}

export { ThemeProviderWeb, useThemeProvider };
