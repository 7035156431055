import styled from 'styled-components';

export const Title = styled.p`
  color: #2b374a;
  font-family: 'ItauDisplay-Light', 'sans-serif';
  font-size: 26px;
  margin: 0;
`;

export const TitleBold = styled.p`
  color: #2b374a;
  font-family: 'ItauDisplay-Regular', 'sans-serif';
  font-weight: 700;
  font-size: 28px;
  margin: 10px 0 26px 0;
`;

export const Text = styled.p`
  color: #2b374a;
  font-family: 'ItauText-Regular', 'sans-serif';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  margin-top: 26px;
  max-width: 368px;
`;

