import { useFormControllerMethods } from '@web/form';
import { Amex, Aura, Diners, Elo, MasterCard, Visa } from '@web/assets';
import { GridTemplateArea } from '@workspace/frontend/auto/presentation/components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';

enum badges {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  AURA = 'AURA',
  ELO = 'ELO',
}

export function BadgeSelector() {
  const { watch } = useFormControllerMethods();
  const CREDITCARD_REGEX = {
    MASTERCARD: /^5[1-5]/,
    VISA: /^4/,
    ELO: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
    AMEX: /^3[47][0-9]{13}/,
    AURA: /^((?!504175))^((?!5067))(^50[0-9])/,
    DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  };

  const checkCreditCardFlag = (string: any) => {
    if (typeof string !== 'string') return null;
    if (string.match(CREDITCARD_REGEX.MASTERCARD)) return badges.MASTERCARD;
    if (string.match(CREDITCARD_REGEX.VISA)) return badges.VISA;
    if (string.match(CREDITCARD_REGEX.AMEX)) return badges.AMEX;
    if (string.match(CREDITCARD_REGEX.DINERS)) return badges.DINERS;
    if (string.match(CREDITCARD_REGEX.AURA)) return badges.AURA;
    if (string.match(CREDITCARD_REGEX.ELO)) return badges.ELO;
    return null;
  };

  const handleNumberChange = (cardNumber: any) => {
    if (!cardNumber) return null;
    return checkCreditCardFlag(cardNumber);
  };
  const selectedBadge = handleNumberChange(watch('creditCardNumber'));
  const badgesOptions = {
    MASTERCARD: { src: MasterCard, ariaLabel: 'bandeira mastercard' },
    VISA: { src: Visa, ariaLabel: 'bandeira visa' },
    AMEX: { src: Amex, ariaLabel: 'bandeira amex' },
    DINERS: { src: Diners, ariaLabel: 'bandeira diners' },
    AURA: { src: Aura, ariaLabel: 'bandeira aura' },
    ELO: { src: Elo, ariaLabel: 'bandeira elo' },
  };

  return (
    <GridTemplateArea area={'badge'}>
      <BoxWeb display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'}>
        <BoxWeb maxWidth={'4rem'}>
          {selectedBadge && (
            <img
              src={badgesOptions[selectedBadge].src}
              alt={badgesOptions[selectedBadge].ariaLabel}
              width={'100%'}
              height={'100%'}
            />
          )}
        </BoxWeb>
      </BoxWeb>
    </GridTemplateArea>
  );
}
