import { Plate } from './Plate';
import { Cep } from '../../properties/Cep';
import moment from 'moment';

interface AutomobileInterface {
  plate?: string;
  zeroKm?: boolean;
  overnightCep?: string;
  brandAndModel?: string;
  bonusClass?: number;
  insuranceCompany?: string;
  modelYear?: number;
  fabricationYear?: number;
  itemsBrandAndModel?: { value: string; key: string | number }[];
}

const FLOOR_LIMIT_CAR_YEAR = -1;
const CEIL_LIMIT_CAR_YEAR = 20;
const MANUFACTURE_YEAR_LIMIT = 50;

export class Automobile {
  private _plate: Plate;
  public zeroKm: boolean;
  private _overnightCep: Cep;
  public brandAndModel: string;
  private _bonusClass: number;
  public insuranceCompany: string;
  private _modelYear: number;
  private _fabricationYear: number;
  private itemsBrandAndModel: { value: string; key: string | number }[];

  constructor({
    plate,
    zeroKm,
    overnightCep,
    brandAndModel,
    bonusClass,
    insuranceCompany,
    modelYear,
    fabricationYear,
    itemsBrandAndModel,
  }: AutomobileInterface) {
    if (plate) this.plate = plate;
    if (zeroKm) this.zeroKm = zeroKm;
    if (overnightCep) this.overnightCep = overnightCep;
    if (brandAndModel) this.brandAndModel = brandAndModel;
    if (bonusClass) this.bonusClass = bonusClass;
    if (insuranceCompany) this.insuranceCompany = insuranceCompany;
    if (modelYear) this.modelYear = modelYear;
    if (fabricationYear) this.fabricationYear = fabricationYear;
    if (itemsBrandAndModel) this.itemsBrandAndModel = itemsBrandAndModel;
  }

  get plate(): string | undefined {
    if (!this._plate) return;
    return this._plate.value;
  }

  set plate(plate: string | undefined) {
    if (!plate) throw new Error('Invalid plate');
    const p = new Plate(plate);
    if (!p.isValid) throw new Error('Invalid plate');
    this._plate = p;
  }

  get overnightCep(): string | undefined {
    if (!this._overnightCep) return;
    return this._overnightCep.value;
  }

  set overnightCep(overnightCep: string | undefined) {
    if (!overnightCep) throw new Error('Invalid cep');
    const cep = new Cep(overnightCep);
    if (!cep.isValid) throw new Error('Invalid cep');
    this._overnightCep = cep;
  }

  set bonusClass(bonusClass: number) {
    if (!(0 <= bonusClass && bonusClass <= 10)) throw new Error('Invalid bonus class');
    this._bonusClass = bonusClass;
  }

  get bonusClass(): number {
    return this._bonusClass;
  }

  static validateModelYear(modelYear: number) {
    const yearDiff = moment().diff(moment(modelYear, 'YYYY'), 'years');
    return yearDiff > FLOOR_LIMIT_CAR_YEAR && yearDiff <= CEIL_LIMIT_CAR_YEAR;
  }

  static validateManufactureYear(manufactureYear: number) {
    const currentYear = new Date().getFullYear();
    return manufactureYear >= currentYear - MANUFACTURE_YEAR_LIMIT && manufactureYear <= currentYear;
  }

  set modelYear(modelYear: number) {
    if (this.zeroKm && !Automobile.validateModelYear(modelYear)) throw new Error('Invalid model year');
    this._modelYear = modelYear;
  }

  get modelYear(): number {
    return this._modelYear;
  }

  static validateFabricationYear(fabricationYear: number) {
    const yearDiff = moment().diff(moment(fabricationYear, 'YYYY'), 'years');
    return yearDiff > FLOOR_LIMIT_CAR_YEAR && yearDiff <= CEIL_LIMIT_CAR_YEAR;
  }

  set fabricationYear(fabricationYear: number) {
    if (this.zeroKm && !Automobile.validateFabricationYear(fabricationYear))
      throw new Error('Invalid fabrication year');
    this._fabricationYear = fabricationYear;
  }

  get fabricationYear(): number {
    return this._fabricationYear;
  }
}
