import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const StateField = ({
  disabled,
  label = 'estado',
}: { disabled?: boolean; label?: string } & Partial<InputWebProps>) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name="state"
    disabled={disabled}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar o estado' })}
    componentProps={{
      label,
      id: 'txtState_screenAddress',
    }}
  />
);
