import { useContext } from 'react';
import { StepperContext } from './stepper-context';
import { StepperWeb } from './stepper-web';
import { Step } from './step';

export function StepperWebController({ steps }: { steps: Step[] }) {
  const { currentStep } = useContext(StepperContext);

  const currentStepWithoutSubStep = steps.reduce(
    (acc, cur, idx) => (idx <= currentStep && !cur.subStep ? acc + 1 : acc),
    -1
  );

  return <StepperWeb steps={steps} activeStep={currentStepWithoutSubStep} />;
}
