import { ISendRecoveryPasswordUsecase } from '@core/domain/usecases/send-recovery-password-usecase';
import { ISendRecoveryPasswordRepository } from '../contracts/auth/send-recovery-password-repository';
import { RecoveryPassword } from '@core/domain/entities/auth/recovery-password';
import { SendRecoveryPasswordResponse } from '@core/domain/entities/auth/response/send-recovery-password-response';

export class SendRecoveryPasswordUsecase implements ISendRecoveryPasswordUsecase {
  constructor(private readonly sendRecoveryPasswordRepository: ISendRecoveryPasswordRepository) {}

  async execute(data: RecoveryPassword, headers?: any): Promise<SendRecoveryPasswordResponse> {
    return await this.sendRecoveryPasswordRepository.execute(data, headers);
  }
}
