import { DialogActionsProps } from '@material-ui/core';
import { useThemeProvider } from '../../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../../theme-provider/theme-provider-injector';

export function DialogActionsWeb({ children, ...otherProps }: DialogActionsProps) {
  const { Components } = useThemeProvider();
  const { DialogActions } = Components;

  return (
    <ThemeProviderInjector>
      <DialogActions {...otherProps}>{children}</DialogActions>
    </ThemeProviderInjector>
  );
}
