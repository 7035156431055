export * from './autov2';
export * from './auth';
export { default as Visa } from './badges/visa.png';
export { default as MasterCard } from './badges/mastercard.png';
export { default as Amex } from './badges/amex.png';
export { default as Diners } from './badges/diners-club.png';
export { default as Elo } from './badges/elo.png';
export { default as Aura } from './badges/aura.png';
export { default as LogoPorto } from './badges/logo_porto.png';
export { default as  CreditCardIcon } from './autov2/icone-cartao-credito.png';
export { default as DebitCardIcon } from './autov2/icone-debito.png';
export * from './config/translate-to-microfrontend-path';
export * from './blog/feedbackIcons/FeedbackIcons';
export * from './config/translate-to-microfrontend-path';

export * from './blog/feedbackIcons/FeedbackIcons';
export { default as BadEmoticon } from './blog/feedbackIcons/bad.svg';
export { default as DidntLikeEmoticon } from './blog/feedbackIcons/didntLike.svg';
export { default as BeatsMeEmoticon } from './blog/feedbackIcons/beatsMe.svg';
export { default as LikedEmoticon } from './blog/feedbackIcons/liked.svg';
export { default as LovedEmoticon } from './blog/feedbackIcons/loved.svg';
export { default as FacebookIcon } from './blog/ic_facebook.svg';
export { default as TwitterIcon } from './blog/ic_twitter.svg';
export { default as WhatsappIcon } from './blog/ic_whatsapp.svg';
export { default as LogoItau } from './blog/logo-itau-varejo-desktop.png';
export { ReactComponent as PlayCarouselIcon } from './blog/play.svg';
export { ReactComponent as PauseCarouselIcon } from './blog/pause.svg';
export { default as ImgBkc } from './blog/background-carousel.png';

export { ReactComponent as LeftSlide } from './blog/left.svg';
export { ReactComponent as RightSlide } from './blog/right.svg';

export { ReactComponent as SetaDireita } from './blog/newBlogIcons/setaDireita.svg';
export { ReactComponent as SetaEsquerda } from './blog/newBlogIcons/setaEsquerda.svg';
export { ReactComponent as SetaBaixo } from './blog/newBlogIcons/setaBaixo.svg';
