import { GetAddressByCepResponse } from "../../../../data/models/autov2/get-address-by-cep-response";
import { ViaCepResponseContract } from "../../../../domain/entities/autov2/via-cep-response-contract";

export abstract class ViaCepResponseContractDTO {
    public static translate(model: ViaCepResponseContract): GetAddressByCepResponse {
        const map: GetAddressByCepResponse = {
            cep: model.cep,
            street: model.logradouro,
            district: model.bairro,
            city: model.localidade,
            state: model.uf,
        };

        return map;
    }
}
