import { rest } from 'msw';

export const hiring = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/contratacoes/assistencia-pet`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        id_contratacao: '123456',
        numero_contratacao: '1337',
        data_contratacao: '01/01/0001',
        estado: 'Success',
      })
    )
  );
};
