import { BoxProps } from '@material-ui/core';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function BoxWeb({ children, ...otherProps }: BoxProps) {
  const { Components } = useThemeProvider();
  const { Box } = Components;

  return (
    <ThemeProviderInjector>
      <Box {...otherProps}>{children}</Box>
    </ThemeProviderInjector>
  );
}
