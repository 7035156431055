import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { useStepProvider } from '../../../../providers/step-provider';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { useEffect, useState } from 'react';
import { DriverCpfStep } from './driver-cpf-step';
import { EnvironmentContextProps, useEnvironment, useRemoteConfigConsumer } from '@workspace/providers';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { sendLeadFactory } from '../../../../factories/send-lead-factory';
import { useOriginContext } from '../../../../providers/origin-provider';
import { eligibilityFactory } from '../../../../factories/eligibility-factory';

const DriverCpfStepContainer = () => {
  const stepProvider = useStepProvider();
  const [helpImagePath, setHelpImagePath] = useState<string>();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const networkStyleService = networkStyleFactory(partialEnvironment);
  const { environment } = partialEnvironment;
  const [leadChannel, setLeadChannel] = useState<string>();
  const [eligibleError, setEligibleError] = useState<boolean>(false);
  const config = useRemoteConfigConsumer();
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const eligibilityUsecase = eligibilityFactory({ partialEnvironment });
  const initImages = async () => {
    const cachedNetworkStyle: NetworkStyle = await networkStyleService.execute(origin);
    const helpImage = cachedNetworkStyle?.images.find((image) => image.name === 'autov2-help');
    setHelpImagePath(helpImage?.file?.url);
  };

  useEffect(() => {
    initImages();
    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name }: { channel_name: string } = await networkStyleUsecase.execute(origin);
      setLeadChannel(channel_name);
    })();
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const onSubmit = async (data, cartFlag) => {
    try {
      const res = await eligibilityUsecase.execute({
        nome: data.name,
        cpf: data.cpf,
      });

      if (res?.data?.elegibilidade?.[0]?.elegivel) {
        AutoStateManager.profileStepDispatcher(data.cpf, cartFlag, data.name, data.cellphone);
        autoV2analyticsCalls.HIRING.PROFILE.CONTINUE();
        stepProvider.handleNext();
      }
      throw new Error('Not eligible');
    } catch (e) {
      console.error(e);
      setEligibleError(true);
    }
  };

  const initialValues = AutoStateManager.profileStepSelector();

  useEffect(() => {
    autoV2analyticsCalls.HIRING.PROFILE.LOAD();
    autoV2analyticsCalls.HIRING.PROFILE.RECEIVE_CONTACT_BY_PHONE(true);
  }, []);

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });

  function handleCreateCartToggle(shoudCreateCat: boolean): void {
    autoV2analyticsCalls.HIRING.PROFILE.RECEIVE_CONTACT_BY_PHONE(shoudCreateCat);
  }

  return (
    <DriverCpfStep
      initialValues={initialValues}
      onSubmit={onSubmit}
      onBack={handleBack}
      helpImagePath={helpImagePath}
      sendLeadUsecase={sendLeadUsecase}
      handleCreateCartToggle={handleCreateCartToggle}
      template={{
        lgpd: 'Os dados inseridos por você poderão ser compartilhados com empresas parceiras para cotação e contratação dos produtos escolhidos e poderão ser tratados de acordo com nossa Política de Privacidade disponível em nossos sites e aplicativos',
      }}
      state={{
        error: {
          message: eligibleError
            ? 'Por razão dos critérios de análise desta corretora e de acordo com os termos da legislação vigente, não podemos seguir com a proposta de contratação do Seguro Automóvel que você solicitou na Loja de Seguros Itaú.'
            : '',
        },
        name: { disabled: !!eligibleError },
        cpf: { disabled: !!eligibleError },
        cellphone: { disabled: !!eligibleError },
        continueButton: { disabled: !!eligibleError },
      }}
      config={config}
    />
  );
};

export default function DriverCpfStepContainerWrapper() {
  return <DriverCpfStepContainer />;
}

