export default function mapper<T>(obj, schema): T | {} {
  let mappedObject = {};
  for (let key in obj) {
    if (obj[key] && schema[key]) {
      mappedObject[schema[key]] = obj[key];
    }
  }
  return mappedObject;
}

