import { PostHttpClientContract } from "../contracts/post-http-client.contract";
import { FetchApiHttpClient } from "./fetch-api.http-client";

export class PostFetchApiHttpClient extends FetchApiHttpClient implements PostHttpClientContract {
    public async execute<TPayload>(input: TPayload): Promise<void> {
        console.log('realizando post com o fetch api');

        await fetch('', { 
            method: 'post',
            headers: {
                ...this.commomHeaders,
            }
        });
    }
}
