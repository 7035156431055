import {
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  assembleAnalyticsPageLoadEvent,
  PageLoadAnalyticsInterface,
} from '@workspace/frontend-shared-application-analytics';

type P = 'P1' | 'P2' | 'P3' | 'P4' | 'P5' | 'P6' | 'P7' | 'P8';
export const pages: Record<P, string> = {
  P1: 'LS:NL:NCC:Cadastro:Cpf',
  P2: 'LS:NL:NCC:Cadastro:UsuarioJaCadastrado',
  P3: 'LS:NL:NCC:Cadastro:Correntista:IdentificacaoDosDados',
  P4: 'LS:NL:NCC:Cadastro:Correntista:Codigo',
  P5: 'LS:NL:NCC:Cadastro:DadosPessoais',
  P6: 'LS:NL:NCC:Cadastro:CriarSenha',
  P7: 'LS:NL:NCC:Cadastro:Validacao',
  P8: 'LS:NL:NCC:Cadastro:Sucesso',
};

export type PageLoadAuthRegisterTechSpecI = (options: {
  origin?: string;
  product?: string;
}) => Record<P, PageLoadAnalyticsInterface>;

export const PageLoadAuthRegisterTechSpec: PageLoadAuthRegisterTechSpecI = (options) => ({
  P1: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P1,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P2: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P2,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P3: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P3,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P4: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P4,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P5: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P5,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P6: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P6,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P7: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P7,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
  P8: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.P8,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      options
    ),
  },
});
