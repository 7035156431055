import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { searchStates } from './states-mock';
import { searchCities } from './cities-mock';
import { offerSearch } from './offers-search-mock';
import { searchDomain } from './domain-mock';
import { searchAssistances } from './simulations-mock';
import { authenticateSms } from './authenticate-sms-mock';
import { authenticateSmsToken } from './authenticate-sms-token-mock';
import { channelsSearch } from './channels-search-mock';
import { productLeads } from './product-leads-mock';
import { session } from './session-mock';
import { searchAddress } from './search-address-mock';
import { eligibilityMock } from './eligibility-mock';
import { authenticationV2 } from './authentication-v2-mock';
import { checkCard } from './check-card-mock';
import { sendCancelTokenMock } from './sent-cancel-token-mock';

import { eligibility2Mock } from './eligibility2-mock';

export const sharedMockHandlers = (env): RestHandler[] => {
  return [
    searchStates(env),
    searchCities(env),
    offerSearch(env),
    searchDomain(env),
    searchAssistances(env),
    authenticateSms(env),
    authenticateSmsToken(env),
    channelsSearch(env),
    productLeads(env),
    session(env),
    sendCancelTokenMock(env),
    searchAddress(env),
    eligibilityMock(env),
    eligibility2Mock(env),
    authenticationV2(env),
    checkCard(env),
  ];
};
