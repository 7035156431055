import { NetworkStyle } from '../../domain/entities/autov2/networkStyle';
import { INetworkStyleUsecase } from '../../domain/usecases/network-style-usecase';
import { INetworkStyleRepository } from '../contracts/autov2/network-style-repository';

export class NewtorkStyleUsecase implements INetworkStyleUsecase {
  constructor(private readonly networkStyleRepository: INetworkStyleRepository) {}
  async execute(partner?: any): Promise<NetworkStyle> {
    return await this.networkStyleRepository.execute(partner);
  }
}
