import TextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core';
import { StyleAdapterTypes, StyleBuilder } from '../base/style-builder';
import { ButtonMui } from './button-mui';
import { OutlinedButtonMui } from './outlined-button-mui';
import { InputMui } from './input-mui';
import { SelectMui } from './select-mui';
import { SwitchMui } from './switch-mui';
import { StepperMui } from './stepper-mui';
import { StepperProgressMui } from './stepper-progress-mui';
import { ContainerMui } from './container-mui';
import { TypographyMui } from './typography-mui';
import { InputMaskMui } from './input-mask-mui';
import { BoxMui } from './box-mui';
import { InfoBoxMui } from './info-box-mui';
import { TooltipMui } from './tooltip-mui';
import { AccordionMui } from './accordion-mui';
import { NavigateButtonsMui } from './navigate-buttons-mui';
import { LinkButtonMui } from './link-button-mui';
import { ModalMui } from './modal-mui';
import { LinkMui } from './link-mui';
import { BreadCrumbsMui } from './breadcrumb-mui';
import { HiddenMui } from './hidden-mui';
import { TextAreaMui } from './text-area-mui';
import { RadioGroupMui } from './radio-group-mui';
import { CheckBoxMui } from './checkbox-mui';
import { AlertMui } from './alert-mui';
import { ToastMui } from './toast-mui';
import { DialogMui } from './dialog-mui';
import { DialogTitleMui } from './dialog-title-mui';
import { DialogContentMui } from './dialog-content-mui';
import { DialogActionsMui } from './dialog-actions-mui';
import { PasswordMui } from './password-mui';
import { ThemeProviderMui } from './theme-provider-mui';
import { LoadingMui } from './loading-mui';
import { DropdownHeaderMui } from './dropdown-header-mui';

export const currentheme = StyleBuilder.adapter(StyleAdapterTypes.LOJA_DE_SEGUROS).builder();
export const LojaDeSegurosUiComponents = {
  Button: ButtonMui,
  Switch: SwitchMui,
  TextField,
  createTheme,
  Dialog: DialogMui,
  DialogTitle: DialogTitleMui,
  DialogActions: DialogActionsMui,
  DialogContent: DialogContentMui,
  Input: InputMui,
  Select: SelectMui,
  OutlinedButton: OutlinedButtonMui,
  Stepper: StepperMui,
  StepperProgress: StepperProgressMui,
  Container: ContainerMui,
  Typography: TypographyMui,
  MaskedInput: InputMaskMui,
  Box: BoxMui,
  Tooltip: TooltipMui,
  InfoBox: InfoBoxMui,
  Accordion: AccordionMui,
  NavigateButtons: NavigateButtonsMui,
  LinkButton: LinkButtonMui,
  Modal: ModalMui,
  Link: LinkMui,
  Breadcrumbs: BreadCrumbsMui,
  Hidden: HiddenMui,
  TextArea: TextAreaMui,
  RadioGroup: RadioGroupMui,
  CheckBox: CheckBoxMui,
  Alert: AlertMui,
  Toast: ToastMui,
  Password: PasswordMui,
  ThemeProvider: ThemeProviderMui,
  Loading: LoadingMui,
  DropdownHeader: DropdownHeaderMui,
};
