import { CMSResponse } from '@core/data/models/autov2/cmsResponse';
import { INetworkStyleRepository } from '@core/data/contracts/autov2/network-style-repository';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { CachedNetworkStyle } from './cached-network-style';

export class NetworkStyleRepository extends CachedNetworkStyle implements INetworkStyleRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, CMSResponse<NetworkStyle[]>>) {
    super();
  }

  async execute(partner: string): Promise<NetworkStyle> {
    if (!this.networkStyleCache) {
      const response: CMSResponse<NetworkStyle[]> = await this.httpGetClient.get(null);
      if (response) {
        const usedNetorkStyle: NetworkStyle = response.data.entries.find(
          (styledChannel) => styledChannel.partner === partner
        );
        this.networkStyleCache = usedNetorkStyle;
        return usedNetorkStyle;
      }
    } else {
      return this.networkStyleCache;
    }

    return null;
  }
}
