import { CreateCartAppServiceContractInput } from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import { CreateCartPayload } from '@workspace/utils/abandoned-cart';

export abstract class CreateCartStrategySerializer {
    abstract specializedExecute(input: CreateCartAppServiceContractInput): CreateCartPayload;

    public execute(input: CreateCartAppServiceContractInput): CreateCartPayload {
        return this.specializedExecute(input);
    }
}
