import { useEffect, useRef, useState } from 'react';
import { Product } from '@workspace/frontend/faq-v2/domain/entities';
import { HorizontalList } from './horizontal-list';

export function HorizontalListContainer({
  items = [],
  onClick,
  selectedCard,
}: {
  items: Product[];
  onClick: any;
  selectedCard: Product;
}) {
  const [selectedBullet, setSelectedBullet] = useState<number>(0);
  const [scrollTotal, setScrollTotal] = useState<number>(0);
  const [activeBullet, setActiveBullet] = useState<number>(0);
  const [amountBullets, setAmountBullets] = useState(0);
  const bulletActived = useRef(null);
  let bullets;

  useEffect(() => {
    bullets = getAmountBullets(items);
    hideArrows();
  }, []);

  const getElement = (element) => {
    return document.querySelector(`${element}`);
  };

  const getAmountBullets = (itemList) => {
    const isFieldVisible =
      getElement('.card-box').clientWidth * itemList.length < getElement('.products-section').clientWidth;
    let amountBullets =
      Math.round(
        (getElement('.card-box').clientWidth * itemList.length) / getElement('.products-section').clientWidth
      ) + 1;

    if (document.body.clientWidth <= 768 && isFieldVisible) {
      amountBullets -= 1;
    }

    setAmountBullets(amountBullets);

    return amountBullets;
  };

  const hideArrows = () => {
    const isFieldVisible =
      getElement('.card-box').clientWidth * items.length < getElement('.products-list').clientWidth;
    if (document.body.clientWidth <= 768 || isFieldVisible) {
      getElement('.scrollball').remove();
      getElement('.box-advance-arrow').remove();
      getElement('.box-back-arrow').remove();
    } else if (bullets > 1) {
      getElement('.back-arrow').setAttribute('hidden', 'true');
    }
  };

  const handleActivedBullet = (bullet: number) => {
    setActiveBullet(bullet);
    if (bullet !== selectedBullet) {
      let scrollTarget = 0;
      const diffBullet = bullet - selectedBullet;

      scrollTarget = getElement('.products-section').clientWidth * diffBullet;
      getElement('.products-list').scrollBy(scrollTarget, 0);

      setSelectedBullet(bullet);
      setScrollTotal(scrollTarget);
    }

    const firstBullet = parseInt(getElement('.scrollball').firstElementChild.id.substring(7), 10);
    const lastBullet = parseInt(getElement('.scrollball').lastElementChild.id.substring(7), 10);

    if (document.body.clientWidth > 768) {
      lastBullet === bullet || firstBullet === bullet ? activeSelectedArrow(bullet) : activeArrow();
    }
  };

  function activeArrow() {
    getElement('.back-arrow').removeAttribute('hidden');
    getElement('.advance-arrow').removeAttribute('hidden');
  }

  function activeSelectedArrow(bullet: number) {
    if (parseInt(getElement('.scrollball').lastElementChild.id.substring(7), 10) === bullet) {
      getElement('.advance-arrow').setAttribute('hidden', 'true');
      getElement('.back-arrow').removeAttribute('hidden');
    } else {
      getElement('.back-arrow').setAttribute('hidden', 'true');
      getElement('.advance-arrow').removeAttribute('hidden');
    }
  }

  return (
    <HorizontalList
      items={items}
      selectedCard={selectedCard}
      onClick={onClick}
      activeBullet={activeBullet}
      handleActivedBullet={handleActivedBullet}
      bulletActived={bulletActived}
      amountBullets={amountBullets}
    />
  );
}
