import { rest } from 'msw';

//return new Response(442, { some: 'header' }, { erro: true })
interface searchAddressRequestProps {
  cep: any;
}

const erroResponseCep = { erro: true };

export const searchAddress = (env) => {
  return rest.get<searchAddressRequestProps>(
    `${env.gatewayAWSBaseURL.url}/v1/codigo-enderecamento-postal/endereco`,
    (req, res, ctx) => {
      const cep = req.url.searchParams.get('cep');
      if (cep === '13101-400') {
        return res(ctx.status(200), ctx.json(erroResponseCep), ctx.delay(200));
      }
      if (cep === '13101-401') {
        return res(ctx.status(400), ctx.json(erroResponseCep));
      }
      return res(
        ctx.status(200),
        ctx.json({
          cep: '13474-181',
          logradouro: 'Rua João Pincelli',
          complemento: '',
          bairro: 'Jardim Brasil',
          localidade: 'Americana',
          uf: 'SP',
          ibge: '3501608',
          gia: '1650',
          ddd: '19',
          siafi: '6131',
        })
      );
    }
  );
};
