import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function StepperProgressWeb(props) {
  const { Components } = useThemeProvider();
  const { StepperProgress } = Components;

  return (
    <ThemeProviderInjector>
      <StepperProgress {...props} />
    </ThemeProviderInjector>
  );
}
