import palette from '../loja-de-seguros/palette';

export default {
  MuiLink: {
    root: {
      color: '#106EB0',
      textDecoration: 'underline',
      fontFamily: 'ItauText-Regular, sans-serif',

      '&.MuiLink-underlineHover': {
        textDecoration: 'underline',
      },
    },
  },
  MuiFormControl: {
    root: {
      '& label + .MuiInput-formControl': {
        marginTop: '24px',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.text.primary,

      '&$error': {
        color: '#781B8E',
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: '#000',
      lineHeight: 'normal',
      '&$focused': {
        color: '#000000',
      },

      '&$error': {
        color: '#781B8E',
        fontWeight: '700',
      },
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '18px'
    },
  },
  MuiInput: {
    root: {
      '&$focused': {
        outline: '2px solid #106EB0',
      },
    },
    input: {
      height: 'auto',
      lineHeight: '22px',
      padding: '14px',
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    underline: {
      '&:after': {
        display: 'none',
      },

      '&:not(.Mui-disabled):before': {
        borderBottom: '2px solid #ACACAC',
      },

      '&:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid #ACACAC',
      },

      '&$error:not(.Mui-disabled):before': {
        borderColor: '#781B8E',
      },
    },
  },
  MuiButton: {
    text: {
      fontWeight: '700',

      '&:disabled, &.Mui-disabled': {
        backgroundColor: '#D9D3CF',
        color: '#969290',
      },
    },
    outlined: {
      fontWeight: '700',
    },
    label: {
      textTransform: 'initial',
    },
  },
  MuiCollapse: {
    container: {
      boxSizing: 'border-box',
    },
  },
  MuiAlert: {
    root: {
      position: 'absolute',
      boxSizing: 'border-box',
      padding: '24px max(15px, 6%)',
      fontSize: '16px',
      lineHeight: '24px',
      width: '100%',
    },
    message: { padding: '0' },
    icon: { padding: '0', fontSize: '24px' },

    standardError: {
      backgroundColor: '#B71C1C',
      color: 'white',
      '& .MuiAlert-icon': {
        color: 'white',
      },
    },

    standardWarning: {
      backgroundColor: '#781B8E',
      color: 'white',
      '& .MuiAlert-icon': {
        color: 'white',
      },
    },
  },
};
