export const stepEffectTypes = {
  profileStepEffect: 'EFFECT Auto/hiring-profile',
  itemRiskStepEffect: 'EFFECT Auto/hiring-itemRisk',
  addressStepEffect: 'EFFECT Auto/hiring-address',
  additionalDataEffect: 'EFFECT Auto/hiring-additionalData',
  paymentEffect: 'EFFECT Auto/hiring-payment',
  testABHiringEffect: 'EFFECT Auto/testABHiring',
  userCpfEffect: 'EFFECT Auth/cpf',
  userEmailEffect: 'EFFECT Auth/email',
  userRegisterEffect: 'EFFECT Auth/register',
  userEffect: 'EFFECT Auth/user',
  userAuthenticatedEffect: 'EFFECT Auth/authenticated',
  registrationCpfEffect: 'EFFECT Auth/cpf',
  registrationEmailEffect: 'EFFECT Auth/email',
  registrationUserInformationEffect: 'EFFECT Auth/register',
  registrationStepEffect: 'EFFECT Auth/registrationStep',
  hospitauContextEffect: 'EFFECT MyAccount/hospitauContext',
};
