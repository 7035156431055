import { cloneElement, useContext } from 'react';
import { StepperContext } from './stepper-context';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { Step } from './step';

export function StepsContentController({ steps = [], hideSteps = false }: { steps: Step[]; hideSteps?: boolean }) {
  const { handleBack, handleNext, currentStep, changeStep } = useContext(StepperContext);

  const _handleBack = () => handleBack;
  const _handleNext = () => handleNext;

  const StepContent = (props, ref) => (
    <BoxWeb id="stepper-content">
      {cloneElement(steps[currentStep]?.component(), {
        handleNext: _handleNext,
        handleBack: _handleBack,
        stepName: steps[currentStep]?.title || '',
      })}
    </BoxWeb>
  );

  return <StepContent />;
}
