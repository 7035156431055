import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

export const ContainerMui = withStyles(({ breakpoints }) => ({
  root: {
    maxWidth: breakpoints.values.lg,
    width: 'initial',
    padding: '0 2rem',
    margin: '3rem auto',
    [breakpoints.down('sm')]: {
      width: '100%',
      boxSizing: 'border-box',
      overflowX: 'hidden',
    },
  },
}))(Box);
