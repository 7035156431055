import { ArticleBlog } from "@workspace/frontend/blog/domain/entities";
import { IGetArticleBlogRepository } from "@workspace/frontend/blog/domain/contracts/repositories";
import { IGetArticleBlogUseCase } from "@workspace/frontend/blog/domain/contracts/use-cases";

export class GetArticleBlogUseCase implements IGetArticleBlogUseCase {
  constructor(private readonly getArticleBlogRepository: IGetArticleBlogRepository) {}

  async execute(): Promise<ArticleBlog> {
   return await this.getArticleBlogRepository.execute();
  };
};
