import { PageLoadAutoV2HiringTechSpec as HiringPageLoadTagsWithoutOrigin } from './page-load-tags';
import {
  ActionEventTags as HiringActionTagsWithoutOrigin,
  ActionsAutoV2HiringLabelCreators,
  ActionsAutoV2HiringLabelCreators as HiringActionLabelsCreator,
} from './action-event-tags';
import { registerAnalyticsEvent } from '@workspace/web/analytics';

const debug = false;
let RAE;

if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const HiringAnalytics = (origin) => {
  const HiringActionTags = HiringActionTagsWithoutOrigin(origin);
  const HiringPageLoadTags = HiringPageLoadTagsWithoutOrigin(origin);

  return {
    PROFILE: {
      LOAD: () => RAE(HiringPageLoadTags.T1),
      CONTINUE: () => RAE(HiringActionTags.A1()),
      RECEIVE_CONTACT_BY_PHONE: (shouldReceiveContactByPhone: boolean) =>
        RAE(HiringActionTags.A22(ActionsAutoV2HiringLabelCreators.A22(shouldReceiveContactByPhone))),
    },
    VEHICLE: {
      LOAD: () => RAE(HiringPageLoadTags.T2),
      CONTINUE: (zeroKm: boolean, classBonus: boolean) => {
        RAE(HiringActionTags.A2(HiringActionLabelsCreator.A2(zeroKm, classBonus)));
      },
      PLATE_NOT_FOUND: () => RAE(HiringActionTags.A19()),
      EDIT: () => RAE(HiringActionTags.A20()),
    },
    ADDRESS: {
      LOAD: () => RAE(HiringPageLoadTags.T3),
      CONTINUE: () => RAE(HiringActionTags.A3()),
    },
    ERROR: {
      LOAD: () => RAE(HiringPageLoadTags.T7),
      BACK: () => RAE(HiringActionTags.A10()),
      CONTINUE: () => RAE(HiringActionTags.A11()),
    },
    QUOTATION: {
      LOAD: () => RAE(HiringPageLoadTags.T4),
      TAB: (betterPrice: boolean) => {
        RAE(HiringActionTags.A4(HiringActionLabelsCreator.A4(betterPrice)));
      },
      ACCORDION: (betterPrice: boolean, detailName, isShowing: boolean) => {
        RAE(HiringActionTags.A5(HiringActionLabelsCreator.A5(betterPrice, detailName, isShowing)));
      },
      CONTINUE: (franchise: 50 | 100, betterPrice: boolean) =>
        RAE(HiringActionTags.A6(HiringActionLabelsCreator.A6(franchise, betterPrice))),
      REDO: () => RAE(HiringActionTags.A7()),
      MODAL: {
        LOAD: () => RAE(HiringPageLoadTags.T5),
        CONTINUE: () => RAE(HiringActionTags.A8()),
      },
    },
    ADDITIONAL_DATA: {
      LOAD: () => RAE(HiringPageLoadTags.T8),
      BACK: () => RAE(HiringActionTags.A12()),
      CONTINUE: () => RAE(HiringActionTags.A13()),
    },
    PAYMENT: {
      LOAD: () => RAE(HiringPageLoadTags.T9),
      BACK: () => RAE(HiringActionTags.A15()),
      SUMMARY: () => RAE(HiringActionTags.A14()),
      CONTINUE: () => RAE(HiringActionTags.A16()),
    },
    INSPECTION: {
      LOAD: () => RAE(HiringPageLoadTags.T10),
      BACK: () => RAE(HiringActionTags.A17()),
    },
    HIRING_SUCCESS: {
      LOAD: () => {
        RAE(HiringPageLoadTags.T11);
        RAE(HiringActionTags.A18());
      },
    },
    SUCCESS: {
      LOAD: (hasCotation: boolean) => {
        if (hasCotation) {
          RAE(HiringPageLoadTags.T6);
          RAE(HiringActionTags.A9());
        } else {
          RAE(HiringPageLoadTags.T12);
          RAE(HiringActionTags.A21());
        }
      },
    },
  };
};
