import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { GetUserInfoRepository } from '@core/infra/repository/my-account/get-user-info-repository';
import { GetUserInfoUsecase } from '@core/data/services/get-user-info-usecase';
import { GetUserInfoResponse } from '@core/domain/entities/my-account/response/get-user-info-response';

export function getUserInfoFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<object, GetUserInfoResponse> = new AxiosHttpGetClient<
    object,
    GetUserInfoResponse
  >('dadosusuario', {}, environment.gatewayBFFBaseURL.url);

  const getUserInfoRepository = new GetUserInfoRepository(httpGetClient);
  const getUserInfoUsecase = new GetUserInfoUsecase(getUserInfoRepository);
  return getUserInfoUsecase;
}
