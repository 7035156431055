import { StepProvider } from '../../providers/step-provider';
import HiringWrapper from './hiring-wrapper';
import { AutoV2Theme } from '../../components/auto-v2-theme/auto-v2-theme';
import { AutoV2OriginProvider } from '../../providers/origin-provider';
import { RecaptchaDOMInjector } from '@workspace/frontend/shared/application/injectors';
import { RemoteConfigProvider } from '@workspace/providers';

export const AutoV2HiringCarrier = ({ partner }) => {
  return (
    <AutoV2OriginProvider partner={partner}>
      <AutoV2Theme partner={partner}>
        <StepProvider>
          <RemoteConfigProvider>
            <RecaptchaDOMInjector />
            <HiringWrapper />
          </RemoteConfigProvider>
        </StepProvider>
      </AutoV2Theme>
    </AutoV2OriginProvider>
  );
};
