import { AuthTheme } from '../../components/auth-theme';
import { AuthOriginProvider } from '../../providers/auth-origin-provider';
import { AuthenticationParamsProvider } from '../../providers/authentication-params-provider';
import { ErrorProvider } from '../../providers/error-provider';
import { TokenConfirmation } from './token-confirmation';
import { AuthProductProvider } from '../../providers/auth-product-provider';

export const ConfirmAuthToken = ({
  origin,
  product,
  text,
  onsubmitcallbackname,
}: {
  origin?: string;
  product?: string;
  text?: string;
  onsubmitcallbackname?: string;
}) => {
  const createCallBackFromEvent = (eventName: string, params, onSuccessCallback, onFailureCallback) => {
    const evt = new CustomEvent(eventName, { detail: { params, onSuccessCallback, onFailureCallback } });
    window.dispatchEvent(evt);
  };
  const onSubmitCallback = ({ token, email }, handleSuccess, handleFailure) =>
    createCallBackFromEvent(onsubmitcallbackname, { token, email }, handleSuccess, handleFailure);

  return (
    <AuthOriginProvider origin={origin}>
      <AuthProductProvider product={product}>
        <AuthenticationParamsProvider>
          <AuthTheme>
            <ErrorProvider>
              <TokenConfirmation subtitle={text} onSubmitCallback={onsubmitcallbackname && onSubmitCallback} />
            </ErrorProvider>
          </AuthTheme>
        </AuthenticationParamsProvider>
      </AuthProductProvider>
    </AuthOriginProvider>
  );
};
