import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { GetUserByEmailRepository } from '@core/infra/repository/auth/get-user-by-email-repository';
import { GetUserByEmailUsecase } from '@core/data/services/get-user-by-email-usecase';
import { UserAuthResponse } from '@core/domain/entities/auth/response/user';
import { UserGetByEmail } from '@core/infra/models/user-get-by-email';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

export function getUserByEmailFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<{ params: UserGetByEmail }, UserAuthResponse> = new AxiosHttpGetClient<
    { params: UserGetByEmail },
    UserAuthResponse
  >('usuarios', {}, environment.gatewayBFFBaseURL.url);
  const getUserByEmailRepository = new GetUserByEmailRepository(httpGetClient);
  const getUserByEmailUseCase = new GetUserByEmailUsecase(getUserByEmailRepository);
  return getUserByEmailUseCase;
}
