import { rest } from 'msw';

export const cancellationMock = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/cancelamento`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        protocolo: '40648120200508000002',
      })
    );
  });
};
