import { InstallmentsQueryRequest } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryRequest';
import { InstallmentsQueryResponse } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryResponse';
import { IInstallmentsQueryRepository } from '@workspace/frontend/auto/infra/repositories';

export interface IInstallmentsQueryUseCase {
  execute(data: InstallmentsQueryRequest): Promise<InstallmentsQueryResponse[]>;
}

export class InstallmentsQueryUseCase implements IInstallmentsQueryUseCase {
  constructor(private readonly installmentsQueryRepository: IInstallmentsQueryRepository) {}

  async execute(data: InstallmentsQueryRequest): Promise<InstallmentsQueryResponse[]> {
    return await this.installmentsQueryRepository.execute(data);
  }
}
