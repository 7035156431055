import styled from 'styled-components';

export const BoxSteps = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1rem 2rem;
  @media (max-width: ${(props) => props.theme?.breakpoints?.values.sm}px) {
    overflow-x: hidden;
  }
  @media (min-width: ${(props) => props.theme?.breakpoints?.values.sm}px) {
    max-width: ${(props) => props.theme?.breakpoints?.values.lg}px;
    width: initial;
    padding: 3rem 2rem;
    overflow: auto;
  },
`;
