import { Route } from 'react-router-dom';

type oringProduct = {
  origin: string;
  product: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'auth-login': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & oringProduct;
      'auth-register': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & oringProduct;
      'auth-registered-user': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & oringProduct;
      'auth-confirm-token': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & oringProduct;
      'auth-password-recovery': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & oringProduct;
    }
  }
}

const AuthLogin = () => <auth-login origin={'local'} product={'local'} />;
const AuthRegister = () => <auth-register origin={'local'} product={'local'} />;
const AuthRegisteredUser = () => <auth-registered-user origin={'local'} product={'local'} />;
const AuthConfirmToken = () => <auth-confirm-token origin={'local'} product={'local'} />;
const AuthPasswordRecovery = () => <auth-password-recovery origin={'local'} product={'local'} />;

const LoginRoute = ({ prefixPath }) => <Route exact path={`${prefixPath}/entrar`} render={() => <AuthLogin />} />;
const RegisterRoute = ({ prefixPath }) => (
  <Route exact path={`${prefixPath}/cadastrar`} render={() => <AuthRegister />} />
);
const RegisteredUserRoute = ({ prefixPath }) => (
  <Route exact path={`${prefixPath}/usuario-existente`} render={() => <AuthRegisteredUser />} />
);
const ConfirmTokenRoute = ({ prefixPath }) => (
  <Route exact path={`${prefixPath}/confirmar-codigo`} render={() => <AuthConfirmToken />} />
);
const PasswordRecoveryRoute = ({ prefixPath }) => (
  <Route exact path={`${prefixPath}/recuperar-senha`} render={() => <AuthPasswordRecovery />} />
);

export const AuthV2Router = function ({ prefixPath = '/v2/autenticacao' }) {
  return (
    <>
      <LoginRoute prefixPath={prefixPath} />
      <RegisterRoute prefixPath={prefixPath} />
      <RegisteredUserRoute prefixPath={prefixPath} />
      <ConfirmTokenRoute prefixPath={prefixPath} />
      <PasswordRecoveryRoute prefixPath={prefixPath} />
    </>
  );
};
