import { PartnersEnum } from '@core/data/enums/partners-enum';
import {
  ComponentAdapterTypes,
  ComponentMode,
  StyleAdapterTypes,
  StyleMode,
  ThemeProviderWeb,
} from '@workspace/frontend/shared/presentation/components';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { useEffect } from 'react';
import { networkStyleFactory } from '../../factories/network-style-factory';

export function AutoV2Theme({ children, partner = PartnersEnum.LOJA_DE_SEGUROS }: { children: any; partner: any }) {
  const environment: Partial<EnvironmentContextProps> = useEnvironment();
  const networkStyleUsecase = networkStyleFactory(environment);

  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.LOJA_DE_SEGUROS,
  };

  const fetchNetworkStyle = async () => {
    return await networkStyleUsecase.execute(partner);
  };
  useEffect(() => {
    fetchNetworkStyle();
  }, []);

  return (
    <ThemeProviderWeb
      rootName="autoV2"
      componentMode={componentMode}
      styleMode={styleMode}
      networkStyleService={fetchNetworkStyle}
    >
      {children}
    </ThemeProviderWeb>
  );
}
