import { rest } from 'msw';

export const offerAssist = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/ofertas/assistencia-pet`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        id_oferta: '45',
        nome: 'assistência Realiza pet',
        produtos: [
          {
            id_produto: '62',
            id_parceiro: '67',
            nome: 'assistência Realiza pet',
            valor_mensal_minimo: 14.9,
            vigencia: {
              quantidade: 1,
              unidade: 'anos',
            },
            importancia_base: {
              incremento: 1,
              maximo: 999999999999,
              minimo: 0,
              valor_sugerido: [],
            },
            assistencias: [
              {
                id_assistencia: '34cd651d-e918-4df6-a9f8-038446b4df31',
                nome: 'Suporte veterinário',
                descricao: 'ilimitado.',
                resumo: 'suporte telefônico para os primeiros socorros do seu pet e indicação de clínicas veterinárias',
                assistencia_obrigatoria: true,
                identificador: 'suporte_veterinario',
                premio: 14.9,
              },
              {
                id_assistencia: 'a8c4af69-d8c2-459e-a9c0-8b093504bfcc',
                nome: 'Consulta emergencial',
                descricao: 'até 2 utilizações por ano limitado a R$250,00 por intervenção.',
                resumo: 'consultas veterinárias em nossa rede credenciada',
                assistencia_obrigatoria: true,
                identificador: 'consulta_emergencial',
                premio: 0.0,
              },
              {
                id_assistencia: 'f8ad6c96-1e83-41bc-8e05-9283392e7836',
                nome: 'Vacina e aplicação',
                descricao: '1 utilização de cada vacina mencionada.',
                resumo: 'assistência de vacinação para o pet',
                assistencia_obrigatoria: false,
                identificador: 'vacina_e_aplicacao',
                premio: 7.0,
              },
              {
                id_assistencia: 'e556bb1e-639f-49f8-9d9e-157176fa65ad',
                nome: 'Hospedagem Pet',
                descricao: '2 intervenções limitados a R$ 250,00 para cada intervenção.',
                resumo: 'hospedagem em hotéis nas redes credenciadas',
                assistencia_obrigatoria: false,
                identificador: 'hospedagem_pet',
                premio: 10.0,
              },
              {
                id_assistencia: '240cff51-78ef-4bad-8be5-44811dc8a165',
                nome: 'Castração Pet',
                descricao: '1 intervenção (até R$300,00).',
                resumo: 'assistência para cirurgia de castração',
                assistencia_obrigatoria: false,
                identificador: 'castracao_pet',
                premio: 10.0,
              },
              {
                id_assistencia: '1c325b4c-ca1b-4201-92cd-a79f2687c7aa',
                nome: 'Funeral Pet',
                descricao: '1 utilização (até R$ 700,00).',
                resumo: 'auxilio na organização e custos do funeral',
                assistencia_obrigatoria: false,
                identificador: 'funeral_pet',
                premio: 5.0,
              },
            ],
          },
        ],
      })
    )
  );
};
