import { AuthTheme } from '../../components/auth-theme';
import { AuthenticationParamsProvider } from '../../providers/authentication-params-provider';
import { ErrorProvider } from '../../providers/error-provider';
import { RecoveryContainer } from './recovery-container';
import { AuthOriginProvider } from '../../providers/auth-origin-provider';
import { AuthProductProvider } from '../../providers/auth-product-provider';

export const PasswordRecovery = ({ origin, product }: { origin?: string; product?: string }) => {
  return (
    <AuthOriginProvider origin={origin}>
      <AuthProductProvider product={product}>
        <AuthTheme>
          <AuthenticationParamsProvider>
            <ErrorProvider>
              <RecoveryContainer />
            </ErrorProvider>
          </AuthenticationParamsProvider>
        </AuthTheme>
      </AuthProductProvider>
    </AuthOriginProvider>
  );
};
