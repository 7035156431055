import { FormControllerFieldConnector } from '@web/form';
import { SelectWeb, SelectWebProps } from '@workspace/frontend/shared/presentation/components';
import { numberValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';

export const PaymentDayField = ({
  id = 'txtPaymentDay_screen',
  label = 'dia do débito',
  options = [],
}: {
  id?: string;
  label?: string;
  options?: Array<{ value: string; key: number }>;
}): JSX.Element => (
  <FormControllerFieldConnector<SelectWebProps>
    component={SelectWeb}
    name={'paymentDay'}
    validation={numberValidationFactory({ requiredMessage: 'você precisa informar um dia para o pagamento' })}
    componentProps={{ id, label, items: options }}
  />
);
