/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { PageLoadBlogPostTechSpec as BlogPostLoadTags } from './Post/pages-blog-post-techspec';
import { ActionsBlogPostTechSpec as BlogPostActionsLoadTags } from './Post/action-blog-post-techspec';
import { registerAnalyticsEvent } from '../../analytics-config/dispatch-analytics-events';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const BlogPostAnalytics = {
  LOAD: (textValue) => RAE(BlogPostLoadTags.T1(textValue)),
  SHARE_CONTENT: (textValue) => RAE(BlogPostActionsLoadTags.A1(textValue)),
  SHARE_CONTENT_2: (textValue) => RAE(BlogPostActionsLoadTags.A2(textValue)),
  SATISFACTION: (textValue) => RAE(BlogPostActionsLoadTags.A3(textValue)),
};
