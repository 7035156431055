import { AccordionWeb, BoxWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import styled from 'styled-components';

const SummaryCardBorderMobile = styled.div`
  background-color: #212b3b;
`;

const SummaryCardContentMobile = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const SummaryCardHeaderItemMobile = styled.div`
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`;

const SummaryCardContentItemMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin: 0.25rem 0;
`;
const SummaryCardContentItemBoldLabelMobile = styled.span`
  font-family: ItauText-Bold;
  font-size: 18px;
`;

const SummaryCardContentItemRegularLabelMobile = styled.span`
  font-family: ItauText-Regular;
  font-size: 16px;
`;

const SummaryCardContentSuTitleItemRegularLabelMobile = styled.span`
  font-family: ItauText-Regular;
  font-size: 16px;
  text-align: right;
  max-width: 170px;
`;

const MainTextHeader = ({ text }: { text: string }) => (
  <BoxWeb color={'primary.main'} textAlign="center" fontSize="48px" fontFamily="ItauText-XBold" mx="auto">
    {text}
  </BoxWeb>
);

const SummaryCardHeader = ({ overtitle = '', title = '', subtitle = '' }: { overtitle: string; title: string; subtitle: string }) => {
  const fullText = `${overtitle} ${title} ${subtitle}`;
  return (
    <BoxWeb role={'heading'} aria-level={3} aria-label={fullText}>
      <BoxWeb
        height={'158px'}
        borderBottom={'1px solid lightgray'}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        fontSize="18px"
        fontFamily="ItauText-Light"
        aria-hidden
      >
        {overtitle && <BoxWeb>{overtitle}</BoxWeb>}
        <MainTextHeader text={title} />
        {subtitle && <BoxWeb textAlign="center">{subtitle}</BoxWeb>}
      </BoxWeb>
    </BoxWeb>
  );
};

const AccordionList = ({
  list,
}: {
  list: Array<{ list?: Array<string>; title?: string; renderContent?: JSX.Element }>;
}) => (
  <>
    {list?.map((item) => {
      if (item?.renderContent) {
        return <>{item?.renderContent}</>;
      }
      return (
        <AccordionWeb title={item?.title}>
          <BoxWeb component="ul" m={0} pl={3}>
            {item.list?.map((list) => (
              <li>{list}</li>
            ))}
          </BoxWeb>
        </AccordionWeb>
      );
    })}
  </>
);

const SummaryCardBody = ({
  list,
  bodyTitle = 'detalhes do pacote',
}: {
  list: Array<{ list?: Array<string>; title?: string; renderContent?: JSX.Element }>;
  bodyTitle?: string;
}) => (
  <BoxWeb height={'374px'} overflow={'auto'} padding={'1.5rem'} boxSizing={'border-box'}>
    <BoxWeb fontSize="22px" mb={2}>
      {bodyTitle}
    </BoxWeb>
    <AccordionList list={list} />
  </BoxWeb>
);

const SummaryCardBorder = ({ children }) => (
  <BoxWeb width={'384px'} height={'532px'} border={'1px solid lightgray'} borderRadius={'12px'}>
    {children}
  </BoxWeb>
);

export const SummaryCard = ({
  contentList = [],
  title,
  overtitle,
  subtitle,
}: {
  contentList: Array<{ list?: Array<string>; title?: string; renderContent?: JSX.Element }>;
  overtitle?: string;
  title: string;
  subtitle?: string;
}) => (
  <SummaryCardBorder>
    <SummaryCardHeader overtitle={overtitle} title={title} subtitle={subtitle} />
    <SummaryCardBody list={contentList} />
  </SummaryCardBorder>
);

export const SummaryCardMobile = ({
  contentList = [],
  title = '',
  overtitle,
  subtitle = '',
}: {
  contentList: Array<{ title: string; subtitle: string }>;
  overtitle?: string;
  title: string;
  subtitle?: string;
}) => (
  <SummaryCardBorderMobile>
    <SummaryCardContentMobile>
      <BoxWeb role={'heading'} aria-level={3} aria-label={`${title} ${subtitle}`}>
        <BoxWeb aria-hidden>
          <SummaryCardHeaderItemMobile>
            <SummaryCardContentItemBoldLabelMobile>{title}</SummaryCardContentItemBoldLabelMobile>
            <SummaryCardContentItemBoldLabelMobile>{subtitle}</SummaryCardContentItemBoldLabelMobile>
          </SummaryCardHeaderItemMobile>
        </BoxWeb>
      </BoxWeb>

      {contentList.map((itemList) => (
        <SummaryCardContentItemMobile>
          <SummaryCardContentItemRegularLabelMobile>{itemList.title}</SummaryCardContentItemRegularLabelMobile>
          <SummaryCardContentSuTitleItemRegularLabelMobile>
            {itemList.subtitle}
          </SummaryCardContentSuTitleItemRegularLabelMobile>
        </SummaryCardContentItemMobile>
      ))}
    </SummaryCardContentMobile>
  </SummaryCardBorderMobile>
);
