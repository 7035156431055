import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; // defaults to sessionStorage for web
import createSagaMiddleware from 'redux-saga';
import effects from './effects/rootEffects';
import rootReducer from './reducers/rootReducers';

const persistConfig = {
  key: 'evolution',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const composedEnhancers = composeWithDevTools({ name: 'Evolution Store' })(applyMiddleware(sagaMiddleware));

const store = createStore(persistedReducer, composedEnhancers);
const persistor = persistStore(store);
sagaMiddleware.run(effects);

export { persistor, store };
