import { IRecoverPasswordUsecase } from '../../domain/usecases/recover-password-usecase';
import { IRecoverPasswordRepository } from '../contracts/auth/recover-password-repository';
import { RecoverPasswordResponse } from '../../domain/entities/auth/response/recover-password-response';

export class RecoverPasswordUsecase implements IRecoverPasswordUsecase {
  constructor(private readonly recoverPasswordRepository: IRecoverPasswordRepository) {}

  async execute(email: string, headers?: any): Promise<RecoverPasswordResponse> {
    return await this.recoverPasswordRepository.execute(email, headers);
  }
}
