import { Cpf } from '../../properties/Cpf';
import { Email } from '../../properties/Email';

interface ActorInterface {
  cpf?: string;
  name?: string;
  email?: string;
  contact?: boolean;
}

export class Actor {
  private _cpf: string;
  private _name: string;
  private _email: string;
  private _contact: string;

  set cpf(value: string) {
    const _cpf = new Cpf(value);
    if (!_cpf.isValid) throw new Error('Invalid cpf');
    this._cpf = value;
  }
  get cpf(): string {
    return this._cpf;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get contact(): string {
    return this._contact;
  }

  set contact(contact: string) {
    this._contact = contact;
  }

  get email(): string {
    return this._email;
  }

  set email(email: string) {
    const _email = new Email(email);
    if (!_email.isValid) throw new Error('Invalid email');
    this._email = _email.value;
  }

  constructor({ cpf, name, email }: ActorInterface) {
    if (cpf) this.cpf = cpf;
    if (name) this._name = name;
    if (email) this.email = email;
  }
}

export default Actor;
