import {
  driverInterface,
  itemRiskInterface,
  offerInterface,
  paymentInterface,
  proponentInterface,
  proposalAutoInterface,
} from './rootInitialValues.model';

interface StateFormatInterface {
  actor: {
    proponent: proponentInterface;
    driver: driverInterface;
  };
  itemRisk: itemRiskInterface | Record<string, never>;
  offer: offerInterface | Record<string, never>;
  api: {
    //temporary object. apis should be cache on another layer
    vehicleSearchPlate: [];
  };
  step: number | 0;
  payment: paymentInterface | Record<string, never>;
  proposal: {
    // product related info (simulation/hiring)
    auto: proposalAutoInterface | Record<string, never>;
  };
  faq: {
    topics: [];
    questions: [];
    products: [];
    selectedProduct: any;
  };
}

/**
 * this is the generic values for all products DO NOT CHANGE IT TO BE SPECIFIC
 * all objects with product dependency are informed on the interface
 */
export const rootInitialValues: Partial<StateFormatInterface> = {
  actor: {
    proponent: {
      address: {},
    },
    driver: {
      contact: true,
      address: {},
    },
  },
  itemRisk: {
    automobile: {
      lastInsurance: {},
    },
  },
  offer: {
    auto: {},
  },
  api: {
    vehicleSearchPlate: [],
  },
  step: 0,
  proposal: {
    auto: {},
  },
  faq: {
    topics: [],
    questions: [],
    products: [],
    selectedProduct: {},
  },
};
