import { PageLoadAuthRegisterTechSpec as AuthRegisterPageLoadTagsWithoutOrigin } from './page-load-tags';
import {
  ActionEventTags as AuthRegisterActionsTagsWithoutOrigin,
  ActionsAuthRegisterLabelCreators as AuthRegisterLabelCreators,
} from './action-event-tags';
import { registerAnalyticsEvent } from '@workspace/web/analytics';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const RegisterTags = (options: { origin?: string; product?: string }) => {
  const AuthRegisterActionsTags = AuthRegisterActionsTagsWithoutOrigin(options);
  const AuthRegisterPageLoadTags = AuthRegisterPageLoadTagsWithoutOrigin(options);

  return {
    PERSONAL_DOCUMENT: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P1),
      BACK: () => RAE(AuthRegisterActionsTags.P1E1()),
      NEXT: () => RAE(AuthRegisterActionsTags.P1E2()),
    },
    USER_ALREADY_REGISTERED: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P2),
      BACK: () => RAE(AuthRegisterActionsTags.P2E1()),
      LOGIN: () => RAE(AuthRegisterActionsTags.P2E2()),
    },
    USER_ALREADY_CLIENT: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P3),
      DATA_NOT_IDENTIFIED: () => RAE(AuthRegisterActionsTags.P3E1()),
      BACK: () => RAE(AuthRegisterActionsTags.P3E2()),
      CONTINUE: () => RAE(AuthRegisterActionsTags.P3E3()),
      SKIP_STEP: () => RAE(AuthRegisterActionsTags.P3E4()),
    },
    USER_ALREADY_CLIENT_VERIFY_CODE_SENT: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P4),
      CODE_ERROR: (error: string) => RAE(AuthRegisterActionsTags.P4E1(AuthRegisterLabelCreators.P4E1(error))),
      RESEND_CODE: () => RAE(AuthRegisterActionsTags.P4E2()),
      CHANGE_METHOD: () => RAE(AuthRegisterActionsTags.P4E3()),
      BACK: () => RAE(AuthRegisterActionsTags.P4E4()),
      CONTINUE: () => RAE(AuthRegisterActionsTags.P4E5()),
    },
    PERSONAL_DATA: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P5),
      EMAIL_ALREADY_REGISTERED: (error: string) =>
        RAE(AuthRegisterActionsTags.P5E1(AuthRegisterLabelCreators.P5E1(error))),
      BACK: () => RAE(AuthRegisterActionsTags.P5E2()),
      NEXT: () => RAE(AuthRegisterActionsTags.P5E3()),
    },
    CREATE_PASSWORD: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P6),
      PASSWORDS_DONT_MATCH: (error: string) => RAE(AuthRegisterActionsTags.P6E1(AuthRegisterLabelCreators.P6E1(error))),
      BACK: () => RAE(AuthRegisterActionsTags.P6E2()),
      NEXT: () => RAE(AuthRegisterActionsTags.P6E3()),
    },
    VERIFY_CODE_SENT: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P7),
      CODE_ERROR: (error: string) => RAE(AuthRegisterActionsTags.P7E1(AuthRegisterLabelCreators.P7E1(error))),
      RESEND_CODE: () => RAE(AuthRegisterActionsTags.P7E2()),
      BACK: () => RAE(AuthRegisterActionsTags.P7E3()),
      CONTINUE: () => RAE(AuthRegisterActionsTags.P7E4()),
    },
    SUCCESS: {
      LOAD: () => RAE(AuthRegisterPageLoadTags.P8),
      SEEN_SUCCESS: () => RAE(AuthRegisterActionsTags.P8E1()),
      LOGIN: () => RAE(AuthRegisterActionsTags.P8E2()),
    },
  };
};
