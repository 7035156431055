import { booleanValidationFactory } from '../validation-generators/boolean-validation-factory';

export const trueBooleanValidation = (overrides = {}) => {
  const isTrueTest = (message = 'campo obrigatório') => ({
    name: 'isTrue',
    message,
    test: (value) => value,
  });

  const defaultValues = {
    test: [isTrueTest('campo obrigatório')],
  };
  const values = Object.assign(defaultValues, overrides);

  // @ts-ignore
  return booleanValidationFactory({ ...values, test: [isTrueTest(values.requiredMessage), ...values.test] });
};
