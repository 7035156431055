import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';

import { GetFAQProductQuestionsRepository } from '@workspace/frontend/faq-v2/infra/repositories';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { FAQProductQuestionsResponse } from '@workspace/frontend/faq-v2/domain/entities';
import { GetFaqProductQuestionsUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';

export function getFAQProductQuestionsFactory(product: string) {
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQProductQuestionsResponse> = new AxiosHttpGetClient(
    `/content_types/faq_pergunta_resposta/entries?environment=${environment?.cmsBase?.environment}&query={"produto":{"$in_query":{"slug":"${product}"}}}&asc=posicao`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQProductQuestionsRepository = new GetFAQProductQuestionsRepository(httpGetClient);
  return new GetFaqProductQuestionsUseCase(getFAQProductQuestionsRepository);
}
