import { produce } from 'immer';

export enum ProposalTypes {
  setHiringId = 'SET [proposal] [auto] [hiringId]',
}

export const proposalHandlers = {
  [ProposalTypes.setHiringId]: produce((draft, _action) => {
    draft.proposal.auto.hiringId = _action.data;
  }),
};
