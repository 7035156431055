import { UserAuth, UserAuthResponse } from '../../domain/entities/auth/response/user';
import { ISendUserAuthUsecase } from '../../domain/usecases/send-user-auth-usecase';
import { ISendUserAuthRepository } from '../contracts/auth/send-user-auth';

export class SendUserAuthUsecase implements ISendUserAuthUsecase {
  constructor(private readonly sendUserAuthRepository: ISendUserAuthRepository) {}

  async execute(authParams: UserAuth, headers?: any): Promise<UserAuthResponse> {
    return await this.sendUserAuthRepository.execute(authParams, headers);
  }
}
