import { autov2Images } from '@web/assets';

export const advantageItems = [
  {
    title: 'Proteção para o seu veículo',
    content: 'Fique protegido contra colisão, incêndio, roubo, furto e etc',
    icon: 'icon-itaufonts_seguranca',
  },
  {
    title: 'Proteção para veículos terceiros',
    content: 'Cobertura para danos decorrentes de acidentes com terceiros',
    icon: 'icon-itaufonts_veiculo',
  },
  {
    title: 'Credibilidade',
    content: 'Atendimento e serviços executados com garantia e qualidade',
    icon: 'icon-itaufonts_exclusivo_beneficios',
  },
  {
    title: 'Tranquilidade',
    content: 'Assistência 24h com guincho ou técnico especializado',
    icon: 'icon-itaufonts_mais',
  },
];

export const coverageItems = [
  {
    title: 'cobertura total',
    content: 'batida, roubo/furto, incêndio e serviços emergenciais',
    image: autov2Images.coberturaTotal,
    fullDescription:
      'Seu carro seguro! Essa cobertura garante a indenização total ou parcial em casos de batida, incêndio, roubo, furto e alagamento.',
  },
  {
    title: 'proteção para vidros',
    content: 'conte com a troca de vidros, lanternas, faróis e retrovisores',
    image: autov2Images.protecaoVidros,
    fullDescription:
      'Precisa de reparo nos vidros do seu carro? Com essa cobertura você conta com a troca ou reparo de lanternas, faróis, vidros e retrovisores.',
  },
  {
    title: 'danos materiais a terceiros',
    content: 'pensando no carro, nas pessoas e nos materiais',
    image: autov2Images.danosTerceiros,
    fullDescription:
      'Pensando no seu carro e também nas pessoas, que tal uma cobertura para os terceiros? Cobertura para danos corporais decorrentes de acidentes com terceiros.',
  },
  {
    title: 'acidentes pessoais de passageiros',
    content: 'casos de invalidez permanente e morte acidental.',
    image: autov2Images.acidentesPessoais,
    fullDescription:
      'Pensando em você e no passageiro, essa cobertura te indeniza caso aconteça alguma lesão corporal ou morte por conseguência de acidente no trânsito envolvendo o carro segurado.',
  },
  {
    title: 'danos corporais a terceiros',
    content: 'se preocupar com o carro e também com as pessoas',
    image: autov2Images.danosCorporais,
    fullDescription:
      'Pensando no seu carro e também nas pessoas, que tal uma cobertura para os terceiros? Cobertura para danos corporais decorrentes de acidentens com terceiros.',
  },
  {
    title: 'assistências',
    content: 'serviços de guincho, chaveiro, carro extra e muito mais.',
    image: autov2Images.assistencias,
    fullDescription:
      'Oferecemos diversas assistências para sua comodidade, sendo elas: assistência 24h caso você precise de um técnico ou guincho com agilidade e segurança, também temos o serviço de motorista da vez quando você não puder dirigir e precisa continuar sua viagem, serviço de chaveiro que vai até você e carro extra caso fique na mão.',
  },
];
