import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';

export const DropdownWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  height: 55px;
  border: none;
  font-size: 20px;
  font-weight: 700;
  &::before {
    border: none;
  }
  &.MuiSelect-select:focus {
    background-color: none;
  }
  .MuiInputBase-root {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const FormControlStyled = styled(FormControl)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  display: flex;
  align-items: center;
  width: 100%;
  margin: 11px 14px;
  border-bottom: none;
  &::before {
    border-bottom: none;
  }
`;
export const SelectStyled = styled(Select)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-family: ItauDisplayPro-XBold;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 34px;
  border-radius: 25px;
  border: 0;
  padding: 15px 0px;
  .MuiOutlinedInput-input {
    padding: 0px 14px;
  }
  fieldset {
    display: none;
  }
  &:focus {
    background-color: none;
  }
  &.MuiSelect-select:focus {
    background-color: none;
  }

  &:hover {
    border: none;
  }
`;
export const MenuItemStyled = styled(MenuItem)`
  font-family: ItauDisplayPro-XBold;
  width: 100%;
  margin: 20px 9px;
  border-bottom: none;
  &::before {
    border-bottom: none !important;
  }
  &:before {
    border-bottom: none !important;
  }
  &.MuiSelect-select:focus {
    background-color: none;
  }
`;
export const IconStyled = styled.button`
  background-color: ${(props) => props.theme.palette.primary.contrastText};
  width: 22px;
  height: 22px;
  display: flex;
  border: 0;
  border-radius: 50%;
  border-color: transparent;
  margin-inline: 19px;
  font-family: 'ItauDisplayPro-Black';
  font-size: 35px;
  padding: 0;
  cursor: pointer;
  &:active {
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    transform: scale(0.9);
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    cursor: pointer;
  }
  right: -8px;
`;
export const InputLabelStyled = styled(InputLabel)`
  color: transparent;

  &.Mui-focused {
    color: transparent;
  }
`;
