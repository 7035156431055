import { rest } from 'msw';

export const authenticateSms = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-autenticacao/autenticacao/sms`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id_contratacao: 9039758,
        cpf: '22938419368',
      })
    );
  });
};
