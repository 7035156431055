import { LojaDeSegurosUiComponents } from '../loja-de-seguros-ui';
import { ReactTextMaskUIComponents } from '../common/react-text-mask';
import { ReactAuthenticationUIComponents } from '../authentication-ui';
import { ReactFAQV2UIComponents } from '../faqv2-ui';
import { ReactMyAccountUIComponents } from '../my-account-ui';

export enum ComponentAdapterTypes {
  LOJA_DE_SEGUROS_UI,
  COMMON_INPUT_MASK,
  MICROFRONTEND_IMAGE,
  AUTHENTICATION_UI,
  MY_ACCOUNT_UI,
  FAQV2_UI,
}

const _componentAdapters = {
  [ComponentAdapterTypes.LOJA_DE_SEGUROS_UI]: LojaDeSegurosUiComponents,
  [ComponentAdapterTypes.COMMON_INPUT_MASK]: ReactTextMaskUIComponents,
  [ComponentAdapterTypes.AUTHENTICATION_UI]: ReactAuthenticationUIComponents,
  [ComponentAdapterTypes.MY_ACCOUNT_UI]: ReactMyAccountUIComponents,
  [ComponentAdapterTypes.FAQV2_UI]: ReactFAQV2UIComponents,
};

export class ComponentBuilder {
  private componentBaseType: ComponentAdapterTypes;
  private overrides: ComponentAdapterTypes[];

  constructor(componentBaseType: ComponentAdapterTypes) {
    this.componentBaseType = componentBaseType;
    this.overrides = [];
  }

  static adapter(componentBaseType: ComponentAdapterTypes): ComponentBuilder {
    return new ComponentBuilder(componentBaseType);
  }

  compose(componentBaseType: ComponentAdapterTypes) {
    this.overrides.push(componentBaseType);
    return this;
  }

  private getReduceCompositions() {
    return this.overrides.reduce((newValue, overrideType) => {
      newValue = {
        ...newValue,
        ..._componentAdapters[overrideType],
      };
      return newValue;
    }, {});
  }

  builder() {
    const componentOverrides = this.getReduceCompositions();
    return {
      ..._componentAdapters[this.componentBaseType],
      ...componentOverrides,
    };
  }
}
