export const KEY_CODES = {
  SPACEBAR: [0, 32],

  ENTER: 13,

  DOWN_ARROW: 40,

  UP_ARROW: 38,

  ESCAPE: 27,
};

export const SELECTOR_DATA_ATTR = 'data-selector';
export const LIST_ITEM_ID_PREFIX = 'MenuItem';
