import ReactMarkdown from 'react-markdown';
import { Markdown } from '../../models/markdown';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function MarkdownWeb({ children }: Markdown) {
  return (
    <ThemeProviderInjector>
      <ReactMarkdown children={children} unwrapDisallowed />
    </ThemeProviderInjector>
  );
}

export default MarkdownWeb;
