import {
  BoxWeb,
  ButtonWeb,
  ContainerWeb,
  NavigateButtonsWeb,
} from '@workspace/frontend/shared/presentation/components';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LeadDialog } from '../auto-quotation-lead-dialog/auto-quotation-lead-dialog';

const ErrorTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: normal;
`;

const ErrorActionsWrapper = styled.div`
  margin-top: 4rem;
`;

type LoadingErrorProps = {
  onRetry?: () => void;
  onBack?: () => void;
  onLoad?: () => void;
  activeLeadError?: boolean;
  redirectToHome?: boolean;
  errorTitle?: string;
  initialValues?: { cpf?: string; name?: string; email?: string; cellphone?: string };
  sendLeadUsecase?: any;
};

export function LoadingError({
  onRetry,
  onBack,
  onLoad,
  activeLeadError,
  redirectToHome,
  errorTitle,
  initialValues,
  sendLeadUsecase,
}: LoadingErrorProps) {
  const titleRef = useRef(null);
  const [showLeadDialog, setShowLeadDialog] = useState<boolean>(false);
  useEffect(() => {
    if (typeof onLoad === 'function') onLoad();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (titleRef && titleRef.current) {
        titleRef.current.focus();
      }
    }, 300);
  });

  const handleClosLeadDialog = () => setShowLeadDialog(false);

  const handleOpenLeadDialog = () => setShowLeadDialog(true);

  return (
    <ContainerWeb>
      <BoxWeb mx="auto" display="flex" width="100%" justifyContent="space-around" flexDirection={['column', 'row']}>
        <BoxWeb maxWidth="30rem">
          <ErrorTitle tabIndex={-1} ref={titleRef}>
            {errorTitle}
          </ErrorTitle>
          <ErrorActionsWrapper>
            {activeLeadError ? (
              <NavigateButtonsWeb
                onClickBack={onBack}
                onClickContinue={handleOpenLeadDialog}
                continueText="entre em contato"
              />
            ) : redirectToHome ? (
              <BoxWeb width={'50%'}>
                <ButtonWeb onClick={onRetry} label="voltar para tela inicial" />
              </BoxWeb>
            ) : (
              <NavigateButtonsWeb onClickBack={onBack} onClickContinue={onRetry} continueText="tentar novamente" />
            )}
          </ErrorActionsWrapper>
        </BoxWeb>
        <LeadDialog
          open={showLeadDialog}
          onClose={handleClosLeadDialog}
          initialValues={initialValues}
          sendLeadUsecase={sendLeadUsecase}
        />
        <BoxWeb width={'-webkit-fill-available'} mt={['3rem', '0']}>
          <img
            style={{ width: 'inherit' }}
            alt="tivemos um imprevisto"
            aria-hidden
            src="https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/auto/ilustracoes/icone_block.svg"
          />
        </BoxWeb>
      </BoxWeb>
    </ContainerWeb>
  );
}
