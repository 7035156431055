import { BoxWeb, NavigateButtonsWeb, OutlinedButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { CepField } from '@web/form/component';
import { Link } from './auto-address-step.style';
import { BoxSteps } from '../../hiring-utils';
import React, { useState } from 'react';
import { LeadDialogFormConnectorWrapper } from '../../../../components/auto-quotation-lead-dialog/lead-dialog-form-connector-wrapper';
import { FormHelperText } from '@material-ui/core';

export const AutoAddressStep = ({
  onBack,
  onSubmit,
  initialValues,
  sendLeadUsecase,
  onChangeCep,
  cepNotFound,
  loading,
  config,
}: any) => {
  const SEARCH_CEP = 'https://buscacepinter.correios.com.br/app/endereco/index.php?t';
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const handleCloseLeadModal = () => setOpenLeadModal(false);
  const handleOpenLeadModal = () => setOpenLeadModal(true);
  return (
    <BoxSteps>
      <FormController onSubmit={onSubmit} initialValues={initialValues}>
        <LeadDialogFormConnectorWrapper
          open={openLeadModal}
          onClose={handleCloseLeadModal}
          sendLeadUsecase={sendLeadUsecase}
        />

        <BoxWeb display={['flex', 'block']} flexDirection={'column'} alignItems={'center'}>
          <BoxWeb mb={{ xs: 4, md: 7 }}>
            <BoxWeb component={'h1'} aria-label="qual seu CEP?" id="lblZipCodeCar_screenAddress" m={0}>
              <span aria-hidden>
                qual seu <strong>CEP?</strong>
              </span>
            </BoxWeb>
          </BoxWeb>
          <BoxWeb display="flex" flexDirection={{ xs: 'column', sm: 'row' }} mb={10} width={'100%'}>
            <BoxWeb width={'100%'} maxWidth={320} mr="2rem">
              <CepField
                helperText={'com o seu CEP analisamos o nível de risco para o seu carro'}
                onChange={onChangeCep}
              />
              {cepNotFound && (
                <FormHelperText error={true}>não é possível contratar para esse cep, tente outro</FormHelperText>
              )}
            </BoxWeb>
            <BoxWeb mt="2.2rem">
              <Link
                tabIndex={0}
                rel="noopener noreferrer"
                target="_blank"
                href={SEARCH_CEP}
                id="lnkZipCode_screenAddress"
              >
                não sei meu CEP
              </Link>
            </BoxWeb>
          </BoxWeb>
          <NavigateButtonsWeb onClickBack={onBack} disabled={cepNotFound} loading={loading} />
          <BoxWeb height="3rem" />
          {config?.ENABLE_AUTO_SIMPLE_LEAD && (
            <BoxWeb width="17rem">
              <span>se preferir, deixe seus dados e nós entraremos em contato com você</span>
              <BoxWeb height="0.5rem" />
              <BoxWeb tabIndex={0}>
                <OutlinedButtonWeb
                  id="btnContactLead_screenAddress"
                  label="entrar em contato"
                  onClick={handleOpenLeadModal}
                />
              </BoxWeb>
            </BoxWeb>
          )}
        </BoxWeb>
      </FormController>
    </BoxSteps>
  );
};
