import { DynamicLandpage } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import { IGetDynamicLandPageRepository } from '../contracts/landings/get-dynamic-landpage-repository';
import { IGetDynamicLandPageUseCase } from '@core/domain/usecases/get-dynamic-landpage-usecase';

export class GetDynamicLandPageUseCase implements IGetDynamicLandPageUseCase {
  constructor(private readonly getDynamicLandPageRepository: IGetDynamicLandPageRepository) {}

  async execute(): Promise<DynamicLandpage[]> {
    return await this.getDynamicLandPageRepository.execute();
  };
};
