import { FormControllerFieldConnector } from '@web/form';
import * as React from 'react';
import { SelectWeb, SelectWebProps } from '@workspace/frontend/shared/presentation/components';
import { Automobile } from '@workspace/entities';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const ManufactureYearField = ({
  items,
  disabled,
  onChange,
}: {
  items: any[];
  disabled?: boolean;
  onChange?: () => void;
}) => (
  <FormControllerFieldConnector<SelectWebProps>
    name="fabricationYear"
    component={SelectWeb}
    disabled={disabled}
    onChange={onChange}
    validation={stringValidationFactory({
      requiredMessage: 'você precisa informar um ano de fabricação',
      test: [
        {
          name: 'modelYear',
          message: 'você precisa informar uma data válida',
          test: Automobile.validateFabricationYear,
        },
      ],
    })}
    componentProps={{
      id: 'txtProductionYear_screenCarInformation',
      items: items || [],
      label: 'ano de fabricação',
    }}
  />
);
