import React, { useEffect, useRef } from 'react';
import { BoxWeb, ButtonWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';
import { TitleDynamic } from '../components/title-dynamic/title-dynamic';
import { InfosDynamic } from '../components/infos-dynamic/infos-dynamic';
import { BannerDynamic } from '../components/banner-dynamic/banner-dynamic';
import { LeadDynamic } from '../components/lead-dynamic-landings/lead-dynamic';
import styled from 'styled-components';
import { InsuranceCompany } from '../components/insurance-company/insurance-company';
import { TjDynamic } from '../components/tj-dynamic/tj-dynamic';
import { DetailsDynamic } from '../components/details-dynamic/details-dynamic';
import { Banner, DynamicLandpage } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import { ANALYTICS_DYNAMIC_LANDING } from '@workspace/frontend-shared-application-analytics';

export const BannerImgMobile = styled.img`
  width: 100%;
`;

interface ContentButtonMobileProps {
  banner: Banner;
  handleButtonTop: (typeRedirect: string, url: string) => void;
}

type DynamicLandPageViewProps = {
  content: DynamicLandpage;
};

export const ContentButtonMobile = ({ banner, handleButtonTop }: ContentButtonMobileProps) => {
  const handleClick = () => {
    handleButtonTop(banner?.action?.type, banner?.action?.url);
  };
  return (
    <BoxWeb
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      flexWrap={'wrap'}
      height={'300px'}
      width={'100%'}
    >
      <BoxWeb
        color="primary.main"
        fontSize={'30px'}
        fontFamily="ItauDisplay-Regular"
        width={'275px'}
        textAlign="center"
        component={'h3'}
      >
        {banner?.title}
      </BoxWeb>
      {banner?.subtitle_middle ? (
        <BoxWeb
          color="primary.main"
          fontSize={'35px'}
          fontFamily="ItauDisplay-Regular"
          width={'275px'}
          textAlign="center"
        >
          {banner?.subtitle_middle}
        </BoxWeb>
      ) : null}
      <BoxWeb color="primary.contrastText" fontSize={'18px'} fontFamily="ItauDisplay-Regular">
        {banner?.subtitle}
      </BoxWeb>
      <BoxWeb display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <BoxWeb width={'272px'}>
          <ButtonWeb label={banner?.action?.label} onClick={handleClick} />
        </BoxWeb>
        {banner?.action?.helper_text && (
          <BoxWeb color={'primary.contrastText'} fontSize={'13px'}>
            {banner?.action?.helper_text}
          </BoxWeb>
        )}
      </BoxWeb>
    </BoxWeb>
  );
};

export const DynamicLandPageView = ({ content }: DynamicLandPageViewProps) => {
  const {
    pageload: pageloadCmsAnalytics,
    redirect_top: redirectTopCmsAnalytics,
    redirect_bottom: redirectBottomCmsAnalytics,
    anchor: anchorCmsAnalytics,
    send: sendCmsAnalytics,
  } = content?.analytics[0];

  const leadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ANALYTICS_DYNAMIC_LANDING.DYNAMIC_LANDING.CALL(pageloadCmsAnalytics);
  }, []);

  const handleRedirect = (url: string) => {
    window.location.assign(url);
  };

  const markdownToString = (markdown) => {
    if (typeof markdown === 'string') {
      return markdown.replace(/\*/g, '');
    }
    return markdown;
  };

  const handleButtonTop = (typeRedirect: string, url: string) => {
    if (typeRedirect === 'Anchor') {
      setTimeout(() => {
        if (leadRef?.current) {
          leadRef?.current?.focus();
          ANALYTICS_DYNAMIC_LANDING.DYNAMIC_LANDING.CALL(anchorCmsAnalytics);
        }
      }, 600);
    } else {
      ANALYTICS_DYNAMIC_LANDING.DYNAMIC_LANDING.CALL(redirectTopCmsAnalytics);
      handleRedirect(url);
    }
  };

  const handleButtonBottom = (url: string) => {
    ANALYTICS_DYNAMIC_LANDING.DYNAMIC_LANDING.CALL(redirectBottomCmsAnalytics);
    handleRedirect(url);
  };

  return (
    <>
      <BoxWeb
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        position={'relative'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        width={'100%'}
        mt={{ xs: 0, sm: '70px' }}
      >
        <BoxWeb display={{ xs: 'flex', sm: 'none' }} height={'288px'}>
          <BannerImgMobile src={content?.banner?.banner_image?.path} alt={content?.banner?.alt} aria-hidden />
        </BoxWeb>
        {content?.home?.title ? (
          <BoxWeb
            position={'absolute'}
            left={0}
            top={{ xs: '24.9rem', sm: '75px' }}
            width={'320px'}
            height={'4.83px'}
            bgcolor={'primary.main'}
          />
        ) : null}
        <BoxWeb
          display="flex"
          flexDirection="column"
          flexWrap={'wrap'}
          width={{ xs: '100%', sm: '50%' }}
          height={{ xs: 'max-content', sm: 'auto' }}
        >
          <BoxWeb px={{ xs: '0', sm: '0' }} width={'100%'}>
            <BoxWeb height={{ xs: '25px', sm: '90px' }}>
              {content?.home?.title ? (
                <BoxWeb
                  pt={{ xs: '3rem', sm: '1rem' }}
                  ml={{ xs: '2rem', sm: '5rem' }}
                  fontSize={'25px'}
                  color="text.primary"
                  role={'heading'}
                  aria-level={1}
                >
                  <MarkdownWeb>{content?.home?.title}</MarkdownWeb>
                </BoxWeb>
              ) : null}
            </BoxWeb>
            <BoxWeb zIndex={1} display="flex" flexDirection="column" justifyContent="space-between" width={'auto'}>
              <BoxWeb mt={{ xs: `${content?.home?.title ? '7rem' : 0}`, sm: 0 }}>
                <TitleDynamic home={content?.home} headingLevel={content?.home?.title ? 2 : 1} />
              </BoxWeb>
              <BoxWeb bgcolor={'background.lightSecondary'} width="100%" height="max-content">
                <InfosDynamic infos={content?.infos} />
              </BoxWeb>
            </BoxWeb>
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display={{ xs: 'none', sm: 'flex' }} width={'50%'} zIndex={1} height={'auto'} bgcolor={'#2B2724'}>
          <BannerDynamic handleButtonTop={handleButtonTop} banner={content?.banner} />
        </BoxWeb>
        <BoxWeb display={{ xs: 'flex', sm: 'none' }} px={{ xs: '20px', sm: 0 }} bgcolor={'#2B2724'}>
          <ContentButtonMobile banner={content?.banner} handleButtonTop={handleButtonTop} />
        </BoxWeb>
      </BoxWeb>
      <DetailsDynamic detail={content?.detail} handleButtonBottom={handleButtonBottom} />
      {content?.partner?.image && <InsuranceCompany partner={content?.partner} />}

      {content.banner.action.type === 'Anchor' ? (
        <div>
          <BoxWeb
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-around'}
            flexWrap="wrap"
            alignItems={'center'}
          >
            <BoxWeb
              display="flex"
              flexDirection="column"
              justifyContent={'space-around'}
              height={'215px'}
              maxWidth="30rem"
              textAlign={{ xs: 'center', sm: 'start' }}
              alignItems={{ xs: 'center', sm: 'start' }}
              fontSize={'25px'}
            >
              <div
                tabIndex={-1}
                ref={leadRef}
                role={'heading'}
                aria-level={2}
                aria-label={markdownToString(content?.lead?.title)}
              >
                <BoxWeb width={'100%'} fontSize={'1.5rem'} aria-hidden>
                  <MarkdownWeb>{content?.lead?.title}</MarkdownWeb>
                </BoxWeb>
              </div>

              <BoxWeb width={'250px'}>{content?.lead?.subtitle} </BoxWeb>
            </BoxWeb>
            <LeadDynamic products={content?.products} sendCmsAnalytics={sendCmsAnalytics} />
          </BoxWeb>
        </div>
      ) : null}
      {content?.tj?.title_one && <TjDynamic content={content?.tj} />}
    </>
  );
};
