import { CheckBoxWeb, DefaultCheckboxProps } from '@workspace/frontend/shared/presentation/components';
import * as React from 'react';
import { FormControllerFieldConnector } from '@web/form';
import { trueBooleanValidation } from '@workspace/frontend-shared-application-validation';

export const TermField = ({ label, id, errorMessage }: { label: string; id?: string; errorMessage?: string }) => (
  <FormControllerFieldConnector<DefaultCheckboxProps>
    component={CheckBoxWeb}
    name="termField"
    translator={{ value: 'checked' }}
    validation={trueBooleanValidation({ requiredMessage: errorMessage })}
    componentProps={{
      label,
      id,
    }}
  />
);
