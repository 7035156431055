import { GetAddressByCepRepository } from '@core/infra/repository/autov2/get-address-by-cep-repository';
import { IGetAddressByCepRepository } from '@core/data/contracts/autov2/get-address-by-cep-repository-interface';
import { ViaCepResponseContract } from '@core/domain/entities/autov2/via-cep-response-contract';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';

export abstract class GetAddressByCepRepositoryFactory {
  private static _instance: IGetAddressByCepRepository;

  public static get instance(): IGetAddressByCepRepository {
    if (!this._instance) {
      const httpClient = new AxiosHttpGetClient<string, ViaCepResponseContract>(null, null, 'https://viacep.com.br');

      this._instance = new GetAddressByCepRepository(httpClient);
    }

    return this._instance;
  }
}
