import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const RenavamField = ({
  disabled,
  id,
  type = 'tel',
  label = 'renavam',
}: {
  disabled?: boolean;
  id?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name="renavam"
    helperText={'campo com 11 caracteres'}
    disabled={disabled}
    validation={stringValidationFactory({
      length: { limit: 11, message: 'o renavam deve ter 11 caracteres' },
      requiredMessage: 'você precisa informar o número do renavam',
    })}
    type="tel"
    componentProps={{
      label,
      id,
      type,
      maskType: '99999999999',
    }}
  />
);
