import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function InfoBoxWeb(props) {
  const { Components } = useThemeProvider();
  const { InfoBox } = Components;
  return (
    <ThemeProviderInjector>
      <InfoBox {...props} />
    </ThemeProviderInjector>
  );
}
