import { LoadingProps } from '../../models/loading-props';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function LoadingWeb(props: LoadingProps) {
  const { Components } = useThemeProvider();
  const { Loading } = Components;
  return (
    <ThemeProviderInjector>
      <Loading {...props} />
    </ThemeProviderInjector>
  );
}
