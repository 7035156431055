import { produce } from 'immer';

export const stepperTypes = { setStep: 'SET step' };

//Action data type
export type setStepAD = { step: number };

export const stepperHandlers = {
  [stepperTypes.setStep]: produce((draft, _action: setStepAD) => {
    draft.step = _action.step;
  }),
};
