import { BoxWeb, ButtonWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';
import {
  CoverageImageItem,
  CoverageItem,
  CoverageTextItemContent,
  CoverageTextItemTitle,
  CoverageTextItemWrapper,
  SectionCoveragesCards,
} from './styles';
import { Detail } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import React from 'react';

type DetailsDynamicProps = {
  detail: Detail;
  handleButtonBottom: (url: string) => void;
};

export const DetailsDynamic = ({ detail, handleButtonBottom }: DetailsDynamicProps) => {
  const handleClick = () => {
    handleButtonBottom(detail?.action?.url);
  };
  return (
    <BoxWeb display="flex" flexDirection="column" justifyContent="center">
      <BoxWeb
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        height={'200px'}
        position="relative"
        textAlign={{ xs: 'center', sm: 'none' }}
      >
        <BoxWeb width={{ xs: '100%', sm: 'auto' }} fontSize={'35px'} role={'heading'} aria-level={2}>
          <MarkdownWeb>{detail?.title}</MarkdownWeb>
        </BoxWeb>
        <BoxWeb
          height={'3px'}
          width={'100px'}
          bgcolor="primary.main"
          position="absolute"
          top={{ xs: '11.5rem', sm: '8.5rem' }}
        />
      </BoxWeb>
      <BoxWeb>
        <SectionCoveragesCards>
          {detail.detail_items.map((item: any) => (
            <CoverageItem>
              <CoverageImageItem aria-hidden alt={item?.alt} src={item?.image?.url} />
              <CoverageTextItemWrapper>
                <CoverageTextItemTitle role={'heading'} aria-level="3">
                  <MarkdownWeb>{item?.title}</MarkdownWeb>
                </CoverageTextItemTitle>
                <CoverageTextItemContent size={'160px'}>
                  <MarkdownWeb>{item?.subtitle}</MarkdownWeb>
                </CoverageTextItemContent>
              </CoverageTextItemWrapper>
            </CoverageItem>
          ))}
        </SectionCoveragesCards>
      </BoxWeb>
      {detail?.action?.label && (
        <BoxWeb display={'flex'} mb={'3rem'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <BoxWeb width={'272px'}>
            <ButtonWeb label={detail?.action?.label} onClick={handleClick} />
          </BoxWeb>
          {detail?.action?.helper_text && <BoxWeb fontSize={'13px'}>{detail?.action?.helper_text}</BoxWeb>}
        </BoxWeb>
      )}
    </BoxWeb>
  );
};
