import { FormControllerFieldConnector } from '@web/form';
import {
  InputMaskType,
  InputMaskWeb,
  InputMaskWebProps,
  KeyboardMode,
} from '@workspace/frontend/shared/presentation/components';
import { cepValidation } from '@workspace/frontend-shared-application-validation';

export const CepField = ({
  helperText,
  onBlur,
  onChange,
}: Partial<{
  helperText: string;
  onBlur: any;
  onChange: any;
}>) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name={'cep'}
    validation={cepValidation()}
    onBlur={onBlur}
    onChange={onChange}
    componentProps={{
      helperText: helperText,
      inputKeyboardType: KeyboardMode.NUMERIC,
      maskType: InputMaskType.CEP,
      label: 'CEP',
      id: 'txtZipCodeCar_screenAddress',
    }}
  />
);
