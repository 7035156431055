import { GetArticleBlogUseCase } from '@workspace/frontend/blog/domain/use-cases';
import { GetArticleRepository } from '@workspace/frontend/blog/infra/repositories';
import { EnvironmentContextProps } from '@workspace/providers';
import { ArticleBlogResponse } from '@workspace/frontend/blog/domain/entities';
import {IHttpGetClient} from "@workspace/frontend/http/domain/contracts/repositories";
import {AxiosHttpGetClient} from "@workspace/frontend/http/repositories/axios";

export function getContentArticleFactory(
  { environment }: Partial<EnvironmentContextProps>,
  uid?: string,
  destaque?: boolean
) {
  const httpGetClient: IHttpGetClient<null, ArticleBlogResponse> = new AxiosHttpGetClient(
    `/content_types/seg_blog_posts/entries/${uid ? uid : ''}?environment=${environment?.cmsBase?.environment}${
      destaque ? '&query={"flag_spotlight_content":true}' : ''
    }&include[]=post_category`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getArticleRepository = new GetArticleRepository(httpGetClient);
  const getArticleBlogUsecase = new GetArticleBlogUseCase(getArticleRepository);
  return getArticleBlogUsecase;
}

// TODO USAR O SDK DO CONTENTSTAK
