import { ExemptCarInspectionModal } from './exempt-modal';
import { RequiredCarInspectionModal } from './required-inspection-modal';

type CarInspectionModalProps = {
  onClose: () => void;
  open: boolean;
  isRequiredInsuranceCar: boolean;
};

export function CarInspectionModal({ onClose, open, isRequiredInsuranceCar }: CarInspectionModalProps) {
  return isRequiredInsuranceCar ? (
    <RequiredCarInspectionModal onClose={onClose} open={open} />
  ) : (
    <ExemptCarInspectionModal onClose={onClose} open={open} />
  );
}
