import {
  ActionAnalyticsInterface,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENT_CATEGORIES,
  assembleAnalyticsCustomEvent,
} from '@workspace/web/analytics';
import { ANALYTICS_EVENT_SQUADS } from 'libs/frontend/shared/application/analytics/src/constants/squads';
import { pages as AnalyticsHiringPages } from './page-load-tags';
type A =
  | 'A1'
  | 'A2'
  | 'A3'
  | 'A4'
  | 'A5'
  | 'A6'
  | 'A7'
  | 'A8'
  | 'A9'
  | 'A10'
  | 'A11'
  | 'A12'
  | 'A13'
  | 'A14'
  | 'A15'
  | 'A16'
  | 'A17'
  | 'A18'
  | 'A19'
  | 'A20'
  | 'A21'
  | 'A22';

const eventLabels: Record<A, string> = {
  A1: 'BTN:Auto:Auto2.0:DadosDoCondutor:Cpf:Continuar',
  A2: 'BTN:Auto:Auto2.0:DadosDoCondutor:Veiculo:Continuar:ZeroKM{0}:Bonus{1}',
  A3: 'BTN:Auto:Auto2.0:DadosDoCondutor:Cep:Continuar',
  A4: 'BTN:Auto:Auto2.0:Cotacao:Aba:{0}',
  A5: 'BTN:Auto:Auto2.0:Cotacao:{0}:{1}:{2}',
  A6: 'BTN:Auto:Auto2.0:Cotacao:Continuar:Franquia{0}:{1}',
  A7: 'BTN:Auto:Auto2.0:Cotacao:RefazerCotacao',
  A8: 'BTN:Auto:Auto2.0:FormLead:Enviar',
  A9: 'BTN:Auto:Auto2.0:Sucesso',
  A10: 'BTN:Auto:Auto2.0:Erro:Voltar',
  A11: 'BTN:Auto:Auto2.0:Erro:Continuar',
  A12: 'BTN:Auto:Auto2.0:DadosDoTitular:Voltar',
  A13: 'BTN:Auto:Auto2.0:DadosDoTitular:Continuar',
  A14: 'BTN:Auto:Auto2.0:Pagamento:VerResumoDoSeguro',
  A15: 'BTN:Auto:Auto2.0:Pagamento:Voltar',
  A16: 'BTN:Auto:Auto2.0:Pagamento:Contratar',
  A17: 'BTN:Auto:Auto2.0:DispensaVistoria:Continuar',
  A18: 'Alerta:Auto:Auto2.0:Contratacao:Sucesso',
  A19: 'Alerta:Auto:Auto2.0:DadosDoCondutor:Veiculo:PlacaNaoEncontrada',
  A20: 'BTN:Auto:Auto2.0:DadosDoCondutor:Veiculo:EditarVeiculo',
  A21: 'BTN:Auto:Auto2.0:Sucesso:SemCotacao',
  A22: 'BTN:ReceberContatoTelefone:{0}',
};
export type ActionsAutoV2HiringTechSpecI = (
  origin: string
) => Record<A, (labels?: unknown[]) => ActionAnalyticsInterface>;

export const ActionEventTags: ActionsAutoV2HiringTechSpecI = (origin) => ({
  A1: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A1,
        noInteraction: false,
      },
      null,
      origin
    ),
  A2: (labels: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A2,
        noInteraction: false,
      },
      labels,
      origin
    ),
  A3: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A3,
        noInteraction: false,
      },
      null,
      origin
    ),
  A4: (label: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A4,
        noInteraction: false,
      },
      label,
      origin
    ),
  A5: (label: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A5,
        noInteraction: false,
      },
      label,
      origin
    ),
  A6: (label: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A6,
        noInteraction: false,
      },
      label,
      origin
    ),
  A7: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A7,
        noInteraction: false,
      },
      null,
      origin
    ),
  A8: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A8,
        noInteraction: false,
      },
      null,
      origin
    ),
  A9: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A9,
        noInteraction: true,
      },
      null,
      origin
    ),
  A10: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A10,
        noInteraction: false,
      },
      null,
      origin
    ),
  A11: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A11,
        noInteraction: false,
      },
      null,
      origin
    ),
  A12: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A12,
        noInteraction: false,
      },
      null,
      origin
    ),
  A13: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A13,
        noInteraction: false,
      },
      null,
      origin
    ),
  A14: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A14,
        noInteraction: false,
      },
      null,
      origin
    ),
  A15: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A15,
        noInteraction: false,
      },
      null,
      origin
    ),
  A16: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A16,
        noInteraction: false,
      },
      null,
      origin
    ),
  A17: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A17,
        noInteraction: false,
      },
      null,
      origin
    ),
  A18: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A18,
        noInteraction: false,
      },
      null,
      origin
    ),
  A19: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A19,
        noInteraction: false,
      },
      null,
      origin
    ),
  A20: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A20,
        noInteraction: false,
      },
      null,
      origin
    ),
  A21: () =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A21,
        noInteraction: false,
      },
      null,
      origin,
      { page: AnalyticsHiringPages.T12, responsibleSquadTag: ANALYTICS_EVENT_SQUADS.CDPFMA }
    ),
  A22: (labels: string[]) =>
    assembleAnalyticsCustomEvent(
      {
        category: ANALYTICS_EVENT_CATEGORIES.SEEN_OBJECT,
        action: ANALYTICS_EVENT_ACTIONS.AUTO,
        label: eventLabels.A22,
        noInteraction: false,
      },
      labels,
      origin,
      { page: AnalyticsHiringPages.T1, responsibleSquadTag: ANALYTICS_EVENT_SQUADS.CDPFMA }
    ),
});

export const ActionsAutoV2HiringLabelCreators = {
  A2: (zeroKm: boolean, classBonus: boolean): string[] => {
    const zeroKmLabel = zeroKm ? 'Sim' : 'Nao';
    const classBonusLabel = classBonus ? 'Sim' : 'Nao';
    return [zeroKmLabel, classBonusLabel];
  },
  A4: (betterPrice: boolean): string[] => {
    const betterPriceLabel = betterPrice ? 'MelhorCusto' : 'MelhorOpcao';
    return [betterPriceLabel];
  },
  A5: (betterPrice: boolean, detailName, isShowing: boolean) => {
    const betterPriceLabel = betterPrice ? 'MelhorCusto' : 'MelhorOpcao';
    const showingLabel = isShowing ? 'Ver' : 'Esconder';
    return [betterPriceLabel, detailName, showingLabel];
  },
  A6(franchise: 50 | 100, betterPrice: boolean) {
    const betterPriceLabel = betterPrice ? 'MelhorCusto' : 'MelhorOpcao';
    return [franchise.toString(), betterPriceLabel];
  },
  A22(receiveContactByPhone) {
    const value = receiveContactByPhone ? 'sim' : 'nao';
    return [value];
  },
};
