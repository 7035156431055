import { createContext, useContext } from 'react';

const OriginContext = createContext({ origin: 'lojadeseguros' });

export const AuthOriginProvider = ({ origin, children }: { origin: string; children: JSX.Element }) => {
  return <OriginContext.Provider value={{ origin }}>{children}</OriginContext.Provider>;
};

export const useAuthOriginProvider = () => {
  const value = useContext(OriginContext);
  return value.origin;
};
