import LojaDeSegurosGlobalStyle from '../../style/types/global-loja-de-seguros';

export enum GlobalStyleAdapterTypes {
  LOJA_DE_SEGUROS,
}

const _styleAdapters = {
  [GlobalStyleAdapterTypes.LOJA_DE_SEGUROS]: LojaDeSegurosGlobalStyle,
};

export class GlobalStyleBuilder {
  private styleBaseType: GlobalStyleAdapterTypes;

  constructor(styleBaseType: GlobalStyleAdapterTypes) {
    this.styleBaseType = styleBaseType;
  }

  static adapter(componentBaseType: GlobalStyleAdapterTypes): GlobalStyleBuilder {
    return new GlobalStyleBuilder(componentBaseType);
  }

  builder() {
    return _styleAdapters[this.styleBaseType];
  }
}
