import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { ISimulationRepository } from '../../../data/contracts/simulation-repository';
import { SimulationRequest } from '../../../domain/entities/autov2/simulation-request';
import { SimulationResponse } from '../../../domain/entities/autov2/simulation-response';

export class AutoV2SimulationRepository
  implements ISimulationRepository<SimulationRequest, { data: SimulationResponse }>
{
  constructor(private readonly httpPostClient: IHttpPostClient<SimulationRequest, { data: SimulationResponse }>) {}

  async execute(simulation: SimulationRequest): Promise<{ data: SimulationResponse }> {
    return await this.httpPostClient.post(simulation);
  }
}
