import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { RecoveryPassword } from '@core/domain/entities/auth/recovery-password';
import { SendRecoveryPasswordRepository } from '@core/infra/repository/auth/send-recovery-password-repository';
import { SendRecoveryPasswordUsecase } from '@core/data/services/send-recovery-password-usecase';
import { SendRecoveryPasswordResponse } from '@core/domain/entities/auth/response/send-recovery-password-response';

export function sendRecoveryPasswordFactory() {
  //eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<RecoveryPassword, SendRecoveryPasswordResponse> = new AxiosHttpPostClient<
    RecoveryPassword,
    SendRecoveryPasswordResponse
  >('recuperarsenha/resetSenha', {}, environment.gatewayBFFBaseURL.url);
  const sendRecoveryPasswordRepository = new SendRecoveryPasswordRepository(httpPostClient);
  const sendRecoveryPasswordUsecase = new SendRecoveryPasswordUsecase(sendRecoveryPasswordRepository);

  return sendRecoveryPasswordUsecase;
}
