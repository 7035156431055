import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { IGetFAQTopicRepository } from '@workspace/frontend/faq-v2/domain/contracts';
import { FAQTopicResponse, Topic } from '@workspace/frontend/faq-v2/domain/entities';

export class GetFAQTopicRepository implements IGetFAQTopicRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQTopicResponse>) {}

  async execute(): Promise<Topic[]> {
    const response: FAQTopicResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
