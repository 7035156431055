import React from 'react';
import { FormControllerFieldConnector } from '@web/form';
import { PasswordWeb } from '@workspace/frontend/shared/presentation/components';
import { IStringValidationFactory, passwordValidation } from '@workspace/frontend-shared-application-validation';

export const PasswordField = ({
  prefix = undefined,
  componentProps,
  disableValidation = false,
  validationOverrides,
  onChange,
  onBlur,
}: {
  prefix?: string;
  disableValidation?: boolean;
  validationOverrides?: IStringValidationFactory;
  componentProps?: any;
  onChange?(event: React.ChangeEvent): void;
  onBlur?(): void;
}) => {
  return (
    <FormControllerFieldConnector
      component={PasswordWeb}
      name={prefix ? prefix + '_password' : 'password'}
      validation={!disableValidation ? passwordValidation(validationOverrides) : null}
      componentProps={componentProps}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
