import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Options = {
  label: string;
  checked: boolean;
};

type NotificationProps = {
  options: Array<Options>;
  config?: {
    name: string;
  };
  checkedOptions(options?: Array<Options>): void;
};

export const Notification = ({
  options,
  config = { name: 'checkbox' },
  checkedOptions,
}: NotificationProps): JSX.Element => {
  const [internalOptions, setInternalOptions] = useState<NotificationProps['options']>(options);

  useEffect(() => checkedOptions(internalOptions), [internalOptions, checkedOptions]);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, checked },
    } = event;

    const check = internalOptions?.map((option) => (option?.label !== id ? option : { ...option, checked }));

    setInternalOptions(check);
  };

  return (
    <NotificationWrapper>
      {options?.length &&
        options.map(({ label, checked }) => (
          <CheckboxWrapper key={`${label}-${config.name}`}>
            <InputCheck
              type="checkbox"
              name={config.name}
              id={label}
              defaultChecked={checked}
              onChange={handleCheckChange}
            />
            <LabelCheck htmlFor={label}>{label}</LabelCheck>
          </CheckboxWrapper>
        ))}
    </NotificationWrapper>
  );
};

const NotificationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const CheckboxWrapper = styled.div`
  display: inline-block;
  margin: 0 20px 15px 0;

  :last-of-type {
    margin-right: 0;
  }
`;

const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;

  :checked + label {
    background-color: #106eb0;
    color: #fff;
  }
`;

const LabelCheck = styled.label`
  position: relative;
  cursor: pointer;
  min-width: 124px;
  height: 32px;
  font-size: 18px;
  color: #106eb0;
  border: 1px solid #106eb0;
  border-radius: 20px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  ::before {
    box-sizing: border-box;
    content: ' ';
    position: absolute;
    top: 0.3em;
    left: 0;
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 2px solid white;
    border-radius: 0.25em;
    z-index: -1;
  }
`;
