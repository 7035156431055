abstract class LocalStorageBaseRepository {
  protected localstorage: Storage;

  protected constructor(private key: string) {
    this.localstorage = window.localStorage;
  }

  public create(value: string): void {
    this.localstorage.setItem(this.key, value);
  }

  public get(): string {
    return this.localstorage.getItem(this.key);
  }

  public remove(): void {
    return this.localstorage.removeItem(this.key);
  }
}

export { LocalStorageBaseRepository };
