import { Automobile } from '@workspace/entities';
import { produce } from 'immer';
import { rootInitialValues } from '../rootInitialValues';

export const itemRiskAutomobileTypes = {
  setPlate: 'SET [itemRisk] [automobile] plate',
  setZerokm: 'SET [itemRisk] [automobile] zeroKm',
  setOvernightCep: 'SET [itemRisk] [automobile] overnightCep',
  setBrandAndModel: 'SET [itemRisk] [automobile] brandNModel',
  setBonusClass: 'SET [itemRisk] [automobile] [lastInsurance] bonusClass',
  setInsuranceCompany: 'SET [itemRisk] [automobile] [lastInsurance] insuranceCompany',
  setModelYear: 'SET [itemRisk] [automobile] modelYear',
  setFabricationYear: 'SET [itemRisk] [automobile] fabricationYear',
  setChassis: 'SET [itemRisk] [automobile] chassis',
  setRenavam: 'SET [itemRisk] [automobile] renavam',
  reset: 'RESET [itemRisk] automobile',
};

const automobileHandlers = {
  [itemRiskAutomobileTypes.setPlate]: produce((draft, _action) => {
    const automobile = new Automobile({ plate: _action.data });
    draft.itemRisk.automobile.plate = automobile.plate;
  }),
  [itemRiskAutomobileTypes.setZerokm]: produce((draft, _action) => {
    const automobile = new Automobile({ zeroKm: _action.data });
    draft.itemRisk.automobile.zeroKm = automobile.zeroKm;
  }),
  [itemRiskAutomobileTypes.setOvernightCep]: produce((draft, _action) => {
    const automobile = new Automobile({ overnightCep: _action.cep });
    draft.itemRisk.automobile.overnightCep = automobile.overnightCep;
  }),
  [itemRiskAutomobileTypes.setBrandAndModel]: produce((draft, _action) => {
    const automobile = new Automobile({ brandAndModel: _action.data });
    draft.itemRisk.automobile.brandAndModel = automobile.brandAndModel;
  }),
  [itemRiskAutomobileTypes.setBonusClass]: produce((draft, _action) => {
    const automobile = new Automobile({ bonusClass: _action.data });
    draft.itemRisk.automobile.lastInsurance.bonusClass = automobile.bonusClass;
  }),
  [itemRiskAutomobileTypes.setInsuranceCompany]: produce((draft, _action) => {
    const automobile = new Automobile({ insuranceCompany: _action.data });
    draft.itemRisk.automobile.lastInsurance.insuranceCompany = automobile.insuranceCompany;
  }),
  [itemRiskAutomobileTypes.setModelYear]: produce((draft, _action) => {
    const automobile = new Automobile({ modelYear: _action.data });
    draft.itemRisk.automobile.modelYear = automobile.modelYear;
  }),
  [itemRiskAutomobileTypes.setFabricationYear]: produce((draft, _action) => {
    const automobile = new Automobile({ fabricationYear: _action.data });
    draft.itemRisk.automobile.fabricationYear = automobile.fabricationYear;
  }),
  [itemRiskAutomobileTypes.setChassis]: produce((draft, _action) => {
    draft.itemRisk.automobile.chassis = _action.data;
  }),
  [itemRiskAutomobileTypes.setRenavam]: produce((draft, _action) => {
    draft.itemRisk.automobile.renavam = _action.data;
  }),
  [itemRiskAutomobileTypes.reset]: produce((draft) => {
    draft.itemRisk.automobile = rootInitialValues.itemRisk.automobile;
  }),
};

export default automobileHandlers;
