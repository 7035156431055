import { HiddenProps } from '../../models/hidden';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function HiddenWeb(props: HiddenProps) {
  const { Components, Theme } = useThemeProvider();
  const { Hidden } = Components;
  return (
    <ThemeProviderInjector>
      <Hidden {...props} />
    </ThemeProviderInjector>
  );
}

export default HiddenWeb;
