import { DialogTitle as MuiDialogTitle, IconButton, Typography, withStyles } from '@material-ui/core';
import { DialogTitleProps } from '@material-ui/core/DialogTitle/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useRef } from 'react';
import { BoxWeb } from '../../index';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.grey[600],
    right: theme.spacing(3),
    top: theme.spacing(2.5),
  },
});
export const DialogTitleAuthentication = withStyles(styles)(
  ({
    children,
    classes,
    onClose,
    id,
    ...other
  }: DialogTitleProps & { onClose?: () => void; classes?: { closeButton: any } }) => {
    const ref = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (ref.current) ref.current.focus();
      }, 300);
    }, []);

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <BoxWeb display='flex' flexDirection='row' width='100%'>
          {onClose ? (
            <IconButton aria-label='fechar' className={classes.closeButton} onClick={onClose}
                        style={{ padding: '2px', position: 'absolute' }} tabIndex={0} ref={ref}>
              <CloseIcon />
            </IconButton>
          ) : null}
          <Typography variant='h2' id={id} tabIndex={-1} style={{ width: '100%' }}>
            {children}
          </Typography>
        </BoxWeb>
      </MuiDialogTitle>
    );
  }
);
