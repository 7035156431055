import {
  getAuthentication,
  removeAuthentication,
  setAuthentication
} from '@workspace/frontend/auth/infra/repositories';
import { AuthStateManager } from '@web/state-management';
import { useEffect, useState, useRef } from 'react';
import { BoxWeb, LoadingWeb } from '@workspace/frontend/shared/presentation/components';
import { MyAccountRoutesEnum } from '../../enums/my-account-routes-enum';
import { refreshTokenFactory } from '@workspace/frontend/my-account/infra/factories';
import { RouteEnum } from '@workspace/frontend/auth/presentation/web-app';

enum INTERACTION_MFE_EVENT {
  EVENT = 'interactionEvent',
  REFRESH_TOKEN = 'refreshTokenEvent',
}

const addListener = (element: HTMLElement, event: EventListenerOrEventListenerObject) =>
  element?.addEventListener(INTERACTION_MFE_EVENT.EVENT, event);

const removeListener = (element: HTMLElement, event: EventListenerOrEventListenerObject) =>
  element?.removeEventListener(INTERACTION_MFE_EVENT.EVENT, event);

type ContextData = {
  token: string;
  apikey: string;
  inputdata: {
    id_beneficiario: string;
    userIdentifier: string;
  };
};
type Context = {
  data: ContextData;
  stringify: string;
};

export function HospitauMicrofrontendContainer() {
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState<Context>();
  const hospitauRef = useRef<HTMLElement>(null);
  let updatableContext: Context;

  const refreshTokenUsecase = refreshTokenFactory();

  const isEmpty = (param) => {
    try {
      const props = Object.keys(param);
      return props.length <= 0;
    } catch (error) {
      return true;
    }
  };

  const getContextAndUserData = () => {
    const { accessToken, identifier } = getAuthentication();
    const hospitauContext = AuthStateManager.contextHospitauSelector();

    const newContext: ContextData = {
      token: accessToken,
      apikey: hospitauContext.integrationKey,
      inputdata: {
        id_beneficiario: hospitauContext.identifier,
        userIdentifier: identifier
      }
    };

    updatableContext = {
      data: newContext,
      stringify: JSON.stringify(newContext)
    };

    setContext({
      data: newContext,
      stringify: JSON.stringify(newContext)
    });

    setLoading(false);
  };

  const logout = (): void => {
    removeAuthentication();
    AuthStateManager.userNameDispatcher(undefined);
    AuthStateManager.userAuthenticatedDispatcher(false);

    setTimeout(() => window.location.assign(RouteEnum.LOGIN), 100);
  };

  const interactionEvent = (event: CustomEvent<{ name: string; data: Record<string, unknown> }>) => {
    const { refreshToken } = getAuthentication();

    if (event?.detail?.name !== INTERACTION_MFE_EVENT.REFRESH_TOKEN) return;

    const payload = {
      token: refreshToken,
      userIdentifier: updatableContext?.data?.inputdata?.userIdentifier
    };

    refreshTokenUsecase
      .execute(payload, {
        Authorization: `Bearer ${getAuthentication().accessToken}`,
      })
      .then((response) => {
        const updateContext: ContextData = {
          ...updatableContext?.data,
          token: response?.data?.value.access_token
        };

        setAuthentication({
          ...getAuthentication(),
          refreshToken: response?.data?.value.refresh_token,
          accessToken: updateContext.token
        });

        updatableContext = {
          data: updateContext,
          stringify: JSON.stringify(updateContext)
        };

        setContext({
          data: updateContext,
          stringify: JSON.stringify(updateContext)
        });
      })
      .catch(() => {
        logout();
      });
  };

  useEffect(() => {
    isEmpty(getAuthentication()) ? window.location.assign(MyAccountRoutesEnum.LOGIN) : getContextAndUserData();

    setTimeout(() => addListener(hospitauRef?.current, interactionEvent), 300);

    return () => removeListener(hospitauRef?.current, interactionEvent);
  }, []);

  useEffect(() => {
  }, [context]);

  if (loading)
    return (
      <BoxWeb height={'100%'} minHeight={'400px'} display={'flex'} alignItems={'center'}>
        <LoadingWeb />
      </BoxWeb>
    );

  return (
    <BoxWeb display={{ xl: 'flex' }} justifyContent={{ xl: 'center' }} margin={'16px 0'}>
      {/*@ts-ignore*/}
      <mf-portalsegurado-hospitau id={'mc'} ref={hospitauRef} context={context.stringify} />
    </BoxWeb>
  );
}
