import styled from 'styled-components';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';

export const Aside = styled.aside`
  background-color: #efe9e5;
  border-radius: 10px;
  padding: 36px 24px;
`;

export const Title = styled.h2`
  color: #2b374a;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;
export const ContainerInput = styled(BoxWeb)`
  .MuiFormLabel-root.Mui-error {
    color: #781b8e;
  }
  @media (max-width: 1024px) {
    .MuiFormLabel-root {
      color: #2b374a;
    }
  }
`;

export const ContainerButton = styled(BoxWeb)`
  > .MuiButtonBase-root {
    background: #ec7000;
    > .MuiButton-label {
      color: #fff;
    }
  }
  .MuiButtonBase-root:hover {
    background: #ec7000;
  }
  @media (max-width: 1024px) {
    > .MuiButtonBase-root {
      background: #ec7000;
      > .MuiButton-label {
        color: #fff;
      }
    }
  }
`;




