import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';

export abstract class CachedNetworkStyle {
  private readonly networkCacheKey: string = 'auto:v2:cache';

  get networkStyleCache(): NetworkStyle | null {
    const stringifyNetworkCacheStyle = sessionStorage.getItem(this.networkCacheKey);
    if (stringifyNetworkCacheStyle) {
      return JSON?.parse(stringifyNetworkCacheStyle);
    }
    return null;
  }

  set networkStyleCache(networkStyle: NetworkStyle) {
    sessionStorage.setItem(this.networkCacheKey, JSON.stringify(networkStyle));
  }
}
