import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { AlertWeb, BoxWeb, LinkWeb } from '@workspace/frontend/shared/presentation/components';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  list-style-type: none;
`;

export function FormControllerErrorBanner() {
  const titleRef = useRef(null);

  const { formState } = useFormContext();

  const errors = formState.errors;
  const hasErrors = Object.keys(formState.errors).length !== 0;

  useEffect(() => {
    if (hasErrors && titleRef.current) {
      // @ts-ignore
      titleRef.current.focus();
    }
  }, [hasErrors, titleRef]);

  const classes = makeStyles({ button: { textAlign: 'initial' } })();

  return (
    <BoxWeb display={hasErrors ? 'block' : 'none'} my={'1rem'}>
      <AlertWeb severity={'error'}>
        <div ref={titleRef} tabIndex={-1}>
          Você ainda tem os seguintes erros:
        </div>
        <List>
          {Object.entries(errors).map(([key, val], idx) => {
            return (
              <li
                onClick={() => {
                  document.getElementsByName(key)[0].focus();
                }}
                key={idx}
              >
                <LinkWeb
                  component={'button'}
                  classes={{ button: classes.button }}
                  text={val.message}
                  id={`btnMessageError${key}`}
                />
              </li>
            );
          })}
        </List>
      </AlertWeb>
    </BoxWeb>
  );
}
