import { ModalProps } from '../../models/modal-props';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function ModalWeb({ children, ...props }: ModalProps) {
  const { Components } = useThemeProvider();
  const { Modal } = Components;

  return (
    <ThemeProviderInjector>
      <Modal {...props}>{children}</Modal>
    </ThemeProviderInjector>
  );
}

export default ModalWeb;
