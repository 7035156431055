import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { IRecoverPasswordRepository } from '@core/data/contracts/auth/recover-password-repository';
import { RecoverPasswordResponse } from '@core/domain/entities/auth/response/recover-password-response';

export class RecoverPasswordRepository implements IRecoverPasswordRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<{ email: string }, RecoverPasswordResponse>) {}

  async execute(email: string, headers?: any): Promise<RecoverPasswordResponse> {
    return await this.httpPostClient.post({ email }, null, headers);
  }
}
