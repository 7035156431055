import { ComponentMode, StyleMode, ThemeProviderWeb } from '../../';
import { ComponentAdapterTypes } from '../../infra/base/component-builder';
import { StyleAdapterTypes } from '../../infra/base/style-builder';

export function ThemeProviderInjectorMock({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.LOJA_DE_SEGUROS,
  };

  return (
    <ThemeProviderWeb rootName="autoV2" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}

export function ThemeProviderInjectorMobileMock({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  };
  const selectedStyle: any = StyleAdapterTypes.LOJA_DE_SEGUROS_MOBILE_TEST;
  const styleMode: StyleMode = {
    styleBase: selectedStyle,
  };

  return (
    <ThemeProviderWeb rootName="autoV2" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
export function ThemeProviderInjectorMockAuthentication({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
    componentOverrides: [ComponentAdapterTypes.AUTHENTICATION_UI],
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.AUTHENTICATION,
  };

  return (
    <ThemeProviderWeb rootName="auth" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}

export function ThemeProviderInjectorMockFAQV2({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
    componentOverrides: [ComponentAdapterTypes.FAQV2_UI],
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.FAQV2,
  };

  return (
    <ThemeProviderWeb rootName="faq" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}

export function ThemeProviderInjectorMockMyAccount({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
    componentOverrides: [ComponentAdapterTypes.MY_ACCOUNT_UI],
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.MY_ACCOUNT,
  };

  return (
    <ThemeProviderWeb rootName="my-account" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
