import { rest } from 'msw';

interface UserIDProps {
  userIdentifier: string;
}

export const getUserProductsByIdentifierMock = (env) => {
  return rest.post<UserIDProps>(`${env.gatewayBFFBaseURL.url}/produtos-usuario`, (req, res, ctx) => {
    const { userIdentifier } = req.body;

    if (userIdentifier === '') {
      return res(
        ctx.status(409),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'payloadIsRequired',
                message: 'Payload deve ser informado',
              },
            ],
          },
        })
      );
    }

    if (userIdentifier === 'tipo dado diferente') {
      return res(
        ctx.status(415),
        ctx.json({
          data: 'Unsupported Media Type',
        })
      );
    }

    if (userIdentifier === 'erro') {
      return res(
        ctx.status(417),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'systemicError',
                message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
              },
            ],
          },
        })
      );
    }

    if (userIdentifier === 'instabilidade api') {
      return res(
        ctx.status(200),
        ctx.json({
          data: {
            success: true,
            errors: [],
            value: {
              products: [
                {
                  type: 1,
                  identifier: '01134304854',
                  error: {
                    code: 'systemicError',
                    message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
                  },
                  isValid: false,
                },
              ],
            },
          },
        })
      );
    }

    if (userIdentifier === 'problema cpf') {
      return res(
        ctx.status(200),
        ctx.json({
          success: true,
          errors: [],
          value: {
            products: [
              {
                type: 1,
                identifier: '17632981080',
                error: {
                  code: 'hospitauError',
                  message: 'Cliente com problema no cadastro de CPF, entrar em contato para regularização.',
                },
                isValid: false,
              },
            ],
          },
        })
      );
    }

    if (userIdentifier === 'não possui hospitau') {
      return res(
        ctx.status(200),
        ctx.json({
          success: true,
          errors: [],
          value: {
            products: [
              {
                type: 1,
                identifier: '17632981080',
                error: {
                  code: 'hospitauError',
                  message: 'Cliente não possui produto do Hospitaú.',
                },
                isValid: false,
              },
            ],
          },
        })
      );
    }

    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        errors: [],
        value: {
          integrationKey: '15f799a3-b211-411c-96a9-fd49472c441a',
          products: [
            {
              type: 1,
              identifier: '17309497899',
              isValid: true,
            },
          ],
        },
      })
    );
  });
};
