import React from 'react';
import { DropdownProps } from '../../models/dropdown';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function DropdownHeaderWeb(props: DropdownProps) {
  const { Components } = useThemeProvider();
  const { DropdownHeader } = Components;

  return (
    <ThemeProviderInjector>
      <DropdownHeader {...props} />
    </ThemeProviderInjector>
  );
}

export default DropdownHeaderWeb;
