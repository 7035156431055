import { AuthenticationRepositoryContract } from '@workspace/frontend/shared/domain/contracts';
import { AuthenticationRepository } from '@workspace/frontend/shared/infra/repositories/session-storage';

abstract class AuthenticationRepositoryFactory {
  private static authenticationRepository: AuthenticationRepositoryContract;

  public static get instance(): AuthenticationRepositoryContract {
    if (!this.authenticationRepository) {
      this.authenticationRepository = new AuthenticationRepository();
    }

    return this.authenticationRepository;
  }
}

export { AuthenticationRepositoryFactory };
