import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';

import { GetFaqQuestionUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';
import { GetFAQQuestionRepository } from '@workspace/frontend/faq-v2/infra/repositories';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { FAQQuestionResponse } from '@workspace/frontend/faq-v2/domain/entities';

export function getFAQQuestionFactory() {
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQQuestionResponse> = new AxiosHttpGetClient(
    `/content_types/faq_pergunta_resposta/entries?environment=${environment?.cmsBase?.environment}&asc=posicao`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQQuestionRepository = new GetFAQQuestionRepository(httpGetClient);
  return new GetFaqQuestionUseCase(getFAQQuestionRepository);
}
