import { FormControllerFieldConnector } from '@web/form';
import { SelectWeb } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@web/validation';

export const PlateFoundBrandAndModelField = (props) => {
  return (
    <FormControllerFieldConnector
      component={SelectWeb}
      name="brandAndModel"
      componentProps={{
        ...props,
        label: 'marca e modelo do carro',
      }}
      validation={stringValidationFactory({})}
      disabled
    />
  );
};
