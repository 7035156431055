import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { IGetFAQProductRepository } from '@workspace/frontend/faq-v2/domain/contracts';
import { FAQProductResponse, Product } from '@workspace/frontend/faq-v2/domain/entities';

export class GetFAQProductRepository implements IGetFAQProductRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQProductResponse>) {}

  async execute(): Promise<Product[]> {
    const response: FAQProductResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
