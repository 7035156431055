import { Button } from '@material-ui/core';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';
import { CategoryText } from '../..';

interface HeroItemProps {
  image: string;
}
export const HeroItemStyled = styled(BoxWeb)<HeroItemProps>`
  background-position: center;
  background-size: cover;
  display: flex;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.palette.grey[200]};
  background-image: url(${(props) => props.image});
`;

export const ClickableArea = styled(Button)`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: flex-end;
  background-color: transparent;
  height: auto;
  border: none;
  text-decoration: none;
  &:active {
    color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
`;

export const StyledPaper = styled(BoxWeb)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 0;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(1, 1)};
    font-size: 15px;
    margin: ${(props) => props.theme.spacing(1, 1)};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: ${(props) => props.theme.spacing(0, 5, 3, 5)};
    padding: ${(props) => props.theme.spacing(2, 3)};
  } ;
`;

export const PostCategory = styled(CategoryText)`
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;
