import { FormControl, InputLabel, Select, styled } from '@material-ui/core';
import { SelectWebProps } from '../../models/select-web-props';
import FormHelperText from '@material-ui/core/FormHelperText';

const InputCustomLabel = styled(InputLabel)(() => ({
  top: '-0.3rem',
  fontSize: '16px',
}));

const CustomSelect = (props: SelectWebProps) => (
  <>
    <FormControl fullWidth variant="outlined" disabled={props.disabled} required={props.required}>
      <InputCustomLabel htmlFor={props.id}>{props.label}</InputCustomLabel>
      <Select
        native
        fullWidth
        inputProps={{
          name: props.name,
          id: props.id,
          'data-testid': props.id,
          'aria-label': props.label,
        }}
        {...props}
      >
        {props.items.length > 1 && <option aria-label="nenhuma" value="" data-testid={`option-${props.id}`} />}
        {props.items.map((item, idx) => (
          <option value={item.key} data-testid={`option-${props.id}`} key={idx}>
            {item.value}
          </option>
        ))}
      </Select>
      <FormHelperText id={props.id + '-helper-text'} error={props.error}>
        {props.helperText}
      </FormHelperText>
    </FormControl>
  </>
);

export const SelectMui = styled(CustomSelect)(() => ({
  width: '100%',
  height: '48px',
}));
