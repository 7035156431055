import { DefaultSwitchProps, SwitchWeb } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';

export const ZeroKmField = ({ onChange, disabled = false }: { onChange: (e) => void; disabled: boolean }) => (
  <FormControllerFieldConnector<DefaultSwitchProps>
    component={SwitchWeb}
    name="zeroKm"
    onChange={onChange}
    translator={{ value: 'checked' }}
    componentProps={{
      label: 'Zero km?',
      disabled,
      id: 'chkVehicle0Km_screenCarInformation',
    }}
  />
);
