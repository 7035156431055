import {
  BoxWeb,
  HiddenWeb,
  StepperProgressWeb,
  TypographyWeb,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { GreenBox } from '@workspace/frontend/auto/presentation/components';
import React, { useEffect, useState } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { EnvironmentContextProps, useEnvironment } from "@workspace/providers";
import { useOriginContext } from '../../providers/origin-provider';

export function Success({ proposalNumber, haveProposal, enableCampanhaAuto }) {
  const fixedSteps = [
    {
      label: 'recebimento do e-mail com resumo da proposta do seu seguro',
    },
    {
      label:
        'agendamento ou dispensa de vistoria, análise da proposta e recebimento de um e-mail de boas-vindas com as informações do seu seguro',
    },
    {
      label: 'você poderá começar a utilizar seu seguro e curtir seu veículo com toda proteção',
    },
  ];
  const autocampaign = () => enableCampanhaAuto ? 'fique ligado no telefone que você nos indicou, em até 1 dia útil um dos nossos especialistas entrará em contato' : 'agora basta aguardar que um dos nossos especialistas entrará em contato com você';

  return !haveProposal ? (
    <BoxWeb position="relative" bgcolor="success.main" mt="17rem" pt={'12rem'} pb={'.5rem'}>
      <GreenBox>sua cotação foi enviada com sucesso!</GreenBox>
      <BoxWeb mx="auto" color="#FFFFFF" textAlign="center" fontFamily="Itau Text" maxWidth={'30rem'}>
        <BoxWeb fontSize={'1.5rem'} pt={'6rem'} pb={'12rem'}>
          {autocampaign()}
        </BoxWeb>
      </BoxWeb>
    </BoxWeb>
  ) : (
    <>
      <BoxWeb position="relative" bgcolor="success.main" mt="17rem" pt={'12rem'} pb={'.5rem'}>
        <GreenBox>sua cotação foi enviada com sucesso!</GreenBox>

        <BoxWeb m="auto" color="#FFFFFF" textAlign="left" fontFamily="Itau Text" maxWidth={1440}>
          <BoxWeb px={'2rem'} fontSize={{ xs: '22px', sm: '32px' }} id="lblFeedback_screenConfirmation" display="flex">
            <span>o número da sua proposta é:</span>
            <b>{proposalNumber}</b>
          </BoxWeb>

          <BoxWeb py={'2rem'}>
            <BoxWeb
              component={'h2'}
              color="#FFFFFF"
              textAlign="left"
              fontWeight={400}
              fontSize={'1.25rem'}
              fontFamily="Itau Text"
              aria-label={`Confira os próximos passos abaixo`}
              tabIndex={-1}
            >
              <BoxWeb component={'span'} px={'2rem'} aria-hidden>
                confira os próximos passos abaixo
              </BoxWeb>
            </BoxWeb>

            <HiddenWeb mdUp>
              <StepperProgressWeb steps={fixedSteps} activeStep={1} orientation={'vertical'} alternativeLabel={false} />
            </HiddenWeb>
            <HiddenWeb smDown>
              <StepperProgressWeb
                steps={fixedSteps}
                activeStep={1}
                orientation={'horizontal'}
                alternativeLabel={true}
              />
            </HiddenWeb>
          </BoxWeb>
        </BoxWeb>
      </BoxWeb>
      <BoxWeb
        py={{ xs: '1rem', sm: '1.5rem' }}
        fontSize={{ xs: '12px', sm: '16px' }}
        px={'2rem'}
        bgcolor={'background.lightSecondary'}
      >
        <TypographyWeb component={'p'}>
          Para cancelamento em até 7 dias da data da compra, você receberá o estorno do valor total do seguro, conforme
          o Código de Defesa do Consumidor
        </TypographyWeb>
      </BoxWeb>
      {/*<FormController onSubmit={console.log}>*/}
      {/*  {({ watch }) => {*/}
      {/*    const satisfactionLevel = watch('satisfactionLevel');*/}
      {/*    return <LikertSection satisfactionLevel={satisfactionLevel} />;*/}
      {/*  }}*/}
      {/*</FormController>*/}{' '}
    </>
  );
}

export default SuccessContainer;

function SuccessContainer() {
  const [proposalNumber, setProposalNumber] = useState<string | null>();
  const { Theme } = useThemeProvider();

  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const enableAutov2Hiring = partialEnvironment.environment.enableAutov2Hiring;
  const enableCampanhaAuto = partialEnvironment.environment.enableCampanhaAuto;
  const leadParams = JSON.parse(new URLSearchParams(window.location.search).get('lead'));
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);

  // @ts-ignore
  useEffect(() => {
    setTimeout(() => {
      const { simulationNumber, proposalNumber } = AutoStateManager.successSelector();
      autoV2analyticsCalls.HIRING.SUCCESS.LOAD(!!simulationNumber);
      setProposalNumber(proposalNumber);
    }, 100);

    return () => AutoStateManager.clearStore();
  }, []);

  return (
    <SCThemeProvider theme={Theme}>
      <Success proposalNumber={proposalNumber} haveProposal={enableAutov2Hiring && !leadParams} enableCampanhaAuto={enableCampanhaAuto}/>
    </SCThemeProvider>
  );
}
