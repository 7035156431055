import { CreateCartAppServiceContractInput, CreateCartSerializerContract } from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import { CreateCartPayload } from '@workspace/utils/abandoned-cart';
import { CreateCartAutoStrategySerializer } from './create-cart-auto-strategy.serializer';
import { CreateCartOdontoStrategySerializer } from './create-cart-odonto-strategy.serializer';
import { CreateCartResidStrategySerializer } from './create-cart-resid-strategy.serializer';
import { CreateCartStrategySerializer } from './create-cart-strategy.serializer';
import {ContextsEnum} from "@workspace/frontend/shared/abandoned-cart/application/enums";

export class CreateCartSerializer implements CreateCartSerializerContract {

    private strategies: Record<ContextsEnum, CreateCartStrategySerializer> = {
      [ContextsEnum.AUTO]: new CreateCartAutoStrategySerializer(),
      [ContextsEnum.ODONTO]: new CreateCartOdontoStrategySerializer(),
      [ContextsEnum.RESID]: new CreateCartResidStrategySerializer(),
    };

    public execute(input: CreateCartAppServiceContractInput): CreateCartPayload {
      const result = this.strategies[input.context].execute(input);
      return result;
    }
}
