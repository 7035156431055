import { useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core';

export const TooltipMui = styled(({ ...props }: TooltipProps) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleShowTooltip = (): void => {
    setOpenTooltip((oldProp: boolean) => !oldProp);
  };

  return (
    <Tooltip {...props} open={openTooltip} onClick={handleShowTooltip} aria-label={props.title.toString()}>
      <IconButton>{props.children ? props.children : <HelpIcon />}</IconButton>
    </Tooltip>
  );
})(({ theme }) => ({
  padding: 0,
  color: theme.palette.primary.main,
  alignItems: 'flex-start',
}));
