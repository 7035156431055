import { AxiosHttpGetClient } from '../../../../../core/src/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '../../../../../core/src/data/contracts/http/http-get-client';
import { GetDynamicLandPageUseCase } from '../../../../../core/src/data/services/get-dynamic-landpage-usecase';
import { GetDynamicLandPageRepository } from '../../../../../core/src/infra/repository/landings/get-dynamic-landpage-repository';
import { EnvironmentContextProps } from '@workspace/providers';
import { DynamicLandPageResponse } from '../../../../../core/src/domain/entities/dynamic-landings/dynamic-landpage-response';

export function getDynamicLandingsFactory({ environment }: Partial<EnvironmentContextProps>) {
  const httpGetClient: IHttpGetClient<null, DynamicLandPageResponse> = new AxiosHttpGetClient(
    `/content_types/${environment?.cmsBase?.contentType}/entries?environment=${environment?.cmsBase?.environment}`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getDynamicLandpageRepository = new GetDynamicLandPageRepository(httpGetClient);
  const getDynamicLandpageUsecase = new GetDynamicLandPageUseCase(getDynamicLandpageRepository);
  return getDynamicLandpageUsecase;
}
