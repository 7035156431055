import { WebComponentBuilder } from '@workspace/frontend/shared/presentation/webcomponent-builder';
import { InsuranceStoreAuto } from './insurance-store-auto';
import { TemporaryFeatFlag, InsuranceHiringAutoSuperApp, InsuranceHiringSuccessAutoSuperApp} from './insurance-hiring-auto';

export enum WebcomponentModule {
  LOJA_DE_SEGUROS_AUTO = 'loja-seguros-auto',
  LOJA_DE_SEGUROS_AUTO_CONTRATACAO = 'loja-seguros-auto-contratacao',
  LOJA_DE_SEGUROS_AUTO_CONTRATACAO_SUPERAPP = 'loja-seguros-auto-contratacao-superapp',
  LOJA_DE_SEGUROS_AUTO_CONTRATACAO_SUCESSO_SUPERAPP = 'loja-seguros-auto-contratacao-sucesso-superapp',
}

export const createInsuranceStoreAutoWebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env).create(WebcomponentModule.LOJA_DE_SEGUROS_AUTO, InsuranceStoreAuto).builder();
};

export const createInsuranceHiringAutoWebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env)
    .create(WebcomponentModule.LOJA_DE_SEGUROS_AUTO_CONTRATACAO, TemporaryFeatFlag)
    .builder();
};
export const createInsuranceHiringSuperappAutoWebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env)
    .create(WebcomponentModule.LOJA_DE_SEGUROS_AUTO_CONTRATACAO_SUPERAPP, InsuranceHiringAutoSuperApp)
    .builder();
};

export const createInsuranceHiringSuccessSuperAppAutoWebComponent = (env) => {
  WebComponentBuilder.setEnvironment(env)
    .create(WebcomponentModule.LOJA_DE_SEGUROS_AUTO_CONTRATACAO_SUCESSO_SUPERAPP, InsuranceHiringSuccessAutoSuperApp)
    .builder();
};
