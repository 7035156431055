import {
  stringValidationFactory,
  IStringValidationFactory,
  StringValidationSchema,
} from '../validation-generators/string-validation-factory';
import { Cep } from '@workspace/entities';

export function cepValidation(overrides: IStringValidationFactory = {}): StringValidationSchema {
  const defaultValues: IStringValidationFactory = {
    requiredMessage: 'você precisa informar o CEP',
    test: [],
    matches: {
      pattern: Cep.REGEX,
      message: 'CEP inválido',
    },
  };

  const values = Object.assign(defaultValues, overrides);

  return stringValidationFactory({
    requiredMessage: values.requiredMessage,
    matches: values.matches,
    test: values.test,
  });
}
