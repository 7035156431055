import {
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  ANALYTICS_EVENT_SQUADS,
  assembleAnalyticsPageLoadEvent,
  PageLoadAnalyticsInterface,
} from '@workspace/web/analytics';

type T = 'T1' | 'T2' | 'T3' | 'T4' | 'T5' | 'T6' | 'T7' | 'T8' | 'T9' | 'T10' | 'T11' | 'T12';
export const pages: Record<T, string> = {
  T1: 'LS:NL:NCC:Auto:Auto2.0:DadosDoCondutorEVeiculo:Cpf',
  T2: 'LS:NL:NCC:Auto:Auto2.0:DadosDoCondutorEVeiculo:Veiculo',
  T3: 'LS:NL:NCC:Auto:Auto2.0:DadosDoCondutorEVeiculo:Cep',
  T4: 'LS:NL:NCC:Auto:Auto2.0:Cotacao',
  T5: 'LS:NL:NCC:Auto:Auto2.0:FormLead',
  T6: 'LS:NL:NCC:Auto:Auto2.0:Sucesso',
  T7: 'LS:NL:NCC:Auto:Auto2.0:Erro',
  T8: 'LS:NL:NCC:Auto:Auto2.0:Contratacao:DadosDoTitular',
  T9: 'LS:NL:NCC:Auto:Auto2.0:Contratacao:Pagamento',
  T10: 'LS:NL:NCC:Auto:Auto2.0:Contratacao:DispensaVistoria',
  T11: 'LS:NL:NCC:Auto:Auto2.0:Contratacao:Sucesso',
  T12: 'LS:NL:NCC:Auto:Auto2.0:SucessoSemCotacao',
};
export type PageLoadAutoV2HiringTechSpecI = (origin: string) => Record<T, PageLoadAnalyticsInterface>;

const analyticsPageData = (nome: string, options?: Partial<{responsibleSquadTag: string, }>) => ({ 
  ...assembleAnalyticsPageLoadEvent({
    nome,
    idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
    ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
  },
  null,
  { ...options, origin }
), })

export const PageLoadAutoV2HiringTechSpec: PageLoadAutoV2HiringTechSpecI = (origin) => ({
  T1: { ...analyticsPageData(pages.T1), },
  T2: { ...analyticsPageData(pages.T2), },
  T3: { ...analyticsPageData(pages.T3), },
  T4: { ...analyticsPageData(pages.T4), },
  T5: { ...analyticsPageData(pages.T5), },
  T6: { ...analyticsPageData(pages.T6), },
  T7: { ...analyticsPageData(pages.T7), },
  T8: { ...analyticsPageData(pages.T8), },
  T9: { ...analyticsPageData(pages.T9), },
  T10: { ...analyticsPageData(pages.T10), },
  T11: { ...analyticsPageData(pages.T11), },
  T12: { ...analyticsPageData(pages.T12, {responsibleSquadTag: ANALYTICS_EVENT_SQUADS.CDPFMA}), }, // rever os dados
});
