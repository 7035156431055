import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { OfferResponse } from '@core/data/models/autov2/response/offer-response';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { AxiosPostRepository } from '@workspace/frontend/auto/infra/repositories';
import { GetOffersUseCase } from '@workspace/frontend/auto/domain/use-cases';

export function getOffersFactory() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const { environment } = partialEnvironment;
  const httpPostClient: IHttpPostClient<null, OfferResponse> = new AxiosHttpPostClient(
    environment.autoV2Offers.path,
    {},
    environment.gatewayBFFBaseURL.url
  );
  const getOffersRepository = new AxiosPostRepository<null, OfferResponse>(httpPostClient);
  return new GetOffersUseCase(getOffersRepository);
}
