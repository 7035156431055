import { rest } from 'msw';
import residSimulacao from './../resid/simulacoes.json';

export const searchAssistances = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/simulacoes/assistencias`, (req: any, res, ctx) => {
    const { cep } = req?.body?.item_risco?.imovel?.endereco;
    const invalidCEPs = ['03021090' ,'03570490' ,'03625090' ,'04116190' ,'04330090' ,'06539390' ,'09341190' ,'09980690' ,'42825901'];

    if (invalidCEPs.includes(cep)) {
      return res(ctx.status(422), ctx.json({status: 422}));
    }

    return res(ctx.status(200), ctx.json(residSimulacao));
  });
};