import { BoxWeb, ButtonWeb, HiddenWeb, ModalWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';
import { autov2Images } from '@web/assets';

const { requiredInspectionCar, portoInspectionLogo, appStoreLogo, playStoreLogo, appStoreQRCode, playStoreQRCode } =
  autov2Images;

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 22px;
  margin-bottom: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-bottom: 1.5rem;
  }
`;

const ModalSubtitle = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  line-height: 24px;
`;

const ModalActionDescription = styled.p`
  color: #6b625b;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.5rem;
`;

const Image = styled.img`
  position: absolute;
  top: 2rem;
  right: 4rem;
  height: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    top: 4rem;
    right: 1rem;
  }
`;

const ListItem = styled.div`
  color: #6b625b;
  font-size: 14px;
  line-height: 24px;
`;

const ListItemCounter = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  line-height: 24px;
  margin-top: 1rem;
`;

const LogoImg = styled.img`
  width: 120px;
`;

const QRCodeImg = styled.img`
  width: 110px;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;
const ButtonWrapper = styled.div`
  width: max-content;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
    margin-top: 0.5rem;
  }
`;
type RequiredCarInspectionModalProps = {
  onClose: any;
  open: boolean;
};
export function RequiredCarInspectionModal({ onClose, open }: RequiredCarInspectionModalProps) {
  return (
    <ModalWeb open={open}>
      <BoxWeb p={{ md: '4rem', xs: '2rem' }} pb="2rem">
        <BoxWeb display="flex">
          <BoxWeb flex={{ lg: '0.5', xs: '0.6' }}>
            <ModalTitle>Agendamento da vistoria.</ModalTitle>
            <ModalSubtitle tabIndex={0}>
              Para fecharmos sua proposta, falta somente a vistoria do veículo e ela pode ser realizada por você.
            </ModalSubtitle>
          </BoxWeb>
          <BoxWeb flex={{ xs: '0.4', lg: '0.2' }}>
            <Image src={requiredInspectionCar} aria-hidden />
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display="flex" mt="1rem">
          <BoxWeb>
            <ModalActionDescription tabIndex={0}>Para a vistoria, basta seguir os passos abaixo:</ModalActionDescription>
            <BoxWeb role="list">
              <ListItem role="listitem" tabIndex={0}>
                <ListItemCounter>1 - </ListItemCounter>Baixe o aplicativo buscando pelo app "Vistoria Prévia Porto
                Seguro” ou diretamente pelo QR Code usando a câmera do seu smartphone :
                <LogoWrapper>
                  <LogoImg src={portoInspectionLogo} alt="Vistoria Prévia Porto Seguro Logo" />
                  <QRCodeWrapper>
                    <LogoImg src={appStoreLogo} aria-hidden />
                    <HiddenWeb smDown>
                      <QRCodeImg src={appStoreQRCode} alt="qrcode para Vistoria Prévia Porto Seguro na App Store" />
                    </HiddenWeb>
                  </QRCodeWrapper>
                  <QRCodeWrapper>
                    <LogoImg src={playStoreLogo} aria-hidden />
                    <HiddenWeb smDown>
                      <QRCodeImg src={playStoreQRCode} alt="qrcode para Vistoria Prévia Porto Seguro na Play Store" />
                    </HiddenWeb>
                  </QRCodeWrapper>
                </LogoWrapper>
              </ListItem>
              <ListItem role="listitem" tabIndex={0}>
                <ListItemCounter>2 - </ListItemCounter>abra o aplicativo
              </ListItem>
              <ListItem role="listitem" tabIndex={0}>
                <ListItemCounter>3 - </ListItemCounter>insira as informações solicitadas
              </ListItem>
              <ListItem role="listitem" tabIndex={0}>
                <ListItemCounter>4 - </ListItemCounter>selecione o veículo
              </ListItem>
              <ListItem role="listitem" tabIndex={0}>
                <ListItemCounter>5 - </ListItemCounter>realize a vistoria
              </ListItem>
            </BoxWeb>
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display="flex" justifyContent="center">
          <ButtonWrapper>
            <ButtonWeb label="Ok, continuar" onClick={onClose} />
          </ButtonWrapper>
        </BoxWeb>
      </BoxWeb>
    </ModalWeb>
  );
}
