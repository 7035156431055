import { EnvironmentContextProps } from "@workspace/providers";
import { IHttpGetClient } from "@core/data/contracts/http/http-get-client";
import { AxiosHttpGetClient } from "@core/infra/axios/axios-http-get-client";
import { SearchCep } from "@core/infra/models/search-cep";
import { SearchCepResponse } from "@core/domain/entities/autov2/response/search-cep";
import { SearchCepRepository } from "@core/infra/repository/autov2/search-cep-repository";
import { SearchCepUsecase } from "@core/data/services/search-cep-usecase";

type SearchCepFactoryProps = {
  partialEnvironment: Partial<EnvironmentContextProps>;
  onError: (error) => void;
  cepIn?: string;
}

// https://mktseguros-api.hom.cloud.itau.com.br/v1/codigo-enderecamento-postal/endereco?cep=13101-400

export function searchCepFactory({ partialEnvironment, onError}: SearchCepFactoryProps) {
  const { environment } = partialEnvironment;
  const httpGetClient: IHttpGetClient<{ params: SearchCep }, { data: SearchCepResponse }> = new AxiosHttpGetClient(`/v1/codigo-enderecamento-postal/endereco`, {}, environment.gatewayAWSBaseURL.url);

  const searchCepRepository = new SearchCepRepository(httpGetClient);
  return new SearchCepUsecase(searchCepRepository, onError);
}
