import { AccordionDefaultProps } from '../../models/accordion';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export const AccordionWeb = (props: AccordionDefaultProps) => {
  const { Components } = useThemeProvider();
  const { Accordion } = Components;
  return (
    <ThemeProviderInjector>
      <Accordion {...props} />
    </ThemeProviderInjector>
  );
};
