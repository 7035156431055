import styled from 'styled-components';

export const SectionCoveragesCards = styled.div`
  display: flex;
  margin: 4rem auto;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  @media (min-width: 100px) {
    padding: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const CoverageItem = styled.div<any>`
  z-index: 3;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 40px;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 110px;
  box-sizing: border-box;
  @media (min-width: 100px) {
    margin-bottom: 40px;
    width: 350px;
  }
`;

export const CoverageImageItem = styled.img`
  z-index: 3;
  display: flex;
  border: 1 solid #d5dadc;
  border-radius: 8px;
  min-width: 70px;
`;

export const CoverageTextItemWrapper = styled.div`
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const CoverageTextItemTitle = styled.span<any>`
  font-family: ItauText-Bold;
  font-size: 16px;
  margin-bottom: 8px;
  @media (min-width: 100px) {
  }
`;

export const CoverageTextItemContent = styled.span<any>`
  font-family: ItauText-Regular;
  font-size: 14px;
  width: 100%;
  @media (min-width: 100px) {
  }
`;
