import { ISendNicknameRepository } from '../../../data/contracts/my-account/send-nickname-repository';
import { SendNicknameResponse } from '../../../domain/entities/my-account/response/send-nickname-response';
import { IHttpPostClient } from '../../../data/contracts/http/http-post-client';
import { SendNickname } from '@core/infra/models/send-nickname';

export class SendNicknameRepository implements ISendNicknameRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<SendNickname, SendNicknameResponse>) {}

  async execute(props: SendNickname, headers?: any): Promise<SendNicknameResponse> {
    return await this.httpPostClient.post(props, null, headers);
  }
}
