import styled, { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Title, TitleBold } from '../style';
import { BoxWeb, NavigateButtonsWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { authImages, translateToMicrofrontendPath } from '@web/assets';
import { useEffect, useRef, useState } from 'react';
import { Loading } from '../../components/loading/loading';
import { RouteEnum } from '../../enums/route-enum';
import { useAuthenticationParams } from '../../providers/authentication-params-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthOriginProvider } from '../../providers/auth-origin-provider';
import { useAuthProductProvider } from '../../providers/auth-product-provider';

const SucessContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 861px;
  padding: 0 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RegisteredUserImage = styled.div`
  width: 350px;
  height: 280px;
  background-image: url(${translateToMicrofrontendPath(authImages.registeredUser)});
  margin-bottom: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-bottom: 0;
  }
`;

export const RegisteredUserContainer = () => {
  const { Theme } = useThemeProvider();
  const [email, setEmail] = useState<string>('');
  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.USER_ALREADY_REGISTERED;

  const { getRegisteredLoginURLBase } = useAuthenticationParams();
  const titleRef = useRef(null);

  const handleContinue = () => {
    analyticsAuth.LOGIN();
    const loginURLBase = getRegisteredLoginURLBase();
    window.location.assign(loginURLBase);
  };

  const handleBack = () => {
    analyticsAuth.BACK();
    window.location.assign(RouteEnum.REGISTER);
  };

  useEffect(() => {
    analyticsAuth.LOAD();
    const emailParam = new URLSearchParams(window.location.search).get('email');

    if (!emailParam) {
      handleContinue();
      return;
    }

    setEmail(emailParam);

    setTimeout(() => {
      if (titleRef && titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  const Container = styled.div`
    max-width: 1260px;
    padding: 63px 0 80px;
    margin: 0 auto;
  `;

  return email ? (
    <SCThemeProvider theme={Theme}>
      <Container tabIndex={0}>
        <SucessContainer>
          <BoxWeb maxWidth="440px">
            <BoxWeb margin="0 0 8px" display="flex">
              <Title tabIndex={-1} ref={titleRef}>
                você já tem conta
              </Title>
              <BoxWeb
                role="img"
                aria-hidden={true}
                component="span"
                color=" #2b374a"
                fontFamily="'ItauDisplay-Light', 'sans-serif'"
                fontWeight=" normal"
                fontSize=" 1.375rem"
                lineHeight="2.09125rem"
                marginLeft="8px"
              >
                :)
              </BoxWeb>
            </BoxWeb>
            <BoxWeb margin="0 0 34px 0">
              <TitleBold>é só fazer seu login</TitleBold>
            </BoxWeb>
            <BoxWeb margin="0 0 51px 0" component="p" fontSize="18px" lineHeight="23px" maxWidth="332px">
              Use o e-mail {email} para fazer login novamente.
            </BoxWeb>
            <NavigateButtonsWeb
              continueText="fazer login"
              widthContinueButton="183px"
              widthBackButton="188px"
              onClickContinue={handleContinue}
              onClickBack={handleBack}
            />
          </BoxWeb>
          <RegisteredUserImage />
        </SucessContainer>
      </Container>
    </SCThemeProvider>
  ) : (
    <Loading />
  );
};
