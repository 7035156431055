import { FormControllerFieldConnector } from '@web/form';
import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { emailValidation, IStringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const EmailField = ({
  prefix = undefined,
  componentProps,
}: {
  prefix?: string;
  validationOverrides?: IStringValidationFactory;
  componentProps?: InputWebProps;
}) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name={prefix ? prefix + '_email' : 'email'}
    validation={emailValidation()}
    componentProps={componentProps}
  />
);
