import { rest } from 'msw';

export const authenticateSmsToken = (env) => {
  return rest.post(
    `${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-autenticacao/autenticacao/sms/token`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          id_contratacao: 9039758,
          cpf: '22938419368',
        })
      );
    }
  );
};
