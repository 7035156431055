export const getMonth = (num: number) => {
  const monthText = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  const monthText2 = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return {
    data: monthText[num],
    dateAcessibility: monthText2[num],
  };
};

export const dateFormat = (date: string) => {
  const dateFormated = date.substring(0, 10).split('-');
  const month = getMonth(parseInt(dateFormated[1]) - 1);

  return {
    date: dateFormated[2] + ' ' + month.data + ' ' + dateFormated[0],
    dateAcessibility: dateFormated[2] + ' ' + 'de' + ' ' + month.dateAcessibility + ' ' + 'de' + ' ' + dateFormated[0],
  };
};

export const timeToRead = (text: string) => {
  if (text.length == 0) return '0';
  const qtdWords = text.split(' ').length;
  return String(Math.round(qtdWords / 200)) + ' min';
};

const removeSpecialCharactersAndAccents = (string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[:;!./?]/g, '');

const wordToUpperCamelCase = (word) => word.charAt(0).toUpperCase() + word.slice(1);

export const phraseToUpperCamelCase = (phrase) => {
  const splittedAndUpperCamelCasedPhrase = JSON.stringify(phrase).split(' ').map(wordToUpperCamelCase);
  return JSON.parse(removeSpecialCharactersAndAccents(splittedAndUpperCamelCasedPhrase.join('')));
};
