import * as yup from 'yup';
import { BooleanSchema } from 'yup';
import mixedValidationFactory from './mixed-validation-factory';

export function booleanValidationFactory({ ...mixedValidationProps }: BooleanSchema) {
  let yupChain = yup.boolean().default(false);

  yupChain = mixedValidationFactory<BooleanSchema<boolean>>({ ...mixedValidationProps, chainableSchema: yupChain });

  return yupChain;
}
