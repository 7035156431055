import styled from 'styled-components';

interface ProductCardProps {
  imgSrc: string;
  title: string;
  onClick: () => void;
  selected: boolean;
}

interface CardSelectedProps {
  selected: boolean;
}

const CardContainer = styled.div`
  cursor: pointer;

  width: 176px;
  height: 140px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 120px;
    height: 96px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 98px;
    height: 78px;
  }
`;

const CardImg = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 33px;
    height: 33px;
    margin-top: 13px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 27px;
    height: 27px;
    margin-top: 11px;
  }
`;

const CardLabel = styled.span<CardSelectedProps>`
  width: 100px;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  color: #2b374a;
  text-transform: lowercase;
  margin-top: 6px;
  cursor: pointer;
  transition: 0.5s;
  font-family: ${(props) => (props.selected ? 'ItauDisplay-Bold' : 'ItauDisplay-Light')};
  font-weight: ${(props) => (props.selected ? 800 : 300)};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 12px;
    line-height: 15px;
    width: 65px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 10px;
    line-height: 13px;
    width: 55px;
  }
`;

const CardMark = styled.div<CardSelectedProps>`
  height: 4px;
  width: 97px;
  transition: 0.5s;
  margin-top: auto;
  background-color: #ec7000;
  opacity: ${(props) => (props.selected ? 1 : 0)}; ;
`;

export const ProductCard = ({ imgSrc, title, onClick, selected }: ProductCardProps) => {
  return (
    <CardContainer onClick={onClick}>
      <CardImg src={imgSrc} alt={''} />
      <CardLabel selected={selected}>{title}</CardLabel>
      <CardMark selected={selected} />
    </CardContainer>
  );
};
