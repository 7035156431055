import { ANALYTICS_EVENT_IDPF } from '../constants/idpf';
import { ANALYTICS_EVENT_CHANNEL_TYPES } from '../constants/channelTypes';
import { ANALYTICS_EVENT_SEGMENTS } from '../constants/segments';
import { ANALYTICS_EVENT_RULES } from '../constants/rules';
import { ANALYTICS_EVENT_BUSINESS } from '../constants/business';
import { PageLoadAnalyticsInterface, PageLoadEventCustomAnalyticsInterface, PageLoadEventPageAnalyticsInterface } from './analytics';
import { replaceLabelTokensByValues } from './replace-label-tokens-by-values';
import { ANALYTICS_EVENT_ENVIRONMENTS } from '../constants/environments';
import { ANALYTICS_EVENT_SQUADS } from '../constants/squads';
import mapper from './mapper';

export const assembleAnalyticsPageLoadEvent = (
  analyticsPageLoadEventPage: PageLoadEventPageAnalyticsInterface,
  labelValues: string[] = [],
  options: Partial<{ origin: string; product: string, responsibleSquadTag: string }> = {}
): PageLoadAnalyticsInterface => {
  const formattedLabel: string = replaceLabelTokensByValues(analyticsPageLoadEventPage.nome, labelValues);
  analyticsPageLoadEventPage.nome = formattedLabel;

  const pageLoadEventPayload: PageLoadAnalyticsInterface = {
    site: {
      negocio: analyticsPageLoadEventPage['screen'] || ANALYTICS_EVENT_BUSINESS.NATURAL_PERSON,
      tipoDeCanal: ANALYTICS_EVENT_CHANNEL_TYPES.WEB,
    },
    page: analyticsPageLoadEventPage,
    visitor: {
      iDPF: ANALYTICS_EVENT_IDPF.DYNAMIC_VALUE,
      segmento: ANALYTICS_EVENT_SEGMENTS.VUP,
    },
    rule: ANALYTICS_EVENT_RULES.PAGE_LOAD,
    label: formattedLabel,
  };

  if (options && Object.keys(options)?.length > 0) {
    const transactionSchema = { origin: 'name', product: 'details' };
    pageLoadEventPayload.transaction = mapper(options, transactionSchema);
    if(options.responsibleSquadTag) {
      const customSchema = { responsibleSquadTag: 'squadresponsaveltag' };
      pageLoadEventPayload.custom = (mapper(options, customSchema) as PageLoadEventCustomAnalyticsInterface);
    }
  }

  return pageLoadEventPayload;
};

export const assembleAnalyticsPageLoadEventV2 = (
  analyticsPageLoadEventPage: PageLoadEventPageAnalyticsInterface,
  labelValues: string[] = []
): PageLoadAnalyticsInterface => {
  const formattedLabel: string = replaceLabelTokensByValues(analyticsPageLoadEventPage.nome, labelValues);
  analyticsPageLoadEventPage.nome = formattedLabel;

  const pageLoadEventPayload = {
    rule: ANALYTICS_EVENT_RULES.PAGE_LOAD,
    page: analyticsPageLoadEventPage,
    custom: {
      squadresponsaveltag: ANALYTICS_EVENT_SQUADS.CUSTOMER_SUCCESS,
    },
    site: {
      platform: ANALYTICS_EVENT_ENVIRONMENTS.NI,
    },
    visitor: {
      customerid: null, // TODO - Can only be developed after the login is finished
      customersegment: null, // TODO - Can only be developed after the login is finished
    },
  };

  return pageLoadEventPayload;
};
