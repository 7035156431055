import { BoxWeb, ItauIcon } from '@workspace/frontend/shared/presentation/components';
import { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';

const iconStyle: CSSProperties = {
  fontSize: '3rem',
  color: '#781B8E',
};

const rotationKeyframe = keyframes`
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

const ShadowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  padding: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const RotateWrapper = styled.div`
  animation: 2s ${rotationKeyframe} linear infinite;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = () => {
  return (
    <ShadowContainer>
      <Container>
        <RotateWrapper>
          <ItauIcon iconName="full_atualizando" style={iconStyle} />
        </RotateWrapper>
        <BoxWeb marginTop="1rem" fontSize="1rem" fontWeight="bold" role="alert" component="span">
          Carregando
        </BoxWeb>
      </Container>
    </ShadowContainer>
  );
};
