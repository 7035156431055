import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';
import { CarBrandModelView, GridTemplateArea } from '@workspace/frontend/auto/presentation/components';
import { ChassisField } from '../../../../../components/autoFields/chassis-field';
import { RenavamField } from '../../../../../components/autoFields/renavam-field';
import { PlateField } from '../../../../../components/autoFields/PlateField';
import { gridTemplateBase } from '../constants/grid-template-base';
import React from 'react';

const AdditionalVehicleDataGridTemplate = styled.div`
  ${gridTemplateBase}
  grid-template-rows: 50px repeat(2, minmax(84px, auto));
  grid-template-areas:
    'title title'
    'chassis carBrand'
    'plate renavam';
  h2 {
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    grid-template-rows: 120px repeat(4, 84px);
    grid-template-columns: 100%;
    grid-template-areas:
      'title'
      'chassis'
      'carBrand'
      'plate'
      'renavam';
  }
`;

export function AdditionalVehicleData({ selectedVehicle, isZeroKm, carImagePath }) {
  return (
    <AdditionalVehicleDataGridTemplate>
      <GridTemplateArea area="title">
        <BoxWeb width={'100%'} display={'flex'} justifyContent={{ xs: 'center', md: 'start' }}>
          <BoxWeb
            component={'h2'}
            fontSize={{ xs: '2rem', md: '2.25rem' }}
            aria-label="dados adicionais do veículo"
            id="lblAdditional_screenAdditionalHolderData"
            textAlign={{ xs: 'center', md: 'left' }}
            width={{ xs: '280px', md: '100%' }}
          >
            <span aria-hidden>
              dados<strong> adicionais do veículo</strong>
            </span>
          </BoxWeb>
        </BoxWeb>
      </GridTemplateArea>

      <GridTemplateArea area="chassis">
        <ChassisField id="txtChassi_screenAdditionalHolderData" />
      </GridTemplateArea>

      <GridTemplateArea area="carBrand">
        <CarBrandModelView text={selectedVehicle} imagePath={carImagePath} />
      </GridTemplateArea>

      {!isZeroKm && (
        <>
          <GridTemplateArea area="plate">
            <PlateField disabled />
          </GridTemplateArea>

          <GridTemplateArea area="renavam">
            <RenavamField id="txtRenavam_screenAdditionalHolderData" />
          </GridTemplateArea>
        </>
      )}
    </AdditionalVehicleDataGridTemplate>
  );
}
