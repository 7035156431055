import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { searchVehicle } from './search-vehicle-mock';
import { autoSimulationMock } from './auto-simulation-mock';
import { offersHomeMock } from './offers-mock';
import { leadMock } from './lead-mock';
import { cmsWhitelabelMock } from './cms-whitelabel-mock';

export const autoMockHandlers = (env): RestHandler[] => {
  return [searchVehicle(env), autoSimulationMock(env), offersHomeMock(env), leadMock(env), cmsWhitelabelMock(env)];
};
