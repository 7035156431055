import { BoxWeb, DialogTitleWeb } from '@workspace/frontend/shared/presentation/components';
import { useEffect } from 'react';
import { useAuthOriginProvider } from '../../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../../providers/auth-product-provider';

export const EmailSentScreen = ({ email, onClose }) => {
  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.CHANGE_PASSWORD_EMAIL_SENT;
  useEffect(() => analyticsAuth.LOAD(), []);

  return (
    <>
      <DialogTitleWeb onClose={onClose} />

      <BoxWeb
        fontSize="28px"
        lineHeight="36px"
        textAlign="center"
        color="#2B374A"
        fontFamily="ItauDisplay-Regular"
        fontWeight="700"
      >
        e-mail enviado!
      </BoxWeb>

      <BoxWeb maxWidth="430px" textAlign="center" margin="30px auto 0">
        <BoxWeb component="p" marginBottom="20px" fontSize="18px" lineHeight="23px">
          Você irá receber um e-mail com as instruções e o link para alterar a senha, caso exista uma conta cadastrada
          com o e-mail <b style={{ wordBreak: 'break-word' }}>{email}</b>.
        </BoxWeb>
        <BoxWeb component="p" fontSize="18px" lineHeight="23px">
          Se não receber em alguns minutos, verifique a sua caixa de spam ou tente novamente.
        </BoxWeb>
      </BoxWeb>
    </>
  );
};
