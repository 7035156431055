import { TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ReactMaskedInput from 'react-input-mask';
import { InputMaskWebProps } from '../../models/input-mask-web-props';
import { ItauIcon } from '../common/itau-icon';

function MaskCustom({ maskType, ...others }: InputMaskWebProps) {
  return (
    <ReactMaskedInput
      {...others}
      maskChar=" "
      alwaysShowMask={false}
      mask={maskType}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export const CustomMaskedInput = (props: InputMaskWebProps) => {
  const customHelperText = () => {
    if (props.error) {
      return (
        <>
          <ItauIcon
            iconName="exclamacao"
            ariaHidden={true}
            style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}
          />
          {props.helperText}
        </>
      );
    }

    return props.helperText;
  };

  return (
    <TextField
      {...props}
      label={props.label}
      name={props.name}
      helperText={customHelperText()}
      id={props.id}
      inputProps={{
        'data-testid': props.dataTestID,
        'aria-label': props.label,
        pattern: props?.inputKeyboardType?.pattern,
        inputmode: props?.inputKeyboardType?.inputmode,
        maskType: props.maskType,
      }}
      InputProps={{
        inputComponent: MaskCustom,
      }}
    />
  );
};

export const InputMaskAuthentication = styled(CustomMaskedInput)(() => ({
  width: '100%',
  root: {
    width: '100%',
  },
}));
