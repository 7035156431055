/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ComponentMode,
  StyleMode,
  ThemeProviderWeb,
  ComponentAdapterTypes,
  StyleAdapterTypes,
} from '@workspace/frontend/shared/presentation/components';

export function BlogTheme({ children }) {
  const componentMode: ComponentMode = {
    componentBase: ComponentAdapterTypes.LOJA_DE_SEGUROS_UI,
  };

  const styleMode: StyleMode = {
    styleBase: StyleAdapterTypes.BLOG,
  };
  return (
    <ThemeProviderWeb rootName="blog" componentMode={componentMode} styleMode={styleMode}>
      {children}
    </ThemeProviderWeb>
  );
}
