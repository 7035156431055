import { IDomainRepository } from '../../../data/contracts/autov2/domain-repository';
import { Domain } from '../../../domain/entities/autov2/response/domain';
import { IHttpPostClient } from '../../../data/contracts/http/http-post-client';

export class DomainRepository implements IDomainRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<null, Domain>) {}

  async execute(): Promise<Domain> {
    return await this.httpPostClient.post(null);
  }
}
