import styled from 'styled-components';

const DisabledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  background: #f7f4f2;
  height: 76px;
  border-bottom: 2px solid #acacac;
  margin: 15px 10px;
  font-family: 'ItauDisplay-Light';
  font-size: 14px;
  font-weight: 400;
`;
const DisabledLabel = styled.label`
  color: #969290;
`;
const DisabledInput = styled.input`
  font-family: 'ItauDisplay-Light';
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  color: #969290;
  cursor: not-allowed;
  &:focus {
    outline: none;
  }
`;
const Input = ({ label, input }: any) => {
  return (
    <div>
      <DisabledInputContainer>
        <DisabledLabel>{label}</DisabledLabel>
        <DisabledInput disabled value={input} />
      </DisabledInputContainer>
    </div>
  );
};

export default Input;
