import { VehicleResponse } from '@core/domain/entities/autov2/response/vehicle';
import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { FindVehicleByDescriptionUsecase } from '@core/data/services/find-vehicle-by-description-usecase';
import { VehicleSearchByDescription } from '@core/infra/models/vehicle-search-by-desciption';
import { FindVehicleByDescriptionRepository } from '@core/infra/repository/autov2/find-vehicle-by-description-repository';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { EnvironmentContextProps } from '@workspace/providers';

export type FindVehicleByDescriptionFactoryProps = {
  onError: (error) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

export function findVehicleByDescriptionFactory({ partialEnvironment, onError }: FindVehicleByDescriptionFactoryProps) {
  const { environment } = partialEnvironment;
  const httpGetClient: IHttpGetClient<{ params: VehicleSearchByDescription }, { data: VehicleResponse }> =
    new AxiosHttpGetClient('automovel/buscaveiculo', {}, environment.gatewayBFFBaseURL.url);

  const fazerSimulacaoRepository = new FindVehicleByDescriptionRepository(httpGetClient);
  return new FindVehicleByDescriptionUsecase(fazerSimulacaoRepository, onError);
}
