import { InstallmentsQueryResponse } from '@core/functions/autoV2/1-enterprise/models/installmentsQuery/installmentsQueryResponse';

export function InstallmentsQueryResponseAdapter(data: InstallmentsQueryResponse[]) {
  // CREDITO_PORTO = 97,
  // CREDITO = 62,
  // DEBITO_CONTA = 52

  const res = {
    credit: undefined,
    debit: undefined,
    creditPorto: undefined,
  };

  const creditPaymentMethod = data.find((i) => i.codigoFormaPagamento === 62);
  const creditPortoPaymentMethod = data.find((i) => i.codigoFormaPagamento === 97);
  const debitPaymentMethod = data.find((i) => i.codigoFormaPagamento === 52);

  res.credit = creditPaymentMethod.parcelamentos.map((i) => ({ value: i.descricao, key: i.numero }));
  res.debit = debitPaymentMethod.parcelamentos.map((i) => ({ value: i.descricao, key: i.numero }));
  res.creditPorto = creditPortoPaymentMethod.parcelamentos.map((i) => ({ value: i.descricao, key: i.numero }));

  return res;
}
