import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { IGetFAQQuestionRepository } from '@workspace/frontend/faq-v2/domain/contracts';
import { FAQQuestionResponse, Question } from '@workspace/frontend/faq-v2/domain/entities';

export class GetFAQQuestionRepository implements IGetFAQQuestionRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQQuestionResponse>) {}

  async execute(): Promise<Question[]> {
    const response: FAQQuestionResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
