import { rest } from 'msw';
interface RefreshTokenRequestProps {
  token: string;
}
export const refreshTokenMock = (env) => {
  return rest.post<RefreshTokenRequestProps>(`${env.gatewayBFFBaseURL.url}/refreshtoken`, (req, res, ctx) => {
    const { token } = req.body;
    if(token === '123')  return res(
      ctx.status(400),
      ctx.json({
        success: false,
        errors: ['erro'],
      })
    );
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        errors: [],
        value: {
          access_token:
            `eyJraWQiOiJhZTYxYWIxZi0yNTRhLTQ5ZWQtODMzNC05ZDJlN2E0MzZiNGQuaG9tLmdlbi4xNTk3NjAwMzM2OTkyLmp3dCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5ZWUxNGI4Mi1mY2MzLTQ1MDctYTVjNi03MzJiNDMyM2Y1ZjUiLCJpc3MiOiJodHRwczovL29wZW5pZC5pdGF1LmNvbS5ici9hcGkvb2F1dGgvdG9rZW4iLCJpYXQiOjE2NDcwMjQzMzUsImV4cCI6MTY0NzAyNDYzNSwiQWNjZXNzX1Rva2VuIjoiMmFhM2ZlOTUuNDJkZmU2ZmItYWM2OS00ZTNlLWE4NzgtNDM4ODQxN2ZlNjA2IiwidXNyIjoibnVsbCIsImZsb3ciOiJDQyIsInNvdXJjZSI6IklOVCIsInNpdGUiOiJkZXYiLCJlbnYiOiJIIiwibWJpIjoidHJ1ZSIsImF1dCI6IiIsInZlciI6InYxLjAiLCJzY29wZSI6ImFwcGlkLUxTMi1FIHJlc291cmNlLlJFQUQifQ.HGyGVUDYrYmefTQY_3tcoiQaEIw3bygQDdt2vDXrSig-wD9UtrqHubOckfUVQtLc7mkgfqgUpJyFPfSmJm02l68yBMmoHHQL5m-i4lUuAsIkz4-ehLajguKiYo8cwSWCNh9fQ2bMFPOt-6UXzzEPh2OF9P1rFEUonBI6fmxpjMQzuhMevKTiaGqLVKQjPYB-FahIRhITlKfWdsbQIWQZC-tGoD31dC4Hf1Ah6YkZy-401t9h2VMMKHRuDuFt_m47cwS6hR1HCYbK4Ev0LNnjo382sEHidLWP4vsW7WugZG_wvKEZeGozEyp1R4uFDW0Pk6gK2wd${(Math.random() * 65_000).toString(16)}`,
          refresh_token:
            `eyJraWQiOiJyZWZyZXNoLmhvbS5nZW4uaW50ZXJuYWwuand0IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI5ZWUxNGI4Mi1mY2MzLTQ1MDctYTVjNi03MzJiNDMyM2Y1ZjUiLCJpc3MiOiJodHRwczovL29wZW5pZC5pdGF1LmNvbS5ici9hcGkvb2F1dGgvdG9rZW4iLCJpYXQiOjE2NDcwMjQzMzUsImV4cCI6MTY0NzAyNDY5NSwiQWNjZXNzX1Rva2VuIjoiZTcxNTgzYTFkLjgxMTYzMGEzLTI1YjktNGFiZC05ZWYwLTU4MDc2Y2RlNmI4YSIsInVzciI6Im51bGwiLCJmbG93IjoiQ0MiLCJzb3VyY2UiOiJJTlQiLCJzaXRlIjoiZGV2IiwiZW52IjoiSCIsIm1iaSI6InRydWUiLCJhdXQiOiIiLCJ2ZXIiOiJ2MS4wIiwic2NvcGUiOiJhcHBpZC1MUzItRSByZXNvdXJjZS5SRUFEIn0.VT4fTZMQEW-Xvy3adsdD0xRSy18AgKeB5HT3plH74zGj-A_SGmw_Q2v_dFXWRb515LY_bsYUFznDsX5dbjN89UW5NWT-ZMO6Dz9joA4G3zIzCpHQtlmA8w5b9cHWMl9C1VQUowJ1BatiaLI_yN1TDSX8pl9dA-sM-hyP3JycF-af3PIRAg_SXf2eAk9AF5QE7AqSrzJXeKvTeXYLDfz6jx4dBPlAznmTtDvWDIufBvGMCavye09Pk3-O97uYGrOfFvQoeivxizYPaAexRw1Dx7umX8xAbTdh6GAmJlXfrr0yTTbL3nze6e49dABtfbvVUJWoTf${(Math.random() * 65_000).toString(16)}`,
        },
      })
    );
  });
};
