import { ANALYTICS_EVENT_CATEGORIES } from '../../../constants/categories';
import { ANALYTICS_EVENT_ACTIONS } from '../../../constants/actions';
import { ActionAnalyticsInterface } from '../../../analytics-config/analytics';
import { assembleAnalyticsPageCustomEvent } from '../../../analytics-config/assemble-analytics-page-custom-event';
type A = 'A1' | 'A2' | 'A3';

type T = 'T1';
export const pages: Record<T, string> = {
  T1: 'LS:NL:NCC:Blog:Home',
};

const actions: Record<A, string> = {
  A1: `BTN:Blog:Carrossel:{0}:LerPostagem`,
  A2: 'BTN:Blog:UltimasDoBlog:{0}:LerPostagem',
  A3: 'BTN:Blog:UltimasDoBlog:CarregarMaisPostagens',
};
export type ActionsBlogHomeTechSpecI = Record<A, (labels?: unknown[]) => ActionAnalyticsInterface>;
export const ActionsBlogHomeTechSpec: ActionsBlogHomeTechSpecI = {
  A1: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.T1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.BLOG,
        label: actions.A1,
        noInteraction: false,
      },
      label
    ),
  A2: (label: string[]) =>
    assembleAnalyticsPageCustomEvent(
      {
        pageName: pages.T1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.BLOG,
        label: actions.A2,
        noInteraction: false,
      },
      label
    ),
  A3: () =>
    assembleAnalyticsPageCustomEvent({
      pageName: pages.T1,
      category: ANALYTICS_EVENT_CATEGORIES.CLICK,
      action: ANALYTICS_EVENT_ACTIONS.BLOG,
      label: actions.A3,
      noInteraction: false,
    }),
};
