import { BoxWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';

const markdownToString = (markdown) => {
  if (typeof markdown === 'string') {
    return markdown.replace(/\*/g, '');
  }
  return markdown;
};

export const InfosDynamic = ({ infos }: any) => {
  return (
    <BoxWeb
      display="flex"
      flexDirection="column"
      p={{ xs: '20px 20px 40px', sm: 0 }}
      height={{ xs: 'max-content', sm: 226 }}
      width={{ md: '400px' }}
      justifyContent="center"
      textAlign={{ xs: 'center', sm: 'start', lg: 'start' }}
      ml={{ xs: 0, sm: '5rem', md: '10rem' }}
    >
      {infos?.title ? (
        <BoxWeb fontSize={'35px'} height={{ sm: '76px', md: '30px' }} role={'heading'} aria-level={2}>
          {infos?.title}
        </BoxWeb>
      ) : null}
      <BoxWeb fontSize={'20px'} height={{ sm: 'auto' }} fontWeight={'bold'} color="#2B374A">
        <MarkdownWeb>{infos?.subtitle}</MarkdownWeb>
      </BoxWeb>
      <BoxWeb display={{ sm: 'flex' }} fontSize={'14px'} height={{ sm: 'auto' }} color="text.primary">
        {infos?.description}
      </BoxWeb>
    </BoxWeb>
  );
};
