import { IHiring } from '../../domain/usecases/hiring-usecase';
import { IHiringRepository } from '../contracts/autov2/hiring-repository';
export class HiringUsecase<T,R> implements IHiring<T,R> {
  constructor(
    private readonly hiringRepository: IHiringRepository<T, R>,
    private readonly onError: (error) => void,
  ) {}

  async execute(hiringPayload: T): Promise<R> {
    try {
      return await this.hiringRepository.execute(hiringPayload);
    } catch (e) {
      this.onError(e);
    }
  }
}
