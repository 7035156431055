import { InputMaskWebProps } from '../../models/input-mask-web-props';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function InputMaskWeb(props: InputMaskWebProps) {
  const { Components } = useThemeProvider();
  const { MaskedInput } = Components;
  return (
    <ThemeProviderInjector>
      <MaskedInput {...props} />
    </ThemeProviderInjector>
  );
}

export default InputMaskWeb;
