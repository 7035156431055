import React from 'react';
import { SetaBaixo } from '@web/assets';
import { DropdownWrapperStyled, FormControlStyled, IconStyled, MenuItemStyled, SelectStyled } from './Dropdown-styles';
export interface DropdownProps {
  onClickItem: (uid: string) => void;
  items: { uid: string; title: string }[];
  defaultValue: { value: string; title: string };
  listTitle?: string;
  selectedItem?: string;
}

export const Dropdown = ({ onClickItem, items, defaultValue, listTitle, selectedItem }: DropdownProps) => {
  const handleChange = (event) => {
    onClickItem(event.target.value);
  };

  return (
    <DropdownWrapperStyled>
      <FormControlStyled>
        <SelectStyled
          data-testid="dropdown"
          value={selectedItem}
          onChange={handleChange}
          variant="outlined"
          inputProps={{ 'aria-label': `lista de ${listTitle} ${items?.length + 1} itens` }}
          MenuProps={{ 'aria-hidden': true }}
          displayEmpty
          defaultValue={defaultValue.value}
          IconComponent={(props) => (
            <IconStyled {...props} aria-hidden tabIndex={-1}>
              <SetaBaixo />
            </IconStyled>
          )}
        >
          <MenuItemStyled value={defaultValue.value}>{defaultValue.title}</MenuItemStyled>
          {items.map((item) => (
            <MenuItemStyled value={item.uid} key={item.uid}>
              {item.title}
            </MenuItemStyled>
          ))}
        </SelectStyled>
      </FormControlStyled>
    </DropdownWrapperStyled>
  );
};
