import { assembleAnalyticsPageCustomEventV2 } from '../../../analytics-config/assemble-analytics-page-custom-event';
import { ANALYTICS_EVENT_CATEGORIES } from '../../../constants/categories';
import { ANALYTICS_EVENT_ACTIONS } from '../../../constants/actions';
import { ActionAnalyticsInterface } from '../../../analytics-config/analytics';
import { pages } from './pages-faq-questions-techspec';

type E = 'P1E1' | 'P1E2' | 'P1E3' | 'P1E4' | 'P1E5';

const pageEvent: Record<E, string> = {
  P1E1: 'BTN:PrecisaDeAjuda:Inicio',
  P1E2: 'BTN:PrecisaDeAjuda:{0}',
  P1E3: 'BTN:PrecisaDeAjuda:{0}:{1}:{2}',
  P1E4: 'BTN:PrecisaDeAjuda:{0}:{1}:LinkResposta',
  P1E5: 'BTN:PrecisaDeAjuda:{0}:Likert:{1}',
};

export type ActionsFaqQuestionsTechSpecI = Record<
  E,
  (pageLabels?: unknown[], actionLabels?: unknown[]) => ActionAnalyticsInterface
>;
export const ActionsFaqQuestionsTechSpec: ActionsFaqQuestionsTechSpecI = {
  P1E1: (pageLabels: string[], actionLabels: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E1,
      },
      pageLabels,
      actionLabels
    ),
  P1E2: (pageLabels: string[], actionLabels: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E2,
      },
      pageLabels,
      actionLabels
    ),
  P1E3: (pageLabels: string[], actionLabels: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E3,
      },
      pageLabels,
      actionLabels
    ),
  P1E4: (label: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E4,
      },
      label
    ),
  P1E5: (pageLabels: string[], actionLabels: string[]) =>
    assembleAnalyticsPageCustomEventV2(
      {
        pageName: pages.P1,
        category: ANALYTICS_EVENT_CATEGORIES.CLICK,
        action: ANALYTICS_EVENT_ACTIONS.FAQ,
        label: pageEvent.P1E5,
      },
      pageLabels,
      actionLabels
    ),
};

export const ActionsFaqQuestionsLabelCreators = {
  P1E1: (labels: string): string[] => {
    return [labels];
  },
  P1E2: (labels: string): string[] => {
    return [labels];
  },
  P1E3: (labels: string[]): string[] => {
    return labels;
  },
  P1E4: (labels: string[]): string[] => {
    return labels;
  },
  P1E5: (labels: string[]): string[] => {
    return labels;
  },
};
