import {
  InputMaskType,
  InputMaskWeb,
  InputMaskWebProps,
  KeyboardMode,
} from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { cellphoneValidation } from '@workspace/frontend-shared-application-validation';

export const CellphoneField = ({ disabled, componentProps = {} }: { disabled?: boolean; componentProps?: any }) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    validation={cellphoneValidation()}
    name="cellphone"
    disabled={disabled}
    componentProps={{
      ...componentProps,
      inputKeyboardType: KeyboardMode.NUMERIC,
      label: componentProps.label || 'celular',
      maskType: InputMaskType.CELLPHONE,
    }}
  />
);
