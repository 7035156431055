import { DivergentDataQuotationHolderPage } from './divergent-data-quotation-holder-page';
import { RouteEnum } from '@workspace/frontend/auth/presentation/web-app';
import { removeAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { AuthStateManager } from '@web/state-management';

export function DivergentDataQuotationHolderContainer({
  onContinueDivergentQuotationHolder, onBackDivergentQuotationHolderName = undefined,
}: {
  onContinueDivergentQuotationHolder: () => void;
  onBackDivergentQuotationHolderName?: () => void;
}) {
  const handleBackButton = (): void => {
    onBackDivergentQuotationHolderName && onBackDivergentQuotationHolderName();
    removeAuthentication();
    AuthStateManager.userNameDispatcher(undefined);
    AuthStateManager.userAuthenticatedDispatcher(false);
    setTimeout(() => window.location.assign(RouteEnum.LOGIN), 50);
  };

  const handleContinueButton = (): void => {
    onContinueDivergentQuotationHolder();
  };

  return <DivergentDataQuotationHolderPage onBackButton={handleBackButton} onContinueButton={handleContinueButton} />;
}
