import { rest } from 'msw';

export const channelsSearch = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/channels/search`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        residencial: [
          'loja',
          'nlseguros',
          'hipercard',
          'veiculos',
          'finanveiculos',
          'cartaonaologado',
          'credicard',
          'itaucard',
          'itaunaologado',
          'itauuniclass',
          'itaupersonnalite',
          'consorcio',
          'autenticado',
          'maladigital',
          'sms',
          'maladigital1',
          'maladigital2',
          'connectcar',
          'NLcartoesseguros',
          'itaucardparcerias',
          'creditoimobiliario',
          'assai',
        ],
        automovel: [
          'loja',
          'nlseguros',
          'hipercard',
          'veiculos',
          'finanveiculos',
          'cartaonaologado',
          'credicard',
          'itaucard',
          'itaunaologado',
          'itauuniclass',
          'itaupersonnalite',
          'consorcio',
          'autenticado',
          'maladigital',
          'sms',
          'maladigital1',
          'maladigital2',
          'connectcar',
          'NLcartoesseguros',
          'autopiloto',
          'itaucardparcerias',
          'creditoimobiliario',
          'assai',
        ],
        pet: [
          'loja',
          'nlseguros',
          'hipercard',
          'veiculos',
          'finanveiculos',
          'cartaonaologado',
          'credicard',
          'itaucard',
          'itaunaologado',
          'itauuniclass',
          'itaupersonnalite',
          'consorcio',
          'autenticado',
          'maladigital',
          'sms',
          'maladigital1',
          'maladigital2',
          'connectcar',
          'realizapiloto',
          'NLcartoesseguros',
          'itaucardparcerias',
          'creditoimobiliario',
        ],
        'automovel-contratacao': [
          'autov2piloto',
          'loja',
          'nlseguros',
          'hipercard',
          'veiculos',
          'finanveiculos',
          'cartaonaologado',
          'credicard',
          'itaucard',
          'superapp',
          'itaunaologado',
          'itauuniclass',
          'itaupersonnalite',
          'consorcio',
          'autenticado',
          'maladigital',
          'sms',
          'maladigital1',
          'maladigital2',
          'connectcar',
          'NLcartoesseguros',
          'itaucardparcerias',
          'creditoimobiliario',
          'assai',
        ],
        'odonto-novos-planos': ['odontov2', 'itaucardparcerias', 'creditoimobiliario'],
        carglass: ['carglasspiloto', 'itaucardparcerias', 'creditoimobiliario'],
        bllu: ['superapp'],
        consorcio: ['campanhaconsorcio'],
        conectcar: ['connectcar'],
      })
    );
  });
};
