import { SelectWebProps } from '../../models/select-web-props';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function SelectWeb(props: SelectWebProps) {
  const { Components } = useThemeProvider();
  const { Select } = Components;
  return (
    <ThemeProviderInjector>
      <Select {...props} />
    </ThemeProviderInjector>
  );
}
