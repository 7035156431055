import { Product, Question, Topic } from '@workspace/frontend/faq-v2/domain/entities';

export const mockedProducts: Product[] = [
  { uid: '1', titulo: 'Produto 1', logo: { path: '' }, title: 'Produto 1' },
  { uid: '2', titulo: 'Produto 2', logo: { path: '' }, title: 'Produto 2' },
  { uid: '3', titulo: 'Produto 3', logo: { path: '' }, title: 'Produto 3' },
  { uid: '4', titulo: 'Produto 4', logo: { path: '' }, title: 'Produto 4' },
  { uid: '5', titulo: 'Produto 5', logo: { path: '' }, title: 'Produto 5' },
  { uid: '6', titulo: 'Produto 6', logo: { path: '' }, title: 'Produto 6' },
  { uid: '7', titulo: 'Produto 7', logo: { path: '' }, title: 'Produto 7' },
];

export const mockedTopics: Topic[] = [
  { uid: '1', titulo: 'Produto 1 - Tópico 1', produto: [mockedProducts[0]] },
  { uid: '2', titulo: 'Produto 1 - Tópico 2', produto: [mockedProducts[0]] },
  { uid: '3', titulo: 'Produto 1 - Tópico 3', produto: [mockedProducts[0]] },
  { uid: '4', titulo: 'Produto 1 - Tópico 4', produto: [mockedProducts[0]] },
  { uid: '5', titulo: 'Produto 1 - Tópico 5', produto: [mockedProducts[0]] },
  { uid: '6', titulo: 'Produto 1 - Tópico 6', produto: [mockedProducts[0]] },
  { uid: '7', titulo: 'Produto 1 - Tópico 7', produto: [mockedProducts[0]] },

  { uid: '8', titulo: 'Produto 2 - Tópico 1', produto: [mockedProducts[1]] },
  { uid: '9', titulo: 'Produto 2 - Tópico 2', produto: [mockedProducts[1]] },
];
export const mockedQuestions: Question[] = [
  { uid: '1', pergunta: 'Prod 1 - Tópico 1 - Pergunta 1', resposta: 'Prod 1 - Tópico 1 - Resposta 1', produto: [mockedProducts[0]], topico: [mockedTopics[0]] },
  { uid: '2', pergunta: 'Prod 1 - Tópico 1 - Pergunta 2', resposta: 'Prod 1 - Tópico 1 - Resposta 2', produto: [mockedProducts[0]], topico: [mockedTopics[0]] },
  { uid: '3', pergunta: 'Prod 1 - Tópico 1 - Pergunta 3', resposta: 'Prod 1 - Tópico 1 - Resposta 3', produto: [mockedProducts[0]], topico: [mockedTopics[0]] },
  { uid: '4', pergunta: 'Prod 1 - Tópico 2 - Pergunta 1', resposta: 'Prod 1 - Tópico 2 - Resposta 1', produto: [mockedProducts[0]], topico: [mockedTopics[1]] },
  { uid: '5', pergunta: 'Prod 1 - Tópico 3 - Pergunta 1', resposta: 'Prod 1 - Tópico 3 - Resposta 1', produto: [mockedProducts[0]], topico: [mockedTopics[2]] },
  { uid: '6', pergunta: 'Prod 2 - Tópico 1 - Pergunta 1', resposta: 'Prod 2 - Tópico 1 - Resposta 1', produto: [mockedProducts[1]], topico: [mockedTopics[7]] },
  { uid: '7', pergunta: 'Prod 2 - Tópico 2 - Pergunta 1', resposta: 'Prod 2 - Tópico 2 - Resposta 1', produto: [mockedProducts[1]], topico: [mockedTopics[8]] },
  { uid: '8', pergunta: 'Prod 2 - Tópico 2 - Pergunta 2', resposta: 'Prod 2 - Tópico 2 - Resposta 2', produto: [mockedProducts[1]], topico: [mockedTopics[8]] },
];
