import moment from 'moment';

export const futureDateTest = ({ message = 'a data deve ser futura', format = 'DD/MM/YYYY' }) => ({
  name: 'futureDate',
  message,
  test: (value: string): boolean => {
    const date = moment(value, format);
    if (!date.isValid()) return true;
    return date.isAfter(moment());
  },
});
