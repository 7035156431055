import MobileAccessButton from './mobile/mobile-container';
import DesktopAccessButton from './desktop/desktop-container';
import { AccessButtonProvider } from '../../providers/access-button-provider';
import { AuthTheme } from '../../components/auth-theme';

type TagProps = {
  alignright?: boolean;
};

type AccessProps = {
  mobile?: boolean;
  listItem?: boolean;
  alignRight?: boolean;
};

export const AccessButton = ({ mobile, listItem, ...props }: AccessProps & TagProps) => {
  // Mapped props
  const align = props.alignRight || props.alignright;

  return (
    <AccessButtonProvider>
      {mobile ? (
        <MobileAccessButton listItem={listItem} />
      ) : (
        <AuthTheme>
          <DesktopAccessButton alignRight={align} />
        </AuthTheme>
      )}
    </AccessButtonProvider>
  );
};
