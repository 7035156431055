import {
  ActionsFaqQuestionsLabelCreators as FaqQuestionsLabelCreators,
  ActionsFaqQuestionsTechSpec as FaqQuestionsActionsTags,
} from './questions/actions-faq-questions-techspec';

import { PageLoadFaqQuestionsTechSpec as FaqQuestionsPageLoadTags } from './questions/pages-faq-questions-techspec';

import { registerAnalyticsEvent } from '../../analytics-config/dispatch-analytics-events';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const FAQQuestionsAnalytics = {
  PAGE: {
    LOAD: (labels: string[]) => RAE(FaqQuestionsPageLoadTags.P1(labels)),
    BEGINNING_BREADCRUMB: (pageLabels?: string[], actionLabels?: string[]) =>
      RAE(FaqQuestionsActionsTags.P1E1(pageLabels, actionLabels)),
    GO_FOR_CHIPS: (pageLabels?: string[], actionLabels?: string[]) =>
      RAE(FaqQuestionsActionsTags.P1E2(pageLabels, actionLabels)),
    SHOW_HIDE_ANSWER: (pageLabels?: string[], actionLabels?: string[]) =>
      RAE(FaqQuestionsActionsTags.P1E3(pageLabels, actionLabels)),
    CLICK_LINK_ANSWER: (labels: string[]) => RAE(FaqQuestionsActionsTags.P1E4(FaqQuestionsLabelCreators.P1E4(labels))),
    LIKE_DISLIKE_ANSWER: (pageLabels?: string[], actionLabels?: string[]) =>
      RAE(FaqQuestionsActionsTags.P1E5(pageLabels, actionLabels)),
  },
};
