import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { FAQV2Theme } from '@workspace/frontend/faq-v2/presentation/components';

import { FaqProductsContainer } from './products/faq-products-container';
import { FaqQuestionsContainer } from './questions/faq-questions-container';
import { FaqBannerContainer } from './banner/faq-banner-container';

import { FaqRouteEnum } from '../enums/faq-routes-enum';
import { FaqSearchesContainer } from './searches/faq-searches-container';

export function FAQV2Base() {
  return (
    <FAQV2Theme>
      {/* <FaqBannerContainer /> */}
      <BrowserRouter>
        <Switch>
          <Route exact path={FaqRouteEnum.PRODUCTS} component={FaqProductsContainer} />
          <Route exact path={FaqRouteEnum.QUESTIONS} component={FaqQuestionsContainer} />
          <Route exact path={FaqRouteEnum.SEARCHES} component={FaqSearchesContainer} />
          <Redirect to={FaqRouteEnum.PRODUCTS} />
        </Switch>
      </BrowserRouter>
    </FAQV2Theme>
  );
}
