import { ContextsEnum } from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { CartCustomer, CartAddress, CartProduct } from '@workspace/utils/abandoned-cart';

export abstract class CreateCartAppServiceContractInput {
    constructor(public readonly context: ContextsEnum) {};
}

export class CreateCartAutoAppServiceContractInput extends CreateCartAppServiceContractInput {
  public cpf: string;
  public fullName: string;
  public cellphone: string;
  public simulationNumber: number | string;
  public dac: number;
  public segment: string;
  
  constructor(
    cpf?: string,
    fullName?: string,
    cellphone?: string,
    simulationNumber?: number | string,
    dac?: number,
    segment?: string
  ) {
    super(ContextsEnum.AUTO);
    this.cpf = cpf;
    this.cellphone = cellphone;
    this.fullName = fullName;
    this.simulationNumber = simulationNumber;
    this.segment = segment;
    this.dac = dac;
  } 
}

export class CreateCartResidAppServiceContractInput extends CreateCartAppServiceContractInput {
    constructor() {
        super(ContextsEnum.RESID);
    }
}

export class CreateCartOdontoAppServiceContractInput extends CreateCartAppServiceContractInput {
    constructor() {
        super(ContextsEnum.ODONTO);
    }
    public customer: CartCustomer;
    public address: CartAddress;
    public product: CartProduct;
}

export interface CreateCartAppServiceContract {
    execute(input: CreateCartAppServiceContractInput): Promise<void>;
}
