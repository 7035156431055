import React, { useEffect, useRef } from 'react';
import { CardContainer, PostCardWrapper, PostImage } from './PostCardSecondary-style';
import { CategoryText, HomePostTitle } from '../..';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { DefaultButtonProps } from '@workspace/frontend/shared/presentation/components';
export interface PostCardProps extends DefaultButtonProps {
  image: string;
  category: string;
  id?: string;
  title: string;
  index?: number;
  focusOnLoad: boolean;
  onClickPost: (event: MouseEvent) => void;
  imageAlt?: string;
}
export const PostCardSecondary = ({
  image,
  category,
  title,
  focusOnLoad = false,
  onClickPost,
  imageAlt,
  ...props
}: PostCardProps) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (focusOnLoad && !!itemRef.current) {
      itemRef.current?.focus();
    }
  }, [itemRef]);

  return (
    <CardContainer onClick={onClickPost} ref={itemRef} role="link" data-testid="post-card-secondary" {...props}>
      <PostCardWrapper
        justifyContent="start"
        aria-label={`Categoria: ${category}. ${title}`}
        role="heading"
        aria-level={2}
      >
        <PostImage aria-hidden alt={imageAlt} src={image} />
        <BoxWeb px={3} aria-hidden>
          <BoxWeb marginY={2} justifyContent="start">
            <CategoryText ariaHiden>{category}</CategoryText>
          </BoxWeb>
          <HomePostTitle component="h2">{title}</HomePostTitle>
        </BoxWeb>
      </PostCardWrapper>
    </CardContainer>
  );
};
