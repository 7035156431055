import { ISearchCepRepository } from "@core/data/contracts/autov2/search-cep-repository-interface";
import { IHttpGetClient } from "@core/data/contracts/http/http-get-client";
import { SearchCep } from "@core/infra/models/search-cep";
import { SearchCepResponse } from "@core/domain/entities/autov2/response/search-cep";

export class SearchCepRepository implements ISearchCepRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<{ params: SearchCep }, { data: SearchCepResponse }>) {}

  async execute(cepIn: string): Promise<{ data: SearchCepResponse }> {
    const params = { cep: cepIn };
    return await this.httpGetClient.get({ params }, {});
  }
}
