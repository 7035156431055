import { rest } from 'msw';

interface EligibilityRequestProps {
  nome: string;
  cpf: string;
}
export const eligibility2Mock = (env) => {
  return rest.post<EligibilityRequestProps>(
    `${env.gatewayBFFBaseURL.url}/usuarios/:id/elegibilidade`,
    (req, res, ctx) => res(ctx.status(200), ctx.json({}))
  );
};
