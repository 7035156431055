export default {
  neutral: {
    0: '#FFFFFF',
    50: '#FAF7F5',
    100: '#FAF7F5',
    200: '#D6D0CC',
    500: '#89837F',
    600: '#706A66',
    700: '#56504C',
    900: '#231D19',
  },
  primary: {
    main: '#EC7000',
    light: '#FF7500',
    dark: '#D9640B',
    darker: '#D9640B',
  },
  secondary: {
    main: '#106EB0',
    light: '#ECF4F9',
    dark: '#004480',
  },
  support: {
    main: '#106EB0',
    light: '#E8F1F8',
    dark: '#0D588D',
  },
  success: {
    main: '#007A47',
    light: '#E6F2ED',
    dark: '#006239',
  },
  warning: {
    main: '#F1AE2F',
    dark: '#C18B26',
    light: '#FEF7EB',
  },
  error: {
    main: '#781B8E',
    dark: '#601672',
    light: '#F2E9F4',
  },
  criticalError: {
    main: '#B71C1C',
    dark: '#921616',
    light: '#F8E9E9',
  },
  gradient: {},
  overlay: {},
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
