export class Cep {
  static REGEX= /^\d{5}-?\d{3}$/;

  get isValid(): boolean{
    return Cep.REGEX.test(this._value);
  };

  get value(): string {
    return this._value;
  }

  private readonly _value: string;

  constructor(cep: string) {
    this._value=cep.replace('-','');
  }

}
