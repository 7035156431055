import { ModalFilteredSelection } from '@workspace/frontend/shared/presentation/composed-components';
import { HiddenWeb, SelectWeb, SelectWebProps } from '@workspace/frontend/shared/presentation/components';

export interface SelectWithModalOnMobileProps extends SelectWebProps {
  disableFilter?: boolean;
}

export const SelectWithModalOnMobile = (props: SelectWithModalOnMobileProps) => {
  return (
    <>
      <HiddenWeb smUp>
        <ModalFilteredSelection {...props} />
      </HiddenWeb>

      <HiddenWeb xsDown>
        <SelectWeb {...props} />
      </HiddenWeb>
    </>
  );
};
