import { IRefreshTokenUseCase } from '@core/domain/usecases/refresh-token-auth-usecase';
import { IRefreshTokenRepository } from '../contracts/auth/refresh-token-repository';
import { RefreshTokenResponse } from '@core/domain/entities/auth/response/refresh-token-response';
import { RefreshToken } from '@core/domain/entities/auth/refreshToken';

export class RefreshTokenUsecase implements IRefreshTokenUseCase {
  constructor(private readonly refreshTokenRepository: IRefreshTokenRepository) {}

  async execute(data: RefreshToken, headers?: any): Promise<RefreshTokenResponse> {
    return await this.refreshTokenRepository.execute(data, headers);
  }
}
