import useTheme from '@material-ui/core/styles/useTheme';

import { StepperDesktop } from './stepper-desktop';
import { StepperMobile } from './stepper-mobile';

import { useWindowWidth } from './use-window-width';
import { Step } from './step';

export function StepperWeb({ steps, activeStep }: { steps: Step[]; activeStep?: number }) {
  const width = useWindowWidth();
  const theme = useTheme();

  if (width > theme.breakpoints.values.sm) return <StepperDesktop steps={steps} activeStep={activeStep} />;
  return <StepperMobile steps={steps} activeStep={activeStep} />;
}
