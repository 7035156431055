import styled from 'styled-components';
import { FormControllerFieldConnector } from '@web/form';
import { CSSProperties } from 'react';

type InputProps = {
  spellCheck: boolean,
  placeholder: string,
  style?: CSSProperties,
};

export const SearchInput = ({ spellCheck, placeholder, style }: InputProps) => {
  return <div data-testid={'form-search'} style={{ width: "100%" }}>
    <FormControllerFieldConnector<InputProps>
      component={Input}
      name={'search'}
      componentProps={{ spellCheck: spellCheck, placeholder: placeholder, style: style }}
    />
  </div>;
};

const Input = styled.input`
  border: none;
  outline: none;
  color: #2b374a;
  font-family: 'ItauDisplay-Light';
  font-size: 16px;
  line-height: 18px;
  flex: 1;
  padding: 11px 0px;

  &::placeholder {
    font-family: 'ItauDisplay-Light';
    font-weight: 300;
    color: #2b374a;
    opacity: 1; /* Firefox */
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 18px;
    line-height: 23px;
    padding: 12px 0px;
  }
`;
