import { IHttpGetClient } from '@workspace/backend/shared/contracts/repositories';
import { IGetCategoriesBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';
import { CategoryBlogResponse } from '@workspace/frontend/blog/domain/entities';
import { CategoryBlog } from '@workspace/frontend/blog/domain/entities';

export class GetCategoriesRepository implements IGetCategoriesBlogRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, CategoryBlogResponse>) {}

  async execute(): Promise<CategoryBlog[]> {
    const response: CategoryBlogResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
