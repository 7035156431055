import { RequestHandler, rest, setupWorker } from 'msw';
import { autoMockHandlers } from './handlers/auto/auto-mock-handlers';
import { authMockHandlers } from './handlers/auth/auth-mock-handlers';
import { SetupWorkerApi } from 'msw/lib/types/setupWorker/glossary';
import { sharedMockHandlers } from './handlers/shared/shared-mock-handlers';
import { odontoMockHandlers } from './handlers/odonto/odonto-mock-handlers';
import { FrontendEnvironment } from '@workspace/environments';
import { residMockHandlers } from './handlers/resid/resid-mock-handlers';
import { petMockHandlers } from './handlers/pet/pet-mock-handlers';
import { faqMockHandlers } from './handlers/faq/faq-mock-handlers';
import { cancellationMockHandlers } from './handlers/cancellation/cancellation-mock-handlers';
import { myAccountHandlers } from './handlers/my-account/myAccountMockHandlers';
import { externalMockHandlers } from './handlers/external/externalMockHandlers';

const combinedHandlers = (env): RequestHandler[] => {
  return [
    ...autoMockHandlers(env),
    ...authMockHandlers(env),
    ...sharedMockHandlers(env),
    ...odontoMockHandlers(env),
    ...residMockHandlers(env),
    ...myAccountHandlers(env),
    ...petMockHandlers(env),
    ...faqMockHandlers(env),
    ...cancellationMockHandlers(env),
    ...externalMockHandlers(env),
  ];
};

const startMockServer = (env: FrontendEnvironment) => {
  const worker: SetupWorkerApi = setupWorker(...combinedHandlers(env));
  // @ts-ignore
  window.msw = { worker, rest };
  const cachedMSW = window.sessionStorage.getItem('msw');
  if (cachedMSW) {
    const cachedMSWBool = JSON.parse(cachedMSW);
    if (!cachedMSWBool) return;
  }
  worker.start();
};

export default startMockServer;
