import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Divider } from '@material-ui/core';

import { AccordionWeb, BoxWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { Product, Question, Topic } from '@workspace/frontend/faq-v2/domain/entities';
import {
  HorizontalListContainer,
  ProductTopic,
} from '@workspace/frontend/faq-v2/presentation/components';

import {
  DesktopContent,
  FrequentlyAskedQuestionsTitlePrefix,
  MobileContent,
  ProductPageLink,
  QuestionStyle,
  TopicQuestion,
} from './faq-products-styles';

import { ANALYTICS_FAQ } from '@workspace/web/analytics';

interface ProductsProps {
  products: Product[];
  topics: Topic[];
  questions: Question[];
  selectedProduct: Product;
  handleSelectProduct: (selectedProduct: Product) => void;
  handleLink: (selectedProduct: Product, question?: Question) => string;
  handleProductPageLink: (productTitle: string) => string;
}

export function FaqProducts({
  products,
  topics,
  questions,
  selectedProduct,
  handleSelectProduct,
  handleLink,
  handleProductPageLink,
}: ProductsProps) {
  const { Theme } = useThemeProvider();
  const formatBigQuestions = (questionText) => {
    const maxQuestionChars = 75;
    return questionText.slice(0, maxQuestionChars);
  };
  const maxQuestionsToRenderAccordingToTopicUid = (uid: string) =>
    questions.filter(({ topico }: Question) => topico[0].uid === uid).slice(0, 3);

  const analyticsFaq = ANALYTICS_FAQ.HOME.PAGE;

  const handleProductQuestionClick = (productSlug: string, questionSlug: string) => {
    analyticsFaq.QUESTION([productSlug, questionSlug]);
  };

  const handleSeeMoreProductQuestionsClick = (slug: string) => {
    analyticsFaq.SEE_MORE(slug);
  };

  return (
    <SCThemeProvider theme={Theme}>
      <BoxWeb
        ml={'auto'}
        mr={'auto'}
        padding={{ xs: '4px 20px 35px', sm: '11px 16px 28px', lg: '19px 24px 32px' }}
        maxWidth={{ md: '1072px' }}
        height={'100%'}
      >
        <BoxWeb display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <HorizontalListContainer items={products} selectedCard={selectedProduct} onClick={handleSelectProduct} />
        </BoxWeb>

        <BoxWeb mb={{ xs: '22px', sm: '22px', md: '40px' }} />

        {selectedProduct?.titulo && (
          <FrequentlyAskedQuestionsTitlePrefix id="duvidas_frequentes" data-title={' ' + selectedProduct?.titulo}>
            Dúvidas frequentes em
          </FrequentlyAskedQuestionsTitlePrefix>
        )}

        <DesktopContent>
          {topics.map((topic: Topic) => (
            <ProductTopic key={topic.uid} title={topic.titulo}>
              <>
                {maxQuestionsToRenderAccordingToTopicUid(topic.uid).map((question) => (
                  <TopicQuestion
                    onClick={() => handleProductQuestionClick(selectedProduct.slug, question.slug)}
                    key={question.uid}
                    href={handleLink(selectedProduct, question)}
                  >
                    {formatBigQuestions(question.pergunta)}
                  </TopicQuestion>
                ))}
              </>
            </ProductTopic>
          ))}
        </DesktopContent>

        <MobileContent marginTop="32px">
          {topics.map((topic: Topic) => (
            <>
              <AccordionWeb
                title={topic?.titulo?.toLowerCase()}
                key={topic.uid}
                arrowPosition="right"
                detailsBgColor={'#FAF7F5'}
                fontFamily={'ItauDisplay-Bold'}
                fontSize={'16px'}
              >
                <BoxWeb>
                  {maxQuestionsToRenderAccordingToTopicUid(topic.uid).map((question) => (
                    <QuestionStyle
                      key={question.uid}
                      display={'flex'}
                      alignItems={'center'}
                      width={'100%'}
                      minHeight={'48px'}
                      bgcolor={'#FAF7F5'}
                    >
                      <a
                        href={handleLink(selectedProduct, question)}
                        onClick={() => handleProductQuestionClick(selectedProduct.slug, question.slug)}
                        style={{
                          color: '#231D19',
                          fontSize: '14px',
                          fontFamily: 'ItauText-Regular',
                          lineBreak: 'auto',
                        }}
                      >
                        {question.title}
                      </a>
                    </QuestionStyle>
                  ))}
                </BoxWeb>
              </AccordionWeb>
              <Divider />
            </>
          ))}
        </MobileContent>

        <BoxWeb display="flex" alignContent="center" justifyContent="center" marginTop={{ xs: '27px', md: '40px' }} />

        {selectedProduct?.titulo && (
          <BoxWeb display='flex' alignContent='center' justifyContent='center'>
            <ProductPageLink
              onClick={() => handleSeeMoreProductQuestionsClick(selectedProduct.slug)}
              href={handleLink(selectedProduct)}
            >
              {handleProductPageLink(selectedProduct.title)}
            </ProductPageLink>
          </BoxWeb>
        )}
      </BoxWeb>
    </SCThemeProvider>
  );
}
