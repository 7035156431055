export { VehicleStep } from './containers/hiring/steps/vehicle-data-step/vehicle-step';
export { AutoStepQuotation } from './containers/hiring/steps/quotation-step/auto-step-quotation';
export { AutoAddressStep } from './containers/hiring/steps/vehicle-address-step/address-step';
export { DriverCpfStep } from './containers/hiring/steps/driver-cpf-step/driver-cpf-step';

export * from './containers/home/autov2-home-carrier';
export * from './containers/hiring/autov2-hiring-carrier';
export * from './containers/success/autov2-success-carrier';
export * from './utils';
export * from './factories/send-lead-factory';
export { createInsuranceStoreAutoWebComponent, createInsuranceHiringAutoWebComponent, createInsuranceHiringSuperappAutoWebComponent, createInsuranceHiringSuccessSuperAppAutoWebComponent } from './build';
export { AutoRouter } from './route/local-router';
