import { rest } from 'msw';

export const getProducts = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/produtos`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          id_produto: 1001,
          nome: 'Odonto MetLife Jovem',
          valor: 29.9,
          descricao_resumida: 'O plano sob medida para clientes até 18 anos',
          quantidade_procedimentos: '215',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1015,
          nome: 'Odonto MetLife Jovem',
          valor: 29.9,
          descricao_resumida: 'O plano sob medida para clientes até 18 anos',
          quantidade_procedimentos: '215',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1022,
          nome: 'Odonto MetLife Jovem',
          valor: 29.9,
          descricao_resumida: 'O plano sob medida para clientes até 18 anos',
          quantidade_procedimentos: '215',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 104,
          nome: 'Odonto MetLife Essencial',
          valor: 41.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1002,
          nome: 'Odonto MetLife Essencial',
          valor: 41.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1016,
          nome: 'Odonto MetLife Essencial',
          valor: 41.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1023,
          nome: 'Odonto MetLife Essencial',
          valor: 41.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1023,
          nome: 'Odonto MetLife Essencial',
          valor: 41.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1024,
          nome: 'Odonto MetLife Doc',
          valor: 46.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal com documentação ortodontica.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1003,
          nome: 'Odonto MetLife Doc',
          valor: 46.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal com documentação ortodontica.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1017,
          nome: 'Odonto MetLife Doc',
          valor: 46.9,
          descricao_resumida: 'Um plano essencial para você cuidar da sua saúde bucal com documentação ortodontica.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1025,
          nome: 'Odonto MetLife Pro',
          valor: 79.9,
          descricao_resumida: 'Um plano essencial para próteses.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1004,
          nome: 'Odonto MetLife Pro',
          valor: 79.9,
          descricao_resumida: 'Um plano essencial para próteses.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1018,
          nome: 'Odonto MetLife Pro',
          valor: 79.9,
          descricao_resumida: 'Um plano essencial para próteses.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1026,
          nome: 'Odonto MetLife Pro Mais',
          valor: 114.9,
          descricao_resumida: 'Um plano completo para próteses que pode dar mais brilho aos dentes.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1005,
          nome: 'Odonto MetLife Pro Mais',
          valor: 114.9,
          descricao_resumida: 'Um plano completo para próteses que pode dar mais brilho aos dentes.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1019,
          nome: 'Odonto MetLife Pro Mais',
          valor: 114.9,
          descricao_resumida: 'Um plano completo para próteses que pode dar mais brilho aos dentes.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1027,
          nome: 'Odonto MetLife Orto',
          valor: 146.9,
          descricao_resumida: 'Um plano completo para um sorriso alinhado.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1006,
          nome: 'Odonto MetLife Orto',
          valor: 146.9,
          descricao_resumida: 'Um plano completo para um sorriso alinhado.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1013,
          nome: 'Odonto MetLife Orto',
          valor: 146.9,
          descricao_resumida: 'Um plano completo para um sorriso alinhado.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1020,
          nome: 'Odonto MetLife Orto',
          valor: 146.9,
          descricao_resumida: 'Um plano completo para um sorriso alinhado.',
          quantidade_procedimentos: '216',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1028,
          nome: 'Odonto MetLife Master',
          valor: 189.9,
          descricao_resumida: 'Um plano completão, para alinhar, dar brilho e cuidar da saúde bucal.',
          quantidade_procedimentos: '300',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1007,
          nome: 'Odonto MetLife Master',
          valor: 189.9,
          descricao_resumida: 'Um plano completão, para alinhar, dar brilho e cuidar da saúde bucal.',
          quantidade_procedimentos: '300',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1014,
          nome: 'Odonto MetLife Master',
          valor: 189.9,
          descricao_resumida: 'Um plano completão, para alinhar, dar brilho e cuidar da saúde bucal.',
          quantidade_procedimentos: '300',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
        {
          id_produto: 1021,
          nome: 'Odonto MetLife Master',
          valor: 189.9,
          descricao_resumida: 'Um plano completão, para alinhar, dar brilho e cuidar da saúde bucal.',
          quantidade_procedimentos: '300',
          url_logotipo: 'https://www.itau.com.br/_arquivosestaticos/Itau/plugandplay/Logo_Metlife_28_x_6_Pt.png',
          parceiro: {
            id_parceiro: 1,
            nome: 'Metlife',
          },
        },
      ])
    )
  );
};
