import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { signinAuthMock } from './signin-auth-mock';
import { tokenAuthMock } from './token-auth-mock';
import { usersMock } from './users-mock';
import { findUserByCPFAuthMock } from './find-user-by-cpf-auth-mock';
import { validateHashAuthMock } from './validate-hash-auth-mock';
import { resendTokenAuthMock } from './resend-token-auth-mock';
import { postUsersMock } from './post-users-mock';
import { refreshTokenMock } from './refresh-token-mock';
import { getUsersByHashMock } from './get-user-by-hash-mock';

export const authMockHandlers = (env): RestHandler[] => {
  return [
    usersMock(env),
    findUserByCPFAuthMock(env),
    validateHashAuthMock(env),
    signinAuthMock(env),
    tokenAuthMock(env),
    resendTokenAuthMock(env),
    postUsersMock(env),
    refreshTokenMock(env),
    getUsersByHashMock(env),
  ];
};
