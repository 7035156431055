import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { hiringFactory } from '../../../../factories/hiring-factory';
import { useStepProvider } from '../../../../providers/step-provider';
import { PaymentStep } from './payment-step';
import React, { useEffect, useState } from 'react';
import { creditCardPortoSectionFactory } from './credit-card-porto-section';
import { debitSectionFactory } from './debit-section';
import { creditCardSectionFactory } from './credit-card-section';
import { formatPriceNumber } from '../../../../utils/format-price-number';
import { LoadingError } from '../../../../components/loading-error/loading-error';
import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { sendLeadFactory } from '@workspace/frontend/auto/presentation/web-app';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { FinalizeCartAppServiceFactory } from '@workspace/frontend/shared/abandoned-cart/infra/factories';
import { ContextsEnum } from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { useHistory } from 'react-router-dom';

const generalConditionsPorto =
  'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/termos/auto/cg_porto_seguro_auto.pdf';
const generalConditionsAzul =
  'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/termos/auto/cg_azul_seguro_auto.pdf';
const termsOfUse =
  'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/assets/termos/termos_condicoes_2021_05.pdf';

/**
 * container for the payment step, it should have all effect call
 * @constructor
 */
function PaymentStepContainer() {
  const stepProvider = useStepProvider();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const [error, setError] = useState<boolean>(false);
  const { environment } = partialEnvironment;
  const [leadChannel, setLeadChannel] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name }: { channel_name: string } = await networkStyleUsecase.execute();
      setLeadChannel(channel_name);
    })();
  }, []);

  const {
    isRequiredInsuranceCar,
    simulationNumber,
    selectedOfferCode,
    installments,
    bankOptions,
    allowedBadges,
    isPorto,
    insuranceTotalPrice,
    coverages,
    assistances,
    name,
    cpf,
    cep,
    address,
  } = AutoStateManager.paymentStepSelector();

  const deleteCart = () => {
    const finalizeCartAppService = FinalizeCartAppServiceFactory.execute();
    finalizeCartAppService.execute({
      context: ContextsEnum.AUTO,
      cpf
    });
  }

  const holderData = [
    { title: 'nome', value: name },
    { title: 'CPF', value: cpf },
  ];

  const holderAdressData = [
    { title: 'endereço', value: address },
    { title: 'CEP', value: cep },
  ];

  const onError = (error) => {
    console.error('error', error);
    setError(true);
  };

  const hiringUseCase = hiringFactory({ partialEnvironment, onError });

  let formData;

  async function handleSubmit(data) {
    formData = data;
    // deleteCart();
  }

  async function handleContinue() {
    // const payload = HiringRequestAdapter()
    const hiringResponse = await hiringUseCase.execute({ cpf: '123 ' });
    await AutoStateManager.paymentStepDispatcher(hiringResponse.numeroProposta);
    const prefix = window.location.pathname.replace(/\/contratacao.*/, '');
    history.push(`${prefix}/sucesso`);
  }

  function handleBack() {
    stepProvider.handleBack();
  }

  const textPEP =
    'Informamos que por razão dos critérios de análise desta corretora e de acordo com termos da legislação vigente, não podemos seguir com a proposta de contratação do produto que você solicitou na Loja de Seguros Itaú.';

  if (error) {
    return <LoadingError errorTitle={textPEP} />;
  }

  const generalConditions = isPorto ? generalConditionsPorto : generalConditionsAzul;

  const paymentDayOptionsMock = [
    { value: '1', key: 1 },
    { value: '42', key: 2 },
  ];

  const summaryCard = {
    overtitle: '12 parcelas mensais de:',
    title: `R$ ${formatPriceNumber(400)}`,
    contentList: [
      {
        renderContent: isPorto ? (
          <BoxWeb component={'span'}>Seguro Porto</BoxWeb>
        ) : (
          <BoxWeb component={'span'}>Seguro Azul</BoxWeb>
        ),
      },
      {
        title: 'vigência',
        list: ['12 meses'],
      },
      {
        title: 'carência',
        list: ['não há nenhuma regra de carência'],
      },
    ],
  };

  const summaryCardMobile = {
    overtitle: '12 parcelas mensais de:',
    subtitle: `R$ ${formatPriceNumber(400)}`,
    title: isPorto ? 'Seguro Porto' : 'Seguro Azul',
    contentList: [
      {
        title: 'Vigência',
        subtitle: '12 meses',
      },
      {
        title: 'Carência',
        subtitle: 'sem regra de carência',
      },
    ],
  };

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });

  return (
    <PaymentStep
      onBack={handleBack}
      onSubmit={handleSubmit}
      onContinue={handleContinue}
      initialValues={{ paymentMethod: '1' }}
      termsOfUse={termsOfUse}
      generalConditions={generalConditions}
      isRequiredInsuranceCar={isRequiredInsuranceCar}
      paymentMethods={[
        creditCardPortoSectionFactory(installments.creditPorto, allowedBadges),
        creditCardSectionFactory(installments.credit, allowedBadges),
        debitSectionFactory(installments.debit, bankOptions, paymentDayOptionsMock),
      ]}
      simulationNumber={simulationNumber}
      selectedOfferCode={selectedOfferCode}
      summaryCard={summaryCard}
      summaryCardMobile={summaryCardMobile}
      summaryAccordion={[coverages, assistances, insuranceTotalPrice, holderData, holderAdressData]}
      sendLeadUsecase={sendLeadUsecase}
    />
  );
}

export function PaymentStepContainerWrapper() {
  return <PaymentStepContainer />;
}
