import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DefaultButtonProps } from '../../models/default-button';

export const LinkButtonAuthentication = styled(({ disabled, onClick, label, ...other }: DefaultButtonProps) => {
  return (
    <Button type="button" disabled={disabled} onClick={onClick} {...other}>
      {label}
    </Button>
  );
})(({ theme }: any) => {
  return {
    color: theme.palette.link.primary,
    background: 'transparent !important',
    textTransform: 'none',
    textDecoration: 'underline',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  };
});
