import { ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.palette.text.error};
  font-size: 0.8rem;
`;

export type RetryFieldProps = {
  loading: boolean;
  onClick: () => void;
  visible: boolean;
};

export function RetryButton(props: RetryFieldProps) {
  if (!props.visible) return <></>;

  return (
    <>
      <ErrorMessage>algo deu errado com sua requisição ):</ErrorMessage>
      <ButtonWeb loading={props.loading} label="tentar novamente" onClick={props.onClick} />
    </>
  );
}
