import { IResendTokenAuthUseCase } from '@core/domain/usecases/resend-token-auth-usecase';
import { IResendTokenRepository } from '@core/data/contracts/auth/resend-token-repository';
import { ResendTokenAuth } from '@core/domain/entities/auth/resend-token';
import { ResendTokenAuthResponse } from '@core/domain/entities/auth/response/resend-token-auth-response';

export class ResendTokenAuthUsecase implements IResendTokenAuthUseCase {
  constructor(private readonly resendTokenRepository: IResendTokenRepository) {}

  async execute(data: ResendTokenAuth, headers?: any): Promise<ResendTokenAuthResponse> {
    return await this.resendTokenRepository.execute(data, headers);
  }
}
