import { AlertAuthentication } from './alert-authentication';
import { DialogTitleAuthentication } from './dialog-title-authentication';
import { InputAuthentication } from './input-authentication';
import { InputMaskAuthentication } from './input-mask-authentication';
import { LinkButtonAuthentication } from './link-button-authentication';
import { PasswordAuthentication } from './password-authentication';

export const ReactAuthenticationUIComponents = {
  Input: InputAuthentication,
  Password: PasswordAuthentication,
  LinkButton: LinkButtonAuthentication,
  Alert: AlertAuthentication,
  DialogTitle: DialogTitleAuthentication,
  MaskedInput: InputMaskAuthentication,
};
