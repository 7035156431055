import { Insurance } from '@core/domain/entities/autov2/insurance';
import { InsuranceType } from './simulation-response';

export class InsuranceAdapter {
  static translate({ tipo }:Insurance): InsuranceType {
    return {
      type: tipo,
    } as InsuranceType;
  }
}
