import { FranchiseDTO } from './franchise-dto';
import { Coverage } from '@core/domain/entities/autov2/simulation-response';
import { CoverageType } from './simulation-response';
import { formatPriceNumber } from '../utils/format-price-number';

export class CoverageDTO {
  static translate(coverages: Coverage[]): CoverageType[] {
    return coverages.map((coverage) => ({
      code: coverage.codigo,
      amountInsured: `R$${formatPriceNumber(coverage.valorImportanciaSegurada)}`,
      price: `R$${formatPriceNumber(coverage.valorPremio)}`,
      franchise: FranchiseDTO.translate(coverage.franquias),
      type: coverage.tipo,
      title: coverage.titulo,
      description: coverage.descricao,
    }));
  }
}
