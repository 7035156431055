/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-dynamic-require */
// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/auth';
import React, {useEffect} from 'react';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

interface ConfigEnvs {
  LIB_FRAMEWORK_DA: string;
  FRAMEWORK_DA: string;
  OPTIMIZE: string;
  ENABLE_GOOGLE_ANALYTICS : boolean;
}

interface TrackObject {
  collection: () => Partial<TrackObject>
  add: () => Partial<TrackObject>
  then: (callback: Function) => Partial<TrackObject>
  catch: (callback: Function) => Partial<TrackObject>
}

export const AnalyticsInjector = () => {
  const head = document.getElementsByTagName('head')[0];
  const {environment}: Partial<EnvironmentContextProps> = useEnvironment();

  const inject = async () => {
    const JsType = 'text/javascript';
    if (environment.enableGoogleAnalytics) {
      const scriptLibFrameworkDA = document.createElement('script');
      scriptLibFrameworkDA.type = JsType;
      scriptLibFrameworkDA.setAttribute('id', 'importLibJSFrameworkDA');
      scriptLibFrameworkDA.src = environment.libFrameworkDA;
      await head.appendChild(scriptLibFrameworkDA);

      const scriptFrameworkDA = document.createElement('script');
      scriptFrameworkDA.type = JsType;
      scriptFrameworkDA.setAttribute('id', 'frameworkDA');
      scriptFrameworkDA.src = environment.frameworkDA;
      await head.appendChild(scriptFrameworkDA);

      const scriptOptimize = document.createElement('script');
      scriptOptimize.type = JsType;
      scriptOptimize.setAttribute('id', 'optimize');
      scriptOptimize.src = environment.optimize;
      await head.appendChild(scriptOptimize);
    }
  }

  useEffect(() => {
    inject();
  }, [])

  return null;
};
