import {
  InputMaskType,
  InputMaskWeb,
  InputMaskWebProps,
  KeyboardMode,
} from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@workspace/frontend/shared/presentation/form/control';
import { cpfValidation } from '@workspace/frontend-shared-application-validation';

export const CpfField = ({
  disabled,
  componentProps,
  validation,
  helperText,
  prefix,
}: {
  disabled?: boolean;
  componentProps?: any;
  validation?: any;
  helperText?: string;
  prefix?: string;
}) => {
  let finalValidation;

  if (validation) finalValidation = validation;
  else finalValidation = cpfValidation();

  return (
    <FormControllerFieldConnector<InputMaskWebProps>
      component={InputMaskWeb}
      name={prefix ? prefix + '_cpf' : 'cpf'}
      disabled={disabled}
      validation={finalValidation}
      helperText={helperText}
      componentProps={{
        ...componentProps,
        inputKeyboardType: KeyboardMode.NUMERIC,
        label: componentProps?.label || 'CPF',
        maskType: InputMaskType.CPF,
      }}
    />
  );
};
