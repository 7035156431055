import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function ContainerWeb({ children }) {
  const { Components } = useThemeProvider();
  const { Container } = Components;

  return (
    <ThemeProviderInjector>
      <Container>{children}</Container>
    </ThemeProviderInjector>
  );
}
