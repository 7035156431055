import { IHttpGetClient } from '@workspace/backend/shared/contracts/repositories';
import { IGetArticleBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';
import { ArticleBlog } from '@workspace/frontend/blog/domain/entities'
import { ArticleBlogResponse } from '@workspace/frontend/blog/domain/entities';


export class GetArticleRepository implements IGetArticleBlogRepository {

  constructor(private readonly httpGetClient: IHttpGetClient<null, ArticleBlogResponse>) { }

  async execute(): Promise<ArticleBlog> {
    const response: ArticleBlogResponse =  await this.httpGetClient.get();
    return response.data.entries;
  }

}
