type IconName =
  | 'exclamacao'
  | 'fechar'
  | 'check'
  | 'check_inst'
  | 'atualizando'
  | 'full_atualizando'
  | 'busca_consulta'
  | 'seta'
  | 'curtir'
  | 'descurtir'
  | 'seta_up'
  | 'seta_down'
  | 'outline_compras'
  | 'usuario_perfil'
  | 'sair'
  | 'descurtir'
  | 'full_curtir'
  | 'full_descurtir';

type IconDefaultClass = 'icon-itaufonts_' | 'icon-';

interface ItauIconProps {
  iconName: IconName;
  defaultClass?: IconDefaultClass;
  style?: React.CSSProperties;
  ariaLabel?: string;
  ariaHidden?: boolean;
}

export const ItauIcon = ({
  iconName,
  defaultClass = 'icon-itaufonts_',
  ariaLabel,
  ariaHidden,
  ...props
}: ItauIconProps): JSX.Element => (
  <i
    tabIndex={-1}
    aria-hidden={ariaHidden}
    aria-label={ariaLabel}
    data-testid={defaultClass + iconName}
    className={defaultClass + iconName}
    {...props}
  />
);
