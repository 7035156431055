import { rest } from 'msw';

const autoSimulationMock50And100 = {
  data: {
    numeroCotacao: 3000069548,
    statusCotacao: [
      {
        codigo: 2,
        descricao: "Escolhemos as melhores ofertas para você !"
      }
    ],
    dataCriacao: "2022-08-24T14:28:00.043Z",
    orcamentos: [
      {
        codigo: 1,
        inicioVigencia: "2022-08-24",
        fimVigencia: "2023-08-24",
        empresa: 1,
        valorPremioFinal: 7565.72,
        valorPremioFinalCartao: 7565.72,
        valorPremioSemIOF: 7045.74,
        valorMonetarioIOF: 519.98,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 75250,
              valorPremio: 6450.94,
              franquias: [
                {
                  codigo: 3,
                  valorFranquia: 2422
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\nCOLISÃO\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado  com a utilização de violência.",
              titulo: "Cobertura completa para seu veículo (Colisão, Incêndio e Roubo)"
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 378.62,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.",
              titulo: "Danos Materiais a Terceiros"
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 55.83,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de dano fisico (corporal) a uma pessoa.",
              titulo: "Danos Corporais a Terceiros"
            },
            {
              codigo: 12,
              tipo: 1,
              valorImportanciaSegurada: 15000,
              valorPremio: 160.35,
              franquias: [
                {
                  codigo: 24,
                  valorFranquia: 114
                },
                {
                  codigo: 27,
                  valorFranquia: 104
                },
                {
                  codigo: 28,
                  valorFranquia: 564
                },
                {
                  codigo: 22,
                  valorFranquia: 351
                },
                {
                  codigo: 30,
                  valorFranquia: 173
                },
                {
                  codigo: 29,
                  valorFranquia: 187
                },
                {
                  codigo: 23,
                  valorFranquia: 339
                },
                {
                  codigo: 26,
                  valorFranquia: 1748
                },
                {
                  codigo: 25,
                  valorFranquia: 1426
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.",
              titulo: "Cobertura de Vidros, Retrovisores, Lanternas e Faróis"
            }
          ],
          descontos: [],
          assistencias: [
            {
              codigo: 21,
              valorPremio: 0,
              codigosTextos: [
                178,
                175,
                39,
                88,
                12,
                13
              ],
              descricao: "A assistência possui o guincho ( sem limite de km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.\nCréditos para aplicativos de mobilidade ou desconto na franquia.\nServiços à residência (ex: encanador, eletricista, chaveiro, reparos de eletrodomésticos de linha branca) - até 1 acionamento (+1 pelos canais digitais).",
              titulo: "Essencial | Assistência Km Ilimitado / Serviços à Residência"
            }
          ],
          rastreador: {
            concessao: {
              codigo: 2
            },
            rastreadorInstalado: {
              codigo: 2
            },
            tipoConcessao: {
              codigo: 31
            }
          },
          beneficios: [
            {
              codigo: 9,
              codigosTextos: [
                160
              ]
            },
            {
              codigo: 15,
              codigosTextos: []
            },
            {
              codigo: 10,
              codigosTextos: [
                57
              ]
            },
            {
              codigo: 19,
              codigosTextos: []
            },
            {
              codigo: 8,
              codigosTextos: []
            }
          ],
          vistoriaPrevia: {
            tipo: 11
          }
        },
        tipoOferta: {
          codigo: 1,
          descricao: "Oferta Recomendada Porto Seguro"
        }
      },
      {
        codigo: 3,
        inicioVigencia: "2022-08-24",
        fimVigencia: "2023-08-24",
        empresa: 1,
        valorPremioFinal: 6987.15,
        valorPremioFinalCartao: 6987.15,
        valorPremioSemIOF: 6506.94,
        valorMonetarioIOF: 480.21,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 75250,
              valorPremio: 5920.16,
              franquias: [
                {
                  codigo: 1,
                  valorFranquia: 4845
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\nCOLISÃO\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado  com a utilização de violência.",
              titulo: "Cobertura completa para seu veículo (Colisão, Incêndio e Roubo)"
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 378.62,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.",
              titulo: "Danos Materiais a Terceiros"
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 55.83,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de dano fisico (corporal) a uma pessoa.",
              titulo: "Danos Corporais a Terceiros"
            },
            {
              codigo: 12,
              tipo: 1,
              valorImportanciaSegurada: 15000,
              valorPremio: 152.33,
              franquias: [
                {
                  codigo: 24,
                  valorFranquia: 114
                },
                {
                  codigo: 27,
                  valorFranquia: 104
                },
                {
                  codigo: 28,
                  valorFranquia: 564
                },
                {
                  codigo: 22,
                  valorFranquia: 351
                },
                {
                  codigo: 30,
                  valorFranquia: 173
                },
                {
                  codigo: 29,
                  valorFranquia: 187
                },
                {
                  codigo: 23,
                  valorFranquia: 339
                },
                {
                  codigo: 26,
                  valorFranquia: 1748
                },
                {
                  codigo: 25,
                  valorFranquia: 1426
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.",
              titulo: "Cobertura de Vidros, Retrovisores, Lanternas e Faróis"
            }
          ],
          descontos: [],
          assistencias: [
            {
              codigo: 21,
              valorPremio: 0,
              codigosTextos: [
                178,
                175,
                39,
                88,
                12,
                13
              ],
              descricao: "A assistência possui o guincho ( sem limite de km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.\nCréditos para aplicativos de mobilidade ou desconto na franquia.\nServiços à residência (ex: encanador, eletricista, chaveiro, reparos de eletrodomésticos de linha branca) - até 1 acionamento (+1 pelos canais digitais).",
              titulo: "Essencial | Assistência Km Ilimitado / Serviços à Residência"
            }
          ],
          rastreador: {
            concessao: {
              codigo: 2
            },
            rastreadorInstalado: {
              codigo: 2
            },
            tipoConcessao: {
              codigo: 31
            }
          },
          beneficios: [
            {
              codigo: 9,
              codigosTextos: [
                160
              ]
            },
            {
              codigo: 15,
              codigosTextos: []
            },
            {
              codigo: 10,
              codigosTextos: [
                57
              ]
            },
            {
              codigo: 19,
              codigosTextos: []
            },
            {
              codigo: 8,
              codigosTextos: []
            }
          ],
          vistoriaPrevia: {
            tipo: 11
          }
        }
      },
      {
        codigo: 2,
        inicioVigencia: "2022-08-24",
        fimVigencia: "2023-08-24",
        empresa: 35,
        valorPremioFinal: 7040.75,
        valorPremioFinalCartao: 7040.75,
        valorPremioSemIOF: 6556.85,
        valorMonetarioIOF: 483.9,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 75250,
              valorPremio: 5913.99,
              franquias: [
                {
                  codigo: 3,
                  valorFranquia: 2543
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\nCOLISÃO\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado  com a utilização de violência.",
              titulo: "Cobertura completa para seu veículo (Colisão, Incêndio e Roubo)"
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 487.28,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.",
              titulo: "Danos Materiais a Terceiros"
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 40.34,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de dano físico (corporal) a uma pessoa.",
              titulo: "Danos Corporais a Terceiros"
            },
            {
              codigo: 59,
              tipo: 1,
              valorImportanciaSegurada: 20000,
              valorPremio: 115.24,
              franquias: [
                {
                  codigo: 10,
                  valorFranquia: 351
                },
                {
                  codigo: 11,
                  valorFranquia: 187
                },
                {
                  codigo: 12,
                  valorFranquia: 114
                },
                {
                  codigo: 13,
                  valorFranquia: 173
                },
                {
                  codigo: 20,
                  valorFranquia: 1748
                },
                {
                  codigo: 21,
                  valorFranqui: 564
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.",
              titulo: "Cobertura de Vidros, Retrovisores, Lanternas e Faróis"
            }
          ],
          descontos: [],
          assistencias: [
            {
              codigo: 54,
              valorPremio: 0,
              codigosTextos: [],
              descricao: "A assistência possui o guincho ( até 400 km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.",
              titulo: "Assistência Gratuita - 400 Km"
            }
          ],
          rastreador: {
            concessao: {
              codigo: 2
            },
            rastreadorInstalado: {
              codigo: 2
            },
            tipoConcessao: {
              codigo: 31
            }
          },
          beneficios: [],
          vistoriaPrevia: {
            tipo: 11
          }
        }
      },
      {
        codigo: 4,
        inicioVigencia: "2022-08-24",
        fimVigencia: "2023-08-24",
        empresa: 35,
        valorPremioFinal: 6292.29,
        valorPremioFinalCartao: 6292.29,
        valorPremioSemIOF: 5859.83,
        valorMonetarioIOF: 432.46,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 75250,
              valorPremio: 5216.97,
              franquias: [
                {
                  codigo: 1,
                  valorFranquia: 5087
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\nCOLISÃO\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado  com a utilização de violência.",
              titulo: "Cobertura completa para seu veículo (Colisão, Incêndio e Roubo)"
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 487.28,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.",
              titulo: "Danos Materiais a Terceiros"
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 40.34,
              codigosTextos: [],
              descricao: "A cobertura garantirá o pagamento em caso de dano físico (corporal) a uma pessoa.",
              titulo: "Danos Corporais a Terceiros"
            },
            {
              codigo: 59,
              tipo: 1,
              valorImportanciaSegurada: 20000,
              valorPremio: 115.24,
              franquias: [
                {
                  codigo: 10,
                  valorFranquia: 351
                },
                {
                  codigo: 11,
                  valorFranquia: 187
                },
                {
                  codigo: 12,
                  valorFranquia: 114
                },
                {
                  codigo: 13,
                  valorFranquia: 173
                },
                {
                  codigo: 20,
                  valorFranquia: 1748
                },
                {
                  codigo: 21,
                  valorFranquia: 564
                }
              ],
              codigosTextos: [],
              descricao: "A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.",
              titulo: "Cobertura de Vidros, Retrovisores, Lanternas e Faróis"
            }
          ],
          descontos: [],
          assistencias: [
            {
              codigo: 54,
              valorPremio: 0,
              codigosTextos: [],
              descricao: "A assistência possui o guincho ( até 400 km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.",
              titulo: "Assistência Gratuita - 400 Km"
            }
          ],
          rastreador: {
            concessao: {
              codigo: 2
            },
            rastreadorInstalado: {
              codigo: 2
            },
            tipoConcessao: {
              codigo: 31
            }
          },
          beneficios: [],
          vistoriaPrevia: {
            tipo: 11
          }
        }
      }
    ]
  }
}

const autoSimulationMock50 = {
  data: {
    numeroCotacao: 7390305,
    statusCotacao: [{ codigo: 2, descricao: 'Escolhemos as melhores ofertas para você !' }],
    dataCriacao: '2022-01-06T19:27:33.314Z',
    orcamentos: [
      {
        codigo: 1,
        inicioVigencia: '2022-01-06',
        fimVigencia: '2023-01-06',
        empresa: 1,
        valorPremioFinal: 3198.91,
        valorPremioFinalCartao: 3038.96,
        valorPremioSemIOF: 2979.06,
        valorMonetarioIOF: 219.85,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 48911,
              valorPremio: 2246.6,
              franquias: [{ codigo: 3, valorFranquia: 2215 }],
              codigosTextos: [],
              descricao:
                'A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\n**COLISÃO**\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado com a utilização de violência.',
              titulo: 'Cobertura completa para seu veículo (Colisão, Incêndio e Roubo) 2',
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 633.11,
              codigosTextos: [],
              descricao: 'A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.',
              titulo: 'Danos Materiais a Terceiros',
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 40.95,
              codigosTextos: [],
              descricao: 'A cobertura garantirá o pagamento em caso de dano fisico (corporal) a uma pessoa.',
              titulo: 'Danos Corporais a Terceiros',
            },
            {
              codigo: 12,
              tipo: 1,
              valorImportanciaSegurada: 10000,
              valorPremio: 58.4,
              franquias: [
                { codigo: 24, valorFranquia: 132 },
                { codigo: 27, valorFranquia: 120 },
                { codigo: 28, valorFranquia: 480 },
                { codigo: 22, valorFranquia: 262.2 },
                { codigo: 30, valorFranquia: 160 },
                { codigo: 29, valorFranquia: 80 },
                { codigo: 23, valorFranquia: 253 },
                { codigo: 26, valorFranquia: 740 },
                { codigo: 25, valorFranquia: 740 },
              ],
              codigosTextos: [],
              descricao:
                'A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.',
              titulo: 'Cobertura de Vidros, Retrovisores, Lanternas e Faróis',
            },
          ],
          descontos: [
            { codigo: 142, percentual: 10 },
            { codigo: 158, percentual: 5, valor: 0 },
          ],
          assistencias: [
            {
              codigo: 21,
              valorPremio: 230.52,
              codigosTextos: [178, 175, 39, 88, 12, 13],
              descricao:
                'A assistência possui o guincho ( sem limite de km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.\nCréditos para aplicativos de mobilidade ou desconto na franquia.\nServiços à residência (ex: encanador, eletricista, chaveiro, reparos de eletrodomésticos de linha branca) - até 1 acionamento (+1 pelos canais digitais).',
              titulo: 'Essencial | Assistência Km Ilimitado / Serviços à Residência',
            },
          ],
          beneficios: [
            { codigo: 9, codigosTextos: [160] },
            { codigo: 15, codigosTextos: [] },
            { codigo: 10, codigosTextos: [57] },
            { codigo: 19, codigosTextos: [] },
            { codigo: 8, codigosTextos: [] },
          ],
          vistoriaPrevia: {
            tipo: 10,
            descricao:
              'A cotação está sem a informação da placa do veículo. Para análise dos dados e validação de necessidade de vistoria prévia, a placa do veículo deve ser informada e efetuado o recálculo do orçamento.',
          },
        },
        tipoOferta: { codigo: 1, descricao: 'Oferta Recomendada Porto Seguro' },
      },
      {
        codigo: 2,
        inicioVigencia: '2022-01-06',
        fimVigencia: '2023-01-06',
        empresa: 35,
        valorPremioFinal: 2814.61,
        valorPremioFinalCartao: 2673.87,
        valorPremioSemIOF: 2621.17,
        valorMonetarioIOF: 193.44,
        item: {
          coberturas: [
            {
              codigo: 1,
              valorImportanciaSegurada: 48911,
              valorPremio: 1888.71,
              franquias: [{ codigo: 1, valorFranquia: 4430 }],
              codigosTextos: [],
              descricao:
                'A cobertura garantirá o pagamento em caso de perdas ou danos, causados por:\n**COLISÃO**\nÉ quando acontece a batida do veículo segurado contra um obstáculo, por exemplo: um outro veículo, um poste, um muro, uma pessoa, um animal, entre outros.\nINCÊNDIO \nÉ quando seu veículo sofre danos causados por fogo derivados de um incêndio, explosão acidental ou queda de raios.\nROUBO \nÉ quando seu veículo é levado com a utilização de violência.',
              titulo: 'Cobertura completa para seu veículo (Colisão, Incêndio e Roubo) 2',
            },
            {
              codigo: 3,
              valorImportanciaSegurada: 50000,
              valorPremio: 633.11,
              codigosTextos: [],
              descricao: 'A cobertura garantirá o pagamento em caso dano à propriedade material de uma pessoa.',
              titulo: 'Danos Materiais a Terceiros',
            },
            {
              codigo: 4,
              valorImportanciaSegurada: 50000,
              valorPremio: 40.95,
              codigosTextos: [],
              descricao: 'A cobertura garantirá o pagamento em caso de dano fisico (corporal) a uma pessoa.',
              titulo: 'Danos Corporais a Terceiros',
            },
            {
              codigo: 12,
              tipo: 1,
              valorImportanciaSegurada: 10000,
              valorPremio: 58.4,
              franquias: [
                { codigo: 24, valorFranquia: 132 },
                { codigo: 27, valorFranquia: 120 },
                { codigo: 28, valorFranquia: 480 },
                { codigo: 22, valorFranquia: 262.2 },
                { codigo: 30, valorFranquia: 160 },
                { codigo: 29, valorFranquia: 80 },
                { codigo: 23, valorFranquia: 253 },
                { codigo: 26, valorFranquia: 740 },
                { codigo: 25, valorFranquia: 740 },
              ],
              codigosTextos: [],
              descricao:
                'A cobertura garantirá a proteção exclusiva dos vidros, retrovisores, lanternas e faróis do seu veículo.',
              titulo: 'Cobertura de Vidros, Retrovisores, Lanternas e Faróis',
            },
          ],
          descontos: [
            { codigo: 142, percentual: 10 },
            { codigo: 158, percentual: 5, valor: 0 },
          ],
          assistencias: [
            {
              codigo: 21,
              valorPremio: 230.52,
              codigosTextos: [178, 175, 39, 88, 12, 13],
              descricao:
                'A assistência possui o guincho ( sem limite de km ), assistências para pane mecânica, elétrica e seca + reparo de furo do pneu + chaveiro automotivo.\nCréditos para aplicativos de mobilidade ou desconto na franquia.\nServiços à residência (ex: encanador, eletricista, chaveiro, reparos de eletrodomésticos de linha branca) - até 1 acionamento (+1 pelos canais digitais).',
              titulo: 'Essencial | Assistência Km Ilimitado / Serviços à Residência',
            },
          ],
          beneficios: [
            { codigo: 9, codigosTextos: [160] },
            { codigo: 15, codigosTextos: [] },
            { codigo: 10, codigosTextos: [57] },
            { codigo: 19, codigosTextos: [] },
            { codigo: 8, codigosTextos: [] },
          ],
          vistoriaPrevia: {
            tipo: 10,
            descricao:
              'A cotação está sem a informação da placa do veículo. Para análise dos dados e validação de necessidade de vistoria prévia, a placa do veículo deve ser informada e efetuado o recálculo do orçamento.',
          },
        },
      },
    ],
  }
}

export const autoSimulationMock = (env) => {
  return rest.post(`${env.gatewayBFFBaseURL.url}/automovel/simulacao`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json(autoSimulationMock50And100)
    )
  );
};
