import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { ValidateRecoveryHashResponse } from '@core/domain/entities/auth/response/validate-recovery-hash-response';
import { IValidateRecoveryHashRepository } from '@core/data/contracts/auth/validate-recovery-hash-repository';

export class ValidateRecoveryHashRepository implements IValidateRecoveryHashRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<{ hash: string }, ValidateRecoveryHashResponse>) {}

  async execute(hash: string, headers?: any): Promise<ValidateRecoveryHashResponse> {
    return await this.httpPostClient.post({ hash }, null, headers);
  }
}
