import {
  InputMaskType,
  InputMaskWeb,
  InputMaskWebProps,
  KeyboardMode,
} from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { phoneValidation } from '@workspace/frontend-shared-application-validation';

export const PhoneField = ({ disabled, label = 'telefone' }: { disabled?: boolean; label?: string }) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    validation={phoneValidation()}
    name="phone"
    disabled={disabled}
    componentProps={{
      inputKeyboardType: KeyboardMode.NUMERIC,
      label,
      maskType: InputMaskType.PHONE,
    }}
  />
);
