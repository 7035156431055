import { NavigateButtonsProps } from '../../models/navigate-buttons';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function NavigateButtonsWeb(props: NavigateButtonsProps) {
  const { Components } = useThemeProvider();
  const { NavigateButtons } = Components;
  return (
    <ThemeProviderInjector>
      <NavigateButtons {...props} />
    </ThemeProviderInjector>
  );
}
