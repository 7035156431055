import { stringValidationFactory, StringValidationSchema } from '../validation-generators/string-validation-factory';
import { dateFormatTest } from '../validation-tests/date-format-test';

export function dateValidation(overrides = {}): StringValidationSchema {
  const defaultValues = {
    requiredMessage: 'você precisa informar uma data',
    test: [],
  };

  const values = Object.assign(defaultValues, overrides);

  return stringValidationFactory({ ...values, test: [dateFormatTest(), ...values.test] });
}
