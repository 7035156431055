import { useFormContext } from 'react-hook-form';
import React from 'react';

export const FormControllerDebug = () => {
  const { formState, getValues } = useFormContext();
  const fieldWatch = getValues();
  
  return (
    <div
      style={{
        background: '#f6f8fa',
        fontSize: '.75rem',
        padding: '.5rem',
        width: '100%',
      }}
    >
      <strong>Field's content: </strong>
      <pre>{JSON.stringify(fieldWatch, null, 2)}</pre>
      <strong>Form erros: </strong>
      <pre>{JSON.stringify(formState.errors, null, 2)}</pre>
    </div>
  );
};
