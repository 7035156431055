import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../components/loading/loading';
import { validateRecoveryHashFactory } from '../../factories/validate-recovery-hash-factory';
import { HashErrorContainer, ValidateRecoveryHashErrors } from './screens/hash-error-container';
import { FormContainer } from './screens/form-container';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { useEnvironment } from '@workspace/providers';
import { RecaptchaActions } from '../../enums/recaptcha-actions-enum';

export const RecoveryContainer = () => {
  const {
    environment: { recaptchaSiteKey },
  } = useEnvironment();

  const [hash, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [validCode, setValidCode] = useState<boolean>(false);
  const [invalidHashCodeMessage, setInvalidHashCodeMessage] = useState<string>('');

  const onError = (errorData) => {
    const errors = errorData?.response?.data?.errors;

    if (errors[0]?.code) {
      setInvalidHashCodeMessage(errors[0]?.code);
    }

    setLoading(false);
    setValidCode(false);
  };

  const validateRecoveryHashUsecase = validateRecoveryHashFactory();

  const validateHash = useCallback(
    async (stringHash) => {
      try {
        const recaptcha = await getRecaptchaToken(RecaptchaActions.VALIDATE_HASH, recaptchaSiteKey);

        const response = await validateRecoveryHashUsecase.execute(stringHash, {
          recaptcha: JSON.stringify(recaptcha),
        });

        if (response?.data?.success) setValidCode(true);
      } catch (error) {
        onError(error);
      }

      setLoading(false);
    },

    [validateRecoveryHashUsecase]
  );

  useEffect(() => {
    const hashParam = new URLSearchParams(window.location.search).get('hash');

    if (!hashParam) {
      setTimeout(() => {
        setInvalidHashCodeMessage(ValidateRecoveryHashErrors.invalid);
        setLoading(false);
      }, 1000);

      return;
    }

    setCode(hashParam);
  }, []);

  useEffect(() => {
    if (hash) validateHash(hash);
  }, [hash, validateHash]);

  if (!loading)
    return validCode ? <FormContainer hash={hash} /> : <HashErrorContainer codeMessage={invalidHashCodeMessage} />;

  return loading && <Loading />;
};
