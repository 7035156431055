import React from 'react';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function SwitchWeb(props) {
  const { Components } = useThemeProvider();
  const { Switch } = Components;
  return (
    <ThemeProviderInjector>
      <Switch {...props} />
    </ThemeProviderInjector>
  );
}

export default SwitchWeb;
