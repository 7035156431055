import { FormControllerFieldConnector } from '@web/form';
import { InputMaskWeb, InputMaskWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import { Automobile } from '@workspace/entities';

export const ManufactureYearEditableField = ({
  id,
  disabled,
  label = 'ano de fabricação',
  onChange,
  autoFocus,
}: {
  disabled?: boolean;
  id?: string;
  label?: string;
  onChange: () => void;
  autoFocus?: boolean;
}) => (
  <FormControllerFieldConnector<InputMaskWebProps>
    component={InputMaskWeb}
    name="fabricationYear"
    disabled={disabled}
    onChange={onChange}
    autoFocus={autoFocus}
    validation={stringValidationFactory({
      requiredMessage: 'você precisa informar o ano de fabricação',
      test: [
        {
          name: 'manufactureYear',
          message: 'você precisa informar uma data válida',
          test: Automobile.validateManufactureYear,
        },
      ],
    })}
    componentProps={{
      label,
      id,
      autoFocus,
      type: 'tel',
      maskType: '9999',
    }}
  />
);
