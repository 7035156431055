import {
  CreateCartAppServiceContract,
  CreateCartAppServiceContractInput,
  CreateCartSerializerContract,
} from '@workspace/frontend/shared/abandoned-cart/application/contracts';
import { CreateCartRepositoryContract } from '@workspace/frontend/shared/abandoned-cart/domain/contracts';

export class CreateCartAppService implements CreateCartAppServiceContract {
  constructor(
    private readonly createCartRepository: CreateCartRepositoryContract,
    private readonly createCartSerializer: CreateCartSerializerContract
  ) {}

  public async execute(input: CreateCartAppServiceContractInput): Promise<void> {
    const createCartPayload = this.createCartSerializer.execute(input);
    await this.createCartRepository.execute(createCartPayload);
  }
}
