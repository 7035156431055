import { rest } from 'msw';

export const searchDomain = (env) => {
  return rest.post(`${env.gatewayBFFBaseURL.url}/dominio`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        data: {
          origemBonus: [],
          classeBonus: [
            {
              codigo: 1,
              descricao: '1',
            },
            {
              codigo: 2,
              descricao: '2',
            },
            {
              codigo: 3,
              descricao: '3',
            },
            {
              codigo: 4,
              descricao: '4',
            },
            {
              codigo: 5,
              descricao: '5',
            },
            {
              codigo: 6,
              descricao: '6',
            },
            {
              codigo: 7,
              descricao: '7',
            },
            {
              codigo: 8,
              descricao: '8',
            },
            {
              codigo: 9,
              descricao: '9',
            },
            {
              codigo: 10,
              descricao: '10',
            },
          ],
          tipoDocumentos: [
            {
              codigo: 1,
              descricao: 'Cadastro de Pessoa Física',
            },
            {
              codigo: 2,
              descricao: 'Cadastro Nacional de Pessoa Jurídica',
            },
            {
              codigo: 3,
              descricao: 'Registro Nacional de Estrangeiro',
            },
            {
              codigo: 4,
              descricao: 'Carteira Nacional de Habilitação',
            },
            {
              codigo: 5,
              descricao: 'Registro Geral',
            },
            {
              codigo: 7,
              descricao: 'Carteira de Identificação Profissional',
            },
            {
              codigo: 8,
              descricao: 'Passaporte',
            },
          ],
          tipoCongeneres: [
            {
              codigoSusepCongenere: 8737,
              nomeLegalPessoa: 'Aig Seguros Brasil',
              codigoPessoa: 11,
            },
            {
              codigoSusepCongenere: 5177,
              nomeLegalPessoa: 'Allianz Seguros',
              codigoPessoa: 14,
            },
            {
              codigoSusepCongenere: 6807,
              nomeLegalPessoa: 'Absoluta Seguros S/A',
              codigoPessoa: 15,
            },
            {
              codigoSusepCongenere: 6513,
              nomeLegalPessoa: 'Ace Seguradora S.A',
              codigoPessoa: 16,
            },
            {
              codigoSusepCongenere: 6734,
              nomeLegalPessoa: 'Aig Brasil Interamericana Cia de Seguros',
              codigoPessoa: 17,
            },
            {
              codigoSusepCongenere: 6297,
              nomeLegalPessoa: 'Aig Life Companhia de Seguros',
              codigoPessoa: 18,
            },
            {
              codigoSusepCongenere: 6629,
              nomeLegalPessoa: 'Ajax Cia Nacional de Seguros',
              codigoPessoa: 20,
            },
            {
              codigoSusepCongenere: 6467,
              nomeLegalPessoa: 'Alfa Seguradora S/A',
              codigoPessoa: 21,
            },
            {
              codigoSusepCongenere: 6764,
              nomeLegalPessoa: 'Alfa Seguros e Previdência S/A',
              codigoPessoa: 22,
            },
            {
              codigoSusepCongenere: 6211,
              nomeLegalPessoa: 'Aliança do Brasil Seguros S.A',
              codigoPessoa: 23,
            },
            {
              codigoSusepCongenere: 5398,
              nomeLegalPessoa: 'Aliança Rio Grandenses Cia de Seguros Gerais',
              codigoPessoa: 24,
            },
            {
              codigoSusepCongenere: 6491,
              nomeLegalPessoa: 'Alvorada Vida',
              codigoPessoa: 26,
            },
            {
              codigoSusepCongenere: 8826,
              nomeLegalPessoa: 'Amazonas Seguradora S/A',
              codigoPessoa: 27,
            },
            {
              codigoSusepCongenere: 5819,
              nomeLegalPessoa: 'American Life Companhia de Seguros',
              codigoPessoa: 28,
            },
            {
              codigoSusepCongenere: 8729,
              nomeLegalPessoa: 'American Motorist',
              codigoPessoa: 29,
            },
            {
              codigoSusepCongenere: 5789,
              nomeLegalPessoa: 'Amil Seguradora',
              codigoPessoa: 30,
            },
            {
              codigoSusepCongenere: 5479,
              nomeLegalPessoa: 'Aplub Seguros S/A',
              codigoPessoa: 31,
            },
            {
              codigoSusepCongenere: 6777,
              nomeLegalPessoa: 'Aps Seguradora S/A',
              codigoPessoa: 32,
            },
            {
              codigoSusepCongenere: 5606,
              nomeLegalPessoa: 'Arbi Seguros S/A',
              codigoPessoa: 33,
            },
            {
              codigoSusepCongenere: 2798,
              nomeLegalPessoa: 'Argo Seguros Brasil S.A.',
              codigoPessoa: 34,
            },
            {
              codigoSusepCongenere: 2143,
              nomeLegalPessoa: 'Assurant Seguradora S/A',
              codigoPessoa: 35,
            },
            {
              codigoSusepCongenere: 5932,
              nomeLegalPessoa: 'Atlantica - Bradesco Seguros S A',
              codigoPessoa: 36,
            },
            {
              codigoSusepCongenere: 6386,
              nomeLegalPessoa: 'Atlantica Seguros S/A',
              codigoPessoa: 37,
            },
            {
              codigoSusepCongenere: 6289,
              nomeLegalPessoa: 'Aurea Seguradora',
              codigoPessoa: 38,
            },
            {
              codigoSusepCongenere: 6998,
              nomeLegalPessoa: 'Aurea Seguros S.A',
              codigoPessoa: 39,
            },
            {
              codigoSusepCongenere: 5967,
              nomeLegalPessoa: 'Avs Seguradora S/A',
              codigoPessoa: 40,
            },
            {
              codigoSusepCongenere: 2852,
              nomeLegalPessoa: 'Axa Seguros S.A.',
              codigoPessoa: 41,
            },
            {
              codigoSusepCongenere: 5266,
              nomeLegalPessoa: 'Azul Companhia de Seguros Gerais',
              codigoPessoa: 42,
            },
            {
              codigoSusepCongenere: 5355,
              nomeLegalPessoa: 'Azul Companhia de Seguros Gerais',
              codigoPessoa: 43,
            },
            {
              codigoSusepCongenere: 5975,
              nomeLegalPessoa: 'B C N Seguradora S/A',
              codigoPessoa: 44,
            },
            {
              codigoSusepCongenere: 5835,
              nomeLegalPessoa: 'Bamerindus Vida Seguros SA',
              codigoPessoa: 45,
            },
            {
              codigoSusepCongenere: 9989,
              nomeLegalPessoa: 'Banco Nacional da Habitação',
              codigoPessoa: 46,
            },
            {
              codigoSusepCongenere: 6009,
              nomeLegalPessoa: 'Banerj Seguros S/A',
              codigoPessoa: 47,
            },
            {
              codigoSusepCongenere: 5274,
              nomeLegalPessoa: 'Banestes Seguros S/A',
              codigoPessoa: 48,
            },
            {
              codigoSusepCongenere: 5231,
              nomeLegalPessoa: 'Bcs Seguros SA',
              codigoPessoa: 49,
            },
            {
              codigoSusepCongenere: 6611,
              nomeLegalPessoa: 'Bemge Seguradora S/A',
              codigoPessoa: 50,
            },
            {
              codigoSusepCongenere: 5843,
              nomeLegalPessoa: 'Indiana Seguros SA',
              codigoPessoa: 51,
            },
            {
              codigoSusepCongenere: 5860,
              nomeLegalPessoa: 'Catarinense de Seguros S/A',
              codigoPessoa: 52,
            },
            {
              codigoSusepCongenere: 5894,
              nomeLegalPessoa: 'Pátria Cia Brás Segs Gerais',
              codigoPessoa: 53,
            },
            {
              codigoSusepCongenere: 5908,
              nomeLegalPessoa: 'Generali do Brasil Cia Nacional de Segs',
              codigoPessoa: 54,
            },
            {
              codigoSusepCongenere: 5916,
              nomeLegalPessoa: 'Real Seguradora S/A',
              codigoPessoa: 55,
            },
            {
              codigoSusepCongenere: 5924,
              nomeLegalPessoa: 'Cia Segs Rio Branco',
              codigoPessoa: 56,
            },
            {
              codigoSusepCongenere: 5983,
              nomeLegalPessoa: 'Unibanco Aig Seguros S/A',
              codigoPessoa: 57,
            },
            {
              codigoSusepCongenere: 6041,
              nomeLegalPessoa: 'Paraná Cia de Seguros',
              codigoPessoa: 58,
            },
            {
              codigoSusepCongenere: 6076,
              nomeLegalPessoa: 'Mundial Seguradora S/A',
              codigoPessoa: 59,
            },
            {
              codigoSusepCongenere: 6092,
              nomeLegalPessoa: 'Novo Hamburgo Cia de Segs Gerais',
              codigoPessoa: 60,
            },
            {
              codigoSusepCongenere: 6106,
              nomeLegalPessoa: 'Hsbc Seguros (Brasil) S.A.',
              codigoPessoa: 61,
            },
            {
              codigoSusepCongenere: 6114,
              nomeLegalPessoa: 'Itatiaia Seguros S/A',
              codigoPessoa: 62,
            },
            {
              codigoSusepCongenere: 6122,
              nomeLegalPessoa: 'Cigna Seguradora S.A.',
              codigoPessoa: 63,
            },
            {
              codigoSusepCongenere: 6190,
              nomeLegalPessoa: 'Tokio Marine Seguradora S.A.',
              codigoPessoa: 64,
            },
            {
              codigoSusepCongenere: 6203,
              nomeLegalPessoa: 'Zurich Brasil Seguros S/A',
              codigoPessoa: 65,
            },
            {
              codigoSusepCongenere: 6238,
              nomeLegalPessoa: 'Mapfre / BB e Mapfre',
              codigoPessoa: 66,
            },
            {
              codigoSusepCongenere: 6254,
              nomeLegalPessoa: 'Cia Patrimonial de Segs Gerais',
              codigoPessoa: 67,
            },
            {
              codigoSusepCongenere: 6343,
              nomeLegalPessoa: 'Sol de Seguros S/A',
              codigoPessoa: 68,
            },
            {
              codigoSusepCongenere: 6360,
              nomeLegalPessoa: 'Kyoei do Brasil Cia de Segs',
              codigoPessoa: 69,
            },
            {
              codigoSusepCongenere: 5363,
              nomeLegalPessoa: 'Meridional Cia de Segs Gerais',
              codigoPessoa: 70,
            },
            {
              codigoSusepCongenere: 5401,
              nomeLegalPessoa: 'Pq Seguros S/A',
              codigoPessoa: 71,
            },
            {
              codigoSusepCongenere: 5444,
              nomeLegalPessoa: 'Bradesco Seguros S/A',
              codigoPessoa: 72,
            },
            {
              codigoSusepCongenere: 5452,
              nomeLegalPessoa: 'Fortaleza Cia Nacional de Seguros',
              codigoPessoa: 73,
            },
            {
              codigoSusepCongenere: 5461,
              nomeLegalPessoa: 'Skandia-Bradesco Cia Brás Segs',
              codigoPessoa: 74,
            },
            {
              codigoSusepCongenere: 5487,
              nomeLegalPessoa: 'Santander Noroeste Seguradora S.A',
              codigoPessoa: 75,
            },
            {
              codigoSusepCongenere: 5495,
              nomeLegalPessoa: 'Zurich Minas-Brasil Seguros S/A',
              codigoPessoa: 76,
            },
            {
              codigoSusepCongenere: 5533,
              nomeLegalPessoa: 'Finasa Seguradora S/A',
              codigoPessoa: 77,
            },
            {
              codigoSusepCongenere: 5541,
              nomeLegalPessoa: 'Ccf Brasil Seguros S.A',
              codigoPessoa: 78,
            },
            {
              codigoSusepCongenere: 5550,
              nomeLegalPessoa: 'Seguradora Indl e Mercantil S/A',
              codigoPessoa: 79,
            },
            {
              codigoSusepCongenere: 5304,
              nomeLegalPessoa: 'Cia Internacional de Seguros',
              codigoPessoa: 80,
            },
            {
              codigoSusepCongenere: 5614,
              nomeLegalPessoa: 'Sul América Santa Cruz Seguros S.A',
              codigoPessoa: 81,
            },
            {
              codigoSusepCongenere: 5622,
              nomeLegalPessoa: 'Brasil Saúde Companhia de Seguros',
              codigoPessoa: 82,
            },
            {
              codigoSusepCongenere: 5631,
              nomeLegalPessoa: 'Caixa Seguros',
              codigoPessoa: 83,
            },
            {
              codigoSusepCongenere: 5649,
              nomeLegalPessoa: 'Royal Seguros (Brasil) S/A',
              codigoPessoa: 84,
            },
            {
              codigoSusepCongenere: 5681,
              nomeLegalPessoa: 'Sul América Bandeirante Segs S/A',
              codigoPessoa: 85,
            },
            {
              codigoSusepCongenere: 5690,
              nomeLegalPessoa: 'Cia Excelsior de Seguros',
              codigoPessoa: 86,
            },
            {
              codigoSusepCongenere: 5711,
              nomeLegalPessoa: 'Bradesco Saúde S/A',
              codigoPessoa: 87,
            },
            {
              codigoSusepCongenere: 5720,
              nomeLegalPessoa: 'Sompo Seguros S/A',
              codigoPessoa: 88,
            },
            {
              codigoSusepCongenere: 5738,
              nomeLegalPessoa: 'Financial Cia de Seguros',
              codigoPessoa: 89,
            },
            {
              codigoSusepCongenere: 5746,
              nomeLegalPessoa: 'Trevo Banorte Seguradora',
              codigoPessoa: 90,
            },
            {
              codigoSusepCongenere: 5801,
              nomeLegalPessoa: 'Trevo Seguradora S/A',
              codigoPessoa: 91,
            },
            {
              codigoSusepCongenere: 6068,
              nomeLegalPessoa: 'Santos Seguradora S/A',
              codigoPessoa: 92,
            },
            {
              codigoSusepCongenere: 5011,
              nomeLegalPessoa: 'Chubb do Brasil Cia de Seguros',
              codigoPessoa: 93,
            },
            {
              codigoSusepCongenere: 5037,
              nomeLegalPessoa: 'Sul América Unibanco Seguradora S/A',
              codigoPessoa: 94,
            },
            {
              codigoSusepCongenere: 5045,
              nomeLegalPessoa: 'Cia de Segs Aliança da Bahia',
              codigoPessoa: 95,
            },
            {
              codigoSusepCongenere: 5053,
              nomeLegalPessoa: 'Confiança Cia de Seguros',
              codigoPessoa: 96,
            },
            {
              codigoSusepCongenere: 5886,
              nomeLegalPessoa: 'Porto Seguro Cia de Seguros Gerais',
              codigoPessoa: 97,
            },
            {
              codigoSusepCongenere: 5096,
              nomeLegalPessoa: 'Unibanco Aig Vida e Prev S/A',
              codigoPessoa: 98,
            },
            {
              codigoSusepCongenere: 5118,
              nomeLegalPessoa: 'Sul América Cia Nacional Seguros',
              codigoPessoa: 99,
            },
            {
              codigoSusepCongenere: 5126,
              nomeLegalPessoa: 'Universal Cia de Segs Gerais',
              codigoPessoa: 100,
            },
            {
              codigoSusepCongenere: 5185,
              nomeLegalPessoa: 'Liberty Paulista Seguros SA',
              codigoPessoa: 102,
            },
            {
              codigoSusepCongenere: 5193,
              nomeLegalPessoa: 'Cia de Segs Previdência do Sul',
              codigoPessoa: 103,
            },
            {
              codigoSusepCongenere: 5207,
              nomeLegalPessoa: 'Cia Sul Brasil Segs Terrest e Marit',
              codigoPessoa: 104,
            },
            {
              codigoSusepCongenere: 5240,
              nomeLegalPessoa: 'Sul América Terrest Marit e Acidentes',
              codigoPessoa: 105,
            },
            {
              codigoSusepCongenere: 5282,
              nomeLegalPessoa: 'Prudential do BR Seguros de Vida S/A',
              codigoPessoa: 106,
            },
            {
              codigoSusepCongenere: 5291,
              nomeLegalPessoa: 'São Paulo Seguros S/A',
              codigoPessoa: 107,
            },
            {
              codigoSusepCongenere: 5312,
              nomeLegalPessoa: 'Bradesco Auto/Re Cia de Seguros',
              codigoPessoa: 108,
            },
            {
              codigoSusepCongenere: 5321,
              nomeLegalPessoa: 'Itaú Seguros S/A',
              codigoPessoa: 109,
            },
            {
              codigoSusepCongenere: 1147,
              nomeLegalPessoa: 'Federal Vida e Previdência',
              codigoPessoa: 110,
            },
            {
              codigoSusepCongenere: 3166,
              nomeLegalPessoa: 'MetLife Vida e Previdência S/A',
              codigoPessoa: 111,
            },
            {
              codigoSusepCongenere: 3816,
              nomeLegalPessoa: 'Real Vida e Previdência SA',
              codigoPessoa: 112,
            },
            {
              codigoSusepCongenere: 4707,
              nomeLegalPessoa: 'BrasilPrev Seguros e Previdência SA',
              codigoPessoa: 113,
            },
            {
              codigoSusepCongenere: 4812,
              nomeLegalPessoa: 'Euler Hermes Seguros de Credito a Export',
              codigoPessoa: 114,
            },
            {
              codigoSusepCongenere: 5088,
              nomeLegalPessoa: 'Bs Seguro de Vida S/A',
              codigoPessoa: 115,
            },
            {
              codigoSusepCongenere: 5134,
              nomeLegalPessoa: 'Equitativa dos Estados Unidos do Brasil',
              codigoPessoa: 116,
            },
            {
              codigoSusepCongenere: 5509,
              nomeLegalPessoa: 'Delfin Seguradora S/A',
              codigoPessoa: 117,
            },
            {
              codigoSusepCongenere: 5568,
              nomeLegalPessoa: 'Safra Cia de Seguros',
              codigoPessoa: 118,
            },
            {
              codigoSusepCongenere: 5703,
              nomeLegalPessoa: 'Fidelidade Companhia de Seg Gerais',
              codigoPessoa: 119,
            },
            {
              codigoSusepCongenere: 5827,
              nomeLegalPessoa: 'Financial Seguros Gerais SA',
              codigoPessoa: 120,
            },
            {
              codigoSusepCongenere: 6149,
              nomeLegalPessoa: 'Trevo S/A Seguros e Previdência Privada',
              codigoPessoa: 121,
            },
            {
              codigoSusepCongenere: 6165,
              nomeLegalPessoa: 'Seguradora Mineira S/A',
              codigoPessoa: 122,
            },
            {
              codigoSusepCongenere: 6181,
              nomeLegalPessoa: 'Brasil Veículos Companhia de Seguros',
              codigoPessoa: 123,
            },
            {
              codigoSusepCongenere: 6301,
              nomeLegalPessoa: 'Canada Life Previdência de Seguros',
              codigoPessoa: 124,
            },
            {
              codigoSusepCongenere: 6327,
              nomeLegalPessoa: 'Planalto Companhia de Seguros Gerais',
              codigoPessoa: 125,
            },
            {
              codigoSusepCongenere: 6378,
              nomeLegalPessoa: 'Vida Seguradora S/A',
              codigoPessoa: 126,
            },
            {
              codigoSusepCongenere: 6432,
              nomeLegalPessoa: 'Nationale Nederlanden',
              codigoPessoa: 127,
            },
            {
              codigoSusepCongenere: 6548,
              nomeLegalPessoa: 'Cardif do Brasil Seguros e Previdência',
              codigoPessoa: 128,
            },
            {
              codigoSusepCongenere: 6823,
              nomeLegalPessoa: 'Liderança Seguradora SA',
              codigoPessoa: 129,
            },
            {
              codigoSusepCongenere: 7005,
              nomeLegalPessoa: 'Nova York Companhia de Seguros',
              codigoPessoa: 130,
            },
            {
              codigoSusepCongenere: 7048,
              nomeLegalPessoa: 'Cia de Seguros Sul América Industrial SA',
              codigoPessoa: 131,
            },
            {
              codigoSusepCongenere: 7064,
              nomeLegalPessoa: 'Seguradora Mineira SA',
              codigoPessoa: 132,
            },
            {
              codigoSusepCongenere: 7111,
              nomeLegalPessoa: 'Delfin Seguradora SA',
              codigoPessoa: 133,
            },
            {
              codigoSusepCongenere: 7145,
              nomeLegalPessoa: 'Companhia de Seguros Monarca',
              codigoPessoa: 134,
            },
            {
              codigoSusepCongenere: 7161,
              nomeLegalPessoa: 'Gnpp Seguradora S/A Retroc Cancel',
              codigoPessoa: 135,
            },
            {
              codigoSusepCongenere: 7323,
              nomeLegalPessoa: 'São Paulo Seguros SA',
              codigoPessoa: 136,
            },
            {
              codigoSusepCongenere: 7382,
              nomeLegalPessoa: 'Fidúcia Companhia de Seguros Gerais',
              codigoPessoa: 137,
            },
            {
              codigoSusepCongenere: 8001,
              nomeLegalPessoa: 'Marítima Saúde Seguros S/A',
              codigoPessoa: 138,
            },
            {
              codigoSusepCongenere: 8036,
              nomeLegalPessoa: 'Itauseg Saúde SA',
              codigoPessoa: 139,
            },
            {
              codigoSusepCongenere: 8052,
              nomeLegalPessoa: 'Salutar Seguradora SA',
              codigoPessoa: 140,
            },
            {
              codigoSusepCongenere: 8702,
              nomeLegalPessoa: 'Sud América',
              codigoPessoa: 141,
            },
            {
              codigoSusepCongenere: 9995,
              nomeLegalPessoa: 'Bônus de Companhia do Exterior',
              codigoPessoa: 142,
            },
            {
              codigoSusepCongenere: 1627,
              nomeLegalPessoa: 'Safra Seguros Gerais SA',
              codigoPessoa: 143,
            },
            {
              codigoSusepCongenere: 1929,
              nomeLegalPessoa: 'Hsbc Seguros S.A.',
              codigoPessoa: 144,
            },
            {
              codigoSusepCongenere: 6874,
              nomeLegalPessoa: 'Santander Seguros S/A',
              codigoPessoa: 145,
            },
            {
              codigoSusepCongenere: 6947,
              nomeLegalPessoa: 'Unimed Seguradora S/A',
              codigoPessoa: 146,
            },
            {
              codigoSusepCongenere: 7501,
              nomeLegalPessoa: 'Total Seguradora S/A',
              codigoPessoa: 147,
            },
            {
              codigoSusepCongenere: 5033,
              nomeLegalPessoa: 'Gboex - Confiança Cia de Seguros',
              codigoPessoa: 148,
            },
            {
              codigoSusepCongenere: 5223,
              nomeLegalPessoa: 'Gnpp-Provida Seguradora SA',
              codigoPessoa: 149,
            },
            {
              codigoSusepCongenere: 5215,
              nomeLegalPessoa: 'Itaú Previdência e Seguros S.A',
              codigoPessoa: 150,
            },
            {
              codigoSusepCongenere: 6955,
              nomeLegalPessoa: 'Cia de Seguros Gralha Azul',
              codigoPessoa: 151,
            },
            {
              codigoSusepCongenere: 5347,
              nomeLegalPessoa: 'Edel Seguradora S/A',
              codigoPessoa: 152,
            },
            {
              codigoSusepCongenere: 6971,
              nomeLegalPessoa: 'Golden Cross Seguradora S/A',
              codigoPessoa: 153,
            },
            {
              codigoSusepCongenere: 5258,
              nomeLegalPessoa: 'Habitasul Seguradora S/A',
              codigoPessoa: 154,
            },
            {
              codigoSusepCongenere: 5762,
              nomeLegalPessoa: 'Santos Cia de Seguros',
              codigoPessoa: 155,
            },
            {
              codigoSusepCongenere: 6921,
              nomeLegalPessoa: 'Rural Seguradora S/A',
              codigoPessoa: 156,
            },
            {
              codigoSusepCongenere: 5169,
              nomeLegalPessoa: 'Sau Seguros e Previdência',
              codigoPessoa: 157,
            },
            {
              codigoSusepCongenere: 6980,
              nomeLegalPessoa: 'Notre Dame Seguradora S/A',
              codigoPessoa: 158,
            },
            {
              codigoSusepCongenere: 6521,
              nomeLegalPessoa: 'Caixa Geral Seguradora S/A',
              codigoPessoa: 159,
            },
            {
              codigoSusepCongenere: 5428,
              nomeLegalPessoa: 'Martinelli Seguradora S/A',
              codigoPessoa: 160,
            },
            {
              codigoSusepCongenere: 5851,
              nomeLegalPessoa: 'Castello Costa Companhia Seguros',
              codigoPessoa: 161,
            },
            {
              codigoSusepCongenere: 5436,
              nomeLegalPessoa: 'J Malucelli Seguradora S/A',
              codigoPessoa: 162,
            },
            {
              codigoSusepCongenere: 6025,
              nomeLegalPessoa: 'Carioca Seguradora S/A',
              codigoPessoa: 163,
            },
            {
              codigoSusepCongenere: 6908,
              nomeLegalPessoa: 'Notre Dame Seguradora S/A',
              codigoPessoa: 164,
            },
            {
              codigoSusepCongenere: 6769,
              nomeLegalPessoa: 'Ubf Garantias S/A',
              codigoPessoa: 165,
            },
            {
              codigoSusepCongenere: 6785,
              nomeLegalPessoa: 'Cia de Seguros Aliança do Brasil',
              codigoPessoa: 166,
            },
            {
              codigoSusepCongenere: 6793,
              nomeLegalPessoa: 'Gente Seguradora S/A',
              codigoPessoa: 167,
            },
            {
              codigoSusepCongenere: 6858,
              nomeLegalPessoa: 'Seguradora Roma S/A',
              codigoPessoa: 168,
            },
            {
              codigoSusepCongenere: 6882,
              nomeLegalPessoa: 'Cia Geral de Seguros',
              codigoPessoa: 169,
            },
            {
              codigoSusepCongenere: 9938,
              nomeLegalPessoa: 'Safra Vida e Previdência S/A',
              codigoPessoa: 170,
            },
            {
              codigoSusepCongenere: 9990,
              nomeLegalPessoa: 'Delphos Servicos Técnicos S/A',
              codigoPessoa: 171,
            },
            {
              codigoSusepCongenere: 6831,
              nomeLegalPessoa: 'Sinaf Previdência Cia de Seguros',
              codigoPessoa: 172,
            },
            {
              codigoSusepCongenere: 6891,
              nomeLegalPessoa: 'Saoex S/A Seguradora',
              codigoPessoa: 173,
            },
            {
              codigoSusepCongenere: 6904,
              nomeLegalPessoa: 'Soma Seguradora S/A',
              codigoPessoa: 174,
            },
            {
              codigoSusepCongenere: 5061,
              nomeLegalPessoa: 'Bozzano Simonsen Seguradora S/A',
              codigoPessoa: 175,
            },
            {
              codigoSusepCongenere: 6939,
              nomeLegalPessoa: 'Seguradora Seasul S/A',
              codigoPessoa: 176,
            },
            {
              codigoSusepCongenere: 5070,
              nomeLegalPessoa: 'Zurich Santander Brasil Seguro Prev S/A',
              codigoPessoa: 177,
            },
            {
              codigoSusepCongenere: 6912,
              nomeLegalPessoa: 'Conapp Cia Nacional de Seguros',
              codigoPessoa: 178,
            },
            {
              codigoSusepCongenere: 6963,
              nomeLegalPessoa: 'Gnpp Seguradora S/A',
              codigoPessoa: 179,
            },
            {
              codigoSusepCongenere: 6416,
              nomeLegalPessoa: 'Yasuda Seguros S/A',
              codigoPessoa: 180,
            },
            {
              codigoSusepCongenere: 6424,
              nomeLegalPessoa: 'S D B Cia de Seguros Gerais',
              codigoPessoa: 181,
            },
            {
              codigoSusepCongenere: 6441,
              nomeLegalPessoa: 'Safra Seguradora S/A',
              codigoPessoa: 182,
            },
            {
              codigoSusepCongenere: 6459,
              nomeLegalPessoa: 'Cia de Segs Inter-atlântico',
              codigoPessoa: 183,
            },
            {
              codigoSusepCongenere: 6483,
              nomeLegalPessoa: 'Xl Insurance (Brasil) Seguradoras S/A',
              codigoPessoa: 184,
            },
            {
              codigoSusepCongenere: 6556,
              nomeLegalPessoa: 'Sul América Seguros Gerais',
              codigoPessoa: 185,
            },
            {
              codigoSusepCongenere: 6572,
              nomeLegalPessoa: 'Hdi Seguros S/A',
              codigoPessoa: 186,
            },
            {
              codigoSusepCongenere: 6602,
              nomeLegalPessoa: 'Mitsui Sumitomo Seguros S/A',
              codigoPessoa: 187,
            },
            {
              codigoSusepCongenere: 6866,
              nomeLegalPessoa: 'Bradesco Previdência e Seguros S/A',
              codigoPessoa: 188,
            },
            {
              codigoSusepCongenere: 6637,
              nomeLegalPessoa: 'Inter-Continental Cia de Segs',
              codigoPessoa: 189,
            },
            {
              codigoSusepCongenere: 6645,
              nomeLegalPessoa: 'Cia Real Brasileira de Segs',
              codigoPessoa: 190,
            },
            {
              codigoSusepCongenere: 6653,
              nomeLegalPessoa: 'Panamericana de Seguros S/A',
              codigoPessoa: 191,
            },
            {
              codigoSusepCongenere: 5576,
              nomeLegalPessoa: 'Cruzeiro do Sul Seguros S/A',
              codigoPessoa: 192,
            },
            {
              codigoSusepCongenere: 6688,
              nomeLegalPessoa: 'Cia de Segs do Est de São Paulo',
              codigoPessoa: 193,
            },
            {
              codigoSusepCongenere: 6696,
              nomeLegalPessoa: 'Gerling Sul América S/A Segs Inds',
              codigoPessoa: 194,
            },
            {
              codigoSusepCongenere: 6700,
              nomeLegalPessoa: 'Cia de Seguros Monarca',
              codigoPessoa: 195,
            },
            {
              codigoSusepCongenere: 6726,
              nomeLegalPessoa: 'Multiplic Seguradora S/A',
              codigoPessoa: 196,
            },
            {
              codigoSusepCongenere: 6742,
              nomeLegalPessoa: 'Cia Colina de Seguros',
              codigoPessoa: 197,
            },
            {
              codigoSusepCongenere: 6751,
              nomeLegalPessoa: 'Seguros Sura S/A',
              codigoPessoa: 198,
            },
            {
              codigoSusepCongenere: 6157,
              nomeLegalPessoa: 'Minas Brasil Seguradora Vida e Prev S/A',
              codigoPessoa: 199,
            },
            {
              codigoSusepCongenere: 6173,
              nomeLegalPessoa: 'Investprev Seguros e Previdência S A',
              codigoPessoa: 200,
            },
            {
              codigoSusepCongenere: 6271,
              nomeLegalPessoa: 'Sudameris Vida e Prev S/A',
              codigoPessoa: 201,
            },
            {
              codigoSusepCongenere: 6319,
              nomeLegalPessoa: 'Companhia Real de Seguros',
              codigoPessoa: 202,
            },
            {
              codigoSusepCongenere: 6335,
              nomeLegalPessoa: 'Seguradora Brasileira de Credito Exporta',
              codigoPessoa: 203,
            },
            {
              codigoSusepCongenere: 6394,
              nomeLegalPessoa: 'Itauprev Vida e Previdência',
              codigoPessoa: 204,
            },
            {
              codigoSusepCongenere: 6475,
              nomeLegalPessoa: 'Sul América Aetna Cia Nac de Seg Previde',
              codigoPessoa: 205,
            },
            {
              codigoSusepCongenere: 6505,
              nomeLegalPessoa: 'Brasluzitana',
              codigoPessoa: 206,
            },
            {
              codigoSusepCongenere: 6670,
              nomeLegalPessoa: 'Visconde de Itaboraí Cia de Seguros Gera',
              codigoPessoa: 207,
            },
            {
              codigoSusepCongenere: 6840,
              nomeLegalPessoa: 'Unibanco Aig Previdência SA',
              codigoPessoa: 208,
            },
            {
              codigoSusepCongenere: 7013,
              nomeLegalPessoa: 'Paraná Companhia de Seguros',
              codigoPessoa: 209,
            },
            {
              codigoSusepCongenere: 7056,
              nomeLegalPessoa: 'Companhia Central de Seguros',
              codigoPessoa: 210,
            },
            {
              codigoSusepCongenere: 7102,
              nomeLegalPessoa: 'Castello Costa Companhia de Seguros',
              codigoPessoa: 211,
            },
            {
              codigoSusepCongenere: 7129,
              nomeLegalPessoa: 'Cruzeiro do Sul Cia Seguradora',
              codigoPessoa: 212,
            },
            {
              codigoSusepCongenere: 7153,
              nomeLegalPessoa: 'Gnpp Provida Seguradora S/A',
              codigoPessoa: 213,
            },
            {
              codigoSusepCongenere: 7269,
              nomeLegalPessoa: 'Companhia Patrimonial de Seguros Gerais',
              codigoPessoa: 214,
            },
            {
              codigoSusepCongenere: 7340,
              nomeLegalPessoa: 'Seguradora Agrobanco',
              codigoPessoa: 215,
            },
            {
              codigoSusepCongenere: 7498,
              nomeLegalPessoa: 'Cia Internacional de Seguros',
              codigoPessoa: 216,
            },
            {
              codigoSusepCongenere: 8028,
              nomeLegalPessoa: 'Gralha Azul Saúde SA',
              codigoPessoa: 217,
            },
            {
              codigoSusepCongenere: 8044,
              nomeLegalPessoa: 'Porto Seguro Seguro Saúde SA',
              codigoPessoa: 218,
            },
            {
              codigoSusepCongenere: 8141,
              nomeLegalPessoa: 'Caixa Vida e Previdência SA',
              codigoPessoa: 219,
            },
            {
              codigoSusepCongenere: 5665,
              nomeLegalPessoa: 'Mapfre Vera Cruz Vida e Previdência S/A',
              codigoPessoa: 220,
            },
            {
              codigoSusepCongenere: 1066,
              nomeLegalPessoa: 'Seguradora Pontual',
              codigoPessoa: 221,
            },
            {
              codigoSusepCongenere: 9993,
              nomeLegalPessoa: 'Sem Furto Ou Roubo',
              codigoPessoa: 222,
            },
            {
              codigoSusepCongenere: 3298,
              nomeLegalPessoa: 'Mapfre Seguradora e Garantia de Credito',
              codigoPessoa: 223,
            },
            {
              codigoSusepCongenere: 6220,
              nomeLegalPessoa: 'Sul América Seguros de Vida e Previdência',
              codigoPessoa: 224,
            },
            {
              codigoSusepCongenere: 9991,
              nomeLegalPessoa: 'Informado Anteriormente',
              codigoPessoa: 225,
            },
            {
              codigoSusepCongenere: 9994,
              nomeLegalPessoa: 'Veiculo s/ Condição Identificação',
              codigoPessoa: 226,
            },
            {
              codigoSusepCongenere: 9992,
              nomeLegalPessoa: 'Vitima sem Seguro',
              codigoPessoa: 227,
            },
            {
              codigoSusepCongenere: 4359,
              nomeLegalPessoa: 'Euler Hermes Seguros de Credito',
              codigoPessoa: 228,
            },
            {
              codigoSusepCongenere: 4740,
              nomeLegalPessoa: 'Hsbc Vida e Previdência (Brasil) SA',
              codigoPessoa: 229,
            },
            {
              codigoSusepCongenere: 4987,
              nomeLegalPessoa: 'Eure-Exterior',
              codigoPessoa: 230,
            },
            {
              codigoSusepCongenere: 5100,
              nomeLegalPessoa: 'Comercial do para Companhia de Seguros',
              codigoPessoa: 231,
            },
            {
              codigoSusepCongenere: 5410,
              nomeLegalPessoa: 'Novo Mundo',
              codigoPessoa: 232,
            },
            {
              codigoSusepCongenere: 5517,
              nomeLegalPessoa: 'Preferencial de Seguros',
              codigoPessoa: 233,
            },
            {
              codigoSusepCongenere: 5584,
              nomeLegalPessoa: 'Multiplic Companhia de Seguros',
              codigoPessoa: 234,
            },
            {
              codigoSusepCongenere: 5673,
              nomeLegalPessoa: 'Itaboraí Seguros S A',
              codigoPessoa: 235,
            },
            {
              codigoSusepCongenere: 5771,
              nomeLegalPessoa: 'Companhia Interestadual de Seguros',
              codigoPessoa: 236,
            },
            {
              codigoSusepCongenere: 5959,
              nomeLegalPessoa: 'Segurança Cia de Seguros e Previdência',
              codigoPessoa: 237,
            },
            {
              codigoSusepCongenere: 5941,
              nomeLegalPessoa: 'Qbe Brasil Seguros S/A',
              codigoPessoa: 238,
            },
            {
              codigoSusepCongenere: 1007,
              nomeLegalPessoa: 'Sabemi Seguradora S.A',
              codigoPessoa: 239,
            },
            {
              codigoSusepCongenere: 5525,
              nomeLegalPessoa: 'Inter Brazil Seguros S/A',
              codigoPessoa: 240,
            },
            {
              codigoSusepCongenere: 5142,
              nomeLegalPessoa: 'Icatu Hartford Seguros S.A',
              codigoPessoa: 241,
            },
            {
              codigoSusepCongenere: 6661,
              nomeLegalPessoa: 'Nova York Cia de Segs',
              codigoPessoa: 242,
            },
            {
              codigoSusepCongenere: 5991,
              nomeLegalPessoa: 'Seguradora Brasileira Rural SA',
              codigoPessoa: 243,
            },
            {
              codigoSusepCongenere: 6815,
              nomeLegalPessoa: 'Cia Ancora de Segs Gerais',
              codigoPessoa: 244,
            },
            {
              codigoSusepCongenere: 5002,
              nomeLegalPessoa: 'Federal de Seguros S.A.',
              codigoPessoa: 245,
            },
            {
              codigoSusepCongenere: 5754,
              nomeLegalPessoa: 'Nobre Seguradora do Brasil',
              codigoPessoa: 246,
            },
            {
              codigoSusepCongenere: 6050,
              nomeLegalPessoa: 'Maxlife Seguradora do Brasil SA',
              codigoPessoa: 247,
            },
            {
              codigoSusepCongenere: 6033,
              nomeLegalPessoa: 'Porto Seguro Vida e Previdência S/A',
              codigoPessoa: 248,
            },
            {
              codigoSusepCongenere: 5592,
              nomeLegalPessoa: 'Total Seguradora',
              codigoPessoa: 249,
            },
            {
              codigoSusepCongenere: 5657,
              nomeLegalPessoa: 'Vanguarda Cia de Seguros Gerais',
              codigoPessoa: 250,
            },
            {
              codigoSusepCongenere: 5878,
              nomeLegalPessoa: 'Sulina Seguradora S/A',
              codigoPessoa: 251,
            },
            {
              codigoSusepCongenere: 6017,
              nomeLegalPessoa: 'Centauro Vida e Previdência S/A',
              codigoPessoa: 252,
            },
            {
              codigoSusepCongenere: 6084,
              nomeLegalPessoa: 'Mbm Seguradora S.A',
              codigoPessoa: 253,
            },
            {
              codigoSusepCongenere: 6246,
              nomeLegalPessoa: 'Sul América Aetna Seguro Saúde SA',
              codigoPessoa: 254,
            },
            {
              codigoSusepCongenere: 6408,
              nomeLegalPessoa: 'Cigna Companhia de Seguros',
              codigoPessoa: 255,
            },
            {
              codigoSusepCongenere: 3182,
              nomeLegalPessoa: 'Isar Itaú Seguros de Auto e Residência',
              codigoPessoa: 256,
            },
            {
              codigoSusepCongenere: 6131,
              nomeLegalPessoa: 'Cia Mutual de Seguros',
              codigoPessoa: 257,
            },
            {
              codigoSusepCongenere: 2950,
              nomeLegalPessoa: 'Sancor Seguros do Brasil S.A',
              codigoPessoa: 258,
            },
            {
              codigoSusepCongenere: 2933,
              nomeLegalPessoa: 'Cardif do Brasil Seguros e Garantias S/A',
              codigoPessoa: 259,
            },
            {
              codigoSusepCongenere: 3727,
              nomeLegalPessoa: 'Travelers Seguros Brasil S.A.',
              codigoPessoa: 260,
            },
            {
              codigoSusepCongenere: 4952,
              nomeLegalPessoa: 'Suhai Seguros S/A',
              codigoPessoa: 261,
            },
            {
              codigoSusepCongenere: 1589,
              nomeLegalPessoa: 'Santander Auto S.A.',
              codigoPessoa: 263,
            },
            {
              codigoSusepCongenere: 4669,
              nomeLegalPessoa: 'Fairfax Brasil Seguros Corporativos S.A',
              codigoPessoa: 264,
            },
            {
              codigoSusepCongenere: 1414,
              nomeLegalPessoa: 'Berkley International Brasil Seguros S/A',
              codigoPessoa: 265,
            },
            {
              codigoSusepCongenere: 1970,
              nomeLegalPessoa: 'Unimed Seguros Patrimoniais S.A.',
              codigoPessoa: 266,
            },
            {
              codigoSusepCongenere: 1121,
              nomeLegalPessoa: 'Youse Seguradora S.A.',
              codigoPessoa: 267,
            },
            {
              codigoSusepCongenere: 1015,
              nomeLegalPessoa: 'SulAmérica Seguros de Automóveis e Massificados S.A.',
              codigoPessoa: 20895,
            },
          ],
          tipoPessoas: [
            {
              codigo: 1,
              descricao: 'Pessoa Fisica',
            },
            {
              codigo: 2,
              descricao: 'Pessoa Juridica',
            },
            {
              codigo: 3,
              descricao: 'Orgao Governamental',
            },
          ],
          tipoSeguros: [
            {
              codigo: 3,
              descricao: 'Endosso Genérico',
            },
            {
              codigo: 0,
              descricao: 'Seguro Novo',
            },
            {
              codigo: 6,
              descricao: 'Renovação Congênere',
            },
          ],
          tipoTelefones: [
            {
              codigo: 1,
              descricao: 'Celular',
            },
            {
              codigo: 2,
              descricao: 'Residencial',
            },
            {
              codigo: 3,
              descricao: 'Comercial',
            },
            {
              codigo: 4,
              descricao: 'Recado',
            },
          ],
          bancos: [
            {
              codigo: 1,
              descricao: 'Itau',
            },
            {
              codigo: 2,
              descricao: 'Bradesco',
            },
            {
              codigo: 3,
              descricao: 'Nubank',
            },
            {
              codigo: 4,
              descricao: 'Inter',
            },
          ],
          bandeirasCartoes: [
            {
              codigo: 1,
              descricao: 'Visa',
            },
            {
              codigo: 2,
              descricao: 'Mastercard',
            },
            {
              codigo: 3,
              descricao: 'Diners',
            },
          ],
          faixaRendaMensal: [
            {
              codigo: 1,
              nome: 'A',
              valorInicial: 100,
              valorFinal: 1000,
              flagSituacao: 'asdf',
            },
            {
              codigo: 2,
              nome: 'B',
              valorInicial: 100,
              valorFinal: 1000,
              flagSituacao: 'asdf',
            },
            {
              codigo: 3,
              nome: 'C',
              valorInicial: 100,
              valorFinal: 1000,
              flagSituacao: 'asdf',
            },
          ],
          ocupacao: [
            {
              numeroOcupacao: 1,
              nomeOcupacao: 'minerador',
            },
            {
              numeroOcupacao: 2,
              nomeOcupacao: 'dev',
            },
          ],
          nacionalidade: [
            { codigo: 1, descricao: 'francês' },
            { codigo: 2, descricao: 'brasileiro' },
            { codigo: 3, descricao: 'alemão' },
            { codigo: 4, descricao: 'italiano' },
          ],
        },
      })
    )
  );
};
