import { DefaultSwitchProps, SwitchWeb } from '@workspace/frontend/shared/presentation/components';
import * as React from 'react';
import { FormControllerFieldConnector } from '@web/form';

export const PreviousBonusField = ({ onChange }: { onChange: (e) => void }) => (
  <FormControllerFieldConnector<DefaultSwitchProps>
    component={SwitchWeb}
    name="previousBonus"
    onChange={onChange}
    componentProps={{
      label: 'Você possui bônus do seu seguro anterior?',
      id: 'chkBonusClass_screenCarInformation',
    }}
    translator={{ value: 'checked' }}
  />
);
