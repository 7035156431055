import { BoxWeb, ModalWeb } from '@workspace/frontend/shared/presentation/components';
import { recoveryPasswordFactory } from '../../../../factories/recovery-password';
import { useState } from 'react';
import { ModalContainer } from './style';
import { SendMailScreen } from './send-mail-screen';
import { EmailSentScreen } from './email-sent-screen';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { RecaptchaActions } from '../../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../../providers/auth-product-provider';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';

export type ForgotPasswordModalProps = {
  open: boolean;
  onClose(): void;
};

export const ForgotPasswordContainer = ({ open, onClose }: ForgotPasswordModalProps) => {
  const {
    environment: { recaptchaSiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();

  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.CHANGE_PASSWORD_EMAIL;

  const handleSubmitError = console.error;

  const recoveryPasswordUsecase = recoveryPasswordFactory();

  const handleSubmit = async ({ email }) => {
    analyticsAuth.SEND();

    setEmail(email);
    setLoading(true);

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.RECOVER_PASSWORD, recaptchaSiteKey);

      await recoveryPasswordUsecase.execute(email, {
        recaptcha: JSON.stringify(recaptcha),
      });
    } catch (error) {
      handleSubmitError(error);
    }

    setLoading(false);
    setEmailSent(true);
  };

  const handleBack = () => {
    analyticsAuth.BACK();
    onClose();
  };

  const handleClose = () => {
    setEmailSent(false);
    onClose();
  };

  return (
    <ModalWeb open={open}>
      <ModalContainer>
        <BoxWeb
          padding={{ xs: '20px', sm: '40px' }}
          width={{ xs: '100%', md: 'auto' }}
          marginTop={{ xs: '2rem', md: 0 }}
        >
          {!emailSent ? (
            <SendMailScreen
              loading={loading}
              handleSubmit={handleSubmit}
              handleBack={handleBack}
              onClose={handleClose}
            />
          ) : (
            <EmailSentScreen email={email} onClose={handleClose} />
          )}
        </BoxWeb>
      </ModalContainer>
    </ModalWeb>
  );
};
