import styled from 'styled-components';

export const ContentWrapper = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-align: left;

  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 25px;

  @media screen and (min-width: 768px) {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
`;

export const HeaderButton = styled.button`
  width: 100%;
  height: 45px;
  color: white;
  padding: 0 16px 0 calc(16px + 1.5rem);
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ec7000;
  font-weight: bold;

  @media screen and (min-width: 768px) {
    padding: 0 40px 0 calc(40px + 1.5rem);
  }
`;

export const HeaderListItem = styled.li`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0 0;
  list-style: none;
`;

export const List = styled.ul`
  padding: 0;
`;

export const ListItem = styled.li`
  list-style: none;
`;

export const ListItemLink = styled.button<any>`
  background-color: #faf7f5;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-bottom: 1px solid #d6d0cc;
  padding: 0 16px 0 calc(16px + 1.5rem);
  text-decoration: none;
  color: rgb(37, 34, 32);

  @media screen and (min-width: 768px) {
    padding: 0 40px 0 calc(40px + 1.5rem);
  }
`;

export const ListItemLabel = styled.span``;
export const ListItemIcon = styled.div`
  font-size: 24px;
  margin-right: 15px;
`;
