import { produce } from 'immer';

export const offerTypes = {
  setQuotation: 'SET [offer] [quotation]',
  setSelectedOffer: 'SET [offer] [selectedOffer]',
};

export const offerHandlers = {
  [offerTypes.setQuotation]: produce((draft, _action) => {
    draft.offer.simulation = _action.data;
  }),
  [offerTypes.setSelectedOffer]: produce((draft, _action) => {
    draft.offer.selectedOffer = _action.data;
  }),
};
