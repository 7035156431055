import { User } from '../../domain/entities/auth/user';
import { RegisterUserResponse } from '../../domain/entities/auth/response/user';
import { IRegisterUserUsecase } from '../../domain/usecases/register-user-usecase';
import { IRegisterUserRepository } from '../contracts/auth/register-user-repository';

export class RegisterUserUsecase implements IRegisterUserUsecase {
  constructor(
    private readonly registerUserRepository: IRegisterUserRepository,
    private readonly onError: (error) => void
  ) {}

  async execute(userParams: User, headers?: any): Promise<RegisterUserResponse['data']> {
    try {
      const { data } = await this.registerUserRepository.execute(userParams, headers);
      return data;
    } catch (e) {
      this.onError({ ...e });
    }
  }
}
