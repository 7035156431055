import { ManufactureYearField } from '../../../../components/autoFields/ManufactureYearField';
import { ModelYearField } from '../../../../components/autoFields/ModelYearField';
import React from 'react';
import { PlateFoundBrandAndModelField } from '../../../../components/autoFields/PlateFoundBrandAndModelField';
import { BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { BrandAndModelFieldBox, SelectFieldBox } from './vehicle-data-step.style';
import { useFormControllerMethods } from '@web/form';

export function RulePlateFoundSection({ itemsBrandAndModel, onClick }) {
  const { watch } = useFormControllerMethods();

  const fabricationYear = Number(watch('fabricationYear'));
  const isZeroKm = watch('zeroKm');
  const modelYear = watch('modelYear');

  const optionFactory = (value) => ({ key: value, value });
  const currentYear = new Date().getFullYear();

  const modelYearOptions = isZeroKm
    ? [optionFactory(fabricationYear), optionFactory(fabricationYear + 1)]
    : [optionFactory(modelYear)];

  const fabricationYearOptions = isZeroKm
    ? [optionFactory(currentYear), optionFactory(currentYear - 1), optionFactory(currentYear - 2)]
    : [optionFactory(fabricationYear)];

  return (
    <BoxWeb display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
      <SelectFieldBox>
        <ManufactureYearField disabled items={fabricationYearOptions} />
      </SelectFieldBox>

      <SelectFieldBox>
        <ModelYearField disabled items={modelYearOptions} />
      </SelectFieldBox>

      <BrandAndModelFieldBox>
        <PlateFoundBrandAndModelField items={itemsBrandAndModel} />
      </BrandAndModelFieldBox>
      <BoxWeb mt={{ xs: '1rem', lg: 0 }} width={{ xs: '100%', md: '100px' }}>
        <ButtonWeb label={'editar'} onClick={onClick} />
      </BoxWeb>
    </BoxWeb>
  );
}
