import { EnvironmentContextProps } from '@workspace/providers';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { Eligibility } from '@core/domain/entities/autov2/eligibility';
import { EligibilityResponse } from '@core/domain/entities/autov2/response/eligibility-response';
import { EligibilityRepository } from '@core/infra/repository/autov2/eligibility-repository';
import { ElegibilityUsecase } from '@core/data/services/elegibility-usecase';

export type EligibilityFactoryProps = {
  onError?: (error) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

export function eligibilityFactory({ partialEnvironment }: EligibilityFactoryProps) {
  const { environment } = partialEnvironment;
  //ex:  https://mktseguros-api.hom.cloud.itau.com.br/v1/mkt-seguros-e2e/elegibilidade

  // gatewayAWSBaseURL: {
  //   url: 'https://mktseguros-api.dev.cloud.itau.com.br',
  // },
  const httpPostClient: IHttpPostClient<Eligibility, EligibilityResponse> = new AxiosHttpPostClient(
    `/v1/mkt-seguros-e2e/elegibilidade`,
    {},
    environment.gatewayAWSBaseURL.url
  );
  const eligibilityRepository = new EligibilityRepository(httpPostClient);
  const eligibilityUsecase = new ElegibilityUsecase(eligibilityRepository);

  return eligibilityUsecase;
}
