import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { BoxProps } from '@material-ui/core';
import { HeroItemStyled, StyledPaper, PostCategory, ClickableArea } from './PostCard-style';
import { HomePostTitle } from '../..';

export interface PostCardProps extends BoxProps {
  image: string;
  category: string;
  title: string;
  focusOnLoad?: boolean;
  onClickPost: MouseEventHandler<HTMLButtonElement>;
}
export const PostCard = ({ image, title, category, focusOnLoad = false, onClickPost, ...props }: PostCardProps) => {
  const itemRef = useRef(null);
  useEffect(() => {
    if (focusOnLoad && !!itemRef.current) {
      itemRef.current?.focus();
    }
  }, [itemRef]);
  // quando houver a página de categoria:
  // - remover aria-hidden de PostCategory
  // - adicionar aria-label={`categoria ${category}`}
  // - alterar base do componente 'PostCategory' de Typography para Link
  // - remover o aria-label do PostTitle

  return (
    <HeroItemStyled image={image} data-testid="post-card" {...props} role="link">
      <ClickableArea
        onClick={onClickPost}
        data-testid="btn-post-card"
        ref={itemRef}
        aria-label={`Categoria: ${category}. ${title}`}
        role="heading"
        aria-level={2}
      >
        <StyledPaper aria-hidden>
          <PostCategory ariaLabel={`Categoria: ${category}.`}>{category}</PostCategory>
          <HomePostTitle component="h2" ariaLabel={`${title}`}>
            {title}
          </HomePostTitle>
        </StyledPaper>
      </ClickableArea>
    </HeroItemStyled>
  );
};
