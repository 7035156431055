import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { residOfferQuestions } from './resid-offer-questions-mock';
import { residOffer } from './resid-offer-mock';
import { residSimulation } from './resid-simulation-mock';
import { residSimulationAssist } from './resid-simulation-assist-mock';
import { residSuspendedLists } from './suspended-list-mock';
import { residHiring } from './resid-hiring';
import { residGetHiring } from './resid-get-hiring-mock';

export const residMockHandlers = (env): RestHandler[] => {
  return [
    residOfferQuestions(env),
    residOffer(env),
    residSimulation(env),
    residSimulationAssist(env),
    residSuspendedLists(env),
    residHiring(env),
    residGetHiring(env),
  ];
};

//ITAU_GATEWAY_HOST = https://mktseguros-api.dev.cloud.itau.com.br
//E2E_PATH = /v1/mkt-seguros-e2e
