import { SessionStorageBaseRepository } from './session-storage-base-repository';
import { IdentifierRepositoryContract } from '@workspace/frontend/shared/domain/contracts';

class IdentifierRepository extends SessionStorageBaseRepository implements IdentifierRepositoryContract {
  constructor() {
    super('identifier');
  }
}

export { IdentifierRepository };
