export const typeGenerator = (parent: string | string[], actionType: string, propName: string) => {
  let parentGenerator = '';
  if (Array.isArray(parent)) {
    parentGenerator = parent.join('] [');
  } else {
    parentGenerator = parent;
  }
  const typemaper = actionType ? actionType + ' ' : '';
  return `${typemaper}[${parentGenerator}] ${propName}`;
};
