import { DatePropos } from '../PostData/PostData';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TextWrapper, CardContainer, ButtonWrapper } from './style';
import { BoxWeb, TypographyWeb, OutlinedButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { BlogImageProps, PostData, PostImage } from '..';
import { phraseToUpperCamelCase } from '../../utils';
import { ANALYTICS_BLOG } from '@workspace/web/analytics';

interface PostCardProps {
  href: string;
  image: BlogImageProps;
  category: any;
  id?: string;
  title: string;
  description: string;
  date: DatePropos;
  readingTime: string;
  index: number;
}

export function PostCard({ href, image, category, title, description, date, readingTime, index }: PostCardProps) {
  const history = useHistory();
  const onClickPost = () => {
    history.push(href);
    ANALYTICS_BLOG.HOME.READ_POST_2([phraseToUpperCamelCase(title)]);
  };

  return (
    <CardContainer>
      <PostImage {...image} />
      <TextWrapper>
        {typeof category === 'string' ? (
          <TypographyWeb color="textSecondary" tabIndex={-1} className="category">
            {category}
          </TypographyWeb>
        ) : (
          category.map((element, i) => {
            return (
              <TypographyWeb key={i} color="inherit" className="category">
                {element.title}
              </TypographyWeb>
            );
          })
        )}
        <TypographyWeb component="h3" id={`lblPostTitle${index}_screenHome`}>
          {title}
        </TypographyWeb>
        <TypographyWeb color="textPrimary">{description}</TypographyWeb>
        <BoxWeb
          my={3}
          role="text"
          aria-label={`Data de publicação ${date.dateAcessibility}. Tempo de leitura ${readingTime}`}
        >
          <PostData date={date} readingTime={readingTime} />
        </BoxWeb>
        <ButtonWrapper>
          <OutlinedButtonWeb
            id={`btnLerPostagem${index}_screenHome`}
            aria-label={`Ler postagem: ${title}`}
            className="outlineButtonWeb"
            color="inherit"
            label="ler postagem"
            onClick={onClickPost}
          />
        </ButtonWrapper>
      </TextWrapper>
    </CardContainer>
  );
}
