import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';

import { GetFaqTopicUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';
import { GetFAQTopicRepository } from '@workspace/frontend/faq-v2/infra/repositories';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { FAQTopicResponse } from '@workspace/frontend/faq-v2/domain/entities';

export function getFAQTopicFactory() {
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQTopicResponse> = new AxiosHttpGetClient(
    `/content_types/faq_topico/entries?environment=${environment?.cmsBase?.environment}&asc=posicao`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQTopicRepository = new GetFAQTopicRepository(httpGetClient);
  return new GetFaqTopicUseCase(getFAQTopicRepository);
}
