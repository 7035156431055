import { rest } from 'msw';

export const cancellationReasonsMock = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/cancelamento/motivos`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          id_motivo_cancelamento: 1,
          descricao: 'Não vi valor no produto',
        },
        {
          id_motivo_cancelamento: 2,
          descricao: 'Não consigo mais pagar esse valor',
        },
        {
          id_motivo_cancelamento: 3,
          descricao: 'A rede credenciada não me atende',
        },
        {
          id_motivo_cancelamento: 4,
          descricao: 'Portabilidade: estou mudando de operadora',
        },
        {
          id_motivo_cancelamento: 5,
          descricao: 'Outros',
        },
      ])
    );
  });
};
