import { useEffect, useState } from 'react';
import { getAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { ItauIcon } from '@workspace/frontend/shared/presentation/components';
import { ContentWrapper, HeaderButton, HeaderListItem } from './mobile-style';
import { AuthStateManager } from '@web/state-management';
import { RouteEnum } from '../../../enums/route-enum';
import OptionsList from './options-list';
import { useAccessButton } from '../../../providers/access-button-provider';

type MobileContainerProps = {
  listItem?: boolean;
};

enum ArrowIcon {
  UP = 'seta_up',
  DOWN = 'seta_down',
}

const MobileContainerComponent = (props: MobileContainerProps) => {
  const { isAuthenticated, name } = AuthStateManager.userSelector();
  const { options } = useAccessButton();

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = () => setExpanded((oldValue) => !oldValue);

  const handleLogin = () => {
    if (!isAuthenticated) {
      return window.location.assign(RouteEnum.LOGIN);
    }

    toggleExpand();
  };

  const headerButtonA11yProps = () =>
    isAuthenticated
      ? {
          'aria-expanded': expanded,
          'aria-label': `Olá, ${name}`,
          'aria-live': 'assertive' as const,
          'aria-controls': 'dropDownList-menu-item-acesso',
          role: 'listbox',
        }
      : {};

  useEffect(() => {
    const auth = getAuthentication();
    if (auth?.accessToken) AuthStateManager.userAuthenticatedDispatcher(true);
  }, []);

  return (
    <ContentWrapper>
      <HeaderButton
        onClick={handleLogin}
        id="dropDownSelected-menu-item-acesso"
        aria-controls="dropDownList-menu-item-acesso"
        {...headerButtonA11yProps()}
      >
        {!isAuthenticated ? 'entre ou crie uma conta' : `Olá, ${name}`}

        {isAuthenticated && (
          <ItauIcon
            ariaHidden
            iconName={expanded ? ArrowIcon.UP : ArrowIcon.DOWN}
            style={{ fontSize: '24px', marginRight: '0.5rem' }}
          />
        )}
      </HeaderButton>

      {isAuthenticated && expanded && <OptionsList id="dropDownList-menu-item-acesso" listItems={options} />}
    </ContentWrapper>
  );
};

export default function MobileContainer(props: MobileContainerProps) {
  return props.listItem ? (
    <HeaderListItem>
      <MobileContainerComponent {...props} />
    </HeaderListItem>
  ) : (
    <MobileContainerComponent {...props} />
  );
}
