import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';
import { RadioGroupWeb } from '@workspace/frontend/shared/presentation/components';
import { FeedBackIcons } from '@web/assets';

const SATISFACTION_OPTIONS = [
  {
    value: '1',
    label: <label aria-hidden={true}>ruim</label>,
    ariaLabel: 'ruim',
    icon: <FeedBackIcons type="bad" />,
    checkedIcon: <FeedBackIcons type="bad" checked />,
  },
  {
    value: '2',
    label: <label aria-hidden={true}>não gostei</label>,
    ariaLabel: 'não gostei',
    icon: <FeedBackIcons type="didntLike" />,
    checkedIcon: <FeedBackIcons type="didntLike" checked />,
  },
  {
    value: '3',
    label: <label aria-hidden={true}>sei lá</label>,
    ariaLabel: 'sei lá',
    icon: <FeedBackIcons type="beatsMe" />,
    checkedIcon: <FeedBackIcons type="beatsMe" checked />,
  },
  {
    value: '4',
    label: <label aria-hidden={true}>gostei</label>,
    ariaLabel: 'gostei',
    icon: <FeedBackIcons type="liked" />,
    checkedIcon: <FeedBackIcons type="liked" checked />,
  },
  {
    value: '5',
    label: <label aria-hidden={true}>adorei</label>,
    ariaLabel: 'adorei',
    icon: <FeedBackIcons type="loved" />,
    checkedIcon: <FeedBackIcons type="loved" checked />,
  },
];

export const SatisfactionLevelField = ({ options = SATISFACTION_OPTIONS, context, ...restProps }: any) => (
  <FormControllerFieldConnector
    component={RadioGroupWeb}
    name="satisfactionLevel"
    validation={stringValidationFactory({})}
    translator={{ value: 'checked' }}
    componentProps={{ options, ...restProps }}
    context={context}
  />
);
