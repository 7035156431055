import { useFormControllerMethods } from '@web/form';
import { LeadDialog } from './auto-quotation-lead-dialog';

export function LeadDialogFormConnectorWrapper({
  onClose,
  open,
  sendLeadUsecase,
}: {
  open: boolean;
  onClose: () => void;
  sendLeadUsecase: any;
}) {
  const { getValues } = useFormControllerMethods();
  const cpfValue = getValues('cpf');
  const nameValue = getValues('name');
  const cellphoneValue = getValues('cellphone');

  return (
    <LeadDialog
      open={open}
      onClose={onClose}
      initialValues={{ cpf: cpfValue, name: nameValue, cellphone: cellphoneValue }}
      sendLeadUsecase={sendLeadUsecase}
    />
  );
}
