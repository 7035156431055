import { AuthenticationAppServiceFactory } from '@workspace/frontend/shared/infra/factories';

const authenticationAppServiceFactory = AuthenticationAppServiceFactory.instance;

interface Authentication {
  accessToken: string;
  refreshToken: string;
  identifier: string;
}

export const getAuthentication = (): Authentication => JSON.parse(authenticationAppServiceFactory.get());
export const setAuthentication = (authenticationTokens: Authentication) =>
  authenticationAppServiceFactory.create(JSON.stringify(authenticationTokens));

export const removeAuthentication = () => authenticationAppServiceFactory.remove();
