import { RegisterContainer } from './register';
import { useEffect, useState } from 'react';
import { AuthStateManager } from '@workspace/frontend-shared-application-state-manager';
import { Loading } from '../../components/loading/loading';

export const RegisterBase = () => {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const clearRegistration = () => AuthStateManager.clearStore();

    setTimeout(() => setLoading(false), 500);

    window.addEventListener('unload', clearRegistration);
    return () => window.removeEventListener('unload', clearRegistration);
  }, []);

  return loading ? <Loading /> : <RegisterContainer />;
};
