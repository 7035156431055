import { useThemeProvider } from '../theme-provider/theme-provider';
import { AlertAuthenticationProps } from '../../models/alert-authentication';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useEffect, useState } from 'react';
import { ItauIcon } from '../../infra/common/itau-icon';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

const alertWrapperStyle: CSSProperties = {
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '4px',
};

export const AlertSelfControlledWeb = ({
  children,
  show,
  severity,
  secondsToClose,
  icon = <ItauIcon iconName="exclamacao" ariaHidden={true} />,
  onClose,
  backgroundColorsMapping = {
    error: '#B71C1C',
    warning: '#781B8E',
  },
}: AlertAuthenticationProps) => {
  const {
    Components: { Alert },
  } = useThemeProvider();

  const [visible, setVisible] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>();

  useEffect(() => {
    if (severity && backgroundColorsMapping) {
      const backgroundStatusColors = ['error', 'warning'];

      const [color] = backgroundStatusColors.filter((statusColor) => statusColor === severity);
      const colorMapped = backgroundColorsMapping[color];

      setBackgroundColor(colorMapped);
    }
  }, [severity, backgroundColorsMapping]);

  useEffect(() => {
    setVisible(show);

    const hideTimeout = secondsToClose ? setTimeout(() => setVisible(false), secondsToClose * 1000) : undefined;

    return () => {
      hideTimeout && clearTimeout(hideTimeout);
    };
  }, [show, secondsToClose]);

  return (
    visible && (
      <ThemeProviderInjector>
        <div style={{ ...alertWrapperStyle, backgroundColor }}>
          <Alert severity={severity} onClose={onClose} icon={icon}>
            {children}
          </Alert>
        </div>
      </ThemeProviderInjector>
    )
  );
};

export default AlertSelfControlledWeb;
