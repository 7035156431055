export const formatPriceNumber = (value) => {
  if (isInteger(value)) {
    return `${formatToString(value)},00`;
  }
  return formatToString(value);
};

const formatToString = (value: string) => new Intl.NumberFormat('pt-BR').format(parseFloat(value));

const isInteger = (value: number) => value % 1 === 0;

export const removeEspecialCharacter = (value: string) => value.replace(/[^a-z0-9]/gi, '');
