import { BoxWeb, InfoBoxWeb } from '@workspace/frontend/shared/presentation/components';
import { CarBrandModelView } from '@workspace/frontend/auto/presentation/components';
import { AutoStepQuotation } from './auto-step-quotation';
import styled from 'styled-components';
import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { useStepProvider } from '../../../../providers/step-provider';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { SimulationResponseDTO, SimulationType } from '../../../../adapter/simulation-response';
import { NoOffersPage } from './no-offers-page';
import React, { useEffect, useState } from 'react';
import { EnvironmentContextProps, useEnvironment, useRemoteConfigConsumer } from '@workspace/providers';
import { InstallmentsQueryResponseAdapter } from '@workspace/frontend/auto/application/adapters';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { NetworkImages } from '../../../../enums/networkImages';
import { BoxSteps } from '../../hiring-utils';
import { sendLeadFactory } from '../../../../factories/send-lead-factory';
import { Company } from '@core/domain/enums/company';
import { Coverage } from '../../../../enums/coverage';
import { useOriginContext } from '../../../../providers/origin-provider';
import { installmentsQueryFactory } from '@workspace/frontend-auto-application-factories';
import {
  CreateCartAppServiceFactory,
  FinalizeCartAppServiceFactory,
} from '@workspace/frontend/shared/abandoned-cart/infra/factories';
import { ContextsEnum } from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { useHistory } from 'react-router-dom';
import { LoadingError } from '../../../../components/loading-error/loading-error';
import { CreateCartAutoAppServiceContractInput } from '@workspace/frontend/shared/abandoned-cart/application/contracts';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    max-width: 744px;
  }
`;

enum QuotationStatusCode {
  SUCCESS = 2,
  NO_OFFERS = 4,
}

function QuotationStepContainer() {
  const stepProvider = useStepProvider();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const { environment } = partialEnvironment;
  const simulationResponse: SimulationResponseDTO = AutoStateManager.quotationStepApiSelector();
  const { selectedVehicle, cpf, name, cellphone, contact } = AutoStateManager.quotationStepSelector();
  const enableAutov2Hiring = partialEnvironment.environment.enableAutov2Hiring;
  const enableCampanhaAuto = partialEnvironment.environment.enableCampanhaAuto;
  const history = useHistory();
  const config = useRemoteConfigConsumer();

  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const analyticsQuotation = autoV2analyticsCalls.HIRING.QUOTATION;

  const installmentsQueryUseCase = installmentsQueryFactory({ partialEnvironment });
  const networkStyleService = networkStyleFactory(partialEnvironment);
  const [carImagePath, setCarImagePath] = useState<string>();
  const [peopleImagePath, setPeopleImagePath] = useState<string>();
  const [helpImagePath, setHelpImagePath] = useState<string>();
  const [leadChannel, setLeadChannel] = useState<string>();
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [sendLeadHasThrowedError, setSendLeadHasThrowedError] = useState<boolean>(false);

  const initImages = async () => {
    const cachedNetworkStyle: NetworkStyle = await networkStyleService.execute();
    const carImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.CAR);
    const peopleImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.PEOPLE);
    const helpImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.HELP);
    setCarImagePath(carImage?.file?.url);
    setPeopleImagePath(peopleImage?.file?.url);
    setHelpImagePath(helpImage?.file?.url);
  };
  useEffect(() => {
    initImages();
    contact && createCart();

    analyticsQuotation.LOAD();

    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name }: { channel_name: string } = await networkStyleUsecase.execute();
      setLeadChannel(channel_name);
      setIsBusy(false);
    })();
  }, []);

  const createCart = async () => {
    if (simulationResponse.quotationStatus.code.toString() === '2') {
      const createCartAppService = CreateCartAppServiceFactory.execute();

      const dac = Number(await getMemoryItem('dac'));
      const segment = await getMemoryItem('segmento');
      

      const input = new CreateCartAutoAppServiceContractInput(
        cpf,
        name,
        cellphone,
        simulationResponse.simulationNumber,
        dac || undefined,
        segment || undefined
      );
      console.log('--- gerou carrinho ---', input);

      createCartAppService.execute(input);
    } else {
      console.log('--- não gerou carrinho ---');
    }
    console.log('quotationStatus: ', simulationResponse.quotationStatus.code.toString());
  };

  const getPortoOffers = (offers: SimulationType[]): SimulationType[] =>
    offers.filter((offer) => offer.company === Company.PORTO);
  const getAzulOffers = (offers: SimulationType[]): SimulationType[] =>
    offers.filter((offer) => offer.company === Company.AZUL);
  const getSelectedCompanyByIndexTab = (betterPrice) => (betterPrice ? Company.AZUL : Company.PORTO);
  const getOfferByFranchise = (offers: SimulationType[], franchiseCode: number): SimulationType => {
    return offers.find((simulation) => {
      const coverageFranchiseIndex = simulation.offers.coverages.find((item) => {
        return item.code === Coverage.WITH_FRANCHISE_VALUE;
      });
      return coverageFranchiseIndex?.franchise[0].code === franchiseCode;
    });
  };

  const selectOffer = (franchise, betterPrice) => {
    const selectedCompany: number = getSelectedCompanyByIndexTab(betterPrice);
    const selectedCompanyOffers =
      selectedCompany === Company.PORTO
        ? getPortoOffers(simulationResponse.offers)
        : getAzulOffers(simulationResponse.offers);
    const selectedOffer = getOfferByFranchise(selectedCompanyOffers, franchise);
    AutoStateManager.selectedOfferDispatcher(selectedOffer);
  };

  const getMemoryItem = async(key: string): Promise<string> => {
    const sdkObject = (window as any).native;
    return (await sdkObject?.getMemoryItem(key)) || '';
  }

  const handleChangeTab = analyticsQuotation.TAB;

  const handleToggleAccordion = analyticsQuotation.ACCORDION;

  const redoQuotation = () => {
    AutoStateManager.itemRiskResetDispatcher();
    stepProvider.setActiveStep(1);
    analyticsQuotation.REDO();
  };

  const handleBack = () => {
    stepProvider.handleBack();
  };

  const deleteCart = () => {
    const finalizeCartAppService = FinalizeCartAppServiceFactory.execute();
    finalizeCartAppService.execute({
      context: ContextsEnum.AUTO,
      cpf,
    });
  };

  const handleContinue = async (franchise: 50 | 100, betterPrice: boolean) => {
    analyticsQuotation.CONTINUE(franchise, betterPrice);

    if (enableAutov2Hiring) {
      installmentsQueryUseCase.execute({ numeroCotacao: 1, codigo: 1 }).then((value) => {
        AutoStateManager.quotationStepDispatcher(InstallmentsQueryResponseAdapter(value));
      });
      selectOffer(franchise === 100, betterPrice);
      stepProvider.handleNext();
    } else {
      try {
        setIsBusy(true);

        const response: boolean = await sendLeadUsecase.execute({
          simulationNumber: simulationResponse.simulationNumber,
          email: 'email@email.com',
          cellphone,
          name: name,
          cpf: cpf,
        });

        deleteCart();

        if (response) { 
          setIsBusy(false);
          const prefix = window.location.pathname.replace(/\/contratacao.*/, '');
          if (window.location.pathname.includes('webview')) {
            return window.parent.dispatchEvent(new CustomEvent('loja:mfe:auto:success:superapp'));
          }
          history.push(`${prefix}/sucesso?lead=true`);
        }
      } catch (e) {
        setIsBusy(false);
        setSendLeadHasThrowedError(true);
      }
    }
  };

  if (simulationResponse?.quotationStatus?.code === QuotationStatusCode.NO_OFFERS)
    return <NoOffersPage description={simulationResponse?.quotationStatus?.description} action={handleBack} />;

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });

  if (sendLeadHasThrowedError) {
    const onRetry = () => {
      setSendLeadHasThrowedError(false);
      handleContinue(100, false);
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const OnLoad = () => {};
    const onBackErrorPage = () => {
      setSendLeadHasThrowedError(false);
    };

    return (
      <LoadingError
        errorTitle={'Houve algum problema, tente novamente :('}
        activeLeadError={false}
        onLoad={OnLoad}
        onRetry={onRetry}
        onBack={onBackErrorPage}
        sendLeadUsecase={sendLeadUsecase}
      />
    );
  }

  return (
    <BoxSteps>
      <Wrapper>
        <BoxWeb display={['flex', 'block']} flexDirection={'column'} alignItems={'center'}>
          <BoxWeb
            component={'h1'}
            aria-label="cotação do seu seguro"
            id="lblQuotation_screenQuotation"
            mb={{ xs: 4, md: 7 }}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            <span aria-hidden>
              <strong>cotação </strong>do seu seguro
            </span>
          </BoxWeb>
        </BoxWeb>

        <CarBrandModelView text={selectedVehicle} imagePath={carImagePath} />
        <BoxWeb width={{ xs: '100%', md: '500px' }} my={'2rem'}>
          <InfoBoxWeb
            text={'com base nas suas informações, trouxemos um plano configurado para o seu perfil'}
            imgSrc={helpImagePath}
            bgcolor={'#fff'}
            border={'1px solid'}
          />
        </BoxWeb>
        <AutoStepQuotation
          enableAutov2Hiring={enableAutov2Hiring}
          redoQuotation={redoQuotation}
          onBack={handleBack}
          onChangeTab={handleChangeTab}
          onToggleAccordion={handleToggleAccordion}
          onContinue={handleContinue}
          insuranceOffers={simulationResponse}
          peopleImagePath={peopleImagePath}
          sendLeadUsecase={sendLeadUsecase}
          initialValues={{ cpf, name, cellphone }}
          isBusy={isBusy}
          config={config}
          enableCampanhaAuto={enableCampanhaAuto}
        />
      </Wrapper>
    </BoxSteps>
  );
}

export function StepFourQuotation() {
  return <QuotationStepContainer />;
}

