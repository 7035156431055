import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { MyAccountRoutesEnum } from '../enums/my-account-routes-enum';
import { MyAccountContainer } from './my-account-container/my-account-container';
import { MyProductsCarrier } from './my-products-container/my-products-carrier';

export function MyAccountBase() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from={MyAccountRoutesEnum.BASE} to={MyAccountRoutesEnum.MY_ACCOUNT} />
        <Route exact path={MyAccountRoutesEnum.MY_ACCOUNT} component={MyAccountContainer} />
        <Route exact path={MyAccountRoutesEnum.MY_PRODUCTS} component={MyProductsCarrier} />
      </Switch>
    </BrowserRouter>
  );
}
