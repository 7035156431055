import { useEffect, useRef, useState } from 'react';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { AlertWeb, BoxWeb, ModalWeb, NavigateButtonsWeb } from '@workspace/frontend/shared/presentation/components';
import { FormController, FormControllerErrorBanner } from '@web/form';
import { CellphoneField, CpfField, NameField } from '@web/form/component';
import styled from 'styled-components';
import { useOriginContext } from '../../providers/origin-provider';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { FinalizeCartAppServiceFactory } from '@workspace/frontend/shared/abandoned-cart/infra/factories';
import { ContextsEnum } from '@workspace/frontend/shared/abandoned-cart/application/enums';
import { useHistory } from 'react-router-dom';
import { StepProviderProps, useStepProvider } from '../../providers/step-provider';

type QuotationLeadDialogProps = {
  open: boolean;
  onClose: any;
  simulationNumber?: number;
  initialValues?: { cpf?: string; name?: string; cellphone?: string };
  sendLeadUsecase?: any;
};

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  line-height: 24px;
`;
const ModalTitleContainer = styled.div`
  padding: 16px 24px;
`;
const ModalContent = styled.div`
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ModalActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    justify-content: space-around;
  }
`;

export const LeadDialog = ({
  open,
  onClose,
  simulationNumber,
  initialValues = {},
  sendLeadUsecase,
}: QuotationLeadDialogProps) => {
  const modalTitleRef = useRef(null);
  const [error, setError] = useState<boolean>(false);
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const partialEnviroment: Partial<EnvironmentContextProps> = useEnvironment();
  const { environment } = partialEnviroment;
  const history = useHistory();
  const stepProvider: StepProviderProps = useStepProvider();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        modalTitleRef.current.focus();
      }, 500);
    }
  }, [open]);

  const [loading, setLoading] = useState<boolean>(false);

  type FormFieldProps = {
    cellphone: string;
    lead_name: string;
    lead_cpf: string;
    selectedOffer: string;
  };

  const handleSubmit = async ({ cellphone, lead_name, lead_cpf }: FormFieldProps): Promise<void> => {
    setLoading(true);
    try {
      if (stepProvider.activeStep > 0) {
        const finalizeCartAppService = FinalizeCartAppServiceFactory.execute();
        finalizeCartAppService.execute({
          context: ContextsEnum.AUTO,
          cpf: lead_cpf,
        });
      }

      const response: boolean = await sendLeadUsecase.execute({
        simulationNumber,
        email: 'email@email.com',
        cellphone,
        name: lead_name,
        cpf: lead_cpf,
      });

      setLoading(false);
      if (response) {
        autoV2analyticsCalls.HIRING.QUOTATION.MODAL.CONTINUE();
        const prefix = window.location.pathname.replace(/\/contratacao.*/, '');
        history.push(`${prefix}/sucesso?lead=true`);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <ModalWeb open={open}>
      <FormController
        onSubmit={handleSubmit}
        hideErrors
        id="leadForm"
        initialValues={{
          lead_cpf: initialValues.cpf,
          lead_name: initialValues.name,
          cellphone: initialValues.cellphone,
        }}
      >
        <ModalTitleContainer>
          <ModalTitle ref={modalTitleRef} tabIndex={-1}>
            deixe seus dados para nosso time entrar em contato com você
          </ModalTitle>
        </ModalTitleContainer>
        <ModalContent>
          <FormControllerErrorBanner />
          <BoxWeb width={'100%'} mb={3}>
            <CpfField
              prefix="lead"
              componentProps={{
                id: 'txtLeadsCpf_screenAuto',
              }}
            />
          </BoxWeb>
          <BoxWeb width={'100%'} mb={3}>
            <NameField
              prefix="lead"
              componentProps={{
                id: 'txtLeadsName_screenAuto',
              }}
            />
          </BoxWeb>
          <BoxWeb width={'100%'} mb={3}>
            <CellphoneField componentProps={{ id: 'txtLeadsCellphone_screenAuto' }} />
          </BoxWeb>
          {error && (
            <BoxWeb mb={'2rem'} maxWidth={'25rem'}>
              <AlertWeb variant="filled" severity="error">
                Houve algum problema, tente novamente :(
              </AlertWeb>
            </BoxWeb>
          )}
        </ModalContent>
        <ModalActions>
          <NavigateButtonsWeb loading={loading} backText={'fechar'} onClickBack={onClose} />
        </ModalActions>
      </FormController>
    </ModalWeb>
  );
};
