//@ts-nocheck

// Import all modules from tihs environment
// import 'firebase/performance';

const firebaseConfig = {
  apiKey: 'AIzaSyBUGkcV3ADFRORKU8KUUocbEUQEP39B-ik',
  authDomain: 'zup-itau-seguros-qa.firebaseapp.com',
  databaseURL: 'https://zup-itau-seguros-qa.firebaseio.com',
  projectId: 'zup-itau-seguros-qa',
  storageBucket: 'zup-itau-seguros-qa.appspot.com',
  messagingSenderId: '567370069477',
  appId: '1:567370069477:web:323eb5785428edc6a96b0c',
};

export const initFirebase = () => {
  let performance = null;
  let remoteConfig = null;
  let database = null;
  const analytics = null;
  let auth = null;
  // initialize modules
  performance = {
    trace: () => ({
      start: () => {},
      stop: () => {},
    }),
  };
  remoteConfig = {
    ENABLE_REDIRECT_AUTO_HIRING: true,
    AUTO_V2_ENABLED_CHANNELS:
      '[ "superappodonto", "loja", "nlseguros", "hipercard", "veiculos", "finanveiculos", "cartaonaologado", "credicard", "itaucard", "superapp", "itaunaologado", "itauuniclass", "itaupersonnalite", "consorcio", "autenticado", "maladigital", "sms", "maladigital1", "maladigital2", "connectcar", "NLcartoesseguros", "bau-flutuante", "nlsegurosflutuante", "itaucardparcerias", "creditoimobiliario", "assai", "campanhas_lps", "rede", "credicardon", "iti", "icarros", "instagram", "google", "facebook", "sms", "emkt", "superapp", "nlhomeitau", "credicardon", "avi", "whatsapp", "bankline", "itaucard-samsung"]',
    ENABLE_RESID_LOGIN: true,
    ENABLE_ODONTO_LOGIN: 'true',
    ITAU_EXCLUSIVES_BANNER: '{"enabled":true}',
    ENABLE_AUTH: 'true',
    ENABLE_AUTOV2_STYLE: 'true',
    ENABLE_FAQ_V2: 'true',
    ENABLE_MY_ACCOUNT: 'true',
    ENABLE_ODONTO_4790: 'true',
    ENABLE_PET_LOGIN: 'true',
    ENABLE_CAMPANHA_AUTO: true,
    ODONTO_HOME_CAMPAIGN: '{"enabled":true}',
    ODONTO_PRODUCT_MAINTENANCE: 'false',
    PROMOTIONINDOOR_ODONTO_NEW_PLANS: '{"enabled":true}',
    RESID_HOME_CAMPAIGN: '{"enabled": false, "discount": false}',
    fetchAndActivate: () => {},
    getAll: () => ({}),
  };

  auth = {
    signInAnonymously: () => {},
    onAuthStateChanged: (callback) => {
      callback();
    },
  };

  const obj = {};
  obj.collection = () => obj;
  obj.add = () => obj;
  obj.then = (callback) => {
    callback();
    return obj;
  };
  obj.catch = (callback) => {
    callback();
    return obj;
  };
  database = obj;
  // remoteConfig.settings = { minimumFetchIntervalMillis: 0 };
  return { performance, remoteConfig, analytics, database, auth };
};
