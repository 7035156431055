import palette from './authentication/palette';
import typography from './authentication/typography';
import breakpoints from './authentication/breakpoints';
import props from './authentication/props';
import overrides from './authentication/overrides';

const style = {
  typography,
  palette,
  breakpoints,
  shape: {},
  overrides,
  props,
};

export default style;
