import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import { autov2Images } from '@web/assets';

const lightgray = '#EDEDED';

export const CarBrandModelView = ({ text, imagePath }: { text: string, imagePath: string }) => {
  return (
    <BoxWeb borderBottom={`2px solid ${lightgray}`} minHeight={'3.75rem'} display={'flex'}>
      <BoxWeb overflow={'auto'}>{text}</BoxWeb>
      <BoxWeb flexShrink={0} width={'9.5rem'} display={['none', 'block']}>
        <img src={imagePath} alt={''} width={'100%'} aria-hidden />
      </BoxWeb>
    </BoxWeb>
  );
};
