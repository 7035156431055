import { Franchise } from '@core/domain/entities/autov2/simulation-response';
import { formatPriceNumber } from '../utils/format-price-number';
import { FranchiseType } from './simulation-response';

export class FranchiseDTO {
  static translate(franchises: Franchise[]): FranchiseType[] {
    return franchises?.map((franchise: Franchise) => ({
      code: franchise.codigo,
      price: `R$${formatPriceNumber(franchise.valorFranquia)}`,
    }));
  }
}
