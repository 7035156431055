interface UserInterface {
  cpf?: string;
  name?: string;
  email?: string;
  password?: string;
  cellphone?: string;
}

export class User {
  private _cpf: string;
  private _name?: string;
  private _email: string;
  private _password?: string;
  private _cellphone?: string;

  constructor({ cpf, name, email, password, cellphone }: UserInterface) {
    if (cpf) this.cpf = cpf;
    if (name) this.name = name;
    if (email) this.email = email;
    if (password) this.password = password;
    if (cellphone) this.cellphone = cellphone;
  }

  get cpf(): string | undefined {
    return this._cpf;
  }

  set cpf(cpf: string | undefined) {
    this._cpf = cpf;
  }
  get name(): string | undefined {
    return this._name;
  }

  set name(name: string | undefined) {
    this._name = name;
  }

  get email(): string | undefined {
    return this._email;
  }

  set email(email: string | undefined) {
    this._email = email;
  }

  get password(): string | undefined {
    return this._password;
  }

  set password(password: string | undefined) {
    this._password = password;
  }

  get cellphone(): string | undefined {
    return this._cellphone;
  }

  set cellphone(cellphone: string | undefined) {
    this._cellphone = cellphone;
  }
}

export default User;
