export const NAME_REGEX = /^[^!@#$%^&*(),.?":{}|<>0-9][a-zA-Z\u00C0-\u00FF]{2,}( [a-zA-Z'\u00C0-\u00FF]{2,})+$/;

export const NAME_ABBREVIATED_REGEX =
  /^[^!@#$%^&*(),.?":{}|<>0-9][a-zA-Z\u00C0-\u00FF]+( [a-zA-Z'\u00C0-\u00FF]+)+$/;

export const EMAIL_REGEX =
  /[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const CELLPHONE_REGEX = /^\(?[0-9]{2}\)? ?[0-9]{5}-?[0-9]{4}$/;

export const CODE_CONFIRMATION = /((?:[\d][\s]?){5}[\d])/;
