import mixedValidationFactory, { mixedValidationInterface } from './mixed-validation-factory';
import * as yup from 'yup';
import { NumberSchema } from 'yup';

export type NumberValidationSchema = NumberSchema<
  number | null | undefined,
  Record<string, number | null | undefined>,
  number | null | undefined
>;

export interface numberValidationFactoryI
  extends Omit<mixedValidationInterface<NumberValidationSchema>, 'chainableSchema'> {
  min?: {
    limit: number;
    message: string;
  };
  max?: {
    limit: number;
    message: string;
  };
}

export function numberValidationFactory({ min, max, ...mixedValidationProps }: numberValidationFactoryI) {
  let yupChain = yup.number().nullable(true);

  yupChain = mixedValidationFactory({ chainableSchema: yupChain, ...mixedValidationProps });

  if (min) {
    yupChain = yupChain.min(min.limit, min.message);
  }

  if (max) {
    yupChain = yupChain.max(max.limit, max.message);
  }

  return yupChain;
}
