import styled from 'styled-components';

const orange = (props) => props.theme.palette.primary.main;
const textColor = (props) => props.theme.palette.text.primary;
const linkColor = (props) => props.theme.palette.link.primary;

export const BreadCrumbSeparator = styled.i`
  font-size: 14px;
`;

export const SectionText = styled.section`
  display: flex;
  flex-direction: column;
  margin: 36px auto 0;
  max-width: 680px;
  padding: 0;
  width: 80%;
  outline: max(1px, 0.1em);
  outline-offset: 0.5em;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const Link = styled.a`
  color: ${linkColor};
  font-weight: bold;
  text-decoration: none;
`;

export const Article = styled.article`
  color: ${textColor};
  line-height: 34px;
  ${(props) => props.theme.breakpoints.down('md')} {
    line-height: 28px;
  }
  > h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    margin: 48px 0 24px 0;
    ${(props) => props.theme.breakpoints.down('md')} {
      margin: 24px 0 12px 0;
    }
  }
  > h3 {
    margin: 16px 0;
    ${(props) => props.theme.breakpoints.down('md')} {
      margin: 8px 0;
    }
  }
  a {
    color: ${linkColor};
    font-weight: bold;
  }
  ul,
  ol {
    margin: 24px 0;
    padding-left: 14px;
    > li {
      margin-bottom: 12px;
    }
    ${(props) => props.theme.breakpoints.down('md')} {
      margin: 12px 0;
      padding-left: 14px;
      > li {
        margin-bottom: 8px;
      }
    }
  }
`;
