import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { rest } from 'msw';

const routes = [
  'https://www.google.com/*',
  'https://connect.facebook.net/*',
  'https://www.google-analytics.com/*',
  'https://firebaselogging-pa.googleapis.com/*',
];

export const externalMockHandlers = (env): RestHandler[] => [remoteConfigMock(env)];

const remoteConfigMock = (env) => {
  const path =
    'https://firebaseremoteconfig.googleapis.com/v1/projects/zup-itau-seguros-qa/namespaces/firebase:fetch?key=AIzaSyBUGkcV3ADFRORKU8KUUocbEUQEP39B-ik';

  return rest.post(path, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        entries: {
          ENABLE_REDIRECT_AUTO_HIRING: true,
          AUTO_V2_ENABLED_CHANNELS:
            '[ "superappodonto", "loja", "nlseguros", "hipercard", "veiculos", "finanveiculos", "cartaonaologado", "credicard", "itaucard", "superapp", "itaunaologado", "itauuniclass", "itaupersonnalite", "consorcio", "autenticado", "maladigital", "sms", "maladigital1", "maladigital2", "connectcar", "NLcartoesseguros", "bau-flutuante", "nlsegurosflutuante", "itaucardparcerias", "creditoimobiliario", "assai", "campanhas_lps", "rede", "credicardon", "iti", "icarros", "instagram", "google", "facebook", "sms", "emkt", "superapp", "nlhomeitau", "credicardon", "avi", "whatsapp", "bankline", "itaucard-samsung"]',
          ENABLE_AUTH: 'true',
          ENABLE_AUTOV2_STYLE: 'true',
          ENABLE_FAQ_V2: 'true',
          ENABLE_MY_ACCOUNT: 'true',
          ENABLE_ODONTO_4790: 'true',
          ENABLE_ODONTO_LOGIN: 'true',
          ENABLE_PET_LOGIN: 'true',
          ENABLE_CAMPANHA_AUTO: true,
          ENABLE_RESID_LOGIN: 'true',
          ITAU_EXCLUSIVES_BANNER: '{"enabled":true}',
          ODONTO_HOME_CAMPAIGN: '{"enabled":true}',
          ODONTO_PRODUCT_MAINTENANCE: 'false',
          PROMOTIONINDOOR_ODONTO_NEW_PLANS: '{"enabled":true}',
          RESID_HOME_CAMPAIGN: '{"enabled": false, "discount": false}',
        },
        state: 'UPDATE',
        templateVersion: '183',
      })
    )
  );
};
