import { string, StringSchema } from 'yup';
import mixedValidationFactory, { mixedValidationInterface } from './mixed-validation-factory';

export type StringValidationSchema = StringSchema<
  string | null | undefined,
  Record<string, string | null | undefined>,
  string | null | undefined
>;

export interface IStringValidationFactory
  extends Omit<mixedValidationInterface<StringValidationSchema>, 'chainableSchema'> {
  matches?: {
    pattern: RegExp;
    message: string;
  };
  min?: {
    limit: number;
    message?: string;
  };
  max?: {
    limit: number;
    message: string;
  };
  length?: {
    limit: number;
    message: string;
  };
}

export function stringValidationFactory({
  matches,
  min,
  max,
  length,
  ...mixedValidationProps
}: IStringValidationFactory): StringValidationSchema {
  let yupChain = string().nullable(true).trim();

  yupChain = mixedValidationFactory<StringValidationSchema>({ ...mixedValidationProps, chainableSchema: yupChain });

  if (matches) {
    yupChain = yupChain.matches(matches.pattern, matches.message);
  }

  if (length) {
    yupChain = yupChain.length(length.limit, length.message);
  }

  if (min) {
    yupChain = yupChain.min(min.limit, min.message);
  }

  if (max) {
    yupChain = yupChain.max(max.limit, max.message);
  }

  return yupChain;
}
