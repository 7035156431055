import { BoxWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';
import { Partner } from '@core/domain/entities/dynamic-landings/dynamic-landpage';

type InsuranceCompanyProps = {
  partner: Partner;
};

export const InsuranceCompany = ({ partner }: InsuranceCompanyProps) => {
  return (
    <BoxWeb
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      height={'400px'}
      bgcolor={'background.gray'}
      position="relative"
      textAlign={{ xs: 'center', sm: 'none' }}
    >
      <BoxWeb fontSize={'35px'} role={'heading'} aria-level={2}>
        <MarkdownWeb>{partner.title}</MarkdownWeb>
      </BoxWeb>
      <BoxWeb
        height={'3px'}
        width={'100px'}
        bgcolor="primary.main"
        position="absolute"
        top={{ xs: '9.5rem', sm: '7.5rem' }}
      />
      <BoxWeb
        width="100%"
        fontSize={{ xs: '13px', sm: '15px' }}
        textAlign={'center'}
        height={{ xs: '100px', sm: '60px' }}
      >
        {partner?.subtitle}
      </BoxWeb>
      <BoxWeb>
        <img alt={partner?.alt} src={partner?.image?.url} />
      </BoxWeb>
    </BoxWeb>
  );
};
