export default {
  values: {
    xxs:0,
    xs: 360,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1600,
  },
};
