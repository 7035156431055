import { CardArticleBlog } from '@workspace/frontend/blog/domain/entities';
import { IGetCardArticleBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';
import { IGetCardArticleBlogUseCase } from '@workspace/frontend/blog/domain/contracts/use-cases';

export class GetCardArticleBlogUseCase implements IGetCardArticleBlogUseCase {
  constructor(private readonly getCardArticleBlogRepository: IGetCardArticleBlogRepository) {}

  async execute(): Promise<CardArticleBlog> {
   return await this.getCardArticleBlogRepository.execute();
  };
};
