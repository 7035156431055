import { AccordionWeb, BoxWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';
import { ContainerBox } from './quotation-description.style';

type DescriptionItemProps = {
  id: string;
  title: string;
  description: string;
  price?: string;
  tabIndex: number;
  onToggleAccordion: (betterPrice: boolean, detailName, isShowing: boolean) => void;
};
export function DescriptionItem({ id, title, description, price, tabIndex, onToggleAccordion }: DescriptionItemProps) {
  const openDetailsSection = (e, expanded): void => {
    onToggleAccordion(tabIndex === 1, title, expanded);
  };

  const createParsedDescription = () => {
    const descriptionMultiLine = description?.split('\n');
    let renderElement = [<MarkdownWeb>{description}</MarkdownWeb>];
    if (descriptionMultiLine) {
      renderElement = descriptionMultiLine.map((descriptionLine) => <MarkdownWeb>{descriptionLine}</MarkdownWeb>);
    }
    return <BoxWeb>{renderElement}</BoxWeb>;
  };

  return (
    <BoxWeb pt={'1.5rem'}>
      <ContainerBox>
        <AccordionWeb onChange={openDetailsSection} title={title} id={id} >
          {createParsedDescription()}
        </AccordionWeb>
        {price && (
          <BoxWeb pt={'1rem'} color={'primary.main'} fontSize={'1.1875rem'} fontWeight={700}>
            {price}
          </BoxWeb>
        )}
      </ContainerBox>
    </BoxWeb>
  );
}
