/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { PageLoadBlogHomeTechSpec as BlogHomeLoadTags } from './home/pages-blog-home-techspec';
import { ActionsBlogHomeTechSpec as BlogActionsLoadTags } from './home/action-blog-home-techspec';
import { registerAnalyticsEvent } from '../../analytics-config/dispatch-analytics-events';
import { phraseToUpperCamelCase } from './utils';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const BlogHomeAnalytics = {
  LOAD: () => RAE(BlogHomeLoadTags.T1),
  READ_POST: (textValue) => RAE(BlogActionsLoadTags.A1(phraseToUpperCamelCase(textValue))),
  READ_POST_2: (textValue) => RAE(BlogActionsLoadTags.A2(phraseToUpperCamelCase(textValue))),
  KEEP_READING: () => RAE(BlogActionsLoadTags.A3()),
};
