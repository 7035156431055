import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { SendNicknameRepository } from '@core/infra/repository/my-account/send-nickname-repository';
import { SendNicknameUsecase } from '@core/data/services/send-nickname-usecase';
import { SendNicknameResponse } from '@core/domain/entities/my-account/response/send-nickname-response';
import { SendNickname } from '@core/infra/models/send-nickname';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

export function sendNicknameFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<SendNickname, SendNicknameResponse> = new AxiosHttpPostClient<
    SendNickname,
    SendNicknameResponse
  >('dadosusuario/salvar', {}, environment.gatewayBFFBaseURL.url);

  const sendNicknameRepository = new SendNicknameRepository(httpPostClient);
  const sendNicknameUsecase = new SendNicknameUsecase(sendNicknameRepository);
  return sendNicknameUsecase;
}
