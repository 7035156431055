import { IHttpPostClient } from '../../../data/contracts/http/http-post-client';
import { IEligibilityRepository } from '../../../data/contracts/autov2/eligibility-repository';
import { Eligibility } from '../../../domain/entities/autov2/eligibility';
import { EligibilityResponse } from '../../../domain/entities/autov2/response/eligibility-response';

export class EligibilityRepository implements IEligibilityRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<Eligibility, EligibilityResponse>) {}

  async execute(data: Eligibility) {
    return await this.httpPostClient.post(data);
  }
}
