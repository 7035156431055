import moment from 'moment';

export const pastDateTest = ({ message = 'a data deve ser passado', format = 'DD/MM/YYYY' } = {}) => ({
  name: 'pastDate',
  message,
  test: (value: string): boolean => {
    const date = moment(value, format, true);
    if (!date.isValid()) return true;
    return date.isBefore(moment());
  },
});
