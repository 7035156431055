import { Question } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQQuestionRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQQuestionUseCase {
  execute(): Promise<Question[]>;
}

export class GetFaqQuestionUseCase implements IGetFAQQuestionUseCase {
  constructor(private readonly getFAQQuestionRepository: IGetFAQQuestionRepository) {}

  async execute(): Promise<Question[]> {
    return await this.getFAQQuestionRepository.execute();
  }
}
