import {
  AccordionWeb,
  BoxWeb,
  BreadCrumbsWeb,
  PageProperties,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { Product, Question, Topic } from '@workspace/frontend/faq-v2/domain/entities';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Divider } from '@material-ui/core';
import { CardImg, DesktopContent, Feedback, NavLink, PageTitle, TopicTitle } from './faq-questions-styles';
import { FaqRouteEnum } from '../../enums/faq-routes-enum';
import parse from 'html-react-parser';
import { useRef } from 'react';
import { FeedbackButtons } from '@workspace/frontend/faq-v2/presentation/components';
import { ANALYTICS_FAQ } from '@workspace/web/analytics';

const questionIdFormatter = (question: Question) => question.uid;
const compareIdWithUrl = (id: string): boolean => {
  const focusIndex = window.location.href.indexOf('#');
  const focusTarget = window.location.href.slice(focusIndex).replace('#', '');
  return id === focusTarget;
};

const defineStyleOnBreakpoint = (theme, md, sm) => (theme.breakpoints.values.sm > document.body.clientWidth ? sm : md);

interface QuestionsProps {
  topics: Topic[];
  questions: Question[];
  selectedProduct: Product;
  breadcrumbsPages: PageProperties[];
}

export function FaqQuestions({ topics, questions, selectedProduct, breadcrumbsPages }: QuestionsProps) {
  const { Theme } = useThemeProvider();
  // TODO: the nav menu was disable cause of incompatibility between evolucao and base repositories
  const navMenuEnabled = false;
  const pageTitle = selectedProduct?.titulo?.toLowerCase();
  const topicId = (topic) => topic?.titulo?.toLowerCase().replace(' ', '-');
  const topicAnchor = (topic) => FaqRouteEnum.QUESTIONS + '#' + topicId(topic);
  const topicTitle = (topic) => topic?.titulo?.toLowerCase();
  const likeButton = useRef();
  const dislikeButton = useRef();

  const analyticsFaq = ANALYTICS_FAQ.QUESTIONS.PAGE;

  const handleNavClick = (productSlug: string, topicTitle: string) => {
    analyticsFaq.GO_FOR_CHIPS([productSlug], [topicTitle]);
  };

  const handleBreadCrumbClick = (productSlug: string) => {
    analyticsFaq.BEGINNING_BREADCRUMB([productSlug]);
  };

  const handleAccordionChange = (productSlug: string, topicTitle: string, questionTitle: string, expanded: boolean) => {
    analyticsFaq.SHOW_HIDE_ANSWER([productSlug], [topicTitle, questionTitle, expanded ? 'Expandir' : 'Retrair']);
  };

  return (
    <SCThemeProvider theme={Theme}>
      <BoxWeb
        ml={'auto'}
        mr={'auto'}
        padding={{ xs: '16px 24px 32px', sm: '16px 24px 32px', md: '16px 24px 32px' }}
        maxWidth={{ md: '1072px' }}
      >
        <DesktopContent>
          <BoxWeb mt={'24px'}>
            <BreadCrumbsWeb
              linkTarget={'_self'}
              pages={breadcrumbsPages}
              style={{ fontWeight: 700, fontSize: '14px', lineHeight: '18px' }}
              onClick={() => handleBreadCrumbClick(selectedProduct.slug)}
            />
          </BoxWeb>
        </DesktopContent>

        <BoxWeb mt={{ md: '36px' }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <BoxWeb display={'flex'} alignItems={'center'}>
            <CardImg src={selectedProduct.logo.path} alt={''} />
            <PageTitle>{pageTitle}</PageTitle>
          </BoxWeb>

          {navMenuEnabled && (
            <DesktopContent>
              <nav>
                ir para:{' '}
                {topics.map((topic) => (
                  <NavLink onClick={() => handleNavClick(selectedProduct.slug, topic.titulo)} href={topicAnchor(topic)}>
                    {topicTitle(topic)}
                  </NavLink>
                ))}
              </nav>
            </DesktopContent>
          )}
        </BoxWeb>

        <section>
          {topics.map((topic) => (
            <BoxWeb mt={'24px'}>
              <TopicTitle aria-label={topicTitle(topic)} id={topicId(topic)} key={topic.uid}>
                {topicTitle(topic)}
              </TopicTitle>

              <BoxWeb>
                {questions
                  .filter((question) => question.topico[0].uid === topic.uid)
                  .map((question) => (
                    <>
                      <AccordionWeb
                        defaultExpanded={compareIdWithUrl(question.uid)}
                        arrowPosition={'right'}
                        id={questionIdFormatter(question)}
                        title={question.title}
                        detailsBgColor={'#FAF7F5'}
                        fontFamily={'ItauText-Bold'}
                        fontSize={defineStyleOnBreakpoint(Theme, '16px', '14px')}
                        fontWeight={defineStyleOnBreakpoint(Theme, 700, 400)}
                        onChange={(event, expanded) =>
                          handleAccordionChange(selectedProduct.slug, topic.slug, question.slug, expanded)
                        }
                      >
                        <BoxWeb mr={'1.5rem'} mt={'8px'}>
                          <BoxWeb
                            fontSize={defineStyleOnBreakpoint(Theme, '16px', '14px')}
                            lineHeight={defineStyleOnBreakpoint(Theme, '24px', '20px')}
                            fontFamily={'ItauText-Regular'}
                            style={{ wordBreak: 'break-word' }}
                          >
                            {parse(question.resposta)}
                          </BoxWeb>

                          <BoxWeb display={'flex'} alignItems={'center'}>
                            <Feedback>Encontrou o que estava procurando?</Feedback>

                            <FeedbackButtons
                              product={selectedProduct}
                              topic={topic}
                              question={question}
                              refLikeButton={likeButton}
                              refDislikeButton={dislikeButton}
                            />
                          </BoxWeb>
                        </BoxWeb>
                      </AccordionWeb>
                      <Divider />
                    </>
                  ))}
              </BoxWeb>
            </BoxWeb>
          ))}
        </section>
      </BoxWeb>
    </SCThemeProvider>
  );
}
