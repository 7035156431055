import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from '@material-ui/core/styles/styled';

import { AccordionDefaultProps } from '../../models/accordion';
import { useEffect } from 'react';

interface CustomAccordionSummaryProps {
  arrowPosition: 'left' | 'right';
  fontSize: string;
  fontFamily: string;
  fontWeight: number;
}

const CustomAccordionSummary = styled(AccordionSummary)(
  ({ arrowPosition, fontSize, fontFamily, fontWeight }: CustomAccordionSummaryProps) => ({
    padding: 0,
    flexDirection: arrowPosition === 'right' ? 'row' : 'row-reverse',
    fontSize: fontSize,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    paddingLeft: arrowPosition === 'right' ? '12px' : '0',
  })
);

const CustomAccordionDetails = withStyles(() => {
  return {
    root: {
      marginLeft: '1.5rem',
    },
  };
})(AccordionDetails);

const CustomAccordion = withStyles(() => {
  return {
    root: {
      '&:before': {
        display: 'none',
      },
    },
  };
})(Accordion);

const CustomAccordionMui = ({
  arrowPosition = 'left',
  fontSize,
  fontFamily,
  fontWeight,
  title,
  id,
  onChange,
  children,
  detailsBgColor = 'transparent',
  defaultExpanded = false,
}: AccordionDefaultProps) => {
  const blockExpanded = children ? undefined : false;

  return (
    <CustomAccordion
      defaultExpanded={defaultExpanded}
      expanded={blockExpanded}
      elevation={0}
      id={id}
      onChange={onChange}
    >
      <CustomAccordionSummary
        IconButtonProps={{ edge: arrowPosition === 'left' ? 'start' : 'end' }}
        expandIcon={children && <ExpandMoreIcon color="primary" />}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        arrowPosition={arrowPosition}
      >
        {title}
      </CustomAccordionSummary>

      <div style={{ backgroundColor: detailsBgColor }}>
        <CustomAccordionDetails>{children}</CustomAccordionDetails>
      </div>
    </CustomAccordion>
  );
};

export const AccordionMui = withStyles(() => ({
  root: {
    margin: 0,
  },
}))(CustomAccordionMui);
