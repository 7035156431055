import { UserGetByEmail } from './../../models/user-get-by-email';
import { IGetUserByEmailRepository } from '../../../data/contracts/auth/get-user-by-email';
import { UserAuthResponse } from '../../../domain/entities/auth/response/user';
import { IHttpGetClient } from '../../../data/contracts/http/http-get-client';

export class GetUserByEmailRepository implements IGetUserByEmailRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<{ params: UserGetByEmail }, UserAuthResponse>) {}

  async execute(email: string, headers?: any): Promise<UserAuthResponse> {
    return await this.httpGetClient.get({ params: { email } }, headers);
  }
}
