import { IGetCategoriesBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';
import { CategoryBlog } from '@workspace/frontend/blog/domain/entities';

export class GetCategoriesBlogUseCase implements IGetCategoriesBlogRepository {
  constructor(private readonly getCategoryBlogRepository: IGetCategoriesBlogRepository) {}

  async execute(): Promise<CategoryBlog[]> {
    return await this.getCategoryBlogRepository.execute();
  }
}
