import { BoxWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';

export interface DatePropos {
  date: string;
  dateAcessibility: string;
}
interface PostDataProps {
  date: DatePropos;
  readingTime: string;
}

export const PostData = ({ date, readingTime }: PostDataProps) => {
  return (
    <BoxWeb display="flex" flexDirection="row" style={{ textTransform: 'uppercase' }} aria-hidden={true}>
      <TypographyWeb component="caption" color="textSecondary" aria-hidden={true}>
        {date.date}
      </TypographyWeb>
      &nbsp;
      <TypographyWeb component="caption" color="textSecondary" aria-hidden={true}>
        •
      </TypographyWeb>
      &nbsp;
      <TypographyWeb component="caption" color="textSecondary" aria-hidden={true}>
        {readingTime}
      </TypographyWeb>
    </BoxWeb>
  );
};
