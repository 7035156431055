import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

type ContainerTabItem = {
  current: boolean;
  children: JSX.Element;
  onClick: () => void;
  id: string;
  role: string;
  tabIndex: string;
};

export const ContainerTabItem: ({
  current,
  children,
  onClick,
  id,
  role,
  tabIndex,
}: ContainerTabItem) => JSX.Element = styled.button`\
  height: 56px;
  margin: 0 0.2rem;
  border: 0;
  background: ${(props: { current: boolean; theme: any }) =>
    props.current ? props.theme.palette.primary.main : '#E3E3E3'};
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type TabAutoItem = {
  onClick: () => void;
  label: string;
  current: boolean;
  id: string;
  ariaPosinset: number;
  ariaSetsize: number;
};

export const TabAutoItem = ({ onClick, label, current, id, ariaPosinset, ariaSetsize }: TabAutoItem) => {
  return (
    <ContainerTabItem
      onClick={onClick}
      current={current}
      id={id}
      role={'tab'}
      aria-setsize={ariaSetsize}
      aria-posinset={ariaPosinset}
      aria-selected={current}
      aria-atomic={true}
      tabIndex={'0'}
    >
      <BoxWeb
        color={current ? '#FFF' : '#808080'}
        fontSize={{ xs: '1rem', md: '1.1875rem' }}
        fontFamily="body1.fontFamily"
        fontWeight="bold"
        width={{ xs: '100%', md: '150px' }}
      >
        {label}
      </BoxWeb>
    </ContainerTabItem>
  );
};
