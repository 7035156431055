import { BrowserRouter, Switch } from 'react-router-dom';
import { AutoRouter } from './route/local-router';

export function InsuranceStoreAuto({ prefixpath, partners }) {
  return (
    <BrowserRouter>
      <Switch>
        <AutoRouter prefixPath={prefixpath} origin={partners} />
      </Switch>
    </BrowserRouter>
  );
}
