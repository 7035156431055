import { rest } from 'msw';

export const checkCard = (env) => {
  return rest.post(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/cartoes/bandeira`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        bandeira: 'Visa',
        subTipo: 'PortoSeguro',
        lista_tamanho_max: [16],
      })
    )
  );
};
