import { StepsContentController } from './steps-content-controller';
import { StepController } from './step-controller';
import { StepperWebController } from './stepper-web-controller';
import { Step } from './step';

export function BaseStepper({
  steps = [],
  initialStep = 0,
  onChangeStep,
  hideSteps = false,
}: {
  steps: Step[];
  initialStep?: number;
  onChangeStep?: (...params) => void;
  hideSteps?: boolean;
}) {
  return (
    <StepController initialStep={initialStep} onChangeStep={onChangeStep}>
      <>
        {!hideSteps && <StepperWebController steps={steps} />}
        <StepsContentController steps={steps} />
      </>
    </StepController>
  );
}
