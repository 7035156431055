import { BoxWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import styled from 'styled-components';

interface SectionTitleProps {
  title: string;
  titleComponent?: string;
  subtitle?: string;
  subtitleComponent?: string;
  children: any;
  id?: string;
}

const Divider = styled.div`
  width: 100px;
  height: 4px;
  background-image: linear-gradient(to right, #f36d00, #fea100);
  margin: 12px 0;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 16px 0;
  }
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25.74px;

    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 48px;
      line-height: 61.78px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 41.18px;

    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 48px;
      line-height: 61.78px;
    }
  }
  [id*='subtitleSection'] {
    font-weight: normal;
    line-height: 20.59px;

    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 24px;
      margin: 24px 0;
      line-height: 30.89px;
    }
  }
`;

export const SectionTitle = ({
  title,
  titleComponent = 'h2',
  subtitle,
  subtitleComponent,
  children,
  id,
}: SectionTitleProps) => {
  return (
    <BoxWeb
      component="section"
      px={{
        xs: '16px',
        md: 0,
      }}
      css={{
        maxWidth: '1330px',
        margin: '0 auto',
      }}
    >
      <TextContainer>
        <TypographyWeb
          component={titleComponent}
          id={id}
          ariaLabel={title.replace(/[*]/gm, '')}
          ariaHidenChildren={true}
        >
          {title}
        </TypographyWeb>
        <Divider />
        {subtitle && (
          <TypographyWeb id="subtitleSection" component={subtitleComponent}>
            {subtitle}
          </TypographyWeb>
        )}
      </TextContainer>
      <BoxWeb my={{ xs: 2, md: 5 }}>{children}</BoxWeb>
    </BoxWeb>
  );
};
