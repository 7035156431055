import { useThemeProvider } from '../theme-provider/theme-provider';
import { DefaultAlertProps } from '../../models/default-alert';
import { AlertAuthenticationProps } from '../../models/alert-authentication';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function AlertWeb(props: DefaultAlertProps | AlertAuthenticationProps) {
  const { Components } = useThemeProvider();
  const { Alert } = Components;
  return (
    <ThemeProviderInjector>
      <Alert {...props} />
    </ThemeProviderInjector>
  );
}

export default AlertWeb;
