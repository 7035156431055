export default {
  MuiTextField: {
    variant: 'standard',
    InputLabelProps: {
      shrink: true,
    },
    FormHelperTextProps: {
      role: 'alert',
    },
  },
};
