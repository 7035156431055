import { StateManager } from '../StateManager';
import { Action } from 'redux';
import { setStepAD, stepperTypes } from '../../reducers/stepper/stepperHandlers';
import { store } from '../../store';

class StepperStateManager extends StateManager {
  constructor(store) {
    super(store);
  }

  setStepDispatch(step: number) {
    this.store.dispatch<Action & setStepAD>({ type: stepperTypes.setStep, step });
  }

  getStep(): number {
    return this.store.getState().step;
  }
}

const StepperStateManagerSingleton = new StepperStateManager(store);
export default StepperStateManagerSingleton;
