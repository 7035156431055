import { DefaultLinkProps } from '../../models/default-link';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function LinkWeb(props: DefaultLinkProps) {
  const { Components } = useThemeProvider();
  const { Link } = Components;
  return (
    <ThemeProviderInjector>
      <Link {...props} color={'link.primary'} underline={'always'} />
    </ThemeProviderInjector>
  );
}

export default LinkWeb;
