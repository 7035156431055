import styled from 'styled-components';
import { BadEmoticon, BeatsMeEmoticon, DidntLikeEmoticon, LikedEmoticon, LovedEmoticon } from '@web/assets';

interface IconContainerProps {
  checked?: boolean;
}

interface FeedbackIcons extends IconContainerProps {
  type: 'bad' | 'didntLike' | 'beatsMe' | 'liked' | 'loved';
}

const IconContainer = styled.div<IconContainerProps>`
  img {
    filter: ${(props) => (!props.checked ? 'grayscale(1)' : 'inherit')};
  }
`;

/**
 * @deprecated
 */
export const FeedBackIcons = ({ checked, type }: FeedbackIcons) => {
  const ICONS = {
    bad: BadEmoticon,
    didntLike: DidntLikeEmoticon,
    beatsMe: BeatsMeEmoticon,
    liked: LikedEmoticon,
    loved: LovedEmoticon,
  };

  return (
    <IconContainer checked={checked}>
      <img src={ICONS[type]} alt="" aria-hidden />
    </IconContainer>
  );
};
