import { DialogProps } from '@material-ui/core';
import { useThemeProvider } from '../../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../../theme-provider/theme-provider-injector';

export function DialogWeb({ children, ...otherProps }: DialogProps) {
  const { Components } = useThemeProvider();
  const { Dialog } = Components;

  return (
    <ThemeProviderInjector>
      <Dialog {...otherProps}>{children}</Dialog>
    </ThemeProviderInjector>
  );
}
