import { Product } from '@workspace/frontend/my-account/domain/entities';

enum productsEnum {
  hospitau = 1,
  odonto,
  resid,
  auto,
  pet,
  welfare,
}

const productsInfo = {
  [productsEnum.hospitau]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/bemestar.png',
    title: 'hospitau',
    buttonText: 'quero cancelar',
  },
  [productsEnum.odonto]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/odonto.png',
    title: 'plano odontológico',
    buttonText: 'quero cancelar',
  },
  [productsEnum.resid]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/resid.png',
    title: 'seguro residencial',
    buttonText: 'quero cancelar',
  },
  [productsEnum.auto]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/auto.png',
    title: 'seguro automovel',
    buttonText: 'quero cancelar',
  },
  [productsEnum.pet]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/pet.png',
    title: 'assistência pet',
    buttonText: 'quero cancelar',
  },
  [productsEnum.welfare]: {
    icon: 'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/bemestar.png',
    title: 'assistência bem-estar',
    buttonText: 'quero cancelar',
  },
};

const getUserProductsAdapter = (
  userProducts: Product,
  getCancellationHiringContracts,
  buttonCallback: (title: string, fromLogin: boolean) => void,
  featureFlagLoginOnly
): IUseCaseResponse => {
  const resCancellationHiringRaw = getCancellationHiringContracts.data;
  const products = resCancellationHiringRaw.contratos;

  const productsFiltered = products.filter((product) => Object.values(productsEnum).includes(product.tipo));

  const productsMapped = productsFiltered.map((product) => {
    let buttonLink;
    const handleButtonCallback = () => {
      buttonCallback(product.title, product.fromLogin);
    };

    const qsButtonLink = `?productType=${productsEnum[product.tipo]}`;
    if (featureFlagLoginOnly) {
      buttonLink = `/cancelamento/proposta/${product.id_contratacao}${qsButtonLink}`;
    } else {
      if (!product.mfa) {
        buttonLink = `/cancelamento/proposta/${product.id_contratacao}${qsButtonLink}`;
      } else {
        buttonLink = `/cancelamento${qsButtonLink}`;
      }
    }



    return {
      ...productsInfo[product.tipo],
      subtitle: product.parceiro,
      infos: [
        {
          title: 'produto',
          value: product.nome_produto,
        },
        {
          title: 'proposta',
          value: product.numero_contratacao,
        },
      ],
      buttonLink,
      onClickButton: handleButtonCallback,
    };
  });

  let hospitau;
  if (userProducts?.data?.value) {
    hospitau = {
      identifier: userProducts.data.value.products[0].identifier,
      integrationKey: userProducts.data.value.integrationKey,
      isValid: userProducts.data.value.products[0].isValid,
      products: userProducts.data.value.products
    };
  }

  return {
    hospitau,
    partners: productsMapped,
  };
};

interface IUseCaseResponse {
  hospitau?: {
    integrationKey: string;
    identifier: string;
    isValid: boolean;
  };
  partners?: {
    icon: string;
    title: string;
    subtitle: string;
    infos: {
      title: string;
      value: string;
    }[];
    buttonText: string;
    buttonLink: string;
    onClickButton?: () => void;
  }[];
}

export class GetUserProductsUseCase {
  constructor(private readonly getUserProductsRepository) {}

  async execute(
    userIdentifier: string,
    accessToken: string,
    buttonCallback?: (productName: string, fromLogin: boolean) => void,
    config = {}
  ): Promise<IUseCaseResponse> {
    const { getHiring, userProducts } = await this.getUserProductsRepository.execute(
      { userIdentifier },
      accessToken,
      buttonCallback
    );

    if (!userProducts.data?.success) throw new Error('getUserProducts was unsuccessful');

    const enableMyProductsLoginOnlyFlag = () => {
      try {
        return JSON.parse(config?.['ENABLE_MYPRODUCTS_LOGIN_ONLY']._value);
      } catch (e) {
        return false;
      }
    };

    return getUserProductsAdapter(userProducts, getHiring, buttonCallback, enableMyProductsLoginOnlyFlag());
  }
}
