import { createContext, useContext } from 'react';

const ProductContext = createContext({ product: 'loja' });

export const AuthProductProvider = ({ product, children }: { product: string; children: JSX.Element }) => {
  return <ProductContext.Provider value={{ product }}>{children}</ProductContext.Provider>;
};

export const useAuthProductProvider = () => {
  const value = useContext(ProductContext);
  return value.product;
};
