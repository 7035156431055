import { FormControllerFieldConnector } from '@web/form';
import { InputWeb, InputWebProps } from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';

export const IssuingAgencyField = ({
  disabled,
  id,
  label = 'órgão emissor',
}: {
  disabled?: boolean;
  id?: string;
  label?: string;
}) => (
  <FormControllerFieldConnector<InputWebProps>
    component={InputWeb}
    name="issuingAgency"
    disabled={disabled}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar o orgão emissor' })}
    componentProps={{
      label,
      id,
    }}
  />
);
