import ReactDOM from 'react-dom';
import { LocalRouter } from './utils/local-router';
import { environment as env } from './environments';
import startMockServer from '@mock-service-worker';
import { WebComponentBuilder } from '@workspace/frontend/shared/presentation/webcomponent-builder';
import { DynamicLandPageWrapper } from '@workspace/web/webcomponents/dynamic-landings';
import { Blog } from '@workspace/frontend/blog/presentation/web-app';
import { FAQV2Base } from '@workspace/frontend/faq-v2/presentation/web-app';
import { AccessButton, AuthBase, createAuthv2WebComponent } from '@workspace/frontend/auth/presentation/web-app';
import { HospitauMicrofrontendContainer, MyAccountBase } from '@workspace/frontend/my-account/presentation/web-app';
import startQaTools from '@workspace/frontend/tools/qatools';
import { createDivergentDataQuotationHolderWebComponent } from '@workspace/frontend/shared/presentation/web-app';
import {
  createInsuranceHiringAutoWebComponent,
  createInsuranceStoreAutoWebComponent,
} from '@workspace/frontend/auto/presentation/web-app';

export enum WebcomponentModule {
  DYNAMIC_LANDPAGE = 'dynamic-landpage',
  BLOG = 'loja-blog',
  FAQ_V2_BASE = 'faq-base',
  AUTH_BASE = 'auth-base',
  ACCESS_BUTTON = 'access-button',
  MY_ACCOUNT = 'my-account',
  MY_PRODUCTS = 'my-products',
}

createInsuranceHiringAutoWebComponent(env);
createInsuranceStoreAutoWebComponent(env);
createAuthv2WebComponent(env);
createDivergentDataQuotationHolderWebComponent(env);

WebComponentBuilder.setEnvironment(env)
  .create(WebcomponentModule.DYNAMIC_LANDPAGE, DynamicLandPageWrapper)
  .create(WebcomponentModule.BLOG, Blog)
  .create(WebcomponentModule.FAQ_V2_BASE, FAQV2Base)
  .create(WebcomponentModule.AUTH_BASE, AuthBase)
  .create(WebcomponentModule.ACCESS_BUTTON, AccessButton)
  .create(WebcomponentModule.MY_ACCOUNT, MyAccountBase)
  .create(WebcomponentModule.MY_PRODUCTS, HospitauMicrofrontendContainer)
  .builder();

if (
  (env.environment === 'develop' ||
    env.environment === 'local' ||
    env.environment === 'e2e' ||
    window.location.href.includes('cdpfma')) &&
  !(window.sessionStorage.getItem('x-environment') || '').toLowerCase().trim().startsWith('virtual')
) {
  startMockServer(env);
  startQaTools();
  ReactDOM.render(<LocalRouter />, document.getElementById('root'));
}
