import { IHiringRepository } from '@core/data/contracts/autov2/hiring-repository';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { HiringRequest } from '@core/domain/entities/autov2/hiring';
import { HiringResponse } from '@core/domain/entities/autov2/response/hiring-response';

export class AutoV2HiringRepository implements IHiringRepository<HiringRequest, HiringResponse> {

  constructor(
    private readonly httpPostClient: IHttpPostClient<HiringRequest, HiringResponse>,
  ) {}

  async execute(hiringPayload: HiringRequest): Promise<HiringResponse> {
    return await this.httpPostClient.post(hiringPayload);
  }
}
