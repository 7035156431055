import * as ReactDOM from 'react-dom';

export class AppWebComponent extends HTMLElement {
constructor(children) {
    super();
    this.observer = new MutationObserver(() => this.update);
    this.observer.observe(this, { attributes: true });
    this.element = children;
  }

  disconnectedCallback() {
    this.unmount();
    this.observer.disconnect();
  }

  connectedCallback() {
    this._innerHTML = this.innerHTML;
    this.mount();
  }

  update() {
    this.unmount();
    this.mount();
  }

  parseHtmlToReact(html) {
    return html;
  }

  convert(attrName, attrValue) {
    let value = attrValue;
    if (attrValue === 'true' || attrValue === 'false')
      value = attrValue === 'true';
    else if (!isNaN(attrValue) && attrValue !== '')
      value = +attrValue;
    else if (/^{.*}/.exec(attrValue))
      value = JSON.parse(attrValue);

    return {
      name: attrName,
      value,
    }
  }

  getProps(attributes) {
    return [...attributes]
      .filter((attr) => attr.name !== 'style')
      .map(attr => this.convert(attr.name, attr.value))
      .reduce((props, prop) => ({ ...props, [prop.name]: prop.value }), {});
  }

  mount() {
    const Component = this.element;
    const props = {
      ...this.getProps(this.attributes),
    };
    ReactDOM.render(<Component {...props} />, this);
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this);
  }
}
