import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import {
  FAQSearchQuestionsResponse,
  Question
} from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQSearchQuestionsRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export class GetFAQSearchQuestionsRepository implements IGetFAQSearchQuestionsRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQSearchQuestionsResponse>) {}

  async execute(): Promise<Question[]> {
    const response: FAQSearchQuestionsResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
