import {
  AlertWeb,
  BoxWeb,
  InfoBoxWeb,
  NavigateButtonsWeb,
  OutlinedButtonWeb,
  SwitchWeb,
} from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@workspace/frontend/shared/presentation/form/control';
import { CellphoneField, CpfField, NameField } from '@workspace/frontend/shared/presentation/form/fields';
import styled from 'styled-components';
import { BoxSteps } from '../../hiring-utils';
import React, { useState } from 'react';
import { LeadDialogFormConnectorWrapper } from '../../../../components/auto-quotation-lead-dialog/lead-dialog-form-connector-wrapper';

const GridController = styled.div`
    display: grid;
    row-gap: 1rem;
    grid-template:
      'title' 1fr
      'cpf' 6rem
      'fullName' 6rem
      'cellphone' 6rem
      'createCart' auto
      'info' 1fr
      '.' 16px
      'buttons' auto
      'lgpd' auto
      'lead' 1fr / 100%;
    justify-items: center;
    align-items: center;
    @media (min-width: ${(props) => props.theme?.breakpoints?.values.sm}px) {
      grid-template:
        'title info' 1fr
        'cpf .' 6rem
        'fullName .' 6rem
        'cellphone .' 6rem
        'createCart .' auto
        'buttons .' 4rem
        'lgpd .' auto
        'lead .' 1fr / 50% 50%;
      row-gap: 1rem;
      justify-items: left;
      justify-content:  start;
    },
  `;

export function DriverCpfStep({
  initialValues,
  onSubmit,
  onBack,
  helpImagePath,
  sendLeadUsecase,
  handleCreateCartToggle,
  template,
  state = {},
  config,
}: any) {
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [createCart, setCreateCart] = useState(initialValues.contact);

  const handleCloseLeadModal = () => setOpenLeadModal(false);
  const handleOpenLeadModal = () => setOpenLeadModal(true);
  const handleCreateCart = () => {
    const shouldCreateCart = !createCart;
    setCreateCart(shouldCreateCart);
    handleCreateCartToggle(shouldCreateCart);
  };

  return (
    <BoxSteps>
      <BoxWeb display={state?.error?.message ? 'block' : 'none'} my={'1rem'}>
        <AlertWeb severity={'error'}>{state?.error?.message}</AlertWeb>
      </BoxWeb>
      <FormController
        onSubmit={(data) => {
          onSubmit(data, createCart);
        }}
        initialValues={initialValues}
      >
        <LeadDialogFormConnectorWrapper
          open={openLeadModal}
          onClose={handleCloseLeadModal}
          sendLeadUsecase={sendLeadUsecase}
        />
        <GridController>
          <BoxWeb gridArea={'title'}>
            <BoxWeb component={'h1'} aria-label="qual seu cpf?" id="lblInicialText_screenMainDriver" m={0}>
              <span aria-hidden>
                informe <strong>seus dados</strong>
              </span>
            </BoxWeb>
          </BoxWeb>

          <BoxWeb gridArea={'cpf'} width={'100%'} maxWidth={'320px'}>
            <CpfField
              helperText={'precisa ser o CPF do condutor principal'}
              componentProps={{
                id: 'txtCpf_screenMainDriver',
              }}
              {...state?.cpf}
            />
          </BoxWeb>
          <BoxWeb gridArea={'fullName'} width={'100%'} maxWidth={'320px'}>
            <NameField
              label="nome completo"
              componentProps={{
                id: 'txtFullName_screenMainDriver',
              }}
              {...state?.name}
            />
          </BoxWeb>
          <BoxWeb gridArea={'cellphone'} width={'100%'} maxWidth={'320px'}>
            <CellphoneField componentProps={{ id: 'txtCellphone_screenMainDriver' }} {...state?.cellphone} />
          </BoxWeb>

          <BoxWeb gridArea={'info'} pr={'2rem'}>
            <InfoBoxWeb
              text={
                'nosso seguro é voltado somente para uso particular do veículo. Uso para motoristas de aplicativos e uso ' +
                'comercial ainda não são aceitos.'
              }
              imgSrc={helpImagePath}
              bgcolor={'#fff'}
              border={'1px solid'}
            />
          </BoxWeb>

          <BoxWeb gridArea="createCart" style={{ margin: '8px 0 32px 0' }}>
            <SwitchWeb
              checked={createCart}
              onChange={handleCreateCart}
              label="caso não consiga terminar a contratação do plano, quero receber contato por telefone."
              name="lgpd"
              id="lgpd"
              labelledByID="lgpd"
              disabled={false}
            />
          </BoxWeb>

          <BoxWeb style={{ gridArea: 'buttons' }}>
            <NavigateButtonsWeb onClickBack={onBack} {...state?.continueButton} />
          </BoxWeb>

          {config?.ENABLE_AUTO_SIMPLE_LEAD && (
            <BoxWeb gridArea={'lead'} width="17rem">
              <span>se preferir, deixe seus dados e nós entraremos em contato com você</span>
              <BoxWeb height="0.5rem" />
              <BoxWeb tabIndex={0}>
                <OutlinedButtonWeb
                  id="btnContactLead_screenMainDriver"
                  label="entrar em contato"
                  onClick={handleOpenLeadModal}
                />
              </BoxWeb>
            </BoxWeb>
          )}
          <BoxWeb gridArea={'lgpd'}>{template?.lgpd}</BoxWeb>
        </GridController>
      </FormController>
    </BoxSteps>
  );
}
