import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';
import { IGetFAQBannerRepository } from '@workspace/frontend/faq-v2/domain/contracts';
import { Banner, FAQBannerResponse } from '@workspace/frontend/faq-v2/domain/entities';

export class GetFAQBannerRepository implements IGetFAQBannerRepository {
  constructor(private readonly httpGetClient: IHttpGetClient<null, FAQBannerResponse>) {}

  async execute(): Promise<Banner[]> {
    const response: FAQBannerResponse = await this.httpGetClient.get();
    return response.data.entries;
  }
}
