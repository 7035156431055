export function testAllPageTags(expect, test, pageTechSpec, params?, labelCreators = undefined, log = false) {
  test.each(Object.keys(pageTechSpec))(
    'should make %s tag',
    async (tag) => {
      if (params) {
        let analyticCall;
        if (labelCreators && typeof labelCreators[tag] === 'function') {
          analyticCall = pageTechSpec[tag](labelCreators[tag](...params[tag]));
        } else {
          analyticCall = pageTechSpec[tag]();
        }
        expect(typeof analyticCall).toBe('object');

        if (log) console.log(analyticCall);
        return;
      } else {
        expect(typeof pageTechSpec[tag]).toBe('object');
      }
    },
    10
  );
}

export function testAllActionTags(expect, test, actionsTechSpec, params?: any, labelCreators = undefined, log = false) {
  test.each(Object.keys(actionsTechSpec))(
    'should make %s tag',
    async (tag) => {
      let analytcCall;
      if (labelCreators && typeof labelCreators[tag] === 'function') {
        analytcCall = actionsTechSpec[tag](labelCreators[tag](...params[tag]));
      } else {
        analytcCall = actionsTechSpec[tag]();
      }
      expect(typeof analytcCall).toBe('object');

      if (log) console.log(analytcCall);
      return;
    },
    10
  );
}
