import Carousel from 'react-material-ui-carousel';
import { DefaultCarouselProps } from '../../models/default-carousel';
import { useRef } from 'react';
import { Fab } from '@material-ui/core';

import { LeftSlide, RightSlide } from '@web/assets';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function CarouselWeb({ items, ItemRender, screen }: DefaultCarouselProps) {
  const setTitlesArticles = items.map((x) => x.title);
  const carouselContentRef = useRef([]);

  return (
    <ThemeProviderInjector>
      <Carousel
        animation={'fade'}
        navButtonsAlwaysVisible
        indicators
        autoPlay={false}
        indicatorContainerProps={{
          'aria-hidden': 'true',
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#ec7000',
          },
        }}
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            height: 40,
            width: 40,
            margin: 0,
            backgroundColor: '#2b374a',
          },
        }}
        navButtonsWrapperProps={{
          style: {
            top: 'calc(50% - 20px)',
            zIndex: 10,
            width: 40,
            height: 40,
            margin: '0 8px',
          },
        }}
        changeOnFirstRender={false}
        NavButton={({ onClick, className, style, next, prev }) => {
          const ariaLabel = next ? 'próximo item' : prev ? 'item anterior' : '';

          return (
            <Fab size="small" aria-label={ariaLabel} onClick={() => onClick()} className={className} style={style}>
              {next && <LeftSlide height="15px" />}
              {prev && <RightSlide height="15px" />}
            </Fab>
          );
        }}
        onChange={(now, previous) => {
          if (previous === now) return;
          carouselContentRef?.current[now]?.focus();
        }}
      >
        {items.map((item, i) => (
          <ItemRender
            titles={setTitlesArticles}
            key={i}
            {...item}
            amount={items.length}
            index={i}
            contentRef={(el) => (carouselContentRef.current[i] = el)}
            screen={screen}
          />
        ))}
      </Carousel>
    </ThemeProviderInjector>
  );
}
