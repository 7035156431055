import { rest } from 'msw';

const payload = [
  {
    id: 'ddc67201-28d0-441a-aad7-062186051506',
    name: 'Odonto',
    label: 'plano odontológico',
    'forbidden-channels': [],
    enableE2E: true,
  },
  {
    id: 'b84c230f-481c-467e-a373-4bd8c37cf3c9',
    name: 'Auto',
    label: 'seguro auto',
    'forbidden-channels': [],
    enableE2E: true,
  },
  {
    id: 'cec3a22f-063f-49dd-8999-b69e3331a576',
    name: 'Residencial',
    label: 'seguro residencial',
    'forbidden-channels': [],
    enableE2E: true,
  },
  {
    id: '39cdd3ca-610d-4ae4-a8ed-5aacbf3625f3',
    name: 'Assistência Pet',
    label: 'Assistência Pet',
    'forbidden-channels': [],
    enableE2E: true,
  },
];

export const productLeads = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/products/leads`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(payload));
  });
};
