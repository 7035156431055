import styled from 'styled-components';

export const TooltipTransformDiv = styled.div`
  transform: translate(0, -10px);
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    transform: translate(0, -15px);
  }
`;

export const FipeTableWrapper = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  margin: 0 2rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cacaca;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin: 0 3rem;
  }
`;

export const ContainerBox = styled.div`
  margin: 0 2rem;
  border-bottom: 1px solid #cacaca;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin: 0 3rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const PaddingBox = styled.div`
  margin: 0 2rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin: 0 3rem;
  }
`;

export const FranchiseDarkInfoBox = styled.div`
  padding: 0.5rem 2rem;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #dcdcdc;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    padding: 0.5rem 3rem;
  }
`;

export const FranchiseTitleBox = styled.div`
  padding: 0.5rem 2rem;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.main};
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    padding: 0.5rem 3rem;
  }
`;

export const lightgray = '#EDEDED';
export const lightergray = '#F5F5F5';
export const darkGray = '#DCDCDC';
export const borderRadius = '0.5rem';
export const dividerColor = '#cacaca';
