import { rest } from 'msw';

export const getDependents = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/cotacoes`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        data: {
          id_cotacao: 1,
          porcentagem_desconto_total: 8.53,
          porcentagem_desconto_unitario: 8.53,
          valor_desconto_total: 12,
          valor_desconto_unitario: 4,
          valor_premio_total_bruto: 140.7,
          valor_premio_total_liquido: 128.7,
          valor_premio_unitario_bruto: 46.9,
          valor_premio_unitario_liquido: 42.9,
        },
      })
    )
  );
};
