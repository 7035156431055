import { Button, ButtonProps } from '@material-ui/core';
import styled from 'styled-components';

interface CircleButtonStyledProps extends Omit<ButtonProps, 'color' | 'size'> {
  $buttonSize: number;
  $buttonColor: string;
}

export const CircleButtonStyled = styled(Button)<CircleButtonStyledProps>`
  background-color: ${(props) => props.$buttonColor};
  width: ${(props) => props.$buttonSize}px;
  height: ${(props) => props.$buttonSize}px;
  border-radius: 50% !important;
  border: none;
  font-family: 'ItauDisplayPro-Black';
  font-size: 35px;
  min-width: 0;
  cursor: pointer;

  &:active {
    background-color: ${(props) => props.$buttonColor};
    transform: scale(0.9);
  }
  &:hover {
    background: ${(props) => props.$buttonColor};
    cursor: pointer;

    &:active {
      background-color: ${(props) => props.$buttonColor};
      transform: scale(0.9);
    }

    &.Mui-disabled {
      background-color: rgb(217, 211, 207) !important;
    }
  }
  &.Mui-disabled {
    background-color: rgb(217, 211, 207) !important;
  }
`;
