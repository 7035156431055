import React from 'react';
import styled from 'styled-components';
import { BoxWeb} from '@workspace/frontend/shared/presentation/components';

export interface BlogImageProps {
  src: string;
  showImageCaption?: boolean;
  alt: string;
  hideFromScreenReader: boolean;
}

const Image = styled.img`
  border-radius: 10px;
  border: transparent;
  height: 100%;
  width: 100%;
`;

export const PostImage = ({ src, showImageCaption, alt, hideFromScreenReader }: BlogImageProps) => {
  function shouldHideImageFromScreenReader(): boolean {
    const withoutAlt = !alt;
    return hideFromScreenReader || withoutAlt;
  }
  return (
    <figure style={{ margin: 0 }}>
      <Image src={src} alt={alt} aria-hidden={shouldHideImageFromScreenReader()} />
      {showImageCaption && <BoxWeb my={1} textAlign={{ xs: 'center', md: 'inherit' }} component="figcaption"></BoxWeb>}
    </figure>
  );
};
