import {
  AlertWeb,
  BoxWeb,
  LinkButtonWeb,
  NavigateButtonsWeb,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { useEffect, useRef, useState } from 'react';
import { CodeConfirmationField } from '../../components';
import { sendTokenAuthFactory } from '../../factories/send-token-auth-factory';
import { resendTokenAuthFactory } from '../../factories/resend-token-auth';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import useErrorProvider from '../../providers/error-provider';
import { Title } from '../style';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { RecaptchaActions } from '../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../providers/auth-product-provider';
import { RouteEnum } from '../../enums/route-enum';

const smsResendTime = 45;

const defaultBorderProps = {
  borderColor: "rgba(0, 0, 0, 0.25)'",
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
};

type TokenConfirmationProps = {
  title?: string;
  subtitle?: string;
  didntReceivedCodeText?: string;
  resendCodeText?: string;
  onSubmitCallback?: (params: any, handleSuccess: () => void, handleFailure: (params: unknown) => void) => void;
};

export const TokenConfirmation = ({
  title = 'enviamos um código para o seu email',
  subtitle = 'agora é só informá-lo pra gente',
  didntReceivedCodeText = 'não recebeu?',
  resendCodeText = 'reenviar código',
  onSubmitCallback,
}: TokenConfirmationProps) => {
  const { Theme } = useThemeProvider();
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const {
    environment: { recaptchaSiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.USER_ALREADY_CLIENT_VERIFY_CODE_SENT;

  const [email, setEmail] = useState<string>('');
  const [seconds, setSeconds] = useState<number>(smsResendTime);
  const [loading, setLoading] = useState<boolean>(false);

  const [disableResendButton, setDisableResendButton] = useState<boolean>(true);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);

  const titleRef = useRef(null);

  const closeAlert = () => setRequestError({ show: false });

  const handleRequestError = (errorData) => {
    setLoading(false);

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });

    setDisableResendButton(false);
    setResendEnabled(true);
    setSeconds(0);
  };

  const sendTokenAuthUseCase = sendTokenAuthFactory();
  const resendTokenAuthUseCase = resendTokenAuthFactory();

  useEffect(() => {
    analyticsAuth.LOAD();
    const emailParam = new URLSearchParams(window.location.search).get('email');

    setEmail(emailParam);

    if (titleRef && titleRef.current) setTimeout(() => titleRef.current.focus(), 100);
  }, []);

  useEffect(() => {
    const resendSmsInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(resendSmsInterval);
        setDisableResendButton(false);
      }
    }, 1000);
    return () => {
      clearInterval(resendSmsInterval);
    };
  }, [seconds]);

  const handleSubmit = async ({ code: token }) => {
    analyticsAuth.CONTINUE();

    closeAlert();
    setLoading(true);

    if (onSubmitCallback) {
      const handleSuccess = () => setLoading(false);
      const handleFailure = handleRequestError;

      onSubmitCallback({ token, email }, handleSuccess, handleFailure);
    } else {
      try {
        const recaptcha = await getRecaptchaToken(RecaptchaActions.CONFIRM_TOKEN, recaptchaSiteKey);

        const payload = { email, token };

        const response = await sendTokenAuthUseCase.execute(payload, {
          recaptcha: JSON.stringify(recaptcha),
        });

        setLoading(false);

        if (response?.data?.success) {
          alert('Token validado com sucesso!')
          return window.location.assign(RouteEnum.LOGIN);
        }


      } catch (error) {
        console.error(error);
        handleRequestError(error);
      }
    }
  };

  const resendSms = async () => {
    analyticsAuth.RESEND_CODE();

    closeAlert();
    setDisableResendButton(true);

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.RESEND_TOKEN, recaptchaSiteKey);

      const payload = { email };
      const response = await resendTokenAuthUseCase.execute(payload, {
        recaptcha: JSON.stringify(recaptcha),
      });

      if (response?.data?.success) {
        setResendEnabled(false);
        setSeconds(smsResendTime);
        setDisableResendButton(true);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleError = (errorMessage: string) => {
    analyticsAuth.CODE_ERROR(errorMessage);
  };

  const disableSmsLinkButton = !(seconds === 0 || resendEnabled) || disableResendButton || loading;
  const showCounter = !(seconds === 0 || resendEnabled);

  return (
    <SCThemeProvider theme={Theme}>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        children={requestError.message}
        onClose={closeAlert}
        secondsToClose={16}
      />
      <BoxWeb
        bgcolor="background.main"
        display="flex"
        height={'700px'}
        justifyContent={'center'}
        alignItems={'center'}
        tabIndex={0}
      >
        <BoxWeb
          display="flex"
          flexDirection="column"
          justifyContent={'center'}
          alignItems={'center'}
          width={{ xs: '100%', md: '600px' }}
          height={'auto'}
          padding={'2rem 1rem 1rem 1rem'}
          margin={{ xs: '1rem', md: '0' }}
          bgcolor="primary.contrastText"
          borderRadius="borderRadius"
          {...defaultBorderProps}
        >
          <BoxWeb display={'flex'} flexDirection={'column'} alignItems={'center'} height={'auto'}>
            <Title tabIndex={-1} ref={titleRef} style={{ marginBottom: '8px' }}>
              {title}
            </Title>
            <BoxWeb mb="40px" fontSize="28px" fontWeight="700" fontFamily="ItauDisplay-Regular">
              {subtitle}
            </BoxWeb>
            <FormController onSubmit={handleSubmit} hideErrors>
              <BoxWeb display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <BoxWeb width={{ xs: '100%', md: '400px' }} mb={'0.5rem'}>
                  <CodeConfirmationField onError={handleError} />
                </BoxWeb>
                <BoxWeb
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                  height={'4rem'}
                  width={{ xs: '100%', md: '400px' }}
                >
                  <BoxWeb mt={'0.28rem'}>{didntReceivedCodeText}</BoxWeb>
                  <BoxWeb ml={{ xs: 0, md: '1rem' }} display={'flex'}>
                    <BoxWeb>
                      <LinkButtonWeb disabled={disableSmsLinkButton} label={resendCodeText} onClick={resendSms} />
                    </BoxWeb>
                    {showCounter && (
                      <BoxWeb
                        ml={'0.4rem'}
                        mt={'0.21rem'}
                        fontSize={'16px'}
                        color={'#969290'}
                      >{`em ${seconds} segundos`}</BoxWeb>
                    )}
                  </BoxWeb>
                </BoxWeb>
                <BoxWeb mb={'2.5rem'} mt={'1rem'}>
                  <NavigateButtonsWeb
                    loading={loading}
                    hideBackButton={true}
                    widthContinueButton={'188px'}
                    justifyContent="center"
                  />
                </BoxWeb>
              </BoxWeb>
            </FormController>
          </BoxWeb>
        </BoxWeb>
      </BoxWeb>
    </SCThemeProvider>
  );
};
