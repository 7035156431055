import { rest } from 'msw';

export const assistDetail = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/ofertas/45/assistencias`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          id_assistencia: '34cd651d-e918-4df6-a9f8-038446b4df31',
          nome: 'Suporte veterinário',
          descricao: [
            {
              conteudo: 'suporte veterinário',
              tipo: 'titulo',
            },
            {
              conteudo:
                'pensando no seu pet e em você, o serviço de suporte por telefone te auxilia caso necessite realizar os primeiros socorros e também te indica para clínicas e veterinários.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '60 dias de carência.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: 'ilimitado.',
              tipo: 'topico',
            },
          ],
        },
        {
          id_assistencia: 'a8c4af69-d8c2-459e-a9c0-8b093504bfcc',
          nome: 'Consulta emergencial',
          descricao: [
            {
              conteudo: 'consulta emergencial',
              tipo: 'titulo',
            },
            {
              conteudo:
                'a consulta veterinária em alguns casos é essencial e por isso temos essa assistência que te indica uma rede credenciada para levar o seu pet.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: 'em casos de acidentes a carência é de 30 dias, e por enfermidades é de 90 dias.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: 'até 2 utilizações por ano limitado a R$250,00 por intervenção.',
              tipo: 'topico',
            },
          ],
        },
        {
          id_assistencia: 'f8ad6c96-1e83-41bc-8e05-9283392e7836',
          nome: 'Vacina e aplicação',
          descricao: [
            {
              conteudo: 'vacina e aplicação',
              tipo: 'titulo',
            },
            {
              conteudo:
                'sabemos da importância que a vacinação tem na vida dos pets, e pensando nisso disponibilizamos a assistência de vacinação que inclui as vacinas Antirrábica, Polivalente e Bordetella.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '90 dias de carência.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '1 utilização de cada vacina mencionada.',
              tipo: 'topico',
            },
          ],
        },
        {
          id_assistencia: 'e556bb1e-639f-49f8-9d9e-157176fa65ad',
          nome: 'Hospedagem Pet',
          descricao: [
            {
              conteudo: 'hospedagem pet',
              tipo: 'titulo',
            },
            {
              conteudo:
                'precisa viajar, trabalhar ou se ausentar? com essa assistência, você pode ficar tranquilo pois te oferecemos hospedagem em hotéis para o seu pet em nossas redes credenciadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '60 dias de carência.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '2 intervenções limitados a R$ 250,00 para cada intervenção.',
              tipo: 'topico',
            },
          ],
        },
        {
          id_assistencia: '240cff51-78ef-4bad-8be5-44811dc8a165',
          nome: 'Castração Pet',
          descricao: [
            {
              conteudo: 'castração pet',
              tipo: 'titulo',
            },
            {
              conteudo:
                'oferecemos a assistência para cirurgia de castração caso o seu pet necessite ou você opte por essa decisão.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '90 dias de carência.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '1 intervenção (até R$300,00).',
              tipo: 'topico',
            },
          ],
        },
        {
          id_assistencia: '1c325b4c-ca1b-4201-92cd-a79f2687c7aa',
          nome: 'Funeral Pet',
          descricao: [
            {
              conteudo: 'funeral pet',
              tipo: 'titulo',
            },
            {
              conteudo: 'auxilio na organização e custos do funeral.',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'o serviço de funeral pet te auxilia em questões delicadas como a organização e custos do funeral, cremação ou enterro.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'qual é a carência?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '60 dias de carência.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quem pode utilizar?',
              tipo: 'subtitulo',
            },
            {
              conteudo:
                'em seu primeiro acionamento a Realiza vai pegar os dados do seu pet e é ele que poderá usar todas as assistências contratadas.',
              tipo: 'paragrafo',
            },
            {
              conteudo: 'quais são os limites de utilização?',
              tipo: 'subtitulo',
            },
            {
              conteudo: '1 utilização (até R$ 700,00).',
              tipo: 'topico',
            },
          ],
        },
      ])
    )
  );
};
