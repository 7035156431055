import { IHttpGetClient } from '@workspace/backend/shared/contracts/repositories';
import { IGetCardArticleBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';
import { CardArticleBlogResponse } from '@workspace/frontend/blog/domain/entities';
import { CardArticleBlog } from '@workspace/frontend/blog/domain/entities';


export class GetCardArticleRepository implements IGetCardArticleBlogRepository {

  constructor(private readonly httpGetClient: IHttpGetClient<null, CardArticleBlogResponse>) { }

  async execute(): Promise<CardArticleBlog> {
    const response: CardArticleBlogResponse =  await this.httpGetClient.get();
    return response.data
  }

}
