import { produce } from 'immer';
import { typeGenerator } from '../utils/typeGenerator';

export const apiTypes = {
  setVehicleSearchPlate: 'SET [api] vehicleSearchPlate',
  setDomain: typeGenerator(['api'], 'SET', 'domain'),
  setInstallmentsQuery: typeGenerator(['api'], 'SET', 'installmentsQuery'),
};

export const apiReducerHandlers = {
  [apiTypes.setVehicleSearchPlate]: produce((draft, _action) => {
    draft.api.vehicleSearchPlate = _action.response;
  }),
  [apiTypes.setDomain]: produce((draft, _action) => {
    draft.api.domain = { data: _action.data };
  }),
  [apiTypes.setInstallmentsQuery]: produce((draft, _action) => {
    draft.api.installmentsQuery = { data: _action.data };
  }),
};
