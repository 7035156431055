import { SimulationStatus } from '@core/domain/entities/autov2/simulation-response';
import { QuotationStatus } from './simulation-response';

export class QuotationStatusDTO {
  static translate(quotationStatus: SimulationStatus[]): QuotationStatus {
    return {
      code: quotationStatus?.[0]?.codigo,
      description: quotationStatus?.[0]?.descricao,
    };
  }
}
