import { rest } from 'msw';

export const searchStates = (env) => {
  return rest.get(
    `${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/estados`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json([
          {
            id_uf: 'AC',
            nome_formatado: 'ACRE',
            nome: 'Acre',
          },
          {
            id_uf: 'AL',
            nome_formatado: 'ALAGOAS',
            nome: 'Alagoas',
          },
          {
            id_uf: 'AP',
            nome_formatado: 'AMAPA',
            nome: 'Amapá',
          },
          {
            id_uf: 'AM',
            nome_formatado: 'AMAZONAS',
            nome: 'Amazonas',
          },
          {
            id_uf: 'BA',
            nome_formatado: 'BAHIA',
            nome: 'Bahia',
          },
          {
            id_uf: 'CE',
            nome_formatado: 'CEARA',
            nome: 'Ceará',
          },
          {
            id_uf: 'DF',
            nome_formatado: 'DISTRITO FEDERAL',
            nome: 'Distrito Federal',
          },
          {
            id_uf: 'ES',
            nome_formatado: 'ESPIRITO SANTO',
            nome: 'Espírito Santo',
          },
          {
            id_uf: 'GO',
            nome_formatado: 'GOIAS',
            nome: 'Goiás',
          },
          {
            id_uf: 'MA',
            nome_formatado: 'MARANHAO',
            nome: 'Maranhão',
          },
          {
            id_uf: 'MT',
            nome_formatado: 'MATO GROSSO',
            nome: 'Mato Grosso',
          },
          {
            id_uf: 'MS',
            nome_formatado: 'MATO GROSSO DO SUL',
            nome: 'Mato Grosso do Sul',
          },
          {
            id_uf: 'MG',
            nome_formatado: 'MINAS GERAIS',
            nome: 'Minas Gerais',
          },
          {
            id_uf: 'PA',
            nome_formatado: 'PARA',
            nome: 'Pará',
          },
          {
            id_uf: 'PB',
            nome_formatado: 'PARAIBA',
            nome: 'Paraíba',
          },
          {
            id_uf: 'PR',
            nome_formatado: 'PARANA',
            nome: 'Paraná',
          },
          {
            id_uf: 'PE',
            nome_formatado: 'PERNAMBUCO',
            nome: 'Pernambuco',
          },
          {
            id_uf: 'PI',
            nome_formatado: 'PIAUI',
            nome: 'Piauí',
          },
          {
            id_uf: 'RJ',
            nome_formatado: 'RIO DE JANEIRO',
            nome: 'Rio de Janeiro',
          },
          {
            id_uf: 'RN',
            nome_formatado: 'RIO GRANDE DO NORTE',
            nome: 'Rio Grande do Norte',
          },
          {
            id_uf: 'RS',
            nome_formatado: 'RIO GRANDE DO SUL',
            nome: 'Rio Grande do Sul',
          },
          {
            id_uf: 'RO',
            nome_formatado: 'RONDONIA',
            nome: 'Rondônia',
          },
          {
            id_uf: 'RR',
            nome_formatado: 'RORAIMA',
            nome: 'Roraima',
          },
          {
            id_uf: 'SC',
            nome_formatado: 'SANTA CATARINA',
            nome: 'Santa Catarina',
          },
          {
            id_uf: 'SP',
            nome_formatado: 'SAO PAULO',
            nome: 'São Paulo',
          },
          {
            id_uf: 'SE',
            nome_formatado: 'SERGIPE',
            nome: 'Sergipe',
          },
          {
            id_uf: 'TO',
            nome_formatado: 'TOCANTINS',
            nome: 'Tocantins',
          },
        ])
      )
  );
};

