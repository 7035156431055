import styled, { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Title, TitleBold } from '../../style';
import { BoxWeb, ButtonWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { autov2Images, translateToMicrofrontendPath } from '@web/assets';
import { useEffect, useRef } from 'react';
import { AuthStateManager } from '@workspace/frontend-shared-application-state-manager';
import { useAuthenticationParams } from '../../../providers/authentication-params-provider';
import { useAuthOriginProvider } from '../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../providers/auth-product-provider';
import useOriginAndProductSession from "../../../hooks/useOriginAndProductSession";

const SuccessImage = styled.img`
  height: 322px;
  width: auto;
  z-index: -1;
`;

const SucessContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: row;
    padding: 0;
  }
`;

const SucessTextContainer = styled.div``;

const SucessImgContainer = styled.div`
  display: flex;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0px auto;
  }
`;

export function SuccessContainer() {
  const { Theme } = useThemeProvider();
  const titleRef = useRef(undefined);
  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.SUCCESS;
  const {removeOriginAndProductSession, setOriginAndProductSession} = useOriginAndProductSession();
  const { getRegisteredLoginURLBase } = useAuthenticationParams();

  useEffect(() => {
    analyticsAuth.LOAD();
    analyticsAuth.SEEN_SUCCESS();

    AuthStateManager.clearStore();

    setTimeout(() => {
      if (titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  const handleClick = () => {
    removeOriginAndProductSession();
    setOriginAndProductSession({origin: 'loja', product: 'home'});
    analyticsAuth.LOGIN();
    const loginURLBase = getRegisteredLoginURLBase();

    window.location.assign(loginURLBase);
  };

  const Container = styled.div`
    max-width: 1260px;
    padding: 63px 0 80px;
    margin: 0 auto;
  `;

  return (
    <SCThemeProvider theme={Theme}>
      <Container>
        <SucessContainer>
          <SucessTextContainer>
            <BoxWeb maxWidth="440px">
              <BoxWeb margin="35px 0 8px 0">
                <Title tabIndex={-1} ref={titleRef}>
                  pronto! sua conta foi criada{' '}
                  <span role="img" aria-hidden={true}>
                    :)
                  </span>
                </Title>
              </BoxWeb>
              <BoxWeb margin="0 0 34px 0">
                <TitleBold>faça seu login para acessar sua conta</TitleBold>
              </BoxWeb>
              <BoxWeb margin="0 0 51px 0">
                <p>Tudo certo para você aproveitar a Loja de Seguros Itaú com segurança!</p>
              </BoxWeb>
              <BoxWeb maxWidth="183px">
                <ButtonWeb id="btnLogin_screen" label="fazer login" type="button" onClick={handleClick} />
              </BoxWeb>
            </BoxWeb>
          </SucessTextContainer>
          <SucessImgContainer>
            <SuccessImage
              src={translateToMicrofrontendPath(autov2Images.jumpingPerson)}
              alt="uma pessoa pulando de felicidade"
            />
          </SucessImgContainer>
        </SucessContainer>
      </Container>
    </SCThemeProvider>
  );
}
