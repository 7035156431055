import { rest } from 'msw';

export const getUsersByHashMock = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-autenticacao/usuario`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        errors: [],
        value: {
          id: 123,
          idSituacao: 'ATIVO',
        },
      })
    );
  });
};
