import { GetAddressByCepRequest } from "../../data/models/autov2/get-address-by-cep-request";
import { GetAddressByCepResponse } from "../../data/models/autov2/get-address-by-cep-response";
import { IGetAddressByCepUseCase } from "../../domain/usecases/get-address-by-cep-usecase-interface";
import { GetBySomethingUseCase } from "./get-by-something-usecase";

export class GetAddressByCepUseCase extends GetBySomethingUseCase<GetAddressByCepRequest, GetAddressByCepResponse> implements IGetAddressByCepUseCase {
    
    public async execute(requestModel: GetAddressByCepRequest): Promise<GetAddressByCepResponse> {
        requestModel.cep = requestModel.cep.replace('-', '');        
        return await super.execute(requestModel);
    }
}
