import { put, select } from 'redux-saga/effects';
import { ActorProponentTypes } from '../../reducers/actor/actor-proponent-reducer-handlers';
import { itemRiskAutomobileTypes } from '../../reducers/itemRisk/item-risk-automobile-handlers';

type ProponentType = {
  isMainDriver: boolean;
  cpf?: string;
  name: string;
  rg: string;
  issueDate: string;
  issuingAgency: string;
  nationality: string;
  profession: string;
  incomeBracket: string;
  cep: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  addressNeighborhood: string;
  city: string;
  state: string;
};

type VehicleType = {
  chassis: string;
  renavam?: string;
};

export type additionalDataType = ProponentType & VehicleType;

function* additionalDataStepEffect({
  name,
  cpf,
  rg,
  issueDate,
  issuingAgency,
  nationality,
  profession,
  incomeBracket,
  cep,
  address,
  addressNumber,
  addressComplement,
  addressNeighborhood,
  city,
  state,
  chassis,
  renavam,
  isMainDriver,
}: additionalDataType) {
  let _cpf;
  if (isMainDriver) {
    _cpf = yield select((state) => state.driver.cpf);
  } else {
    _cpf = cpf;
  }
  //itemRisk
  const zeroKm = yield select((state) => state.itemRisk.automobile.zeroKm);

  //proponent
  yield put({ type: ActorProponentTypes.setName, data: name });
  yield put({ type: ActorProponentTypes.setIsMainDriver, data: isMainDriver });
  yield put({ type: ActorProponentTypes.setCpf, data: _cpf });
  yield put({ type: ActorProponentTypes.setRg, data: rg });
  yield put({ type: ActorProponentTypes.setIssueDate, data: issueDate });
  yield put({ type: ActorProponentTypes.setIssuingAgency, data: issuingAgency });
  yield put({ type: ActorProponentTypes.setNationality, data: nationality });
  yield put({ type: ActorProponentTypes.setProfession, data: profession });
  yield put({ type: ActorProponentTypes.setIncomeBracket, data: incomeBracket });

  //proponent.address
  yield put({ type: ActorProponentTypes.setCep, data: cep });
  yield put({ type: ActorProponentTypes.setAddress, data: address });
  yield put({ type: ActorProponentTypes.setAddressNumber, data: addressNumber });
  yield put({ type: ActorProponentTypes.setAddressComplement, data: addressComplement });
  yield put({ type: ActorProponentTypes.setAddressNeighborhood, data: addressNeighborhood });
  yield put({ type: ActorProponentTypes.setCity, data: city });
  yield put({ type: ActorProponentTypes.setState, data: state });

  if (!zeroKm) {
    yield put({ type: itemRiskAutomobileTypes.setRenavam, data: renavam });
  } else {
    yield put({ type: itemRiskAutomobileTypes.setRenavam, data: undefined });
  }
  yield put({ type: itemRiskAutomobileTypes.setChassis, data: chassis });
}

export default additionalDataStepEffect;
