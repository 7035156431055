import { BoxWeb, SelectOption, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import { DebitI } from './payment-methods-switcher';
import React from 'react';
import styled from 'styled-components';
import { AgencyField, BankField, CheckingAccountField, InstallmentField, PaymentDayField } from '@web/form/component';
import { GridTemplateArea } from '@workspace/frontend/auto/presentation/components';
import { DebitCardIcon } from '@web/assets';

export const debitSectionFactory = (
  installmentNumberOptionsMock: SelectOption[],
  bankOptionsMock: SelectOption[],
  paymentDayOptionsMock: SelectOption[]
) =>
  new DebitSection(
    'debit-card-payment-type',
    'débito em conta',
    '',
    (
      <BoxWeb m={'0.5rem'} fontSize={'26px'}>
        <img src={DebitCardIcon} width={'100%'} />
      </BoxWeb>
    ),
    installmentNumberOptionsMock,
    bankOptionsMock,
    paymentDayOptionsMock
  );

class DebitSection implements DebitI {
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public renderIcon: JSX.Element,
    public installments: SelectOption[],
    public bankOptions: SelectOption[],
    public paymentDayOptions: SelectOption[]
  ) {}

  //seção do debito

  render() {
    return (
      <DebitForm
        bankOption={this.bankOptions}
        paymentDayOption={this.paymentDayOptions}
        installmentOption={this.installments}
      />
    );
  }
}

const DebitFormGridTemplate = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: 40px repeat(4, 97px);
  grid-template-columns: 200px 40px 100px 100px;
  grid-template-areas:
    'titulo titulo titulo titulo'
    'banco banco banco banco'
    'agencia . contaCorrente contaCorrente'
    'diaDebito diaDebito . .'
    'parcelas parcelas parcelas .';

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 30px repeat(5, 97px);
    grid-template-areas:
      'titulo'
      'banco'
      'agencia'
      'contaCorrente'
      'diaDebito'
      'parcelas';
  }
`;

export function DebitForm({ bankOption, paymentDayOption, installmentOption }) {
  return (
    <>
      <BoxWeb height={80} />
      <DebitFormGridTemplate>
        <GridTemplateArea area="titulo">
          <TypographyWeb component={'p'}>escolha o **banco**</TypographyWeb>
        </GridTemplateArea>

        <GridTemplateArea area="banco">
          <BankField options={bankOption} />
        </GridTemplateArea>

        <GridTemplateArea area="agencia">
          <AgencyField />
        </GridTemplateArea>

        <GridTemplateArea area="contaCorrente">
          <CheckingAccountField />
        </GridTemplateArea>

        <GridTemplateArea area="diaDebito">
          <PaymentDayField options={paymentDayOption} />
        </GridTemplateArea>

        <GridTemplateArea area="parcelas">
          <InstallmentField options={installmentOption} />
        </GridTemplateArea>
      </DebitFormGridTemplate>
    </>
  );
}
