import { CPFResponse } from '@core/domain/entities/auth/response/cpf';
import { IFindUserByCPFUsecase } from '@core/domain/usecases/validate-cpf-usecase';
import { IFindUserByCPFRepository } from '../contracts/auth/find-user-by-cpf-repository';

export class FindUserByCPFUsecase implements IFindUserByCPFUsecase {
  constructor(private readonly findUserByCPF: IFindUserByCPFRepository) {}
  async execute(document: string, headers?: any): Promise<CPFResponse> {
    return await this.findUserByCPF.execute(document, headers);
  }
}
