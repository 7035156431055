import { TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

export const CategoryTextStyled = styled(TypographyWeb)`
  text-decoration: none;
  font-family: ItauDisplayPro-XBold;
  font-size: 14px;
  text-transform: initial;
  background-color: none;
`;
