import palette from './faq/palette';
import typography from './faq/typography';
import breakpoints from './faq/breakpoints';
import props from './faq/props';
import overrides from './faq/overrides';

const style = {
  typography,
  palette,
  breakpoints,
  shape: {},
  overrides,
  props,
};

export default style;
