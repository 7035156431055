import { BoxWeb, OutlinedButtonWeb } from '@workspace/frontend/shared/presentation/components';

export function DivergentDataQuotationHolderPage({
  onBackButton,
  onContinueButton,
}: {
  onBackButton: () => void;
  onContinueButton: () => void;
}) {
  return (
    <BoxWeb display={'flex'} bgcolor={'background.lightSecondary'} justifyContent={'center'}>
      <BoxWeb
        display={'flex'}
        flexDirection={'column'}
        bgcolor={'primary.contrastText'}
        justifyContent={'center'}
        alignItems={'center'}
        margin={{ xs: '25px 0', md: '59px 0' }}
        width={{ xs: '323px', md: '600px' }}
        height={{ xs: '517px', md: '437px' }}
        boxShadow={'0px 2px 10px rgba(0,0,0,0.25)'}
      >
        <BoxWeb
          width={{ xs: '282px', md: '548px' }}
          color={'text.secondary'}
          textAlign={'center'}
          fontSize={{ xs: '20px', md: '24px' }}
          fontWeight={'300'}
          mb={'24px'}
        >
          identificamos que os dados utilizados na cotação divergem dos dados do titular da conta
        </BoxWeb>
        <BoxWeb
          color={'text.secondary'}
          textAlign={'center'}
          fontSize={{ xs: '20px', md: '24px' }}
          fontWeight={'400'}
          mb={'24px'}
        >
          o que você gostaria de fazer?
        </BoxWeb>
        <BoxWeb display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}>
          <BoxWeb
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            alignItems={'center'}
            width={{ xs: '277px', md: '250px' }}
            height={{ xs: '165px', md: '210px' }}
            mr={{ xs: 0, md: '17px' }}
            mb={{ xs: '8px', md: 0 }}
            border={'1px solid #D6D0CC'}
            borderRadius={'12px'}
          >
            <BoxWeb
              color={'text.primary'}
              fontSize={'16px'}
              fontWeight={'400'}
              width={{ xs: '246px', md: '211px' }}
              height={{ xs: '69px', md: '72px' }}
              lineHeight={'24px'}
            >
              voltar e realizar o login com os mesmos dados da cotação inicial
            </BoxWeb>
            <BoxWeb>
              <OutlinedButtonWeb label={'voltar'} onClick={onBackButton} />
            </BoxWeb>
          </BoxWeb>
          <BoxWeb
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-around'}
            alignItems={'center'}
            width={{ xs: '277px', md: '250px' }}
            height={{ xs: '144px', md: '210px' }}
            border={'1px solid #D6D0CC'}
            borderRadius={'12px'}
          >
            <BoxWeb
              color={'text.primary'}
              fontSize={'16px'}
              fontWeight={'400'}
              width={{ xs: '250px', md: '197px' }}
              height={{ xs: '48px', md: '72px' }}
              lineHeight={'24px'}
            >
              continuar a contratação com os dados dessa conta
            </BoxWeb>
            <BoxWeb>
              <OutlinedButtonWeb label={'continuar a contratação'} onClick={onContinueButton} />
            </BoxWeb>
          </BoxWeb>
        </BoxWeb>
      </BoxWeb>
    </BoxWeb>
  );
}
