import {
  ANALYTICS_EVENT_ENVIRONMENTS,
  ANALYTICS_EVENT_TECHNICAL_IDS,
  assembleAnalyticsPageLoadEvent,
  PageLoadAnalyticsInterface,
} from '@workspace/web/analytics';

type T = 'T1';
const pages: Record<T, string> = {
  T1: 'LS:NL:NCC:Home:SeguroAuto2.0',
};
export type PageLoadAutoV2HomeTechSpecI = (origin) => Record<T, PageLoadAnalyticsInterface>;

export const PageLoadAutoV2HomeTechSpec: PageLoadAutoV2HomeTechSpecI = (origin) => ({
  T1: {
    ...assembleAnalyticsPageLoadEvent(
      {
        nome: pages.T1,
        idTecnico: ANALYTICS_EVENT_TECHNICAL_IDS.DYNAMIC_BANKLINE_VALUE,
        ambiente: ANALYTICS_EVENT_ENVIRONMENTS.NI,
      },
      null,
      { origin }
    ),
  },
});
