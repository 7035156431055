import { FaqQuestions } from './faq-questions';
import { FAQV2StateManagerSingleton as FAQV2StateManager } from '@workspace/frontend-shared-application-state-manager';
import { getFAQProductFactory, getFAQProductQuestionsFactory } from '@workspace/frontend/faq-v2/infra/factories';
import { useEffect, useState } from 'react';
import { Product, Question } from '@workspace/frontend/faq-v2/domain/entities';
import { BoxWeb, LoadingWeb } from '@workspace/frontend/shared/presentation/components';
import { FaqRouteEnum } from '../../enums/faq-routes-enum';
import { ANALYTICS_FAQ } from '@workspace/web/analytics';

function isObjectEmpty(object) {
  try {
    const props = Object.keys(object);
    return props.length <= 0;
  } catch (error) {
    return true;
  }
}

export function FaqQuestionsContainer() {
  const { selectedProduct, topics } = FAQV2StateManager.faqQuestionsSelector();
  const [questions, setQuestions] = useState<Question[]>([] as Question[]);
  const [localSelectedProduct, setLocalSelectedProduct] = useState<Product>({} as Product);
  const [loading, setLoading] = useState(true);

  const selectedProductFromUrl = window.location.search.replace('?produto=', '').replace('_', ' ');
  const getFAQProductQuestions = getFAQProductQuestionsFactory(selectedProductFromUrl);
  const getFAQProducts = getFAQProductFactory();

  const redirectIfThereIsNoProductSelected = () => {
    if (isObjectEmpty(selectedProduct) || !selectedProductFromUrl) {
      window.location.assign(FaqRouteEnum.PRODUCTS);
    }
  };

  const retrieveProductsIfNotAvailable = async () => {
    if (!isObjectEmpty(selectedProduct)) return;

    const productsFromApi = await getFAQProducts.execute();
    productsFromApi.map((productFromApi) => {
      if (productFromApi.titulo === selectedProductFromUrl) {
        return setLocalSelectedProduct(productFromApi);
      }

      return null;
    });
  };

  const retrieveProductQuestions = async () => {
    const productQuestionsFromApi = await getFAQProductQuestions.execute();
    setQuestions(productQuestionsFromApi);
  };

  const analyticsFaq = ANALYTICS_FAQ.QUESTIONS.PAGE;
  useEffect(() => {
    analyticsFaq.LOAD([selectedProduct.slug]);
  }, []);

  useEffect(() => {
    redirectIfThereIsNoProductSelected();

    (async () => {
      await retrieveProductsIfNotAvailable();
      await retrieveProductQuestions();
      setLoading(false);
    })();
  }, []);

  const breadcrumbsPages = [
    {
      name: 'início',
      href: '/v2/ajuda/produtos',
    },
    {
      name: selectedProduct?.titulo?.toLowerCase(),
      href: '',
    },
  ];

  if (loading)
    return (
      <BoxWeb height={'100%'} minHeight={'400px'} display={'flex'} alignItems={'center'}>
        <LoadingWeb />
      </BoxWeb>
    );

  return (
    <FaqQuestions
      breadcrumbsPages={breadcrumbsPages}
      selectedProduct={selectedProduct}
      topics={topics}
      questions={questions}
    />
  );
}
