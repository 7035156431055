import { AutoV2Theme } from '../../components/auto-v2-theme/auto-v2-theme';
import { Home } from './home';
import { AutoV2OriginProvider } from '../../providers/origin-provider';
import { RecaptchaDOMInjector } from '@workspace/frontend/shared/application/injectors';

export const Autov2Carrier = ({ partner }) => (
  <AutoV2OriginProvider partner={partner}>
    <AutoV2Theme partner={partner}>
      <RecaptchaDOMInjector />
      <Home />
    </AutoV2Theme>
  </AutoV2OriginProvider>
);
