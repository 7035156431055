import { registerAnalyticsEvent } from '../../analytics-config/dispatch-analytics-events';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const DynamicLandingPageAnalytics = {
  CALL: (cmsAnalyticsParameters: any) => RAE(cmsAnalyticsParameters),
};
