import { Button } from '@material-ui/core';
import { BoxWeb, TypographyWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  display: flex;
  text-align: center;
  width: auto;
  border: none;
  border-radius: 25px !important;
  margin: ${(props) => props.theme.spacing(4)}px;
  padding: ${(props) => props.theme.spacing(1, 5)};
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText} !important;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light} !important;
  }
`;

export const TypographyWebStyled = styled(TypographyWeb)`
  font-weight: bold;
  text-transform: initial;
`;

export const ReadMoreWrapper = styled(BoxWeb)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  margin-top: ${(props) => props.theme.spacing(3)}px;
`;
