import { rest } from 'msw';

export const offersResponseMock = {
  data: {
    coberturas: [
      {
        titulo: 'Cobertura completa',
        descricao: 'batida, roubo/furto, incêndio e serviços emergenciais.',
        imagem: 'imagem 1',
      },
      {
        titulo: 'Proteção para vidros',
        descricao: 'conte com a troca de vidros, lanternas, faróis e retrovisores.',
        imagem: 'imagem 2',
      },
      {
        titulo: 'Danos materiais e terceiros',
        descricao: 'pensando no carro e nos bens materiais das pessoas',
        imagem: 'imagem 3',
      },
      {
        titulo: 'Acidentes pessoais a passageiros',
        descricao: 'acidentes de passageiros em casos de invalidez permanente e morte acidental',
        imagem: 'imagem 4',
      },
      {
        titulo: 'Danos corporais a terceiros',
        descricao: 'além do carro, nos preocupamos também com as pessoas',
        imagem: 'imagem 5',
      },
    ],
    assistencias: [
      {
        titulo: 'Assistências',
        descricao: 'serviços de guincho, chaveiro, encanador, reparo de furo de pneu e muito mais.',
        imagem: 'imagem 6',
      },
    ],
  },
};

export const offersHomeMock = (env) => {
  return rest.post(`${env.gatewayBFFBaseURL.url}/oferta`, (req, res, ctx) => {
    res(ctx.status(200), ctx.json(offersResponseMock));
  });
};
