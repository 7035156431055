import { User } from '@workspace/entities';
import { authInitialValues } from '../authInitialValues';
import produce from 'immer';

export const DefaultTypes = {
  RESET: 'RESET',
};

export const UserTypes = {
  setname: 'SET [user] name',
  setauthenticated: 'SET [user] authenticated',
};

export const RegistrationTypes = {
  setcpf: 'SET [registration] cpf',
  setemail: 'SET [registration] email',
  setname: 'SET [registration] name',
  setcellphone: 'SET [registration] cellphone',
  setregistrationstep: 'SET [registration] step',
};

export const MyAccountTypes = {
  sethospitauidentifier: 'SET [my-account] identifier',
  sethospitauintegrationkey: 'SET [my-account] integrationkey',
};

const defaultHandlers = {
  [DefaultTypes.RESET]: () => authInitialValues,
};

const userHandlers = {
  [UserTypes.setname]: produce((draft, _action: { name: string }) => {
    const user = new User({ name: _action.name });
    draft.user.name = user.name;
  }),

  [UserTypes.setauthenticated]: produce((draft, _action: { isAuthenticated: boolean }) => {
    draft.user.isAuthenticated = _action.isAuthenticated;
  }),
};

const myAccountHandlers = {
  [MyAccountTypes.sethospitauidentifier]: produce((draft, _action: { identifier }) => {
    draft.user.identifier = _action.identifier;
  }),
  [MyAccountTypes.sethospitauintegrationkey]: produce((draft, _action: { integrationKey }) => {
    draft.user.integrationKey = _action.integrationKey;
  }),
}

const registrationHandlers = {
  [RegistrationTypes.setcpf]: produce((draft, _action: { cpf: string }) => {
    const user = new User({ cpf: _action.cpf });
    draft.registration.cpf = user.cpf;
  }),
  [RegistrationTypes.setemail]: produce((draft, _action: { email: string }) => {
    const user = new User({ email: _action.email });
    draft.registration.email = user.email;
  }),
  [RegistrationTypes.setname]: produce((draft, _action: { name: string }) => {
    const user = new User({ name: _action.name });
    draft.registration.name = user.name;
  }),
  [RegistrationTypes.setcellphone]: produce((draft, _action: { cellphone: string }) => {
    const user = new User({ cellphone: _action.cellphone });
    draft.registration.cellphone = user.cellphone;
  }),
  [RegistrationTypes.setregistrationstep]: produce((draft, _action: { step: number }) => {
    draft.registration.step = _action.step;
  }),
};

const handlers = {
  ...defaultHandlers,
  ...userHandlers,
  ...registrationHandlers,
  ...myAccountHandlers,
};

export default handlers;
