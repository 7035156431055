import { BoxWeb, RadioGroupWeb, TooltipWeb } from '@workspace/frontend/shared/presentation/components';
import { Company } from '@core/domain/enums/company';
import { useEffect, useState } from 'react';
import { CoverageType, FranchiseType, SimulationType } from '../../adapter/simulation-response';
import {
  borderRadius,
  FipeTableWrapper,
  FranchiseDarkInfoBox,
  FranchiseTitleBox,
  lightergray,
  lightgray,
  PaddingBox,
  TooltipTransformDiv,
} from './quotation-description.style';
import { DescriptionItem } from './description-item';
import { Coverage } from '../../enums/coverage';

const franchiseValueLabel =
  'Franquia é a sua participação no pagamento do reparo do carro em caso de perda parcial.' +
  'Exemplo: Franquia 100%: o valor da sua franquia é de R$ 2.500,00, o prejuízo é de R$ 10.000,00 e para a seguradora R$ 7.500,00.' +
  'Franquia 50%: o valor da sua franquia é de R$1.050,00, o prejuízo é de R$ 10.000,00 e para a seguradora R$ 8.950,00.';

type QuotationDescriptionProps = {
  title: string;
  simulationResponse: SimulationType[];
  partnerBanner: string;
  partnerBannerName: string;
  tabIndex: number;
  onChangeSwitch: any;
  selectedCompany: Company;
  onToggleAccordion: any;
  getOfferByFranchise: (offer: SimulationType[], franchiseIndex: number) => SimulationType;
  enableCampanhaAuto: boolean;
};

export const QuotationDescription = ({
  title,
  simulationResponse,
  partnerBanner,
  partnerBannerName,
  tabIndex,
  onChangeSwitch,
  selectedCompany,
  onToggleAccordion,
  getOfferByFranchise,
  enableCampanhaAuto,
}: QuotationDescriptionProps) => {
  const [currentSwitchChecked, setCurrentSwitchChecked] = useState<number>(Coverage.FRANCHISE_100);

  const currentOffer: SimulationType = getOfferByFranchise(simulationResponse, currentSwitchChecked);

  useEffect(() => {
    if (!currentOffer) {
      setCurrentSwitchChecked((oldValue) =>
        oldValue === Coverage.FRANCHISE_50 ? Coverage.FRANCHISE_100 : Coverage.FRANCHISE_50
      );
    } else {
      onChangeSwitch(currentSwitchChecked);
    }
  }, [currentSwitchChecked, selectedCompany]);

  const currentCoverage: CoverageType = currentOffer?.offers.coverages.find(
    (item) => item.code === Coverage.WITH_FRANCHISE_VALUE
  );
  const currentFranchiseValue: FranchiseType = currentCoverage?.franchise.find((item) => item.code);

  const handleSwitchFranchise = (): void => {
    setCurrentSwitchChecked((oldValue) =>
      oldValue === Coverage.FRANCHISE_50 ? Coverage.FRANCHISE_100 : Coverage.FRANCHISE_50
    );
  };

  const disabledSwitch = () => {
    const offersByFranchise100 = getOfferByFranchise(simulationResponse, Coverage.FRANCHISE_100);
    const offersByFranchise50 = getOfferByFranchise(simulationResponse, Coverage.FRANCHISE_50);
    const disabledByFranchise50Empty = !offersByFranchise50;
    const disabledByFranchise100Empty = !offersByFranchise100;

    return simulationResponse.length === 1 || disabledByFranchise50Empty || disabledByFranchise100Empty;
  };

  return (
    <BoxWeb borderRadius={borderRadius} border={'1px solid'} borderColor={'primary.main'}>
      <BoxWeb
        bgcolor={lightergray}
        borderRadius={`${borderRadius} ${borderRadius} 0 0`}
        py={'1.5rem'}
        display={'flex'}
        justifyContent={'space-between'}
        position={'relative'}
      >
        {enableCampanhaAuto ? (
          <PaddingBox>
            <BoxWeb display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }}>
              <BoxWeb width={{ xs: 'none', sm: '50%' }}>
                <BoxWeb fontWeight={'800'} fontSize={'26px'} color={'secondary.dark'}>
                  {title}
                </BoxWeb>
                <BoxWeb fontSize={30} fontFamily="body1.fontFamily" fontWeight="bold">
                    {`4x mensais de ${currentOffer?.interestFreeInstallments}`}
                </BoxWeb>
                <BoxWeb>
                  <BoxWeb fontSize={16}>{`valor anual de ${currentOffer?.price}`}</BoxWeb>
                </BoxWeb>
              </BoxWeb>
              <BoxWeb display={'flex'} width={{ xs: 'none', sm: '50%' }} mt={'3rem'} fontSize={'18px'}>
                <BoxWeb>A proteção do seu veículo com <span style={{ color: '#EC7000'}}>10% de desconto.</span> É por tempo limitado!</BoxWeb>
              </BoxWeb>
            </BoxWeb>
          </PaddingBox>
          ) : (
          <PaddingBox>
            <BoxWeb fontWeight={'800'} fontSize={'26px'} color={'secondary.dark'}>
              {title}
            </BoxWeb>
            <BoxWeb fontSize={30} fontFamily="body1.fontFamily" fontWeight="bold">
              {`4x mensais de ${currentOffer?.interestFreeInstallments}`}
            </BoxWeb>
            <BoxWeb>
              <BoxWeb fontSize={16}>{`valor anual de ${currentOffer?.price}`}</BoxWeb>
            </BoxWeb>
          </PaddingBox>
        )}
      </BoxWeb>

      <BoxWeb bgcolor={lightgray} display={'flex'} flexDirection={['column', 'row']}>
        <FranchiseTitleBox>
          <BoxWeb id="lblFranchise_screenQuotation" color="#000" fontSize={'1.1875rem'} fontFamily={'ItauText-Bold'}>
            selecione o valor da sua franquia
          </BoxWeb>
          <TooltipTransformDiv>
            <TooltipWeb title={franchiseValueLabel} />
          </TooltipTransformDiv>
        </FranchiseTitleBox>
        <FranchiseDarkInfoBox>
          <RadioGroupWeb
            options={[
              { value: Coverage.FRANCHISE_50, label: '50%' },
              { value: Coverage.FRANCHISE_100, label: '100%' },
            ]}
            defaultValue={Coverage.FRANCHISE_50}
            row={true}
            labelPlacement={'end'}
            id="chkFranchise_screenQuotation"
            value={currentSwitchChecked}
            disabled={disabledSwitch()}
            onChange={handleSwitchFranchise}
          />
          <BoxWeb fontFamily="body1.fontFamily" fontWeight="bold" fontSize={18} color={'#000'}>
            {currentFranchiseValue?.price}
          </BoxWeb>
        </FranchiseDarkInfoBox>
      </BoxWeb>
      <BoxWeb py={'2rem'}>
        <FipeTableWrapper>
          <BoxWeb fontSize={'1.1875rem'} fontWeight={700}>100% da tabela FIPE</BoxWeb>
          <BoxWeb fontSize={'1.1875rem'} fontWeight={700}>{currentCoverage?.amountInsured}</BoxWeb>
        </FipeTableWrapper>
        {currentOffer?.offers.coverages?.map((i, index) => (
          <DescriptionItem
            tabIndex={tabIndex}
            id={`btnCoverage_screenQuotation_${index}`}
            key={index}
            title={i.title}
            description={i.description}
            price={i.amountInsured}
            onToggleAccordion={onToggleAccordion}
          />
        ))}
        {currentOffer?.offers.assistances?.map((i, index) => (
          <DescriptionItem
            tabIndex={tabIndex}
            id={`btnAssitence_screenQuotation_${index}`}
            key={index}
            title={i.title}
            description={i.description}
            onToggleAccordion={onToggleAccordion}
          />
        ))}
      </BoxWeb>
      <BoxWeb pb={'2rem'} px={'4rem'}>
        <img src={partnerBanner} alt={partnerBannerName} />
      </BoxWeb>
    </BoxWeb>
  );
};
