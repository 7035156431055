import { stepEffectTypes } from '../../effects/EffectTypes';
import { store } from '../../authStore';
import { StateManager } from '../StateManager';

interface IUser {
  cpf: string;
  email: string;
  name: string;
  cellphone: string;
  isAuthenticated?: boolean;
}

export class AuthStateManagerClass extends StateManager {
  userSelector(): IUser {
    const { user } = this.store.getState();
    return user;
  }

  userNameDispatcher(name: string) {
    this.store.dispatch({ type: stepEffectTypes.userEffect, name });
  }

  userNameSelector(): IUser['name'] {
    const { user } = this.store.getState();
    return user?.name;
  }

  userAuthenticatedDispatcher(isAuthenticated: boolean): void {
    this.store.dispatch({ type: stepEffectTypes.userAuthenticatedEffect, isAuthenticated });
  }

  userAuthenticatedSelector(): IUser['isAuthenticated'] {
    const { user } = this.store.getState();
    return user?.isAuthenticated;
  }

  registrationCPFDispatcher(cpf: string) {
    this.store.dispatch({ type: stepEffectTypes.registrationCpfEffect, cpf });
  }
  registrationCPFSelector(): IUser['cpf'] {
    const { registration } = this.store.getState();
    return registration?.cpf;
  }

  registrationEmailDispatcher(email: string) {
    this.store.dispatch({ type: stepEffectTypes.registrationEmailEffect, email });
  }

  registrationEmailSelector(): IUser['email'] {
    const { registration } = this.store.getState();
    return registration?.email;
  }

  registrationUserInformationDispatcher(data: Omit<IUser, 'cpf'>) {
    const { name, email, cellphone } = data;

    this.store.dispatch({
      type: stepEffectTypes.registrationUserInformationEffect,
      name,
      email,
      cellphone,
    });
  }

  registrationUserInformationSelector(): IUser {
    const { registration } = this.store.getState();
    return registration;
  }

  registrationStepDispatcher(step: number) {
    this.store.dispatch({ type: stepEffectTypes.registrationStepEffect, step });
  }

  registrationStepSelector(): number {
    const { registration } = this.store.getState();
    return registration?.step;
  }

  getState(): Record<string, any> {
    return this.store.getState();
  }

  clearStore() {
    return this.store.dispatch({ type: 'RESET' });
  }

  contextHospitauSelector() {
    const {
      user: { identifier, integrationKey },
    } = this.store.getState();
    return { identifier, integrationKey };
  }

  contextHospitauDispatcher({
  identifier,
  integrationKey,
  }: {
    identifier;
    integrationKey;
  }) {
    this.store.dispatch({ type: stepEffectTypes.hospitauContextEffect, identifier, integrationKey });
  }
}

const AuthStateManagerSingleton = new AuthStateManagerClass(store);
export default AuthStateManagerSingleton;
