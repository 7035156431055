import { rest } from 'msw';

export const sendCancelTokenMock = (env) => {
  return rest.post(`${env.gatewayBFFBaseURL.url}/cancelamentos/sendtoken`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: true,
      })
    );
  });
};
