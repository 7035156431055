import { AxiosHttpGetClient } from '@core/infra/axios/axios-http-get-client';
import { IHttpGetClient } from '@core/data/contracts/http/http-get-client';

import { GetFaqProductUseCase } from '@workspace/frontend/faq-v2/domain/use-cases';
import { GetFAQProductRepository } from '@workspace/frontend/faq-v2/infra/repositories';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { FAQProductResponse } from '@workspace/frontend/faq-v2/domain/entities';

export function getFAQProductFactory() {
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpGetClient: IHttpGetClient<null, FAQProductResponse> = new AxiosHttpGetClient(
    `/content_types/faq_produto/entries?environment=${environment?.cmsBase?.environment}&asc=posicao`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getFAQProductRepository = new GetFAQProductRepository(httpGetClient);
  return new GetFaqProductUseCase(getFAQProductRepository);
}
