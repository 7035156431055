import { CommentBlogRequest } from '@workspace/frontend/blog/domain/entities';
import { ISendCommentUsecase } from '@workspace/frontend/blog/domain/contracts/use-cases';
import { ICommentBlogRepository } from '@workspace/frontend/blog/domain/contracts/repositories';

export class SendCommentUsecase implements ISendCommentUsecase{
    constructor(
        private readonly sendLeadRepository: ICommentBlogRepository,
        private readonly onError: (error) => void) {}

    async execute(comment: CommentBlogRequest) {
      try {
        await this.sendLeadRepository.execute(comment);
        return true;
      } catch (e) {
        this.onError(e);
        return false;
      }
    }

}
