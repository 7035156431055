import { MouseEventHandler } from 'react';
import { Box, CircularProgress, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ANALYTICS_BLOG } from '@workspace/web/analytics';

import { ReadMore, PostCardSecondary } from '../..';
import { PostCard } from '../PostCard/PostCard';
import { phraseToUpperCamelCase } from '../../../utils';

export interface AlbumProps {
  cards: {
    post_image: {
      path: string;
    };
    post_title: string;
    post_category: {
      title: string;
    }[];
    url: string;
  }[];
  onReadMoreClick: MouseEventHandler<HTMLButtonElement>;
  focusIndex: number;
  loading: boolean;
  showReadMore?: boolean;
}

export const Album = ({ cards, onReadMoreClick, focusIndex, loading, showReadMore = true }: AlbumProps) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onClickPost = (card) => {
    history.push(card.url);
    ANALYTICS_BLOG.HOME.READ_POST_2([phraseToUpperCamelCase(card.post_title)]);
  };

  return (
    <Box role="feed">
      <Grid container spacing={7}>
        {cards?.map((card, index) => (
          <Grid xs={12} md={4} item key={index} role="article">
            {isMobileScreen ? (
              <PostCard
                image={card.post_image.path}
                title={card.post_title}
                category={card.post_category[0]?.title}
                focusOnLoad={focusIndex === index}
                height="61vw"
                data-testid="post-mobile"
                onClickPost={() => onClickPost(card)}
              />
            ) : (
              <PostCardSecondary
                image={card.post_image.path}
                title={card.post_title}
                category={card.post_category[0]?.title}
                focusOnLoad={focusIndex === index}
                data-testid="post-desktop"
                onClickPost={() => onClickPost(card)}
              />
            )}
          </Grid>
        ))}
      </Grid>
      {loading && (
        <Box display="flex" width="100%" justifyContent="center" my={3} data-testid="loading">
          <CircularProgress />
        </Box>
      )}
      {showReadMore && <ReadMore title="Ver mais artigos" onClick={onReadMoreClick} />}
    </Box>
  );
};
