import { VehicleStep } from './vehicle-step';
import { AutoStateManager } from '@workspace/frontend-shared-application-state-manager';
import { useStepProvider } from '../../../../providers/step-provider';
import { useEffect, useState } from 'react';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { BoxSteps } from '../../hiring-utils';
import { sendLeadFactory } from '../../../../factories/send-lead-factory';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { findVehicleByDescriptionFactory } from '../../../../factories/find-vehicle-by-description';
import { findVehicleByPlateFactory } from '../../../../factories/find-vehicle-by-plate';
import { NetworkStyle } from '@core/domain/entities/autov2/networkStyle';
import { NetworkImages } from '../../../../enums/networkImages';
import { useOriginContext } from '../../../../providers/origin-provider';

function StepTwo() {
  const stepProvider = useStepProvider();
  const initialValues = AutoStateManager.itemRiskStepSelector();
  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const [leadChannel, setLeadChannel] = useState<string>();
  const [plateNotFoundMessage, setplateNotFoundMessage] = useState<boolean>(false);
  const [isEditableFields, setIsEditableFields] = useState<boolean>(false);
  const [isPlateFound, setIsPlateFound] = useState(!!initialValues.plate);
  const [searchByDescriptionError, setSearchByDescriptionError] = useState<boolean>(false);
  const [gentilLogoImagePath, setGentilLogoImagePath] = useState<string>();
  const [gentilTextImagePath, setGentilTextImagePath] = useState<string>();
  const networkStyleService = networkStyleFactory(partialEnvironment);

  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const analyticsAuto = autoV2analyticsCalls.HIRING.VEHICLE;
  const config = { enableLead: false };

  const { environment } = partialEnvironment;
  useEffect(() => {
    analyticsAuto.LOAD();
    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name }: { channel_name: string } = await networkStyleUsecase.execute();
      setLeadChannel(channel_name);
    })();
  }, []);

  const onSubmit = (data) => {
    const {
      plate,
      zeroKm,
      bonusClass,
      brandAndModel,
      insuranceCompany,
      modelYear,
      fabricationYear,
      previousBonus,
      itemsBrandAndModel,
    } = data;
    AutoStateManager.itemRiskStepDispatcher(
      plate,
      zeroKm,
      bonusClass,
      brandAndModel,
      insuranceCompany,
      modelYear,
      fabricationYear,
      itemsBrandAndModel
    );
    analyticsAuto.CONTINUE(zeroKm, previousBonus);
    stepProvider.handleNext();
  };

  const handleBack = () => {
    stepProvider.handleBack();
  };

  const initImages = async () => {
    const cachedNetworkStyle: NetworkStyle = await networkStyleService.execute();
    const gentilLogoImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.GENTIL_LOGO);
    const gentilTextImage = cachedNetworkStyle.images.find((image) => image.name === NetworkImages.GENTIL_TEXT);
    setGentilLogoImagePath(gentilLogoImage?.file?.url);
    setGentilTextImagePath(gentilTextImage?.file?.url);
  };
  useEffect(() => {
    initImages();
  }, []);

  const onSearchByPlateError = (e: Response) => {
    if (e.status === 404) {
      setIsEditableFields(true);
      setIsPlateFound(true);
      setplateNotFoundMessage(true);
      autoV2analyticsCalls.HIRING.VEHICLE.PLATE_NOT_FOUND();
    }
  };

  const onSearchByDescriptionError = () => {
    setSearchByDescriptionError(true);
  };

  const findVehicleByDescriptionUsecase = findVehicleByDescriptionFactory({
    partialEnvironment,
    onError: onSearchByDescriptionError,
  });
  const findVehicleByPlateUseCase = findVehicleByPlateFactory({ partialEnvironment, onError: onSearchByPlateError });

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });

  return (
    <BoxSteps>
      <VehicleStep
        initialValues={initialValues}
        onSubmit={onSubmit}
        handleBack={handleBack}
        sendLeadUsecase={sendLeadUsecase}
        findVehicleByPlateUseCase={findVehicleByPlateUseCase}
        findVehicleByDescriptionUsecase={findVehicleByDescriptionUsecase}
        setIsPlateFound={setIsPlateFound}
        isPlateFound={isPlateFound}
        setIsEditableFields={setIsEditableFields}
        isEditableFields={isEditableFields}
        setSearchByDescriptionError={setSearchByDescriptionError}
        plateNotFoundMessage={plateNotFoundMessage}
        searchByDescriptionError={searchByDescriptionError}
        setplateNotFoundMessage={setplateNotFoundMessage}
        analyticsAutoV2={autoV2analyticsCalls}
        gentilLogoImagePath={gentilLogoImagePath}
        gentilTextImagePath={gentilTextImagePath}
        config={config}
      />
    </BoxSteps>
  );
}

export function StepTwoVehicle() {
  return <StepTwo />;
}
