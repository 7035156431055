import { DefaultSwitchProps, SwitchWeb } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';

export const MainDriverField = ({ onChange, disabled = false }: { onChange?: (e) => void; disabled?: boolean }) => (
  <FormControllerFieldConnector<DefaultSwitchProps>
    component={SwitchWeb}
    name="mainDriver"
    onChange={onChange}
    translator={{ value: 'checked' }}
    componentProps={{
      label: 'o titular do veiculo é o condutor principal?',
      disabled,
      id: 'chkMainDriver_screenAdditionalData',
    }}
  />
);
