import React from 'react';
import { BoxWeb, ButtonWeb } from '@workspace/frontend/shared/presentation/components';
import { SatisfactionCommentField } from '@web/form/component';

export const CommentContainer = ({ commentLabel = '' }) => {
  return (
    <BoxWeb display="flex" flexDirection="column">
      <BoxWeb my={2}>
        <label htmlFor="commentTextArea" id="successLabel">
          {commentLabel}
        </label>
      </BoxWeb>
      <BoxWeb my={1}>
        <SatisfactionCommentField
          id="lblCommentBox_screenArticle"
          rows={6}
          rowsMax={6}
          placeholder="escreva aqui"
          style={{ width: '100%', boxSizing: 'border-box' }}
        />
      </BoxWeb>
      <BoxWeb my={1} width={{ xs: 'auto', md: 196 }}>
        <ButtonWeb id="btnEnviarComentario_screenArticle" type="submit" label="enviar comentário" />
      </BoxWeb>
    </BoxWeb>
  );
};
