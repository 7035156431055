import React from 'react';
import { useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import Home from './home';

export function HomeBase() {
  const { Theme } = useThemeProvider();
  return (
    <SCThemeProvider theme={Theme}>
      <Home />
    </SCThemeProvider>
  );
}
