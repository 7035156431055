import { FormControllerFieldConnector } from '@workspace/frontend/shared/presentation/form/control';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import React from 'react';
import {
  SelectWithModalOnMobile,
  SelectWithModalOnMobileProps,
} from '@workspace/frontend/shared/presentation/composed-components';

export const InstallmentField = ({
  id = 'txtInstallment_screen',
  label = 'quantidade de parcelas',
  options = [],
}: {
  id?: string;
  label?: string;
  options?: Array<{ value: string; key: string }>;
}): JSX.Element => (
  <FormControllerFieldConnector<SelectWithModalOnMobileProps>
    component={SelectWithModalOnMobile}
    name={'installmentNumber'}
    validation={stringValidationFactory({ requiredMessage: 'você precisa informar a quantidade de parcelas' })}
    componentProps={{ id, label, items: options }}
  />
);
