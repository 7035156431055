import { SendCommentUsecase } from '@workspace/frontend/blog/domain/use-cases';
import { CommentBlogRepository } from '@workspace/frontend/blog/infra/repositories';
import { EnvironmentContextProps } from '@workspace/providers';
import { CommentBlogRequest } from '@workspace/frontend/blog/domain/entities';
import {IHttpPostClient} from "@workspace/frontend/http/domain/contracts/repositories";
import {AxiosHttpPostClient} from "@workspace/frontend/http/repositories/axios";

export type SendCommentFactoryProps = {
  onError?: (error) => void;
  partialEnvironment: Partial<EnvironmentContextProps>;
};

export function sendCommentFactory({ partialEnvironment, onError }: SendCommentFactoryProps) {
  const { environment } = partialEnvironment;
  const httpPostClient: IHttpPostClient<CommentBlogRequest, string> = new AxiosHttpPostClient(
    `/blog/post/comment`,
    {},
    environment.gatewayBFFBaseURL.url
  );
  const sendCommentRepository = new CommentBlogRepository(httpPostClient);
  const sendCommentUsecase = new SendCommentUsecase(sendCommentRepository, onError);

  return sendCommentUsecase;
}
