import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { ValidateRecoveryHashResponse } from '@core/domain/entities/auth/response/validate-recovery-hash-response';
import { ValidateRecoveryHashRepository } from '@core/infra/repository/auth/validate-recovery-hash-repository';
import { ValidateRecoveryHashUsecase } from '@core/data/services/validate-recovery-hash-usecase';

export type ValidateRecoveryErrorCallback = (error: ValidateRecoveryHashResponse['data']) => void;

export function validateRecoveryHashFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<{ hash: string }, ValidateRecoveryHashResponse> = new AxiosHttpPostClient<
    { hash: string },
    ValidateRecoveryHashResponse
  >('recuperarsenha/validarhash', {}, environment.gatewayBFFBaseURL.url);
  const validateRecoveryHashRepository = new ValidateRecoveryHashRepository(httpPostClient);
  const validateRecoveryHashUsecase = new ValidateRecoveryHashUsecase(validateRecoveryHashRepository);
  return validateRecoveryHashUsecase;
}
