import {
  ActionsFaqHomeLabelCreators as FaqHomeLabelCreators,
  ActionsFaqHomeTechSpec as FaqHomeActionsTags,
} from './home/actions-faq-home-techspec';

import { PageLoadFaqHomeTechSpec as FaqHomePageLoadTags } from './home/pages-faq-home-techspec';

import { registerAnalyticsEvent } from '../../analytics-config/dispatch-analytics-events';

const debug = false;
let RAE;
if (debug) RAE = console.log;
else RAE = registerAnalyticsEvent;

export const FAQHomeAnalytics = {
  PAGE: {
    LOAD: () => RAE(FaqHomePageLoadTags.P1),
    SEARCH: () => RAE(FaqHomeActionsTags.P1E1()),
    CARD: (label: string) => RAE(FaqHomeActionsTags.P1E2(FaqHomeLabelCreators.P1E2(label))),
    QUESTION: (labels: string[]) => RAE(FaqHomeActionsTags.P1E3(FaqHomeLabelCreators.P1E3(labels))),
    SEE_MORE: (label: string) => RAE(FaqHomeActionsTags.P1E4(FaqHomeLabelCreators.P1E4(label))),
  },
};
