import palette from './design-system/palette';
import breakpoints from './design-system/breakpoints';

const style = {
  palette: palette,
  breakpoints: breakpoints,
  shape: {},
  overrides: {},
};

export default style;
