import acidentesPessoais from './acidentes_pessoais.png';
import advantages from './advantages.png';
import assistencias from './assistencias.png';
import brandAndModel from './brand-and-model-icon.png';
import builds from './builds.png';
import car from './car.png';
import coberturaTotal from './cobertura_total.png';
import danosCorporais from './danos_corporais.png';
import danosTerceiros from './danos_terceiros.png';
import exclamationBalloon from './exclamation-balloon.png';
import fillHome from './fill_home.png';
import flowers from './flowers.png';
import logoAzul from './logo_azul.png';
import logoItauBitmap from './logo_itau_bitmap.png';
import logoItau from './logo_itau.png';
import logoSeguradora from './logo_seguradora.png';
import nuvem from './nuvem.png';
import peopleOnCellphone from './people-on-cellphone.png';
import people from './people.png';
import protecaoVidros from './protecao_vidros.png';
import user from './user.png';
import imagemTextoGentil from './image-texto-transito-gentil.png';
import logoTextoGentil from './logo-transito-gentil.png';
import loadingAuto from './loading-auto.gif';
import jumpingPerson from './jumping_person.png';
import exemptCarInspection from './exempt-car-inspection.png';
import bannerFAQ from './banner_faq.png';
import requiredInspectionCar from './required-car-inspection.png';
import portoInspectionLogo from './porto-inspection-logo.png';
import playStoreLogo from './play-store-logo.png';
import appStoreLogo from './app-store-logo.png';
import appStoreQRCode from './app-store-qrcode.png';
import playStoreQRCode from './play-store-qrcode.png';

export const autov2Images = {
  acidentesPessoais,
  advantages,
  assistencias,
  brandAndModel,
  builds,
  car,
  coberturaTotal,
  danosCorporais,
  danosTerceiros,
  exclamationBalloon,
  fillHome,
  flowers,
  logoAzul,
  logoItauBitmap,
  logoItau,
  logoSeguradora,
  nuvem,
  peopleOnCellphone,
  people,
  protecaoVidros,
  user,
  imagemTextoGentil,
  logoTextoGentil,
  loadingAuto,
  jumpingPerson,
  exemptCarInspection,
  bannerFAQ,
  requiredInspectionCar,
  portoInspectionLogo,
  playStoreLogo,
  appStoreLogo,
  appStoreQRCode,
  playStoreQRCode,
};
