import { put } from 'redux-saga/effects';
import { ActorDriverTypes } from '../../reducers/actor/actor-driver-reducer-handlers';

function* profileStepEffect({ cpf, contact, name, cellphone }: { cpf: string, contact: boolean, name: string, cellphone: string }) {
  yield put({ type: ActorDriverTypes.setCpf, cpf });
  yield put({ type: ActorDriverTypes.setContact, contact });
  yield put({ type: ActorDriverTypes.setName, data: name });
  yield put({ type: ActorDriverTypes.setCellphone, cellphone });
}

export default profileStepEffect;
