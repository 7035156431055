import { Quotation } from '@core/domain/entities/autov2/simulation-response';
import { formatPriceNumber } from '../utils/format-price-number';
import { AssistsDTO } from './assists-dto';
import { CoverageDTO } from './coverage-dto';
import { InsuranceAdapter } from './insurance-adapter';
import { SimulationType } from './simulation-response';

export class OfferDTO {
  static translate(offers: Quotation[]): SimulationType[] {
    return offers.map((offer) => ({
      code: offer.codigo,
      company: offer.empresa,
      startValid: offer.inicioVigencia,
      endValid: offer.fimVigencia,
      price: `R$${formatPriceNumber(offer.valorPremioFinal)}`,
      interestFreeInstallments: `R$${formatPriceNumber(parseFloat((offer.valorPremioFinal / 4).toFixed(2)))}`,
      offers: {
        coverages: offer.item ? CoverageDTO.translate(offer.item.coberturas) : [],
        assistances: offer.item ? AssistsDTO.translate(offer.item.assistencias) : [],
        insurance:
          offer.item && offer.item.vistoriaPrevia ? InsuranceAdapter.translate(offer.item.vistoriaPrevia) : null,
      },
    }));
  }
}
