import { BoxWeb, SelectOption } from '@workspace/frontend/shared/presentation/components';
import { LogoPorto } from '@web/assets';
import React from 'react';
import { CreditCardI } from './payment-methods-switcher';
import { InstallmentField } from '@web/form/component';
import { CreditCard } from '@workspace/frontend/auto/presentation/components';
import styled from 'styled-components';
import { AllowedBadgesSection } from './components/allowed-badges-section';
import { possibleBadgesArray } from '@workspace/frontend/auto/domain/entities';

const InstallmentFieldContainer = styled.div`
  width: 350px;
`;

export const creditCardPortoSectionFactory = (
  installmentNumberOptionsMock: SelectOption[],
  allowedBadges: possibleBadgesArray
) =>
  new CreditCardPortoSection(
    'credit-card-porto-seguro-payment-type',
    'cartão de crédito Porto Seguro (com mais 5% de desconto)',
    'com mais 5% de desconto',
    (
      <BoxWeb>
        <img src={LogoPorto} width={'100%'} aria-hidden="true"/>
      </BoxWeb>
    ),
    installmentNumberOptionsMock,
    (
      <BoxWeb width={'100%'} textAlign={'right'}>
        <img src={LogoPorto} height={'64px'} aria-hidden="true"/>
      </BoxWeb>
    ),
    allowedBadges
  );

class CreditCardPortoSection implements CreditCardI {
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public renderIcon: JSX.Element,
    public installments: any,
    public renderBadge?: any,
    public allowedBadges?: possibleBadgesArray
  ) {}

  //seção do cartao porto

  render() {
    return (
      <>
        <AllowedBadgesSection allowedBadges={this.allowedBadges} />
        <CreditCard renderBadge={this.renderBadge} allowedBadges={this.allowedBadges} />
        <BoxWeb height={36} />
        <InstallmentFieldContainer>
          <InstallmentField options={this.installments} />
        </InstallmentFieldContainer>
      </>
    );
  }
}
