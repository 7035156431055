import { useEffect, useState } from "react";

function useOriginAndProductSession() {
  const [originSessionStore, setOriginSessionStore] = useState<string>('')
  const [productSessionStore, setProductSessionStore] = useState<string>('')

  useEffect(() => {
    const originAndProductSessionStore = JSON.parse(sessionStorage.getItem('auth:register:ga'));
    setProductSessionStore(originAndProductSessionStore?.product);
    setOriginSessionStore(originAndProductSessionStore?.origin);
  }, []);

  const removeOriginAndProductSession = () => {
    sessionStorage.removeItem('auth:register:ga');
  };

  const setOriginAndProductSession = (tagParams: {origin: string, product: string}) => {
    removeOriginAndProductSession();
    sessionStorage.setItem('auth:register:ga', JSON.stringify({origin: tagParams?.origin, product: tagParams?.product}))

  };

  return {originSessionStore, productSessionStore, removeOriginAndProductSession, setOriginAndProductSession}
}

export default useOriginAndProductSession;
