import { Route } from 'react-router-dom';
import { Autov2Carrier } from '../containers/home/autov2-home-carrier';
import { AutoV2HiringCarrier } from '../containers/hiring/autov2-hiring-carrier';
import { AutoV2SuccessCarrier } from '../containers/success/autov2-success-carrier';

export const AutoV2Home = ({ origin }) => <Autov2Carrier partner={origin} />;
export const AutoV2Hiring = ({ origin }) => <AutoV2HiringCarrier partner={origin} />;
export const AutoV2Success = ({ origin }) => <AutoV2SuccessCarrier partner={origin} />;

export const AutoRouter = function ({ origin, prefixPath = '/autov2' }) {
  return (
    <>
      <Home prefixPath={prefixPath} origin={origin} />
      <Hiring prefixPath={prefixPath} origin={origin} />
      <Success prefixPath={prefixPath} origin={origin} />
    </>
  );
};

const Home = ({ prefixPath, origin }) => {
  return <Route exact path={prefixPath} render={() => <AutoV2Home origin={origin} />} />;
};
const Hiring = ({ prefixPath, origin }) => (
  <Route exact path={`${prefixPath}/contratacao`} render={() => <AutoV2Hiring origin={origin} />} />
);
const Success = ({ prefixPath, origin }) => (
  <Route exact path={`${prefixPath}/sucesso`} render={() => <AutoV2Success origin={origin} />} />
);
