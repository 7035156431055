import { BoxWeb } from '@workspace/frontend/shared/presentation/components';
import React from 'react';
import { Amex, Aura, Diners, Elo, MasterCard, Visa } from '@web/assets';
import { possibleBadges, possibleBadgesArray } from '@workspace/frontend/auto/domain/entities';

const availableBadges = {
  MASTERCARD: { src: MasterCard, ariaLabel: 'bandeira mastercard' },
  VISA: { src: Visa, ariaLabel: 'bandeira visa' },
  AMEX: { src: Amex, ariaLabel: 'bandeira amex' },
  DINERS: { src: Diners, ariaLabel: 'bandeira diners' },
  AURA: { src: Aura, ariaLabel: 'bandeira aura' },
  ELO: { src: Elo, ariaLabel: 'bandeira elo' },
};

export const AllowedBadgesSection = ({
  allowedBadges = [
    possibleBadges.AURA,
    possibleBadges.AMEX,
    possibleBadges.MASTERCARD,
    possibleBadges.DINERS,
    possibleBadges.ELO,
    possibleBadges.VISA,
  ],
}: {
  allowedBadges?: possibleBadgesArray;
}) => {
  return (
    <>
      <BoxWeb height={16} />
      <BoxWeb fontSize={'16px'} maxWidth={'500px'}>
        aceitamos várias bandeiras de cartão de crédito e débito (cartões gerados virtualmente não são aceitos)
      </BoxWeb>
      <BoxWeb display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {allowedBadges.map((badge) => (
          <BoxWeb maxWidth={'60px'} height={'fit-content'} margin={'0.5rem'}>
            <img
              src={availableBadges[badge].src}
              alt={availableBadges[badge].ariaLabel}
              width={'100%'}
              height={'100%'}
            />
          </BoxWeb>
        ))}
      </BoxWeb>
      <BoxWeb height={8} />
    </>
  );
};
