import { FaqProducts } from './faq-products';
import {
  getFAQProductFactory,
  getFAQQuestionFactory,
  getFAQTopicFactory,
} from '@workspace/frontend/faq-v2/infra/factories';
import { useEffect, useState } from 'react';
import { FAQV2StateManagerSingleton as FAQV2StateManager } from '@workspace/frontend-shared-application-state-manager';
import { Product, Question, Topic } from '@workspace/frontend/faq-v2/domain/entities';
import { BoxWeb, LoadingWeb } from '@workspace/frontend/shared/presentation/components';
import { FaqRouteEnum } from '../../enums/faq-routes-enum';
import { ANALYTICS_FAQ } from '@workspace/web/analytics';

export function FaqProductsContainer() {
  const [loading, setLoading] = useState<boolean>(true);

  const [products, setProducts] = useState<Product[]>([] as Product[]);
  const [topics, setTopics] = useState<Topic[]>([] as Topic[]);
  const [questions, setQuestions] = useState<Question[]>([] as Question[]);

  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([] as Topic[]);
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([] as Question[]);
  const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product);

  const getFAQProduct = getFAQProductFactory();
  const getFAQTopic = getFAQTopicFactory();
  const getFAQQuestion = getFAQQuestionFactory();

  const retrieveContent = async () => {
    const topicsFromApi = await getFAQTopic.execute();
    const questionsFromApi = await getFAQQuestion.execute();
    const productsFromApi = await getFAQProduct.execute();

    setTopics(topicsFromApi);
    setQuestions(questionsFromApi);
    setProducts(productsFromApi);
  };

  const analyticsFaq = ANALYTICS_FAQ.HOME.PAGE;

  useEffect(() => {
    analyticsFaq.LOAD();
    (async () => {
      await retrieveContent();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    selectPrimaryProduct();
  }, [products]);

  useEffect(() => {
    // TODO: we need to do this when page unmounts, but as long as frontend-evolucao and frontend-base are separate, it won't be possible
    persistValuesToStorage();
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct.slug) analyticsFaq.CARD(selectedProduct.slug);
  }, [selectedProduct]);

  const persistValuesToStorage = () => {
    FAQV2StateManager.faqProductsDispatcher({
      topics: selectedTopics,
      products,
      questions: selectedQuestions,
      selectedProduct,
    });
  };

  const handleLink = (selectedProduct?, question?) => {
    return (
      FaqRouteEnum.QUESTIONS +
      (selectedProduct ? `?produto=${selectedProduct.slug.replace(' ', '_')}` : '') +
      (question?.uid ? `#${question.uid?.toLowerCase().replace('?', '').split(' ').join('-')}` : '')
    );
  };

  const handleSelectProduct = (selectedProduct: Product) => {
    const { uid } = selectedProduct;
    setSelectedProduct(selectedProduct);

    if (topics.length) {
      const filteredFaqTopics = topics.filter(({ produto }) => produto[0].uid === uid);
      setSelectedTopics(filteredFaqTopics);
    }

    if (questions.length) {
      const filteredFaqQuestions = questions.filter(({ produto }) => produto[0].uid === uid);
      setSelectedQuestions(filteredFaqQuestions);
    }
  };

  const selectPrimaryProduct = () => {
    if (products.length) {
      const primaryProduct = products.filter(({ posicao }) => posicao === 1)[0];
      handleSelectProduct(primaryProduct);
    }
  };

  if (loading)
    return (
      <BoxWeb height={'100%'} minHeight={'400px'} display={'flex'} alignItems={'center'}>
        <LoadingWeb />
      </BoxWeb>
    );

  const handleProductPageLink = (productTitle: string) => {
    return `ver todas as dúvidas em ${productTitle}`
  };


  return (
    <FaqProducts
      products={products}
      questions={selectedQuestions}
      topics={selectedTopics}
      handleLink={handleLink}
      handleSelectProduct={handleSelectProduct}
      selectedProduct={selectedProduct}
      handleProductPageLink={handleProductPageLink}
    />
  );
}
