import { UserAuthResponse } from '../../domain/entities/auth/response/user';
import { IGetUserByEmailUsecase } from '../../domain/usecases/get-user-by-email-usecase';
import { IGetUserByEmailRepository } from '../contracts/auth/get-user-by-email';

export class GetUserByEmailUsecase implements IGetUserByEmailUsecase {
  constructor(private readonly getUserByEmailRepository: IGetUserByEmailRepository) {}

  async execute(email: string, headers?: any): Promise<UserAuthResponse> {
    return await this.getUserByEmailRepository.execute(email, headers);
  }
}
