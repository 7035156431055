import { useMemo } from 'react';
import { styled, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DefaultButtonProps } from '../../models/default-button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const ContainedVariantStyle = (theme) => ({
  root: () => {
    return {
      width: '100%',
      height: 48,
      color: '#fff',
      fontSize: '19px',
      textTransform: 'lowercase',
      background: theme.palette.primary.main,
      borderRadius: 4,
      lineHeight: '1rem',
      '&:hover': {
        background: theme.palette.primary.light,
        '&:disabled': {
          backgroundColor: 'rgb(217, 211, 207)',
        },
      },
      '&:disabled': {
        backgroundColor: '#D9D3CF',
        '& .MuiButton-label': {
          color: '#969290',
        },
      },
      '& .MuiButton-label': {
        color: theme.palette.primary.contrastText,
      },
    };
  },
  label: {
    lineHeight: '1.25rem',
  },
});

const buttonVariantStyles = {
  contained: ContainedVariantStyle,
  outlined: ContainedVariantStyle,
};

export const ButtonMui = styled(
  ({ type, label, loading, disabled, variant = 'contained', ...other }: DefaultButtonProps) => {
    const VariantButton = useMemo(() => withStyles(buttonVariantStyles[variant])(Button), [variant]);

    return (
      <VariantButton
        {...other}
        disabled={disabled}
        startIcon={loading && <CircularProgress color="inherit" size={32} />}
        type={type}
        fullWidth
        disableFocusRipple
      >
        {label}
      </VariantButton>
    );
  }
)(() => ({
  fontFamily: 'ItauDisplay-Bold',
  fontWeight: 700,
}));
