import { InputWeb } from '@workspace/frontend/shared/presentation/components';
import { FormControllerFieldConnector } from '@web/form';
import { codeConfirmationValidation } from '@workspace/frontend-shared-application-validation';
import { useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export const CodeConfirmationField = ({ onError }: { onError?: (errorMessage: string) => void }) => {
  const { errors } = useFormState();
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      onError(errors['code']['message']);
    }
  }, [errors]);

  return (
    <FormControllerFieldConnector
      component={InputWeb}
      name="code"
      validation={codeConfirmationValidation()}
      componentProps={{
        id: 'txtConfirmCode_screenCodeConfirmation',
        label: 'digite o código',
        type: 'tel',
        pattern: '\\d*',
      }}
    />
  );
};
