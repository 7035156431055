import { ItauIcon } from '@workspace/frontend/shared/presentation/components';

export const InputError = ({ error, inputName }: any) => {
  const inputRef = document.getElementsByName(inputName)[0];
  const handleClick = () => inputRef.focus();

  return (
    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
      <ItauIcon iconName="exclamacao" ariaHidden={true} style={{ marginRight: '8px', verticalAlign: 'text-bottom' }} />
      {error}
    </span>
  );
};
