export default {
  primary: {
    main: '#F36D00',
    contrastText: '#FFF',
    light: 'rgb(221, 118, 45)',
  },
  secondary: {
    main: '#5397C6',
    contrastText: '#FFF',
    light: 'rgba(236,112,0, 0.75)',
    dark: '#004990',
  },
  text: {
    primary: '#2b374a',
    secondary: '#000000',
    error: 'red',
  },
  link: {
    primary: '#0C5599',
  },
  success: {
    main: '#007A47',
  },
  background: {
    lightMain: '#FFDAB8',
    lightSecondary: '#EFE9E5',
    gray: '#F5F5F5',
  },
};
