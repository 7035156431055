import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RouteEnum } from '../../enums/route-enum';
import { ConfirmAuthToken } from '../confirm-token';
import { PasswordRecovery } from '../recovery';
import { Register } from '../register';
import { RegisteredUser } from '../registered-user';
import { Auth } from '../auth';

export type AuthBaseProps = {
  origin?: string;
  product?: string;
  onsuccesslogincallbackname?: string;
  onbackeventname?: string;
};

export function AuthBase({ onbackeventname, onsuccesslogincallbackname, origin, product }: AuthBaseProps) {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={RouteEnum.LOGIN}
          render={() => (
            <Auth
              origin={origin}
              product={product}
              onsuccesslogincallbackname={onsuccesslogincallbackname}
              onbackeventname={onbackeventname}
            />
          )}
        />
        <Route path={RouteEnum.REGISTER} render={() => <Register product={product} origin={origin} />} />;
        <Route
          path={RouteEnum.USER_ALREADY_REGISTERED}
          render={() => <RegisteredUser product={product} origin={origin} />}
        />
        <Route path={RouteEnum.CONFIRM_TOKEN} render={() => <ConfirmAuthToken origin={origin} product={product} />} />
        <Route
          path={RouteEnum.RECOVERY_PASSWORD}
          render={() => <PasswordRecovery origin={origin} product={product} />}
        />
      </Switch>
    </BrowserRouter>
  );
}
