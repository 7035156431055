import { createContext, useContext } from 'react';

const OriginContext = createContext({ origin: 'loja' });

export const useOriginContext = () => {
  return useContext(OriginContext)?.origin;
};

export function AutoV2OriginProvider({ children, partner = 'loja' }) {
  return <OriginContext.Provider value={{ origin: partner }}>{children}</OriginContext.Provider>;
}
