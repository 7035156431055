import { DivergentDataQuotationHolderContainer } from './divergente-data-quotation-holder-container';
import { createContext, useContext } from 'react';

const OriginContext = createContext({ origin: 'loja' });

export const useOriginContext = () => {
  return useContext(OriginContext)?.origin;
};

export function DivergentDataQuotationHolderOriginProvider({ children, partner = 'loja' }) {
  return <OriginContext.Provider value={{ origin: partner }}>{children}</OriginContext.Provider>;
}

export const DivergentDataQuotationHolderCarrier = ({
  origin,
  product,
  oncontinuedivergentquotationholdername,
  onbackdivergentquotationholdername,
}: {
  origin?: string;
  product?: string;
  oncontinuedivergentquotationholdername?: string;
  onbackdivergentquotationholdername?: string;
}) => {
  const createCallBackFromEvent = (eventName: string) => {
    const evt = new CustomEvent(eventName);
    window.dispatchEvent(evt);
  };
  const onContinueDivergentQuotationHolder = () => createCallBackFromEvent(oncontinuedivergentquotationholdername);
  const onBackDivergentQuotationHolderName = () => createCallBackFromEvent(onbackdivergentquotationholdername);

  return (
    <DivergentDataQuotationHolderOriginProvider>
      <DivergentDataQuotationHolderContainer onContinueDivergentQuotationHolder={onContinueDivergentQuotationHolder} onBackDivergentQuotationHolderName={onBackDivergentQuotationHolderName}/>
    </DivergentDataQuotationHolderOriginProvider>
  );
};
