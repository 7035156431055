import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { FindUserByCPFRepository } from '@core/infra/repository/auth/find-user-by-cpf-repository';
import { FindUserByCPFUsecase } from '@core/data/services/find-user-by-cpf-usecase';
import { CPFResponse } from '@core/domain/entities/auth/response/cpf';
import { UserByCPF } from '@core/infra/models/user-by-cpf';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';

export function findUserByCPFFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<UserByCPF, CPFResponse> = new AxiosHttpPostClient<UserByCPF, CPFResponse>(
    'verificausuarioexiste',
    {},
    environment.gatewayBFFBaseURL.url
  );
  const findUserByCPFRepository = new FindUserByCPFRepository(httpPostClient);
  const findUserByCPFUseCase = new FindUserByCPFUsecase(findUserByCPFRepository);
  return findUserByCPFUseCase;
}
