import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function TooltipWeb(props) {
  const { Components } = useThemeProvider();
  const { Tooltip } = Components;
  return (
    <ThemeProviderInjector>
      <Tooltip {...props}>{props.children}</Tooltip>
    </ThemeProviderInjector>
  );
}
