import { FaqSearches } from './faq-searches';
import { getFAQSearchQuestionsFactory } from '@workspace/frontend/faq-v2/infra/factories';
import { useEffect, useState } from 'react';

export function FaqSearchesContainer() {
  const selectedTermFromUrl = decodeURI(window.location.search.replace('?termo=', ''));
  const getFAQSearchQuestions = getFAQSearchQuestionsFactory(selectedTermFromUrl);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const retrieveContent = async () => {
    const questionsFromApi = await getFAQSearchQuestions.execute();
    setQuestions(questionsFromApi);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await retrieveContent();
    })();
  }, []);

  const breadcrumbsPages = [
    {
      name: 'início',
      href: '/v2/ajuda/produtos'
    },
    {
      name: 'busca',
      href: ''
    }
  ];

  return (
    <FaqSearches
      breadcrumbsPages={breadcrumbsPages}
      questions={questions}
      selectedTermFromUrl={selectedTermFromUrl}
      loading={loading}
    />
  );

};
