type Rule = {
  label: string;
  rule: (value: string) => boolean;
  aria?: string;
};

type MissingRulesProps = { prefix?: string; missing: Array<Omit<Rule, 'rule'>> };

export const MissingRules = ({ prefix, missing }: MissingRulesProps) => {
  const capitalize = (text: string) => text.replace(/^\w/, (c) => c.toUpperCase());

  const RuleComponent = ({ aria, label }) => (
    <span>
      <span aria-label={aria} style={{ width: 0, height: 0, opacity: 0, position: 'absolute' }}>
        {aria}
      </span>
      <span aria-hidden={Boolean(aria)}>{label}</span>
    </span>
  );

  const missingRulesFactory = missing.map((item, index, missingArray) => {
    const label = index === missingArray.length - 1 ? item.label : `${item.label}, `;

    if (index === 0) return <RuleComponent aria="" label={capitalize(label)} />;

    if (item?.aria) return <RuleComponent aria={item.aria} label={label} />;

    return <RuleComponent aria="" label={label} />;
  });

  return (
    <>
      {prefix} {missingRulesFactory}
    </>
  );
};

MissingRules.defaultProps = {
  prefix: 'A sua senha deve: ',
};
