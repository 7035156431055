import { rest } from 'msw';

export const searchCities = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/estados/municipios`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          id_municipio: 1200013,
          nome_formatado: 'ACRELANDIA',
          nome: 'Acrelândia',
        },
        {
          id_municipio: 1200054,
          nome_formatado: 'ASSIS BRASIL',
          nome: 'Assis Brasil',
        },
        {
          id_municipio: 1200104,
          nome_formatado: 'BRASILEIA',
          nome: 'Brasiléia',
        },
        {
          id_municipio: 1200138,
          nome_formatado: 'BUJARI',
          nome: 'Bujari',
        },
        {
          id_municipio: 1200179,
          nome_formatado: 'CAPIXABA',
          nome: 'Capixaba',
        },
        {
          id_municipio: 1200203,
          nome_formatado: 'CRUZEIRO DO SUL',
          nome: 'Cruzeiro do Sul',
        },
        {
          id_municipio: 1200252,
          nome_formatado: 'EPITACIOLANDIA',
          nome: 'Epitaciolândia',
        },
        {
          id_municipio: 1200302,
          nome_formatado: 'FEIJO',
          nome: 'Feijó',
        },
        {
          id_municipio: 1200328,
          nome_formatado: 'JORDAO',
          nome: 'Jordão',
        },
        {
          id_municipio: 1200336,
          nome_formatado: 'MANCIO LIMA',
          nome: 'Mâncio Lima',
        },
        {
          id_municipio: 1200344,
          nome_formatado: 'MANOEL URBANO',
          nome: 'Manoel Urbano',
        },
        {
          id_municipio: 1200351,
          nome_formatado: 'MARECHAL THAUMATURGO',
          nome: 'Marechal Thaumaturgo',
        },
        {
          id_municipio: 1200385,
          nome_formatado: 'PLACIDO DE CASTRO',
          nome: 'Plácido de Castro',
        },
        {
          id_municipio: 1200807,
          nome_formatado: 'PORTO ACRE',
          nome: 'Porto Acre',
        },
        {
          id_municipio: 1200393,
          nome_formatado: 'PORTO WALTER',
          nome: 'Porto Walter',
        },
        {
          id_municipio: 1200401,
          nome_formatado: 'RIO BRANCO',
          nome: 'Rio Branco',
        },
        {
          id_municipio: 1200427,
          nome_formatado: 'RODRIGUES ALVES',
          nome: 'Rodrigues Alves',
        },
        {
          id_municipio: 1200435,
          nome_formatado: 'SANTA ROSA DO PURUS',
          nome: 'Santa Rosa do Purus',
        },
        {
          id_municipio: 1200500,
          nome_formatado: 'SENA MADUREIRA',
          nome: 'Sena Madureira',
        },
        {
          id_municipio: 1200450,
          nome_formatado: 'SENADOR GUIOMARD',
          nome: 'Senador Guiomard',
        },
        {
          id_municipio: 1200609,
          nome_formatado: 'TARAUACA',
          nome: 'Tarauacá',
        },
        {
          id_municipio: 1200708,
          nome_formatado: 'XAPURI',
          nome: 'Xapuri',
        },
      ])
    )
  );
};
