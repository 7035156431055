import {
  IStringValidationFactory,
  stringValidationFactory,
} from './../validation-generators/string-validation-factory';

export const confirmPasswordValidation = (overrides: IStringValidationFactory = {}) => {
  const defaultValues = {
    requiredMessage: 'campo obrigatório',
    test: [
      {
        name: 'passwords-match',
        message: 'as senhas precisam ser iguais',
        test: function (value) {
          return this.parent.senha === value;
        },
      },
    ],
  };

  const values: IStringValidationFactory = Object.assign(defaultValues, overrides);

  return stringValidationFactory(values);
};
