import { Question } from '@workspace/frontend/faq-v2/domain/entities';
import {
  AccordionWeb,
  BoxWeb,
  BreadCrumbsWeb,
  LoadingWeb,
  PageProperties,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { DesktopContent, QuestionSection, SearchTitle, Title } from './faq-searches-styles';
import { Feedback } from '../questions/faq-questions-styles';
import parse from 'html-react-parser';
import { Divider } from '@material-ui/core';
import { useRef } from 'react';
import { FeedbackButtons } from '@workspace/frontend/faq-v2/presentation/components';

const questionIdFormatter = (question: Question) => question.uid;

const defineStyleOnBreakpoint = (theme, md, sm) => {
  return theme.breakpoints.values.sm > document.body.clientWidth ? sm : md;
};

interface QuestionsProps {
  questions: Question[];
  breadcrumbsPages: PageProperties[];
  selectedTermFromUrl: string;
  loading: boolean;
}

const isEmpty = (param) => {
  try {
    const props = Object.keys(param);
    return props.length <= 0;
  } catch (error) {
    return true;
  }
};

export function FaqSearches({ questions, breadcrumbsPages, selectedTermFromUrl, loading }: QuestionsProps) {
  const { Theme } = useThemeProvider();
  const likeButton = useRef();
  const dislikeButton = useRef();

  return (
    <SCThemeProvider theme={Theme}>
      <BoxWeb
        ml={'auto'}
        mr={'auto'}
        padding={{ xs: '16px 24px 32px', sm: '16px 24px 32px', md: '16px 24px 32px' }}
        maxWidth={{ md: '1072px' }}
      >
        <DesktopContent>
          <BoxWeb mt={'4px'}>
            <BreadCrumbsWeb
              linkTarget={'_self'}
              pages={breadcrumbsPages}
              style={{ fontWeight: 700, fontSize: '14px', lineHeight: '18px' }}
            />
          </BoxWeb>
        </DesktopContent>

        {loading ? (
          <BoxWeb height={'100%'} minHeight={'400px'} display={'flex'} alignItems={'center'}>
            <LoadingWeb />
          </BoxWeb>
        ) : (
          <>
            <BoxWeb mt={defineStyleOnBreakpoint(Theme, '36px', '4px')}>
              <Title>
                {isEmpty(questions) ? 'não encontramos resultados para: ' : 'resultado de busca para: '}
                <SearchTitle>{selectedTermFromUrl}</SearchTitle>
              </Title>
            </BoxWeb>

            <QuestionSection>
              {
                <BoxWeb>
                  {questions.map((question) => (
                    <>
                      <AccordionWeb
                        arrowPosition={'right'}
                        id={questionIdFormatter(question)}
                        title={question.title}
                        detailsBgColor={'#FAF7F5'}
                        fontFamily={'Itau Text'}
                        fontSize={defineStyleOnBreakpoint(Theme, '16px', '14px')}
                        fontWeight={400}
                      >
                        <BoxWeb mr={'1.5rem'} mt={'8px'}>
                          <BoxWeb
                            fontSize={defineStyleOnBreakpoint(Theme, '16px', '14px')}
                            lineHeight={defineStyleOnBreakpoint(Theme, '24px', '20px')}
                            fontFamily={'ItauText-Regular'}
                          >
                            {parse(question.resposta)}
                          </BoxWeb>
                          <BoxWeb display={'flex'} alignItems={'center'}>
                            <Feedback>Encontrou o que estava procurando?</Feedback>

                            <FeedbackButtons
                              product={null}
                              topic={null}
                              question={question}
                              refLikeButton={likeButton}
                              refDislikeButton={dislikeButton}
                            />
                          </BoxWeb>
                        </BoxWeb>
                      </AccordionWeb>
                      <Divider />
                    </>
                  ))}
                </BoxWeb>
              }
            </QuestionSection>
          </>
        )}
      </BoxWeb>
    </SCThemeProvider>
  );
}
