import React, { useEffect, useState } from 'react';
import { BoxWeb, MarkdownWeb } from '@workspace/frontend/shared/presentation/components';
import { Home } from '@core/domain/entities/dynamic-landings/dynamic-landpage';
import styled from 'styled-components';

type TitleDynamicProps = {
  home: Home;
  headingLevel?: number;
};

type ElemType = {
  url: string;
  alt: string;
};

const Image = styled.img`
  max-height: 50px;
`;
export const TitleDynamic = ({ home, headingLevel }: TitleDynamicProps) => {
  const [infoPartners, setInfoPartners] = useState<{ url: string; alt: string }[]>([{ url: '', alt: '' }]);

  useEffect(() => {
    const combined: { url: string; alt: string }[] = home?.partners_image?.map((elem: any, idx: number) => ({
      url: elem?.url,
      alt: home?.partners_alt[0],
    }));
    setInfoPartners(combined);
  }, []);

  return (
    <BoxWeb
      maxWidth="36rem"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      ml={{ xs: 0, sm: '5rem', md: '5rem' }}
    >
      <BoxWeb
        id="lblSubtitle_screenLanding"
        fontFamily="ItauDisplay-Regular"
        fontSize={'40px'}
        width="100%"
        height={'auto'}
        role={'heading'}
        aria-level={headingLevel ? headingLevel : 2}
      >
        {home?.subtitle}
      </BoxWeb>
      <BoxWeb pt={'2rem'} fontFamily="ItauDisplay-Regular" fontSize={'20px'}>
        <MarkdownWeb>{home?.description}</MarkdownWeb>
      </BoxWeb>
      <BoxWeb
        aria-hidden
        mb={{ xs: '2rem', sm: 0 }}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        alignItems="center"
        width={{ xs: 1, sm: '400px', md: '450px', lg: '470px', xl: '700px' }}
      >
        {infoPartners.map((elem: ElemType, idx: number) => {
          return (
            <BoxWeb key={idx} mr={'1rem'}>
              <Image alt={`${elem.alt} ${idx + 1}`} src={elem.url} />
            </BoxWeb>
          );
        })}
      </BoxWeb>
    </BoxWeb>
  );
};
