import axios, { AxiosInstance } from 'axios';;
export class AxiosBase {

  protected axiosInstance: AxiosInstance;

  constructor(
    protected readonly baseURL?: string,
    protected readonly customResponseInterceptorCallback?: (error: any) => any
  ) {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL
    })

    if (customResponseInterceptorCallback) {
      this.applyResponseInterceptor();
    }

    this.applyRequestInterceptor();
    this.applyResponseInterceptor();
  }

  applyResponseCustomInterceptor() {
    this.axiosInstance.interceptors.request.use(defaultRequestInterceptor, this.customResponseInterceptorCallback)
  }

  applyRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(defaultRequestInterceptor, callbackRequestError);
  }

  applyResponseInterceptor() {
    this.axiosInstance.interceptors.request.use(defaultResponseInterceptor, callbackResponseError);
  }
}

const callbackRequestError = (error) => Promise.reject(error);

const callbackResponseError = (error) => Promise.reject(error);

const defaultRequestInterceptor = config => {
  // config.headers.common['Cache-Control'] = 'no-cache';
  return config;
};

const defaultResponseInterceptor = response => response;
