import { proposalHandlers } from './proposal/proposal-handler';
import { apiReducerHandlers } from './api/api-reducer-handlers';
import { Immutable } from 'immer';
import proponentHandlers from './actor/actor-proponent-reducer-handlers';
import driverHandlers from './actor/actor-driver-reducer-handlers';
import itemRiskAutomobileHandlers from './itemRisk/item-risk-automobile-handlers';
import { offerHandlers } from './offer/offerHandlers';
import { stepperHandlers } from './stepper/stepperHandlers';
import { AnyAction } from 'redux';
import { globalHandlers } from './global-handlers';
import { handlers as faqV2Handlers } from './faq-v2/faq-v2-reducer-handlers';
import { rootInitialValues } from './rootInitialValues';

function rootReducer(state = rootInitialValues, action: AnyAction) {
  const handlers = Object.assign(
    {},
    proponentHandlers,
    driverHandlers,
    itemRiskAutomobileHandlers,
    offerHandlers,
    stepperHandlers,
    apiReducerHandlers,
    globalHandlers,
    proposalHandlers,
    faqV2Handlers
  );

  const reducer: Immutable<any> = Object.entries(handlers).reduce((acc, [key, value]) => {
    return key === action.type ? value : acc;
  }, null);

  if (reducer) return reducer(state, action);
  return state;
}

export default rootReducer;
