import { rest } from 'msw';

export const cancellationHiringDetailMock = (env) => {
  // https://mktseguros-api.dev.cloud.itau.com.br/v1/mkt-seguros-e2e/contratacoes/:idProposal
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/contratacoes/:idProposal`, (req, res, ctx) => {
    if (req.params.plano_odontologico)
      return res(
        ctx.status(200),
        ctx.json({
          plano_odontologico: true,
          status: 'ATIVO',
          id_contratacao: '9039758',
          numero_contratacao: '9039758',
          nome_produto: 'ODONTO METLIFE DIAMOND',
          valor_parcela: 279.9,
          proponente: {
            nome: 'John Doe',
            cpf: '38035125818',
            email: 'john.doe@zup.com.br',
            telefone: '19981317922',
          },
          data_contratacao: '2020-04-06T00:00:00',
          quantidade_beneficiarios: 1,
          id_produto_externo: 1040,
          id_parceiro: 1,
        })
      );
    return res(
      ctx.status(200),
      ctx.json({
        plano_odontologico: false,
        status: 'ATIVO',
        id_contratacao: '7009fe30-b118-4e99-b7d6-b96f80d73e0b',
        numero_contratacao: '51069964',
        nome_produto: 'assistência Realiza bem-estar',
        valor_parcela: 137.9,
        parcelas: 1,
        proponente: {
          nome: 'John Doe',
          cpf: '38035125818',
          email: 'john.doe@itau-unibanco.com.br',
          telefone: '11994997805',
        },
        inicio_vigencia: '2020-04-23',
      })
    );
  });
};
