import {
  IStringValidationFactory,
  stringValidationFactory,
  StringValidationSchema,
} from '../validation-generators/string-validation-factory';
import { NAME_REGEX } from '../regex';

export const nameValidation = (overrides: IStringValidationFactory = {}): StringValidationSchema => {
  const defaultValues = {
    requiredMessage: 'você precisa informar o nome completo (sem abreviações)',
    matches: {
      pattern: NAME_REGEX,
      message: 'verifique seu nome e sobrenome, e se não existe mais de um espaço entre eles.',
    },
  };

  const values: IStringValidationFactory = Object.assign(defaultValues, overrides);

  return stringValidationFactory(values);
};
