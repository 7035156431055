import React from 'react';
import { autov2Images, translateToMicrofrontendPath } from '@web/assets';

function LoadingAutoImage({}) {
  return (
    <div>
      <img src={translateToMicrofrontendPath(autov2Images.loadingAuto)} alt={'um carro correndo'} />
    </div>
  );
}

export default LoadingAutoImage;
