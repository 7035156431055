import { FormControllerFieldConnector, useFormControllerMethods } from '@web/form';
import React, { useMemo } from 'react';
import {
  BoxWeb,
  RadioGroupProps,
  RadioGroupWeb,
  SelectOption,
} from '@workspace/frontend/shared/presentation/components';
import { stringValidationFactory } from '@workspace/frontend-shared-application-validation';
import styled from 'styled-components';

export function PaymentMethodsSwitcher({ paymentMethods }: any) {
  const { watch } = useFormControllerMethods();

  const dependencyWatcher = watch('paymentMethod');
  const MemoizedComponents = useMemo(
    () => paymentMethods.map((i, idx) => dependencyWatcher == idx + 1 && paymentMethods[idx].render()),
    [dependencyWatcher]
  );

  return (
    <>
      <BoxWeb display="flex" flexDirection="column">
        <BoxWeb display="flex" justifyContent="space-between">
          <FormControllerFieldConnector<RadioGroupProps>
            component={RadioGroupWeb}
            name={'paymentMethod'}
            validation={stringValidationFactory({ requiredMessage: 'você precisa informar a forma de pagamento' })}
            componentProps={{
              labelPlacement: 'end',
              row: false,
              defaultValue: '2',
              options: paymentMethods.map((i, idx) => ({
                value: (idx + 1).toString(),
                label: i.title,
                id: i.id,
              })),
            }}
          />
        </BoxWeb>
        {MemoizedComponents}
      </BoxWeb>
    </>
  );
}

export interface PaymentMethod {
  title: string;
  description: string;
  renderIcon: JSX.Element;
  installments?: SelectOption[];
  render: () => JSX.Element;
}

/**
 * renderBadge : component to replace teh default badge
 */
export interface CreditCardI extends PaymentMethod {
  renderBadge?: any;
}

export interface DebitI extends PaymentMethod {
  bankOptions: SelectOption[];
  paymentDayOptions: SelectOption[];
}
