import { IGetUserInfoUsecase } from '../../domain/usecases/get-user-info-usecase';
import { IGetUserInfoRepository } from '../../data/contracts/my-account/get-user-info-repository';
import { GetUserInfoResponse } from '@core/domain/entities/my-account/response/get-user-info-response';

export class GetUserInfoUsecase implements IGetUserInfoUsecase {
  constructor(private readonly getUserInfoRepository: IGetUserInfoRepository) {}

  async execute(string, headers?: any): Promise<GetUserInfoResponse> {
    return await this.getUserInfoRepository.execute(string, headers);
  }
}
