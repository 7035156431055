import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Buttons = ({ type = 'submit' as const }) => {
  const history = useHistory();
  const onBack = () => {
    history.push('/');
  };
  return (
    <ButtonContainer>
      <BackButton onClick={onBack}> voltar </BackButton>
      <SaveButton type={type}>salvar alterações</SaveButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: space-around;
  @media (min-width: 960px) {
    display: block;
  }
`;
const BackButton = styled.button`
  background: #fff;
  border: 1px solid #106eb0;
  border-radius: 4px;
  color: #106eb0;
  cursor: pointer;
  font-family: 'ItauDisplay-Bold';
  font-size: 19px;
  height: 48px;
  min-width: 40%;
  &:hover {
    background: rgba(16, 110, 176, 0.04);
  }
  @media (min-width: 960px) {
    margin: 0 10px;
    min-width: 183px;
  }
`;
const SaveButton = styled.button`
  background: rgb(236, 112, 0);
  border: none;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'ItauDisplay-Bold';
  font-size: 19px;
  height: 48px;
  min-width: 55%;
  &:hover {
    background: rgb(221, 118, 45);
  }
  @media (min-width: 960px) {
    min-width: 183px;
  }
`;
export default Buttons;
