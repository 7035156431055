import { Box, LinearProgress, Step, StepButton, StepLabel, Stepper, withStyles } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultStepperProps } from '../../models/default-stepper';
import MobileStepper from '@material-ui/core/MobileStepper';
import useTheme from '@material-ui/core/styles/useTheme';

const CustomConnector = withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: 'transparent',
      fontFamily: theme.typography.fontWeightBold,
    },
  },
  completed: {
    '& $line': {
      borderColor: 'transparent',
    },
  },
  line: {
    borderColor: 'transparent',
    borderTopWidth: 0,
    borderRadius: 0,
  },
}))(StepConnector);

const CustomStepper = withStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
}))(Stepper);

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return width;
}

export const StepperDesktop = ({ steps, activeStep }: DefaultStepperProps) => {
  const width = useWindowWidth();
  const stepperRef = useRef(undefined);

  useEffect(() => {
    const protectedKey = steps[activeStep].protectedStep;
    if (protectedKey && protectedKey.isRejected) {
      protectedKey.onRejected();
    }
  }, []);

  const handleProgressValue = () => {
    const maxWidth = 1104;
    if (width <= maxWidth) {
      return ((activeStep + 1) / steps.length) * 100;
    }

    const progressPlace = (maxWidth / steps.length) * (activeStep + 1);

    return (((width - maxWidth) / 2 + progressPlace) * 100) / width;
  };
  const isActiveStep = (index) => index === activeStep;

  let stepCount = 0;
  const getWizardStepAriaLabel = (step, index) => {
    stepCount += 1;

    if (index < activeStep) {
      return `ir para ${step.title}, etapa ${stepCount} de ${steps.length}`;
    }

    return `${step.title}, etapa ${stepCount} de ${steps.length}${isActiveStep(index) ? ', etapa atual' : ''}`;
  };

  useEffect(() => {
    setTimeout(() => {
      if (stepperRef.current) stepperRef.current.focus();
    }, 100);
  }, [activeStep]);

  return (
    <>
      <CustomStepper connector={<CustomConnector />} activeStep={activeStep} id={'stepper'}>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepButton
                disableRipple
                aria-label={getWizardStepAriaLabel(step, index)}
                onClick={step.onClick}
                disabled={index > activeStep}
                ref={isActiveStep(index) ? stepperRef : undefined}
              >
                <StepLabel aria-hidden>{step.title}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </CustomStepper>
      <LinearProgress color="primary" variant="determinate" aria-hidden value={handleProgressValue()} />
      <Box maxWidth="values.lg">{steps[activeStep || 0]?.component()}</Box>
    </>
  );
};

function StepperMobile({ steps, activeStep }: DefaultStepperProps) {
  const width = useWindowWidth();
  const stepperRef = useRef(undefined);
  const [maxStepLength, setMaxStepLength] = useState<number>(steps.length + 1);

  useEffect(() => {
    setTimeout(() => {
      if (stepperRef.current) stepperRef.current.focus();
    }, 600);
  }, [activeStep]);

  const handleProgressValue = () => {
    const maxWidth = 1104;
    if (width <= maxWidth) {
      return ((activeStep + 1) / steps.length) * 100;
    }

    const progressPlace = (maxWidth / steps.length) * (activeStep + 1);

    return (((width - maxWidth) / 2 + progressPlace) * 100) / width;
  };

  return (
    <>
      <Box height={'81px'}>
        {/*<IconButton color="primary" aria-label="voltar">*/}
        {/*<i className="icon-itaufonts_seta" />*/}
        {/*</IconButton>*/}
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          my={'1rem'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {/*@ts-ignore*/}
          <Box tabIndex={-1} pr={'1rem'} ref={stepperRef} aria-label={`você está na etapa ${steps[activeStep]?.title}`} style={{outline: 0}}>
            <Box mr={'0.5rem'} display={'inline'} fontSize={'18px'}>
              <i className={'icon-itaufonts_chat_enviado'} />
            </Box>
            {steps[activeStep]?.title}
          </Box>
          <div aria-atomic="true" aria-label={`etapa ${activeStep + 1} de ${steps.length}`}>
            {`etapa ${activeStep + 1} de ${steps.length}`}
          </div>
        </Box>
        <MobileStepper
          variant="progress"
          steps={maxStepLength}
          backButton={console.log}
          nextButton={console.log}
          position="static"
          activeStep={activeStep + 1}
          LinearProgressProps={{ 'aria-hidden': true, value: handleProgressValue(), style: { width: '100%' } }}
        />
      </Box>
      {steps[activeStep || 0]?.component()}
    </>
  );
}

export const StepperMui = (props) => {
  const width = useWindowWidth();
  const theme = useTheme();

  if (width > theme.breakpoints.values.md) return <StepperDesktop {...props} />;

  return <StepperMobile {...props} />;
};
