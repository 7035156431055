const emailValidation = (email: string): boolean => {
  const regExValidation = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.]{2,})+\.([A-Za-z]{2,})$/;

  return regExValidation.test(email);
};

export { emailValidation };

export const EMAIL_REGEX =
  /[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
