import { Lead } from '@core/domain/entities/autov2/lead';
import { ISendLeadUsecase } from '@core/domain/usecases/send-lead-usecase';
import { ISendLeadRepository } from '../contracts/autov2/send-lead-repository';

export const getRecaptchaToken = async (action, recaptchaSiteKey) => {
  // @ts-ignore
  const token = await window?.grecaptcha?.execute(recaptchaSiteKey, {
    action,
  });
  return {
    acao: action,
    token,
  };
};

export class SendLeadUsecase implements ISendLeadUsecase {
  constructor(
    readonly sendLeadRepository: ISendLeadRepository,
    readonly recaptchaSiteKey,
    readonly products,
    readonly channel
  ) {}
  static translate(
    channel,
    email,
    cellphone,
    name,
    cpf,
    captcha,
    comentario,
    produtos
  ): Lead {
    return {
      canal: channel,
      captcha,
      cliente: {
        nome: name,
        cpf: cpf.replace(/[.()\s-]/g, ''),
        email: email,
        telefone: cellphone.replace(/\(-\)\s/g, ''),
        comentario
      },
      produtos,
      indicador_termo_autorizacao: true,
    };
  }
  async execute({
    simulationNumber,
    email,
    cellphone,
    name,
    cpf,
  }: {
    simulationNumber?: number;
    email: string;
    cellphone: string;
    name: string;
    cpf: string;
  }) {
    const captcha = await getRecaptchaToken('lead', this.recaptchaSiteKey);
    
    const dac = await this.getMemoryItem('dac');
    const segment = await this.getMemoryItem('segmento');

    const leadPayload: Lead = SendLeadUsecase.translate(
      this.channel, 
      email,
      cellphone.replace(/[^a-z0-9]/gi, ''),
      name,
      cpf,
      captcha,
      this.buidComment(simulationNumber, dac, segment),
      this.products,
    );

    console.log("lead payload: ", leadPayload)

    await this.sendLeadRepository.execute(leadPayload);
    return true;
  }

  private async getMemoryItem(key: string): Promise<string> {
    const sdkObject = (window as any).native;
    return (await sdkObject?.getMemoryItem(key)) || '';
  }
  
  private buidComment(simulationNumber: number | string, dac: number | string, segment: string): string {
    return (
      (simulationNumber ? `PROPOSTA ${simulationNumber}` : '') + 
      (segment ? ` SEGMENTO ${segment}` : '') + 
      (dac ? ` DAC ${dac}` : '')
    );
  }
}
