import ReCAPTCHA from 'react-google-recaptcha';
import { FormControllerFieldConnector } from '@web/form';
import { stringValidationFactory } from '@web/validation';
import styled from 'styled-components';
import { ItauIcon } from '@workspace/frontend/shared/presentation/components';

export const Span = styled.span<any>`
  color: #781b8e;
  font-size: 13.714px;
`;

const recaptchaComponent = ({
  sitekey,
  onChange,
  helperText,
}: {
  sitekey: string;
  onChange?(): null;
  helperText?: string;
}) => {
  return (
    <>
      <ReCAPTCHA sitekey={sitekey} onChange={onChange} />
      {helperText && (
        <Span role="alert">
          <ItauIcon
            iconName="exclamacao"
            ariaHidden={true}
            style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}
          />
          {helperText}
        </Span>
      )}
    </>
  );
};

export const RecaptchaField = (props) => (
  <FormControllerFieldConnector<any>
    name="recaptcha"
    onChange={props.onChange}
    component={recaptchaComponent}
    validation={stringValidationFactory({ requiredMessage: 'reCAPTCHA deve ser marcado' })}
    componentProps={{
      sitekey: props.sitekey,
    }}
  />
);
