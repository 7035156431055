import styled from 'styled-components';

export const Title = styled.h1`
  color: #2b374a;
  font-family: 'ItauDisplay-Light', 'sans-serif';
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 2.09125rem;
  margin: 0;
  cursor: default;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.625rem;
  }
`;

export const TitleBold = styled.h2`
  color: #2b374a;
  font-family: 'ItauDisplay-Regular', 'sans-serif';
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2.2525rem;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.75rem;
  }
`;

export const TitleDescription = styled.p`
  color: #2b374a;
  display: inline-block;
  font-family: 'ItauDisplay-Light', 'sans-serif';
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.8125rem;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 1.625rem;
    line-height: 2.0625rem;
  }
`;

export const AuthenticationContainer = styled.div`
  width: 100%;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 24px 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: 64px 0;
    max-width: 600px;
    padding: 48px 100px 50px;
  }
`;

export const RegisterContainer = styled.div`
  padding: 30px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 1320px;
    padding: 63px 30px 80px;
  }
`;

export const RecoveryContainer = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 1320px;
    padding: 0 30px;
  }
`;
