import { authImages, translateToMicrofrontendPath } from '@web/assets';
import { BoxWeb, ButtonWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useAuthenticationParams } from '../../../../providers/authentication-params-provider';
import { RecoveryContainer } from '../../../style';
import { useAuthOriginProvider } from '../../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../../providers/auth-product-provider';

const Image = styled.img`
  width: 350px;
  height: 280px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}) {
    margin-left: 100px;
  }
`;

export const RecoverySuccess = ({ username }: { username?: string }) => {
  const { Theme } = useThemeProvider();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.CHANGE_PASSWORD_SUCCESS;
  const [message, setMessage] = useState<string | JSX.Element>();
  const { getRegisteredLoginURLBase } = useAuthenticationParams();

  const handleRedirect = () => {
    analyticsAuth.LOGIN();
    const loginURLBase = getRegisteredLoginURLBase();
    window.location.assign(loginURLBase);
  };

  useEffect(() => {
    analyticsAuth.LOAD();
    analyticsAuth.SEEN_SUCCESS();
  }, []);

  useEffect(() => {
    const defaultMessage = 'agora é só fazer login com sua nova senha';

    return username
      ? setMessage(
          <>
            <b>{username}</b>, {defaultMessage}
          </>
        )
      : setMessage(defaultMessage);
  }, [username]);

  return (
    <ThemeProvider theme={Theme}>
      <RecoveryContainer>
        <BoxWeb
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'start' }}
          alignItems="center"
          marginTop="60px"
          marginBottom="60px"
          color="#2B374A"
        >
          <BoxWeb maxWidth="370px" order={{ xs: 1, md: 0 }}>
            <BoxWeb
              fontFamily="ItauDisplay-Regular"
              fontSize="26px"
              lineHeight="33px"
              fontWeight="300"
              marginBottom="8px"
              component="h1"
            >
              pronto!
            </BoxWeb>
            <BoxWeb
              fontFamily="ItauDisplay-Regular"
              fontSize="28px"
              lineHeight="36px"
              fontWeight="700"
              marginBottom="33px"
              component="p"
            >
              sua senha já foi alterada
            </BoxWeb>
            <BoxWeb fontSize="18px" lineHeight="23px" marginBottom="33px" component="p">
              {message}
            </BoxWeb>
            <BoxWeb maxWidth="183px">
              <ButtonWeb label="fazer login" onClick={handleRedirect} />
            </BoxWeb>
          </BoxWeb>
          <BoxWeb order={{ xs: 0, md: 1 }}>
            <Image src={translateToMicrofrontendPath(authImages.secureComputer)} alt="Computador protegido" />
          </BoxWeb>
        </BoxWeb>
      </RecoveryContainer>
    </ThemeProvider>
  );
};
