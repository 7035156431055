import { Box, Step, StepConnector as StepConnectorMui, StepLabel as StepLabelMui, withStyles } from '@material-ui/core';
import StepperMui from '@material-ui/core/Stepper';
import { StepperProps } from '@material-ui/core/Stepper/Stepper';
import React from 'react';

const Stepper = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  connector: {
    backgroundColor: 'red',
  },
}))(StepperMui);

const StepConnector = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
}))(StepConnectorMui);

const StepLabel = withStyles(() => ({
  root: {},
  completed: {},
  active: {},
  label: {
    '&$completed': {
      color: 'white',
    },
    '&$active': {
      color: 'white',
    },
    color: 'white',
  },
  iconContainer: {
    boxSizing: 'border-box',
    padding: '0',
    margin: '0 0.5rem 0.5rem 0.5rem',
    backgroundColor: 'white',
    borderRadius: '100%',
    border: '1px solid white',
  },
}))(StepLabelMui);

export function StepperProgressMui({
  steps,
  activeStep,
  orientation = 'horizontal',
  alternativeLabel = true,
}: StepperProps & { steps: any }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={alternativeLabel}
        orientation={orientation}
        connector={<StepConnector />}
      >
        {steps?.map((i, idx) => {
          const status = activeStep > idx ? 'completo' : activeStep === idx ? 'atual' : 'incompleto';
          const ariaLabel = `passo ${idx + 1} ${status}`;
          return (
            <Step>
              <StepLabel>
                <span aria-label={`${ariaLabel}`} tab-index="-1"></span>
                <span tab-index="-1">{i.label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
