import React, { createContext, useContext, useEffect, useState } from 'react';
import { ArticleBlogResponse } from '@workspace/frontend/blog/domain/entities';
import { ArticleBlog } from '@workspace/frontend/blog/domain/entities';
import { getContentArticleFactory } from '@workspace/frontend/blog/infra/factories';
import { useEnvironment } from '@workspace/providers';
import { Route } from 'react-router-dom';
import { ArticleBase } from '../pages';
import { CategoryBlog } from '@workspace/frontend/blog/domain/entities';
import { getCategoriesFactory } from '../factories';

type DynamicBlogContextProps = {
  createDynamicRoute?: Function;
  loading?: boolean;
  blogContent?: ArticleBlog[];
  blogCategories?: CategoryBlog[];
};

const DynamicBlogContext = createContext<DynamicBlogContextProps>({});

export type DynamicBlogProps = {
  children: any;
};

const BlogProvider = ({ children }: DynamicBlogProps) => {
  const [blogContent, setDynamicBlog] = useState<ArticleBlog | any>([]);
  const [blogCategories, setBlogCategories] = useState<CategoryBlog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const enviroment = useEnvironment();
  const getBlogUsecase = getContentArticleFactory(enviroment);
  const getCategoriesUsecase = getCategoriesFactory(enviroment);

  const _initialize = async () => {
    setLoading(true);
    const getBlog = await getBlogUsecase.execute();
    const getCategories = await getCategoriesUsecase.execute();
    setLoading(false);
    setDynamicBlog(getBlog);
    setBlogCategories(getCategories);
  };

  useEffect(() => {
    _initialize();
  }, []);

  const createDynamicRoute = (url: string, content: ArticleBlogResponse) => {
    return <Route exact path={url} render={() => <ArticleBase content={content} />} />;
  };

  return (
    <DynamicBlogContext.Provider value={{ createDynamicRoute, loading, blogContent, blogCategories }}>
      {children}
    </DynamicBlogContext.Provider>
  );
};

const useDynamicBlog = () => {
  return useContext(DynamicBlogContext);
};

export { BlogProvider, useDynamicBlog };
