import styled from 'styled-components';

export const Container = styled.div`
  @media (min-width: 960px) {
    max-width: 1360px;
    margin: 0 auto;
  }
`;
export const ContainerWrapper = styled.div`
  @media (min-width: 960px) {
    max-width: 75%;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  height: 76px;
  border-bottom: 2px solid #acacac;
  margin: 15px 10px;
  font-family: 'ItauDisplay-Light', 'sans-serif';
  font-size: 14px;
  font-weight: bold;
  color: #000000;
`;
export const CommonLabel = styled.label`
  font-weight: normal;
`;
export const CommonInput = styled.input`
  font-family: 'ItauDisplay-Light', 'sans-serif';
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  &:focus {
    outline: none;
  }
`;

export const Row = styled.div`
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 26% 50% 24%;
  }
`;
