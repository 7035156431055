import { rest } from 'msw';

interface ResendTokenAuthRequestProps {
  email: string;
  name: string;
  cpf: string;
}

export const resendTokenAuthMock = (env) => {
  return rest.post<ResendTokenAuthRequestProps>(
    `${env.gatewayBFFBaseURL.url}/autenticar/reenviotoken`,
    (req, res, ctx) => {
      const { email } = req.body;

      if (email !== 'mail@mail.com') {
        return res(ctx.status(200), ctx.json({ data: { success: true, errors: [], data: { success: true } } }));
      }

      return res(
        ctx.status(400),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'authenticateTokenNotFoundError',
                message: 'Estamos com problema, tente mais tarde!',
              },
            ],
          },
        })
      );
    }
  );
};
