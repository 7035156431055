import {
  AlertWeb,
  BoxWeb,
  LinkButtonWeb,
  NavigateButtonsWeb,
} from '@workspace/frontend/shared/presentation/components';
import { FormController } from '@web/form';
import { useContext, useEffect, useRef, useState } from 'react';
import { CodeConfirmationField } from '../../../components';
import { AuthStateManager } from '@web/state-management';
import { sendTokenAuthFactory } from '../../../factories/send-token-auth-factory';
import { resendTokenAuthFactory } from '../../../factories/resend-token-auth';
import { removeEspecialCharacter } from '../../../utils/remove-special-character';
import useErrorProvider from '../../../providers/error-provider';
import { StepperContext } from '../stepper/stepper-context';
import { Title } from '../../style';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { RecaptchaActions } from '../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../providers/auth-product-provider';

const smsResendTime = 45;

const defaultBorderProps = {
  borderColor: "rgba(0, 0, 0, 0.25)'",
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
};

interface IUser {
  cpf: string;
  email: string;
  name: string;
  cellphone: string;
}

export function CodeConfirmationContainer() {
  const titleRef = useRef(undefined);
  const { handleNext } = useContext(StepperContext);
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const {
    environment: { recaptchaSiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.VERIFY_CODE_SENT;

  const [registrationData, setRegistrationData] = useState<IUser>({
    cpf: '',
    email: '',
    name: '',
    cellphone: '',
  });
  const [seconds, setSeconds] = useState<number>(smsResendTime);
  const [loading, setLoading] = useState<boolean>(false);

  const [disableResendButton, setDisableResendButton] = useState<boolean>(true);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);

  const closeAlert = () => setRequestError({ show: false });

  const handleRequestError = (errorData) => {
    setLoading(false);

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });

    setDisableResendButton(false);
    setResendEnabled(true);
    setSeconds(0);
  };

  const sendTokenAuthUseCase = sendTokenAuthFactory();
  const resendTokenAuthUseCase = resendTokenAuthFactory();

  useEffect(() => {
    analyticsAuth.LOAD();

    const { registration } = AuthStateManager.getState();

    setRegistrationData(registration);

    setTimeout(() => {
      if (titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  useEffect(() => {
    const resendSmsInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(resendSmsInterval);
        setDisableResendButton(false);
      }
    }, 1000);
    return () => {
      clearInterval(resendSmsInterval);
    };
  }, [seconds]);

  const handleSubmit = async (data) => {
    analyticsAuth.CONTINUE();

    closeAlert();
    setLoading(true);

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.CONFIRM_TOKEN, recaptchaSiteKey);

      const payload = {
        token: data?.code,
        email: registrationData?.email,
      };
      const response = await sendTokenAuthUseCase.execute(payload, {
        recaptcha: JSON.stringify(recaptcha),
      });

      if (response?.data?.success) {
        AuthStateManager.registrationStepDispatcher(4);
        handleNext();
      }
    } catch (error) {
      handleRequestError(error);
    }

    setLoading(false);
  };

  const resendSms = async () => {
    analyticsAuth.RESEND_CODE();

    closeAlert();
    setDisableResendButton(true);

    const { email, name, cpf: dirtyCPF } = registrationData;
    const cpf = dirtyCPF && removeEspecialCharacter(dirtyCPF);

    const payload = { name, email, cpf };

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.RESEND_TOKEN, recaptchaSiteKey);

      const response = await resendTokenAuthUseCase.execute(payload, {
        recaptcha: JSON.stringify(recaptcha),
      });

      if (response?.data?.success) {
        setResendEnabled(false);
        setSeconds(smsResendTime);
        setDisableResendButton(true);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleError = (errorMessage: string) => {
    analyticsAuth.CODE_ERROR(errorMessage);
  };

  const disableSmsLinkButton = !(seconds === 0 || resendEnabled) || disableResendButton || loading;
  const showCounter = !(seconds === 0 || resendEnabled);

  return (
    <>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        children={requestError.message}
        onClose={closeAlert}
        secondsToClose={16}
      />
      <BoxWeb
        bgcolor="background.main"
        display="flex"
        height={'700px'}
        justifyContent={'center'}
        alignItems={'center'}
        tabIndex={0}
      >
        <BoxWeb
          display="flex"
          flexDirection="column"
          justifyContent={'center'}
          alignItems={'center'}
          width={{ sm: '95%', md: '600px' }}
          height={'auto'}
          py={{ xs: '40px', md: '22px' }}
          px={{ xs: '20px', md: '0' }}
          bgcolor="primary.contrastText"
          borderRadius="borderRadius"
          {...defaultBorderProps}
        >
          <BoxWeb display={'flex'} flexDirection={'column'} alignItems={'center'} height={'auto'}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <BoxWeb fontSize={'26px'}>
              <Title id="lblTextConfirmation_screenRegister" tabIndex={-1} ref={titleRef}>
                agora só falta confirmar o seu e-mail
              </Title>
            </BoxWeb>
            <BoxWeb mb={'10px'} textAlign={{ xs: 'center', md: 'left' }} fontSize={'28px'} fontWeight={'bold'}>
              com o código de segurança
            </BoxWeb>
            <BoxWeb mb={'40px'} fontSize={'18px'}>
              Enviamos para o seu e-mail {registrationData?.email}
            </BoxWeb>
            <FormController onSubmit={handleSubmit} hideErrors>
              <BoxWeb display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <BoxWeb width={{ xs: '100%', md: '400px' }} mb={'0.5rem'}>
                  <CodeConfirmationField onError={handleError} />
                </BoxWeb>
                <BoxWeb display={'flex'} flexDirection={'row'} height={'4rem'} width={{ xs: '100%', md: '400px' }}>
                  <BoxWeb mt={'0.28rem'}>não recebeu?</BoxWeb>
                  <BoxWeb ml={'10px'} display={'flex'}>
                    <BoxWeb>
                      <LinkButtonWeb
                        disabled={disableSmsLinkButton}
                        id={'lnkResendSms_screenRegister'}
                        label={'reenviar código'}
                        onClick={resendSms}
                      />
                    </BoxWeb>
                    {showCounter && (
                      <BoxWeb
                        ml={'0.4rem'}
                        mt={'0.21rem'}
                        fontSize={'16px'}
                        color={'#969290'}
                      >{`em ${seconds} segundos`}</BoxWeb>
                    )}
                  </BoxWeb>
                </BoxWeb>
                <BoxWeb mb={{ md: '2.5rem' }}>
                  <NavigateButtonsWeb
                    loading={loading}
                    hideBackButton={true}
                    widthContinueButton={'188px'}
                    justifyContent="center"
                  />
                </BoxWeb>
              </BoxWeb>
            </FormController>
          </BoxWeb>
        </BoxWeb>
      </BoxWeb>
    </>
  );
}
