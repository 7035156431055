import { rest } from 'msw';

export const faqAuto = (env) => {
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/seguros/faq/Auto`, (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        {
          uid: 'blt02169b5305e2dd4e',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'o que é franquia?',
          resposta:
            'É a participação do segurado no pagamento do reparo em caso de sinistro de dano parcial ao veículo. O sinistro somente será informado à Seguradora se o valor dos prejuízos forem superiores ao valor da franquia.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blt89d60c046faa430a',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'o que é classe de bônus?',
          resposta:
            'É o que informa o tempo em que não houve sinistro com o veículo, recompensando o bom motorista com um desconto que vai até 30%.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blta1c47af65c6ee10f',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'receberei algum material informativo após a contratação do seguro?',
          resposta:
            'Após a aceitação da proposta e emissão da apólice de seguro, você receberá um e-mail com as principais informações sobre a contratação e orientações para acesso ao portal do segurado. Além disso, receberá também a sua apólice e carteirinha do seguro.',
          frequente: true,
          ativo: true,
        },
        {
          uid: 'blta178a0ef1b8f5b95',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'o que é cobertura de danos morais?',
          resposta:
            'É a cobertura contra os danos que acabam por abalar a honra, a boa-fé subjetiva ou a dignidade das pessoas físicas ou jurídicas.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltc212988940032ca4',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'qual a diferença entre danos aos terceiros e danos aos passageiros?',
          resposta:
            'Danos aos terceiros são os ocasionados àqueles que estão fora do carro, enquanto o de passageiros abrange aqueles que estiverem dentro do veículo no instante do sinistro.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltdc3821ab774ca472',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'o seguro oferece algum benefício?',
          resposta:
            'Sim, o segurado conta com diversos beneficios como: veículo reserva gratuito, serviços emergenciais para residência e muito mais. <br>Consulte todos os benefícios disponíveis nas Condicções Gerais do produto em www.lojadesegurositau.com.br/auto',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt2c2e7cb85a39f451',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'por que preciso responder à todas as perguntas do perfil?',
          resposta:
            'Para que a seguradora consiga calcular o preço de uma maneira personalizada para atender às suas necessidades.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt43285de9d09be174',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'meu veículo não está em meu nome, eu consigo contratar o seguro?',
          resposta:
            'Sim, permitimos a contratação do seguro quando o segurado não é o proprietário do veículo. <br>Porém, em caso de sinistro, o ressarcimento da indenização será efetuado para o proprietário do veículo.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt213ff3eb1fd8df19',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'o que acontece se eu não contratar cobertura para terceiros?',
          resposta:
            'Caso o segurado colida com outro veículo, não poderá acionar sua apólice para ressarcir o prejuízo ocasionado ao terceiro.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltae0698e10556782d',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'o que é cobertura para terceiros?',
          resposta:
            'É o valor contratado que pode ser utilizado para ressarcir o prejuízo causado à terceiros, no caso de colisão entre os veículos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt0108ccaca9a93187',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'como faço para alterar o meu plano contratado?',
          resposta:
            'O seguro contratado pode ser alterado via contato nas centrais de atendimento da Itaú Corretora nos telefones: <br>4090-1125:para capitais e regiões metropolitanas<br>0800-791-1125: para os demais localidades',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt87f24ca7d3764cf3',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'após a contratação qual é o prazo de análise da minha proposta?',
          resposta:
            '<p>A seguradora tem um prazo de até 15 dias corridos de análise para aceitação ou recusa do seguro.</p>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt8354ab55f67a4113',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta:
            'se algum evento descrito nas coberturas tiver ocorrido antes da contratação do seguro, posso acionar a seguradora?',
          resposta:
            'Não. O seguro estará somente ativo à partir das 24hrs da data de início de vigência indicada na proposta ou apólice.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt34a4c8849680ae84',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'qual o valor de cobertura em caso de roubo ou furto?',
          resposta:
            'O valor de cobertura para indenização integral por roubo ou furto ou colisão (caso a seguradora caracterize como perda total)  é o mesmo na tabela fipe do veiculo considerando % escolhido pelo cliente no ato da contratação, variando de 80% a 120% da tabela fipe.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt011ead051b606b00',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'qual a diferença entre roubo ou furto?',
          resposta:
            'O furto ocorre quando o autor do crime se apropria de um objeto ou valor financeiro no qual ele não possui nem a posse e nem o direito. O roubo acontece quando há o emprego de violência ou grave ameaça e o autor se apropria de algo que não possui nem a posse nem o direito.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt46a9ddb29068e6b5',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt0ce56b26142b96ca',
            titulo: 'contratação',
          },
          pergunta: 'o que é ci - código de identificação?',
          resposta:
            'É uma forma unificada de se informar os dados de uma apólice para que seja feita a confirmação do bônus. Este código irá facilitar a comunicação entre as seguradoras e a central de bônus, tornando o processo mais ágil. As confirmações estão sendo feitas online após a emissão das apólices.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt26085d5d3ef76e15',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blte0ee81114c86da35',
            titulo: 'pagamento',
          },
          pergunta: 'quais são as formas de pagamento do seguro?',
          resposta: 'O pagamento poderá ser efetuado através de débito em conta corrente. O pagamento será mensal.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt816a9308e92b6067',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt16162788caecfdbe',
            titulo: 'atendimento',
          },
          pergunta:
            'qual o telefone e horário de funcionamento das Centrais de Atendimento (para atendimento pós compra)?',
          resposta:
            'Itaú Seguros de Auto e Residência<br>3003-1001 (Capitais e regiões metropolitanas)<br>0800 720 1001 (Demais localidades)<br>Sinistro: 2° a 6° das 8h às 22h. Finais de semana e feriados das 8h às 20h;<br>Informações e alterações no Seguro: 2° a 6° das 8h às 22h;<br>Assistência: 24 horas.<br><br>Itaú Corretora de Seguros:<br>4090 1125 (Capitais e regiões metropolitanas)<br>0800 791 1125 (Demais localidades)<br>Informações e alterações no seguro: 2° a 6° das 8h às 20h',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt4bd7936d8acb546b',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt3f8f7b4dcff623f6',
            titulo: 'cancelamento',
          },
          pergunta: 'em qual outra situação ocorre o cancelamento do seguro?',
          resposta:
            'Em caso de: perda total (decorrente de roubo e furto, incêndio, colisão), o seguro é automaticamente cancelado após a indenização.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltabae79c4064610e8',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt3f8f7b4dcff623f6',
            titulo: 'cancelamento',
          },
          pergunta: 'como faço para cancelar o meu seguro auto?',
          resposta:
            'Para cancelamento é necessário o contato nas centrais de atendimento da Itaú Corretora no telefone: <br>4090-1125 para capitais e regiões metropolitanas<br>0800-791-1125 para os demais localidades',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt9783416db41c4522',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'onde posso consultar todas as coberturas, assistências e informações pertinentes ao seguro?',
          resposta:
            'Nas condições gerais do produto, disponíveis em nosso site.<br><br><a class="link" href="https://itau-mkt-place-seguros.s3-sa-east-1.amazonaws.com/termos/auto/cg-porto-seguro_auto.pdf" rel="noopener noreferrer" target="_blank">Consulte as condições gerais para Porto Seguro</a><br><a class="link" href="https://itau-mkt-place-seguros.s3-sa-east-1.amazonaws.com/termos/auto/cg-azul-seguro-auto.pdf" rel="noopener noreferrer" target="_blank">Consulte as condições gerais para Azul</a><br><br>Após a contratação do seguro, você poderá consultar sua apólice, Condições Gerais e Manual do Segurado a qualquer momento através do endereço <a class="link" href="https://www.portoseguro.com.br/seguro-auto" rel="noopener noreferrer" target="_blank">https://www.portoseguro.com.br/seguro-auto</a> ou <a class="link" href="https://www.azulseguros.com.br/seguro-auto/" rel="noopener noreferrer" target="_blank">https://www.azulseguros.com.br/seguro-auto/</a>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt0d49c292df5f8614',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'quem pode contratar o seguro?',
          resposta: 'Pessoas acima de 18 anos',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt16a829209545d3ac',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt87f592eeead32c3b',
            titulo: 'coberturas',
          },
          pergunta: 'quais são as principais coberturas do seguro auto?',
          resposta:
            'As principais coberturas comercializadas são:  colisão, incêndio, roubo, furto, danos materiais, danos corporais  e acidentes pessoais passageiros.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt6c4e2f037c15807c',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt5590cef6458fc62a',
            titulo: 'dúvidas frequentes',
          },
          pergunta: 'o que é o Seguro Auto?',
          resposta:
            '<p>É um seguro que protege o seu veículo de imprevistos como roubo, furto, incêndio, colisão e outros, garantindo a tranquilidade que você precisa.</p>',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'bltbdfc1e5dcbaae08f',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt9ec4198e23400bff',
            titulo: 'carências',
          },
          pergunta: 'o seguro auto possui carência?',
          resposta:
            'Não. Sua cobertura é valida à partir da data de contratação e emissão da apólice pela seguradora.<br>O prazo de análise da proposta é de até 15 dias corridos.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt45f9afd5567899f6',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'qual a vigência?',
          resposta: 'O seguro auto tem duração de 12 meses à partir da sua contratação.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt932c1361778c8793',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'há renovação?',
          resposta:
            'Sim, todas as apólices podem ser renovadas após o término da vigência, com base na política de aceitação da seguradora.',
          frequente: false,
          ativo: true,
        },
        {
          uid: 'blt56e8c726276509c4',
          produto: {
            uid: 'bltdbb3a002e0aefc81',
            titulo: 'Auto',
          },
          categoria: {
            uid: 'blt53a83ffbb243bb45',
            titulo: 'características do produto',
          },
          pergunta: 'há reajuste?',
          resposta: 'Sim. Na renovação do seguro o valor é reajustado com base na política de aceitação da seguradora.',
          frequente: false,
          ativo: true,
        },
      ])
    )
  );
};
