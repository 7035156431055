import { rest } from 'msw';

interface UserIDProps {
  userIdentifier: string;
}

export const getUserInfoMock = (env) => {
  return rest.get<UserIDProps>(`${env.gatewayBFFBaseURL.url}/dadosusuario`, (req, res, ctx) => {
    const userIdentifier = req.url.searchParams.get('userIdentifier');

    if (userIdentifier) {
      return res(
        ctx.status(200),
        ctx.json({
          success: true,
          errors: [],
          value: {
            firstName: 'Loja',
            name: 'Loja Terceira',
            document: 'xxx.xxx.810-80',
            email: 'r***********t@outlook.com',
            cellphone: '(xx) xxxxx-9999',
            password: '********',
          },
        })
      );
    }

    if (!userIdentifier) {
      return res(
        ctx.status(409),
        ctx.json({
          success: false,
          errors: [
            {
              code: 'userIdentifierIsRequired',
              message: 'Identificador do usuário é obrigatório.',
            },
          ],
        })
      );
    }

    if (userIdentifier === 'fail') {
      return res(
        ctx.status(417),
        ctx.json({
          data: {
            success: false,
            errors: [
              {
                code: 'systemicError',
                message: 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.',
              },
            ],
          },
        })
      );
    }
  });
};
