import { BoxWeb, ButtonWeb, ModalWeb } from '@workspace/frontend/shared/presentation/components';
import styled from 'styled-components';
import { autov2Images, translateToMicrofrontendPath } from '@web/assets';

const { exemptCarInspection } = autov2Images;

const Image = styled.img`
  position: absolute;
  top: 24px;
  right: 40px;
  height: 270px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: 200px;
  }
`;

type ExemptCarInspectionModalProps = {
  onClose: () => void;
  open: boolean;
};

export function ExemptCarInspectionModal({ onClose, open }: ExemptCarInspectionModalProps) {
  return (
    <ModalWeb open={open}>
      <BoxWeb p={{ md: '4rem', xs: '2rem' }} pb="2rem">
        <BoxWeb display="flex">
          <BoxWeb flex={{ lg: '0.8', xs: '0.6' }}>
            <BoxWeb
              id="lblInicialText_screenCarInspection"
              color="primary.main"
              fontSize={'24px'}
              fontFamily={'ItauText-Bold'}
              fontWeight={500}
              mb={{ sm: '1.5rem', md: '0.5rem' }}
              mt={'2rem'}
              role="heading"
              aria-level={2}
            >
              Eba!
            </BoxWeb>
            <BoxWeb
              id="lblDescription_screenCarInspection"
              color="text.primary"
              fontSize={'22px'}
              lineHeight={'24px'}
              fontFamily={'ItauText-Bold'}
              fontWeight={500}
              mb={{ sm: '1.5rem', md: '0.5rem' }}
              mt={'1.5rem'}
              role="heading"
              aria-level={3}
              component="h3"
            >
              Você foi dispensado(a) de fazer a vistoria do seu veículo <span aria-hidden>:)</span>
            </BoxWeb>
          </BoxWeb>
          <BoxWeb flex={{ xs: '0.4', lg: '0.4' }} aria-hidden>
            <Image src={translateToMicrofrontendPath(exemptCarInspection)} aria-hidden />
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display="flex" mt={{ xs: '3rem', lg: '1rem' }}>
          <BoxWeb flex={{ xs: '1', lg: '0.6' }}>
            <BoxWeb component="p" color={'#6b625b'} fontSize={'16px'} lineHeight={'24px'} mt={'1rem'} width={{ xs: '100%', sm: '75%' }}>
              Agora basta clicar no botão "ok, continuar" para visualizar a sua proposta.
            </BoxWeb>
          </BoxWeb>
        </BoxWeb>
        <BoxWeb display="flex" justifyContent="center" mt="3rem">
          <BoxWeb width={{ xs: '100%', lg: 'max-content' }}>
            <ButtonWeb id="btnModalFinalizeHiring_screenPayment" label="Ok, continuar" onClick={onClose} />
          </BoxWeb>
        </BoxWeb>
      </BoxWeb>
    </ModalWeb>
  );
}
