import LojaDeSegurosStyle from '../../style/types/loja-de-seguros';
import LojaDeSegurosMobileStyle from '../../style/types/loja-de-seguros-mobile-test';
import Authentication from '../../style/types/authentication';
import MyAccount from '../../style/types/my-account';
import Blog from '../../style/types/blog';
import FAQV2 from '../../style/types/faqv2';
import DESIGN_SYSTEM from '../../style/types/design-system';

export enum StyleAdapterTypes {
  LOJA_DE_SEGUROS,
  LOJA_DE_SEGUROS_MOBILE_TEST,
  AUTHENTICATION,
  MY_ACCOUNT,
  BLOG,
  FAQV2,
  DESIGN_SYSTEM,
}

const _styleAdapters = {
  [StyleAdapterTypes.LOJA_DE_SEGUROS]: LojaDeSegurosStyle,
  [StyleAdapterTypes.AUTHENTICATION]: Authentication,
  [StyleAdapterTypes.AUTHENTICATION]: MyAccount,
  [StyleAdapterTypes.BLOG]: Blog,
  [StyleAdapterTypes.LOJA_DE_SEGUROS_MOBILE_TEST]: LojaDeSegurosMobileStyle,
  [StyleAdapterTypes.FAQV2]: FAQV2,
  [StyleAdapterTypes.DESIGN_SYSTEM]: DESIGN_SYSTEM,
};

export class StyleBuilder {
  private styleBaseType: StyleAdapterTypes;

  constructor(styleBaseType: StyleAdapterTypes) {
    this.styleBaseType = styleBaseType;
  }

  static adapter(componentBaseType: StyleAdapterTypes): StyleBuilder {
    return new StyleBuilder(componentBaseType);
  }

  builder() {
    return _styleAdapters[this.styleBaseType];
  }
}
