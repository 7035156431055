import { Question } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQProductQuestionsRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQProductQuestionsUseCase {
  execute(): Promise<Question[]>;
}

export class GetFaqProductQuestionsUseCase implements IGetFAQProductQuestionsUseCase {
  constructor(private readonly getFAQProductQuestionsRepository: IGetFAQProductQuestionsRepository) {}

  async execute(): Promise<Question[]> {
    return await this.getFAQProductQuestionsRepository.execute();
  }
}
