import { Actor } from '@workspace/entities';
import produce from 'immer';
import { typeGenerator } from '../utils/typeGenerator';

export const ActorDriverTypes = {
  setCpf: 'SET [actor] [driver] cpf',
  setContact: typeGenerator(['actor', 'driver'], 'SET', 'contact'),
  setName: typeGenerator(['actor', 'driver'], 'SET', 'name'),
  setRg: typeGenerator(['actor', 'driver'], 'SET', 'rg'),
  setIssueDate: typeGenerator(['actor', 'driver'], 'SET', 'issueDate'),
  setIssuingAgency: typeGenerator(['actor', 'driver'], 'SET', 'issuingAgency'),
  setNationality: typeGenerator(['actor', 'driver'], 'SET', 'nationality'),
  setProfession: typeGenerator(['actor', 'driver'], 'SET', 'profession'),
  setIncomeBracket: typeGenerator(['actor', 'driver'], 'SET', 'incomeBracket'),
  setCep: typeGenerator(['actor', 'driver', 'address'], 'SET', 'cep'),
  setAddress: typeGenerator(['actor', 'driver', 'address'], 'SET', 'address'),
  setAddressNumber: typeGenerator(['actor', 'driver', 'address'], 'SET', 'addressNumber'),
  setAddressComplement: typeGenerator(['actor', 'driver', 'address'], 'SET', 'addressComplement'),
  setAddressNeighborhood: typeGenerator(['actor', 'driver', 'address'], 'SET', 'addressNeighborhood'),
  setCity: typeGenerator(['actor', 'driver', 'address'], 'SET', 'city'),
  setState: typeGenerator(['actor', 'driver', 'address'], 'SET', 'state'),
  setCellphone: typeGenerator(['actor', 'driver' ], 'SET', 'cellphone'),
};

const handlers = {
  [ActorDriverTypes.setCpf]: produce((draft, _action) => {
    const actor = new Actor({ cpf: _action.cpf });
    draft.actor.driver.cpf = actor.cpf;
  }),
  [ActorDriverTypes.setContact]: produce((draft, _action) => {
    draft.actor.driver.contact = _action.contact;
  }),
  [ActorDriverTypes.setName]: produce((draft, _action) => {
    draft.actor.driver.name = _action.data;
  }),
  [ActorDriverTypes.setRg]: produce((draft, _action) => {
    draft.actor.driver.rg = _action.data;
  }),
  [ActorDriverTypes.setIssueDate]: produce((draft, _action) => {
    draft.actor.driver.issueDate = _action.data;
  }),
  [ActorDriverTypes.setIssuingAgency]: produce((draft, _action) => {
    draft.actor.driver.issuingAgency = _action.data;
  }),
  [ActorDriverTypes.setNationality]: produce((draft, _action) => {
    draft.actor.driver.nationality = _action.data;
  }),
  [ActorDriverTypes.setProfession]: produce((draft, _action) => {
    draft.actor.driver.profession = _action.data;
  }),
  [ActorDriverTypes.setIncomeBracket]: produce((draft, _action) => {
    draft.actor.driver.incomeBracket = _action.data;
  }),
  [ActorDriverTypes.setCep]: produce((draft, _action) => {
    draft.actor.driver.address.cep = _action.data;
  }),
  [ActorDriverTypes.setAddress]: produce((draft, _action) => {
    draft.actor.driver.address.address = _action.data;
  }),
  [ActorDriverTypes.setAddressNumber]: produce((draft, _action) => {
    draft.actor.driver.address.addressNumber = _action.data;
  }),
  [ActorDriverTypes.setAddressComplement]: produce((draft, _action) => {
    draft.actor.driver.address.addressComplement = _action.data;
  }),
  [ActorDriverTypes.setAddressNeighborhood]: produce((draft, _action) => {
    draft.actor.driver.address.addressNeighborhood = _action.data;
  }),
  [ActorDriverTypes.setCity]: produce((draft, _action) => {
    draft.actor.driver.address.city = _action.data;
  }),
  [ActorDriverTypes.setState]: produce((draft, _action) => {
    draft.actor.driver.address.state = _action.data;
  }),
  [ActorDriverTypes.setCellphone]: produce((draft, _action) => {
    draft.actor.driver.cellphone = _action.cellphone;
  }),
};

export default handlers;
