import SuccessContainer from './auto-success';
import { AutoV2OriginProvider } from '../../providers/origin-provider';
import { RecaptchaDOMInjector } from '@workspace/frontend/shared/application/injectors';
import { RemoteConfigProvider } from '@workspace/providers';

export const AutoV2SuccessCarrier = ({ partner }) => (
  <AutoV2OriginProvider partner={partner}>
    <RemoteConfigProvider>
      <RecaptchaDOMInjector />
      <SuccessContainer />
    </RemoteConfigProvider>
  </AutoV2OriginProvider>
);
