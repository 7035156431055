import { StateManager } from '../StateManager';
import { store } from '../../store';

import { FAQV2Types } from '../../reducers/faq-v2/faq-v2-reducer-handlers';

class FAQV2StateManager extends StateManager {
  faqProductsDispatcher({
    topics,
    products,
    questions,
    selectedProduct,
  }: {
    topics;
    products;
    questions;
    selectedProduct;
  }) {
    const faqContent = { products, questions, topics, selectedProduct };
    this.store.dispatch({ type: FAQV2Types.SET_FAQ_PRODUCTS, faqContent });
  }

  faqQuestionsSelector() {
    const {
      faq: { selectedProduct, topics, questions },
    } = this.store.getState();
    return { selectedProduct, topics, questions };
  }
}

export const FAQV2StateManagerSingleton = new FAQV2StateManager(store);
