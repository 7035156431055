import { useEffect } from 'react';
import { StepProviderProps, useStepProvider } from '../../../../providers/step-provider';
import { useOriginContext } from '../../../../providers/origin-provider';
import { Auth } from '@workspace/frontend/auth/presentation/web-app';
import useOriginAndProductSession
  from "../../../../../../../../auth/presentation/web-app/src/hooks/useOriginAndProductSession";

export function AuthenticationStepContainer() {
  const stepProvider: StepProviderProps = useStepProvider();
  const onBackName = 'auto-onBack';
  const onSuccessLoginCallbackName = 'auto-onSuccessLoginCallback';
  const origin = useOriginContext();
  const {removeOriginAndProductSession, setOriginAndProductSession} = useOriginAndProductSession();

  const onSuccessLoginCallback = () => {
    stepProvider.setActiveStep(6);
  };

  const onBack = () => {
    stepProvider.handleBack();
  };

  useEffect(() => {
    removeOriginAndProductSession();
    setOriginAndProductSession({origin: 'loja', product: 'auto'});
    window.addEventListener(onBackName, onBack);
    window.addEventListener(onSuccessLoginCallbackName, onSuccessLoginCallback);

    return () => {
      window.removeEventListener(onBackName, onBack);
      window.removeEventListener(onSuccessLoginCallbackName, onSuccessLoginCallback);
    };
  }, []);

  return (
    <Auth
      origin={'loja'}
      product={'auto'}
      onsuccesslogincallbackname={onSuccessLoginCallbackName}
      onbackeventname={onBackName}
    />
  );
}

export function AuthenticationStepContainerWrapper() {
  return <AuthenticationStepContainer />;
}
