import { ISendLeadRepository } from "@core/data/contracts/autov2/send-lead-repository";
import { IHttpPostClient } from "@core/data/contracts/http/http-post-client";
import { Lead } from "@core/domain/entities/autov2/lead";

export class SendLeadRepository implements ISendLeadRepository {
  constructor(
    private readonly httpPostClient: IHttpPostClient<Lead, string>
  ) { }

  async execute(lead: Lead) {
    await this.httpPostClient.post(lead);
  }
}