import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DefaultButtonProps } from '../../models/default-button';

export const LinkButtonMui = styled(({ type, label, ...other }: DefaultButtonProps) => (
  <Button type={type} fullWidth {...other}>
    {label}
  </Button>
))(({ theme }) => {
  return {
    width: '100%',
    height: 48,
    textTransform: 'lowercase',
    background: theme.palette.secondary.contrastText,
    textDecoration: 'underline',
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.secondary.contrastText,
    },
    '& .MuiButton-label': {
      color: theme.palette.secondary.main,
    },
  };
});
