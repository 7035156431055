import styled from 'styled-components';

export const FillBackground = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.lightMain};
  margin: auto;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    background-color: #fff;
    min-width: 100%;
    overflow-x: hidden;
  }
`;

export const FillBackground2 = styled.div`
  position: absolute;
  overflow-x: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    overflow-x: visible;
  }
`;

export const FillBackgroundImg = styled.img`
  top: 0;
  right: -30px;
  width: 125%;
  height: 43rem;
  z-index: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 105%;
    height: 280%;
  }
  @media (min-width: 1024px) {
    width: 116%;
    height: 280%;
    right: 0;
  }
`;

export const CloudImg = styled.img`
  width: 140px;
  height: fit-content;
  z-index: 1;
  margin-left: 25px;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 220px;
  }
  @media (min-width: 1024px) {
    margin-left: 200px;
  }
`;

export const BuildImg = styled.img`
  max-width: 630px;
  height: fit-content;
  z-index: 0;
  width: 80%;
  display: none;
  @media (min-width: 1024px) {
    width: 48%;
    display: initial;
    transform: translate(58px, 10px);
    -ms-transform: translateX(58px);
    -ms-transform: translateY(10px);
    -webkit-transform: translateX(58px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateX(58px);
    -moz-transform: translateY(10px);
    -o-transform: translateY(58px);
    -o-transform: translateX(10px);
    margin-right: 80px;
  }
  @media (min-width: 1024px) {
    transform: translate(190px, 10px);
    -ms-transform: translateX(190px);
    -ms-transform: translateY(10px);
    -webkit-transform: translateX(190px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateX(190px);
    -moz-transform: translateY(10px);
    -o-transform: translateY(190px);
    -o-transform: translateX(10px);
  }
`;

export const CarImg = styled.img`
  max-width: 680px;
  height: fit-content;
  z-index: 2;
  width: 30%;
  @media (min-width: 1024px) {
    transform: translate(42px, 0px);
    -ms-transform: translateX(42px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateX(42px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateX(42px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(42px);
    -o-transform: translateX(0px);
    margin-right: 80px;
    width: 50%;
  }
`;

export const UserImg = styled.img`
  max-width: 141px;
  height: fit-content;
  z-index: 2;
  width: 14%;
  @media (min-width: 1024px) {
    transform: translate(0px, -32px);
    -ms-transform: translateX(0px);
    -ms-transform: translateY(-32px);
    -webkit-transform: translateX(0px);
    -webkit-transform: translateY(-32px);
    -moz-transform: translateX(0px);
    -moz-transform: translateY(-32px);
    -o-transform: translateY(0px);
    -o-transform: translateX(-32px);
    margin-right: 60px;
    width: 11%;
  }
  @media (min-width: 1024px) {
    margin-right: 80px;
    transform: translate(113px, -13px);
    -ms-transform: translateX(113px);
    -ms-transform: translateY(-13px);
    -webkit-transform: translateX(113px);
    -webkit-transform: translateY(-13px);
    -moz-transform: translateX(113px);
    -moz-transform: translateY(-13px);
    -o-transform: translateY(113px);
    -o-transform: translateX(-13px);
  }
`;

export const FlowerImg = styled.img`
  max-width: 142px;
  transform: translate(0px, 0);
  -ms-transform: translateX(0px);
  -ms-transform: translateY(0);
  -webkit-transform: translateX(0px);
  -webkit-transform: translateY(0);
  -moz-transform: translateX(0px);
  -moz-transform: translateY(0);
  -o-transform: translateY(0px);
  -o-transform: translateX(0);
  height: fit-content;
  z-index: 2;
  width: 20%;
  @media (min-width: 1024px) {
    transform: translate(114px, 44px);
    -ms-transform: translateX(114px);
    -ms-transform: translateY(44px);
    -webkit-transform: translateX(114px);
    -webkit-transform: translateY(44px);
    -moz-transform: translateX(114px);
    -moz-transform: translateY(44px);
    -o-transform: translateY(114px);
    -o-transform: translateX(44px);
    margin-right: 30px;
    width: 11%;
  }
`;

export const PeopleImg = styled.img`
  max-width: 850px;
  margin-top: 30px;
  height: fit-content;
  z-index: 2;
  width: 78%;
`;

export const Section = styled.div`
  display: flex;
  max-width: 1204px;
  margin: 0 auto;
  z-index: 1000;
  justify-content: space-between;
`;

export const SectionClouds = styled.div`
  margin: 0 auto;
  z-index: 1000;
  padding-top: 4rem;
  justify-content: right;
  flex-direction: row-reverse;
  transform: translate(40px, 105px);
  -ms-transform: translateX(40px);
  -ms-transform: translateY(105px);
  -webkit-transform: translateX(40px);
  -webkit-transform: translateY(105px);
  -moz-transform: translateX(40px);
  -moz-transform: translateY(105px);
  -o-transform: translateY(40px);
  -o-transform: translateX(105px);
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    transform: none;
    display: flex;
  }
`;

export const SectionBuild = styled.div`
  display: flex;
  margin: 0 auto;
  z-index: 1000;
  max-width: 1204px;
  margin-top: 3rem;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: row;
    margin-top: 3rem;
  }
`;
export const SectionCar = styled.div`
  display: flex;
  margin: 0 auto;
  z-index: 1000;
  justify-content: right;
  flex-direction: row-reverse;
  transform: translate(40px, -65px);
  -ms-transform: translateX(40px);
  -ms-transform: translateY(-65px);
  -webkit-transform: translateX(40px);
  -webkit-transform: translateY(-65px);
  -moz-transform: translateX(40px);
  -moz-transform: translateY(-65px);
  -o-transform: translateY(40px);
  -o-transform: translateX(-65px);
  align-items: flex-end;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: flex;

    transform: translate(12px, -180px);
    -ms-transform: translateX(12px);
    -ms-transform: translateY(-180px);
    -webkit-transform: translateX(12px);
    -webkit-transform: translateY(-180px);
    -moz-transform: translateX(12px);
    -moz-transform: translateY(-180px);
    -o-transform: translateY(12px);
    -o-transform: translateX(-180px);
  }
`;

export const CallToActionTop = styled.div`
  display: flex;
  margin: 0 auto 2.5rem auto;
  max-width: 1204px;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    transform: translate(0px, -80px);
    -ms-transform: translateX(0px);
    -ms-transform: translateY(-80px);
    -webkit-transform: translateX(0px);
    -webkit-transform: translateY(-80px);
    -moz-transform: translateX(0px);
    -moz-transform: translateY(-80px);
    -o-transform: translateY(0px);
    -o-transform: translateX(-80px);
  }
`;

export const SectionCoverages = styled.div`
  display: flex;
  margin: 20px auto;
  z-index: 1000;
  max-width: 1204px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 1rem;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    transform: translate(80px, 0px);
    -ms-transform: translateX(80px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateX(80px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateX(80px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(80px);
    -o-transform: translateX(0px);
    padding: 0;
  }
`;

export const SectionCoveragesCards = styled.div`
  display: flex;
  margin: 4rem auto;
  z-index: 1000;
  flex-wrap: wrap;
  max-width: 1204px;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  padding: 0 2rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 80px auto;
    padding: 0;
    flex-direction: row;
    justify-content: space-evenly;
    transform: translate(-80px, 0px);
    -ms-transform: translateX(-80px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateX(-80px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateX(-80px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(-80px);
    -o-transform: translateX(0px);
  }
`;

export const SectionCoveragesContent = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const SectionAdvantages = styled.div`
  display: flex;
  margin: 0 auto;
  z-index: 3;
  max-width: 1204px;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const SectionTitle = styled.div`
  max-width: 340px;
  font-size: 45px;
  z-index: 1;
  padding: 0 2rem;
  line-height: 30px;
  transform: translate(0, -24px);
  -ms-transform: translateX(0px);
  -ms-transform: translateY(-24px);
  -webkit-transform: translateX(0px);
  -webkit-transform: translateY(-24px);
  -moz-transform: translateX(0px);
  -moz-transform: translateY(-24px);
  -o-transform: translateY(0px);
  -o-transform: translateX(-24px);
  margin-top: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    transform: translate(0, -68px);
    -ms-transform: translateX(0px);
    -ms-transform: translateY(-68px);
    -webkit-transform: translateX(0px);
    -webkit-transform: translateY(-68px);
    -moz-transform: translateX(0px);
    -moz-transform: translateY(-68px);
    -o-transform: translateY(0px);
    -o-transform: translateX(-68px);
    margin-top: 8rem;
  }
  @media (min-width: 1024px) {
    transform: none;
    -ms-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -o-transform: none;
    padding: 0 4rem;
  }
`;

export const ActionButtonTopWrapper = styled.div`
  max-width: fit-content;
`;

export const NormalTitle: any = styled.h1`
  width: 23rem;
  font-size: 47px;
  line-height: 50px;
  color: ${(props) => props.theme.palette.primary.main};
  @media (max-width: 1024px) {
    width: 16rem;
    font-size: 32px;
    line-height: 30px;
  }
`;
export const BoldTitle = styled.span`
  font-family: ItauText-Bold;
`;

export const CallToActionTitle = styled.h2`
  font-size: 32px;
  z-index: 2;
  color: ${(props) => props.theme.palette.text.primary};
  padding: 0 2rem 1rem 2rem;
  text-align: center;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;
    text-align: left;
    transform: translate(-60px, 0px);
    -ms-transform: translateX(-60px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateX(-60px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateX(-60px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(-60px);
    -o-transform: translateX(0px);
    max-width: 50%;
  }
  @media (max-width: 1024px) {
    font-size: 28px;
  }
`;

export const NormalCoveragesTitle = styled.span`
  font-size: 35px;
  color: #2b374a;
  z-index: 2;
  margin-top: 80px;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
`;
export const BoldCoveragesTitle = styled.span`
  font-family: ItauText-Bold;
`;

export const AdvantageIconBorder = styled.div`
  border-radius: 50%;
  border: 2px solid #d5dadc;
  width: 80px;
  height: 80px;
  z-index: 3;
`;
export const AdvantageItem = styled.div`
  z-index: 3;
  display: flex;
  width: 201px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-left: 40px;
    margin-bottom: 0;
  }
`;

export const AdvantageTitle = styled.h3`
  font-size: 1rem;
  font-family: ItauText-Bold;
  margin: 8px 0px;
  margin-top: 24px;
  min-width: 240px;
`;
export const AdvantageContent = styled.span`
  font-family: ItauText-Regular;
`;

interface CoverageProps {
  modal?: boolean;
  size?: string;
}

export const CoverageItem = styled.div<CoverageProps>`
  z-index: 3;
  display: flex;
  border: ${({ modal }) => (modal ? 'none' : '2px solid #D5DADC')};
  border-radius: 8px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 40px;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 110px;
  box-sizing: border-box;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-bottom: 40px;
    width: 350px;
    min-height: 10rem;
  }
`;

export const CoverageItemModal = styled.div<CoverageProps>`
  z-index: 3;
  display: flex;
  border: ${({ modal }) => (modal ? 'none' : '2px solid #D5DADC')};
  border-radius: 8px;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 8px 60px;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 110px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
`;

export const CoverageImageItem = styled.img`
  z-index: 3;
  display: flex;
  border: 1 solid #d5dadc;
  border-radius: 8px;
  min-width: 90px;
`;

export const CoverageTextItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const CoverageTextItemTitle = styled.h3`
  font-family: ItauText-Bold;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.4;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 160px;
  }

  &:focus {
    outline: auto !important;
  }
  &:active {
    outline: auto !important;
  }
`;

export const CoverageTextItemContent = styled.span<CoverageProps>`
  font-family: ItauText-Regular;
  font-size: 14px;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: ${({ size }) => size};
  }
  &:focus {
    outline: auto !important;
  }
  &:active {
    outline: auto !important;
  }
`;
export const SectionActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  transform: translate(-170px, -30px);
  -ms-transform: translateX(-170px);
  -ms-transform: translateY(-30px);
  -webkit-transform: translateX(-170px);
  -webkit-transform: translateY(-30px);
  -moz-transform: translateX(-170px);
  -moz-transform: translateY(-30px);
  -o-transform: translateY(-170px);
  -o-transform: translateX(-30px);
  @media (max-width: 1024px) {
    transform: translate(0px, -30px);
    -ms-transform: translateX(0px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateX(0px);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateX(0px);
    -moz-transform: translateY(-30px);
    -o-transform: translateY(0px);
    -o-transform: translateX(-30px);
  }
`;
export const SectionPartners = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  margin: 40px auto;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: row;
    padding-left: 15rem;
  }
`;

export const SectionSeeCoverages = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  margin: 2.5rem auto;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 40px 350px;
    flex-direction: row;
  }
`;

export const PartnersTitle = styled.h2`
  font-size: 32px;
  color: #2b374a;
  width: 100%;
  z-index: 3;
  text-align: center;
  margin-bottom: 2rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-bottom: 0;
    text-align: left;
    width: 450px;
  }
`;

export const PartnersImg = styled.img`
  width: 150px;
  height: auto;
  z-index: 3;
`;

export const PartnersSmallImg = styled.img`
  width: 150px;
  height: auto;
  z-index: 3;
  margin-left: 40px;
`;

export const SeeCoveragesDetails = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  position: relative;
`;

export const ModalTitle = styled.h6`
  padding: 16px 24px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 20px;
  font-family: ItauText-Bold;
  line-height: 24px;
`;
export const ModalTitleContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
export const ModalContent = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-y: scroll;
    height: 100%;
  }
`;

export const HomeBackground = styled.div`
  background-color: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    background-color: initial;
  }
`;
