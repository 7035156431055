import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { IHttpPostClient } from '@core/data/contracts/http/http-post-client';
import { AxiosHttpPostClient } from '@core/infra/axios/axios-http-post-client';
import { ResendTokenAuth } from '@core/domain/entities/auth/resend-token';
import { ResendTokenAuthRepository } from '@core/infra/repository/auth/resend-token-auth-repository';
import { ResendTokenAuthUsecase } from '@core/data/services/resend-token-auth-usecase';
import { ResendTokenAuthResponse } from '@core/domain/entities/auth/response/resend-token-auth-response';

export function resendTokenAuthFactory() {
  // eslint-disable-next-line
  const { environment }: Partial<EnvironmentContextProps> = useEnvironment();

  const httpPostClient: IHttpPostClient<ResendTokenAuth, ResendTokenAuthResponse> = new AxiosHttpPostClient(
    '/autenticar/reenviotoken',
    {},
    environment.gatewayBFFBaseURL.url
  );
  const resendTokenAuthRepository = new ResendTokenAuthRepository(httpPostClient);
  const resendTokenUsecase = new ResendTokenAuthUsecase(resendTokenAuthRepository);

  return resendTokenUsecase;
}
