import { Question } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQSearchQuestionsRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQSearchQuestionsUseCase {
  execute(): Promise<Question[]>;
}

export class GetFaqSearchQuestionsUseCase implements IGetFAQSearchQuestionsUseCase {
  constructor(private readonly getFAQSearchQuestionsRepository: IGetFAQSearchQuestionsRepository) {}

  async execute(): Promise<Question[]> {
    return await this.getFAQSearchQuestionsRepository.execute();
  }
}
