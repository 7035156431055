import { useStepProvider } from '../../../../providers/step-provider';
import { AutoStateManager } from '@web/state-management';
import { simulationFactory } from '../../../../factories/simulation-factory';
import { useEffect, useState } from 'react';
import { LoadingAutoPage } from '@workspace/frontend/auto/presentation/components';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { analyticsAutoV2Factory } from '@workspace/frontend/auto/application/analytics';
import { AutoAddressStep } from './address-step';
import { ErrorCode } from '../../../../enums/error-code';
import { ResponseError } from '../../../../entities/response-error';
import { LoadingError } from '../../../../components/loading-error/loading-error';
import { sendLeadFactory } from '@workspace/frontend/auto/presentation/web-app';
import { networkStyleFactory } from '../../../../factories/network-style-factory';
import { useOriginContext } from '../../../../providers/origin-provider';
import { searchCepFactory } from '../../../../factories/search-cep-factory';

export function AddressStepContainer() {
  const [loading, setLoading] = useState(false);
  const [cachedCep, setCachedCep] = useState<string | null>(null);
  const [simulationError, setSimulationError] = useState<boolean>(false);
  const [activeLeadError, setActiveLeadError] = useState<boolean>(false);
  const [leadChannel, setLeadChannel] = useState<string>();
  const [susep, setSusep] = useState<string>();
  const [cepNotFound, setCepNotFound] = useState<boolean>(false);
  const [cepLoading, setCepLoading] = useState<boolean>(false);

  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();
  const stepProvider = useStepProvider();

  const { environment } = partialEnvironment;
  const initialValues = AutoStateManager.addressStepSelector();
  const origin = useOriginContext();
  const autoV2analyticsCalls = analyticsAutoV2Factory(origin);
  const analyticsAuto = autoV2analyticsCalls.HIRING.ADDRESS;

  useEffect(() => {
    analyticsAuto.LOAD();
    (async () => {
      const networkStyleUsecase = networkStyleFactory(partialEnvironment);
      const { channel_name, susep } = await networkStyleUsecase.execute();
      setLeadChannel(channel_name);
      setSusep(susep);
    })();
  }, []);

  const onSimulationError = (error: ResponseError) => {
    if (
      error?.code === ErrorCode.INVALID_DRIVER ||
      error?.code === ErrorCode.INVALID_INSURE ||
      error?.code === ErrorCode.INVALID_PARTNER_CODE ||
      error?.code === ErrorCode.INVALID_CEP
    ) {
      setActiveLeadError(true);
    }
    setLoading(false);
    setSimulationError(true);
  };

  const onSearchCepError = () => {
    // cep error case
  };

  const searchCepUseCase = searchCepFactory({ partialEnvironment, onError: onSearchCepError });
  const simulationUsecase = simulationFactory(environment);

  const handleBack = () => {
    stepProvider.handleBack();
  };

  const handleOnChangeCep = async (e) => {
    const cep = e?.target?.value;
    if (cep?.length !== 9) return;
    setCepLoading(true);
    const res = await searchCepUseCase.execute(cep);
    setCepNotFound(!!res?.data?.erro);
    setCepLoading(false);
  };

  const handleSubmit = async ({ cep }) => {
    setLoading(true);
    setCachedCep(cep);
    const {
      plate,
      zeroKm,
      brandAndModel,
      modelYear,
      fabricationYear,
      bonusClass,
      previousBonus,
      insuranceCompany,
      itemsBrandAndModel,
    } = AutoStateManager.itemRiskStepSelector();
    const { cpf } = AutoStateManager.profileStepSelector();

    const simulationRequest = {
      cpf,
      fabricationYear,
      modelYear,
      brandAndModel,
      zeroKm,
      plate,
      susep: susep || partialEnvironment.environment.autoV2Susep,
      overnightCep: cep,
      previousBonus,
      bonusClass,
      insuranceCompany,
      itemsBrandAndModel,
    };

    const simulationResponse = await simulationUsecase.execute(simulationRequest);
    if (simulationResponse) {
      AutoStateManager.quotationStepApiResponseDispatcher(simulationResponse);
      AutoStateManager.addressStepDispatcher(cep);
      setLoading(false);
      stepProvider.handleNext();
    }
  };

  if (loading) return <LoadingAutoPage />;

  const sendLeadUsecase = sendLeadFactory({
    service: environment?.leadPath?.path,
    baseURL: environment?.gatewayAWSBaseURL?.url,
    environment: environment?.leadProcessor?.env,
    recaptchaSiteKey: environment.recaptchaSiteKey,
    products: environment.leadProcessor.products,
    channel: leadChannel,
  });

  if (simulationError) {
    const onRetry = () => {
      autoV2analyticsCalls.HIRING.ERROR.CONTINUE();
      handleSubmit({ cep: cachedCep });
    };
    const OnLoad = autoV2analyticsCalls.HIRING.ERROR.LOAD;
    const onBackErrorPage = () => {
      setSimulationError(false);
      autoV2analyticsCalls.HIRING.ERROR.BACK();
    };
    return (
      <LoadingError
        errorTitle={'Tivemos um imprevisto na sua cotação on-line'}
        activeLeadError={activeLeadError}
        onLoad={OnLoad}
        onRetry={onRetry}
        onBack={onBackErrorPage}
        sendLeadUsecase={sendLeadUsecase}
      />
    );
  }
  return (
    <AutoAddressStep
      onSubmit={(props) => {
        analyticsAuto.CONTINUE();
        handleSubmit(props);
      }}
      onBack={handleBack}
      onChangeCep={handleOnChangeCep}
      loading={cepLoading}
      initialValues={initialValues}
      sendLeadUsecase={sendLeadUsecase}
      cepNotFound={cepNotFound}
    />
  );
}

export function StepThreeAddress() {
  return <AddressStepContainer />;
}
