import React, { useEffect, useState } from 'react';
import { BoxWeb, BreadCrumbsWeb, HiddenWeb } from '@workspace/frontend/shared/presentation/components';
import parse from 'html-react-parser';
import { Cta, FeedbackContainer, PostCard, PostData, PostImage, SectionTitle, ShareContent } from '../../components';
import { Article, BreadCrumbSeparator, Link, SectionText } from './styles';

import { sendCommentFactory } from '../../factories/';
import { useEnvironment } from '@workspace/providers';
import { useDynamicBlog } from '../../provider/blog-provider';
import { ArticleBlog } from '@workspace/frontend/blog/domain/entities';
import { dateFormat, phraseToUpperCamelCase, timeToRead } from '../../utils';
import { ANALYTICS_BLOG } from '@workspace/frontend-shared-application-analytics';
export interface postCommentInterface {
  uid: string;
  commentTextArea: string;
  satisfactionLevel: string;
}
export default function PageReadArticle({ content }) {
  const links = {
    local: {
      loja: 'https://lojadeseguros.dev.cloud.itau.com.br/',
    },
    development: {
      loja: 'https://lojadeseguros.dev.cloud.itau.com.br/',
    },
    hom: {
      loja: 'https://lojadeseguros.hom.cloud.itau.com.br/',
    },
    production: {
      loja: 'https://www.lojadesegurositau.com.br/',
    },
  };

  const enviroment = useEnvironment();
  const articles = useDynamicBlog();
  const env = enviroment.environment.environment;
  const useCasePostComment = sendCommentFactory({
    partialEnvironment: enviroment,
    onError: () => console.error('erro'),
  });
  const linkLoja = links[env] ? links[env].loja : links.production.loja;

  const [articleContent, setArticle] = useState<ArticleBlog | undefined>(content);
  const [keepReading, setKeepReading] = useState<ArticleBlog[]>([]);
  const [articleUrl] = useState<string>(linkLoja + content?.url);

  useEffect(() => {
    _init();
    scrollTop();
  }, []);

  const _init = () => {
    ANALYTICS_BLOG.POST.LOAD([phraseToUpperCamelCase(articleContent.post_title)]);

    if (content.keep_reading_posts.length > 0) {
      getKeepReading(content.keep_reading_posts);
    }
  };

  const scrollTop = () => {
    const element = document?.getElementsByClassName('content-wrapper')[0];
    element?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getKeepReading = (posts) => {
    const keepReadingPost = [];
    posts.map((element) => {
      const posts = articles.blogContent.find((e) => e.uid === element.uid);
      keepReadingPost.push(posts);
    });
    setKeepReading(keepReadingPost);
  };

  const satisfactionPayload = (data: postCommentInterface) => {
    return {
      postId: data.uid,
      comment: data.commentTextArea,
      rating: parseInt(data.satisfactionLevel),
    };
  };

  const postSatisfaction = (data) => {
    useCasePostComment.execute(satisfactionPayload({ uid: content.uid, ...data }));
  };

  const analyticsEvent = (title, midia) => {
    ANALYTICS_BLOG.POST.SHARE_CONTENT([phraseToUpperCamelCase(title), phraseToUpperCamelCase(midia)]);
  };

  return (
    <>
      <HiddenWeb smDown>
        <BoxWeb css={{ maxWidth: '1300px', margin: '64px auto 0' }}>
          <BreadCrumbsWeb
            pages={[
              { name: 'início', href: '/blog/home' },
              // { name: 'automotivo', href: '/blog/automotivo' }, Comentado pois ainda não temos a home de categoria
              { name: articleContent.post_title, color: 'textPrimary' },
            ]}
            separator={<BreadCrumbSeparator className="icon-itaufonts_seta_right" />}
          />
        </BoxWeb>
      </HiddenWeb>
      <BoxWeb
        margin="32px auto 64px"
        maxWidth="850px"
        px={{ xs: 2, md: 0 }}
        position="relative"
        width={{ sm: '100%', md: '59%' }}
      >
        <SectionTitle
          id={`lblPostTitle_screenArticle`}
          title={articleContent.post_title}
          titleComponent="h1"
          subtitle={articleContent.post_subtitle}
        >
          <BoxWeb
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ textTransform: 'uppercase' }}
            mb={{ xs: 2, md: 5 }}
          >
            <BoxWeb
              className="read-date"
              role="text"
              aria-label={`Data de publicação ${
                dateFormat(articleContent.created_at).dateAcessibility
              }. Tempo de leitura ${timeToRead(articleContent.post_text)}`}
            >
              <PostData
                date={dateFormat(articleContent.created_at)}
                readingTime={`${timeToRead(articleContent.post_text)}`}
              />
            </BoxWeb>
            <ShareContent title={articleContent.post_title} url={articleUrl} onClick={analyticsEvent} />
          </BoxWeb>
          <PostImage
            src={articleContent.post_image.path}
            showImageCaption
            alt={articleContent.post_image_alt}
            hideFromScreenReader={articleContent.post_image_hide_screen_reader_enabled}
          />
        </SectionTitle>
        <SectionText>
          <BoxWeb mb={8}>
            <Article>{parse(articleContent.post_text)}</Article>
            {articleContent.cta_group.flag_cta_enabled && <Cta visible={false} />}
          </BoxWeb>
          <div>
            <hr />
            <FeedbackContainer title={articleContent.post_title} url={articleUrl} onSubmit={postSatisfaction} />
            <p>
              este artigo faz parte da missão da{' '}
              <Link id="lnkLojadeSegurosItaú_screenArticle" href={linkLoja}>
                Loja de Seguros Itaú
              </Link>{' '}
              que é ajudar e assessorar o nosso cliente. visite a{' '}
              <Link id="lnkLojadeSegurosItaú_screenArticle" href={linkLoja}>
                Loja de Seguros Itaú
              </Link>{' '}
              e conheça os nossos produtos
            </p>
          </div>
        </SectionText>
      </BoxWeb>
      {keepReading.length > 0 && (
        <BoxWeb py={{ xs: 4, md: 8 }} bgcolor={'background.lightSecondary'}>
          <SectionTitle title="continue **lendo**">
            <BoxWeb display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {keepReading.map((element, index) => (
                <PostCard
                  index={index}
                  href={element.url}
                  image={{
                    src: element.post_image.path,
                    alt: element.post_image_alt,
                    hideFromScreenReader: element.post_image_hide_screen_reader_enabled,
                  }}
                  category={element?.post_category}
                  title={element.post_title}
                  description={element.post_subtitle}
                  date={dateFormat(element.created_at)}
                  readingTime={timeToRead(element.post_text)}
                />
              ))}
            </BoxWeb>
          </SectionTitle>
        </BoxWeb>
      )}
    </>
  );
}
