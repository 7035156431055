import { BoxWeb, useThemeProvider } from '@workspace/frontend/shared/presentation/components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import {
  AlertProduct,
  BoldText,
  HighlightText,
  LightText,
  ProductCard,
} from '@workspace/frontend/my-account/presentation/components';
import React from 'react';

interface ProductsProps {
  isClientHospitau: boolean;
  showAlert: boolean;
  messageDescriptive: string;
}

export function HospitauProductSection({ isClientHospitau, showAlert, messageDescriptive }: ProductsProps) {
  const { Theme } = useThemeProvider();
  return (
    <SCThemeProvider theme={Theme}>
      {showAlert ? (
        <AlertProduct
          show={showAlert}
          message={'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.'}
        />
      ) : null}
      {isClientHospitau && (
        <>
          <BoxWeb mt={'18px'}>
            <BoxWeb
              component={'h2'}
              aria-label="produtos hospitaú contratados"
              m={0}
              id="lbHospitauText_screenMyProducts"
            >
              <LightText aria-hidden>
                produtos <HighlightText>hospitaú</HighlightText> <BoldText> contratados</BoldText>
              </LightText>
            </BoxWeb>
          </BoxWeb>

          <ProductCard
            img={'https://www.itau.com.br/assets/dam/publisher/01_itau/09_Seguros/01_campanha1/faq/bemestar.png'}
            title={'hospitaú'}
            descriptive={messageDescriptive}
          />
        </>
      )}
    </SCThemeProvider>
  );
}
