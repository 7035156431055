import { User } from '../../../domain/entities/auth/user';
import { IRegisterUserRepository } from '../../../data/contracts/auth/register-user-repository';
import { RegisterUserResponse } from '../../../domain/entities/auth/response/user';
import { IHttpPostClient } from '../../../data/contracts/http/http-post-client';

export class RegisterUserRepository implements IRegisterUserRepository {
  constructor(private readonly httpPostClient: IHttpPostClient<User, RegisterUserResponse>) {}

  async execute(userParams: User, headers?: any): Promise<RegisterUserResponse> {
    return await this.httpPostClient.post(userParams, null, headers);
  }
}
