import { Product } from '@workspace/frontend/faq-v2/domain/entities';
import { IGetFAQProductRepository } from '@workspace/frontend/faq-v2/domain/contracts';

export interface IGetFAQProductUseCase {
  execute(): Promise<Product[]>;
}

export class GetFaqProductUseCase implements IGetFAQProductUseCase {
  constructor(private readonly getFAQProductRepository: IGetFAQProductRepository) {}

  async execute(): Promise<Product[]> {
    return await this.getFAQProductRepository.execute();
  }
}
