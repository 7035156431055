import { DefaultButtonProps } from '../../models/default-button';
import { useThemeProvider } from '../theme-provider/theme-provider';
import { ThemeProviderInjector } from '../theme-provider/theme-provider-injector';

export function LinkButtonWeb({ type, label, onClick, id, disabled }: DefaultButtonProps) {
  const { Components } = useThemeProvider();
  const { LinkButton } = Components;
  return (
    <ThemeProviderInjector>
      <LinkButton type={type} onClick={onClick} id={id} label={label} disabled={disabled} />
    </ThemeProviderInjector>
  );
}

export default LinkButtonWeb;
