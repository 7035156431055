import { useEffect, useState } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import {
  AlertWeb,
  BoxWeb,
  LinkButtonWeb,
  LinkWeb,
  NavigateButtonsWeb,
  useThemeProvider,
} from '@workspace/frontend/shared/presentation/components';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { sendUserAuthFactory } from '../../factories/send-user-auth';
import { EmailField, PasswordField } from '@web/form/component';
import { FormController } from '@web/form';
import { AuthenticationContainer, Title, TitleBold } from '../style';
import { ForgotPasswordModal } from './screens/forgot-password-modal/';
import useErrorProvider from '../../providers/error-provider';
import { setAuthentication } from '@workspace/frontend/auth/infra/repositories';
import { RouteEnum } from '../../enums/route-enum';
import { useAuthenticationParams } from '../../providers/authentication-params-provider';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { AuthStateManager } from '@workspace/frontend-shared-application-state-manager';
import { RecaptchaActions } from '../../enums/recaptcha-actions-enum';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthOriginProvider } from '../../providers/auth-origin-provider';
import { useAuthProductProvider } from '../../providers/auth-product-provider';

type AuthContainerProps = {
  onSuccessLoginCallback: () => void;
  onBack: () => void;
};
export function AuthContainer({ onSuccessLoginCallback = () => null, onBack }: AuthContainerProps) {
  const { Theme } = useThemeProvider();
  const { buildError, requestError, setRequestError } = useErrorProvider();

  const partialEnvironment: Partial<EnvironmentContextProps> = useEnvironment();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).LOGIN.ACCOUNT_ACCESS;

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [openForgotMyPasswordModal, setOpenForgotMyPasswordModal] = useState(false);
  const handleCloseAlert = () => setRequestError({ show: false });
  const handleCloseModal = () => setOpenForgotMyPasswordModal(false);
  const { registerLoginURLBase } = useAuthenticationParams();

  const onError = (errorData) => {
    const responseData = errorData?.response?.data;
    const pendingError = responseData?.data?.errors?.[0]?.code === 'pendingUser';

    if (pendingError) {
      return window.location.assign(`${RouteEnum.CONFIRM_TOKEN}?email=${email}`);
    }

    setSubmitLoading(false);

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });
  };

  const sendUserAuthUsecase = sendUserAuthFactory();

  const handleBack = (): void => {
    analyticsAuth.BACK();
    onBack();
  };

  const handleSubmit = async ({ email, password }): Promise<void> => {
    const { environment } = partialEnvironment;

    analyticsAuth.CONTINUE();
    setSubmitLoading(true);

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.AUTHENTICATION, environment.recaptchaSiteKey);

      const payload = { email, senha: password };
      const response = await sendUserAuthUsecase.execute(payload, {
        recaptcha: JSON.stringify(recaptcha),
      });

      if (response?.data?.success) {
        const { identifier, username, tokens } = response.data.value;

        AuthStateManager.userNameDispatcher(username);
        AuthStateManager.userAuthenticatedDispatcher(true);

        setAuthentication({ accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, identifier });
        setSubmitLoading(false);
        if(onSuccessLoginCallback) {
          onSuccessLoginCallback();
          setSubmitLoading(false);
          return;
        }

        window.location.assign('/');
      }

      setSubmitLoading(false);
    } catch (error) {
      onError(error);
    }
  };

  const handleForgotMyPassword = () => {
    analyticsAuth.FORGOT_PASSWORD();
    setOpenForgotMyPasswordModal(true);
  };

  const handleRegister = () => {
    analyticsAuth.REGISTER();
  };

  useEffect(() => {
    analyticsAuth.LOAD();
    registerLoginURLBase();
  }, []);

  useEffect(handleCloseAlert, [submitLoading]);

  return (
    <SCThemeProvider theme={Theme}>
      <ForgotPasswordModal open={openForgotMyPasswordModal} onClose={handleCloseModal} />

      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        onClose={handleCloseAlert}
        children={requestError.message}
        secondsToClose={16}
      />

      <BoxWeb
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="background.main"
      >
        <AuthenticationContainer>
          <FormController onSubmit={handleSubmit} hideErrors validationMode="onBlur">
            {({ formState: { errors }, watch }) => {
              setEmail(watch('email'));

              return (
                <>
                  <BoxWeb mb={'2.5rem'} textAlign="center">
                    <BoxWeb mb="8px">
                      <Title>que bom ter você por aqui!</Title>
                    </BoxWeb>
                    <TitleBold id="titleAcount_screenAuth">acesse sua conta</TitleBold>
                  </BoxWeb>
                  <BoxWeb mb={'1.875rem'}>
                    <EmailField
                      componentProps={{
                        id: 'txtEmail_screenAuth',
                        label: 'insira seu e-mail',
                        type: 'email',
                        required: true,
                      }}
                    />
                  </BoxWeb>
                  <BoxWeb mb={'0.5rem'}>
                    <PasswordField
                      componentProps={{ id: 'txtPassword_screenAuth', label: 'digite sua senha', required: true }}
                      validationOverrides={{ matches: null }}
                    />
                  </BoxWeb>
                  <BoxWeb mt={errors?.password ? '1.5625rem' : '0.5rem'} mb={'2.5rem'}>
                    <LinkButtonWeb
                      id="lnkForgotMyPassword_screenAuth"
                      label="não lembro a minha senha"
                      onClick={handleForgotMyPassword}
                    />
                  </BoxWeb>
                  <BoxWeb mb={'2.5rem'}>
                    <NavigateButtonsWeb
                      loading={submitLoading}
                      onClickBack={handleBack}
                      widthBackButton={'188px'}
                      widthContinueButton={'188px'}
                      justifyContent="space-between"
                      disabled={Boolean(Object.keys(errors).length)}
                    />
                  </BoxWeb>
                  <BoxWeb display="flex" justifyContent="center">
                    <BoxWeb component="span" fontWeight="700" color="#2b374a">
                      Ainda não tem uma conta?{' '}
                      <LinkWeb
                        id="lnkRegister_screenAuth"
                        href={RouteEnum.REGISTER}
                        onClick={handleRegister}
                        text="Cadastre-se"
                        target="_self"
                        rel=""
                      />
                    </BoxWeb>
                  </BoxWeb>
                </>
              );
            }}
          </FormController>
        </AuthenticationContainer>
      </BoxWeb>
    </SCThemeProvider>
  );
}
