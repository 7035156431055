import { SimulationRequest } from '@core/domain/entities/autov2/simulation-request';
import { Actor, Automobile } from '@workspace/entities';

export class SimulationRequestDTO {
  static translate({
    cpf,
    fabricationYear,
    modelYear,
    brandAndModel,
    zeroKm,
    plate,
    overnightCep,
    susep,
    previousBonus,
    bonusClass,
    insuranceCompany,
    itemsBrandAndModel,
  }: {
    cpf: string;
    fabricationYear: number;
    modelYear: number;
    brandAndModel: string;
    zeroKm?: boolean;
    plate: string;
    susep: string;
    overnightCep: string;
    previousBonus: boolean;
    bonusClass?: number;
    insuranceCompany?: any;
    itemsBrandAndModel: { value: string; key: number | string }[];
  }) {
    const itemRisk = new Automobile({
      plate,
      zeroKm,
      overnightCep,
      brandAndModel,
      modelYear,
      fabricationYear,
      bonusClass,
      insuranceCompany,
      itemsBrandAndModel,
    });
    const actor = new Actor({ cpf });
    const itemsBrandAndModel2: number =
      typeof itemsBrandAndModel[0].key === 'string' ? parseInt(itemsBrandAndModel[0].key) : itemsBrandAndModel[0].key;
    const res: SimulationRequest = {
      cepPernoite: itemRisk.overnightCep.replace('-', ''),
      codigoParceiro: 51,
      susep,
      condutores: [
        {
          pessoa: {
            documento: {
              numero: actor.cpf?.replace(/[.-]/g, ''),
              tipo: 1,
            },
            tipo: 1,
          },
        },
      ],
      segurado: {
        pessoa: {
          documento: {
            numero: actor.cpf?.replace(/[.-]/g, ''),
            tipo: 1,
          },
          tipo: 1,
        },
      },
      tipoSeguro: previousBonus ? 6 : 0,
      veiculo: {
        anoFabricacao: itemRisk.fabricationYear,
        anoModelo: itemRisk.modelYear,
        codigoVersao: zeroKm ? itemsBrandAndModel2 : parseInt(itemRisk.brandAndModel),
        flagZeroKm: itemRisk.zeroKm ? 'S' : 'N',
        placa: itemRisk.plate,
      },
    };

    const BonusPolicy = {
      bonus: {
        valor: bonusClass,
      },
      congenere: insuranceCompany,
    };

    if (previousBonus) res.apoliceBonus = BonusPolicy;

    return res;
  }
}
