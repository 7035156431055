import styled from 'styled-components';

interface ProductTopicProps {
  title: string;
  children: JSX.Element;
}

const TopicContainer = styled.div`
  width: 176px;
  height: fit-content;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-child {
    padding-left: 0;
  }
`;

const TopicTitle = styled.span`
  color: #2b374a;
  font-family: 'ItauText-XBold';
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 26px;
  text-transform: lowercase;
  cursor: default;
`;

export const ProductTopic = ({ title, children }: ProductTopicProps) => {
  return (
    <TopicContainer>
      <TopicTitle>{title}</TopicTitle>

      {children}
    </TopicContainer>
  );
};
