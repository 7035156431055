import { RestHandler } from 'msw/lib/types/handlers/RestHandler';
import { getProducts } from './odonto-product-mock';
import { getProposal } from './odonto-proposal-mock';
import { getProductsAns } from './odonto-product-ans-mock';
import { getEffectiveProposal } from './odonto-effective-proposal-mock';
import { StatusMock } from './odonto-status-mock';
import { getProposalDetail } from './odonto-proposal-detail-mock';
import { getDependents } from './odonto-dependents-mock';
import { getProductsKinship } from './odonto-product-kinship-mock';

export const odontoMockHandlers = (env): RestHandler[] => {
  return [getProducts(env), getProposal(env), getProductsAns(env), getEffectiveProposal(env), StatusMock(env), getProposalDetail(env), getDependents(env), getProductsKinship(env)];
};
