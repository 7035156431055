import { GetCategoriesBlogUseCase } from '@workspace/frontend/blog/domain/use-cases';
import { GetCategoriesRepository } from '@workspace/frontend/blog/infra/repositories';
import { EnvironmentContextProps } from '@workspace/providers';
import { CategoryBlogResponse } from '@workspace/frontend/blog/domain/entities';
import {AxiosHttpGetClient} from "@workspace/frontend/http/repositories/axios";
import {IHttpGetClient} from "@workspace/frontend/http/domain/contracts/repositories";

export function getCategoriesFactory({ environment }: Partial<EnvironmentContextProps>) {
  const httpGetClient: IHttpGetClient<null, CategoryBlogResponse> = new AxiosHttpGetClient(
    `/content_types/seg_blog_categorias/entries/?environment=${environment?.cmsBase?.environment}`,
    {
      api_key: environment?.contentStack?.apiKey,
      access_token: environment?.contentStack?.accessToken,
    },
    environment?.cmsBase?.url
  );
  const getCategoryRepository = new GetCategoriesRepository(httpGetClient);
  const getArticleBlogUsecase = new GetCategoriesBlogUseCase(getCategoryRepository);
  return getArticleBlogUsecase;
}
