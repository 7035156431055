import { useContext, useEffect, useRef, useState } from 'react';
import { FormController } from '@web/form';
import { RegisterContainer, Title } from '../../style';
import { findUserByCPFFactory } from '../../../factories/find-user-by-cpf';
import { AuthStateManager } from '@web/state-management';
import { AlertWeb, BoxWeb, NavigateButtonsWeb } from '@workspace/frontend/shared/presentation/components';
import { CpfField } from '@web/form/component';
import useErrorProvider from '../../../providers/error-provider';
import { Loading } from '../../../components/loading/loading';
import { StepperContext } from '../stepper/stepper-context';
import { RouteEnum } from '../../../enums/route-enum';
import { useAuthenticationParams } from '../../../providers/authentication-params-provider';
import { getRecaptchaToken } from '@workspace/frontend/shared/application/injectors';
import { EnvironmentContextProps, useEnvironment } from '@workspace/providers';
import { RecaptchaActions } from '../../../enums/recaptcha-actions-enum';
import { useAuthOriginProvider } from '../../../providers/auth-origin-provider';
import { analyticsAuthV2Factory } from '@workspace/frontend/auth/application/analytics';
import { useAuthProductProvider } from '../../../providers/auth-product-provider';

export function ValidateCPFContainer() {
  const { handleNext } = useContext(StepperContext);
  const titleRef = useRef(undefined);
  const { buildError, requestError, setRequestError } = useErrorProvider();
  const {
    environment: { recaptchaSiteKey, recaptchaV2SiteKey },
  }: Partial<EnvironmentContextProps> = useEnvironment();

  const origin = useAuthOriginProvider();
  const product = useAuthProductProvider();
  const analyticsAuth = analyticsAuthV2Factory({ origin, product }).REGISTER.PERSONAL_DOCUMENT;

  const [loading, setLoading] = useState<boolean>(true);
  const [storedCPF, setStoredCPF] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const { getRegisteredLoginURLBase } = useAuthenticationParams();

  const handleCloseAlert = () => setRequestError({ show: false });

  const onError = (errorData) => {
    setSending(false);

    if (!errorData?.response || errorData.response?.status < 500) return;

    const error = buildError(errorData);
    setRequestError({ ...error, show: true });
  };

  const findUserByCPF = findUserByCPFFactory();

  const handleSubmit = async ({ cpf }) => {
    analyticsAuth.NEXT();

    const searchCPF = !storedCPF || storedCPF !== cpf;
    if (!searchCPF) return handleNext();

    setSending(true);
    AuthStateManager.clearStore();

    try {
      const recaptcha = await getRecaptchaToken(RecaptchaActions.CONSULT_CPF, recaptchaSiteKey);
      const response = await findUserByCPF.execute(cpf, {
        recaptcha: JSON.stringify(recaptcha),
      });

      const userAlreadyRegistered = response?.data?.success && response?.data?.value?.foundIn === 2;

      if (userAlreadyRegistered) {
        const { email } = response?.data?.value;
        const redirectUrl = `${RouteEnum.USER_ALREADY_REGISTERED}?email=${email}`;
        return window.location.assign(redirectUrl);
      }
    } catch (error) {
      onError(error);
    }

    setSending(false);

    AuthStateManager.registrationCPFDispatcher(cpf);
    AuthStateManager.registrationStepDispatcher(1);

    handleNext();
  };

  const handleClickBack = () => {
    analyticsAuth.BACK();
    const loginURLBase = getRegisteredLoginURLBase();
    window.location.assign(loginURLBase);
  };

  useEffect(() => {
    analyticsAuth.LOAD();

    const cpf = AuthStateManager.registrationCPFSelector();
    setStoredCPF(cpf);

    setLoading(false);

    setTimeout(() => {
      if (titleRef.current) titleRef.current.focus();
    }, 100);
  }, []);

  return (
    <>
      <AlertWeb
        show={requestError.show}
        severity={requestError.type}
        onClose={handleCloseAlert}
        children={requestError.message}
        secondsToClose={16}
      />
      <RegisterContainer>
        <BoxWeb marginBottom="32px">
          <Title id="lblInicialText_screenRegister" tabIndex={-1} ref={titleRef}>
            qual é o seu CPF?
          </Title>
        </BoxWeb>

        {loading ? (
          <Loading />
        ) : (
          <FormController
            validationMode="onBlur"
            onSubmit={handleSubmit}
            hideErrors
            initialValues={{
              cpf: storedCPF,
            }}
          >
            <BoxWeb maxWidth="240px" marginBottom="45px">
              <CpfField componentProps={{ id: 'txtCpf_screenRegister', label: 'informe seu CPF' }} />
            </BoxWeb>

            <BoxWeb maxWidth="400px">
              <NavigateButtonsWeb
                loading={sending}
                onClickBack={handleClickBack}
                widthBackButton={'188px'}
                widthContinueButton={'188px'}
                justifyContent="center"
              />
            </BoxWeb>
          </FormController>
        )}
      </RegisterContainer>
    </>
  );
}
