import { rest } from 'msw';

const responseError = { erros: ['cpf'] };
interface OdontoProposalRequestProps {
  pagador: any;
}

export const getProposal = (env) => {
  return rest.post<OdontoProposalRequestProps>(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-odonto/propostas`, (req, res, ctx) => {
    const { cpf } = req.body.pagador;
    if(cpf === '41893699854') return res(ctx.status(400), ctx.json(responseError));

    return res(
      ctx.status(200),
      ctx.json({
        id_proposta: 9041388,
        data_contratacao: "2020-06-01T23:34:00",
        situacao: "aguardando efetivação ",
        efetivada: false,
        id_produto: 26,
        id_produto_externo: 1169,
        codigo_campanha: "347",
        nome_produto: "Plano Odonto  MetLife",
        valor_premio: 41.9,
        valor_premio_total: 41.9,
        dia_pagamento: 1,
        metodo_formalizacao: "Senha + Token",
        pagador: {
          nome_completo: "John Due",
          cpf: "22938419368",
          email: "john.due@itau.com.br",
          enderecos: [
            {
              logradouro: "Rua Santina Zanatta",
              numero: "501",
              complemento: "",
              bairro: "Novo Mundo",
              uf: "MG",
              cep: "13087500"
            }
          ],
          telefones: [
            {
              tipo: "celular",
              ddd: 34,
              numero: 988909683
            }
          ]
        },
        beneficiarios: [
          {
            titular: true,
            sequencia: 0,
            nome_completo: "John Due",
            cpf_beneficiario: "22445725879",
            email: "john.due@itau.com.br",
            sexo: "M",
            data_nascimento: "1983-05-29T00:00:00",
            nome_completo_mae: "Joana Due",
            enderecos: [
              {
                logradouro: "Rua Santina Zanatta",
                numero: "501",
                complemento: "",
                bairro: "Novo Mundo",
                uf: "MG",
                cep: "13087500"
              }
            ],
            telefones: [
              {
                tipo: "celular",
                ddd: 34,
                numero: 988909683
              }
            ]
          }
        ]
      })
    );
  });
};
