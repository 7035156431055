import { rest } from 'msw';

interface SigninAuthRequestProps {
  email: string;
  senha: string;
}
export const signinAuthMock = (env) => {
  return rest.post<SigninAuthRequestProps>(`${env.gatewayBFFBaseURL.url}/usuarios/login`, (req, res, ctx) => {
    const { email } = req.body;
    if (email !== 'mock@mock.com') {
      return res(
        ctx.status(200),
        ctx.json({
          success: true,
          errors: [],
          value: {
            id: 0,
            status: 'Autenticação realizada com sucesso!',
            identifier: 'c478ff77-a348-404d-a775-357894c605e6',
            username: 'João',
            tokens: {
              accessToken: "eyJraWQiOiJhZTYxYWIxZi0yNTRhLTQ5ZWQtODMzNC05ZDJlN2E0MzZiNGQuaG9tLmdlbi4xNTk3NjAwMzM2OTkyLmp3dCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5ZWUxNGI4Mi1mY2MzLTQ1MDctYTVjNi03MzJiNDMyM2Y1ZjUiLCJpc3MiOiJodHRwczovL29wZW5pZC5pdGF1LmNvbS5ici9hcGkvb2F1dGgvdG9rZW4iLCJpYXQiOjE2NTE1MjA3MDMsImV4cCI6MTY1MTUyMTAwMywiQWNjZXNzX1Rva2VuIjoiMmFhM2ZlOTUuZTAzY2MzNGMtNjFlNi00MWI0LWFjZDgtZjQ4ZGQ4MWQxOTU4IiwidXNyIjoibnVsbCIsImZsb3ciOiJDQyIsInNvdXJjZSI6IklOVCIsInNpdGUiOiJkZXYiLCJlbnYiOiJIIiwibWJpIjoidHJ1ZSIsImF1dCI6IiIsInZlciI6InYxLjAiLCJzY29wZSI6ImFwcGlkLUxTMi1FIHJlc291cmNlLlJFQUQifQ.PHCI9y7E6qq8QMHc83d0Y392QuByGf8jmaNIdEdbt8nQnObAgK794-kR9sSTfhvEcq2UgV8aF5SgYlhHSddMeKth0m34mW99lqYELNZAUae2cwwqyYJZAjbT0JPlNEnA0LDxxLp8DvpTVmWT0HfM9XDtEJkmDhn4e9MyOnjfTHrQKmFdtTy2uwWLx2I0YF1TZNf7_Xh0SGS8NGXE-4EhsY898Iv11Nds7x2XBzYQnUr9DkApnmBEiG-4FGdcvu5ZzIozhniyJPftbfg2ttcj8S_EHbwiDCnetZybpihjHHTye9LJt2LEoDH7_ZtrncGp6JG2st0vMTxFU_acn0JlnA",
              refreshToken: "eyJraWQiOiJyZWZyZXNoLmhvbS5nZW4uaW50ZXJuYWwuand0IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI5ZWUxNGI4Mi1mY2MzLTQ1MDctYTVjNi03MzJiNDMyM2Y1ZjUiLCJpc3MiOiJodHRwczovL29wZW5pZC5pdGF1LmNvbS5ici9hcGkvb2F1dGgvdG9rZW4iLCJpYXQiOjE2NTE1MTkxNTAsImV4cCI6MTY1MTUxOTUxMCwiQWNjZXNzX1Rva2VuIjoiZTcxNTgzYTFkLjU1MDkwNGVhLTdmZDUtNGJhNy1iZjZkLTkzMmMxMmM1YmUzMSIsInVzciI6Im51bGwiLCJmbG93IjoiQ0MiLCJzb3VyY2UiOiJJTlQiLCJzaXRlIjoiZGV2IiwiZW52IjoiSCIsIm1iaSI6InRydWUiLCJhdXQiOiIiLCJ2ZXIiOiJ2MS4wIiwic2NvcGUiOiJhcHBpZC1MUzItRSByZXNvdXJjZS5SRUFEIn0.IdDdPzB9k5wSRJ_RQDBKbIyIFFS9SomKNkChD6po9oezpWCphz4Vt_I8BFwZAUjWLdk1Zb9l8oJrIUEMQXi5VTXVM6Uhy5IE8mdnGZpn8srjzeQzIP9IwYa90_dxYwN7NXGtumOF2mSWi7Yj86av4nsdIwP9HBhqmJz-RzUem9OKhX26STfaQedohIG9DDKCfKAWyY2pMsB3lCpfSPnxJtEhzVK_w0EMFMQHFMJuSO3lKzzQ_6A7r7luFOndJ_Z4UR5EFttEwhGlWKyX1qGnPmfqJ6NZhCBkVZn3UCjgMd8FAHJ6_sDHzQmsoSBFB6zCH3C-32n3IVKk6a4Hu32hKw",
            },
          },
        })
      );
    }

    return res(
      ctx.status(400),
      ctx.json({
        success: false,
        errors: [
          {
            code: 'loginIsInvalid',
            message: 'E-mail ou senha inválidos',
          },
        ],
      })
    );
  });
};
