import { AxiosPostRepository } from '@workspace/frontend/auto/infra/repositories';
import { OfferResponseRaw, OffersResponseAdapted } from '@workspace/frontend/auto/domain/contracts';
import * as yup from 'yup';

export interface BaseUseCase<Data, Response> {
  execute: (data?: Data) => Response;
}
export class GetOffersUseCase implements BaseUseCase<null, Promise<OffersResponseAdapted>> {
  constructor(private readonly repository: AxiosPostRepository<null, OfferResponseRaw>) {}

  execute() {
    return this.repository.execute().then((res) => {
      getOffersUseCaseResponseValidator(res);
      return getOffersResponseAdapter(res);
    });
  }
}

const getOffersResponseAdapter = ({ data }): OffersResponseAdapted => ({
  assistences: data.data.assistencias,
  coverages: data.data.coberturas,
});

const getOffersUseCaseResponseValidator = (offersResponse) => {
  const schema = yup.object({
    data: yup.object({
      data: yup.object({
        coberturas: yup.array().of(yup.object()).required('faltando array de coberturas'),
        assistencias: yup.array().of(yup.object()).required('faltando array de assistencias'),
      }),
    }),
  });
  schema
    .validate(offersResponse, {
      abortEarly: false,
    })
    .catch((error) => {
      console.error(
        `${error.name}: ${error.errors.length} error(s) ocurred:`,
        '\n',
        error.errors.join('\n'),
        '\n',
        'objeto recebido:',
        offersResponse
      );
    });
};
