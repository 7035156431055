import { IdentifierRepositoryContract } from '@workspace/frontend/shared/domain/contracts';
import { IdentifierRepository } from '@workspace/frontend/shared/infra/repositories/local-storage';

abstract class IdentifierRepositoryFactory {
  private static identifierRepository: IdentifierRepositoryContract;

  public static get instance(): IdentifierRepositoryContract {
    if (!this.identifierRepository) {
      this.identifierRepository = new IdentifierRepository();
    }

    return this.identifierRepository;
  }
}

export { IdentifierRepositoryFactory };
