import { styled as styledMui, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { InputWebProps } from '../../models/input-web-props';
import { useState } from 'react';
import { ItauIcon } from '../common/itau-icon';

export const ToggleVisibleButton = styled.button`
  font-size: 16px;
  padding: 3px 6px;
  color: ${({ theme }) => theme.palette.link.primary};
  text-decoration: underline;
  line-height: 20px;
  background-color: transparent;
  border: unset;
  cursor: pointer;
`;

const CustomInput = ({
  id,
  dataTestID,
  onChange,
  onBlur,
  name,
  value,
  variant = 'standard',
  label,
  InputLabelProps,
  helperText,
  maxLength = 250,
  error,
  required,
}: InputWebProps) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const customHelperText = () => {
    if (error) {
      return (
        <>
          <ItauIcon
            iconName="exclamacao"
            ariaHidden={true}
            style={{ marginRight: '8px', verticalAlign: 'text-bottom' }}
          />
          {helperText}
        </>
      );
    }

    return helperText;
  };

  const renderEndAdornment = (): JSX.Element => (
    <ToggleVisibleButton
      type="button"
      id="lnkPasswordToggleVisible_screenAuth"
      onClick={() => setVisiblePassword((oldProps) => !oldProps)}
    >
      {visiblePassword ? 'ocultar' : 'mostrar'}
    </ToggleVisibleButton>
  );

  return (
    <TextField
      id={id}
      fullWidth
      name={name}
      required={required}
      value={value}
      label={label}
      error={error}
      onBlur={onBlur}
      variant={variant}
      onChange={onChange}
      helperText={customHelperText()}
      type={visiblePassword ? 'text' : 'password'}
      inputProps={{
        'data-testid': dataTestID,
        'aria-label': label,
        maxLength,
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        endAdornment: renderEndAdornment(),
      }}
    />
  );
};

export const PasswordAuthentication = styledMui(CustomInput)(() => ({
  width: '100%',
  root: {
    width: '100%',
  },
}));
