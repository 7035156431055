import moment from 'moment';

interface props {
  message?: string;
  format?: string;
}

export const dateFormatTest = ({
  message = 'você precisa informar uma data válida',
  format = 'DD/MM/YYYY',
}: props = {}) => ({
  name: 'validDate',
  message,
  test: (value: string) => moment(value, format, true).isValid(),
});
