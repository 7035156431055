import { rest } from 'msw';

export const cancellationHiringMock = (env) => {
  // https://mktseguros-api.dev.cloud.itau.com.br/v1/mkt-seguros-e2e/contratacoes
  return rest.get(`${env.gatewayAWSBaseURL.url}/v1/mkt-seguros-e2e/contratacoes`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        contratos: [
          {
            id_contratacao: '7009fe30-b118-4e99-b7d6-b96f80d73e0b',
            numero_contratacao: '51069964',
            data_contratacao: '23/04/2020',
            status: 'ativo',
            nome_produto: 'PORTO RESIDENCIAL MODULARIZADO',
            plano_odontologico: false,
            tipo: 3,
            mfa: true,
          },
          {
            id_contratacao: 'a460c73b-611a-4067-b375-9ff8d44bec01',
            numero_contratacao: '51069931',
            data_contratacao: '23/04/2020',
            status: 'ativo',
            nome_produto: 'PORTO RESIDENCIAL MODULARIZADO',
            plano_odontologico: false,
            tipo: 3,
            mfa: false,
          },
          {
            id_contratacao: 'a460c73b-611a-4067-b375-9ff8d44bec01',
            numero_contratacao: '666666',
            data_contratacao: '23/04/2020',
            status: 'ativo',
            nome_produto: 'PORTO RESIDENCIAL SUPERAPP',
            plano_odontologico: false,
            tipo: null,
            mfa: false,
          },
          {
            id_contratacao: '9039759',
            numero_contratacao: '51069924',
            data_contratacao: '23/04/2020',
            status: 'ativo',
            nome_produto: 'ASSISTÊNCIA REALIZA BEM-ESTAR',
            plano_odontologico: false,
            parceiro: 'realiza',
            tipo: 6,
            mfa: true,
          },
          {
            id_contratacao: '9039759',
            numero_contratacao: '61062739',
            data_contratacao: '23/04/2020',
            status: 'ativo',
            nome_produto: 'ASSISTÊNCIA REALIZA PET',
            plano_odontologico: false,
            parceiro: 'realiza',
            tipo: 5,
            mfa: true,
          },
          {
            id_contratacao: '9039758',
            numero_contratacao: '9039758',
            data_contratacao: '06/04/2020',
            status: 'cancelado',
            nome_produto: 'ODONTO METLIFE DIAMOND',
            plano_odontologico: true,
            tipo: 2,
            mfa: true,
          },
          {
            id_contratacao: '9039757',
            numero_contratacao: '9039757',
            data_contratacao: '06/04/2020',
            status: 'ativo',
            nome_produto: 'ODONTO METLIFE DIAMOND',
            plano_odontologico: true,
            tipo: 2,
            mfa: false,
          },
        ],
        status: 'OK',
      })
    );
  });
};
